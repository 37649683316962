import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import PureModal from '+/Modal';
import { actions as mainActions } from '../main/sagaSlice';

export const GoingLiveModal = ({
  open,
  toggle,
  isLoading,
  goLiveFailure,
  selectActiveProvider,
}) => {
  const { providerId } = useParams();

  const strengthenProfileClicked = () => {
    console.log('strengthenProfileClicked() called');
    // If URL is /profiles/{something}-for/{providerId}
    // then providerId param will be set in router
    // and thus we should auto-select it as the active provider.
    if (providerId) {
      console.log(`providerId = ${providerId}`);
      selectActiveProvider({ id: parseInt(providerId) });
    }

    toggle(false);
    history.push('/');
  };

  // eslint-disable-next-line implicit-arrow-linebreak
  return (
    <PureModal
      visible={open}
      closeModal={() => toggle(false)}
      headerContent={
        <h4 className='congratulations-header'>Congratulations, your profile is going live!</h4>
      }
    >
      {isLoading ? (
        'Working on it...'
      ) : goLiveFailure ? (
        <div>Server error. Please send email to hello@zencare.co to let us know.</div>
      ) : (
        <>
          <h3>Woohoo!</h3>
          <p>
            Your profile will go live within 24 business hours, pending a verification of the
            license information you provided. You will receive a separate email regarding the
            details of your profile going live, plus membership benefits - let us know if you don't
            see it!
          </p>

          <p>
            There are more fields you can add to improve your profile performance! Continue on in
            the Members Portal.
          </p>

          <p>Thanks so much, and welcome to the community!</p>
          <p>Yours,</p>
          <p>Zencare team</p>

          <button className='pill primary md' onClick={strengthenProfileClicked}>
            Strengthen my profile
          </button>
        </>
      )}
    </PureModal>
  );
};

const mapStateToProps = ({ main }) => ({
  isLoading: main.isLoading,
  goLiveFailure: main.goLiveFailure,
  goLiveResult: main.goLiveResult,
});
const actions = {
  selectActiveProvider: mainActions.selectActiveProvider,
};

export default connect(mapStateToProps, actions)(GoingLiveModal);
