import React from 'react';
import { Field } from 'react-final-form';
import Input from '~/shared/forms/Input';
import TermsOfService from './TermsOfService';
import WrappedCardElement from './WrappedCardElement';
import BillingAddressInputs from '~/shared/forms/BillingAddressInputs';

function AccountDetailsBox() {
  return (
    <div className='box'>
      <div className='account-details-inputs'>
        <Field name='first_name' component={Input} label='First name' placeholder='First name' />
        <Field name='last_name' component={Input} label='Last name' placeholder='Last name' />
        <Field name='email' component={Input} label='Email' placeholder='Email' />
        <WrappedCardElement />
        <h5 className='m-b-0 m-t-sm'>Billing Address</h5>
        <BillingAddressInputs />
        <TermsOfService />
      </div>
    </div>
  );
}

export default AccountDetailsBox;
