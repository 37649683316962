/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import storage from '#/storage';
import useStripeCardElement from '+/hooks/useStripeCardElement';
import { CardElement } from '@stripe/react-stripe-js';
import wrapStripeElements from '#/seat-based/wrapStripeElements';
import { ConvertToMonthlySagaParam } from '../payment/seat-based/types/types';
import ReCAPTCHA from 'react-google-recaptcha';

const DeveloperTools = () => {
  const dispatch = useDispatch();
  // @ts-ignore-next-line
  const recaptchaRef: MutableRefObject<ReCAPTCHA> = React.useRef();
  const [recaptchaTokenWasCheckedBefore, setRecaptchaTokenWasCheckedBefore] = useState(false);

  const { stripe, elements, cardElRef, cardComplete, cardError } = useStripeCardElement();
  // @ts-ignore-next-line - $TSFixMe
  const newStripeCustomerId = useSelector((state) => state.developerTools.stripeCustomerId);
  // @ts-ignore-next-line - $TSFixMe
  const newStripeSubscriptionId = useSelector((state) => state.developerTools.stripeSubscriptionId);
  const standaloneZencareAccountId = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.developerTools.standaloneZencareAccountId
  );
  const [customStripeCustomerId, setCustomStripeCustomerId] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressState, setAddressState] = useState('');
  const [addressPostalCode, setAddressPostalCode] = useState('');

  const stripeCustomerId =
    customStripeCustomerId === '' ? newStripeCustomerId : customStripeCustomerId;

  const previewedSubscriptionProrationDate = useSelector(
    // @ts-ignore-next-line - $TSFixMe
    (state) => state.developerTools.previewedSubscriptionProrationDate
  );

  React.useEffect(() => {
    if (!standaloneZencareAccountId) {
      console.log('calling createStandaloneZencareAccount');
      // Call our API to create an account ID
      dispatch(actions.createStandaloneZencareAccount());
    }
  }, [dispatch, standaloneZencareAccountId]);

  //const [stripeCustomerId, setStripeCustomerId] = useState('cus_KDnKG9W7ep8PhI');
  const [priceLookupKey, setPriceLookupKey] = useState('zencare_basic_monthly_ny_v4');
  const [setupPriceLookupKey, setSetupPriceLookupKey] = useState(
    'setup_remote_photo_videoshoot_v1'
  );
  const [addOnPriceLookupKey, setAddOnPriceLookupKey] = useState(
    'add_on_professional_copywriting_v1'
  );

  const [coupon, setCoupon] = useState('0zA8T3XM');

  // const [stripeSourceId, setStripeSourceId] = useState('src_1JZLjVHEu9I8ej85GiGGZfnm');

  const [customStripeSubscriptionId, setCustomStripeSubscriptionId] = useState('');

  const stripeSubscriptionId =
    customStripeSubscriptionId === '' ? newStripeSubscriptionId : customStripeSubscriptionId;

  const { provider } = useSelector<State, MainState>((state) => state.main);
  const accountId = provider?.account_id;

  return (
    <div>
      <h2>Unified Inbox - Test Tools</h2>
      <button
        onClick={() => {
          /** @type {GetAccountConversationsSagaParam} */
          const sagaParam = { params: { account_id: accountId } };

          // @ts-expect-error - NOTE: Need to use Jake's example for this so this @ts-expect-error is not needed.
          dispatch(actions.getAccountConversations(sagaParam));
        }}
      >
        Fetch conversations for account id {accountId}
      </button>

      <hr />

      <button
        onClick={() => {
          /** @type {GetAccountAppointmentsSagaParam} */
          const sagaParam = { params: { account_id: accountId } };

          // @ts-expect-error - NOTE: Need to use Jake's example for this so this @ts-expect-error is not needed.
          dispatch(actions.getAccountAppointments(sagaParam));
        }}
      >
        Fetch appointments for account id {accountId}
      </button>

      <hr />

      <button
        onClick={() => {
          /** @type {GetAccountClientsSagaParam} */
          const sagaParam = { params: { account_id: accountId } };

          // @ts-expect-error - NOTE: Need to use Jake's example for this so this @ts-expect-error is not needed.
          dispatch(actions.getAccountClients(sagaParam));
        }}
      >
        Fetch clients for account id {accountId}
      </button>

      <h2>Seat Based Billing - Test Tools</h2>
      <div>
        <label>
          Stripe Customer Id
          <input
            type='text'
            style={{ width: '100%' }}
            value={stripeCustomerId}
            onChange={(e) => {
              setCustomStripeCustomerId(e.target.value);
            }}
          />
        </label>
      </div>

      <div>
        <label>
          Stripe Subscription Id
          <input
            type='text'
            style={{ width: '100%' }}
            value={stripeSubscriptionId}
            onChange={(e) => {
              setCustomStripeSubscriptionId(e.target.value);
            }}
          />
        </label>
      </div>

      <div>
        <label>
          Price Lookup Key
          <input
            type='text'
            style={{ width: '100%' }}
            value={priceLookupKey}
            onChange={(e) => {
              setPriceLookupKey(e.target.value);
            }}
          />
        </label>
      </div>

      <div>
        <label>
          Coupon
          <input
            type='text'
            style={{ width: '100%' }}
            value={coupon}
            onChange={(e) => {
              setCoupon(e.target.value);
            }}
          />
        </label>
      </div>

      <h3>Reimbursify test tools</h3>

      <div className='m-t-md'>
        <button
          onClick={() => {
            const sagaParam: UpgradeToPaidReimbursifyProductParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'professional',
                coupon,
              },
            };

            // @ts-expect-error - NOTE: Can look at Jake's pattern for how to type this.
            dispatch(actions.upgradeToPaidReimbursifyProductPreview(sagaParam));
          }}
        >
          Preview Upcoming Invoice for Upgrade to Reimbursify Pro
        </button>
      </div>

      <div className='m-t-md'>
        <button
          onClick={() => {
            const sagaParam: UpgradeToPaidReimbursifyProductParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'professional',
                coupon,
              },
            };

            // @ts-expect-error - NOTE: Can look at Jake's pattern for how to type this.
            dispatch(actions.upgradeToPaidReimbursifyProduct(sagaParam));
          }}
        >
          Upgrade to Reimbursify Pro
        </button>
      </div>

      <div className='m-t-md'>
        <button
          onClick={() => {
            const sagaParam: UpgradeToPaidReimbursifyProductParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'premium',
                coupon,
              },
            };

            // @ts-expect-error - NOTE: Can look at Jake's pattern for how to type this.
            dispatch(actions.upgradeToPaidReimbursifyProductPreview(sagaParam));
          }}
        >
          Preview Upcoming Invoice for Upgrade to Reimbursify Premium
        </button>
      </div>

      <div className='m-t-md'>
        <button
          onClick={() => {
            const sagaParam: UpgradeToPaidReimbursifyProductParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'premium',
                coupon,
              },
            };

            // @ts-expect-error - NOTE: Can look at Jake's pattern for how to type this.
            dispatch(actions.upgradeToPaidReimbursifyProduct(sagaParam));
          }}
        >
          Upgrade to Reimbursify Premium
        </button>
      </div>
      <h3>ZPM test tools</h3>
      <div>
        <h2>Create</h2>

        <div>
          {/*<div>
            <label>
              Stripe Source Id
              <input
                type='text'
                style={{ width: '100%' }}
                value={stripeSourceId}
                onChange={(e) => {
                  setStripeSourceId(e.target.value);
                }}
              />
            </label>
              </div>*/}
          <div>
            <label>
              Setup Price Lookup Key
              <input
                type='text'
                style={{ width: '100%' }}
                value={setupPriceLookupKey}
                onChange={(e) => {
                  setSetupPriceLookupKey(e.target.value);
                }}
              />
            </label>
          </div>

          <div>
            <label>
              Add-On Price Lookup Key (optional)
              <input
                type='text'
                style={{ width: '100%' }}
                value={addOnPriceLookupKey}
                onChange={(e) => {
                  setAddOnPriceLookupKey(e.target.value);
                }}
              />
            </label>
          </div>

          <div className='flex column m-b-sm' ref={cardElRef}>
            <div className={`card-input m-b-xs`}>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#666666',
                      },
                    },
                    invalid: {
                      color: '#F2474E',
                    },
                  },
                }}
              />
            </div>
            {/* {cardError && <span className='text-error'>{cardError.message}</span>} */}
          </div>

          <input
            placeholder='Address Line 1'
            type='text'
            style={{ width: '50%' }}
            onChange={(e) => {
              setAddressLine1(e.target.value);
            }}
          />

          <input
            placeholder='Address Line 2'
            type='text'
            style={{ width: '50%' }}
            onChange={(e) => {
              setAddressLine2(e.target.value);
            }}
          />

          <input
            placeholder='City'
            type='text'
            style={{ width: '50%' }}
            onChange={(e) => {
              setAddressCity(e.target.value);
            }}
          />

          <input
            placeholder='State'
            type='text'
            style={{ width: '50%' }}
            onChange={(e) => {
              setAddressState(e.target.value);
            }}
          />

          <input
            placeholder='Zip Code'
            type='text'
            style={{ width: '50%' }}
            onChange={(e) => {
              setAddressPostalCode(e.target.value);
            }}
          />

          <h4>ZPM Standalone test tools</h4>
          <div className='m-t-md'>
            {process.env.ZENCARE_GOOGLE_CAPTCHA_KEY && (
              <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={process.env.ZENCARE_GOOGLE_CAPTCHA_KEY}
              />
            )}
            <button
              onClick={() => {
                // We might already have a recaptcha token if we already tried to submit and got server validation error.
                // If so, we need to call reset before calling recaptchaRef.current.executeAsync
                if (recaptchaTokenWasCheckedBefore) {
                  recaptchaRef.current.reset();
                }

                recaptchaRef.current.executeAsync().then(async (token: string) => {
                  setRecaptchaTokenWasCheckedBefore(true);

                  // @ts-ignore-next-line - $TSFixMe
                  const cardElement = elements.getElement(CardElement);
                  // @ts-ignore-next-line - $TSFixMe
                  const sourcePromise = () =>
                    // @ts-ignore-next-line - $TSFixMe
                    stripe.createSource(cardElement, {
                      type: 'card',
                      currency: 'usd',
                    });

                  const sagaParam: ZpmSignupPayTestCallSagaParam = {
                    params: {
                      account_id: standaloneZencareAccountId,
                    },
                    apiPayload: {
                      first_name: 'dev_tool_test_tool_first_name',
                      last_name: 'dev_tool_test_tool_last_name',
                      email: `staging+dev_tool_test_tool${new Date().getTime()}@zencare.co`,
                      zpm_tier: 'professional',
                      quantity: 2,
                      coupon,
                      token,
                      address_line1: addressLine1,
                      address_line2: addressLine2,
                      address_city: addressCity,
                      address_state: addressState,
                      address_postal_code: addressPostalCode,
                    },
                    sourcePromise,
                  };

                  // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
                  dispatch(actions.zpmSignupPayTestCall(sagaParam));
                });
              }}
            >
              Pay for ZPM Standalone
            </button>

            <button
              onClick={() => {
                const sagaParam: ZpmSignupPayPreviewTestCallSagaParam = {
                  params: {},
                  apiPayload: {
                    zpm_tier: 'professional',
                    quantity: 2,
                    coupon,
                  },
                };

                // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
                dispatch(actions.zpmSignupPayPreviewTestCall(sagaParam));
              }}
            >
              Preview ZPM Standalone
            </button>
          </div>

          <h4>Stripe Test Tools</h4>

          <button
            onClick={() => {
              // @ts-ignore-next-line - $TSFixMe
              const cardElement = elements.getElement(CardElement);

              // @ts-ignore-next-line - $TSFixMe
              const sourcePromise = () =>
                // @ts-ignore-next-line - $TSFixMe

                stripe.createSource(cardElement, {
                  type: 'card',
                  currency: 'usd',
                  /*owner: {
                    name: 'Test User', //_.get(source, 'name'),
                  },*/
                });

              const sagaParam: SubscribeToPlanApiTestCallSagaParam = {
                params: {
                  provider_id: storage.get('current_provider_id'),
                },
                apiPayload: {
                  // Note Use sourcePromise instead like PaymentForm.js does.
                  //source: { id: stripeSourceId },
                  lookup_key: priceLookupKey,
                  setup_lookup_key: setupPriceLookupKey,
                  add_on_lookup_key: addOnPriceLookupKey,
                  coupon,
                  referrer: undefined,
                  add_reimbursify: true,
                  address_line1: addressLine1,
                  address_line2: addressLine2,
                  address_city: addressCity,
                  address_state: addressState,
                  address_postal_code: addressPostalCode,
                },
                sourcePromise,
              };

              // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
              dispatch(actions.subscribeToPlanApiTestCall(sagaParam));
            }}
          >
            Create Stripe Subscription
          </button>
        </div>

        <div className='m-t-md'>
          <h5>Update address test tool</h5>
          {accountId && (
            <button
              onClick={() => {
                const sagaParam: UpdateAddressTestCallSagaParam = {
                  params: {
                    account_id: accountId,
                  },
                  apiPayload: {
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    address_city: addressCity,
                    address_state: addressState,
                    address_postal_code: addressPostalCode,
                  },
                };

                // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
                dispatch(actions.updateAddressTestCall(sagaParam));
              }}
            >
              Update Address
            </button>
          )}
        </div>

        <h2>Modify</h2>
        <div>
          <label>
            Stripe Subscription Id
            <input
              disabled={true}
              type='text'
              style={{ width: '100%' }}
              value={stripeSubscriptionId}
            />
          </label>
        </div>
        <div></div>
      </div>

      <hr />
      <div>
        <button
          onClick={() => {
            const sagaParam: AddSeatSagaParam = {
              params: {
                provider_id: storage.get('current_provider_id'),
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                lookup_key: priceLookupKey,
                setup_lookup_key: setupPriceLookupKey,
                add_on_lookup_key: addOnPriceLookupKey,
                coupon,
              },
            };

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.addSeatPreview(sagaParam));
          }}
        >
          Preview Invoice for Increment Stripe Subscription Seats for {priceLookupKey}
        </button>
      </div>
      <div>
        previewedSubscriptionProrationDate: {previewedSubscriptionProrationDate}
        <button
          onClick={() => {
            const sagaParam: AddSeatSagaParam = {
              params: {
                provider_id: storage.get('current_provider_id'),
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                lookup_key: priceLookupKey,
                setup_lookup_key: setupPriceLookupKey,
                add_on_lookup_key: addOnPriceLookupKey,
                coupon,
              },
            };

            if (previewedSubscriptionProrationDate) {
              sagaParam.apiPayload.previewed_subscription_proration_date =
                previewedSubscriptionProrationDate;
            }

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.addSeat(sagaParam));
          }}
        >
          Increment Stripe Subscription Seats for {priceLookupKey}
        </button>
      </div>

      <hr />
      <div>
        <button
          onClick={() => {
            const sagaParam: UpgradeProductSagaParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'professional',
                is_legacy: false,
              },
            };

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.upgradeProductPreview(sagaParam));
          }}
        >
          Preview Invoice for Upgrading Product to Professional
        </button>
      </div>
      <div className='m-t-md'>
        previewedSubscriptionProrationDate: {previewedSubscriptionProrationDate}
        <button
          onClick={() => {
            const sagaParam: UpgradeProductSagaParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'professional',
                is_legacy: false,
                coupon,
              },
            };

            if (previewedSubscriptionProrationDate) {
              sagaParam.apiPayload.previewed_subscription_proration_date =
                previewedSubscriptionProrationDate;
            }

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.upgradeProduct(sagaParam));
          }}
        >
          Upgrade Product to Professional
        </button>
      </div>

      <hr />
      <div>
        <button
          onClick={() => {
            const sagaParam: UpgradeProductSagaParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'premium',
                is_legacy: false,
                coupon,
              },
            };

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.upgradeProductPreview(sagaParam));
          }}
        >
          Preview Invoice for Upgrading Product to Premium
        </button>
      </div>
      <div className='m-t-md'>
        previewedSubscriptionProrationDate: {previewedSubscriptionProrationDate}
        <button
          onClick={() => {
            const sagaParam: UpgradeProductSagaParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'premium',
                is_legacy: false,
                coupon,
              },
            };

            if (previewedSubscriptionProrationDate) {
              sagaParam.apiPayload.previewed_subscription_proration_date =
                previewedSubscriptionProrationDate;
            }

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.upgradeProduct(sagaParam));
          }}
        >
          Upgrade Product to Premium
        </button>
      </div>

      <hr />
      <div>
        <button
          onClick={() => {
            const sagaParam: UpgradeProductSagaParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                is_legacy: true,

                new_tier: 'basic',
                coupon,
              },
            };

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.upgradeProductPreview(sagaParam));
          }}
        >
          Preview Invoice for Converting from Yearly to Basic Monthly
        </button>
      </div>
      <div className='m-t-md'>
        previewedSubscriptionProrationDate: {previewedSubscriptionProrationDate}
        <button
          onClick={() => {
            const sagaParam: ConvertToMonthlySagaParam = {
              params: {
                stripe_customer_id: stripeCustomerId,
                stripe_subscription_id: stripeSubscriptionId,
              },
              apiPayload: {
                new_tier: 'basic',
                coupon,
                legacy_is_ny_or_ca: false,
                legacy_provider_id: storage.get('current_provider_id'),
              },
              onSuccess: () => {},
              onError: () => {},
            };

            if (previewedSubscriptionProrationDate) {
              sagaParam.apiPayload.previewed_subscription_proration_date =
                previewedSubscriptionProrationDate;
            }

            // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
            dispatch(actions.convertToMonthly(sagaParam));
          }}
        >
          Convert Yearly to Monthly
        </button>
      </div>
    </div>
  );
};

export default connect(() => ({}), {})(wrapStripeElements(DeveloperTools));
