// import { Account } from '../../src/components/payment/seat-based/types';
interface Provider {
  account_id: string | number;
}

interface Customer {
  metadata: {
    account_id: number;
    provider_id: number;
    user_id: number;
  };
  sources: Array<$TSFixMe>;
}

const getAccountData = (provider: Provider, accounts: Account[], customer?: Customer): Account => {
  // TODO Review with Elijah - Elijah reviewed this on 10/29 after
  // he encountered errors after moving a Stripe customer around using his local
  // admin. We don't want to ever use the customer.metadata.account_id value
  // so commented that out. We should always use provider.account_id.
  /*let matchingAccount;
  if (customer && customer.metadata && customer.metadata.account_id) {
    matchingAccount = accounts.filter((account) => account.id === customer.metadata.account_id)[0];
  } else { */
  const { account_id } = provider;
  const matchingAccount = accounts.filter((account) => account.id === account_id)[0];
  //}
  if (
    matchingAccount &&
    matchingAccount.has_practice_management &&
    matchingAccount.tier === 'basic'
  ) {
    alert(
      'Uh Oh! It looks like your account has access to two different levels of Zencare Practice Management! This should not be possible. Please contact support@zencare.co to remedy this issue!'
    );
    window.location.replace('/');
  }
  return matchingAccount || {};
};

export default getAccountData;
