import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as authActions } from '../../auth/sagaSlice';
import { actions as mainActions } from '../sagaSlice';
import _ from 'lodash';
import { Button } from '@zencare/ui';
import history from '#/history';
import dayjs from 'dayjs';
import voca from 'voca';
import Modal from '+/Modal';
import blogPosts from './blogPosts.js';
import BlogPost from './BlogPost';
import ActionItems from './ActionItems';
import UrgentActionItems from './UrgentActionItems';
import ProfileCreationChecklist from './ProfileCreationChecklist';
import UpcomingAppointmentsDashboardWidget from '~/components/provider/appointments/UpcomingAppointmentsDashboardWidget';
import UnreadMessagesWidget from '~/components/provider/appointments/messages/UnreadMessagesWidget';
import GoLiveForm from '~/components/onboarding/GoLiveForm';
// import CTAItems from './CTAItemCards.jsx';

function getTimeOfDay() {
  const h = new Date().getHours();
  if (3 <= h && h < 12) return 'morning';
  if (12 <= h && h < 18) return 'afternoon';

  return 'evening';
}

function navToAvailability() {
  history.push('/profile/availability-locations');
}

function iconByTime(time) {
  switch (time) {
    case 'morning':
      return <i style={{ color: '#EFB52E' }} className='fad m-l-xs fa-sun'></i>;
    case 'afternoon':
      return <i style={{ color: '#EFB52E' }} className='fad m-l-xs fa-mug'></i>;
    default:
      return <i className='fad m-l-xs text-tertiary fa-moon-stars'></i>;
  }
}

const timeDescriptions = {
  morning: 'Morning (Before 10am)',
  daytime: 'Daytime (10am - 1pm)',
  afternoon: 'Afternoon (1pm - 5pm)',
  evening: 'Evening (After 5pm)',
  weekend: 'Weekends',
};

function acceptingSpan(location) {
  const where = location.remote_location ? 'online' : 'in-person';
  switch (location.accepting_patients) {
    case 0:
      return (
        <span className='text-error'>
          <i className={'fas fa-times-circle'}></i>
          Not accepting {where}
        </span>
      );
    case 20:
      return (
        <span className='text-warning'>
          <i className={'fas fa-info-circle'}></i>
          Accepting {where} with a 2-3 week wait
        </span>
      );
    case 40:
      return (
        <span className='text-primary'>
          <i className={'fas fa-check-circle'}></i>
          Accepting {where}
        </span>
      );
    default:
      return (
        <span className='text-error'>
          <i className={'fas fa-times-circle'}></i>
          Not accepting {where}
        </span>
      );
  }
}

function locationAvailability(location) {
  return (
    <div className='location'>
      <h6 className='m-b-xs'>{location.location_name}</h6>
      {acceptingSpan(location)}

      {['morning', 'afternoon', 'daytime', 'evening', 'weekend'].map((time) => {
        const icon =
          location.accepting_patients && location[`${time}_availability`]
            ? 'check-circle text-primary'
            : 'times-circle text-secondary';

        return (
          <span>
            <i className={`fas fa-${icon}`}></i>
            {timeDescriptions[time]}
          </span>
        );
      })}
    </div>
  );
}

export const Dashboard = ({
  provider,
  updateProvider,
  checklist,
  getChecklist,
  isSignedIn,
  roles,
  accounts,
  licenses,
}) => {
  const checklistIsDoneOrOnLastStep =
    checklist?.length === 0 || (checklist?.length === 1 && checklist.includes('Start membership'));
  const isIncompleteOrReviewing = ['reviewing', 'incomplete'].includes(provider.status);

  const showGoLiveForm = isIncompleteOrReviewing && checklistIsDoneOrOnLastStep;

  useEffect(() => {
    if (isSignedIn && provider && provider.id) {
      getChecklist({ id: provider.id });
    }
  }, [getChecklist, provider.id, isSignedIn]);

  const confirmAvailability = () => {
    updateProvider({
      id: provider.id,
      custom_content: {
        availability_last_updated: new Date(),
      },
    });
    history.push('/profile/availability-locations#confirmed');
  };

  const updatedAt = _.get(provider, 'custom_content.availability_last_updated');
  // Show the availability confirmation if there are locations, there is no last update date or if the last update date is >15days ago
  const showAvailabilityConfirmation =
    _.get(provider, 'locations', []).length &&
    (!updatedAt || (updatedAt && dayjs().diff(dayjs(updatedAt)) >= 1000 * 60 * 60 * 24 * 15));

  const hasCalendar = provider.calendar && provider.calendar.length;

  if (showGoLiveForm) {
    return <GoLiveForm provider={provider} />;
  }

  return (
    <div>
      <h1 className='m-b-md m-t-l'>
        Good {voca.capitalize(getTimeOfDay())}, {provider.first_name || provider.company_name}!{' '}
        {iconByTime(getTimeOfDay())}
      </h1>
      <UrgentActionItems provider={provider} />
      {provider.status === 'active' && (
        <div className='widgets-wrapper m-b-md'>
          <UnreadMessagesWidget />
          <UpcomingAppointmentsDashboardWidget />
        </div>
      )}
      <ProfileCreationChecklist
        checklist={checklist}
        provider={provider}
        roles={roles}
        accounts={accounts}
        licenses={licenses}
      />
      <ActionItems provider={provider} />
      <h2 className='m-t-xl m-b-md'>Announcements</h2>
      {showAvailabilityConfirmation ? (
        <div className='custom announcements no-mobile box m-b-sm'>
          <div className='header'>
            <h4 className='m-b-0'>Confirm your availability</h4>
          </div>
          {updatedAt ? (
            <p>
              The last time you updated or confirmed your practice availability was{' '}
              <b>{dayjs(updatedAt).format('MMMM DD, YYYY')}.</b> Let's keep your practice details
              up-to-date! Is this information still accurate?
            </p>
          ) : (
            <p>Let's keep your practice details up-to-date! Is this information accurate?</p>
          )}
          <div className='locations-container m-b-md'>
            {provider.locations && provider.locations.map((l) => locationAvailability(l))}
          </div>
          <div className='actions'>
            <Button className='primary pill' onClick={confirmAvailability}>
              Confirm Availability
            </Button>
            <Button className='primary pill' onClick={navToAvailability}>
              Update Availability
            </Button>
          </div>
        </div>
      ) : (
        <div className='custom announcements align-center box m-b-sm'>
          <h2>
            <i className='fad fa-sparkles text-quaternary'></i>
          </h2>
          <h5>You're all set! Have a wonderful day ahead.</h5>
        </div>
      )}
      <h2 className='m-t-xl m-b-md'>Top Therapist Blog Posts</h2>
      <div className='blog-posts'>
        {blogPosts.map((props) => (
          <BlogPost {...props} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ main, auth }) => ({
  isSignedIn: auth.isSignedIn,
  provider: main.provider,
  checklist: main.checklist,
  roles: auth.roles,
  licenses: main.licenses,
  accounts: main.accounts,
});

const actions = {
  updateProvider: mainActions.updateProvider,
  getChecklist: mainActions.getChecklist,
  hideOnboarding: authActions.hideOnboarding,
};

export default connect(mapStateToProps, actions)(Dashboard);
