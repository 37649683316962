import React from 'react';
import { Button } from '@zencare/ui';

// interface Props {
//   submittable?: boolean;
//   buttonClass?: string;
//   buttonText?: string;
//   pristine?: boolean;
//   valid?: boolean;
//   handleSubmit?: $TSFixMeFunction;
//   children?: JSX.Element;
// }

/*
 * Submittable prop allows overriding of the pristine/valid behavior in cases where a form has
 * changed by is not dirtied (e.g. sortable)
 */
const SubmitButton = ({
  submittable = false,
  buttonClass = 'primary',
  buttonText = 'Submit',
  pristine,
  valid,
  handleSubmit,
  children,
}: $TSFixMe) => (
  <div className='actions'>
    {!submittable && (pristine || (!pristine && !valid)) ? (
      <Button className={buttonClass} disabled>
        {buttonText}
        {children}
      </Button>
    ) : (
      <Button className={buttonClass} onClick={handleSubmit}>
        {buttonText}
        {children}
      </Button>
    )}
  </div>
);

export default SubmitButton;
