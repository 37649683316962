import React from 'react';
import { connect } from 'react-redux';

const PhotoVideoshootIframe = ({ provider, type }) => {
  /* const { hasProfessionalSetup, hasPremiumSetup } = provider;
    const has75MinShoot = hasProfessionalSetup || hasPremiumSetup;
    */
  const has75MinShoot = true;

  const typeToIframe = {
    'new-photoshoot': has75MinShoot
      ? 'https://therapist.zencare.co/zencare-photovideoshoot-75min'
      : 'https://therapist.zencare.co/photo-videoshoot',
    reshoot: 'https://therapist.zencare.co/photo-video-reshoot',
    'remote-new-photoshoot': 'https://therapist.zencare.co/photo-video-reshoot',
    'reschedule-shoot': has75MinShoot
      ? 'https://therapist.zencare.co/zencare-photovideoshoot-75min'
      : 'https://therapist.zencare.co/photo-videoshoot',
    'remote-shoot': 'https://zencarephotoshoots.as.me/schedule.php?appointmentType=23612305',
  };

  if (!typeToIframe[type]) {
    return;
  }

  const id = 'photovideoshoot-iframe';
  /* eslint-disable*/
  return (
    <iframe
      id={id}
      style={{
        width: '100%',
        height: 'calc(100% - 120px)',
      }}
      title='Photo/Videoshoot'
      src={typeToIframe[type]}
    />
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

export default connect(mapStateToProps, null)(PhotoVideoshootIframe);
