import React from 'react';

export const TabSelect = ({ location, initialState, onSubmit }) => {
  const tabOptions = {
    accepting: 'Yes',
    waitlist: 'Waitlist',
    not_accepting: 'No',
  };

  const renderOptions = () =>
    Object.entries(tabOptions).map(([key, val], i) => (
      <div key={i} className='container'>
        <input
          type='radio'
          component='input'
          id={`${location.id}-${location.location_name.toLowerCase()}_${key.toLowerCase()}`}
          value={`${key.toLowerCase()}`}
          checked={initialState === key}
          name={`${location.id}-${location.location_name.toLowerCase()}_availability_status`}
          onChange={(e) => onSubmit(e.currentTarget.value)}
        />
        <label
          htmlFor={`${location.id}-${location.location_name.toLowerCase()}_${key.toLowerCase()}`}
        >
          {val}
        </label>
      </div>
    ));

  return <div className='tab-select'>{renderOptions()}</div>;
};

export default TabSelect;
