import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import providerName from '#/providerName';
import { actions as mainActions } from '../sagaSlice';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import { useSelector, useDispatch } from 'react-redux';
import SectionLoader from '~/shared/forms/SectionLoader';

const NewMessageCount = ({ provider }: { provider: Provider }) => {
  const newMessageCount = provider.unread_message_count ? provider.unread_message_count : 0;
  const newMessageCountText = `${newMessageCount} New Message${newMessageCount > 1 ? `s` : ``}`;

  if (!newMessageCount || provider.status !== 'active') return null;
  return (
    <p className='m-b-0 bold '>
      <i className='far fa-envelope nav-messages-icon p-r-xs new-message-count-envelope'>
        <span className='unread'></span>
      </i>
      {newMessageCountText}
    </p>
  );
};

const calculateOrder = (p: Provider) => {
  const { value } = valByStatus(p.status);

  // Order active providers by number of unread messages
  const unreadMessages = p.unread_message_count ? p.unread_message_count : 0;
  const isActive = p.status === 'active';
  const sortOrder = isActive ? value + unreadMessages : value;

  return sortOrder;
};

const valByStatus = (status: $TSFixMe) => {
  let color;
  let icon;
  let value;

  if (status === 'active') {
    color = 'primary';
    icon = 'check-circle';
    value = 3;
  } else if (status === 'inactive') {
    color = 'secondary';
    icon = 'times-circle';
    // Show inactive providers at the bottom of the list
    value = -2;
  } else if (['reviewing', 'incomplete'].includes(status)) {
    color = 'quaternary';
    icon = 'exclamation-circle';
    value = status === 'reviewing' ? 2 : 1;
  } else {
    value = -1;
  }

  return {
    color,
    icon,
    value,
  };
};

const StatusSpan = ({ provider }: { provider: Provider }) => {
  const { status } = provider;

  const { color, icon } = valByStatus(status);

  return (
    <p className={`text-${color} bold m-b-0`}>
      {icon && <i className={`far fa-${icon} p-r-xs`}></i>}Profile
      {status === 'active' ? ' Live' : ` ${status.charAt(0).toUpperCase()}${status.slice(1)}`}
    </p>
  );
};

const ProviderCard = ({
  cardProvider,
  selectedProvider,
}: {
  cardProvider: Provider;
  selectedProvider: Provider | null;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div
      className={`box no-mobile provider-select-toggle ${
        selectedProvider?.id === cardProvider.id ? 'selected' : ''
      }`}
      title={`View ${providerName(cardProvider, true)}'s profile`}
      role='presentation'
      onClick={(e) => {
        dispatch(mainActions.selectActiveProvider({ id: cardProvider.id }));
        // If the card is clicked, navigate to the home page of the portal.
        // Ignore button clicks as those are links themselves.
        if ((e.target as HTMLDivElement).tagName !== 'BUTTON') {
          history.push('/');
        }
      }}
    >
      <div className='flex align-center'>
        <img
          className='avatar border-primary md m-r-sm'
          src={
            _.get(cardProvider, 'image.key')
              ? imgix(cardProvider.image)
              : require('~/assets/placeholder.png')
          }
          alt={cardProvider.first_name}
        />
        <div>
          <h4 className='m-b-0'>{providerName(cardProvider)}</h4>
          <p className='m-b-0'>{_.get(cardProvider, 'primary_profession.name')}</p>
          <StatusSpan provider={cardProvider} />
          <NewMessageCount provider={cardProvider} />
        </div>
      </div>
      <div className='button-container'>
        {cardProvider.status === 'active' && (
          <Link to='/messages'>
            <button
              title=''
              className={`primary pill md m-r-xs ${
                cardProvider.unread_message_count ? '' : 'hollow'
              }`}
            >
              View Messages
            </button>
          </Link>
        )}
        <Link to={cardProvider.status === 'incomplete' ? `/` : '/profile/general-information'}>
          <button title='' className='primary pill hollow md'>
            Edit Profile
          </button>
        </Link>
      </div>
    </div>
  );
};

export const SwitchProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');
  const [showInactiveProviders, setShowInactiveProviders] = useState(false);

  const [filteredProviders, setFilteredProviders] = useState<Provider[]>([]);
  // const allProviders = useSelector((state) => state.main.allProviders) || {};
  const { allProviders, provider } = useSelector<State, MainState>((state) => state.main);

  useEffect(() => {
    dispatch(mainActions.getAllMyProviders());
    // getAllMyProviders();
  }, [dispatch]);

  if (!allProviders) {
    return (
      <div className='switch-profile-loader'>
        <SectionLoader />
      </div>
    );
  }

  if (allProviders && allProviders.length === 1) {
    history.push('/');
  }

  const switchableProviders = allProviders.filter((p: Provider) => p.hasClinicianAccess);

  const nonInactiveProviders = switchableProviders.filter((p: Provider) => p.status !== 'inactive');
  const inactiveProviders = allProviders.filter((p: Provider) => p.status === 'inactive');

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setInputValue(value);

    const filtered = allProviders
      .filter(
        (p) =>
          p.first_name?.toLowerCase().includes(value.toLowerCase()) ||
          p.last_name?.toLowerCase().includes(value.toLowerCase()) ||
          p.company_name?.toLowerCase().includes(value.toLowerCase())
      )
      .sort((p) => valByStatus(p.status).value);

    setFilteredProviders(filtered);
  };

  return (
    <div className='m-b-sm'>
      {provider && provider?.status === 'active' && (
        <>
          <div style={{ height: '46px' }}></div>
          <div className='switch-profiles-profile-is-live-bar'>
            Your profile is live! <Link to='/'>Edit your profile</Link>.
          </div>
        </>
      )}
      <h1 className='switch-profile-heading'>Choose a Profile</h1>
      <input
        type='text'
        placeholder='Search for Provider'
        className='switch-profile-search'
        value={inputValue}
        onChange={handleInputChange}
      />
      {inputValue && !filteredProviders.length ? (
        <h4 className='search-results-placeholder'>No results! Please search again...</h4>
      ) : inputValue ? (
        <div className='provider-select-toggle-grid'>
          {filteredProviders.map((p) => (
            <ProviderCard key={p.id} cardProvider={p} selectedProvider={provider} />
          ))}
        </div>
      ) : (
        <>
          <div className='provider-select-toggle-grid'>
            {_.orderBy(nonInactiveProviders, (p) => calculateOrder(p), 'desc').map((p) => (
              <ProviderCard key={p.id} cardProvider={p} selectedProvider={provider} />
            ))}
          </div>

          {inactiveProviders?.length > 0 ? (
            <>
              <h3 className='m-t-lg '>
                Inactive providers
                <button
                  className='m-l-sm button-as-link'
                  onClick={() => {
                    setShowInactiveProviders(!showInactiveProviders);
                  }}
                >
                  {showInactiveProviders ? 'Hide' : 'Show all'}
                </button>
              </h3>
              {showInactiveProviders ? (
                <div className='provider-select-toggle-grid'>
                  {_.orderBy(inactiveProviders, (p) => calculateOrder(p), 'desc').map((p) => (
                    <ProviderCard key={p.id} cardProvider={p} selectedProvider={provider} />
                  ))}
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default SwitchProfile;
