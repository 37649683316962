import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import AppointmentModal from '../provider/appointments/AppointmentModal';
import PracticeConversationList from './PracticeConversationList';
import PracticeCurrentConversation from './PracticeCurrentConversation';
import { Route } from 'react-router-dom';

export default function () {
  const provider = useSelector((state) => _.get(state, 'main.provider'));
  const { conversations } = useSelector((state) => state.practiceInbox);
  const account_id = provider.account_id;
  const dispatch = useDispatch();
  const [showConversation, setShowConversation] = useState(false);
  const LIMIT = 100;
  const [offset, setOffset] = useState(0);

  /**
   * Whereas single-provider level conversations are loaded when the provider is selected
   * we load account conversations via a useEffect when the Client Messages page loads
   * ---
   * We get the account ID off the currently selected provider assuming that the list
   * of conversations the authorized user would like to view are located within the account
   * of the currently selected provider
   */
  useEffect(() => {
    if (account_id && !(conversations.length > 0)) {
      dispatch(actions.getConversations({ account_id, limit: LIMIT, offset }));
      setOffset(offset + LIMIT);
    }
  }, [account_id, conversations]);

  const getConversations = () => {
    dispatch(actions.getConversations({ account_id, limit: LIMIT, offset }));
    setOffset(offset + LIMIT);
  };
  return (
    <>
      <h1 className='messages-text-content'>Practice Inbox | Client Messages</h1>
      <p className='messages-text-content'>
        Message any client who has contacted you or booked a phone consultation with you through
        Zencare.
      </p>
      <div className='box messages-box'>
        <PracticeConversationList
          showConversation={showConversation}
          setShowConversation={setShowConversation}
          getConversations={getConversations}
        />
        <Route
          exact
          path='/practice-inbox/messages'
          children={
            <PracticeCurrentConversation
              showConversation={showConversation}
              setShowConversation={setShowConversation}
            />
          }
        />
        <Route
          exact={false}
          path='/practice-inbox/messages/:clientId/:providerId'
          children={
            <PracticeCurrentConversation
              showConversation={showConversation}
              setShowConversation={setShowConversation}
            />
          }
        />
      </div>
      <AppointmentModal practiceInbox={true} />
    </>
  );
}
