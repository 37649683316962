import React from 'react';
import { useForm } from 'react-final-form';

function UserCounter({ value = 'count', unit = 'user' }: $TSFixMe) {
  const form = useForm();
  const displayValue = form.getState().values[value];
  const displayString = `${displayValue} ${unit}${displayValue > 1 ? 's' : ''}`;

  const incrementCount = (e: $TSFixMe) => {
    e.preventDefault();
    const currentCount = form.getState().values[value];
    return form.change(value, currentCount + 1);
  };
  const decrementCount = (e: $TSFixMe) => {
    e.preventDefault();
    const currentCount = form.getState().values[value];
    if (currentCount === 1) {
      return form.change(value, currentCount);
    }
    return form.change(value, currentCount - 1);
  };
  return (
    <div className='user-count-box'>
      <div className='flex align-center'>
        <p className=''>{displayString}</p>
      </div>
      <button className='hollow' onClick={incrementCount}>
        +
      </button>
      <button className='hollow' onClick={decrementCount}>
        -
      </button>
    </div>
  );
}

export default UserCounter;
