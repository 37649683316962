import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { useLocation } from 'react-router-dom';

import App from './App';
import store from './store';
import * as serviceWorker from '#/serviceWorker';
import history from '#/history';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const AppWrapper = (
  <Provider store={store}>
    <ConnectedRouter basename='' history={history}>
      <ScrollToTop />
      <App />
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(AppWrapper, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
