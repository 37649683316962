/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';

export const WebBadge = ({ provider }) => {
  const [textBlueButton, setTextBlueButton] = useState('Copy Code!');
  const [textWhiteButton, setTextWhiteButton] = useState('Copy Code!');

  const encodeParams = (color) =>
    encodeURIComponent(
      btoa(
        JSON.stringify({
          id: provider.id,
          color,
        })
      )
    );

  const onCopyTurquoise = () => {
    const el = document.createElement('textarea');
    el.value = document.getElementById('codeBlue').innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setTextBlueButton('Copied!');
    setTextWhiteButton('Copy Code!');
  };

  const onCopyWhite = () => {
    const el = document.createElement('textarea');
    el.value = document.getElementById('codeWhite').innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setTextWhiteButton('Copied!');
    setTextBlueButton('Copy Code!');
  };

  return (
    <div
      id='static-page'
      className='main-content web-badge'
      style={{
        maxWidth: '1080px',
        margin: '0 auto',
      }}
    >
      <h1 style={{ whiteSpace: 'pre-wrap' }}>Zencare web badge</h1>
      <p style={{ whiteSpace: 'pre-wrap' }}>
        Add your Zencare badge on your website to increase your search rankings! See below for our
        guide.
      </p>
      <p style={{ whiteSpace: 'pre-wrap' }}>
        Questions? Email{' '}
        <a href='mailto:support@zencare.co' rel='noopener noreferrer' target='_blank'>
          support@zencare.co
        </a>{' '}
        for help from our team!
      </p>
      <div id='app'>
        <section className='preview-section'>
          <div className='title-number'>
            <h2>Turquoise Web Badge</h2>
          </div>

          <div className='preview'>
            <iframe
              frameborder='0'
              title='Zencare Therapist Badge'
              style={{
                width: '250px',
                height: '150px',
                marginRight: '20px',
                border: 'none',
              }}
              src={`${process.env.ZENCARE_WEBSITE_URL}/embed/trust-badge?z=${encodeParams(
                'turquoise'
              )}`}
            ></iframe>
            <div className='code-container'>
              <code id='codeBlue' className='preview-area__code'>
                &lt;iframe title="Zencare Therapist Badge" style="width: 250px; height: 150px;
                border: none" src="
                {`${process.env.ZENCARE_WEBSITE_URL}/embed/trust-badge?z=${encodeParams(
                  'turquoise'
                )}`}
                "&gt;&lt;/iframe&gt;
              </code>
              <button
                data-clipboard-target='.preview-area__code'
                type='button'
                className='copy-btn'
                onClick={onCopyTurquoise}
              >
                {textBlueButton}
              </button>
            </div>
          </div>
        </section>

        <section className='preview-section white'>
          <div className='title-number'>
            <h2>White Web Badge</h2>
          </div>

          <div className='preview'>
            <iframe
              frameborder='0'
              title='Zencare Therapist Badge'
              style={{
                width: '250px',
                height: '150px',
                marginRight: '20px',
                border: 'none',
              }}
              src={`${process.env.ZENCARE_WEBSITE_URL}/embed/trust-badge?z=${encodeParams(
                'white'
              )}`}
            ></iframe>
            <div className='code-container'>
              <code id='codeWhite' className='preview-area__code'>
                &lt;iframe title="Zencare Therapist Badge" style="width: 250px; height: 150px;
                border: none" src="
                {`${process.env.ZENCARE_WEBSITE_URL}/embed/trust-badge?z=${encodeParams('white')}`}
                "&gt;&lt;/iframe&gt;
              </code>
              <button
                data-clipboard-target='.preview-area__code'
                type='button'
                className='copy-btn'
                onClick={onCopyWhite}
              >
                {textWhiteButton}
              </button>
            </div>
          </div>
        </section>

        <div className='input-section'>
          <h2>How to upload code to website builders</h2>
          <ul
            style={{
              textAlign: 'left',
              marginBottom: 0,
            }}
          >
            <li>
              <b>Squarespace</b> -
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://support.squarespace.com/hc/en-us/articles/206543167-Using-Code-Blocks'
              >
                Add a Code Block.
              </a>
            </li>
            <li>
              <b>Wix</b> -
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://support.wix.com/en/article/adding-html-code'
              >
                Add HTML Code.
              </a>
            </li>
            <li>
              <b>WordPress</b> -
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://en.support.wordpress.com/wordpress-editor/blocks/code-block/'
              >
                Add a Code Block.
              </a>
            </li>
            <li>
              <b>Weebly</b> -
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://weeblytutorials.com/add-html-to-weebly/'
              >
                Add the Embed Code element.
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, main }) => ({
  provider: main.provider,
});

export default connect(mapStateToProps, null)(WebBadge);
