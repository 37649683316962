import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  commaSeparateValues,
  calculateEffectiveSessionFeeHigherRange,
  calculateEffectiveSessionFeeLowerRange,
} from './reimbursifyUtils';

function getFortnightsLeftInYear() {
  // Get the current date
  const currentDate = new Date();
  // Get the start of the year
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  // Calculate the difference in milliseconds between the current date and the start of the year
  const timeDiff = currentDate.getTime() - startOfYear.getTime();
  // Calculate how far into the year we are
  const weeksIntoYear = Math.floor(timeDiff / 1000 / 60 / 60 / 24 / 7);
  const weeksInAYear = 52;
  // Calculate how many two-week periods (fortnights) are left in the year and return
  return (weeksInAYear - weeksIntoYear) / 2;
}

interface Props {
  vobData: UserVob;
}
function SampleVobMessage({ vobData }: Props) {
  const {
    estimated_reimbursement_after_deductible_percent,
    oon_individual_deductible_remaining_dollars,
    oon_family_deductible_remaining_dollars,
    status,
  } = vobData;

  const { provider } = useSelector((state: $TSFixMe) => state.main);
  const { session_fee_ongoing } = provider;

  if (!estimated_reimbursement_after_deductible_percent || status === 'vob_limit_reached') {
    return null;
  }

  // Calculate sample message values
  const percentCovered = estimated_reimbursement_after_deductible_percent;
  const deductibleRemaining =
    oon_individual_deductible_remaining_dollars || oon_family_deductible_remaining_dollars || 0;
  const remainingSessions = Math.ceil(
    deductibleRemaining ? deductibleRemaining / parseFloat(session_fee_ongoing) : 0
  );

  // Don't show this sample message if the remaining sessions amount exceeds fortnights left in year
  const hideSessionsLeft = remainingSessions > getFortnightsLeftInYear();

  // Calculate what a person will pay out of pocket as a percentage after reimbursement
  // Multiply the session cost by that percentage to get what a covered session will cost
  const outOfPocketPercent = (100 - estimated_reimbursement_after_deductible_percent) / 100;
  const coveredSessionCost = parseFloat(session_fee_ongoing) * outOfPocketPercent;

  const remainingSessionsIsPlural = remainingSessions > 1 ? 's' : '';
  const futureSessionCost = commaSeparateValues(coveredSessionCost);
  const formattedDeductibleRemaining = commaSeparateValues(deductibleRemaining);

  const lowRange = calculateEffectiveSessionFeeLowerRange(session_fee_ongoing, percentCovered);
  const highRange = calculateEffectiveSessionFeeHigherRange(session_fee_ongoing, percentCovered);
  const sessionsLeft = hideSessionsLeft
    ? ``
    : `, which you’ll hit after ${remainingSessions} session${remainingSessionsIsPlural} with me`;

  const sampleMessage = deductibleRemaining
    ? `It looks like your insurance will cover up to ${percentCovered}% of the session fee! Initially, our sessions will be $${session_fee_ongoing}. You have $${formattedDeductibleRemaining} of your deductible remaining${sessionsLeft}. Then you can get reimbursed, so your out-of-pocket cost will likely range between $${lowRange} and $${highRange} per session.`
    : `Great news! It looks like your insurance will cover up to ${percentCovered}% of the initial $${session_fee_ongoing} session fee! Your deductible has been met which means you can get reimbursed immediately, likely bringing you to $${futureSessionCost} per session.`;

  return (
    <div className='sample-vob-message'>
      <h6>Try sending a message like this:</h6>
      <p>{sampleMessage}</p>
      <p>
        This is a quick estimate, so please double-check with your insurance company to confirm.
      </p>
    </div>
  );
}

export default SampleVobMessage;
