/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';

import PureModal from '+/Modal';
import { useSelector, useDispatch } from 'react-redux';

import { Field, Form } from 'react-final-form';
import Input from '+/forms/Input';
import { actions as authActions } from '../../auth/sagaSlice';
interface Props {
  open: boolean;
  toggle: (value: boolean) => void;
}

interface FormValues {
  phone_number: string;
  secure_access_code: string;
}

export default function TwoFactorAuthEnrollModal({ open, toggle }: Props) {
  const [currentStep, setCurrentStep] = useState('enter_phone_number');
  const [pendingNewPhoneNumber, setPendingNewPhoneNumber] = useState('');
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false);
  const dispatch = useDispatch();

  const { isLoading, phone_number, phoneLastFour, phoneNumberSentCodeMatched } = useSelector<
    State,
    AuthState
  >((state) => state.auth);

  useEffect(() => {
    if (phoneLastFour && currentStep === 'loading_sending_secure_access_code') {
      setShowInvalidCodeError(false);
      setCurrentStep('enter_secure_access_code');
    }
  }, [currentStep, phoneLastFour]);

  useEffect(() => {
    if (currentStep === 'loading_verifying_secure_access_code') {
      if (phoneNumberSentCodeMatched !== null) {
        if (phoneNumberSentCodeMatched) {
          setCurrentStep('confirmed');
        } else {
          setCurrentStep('enter_phone_number');
          setShowInvalidCodeError(true);
        }
      }
    }
  }, [currentStep, phoneNumberSentCodeMatched]);

  const onSubmit = (values: FormValues) => {
    if (isLoading) {
      // eslint-disable-next-line no-console
      console.log('Server request loading. Ignoring click.');
    } else {
      switch (currentStep) {
        case 'enter_phone_number': {
          setCurrentStep('loading_sending_secure_access_code');
          setPendingNewPhoneNumber(values.phone_number);
          const sagaParam = { phone_number: values.phone_number };
          dispatch(authActions.verifyNewPhoneNumber(sagaParam));
          break;
        }

        case 'enter_secure_access_code': {
          setCurrentStep('loading_verifying_secure_access_code');
          const sagaParam = {
            phone_number: pendingNewPhoneNumber,
            token: values.secure_access_code,
          };
          dispatch(authActions.finalizeTwoFactorEnrollment(sagaParam));
          break;
        }

        case 'confirmed': {
          // Close modal
          toggle(false);
          setCurrentStep('enter_phone_number');
          break;
        }

        default:
          // eslint-disable-next-line no-console
          console.error('Invalid step');
      }
    }
  };

  return (
    <PureModal
      visible={open}
      additionalCssClasses=''
      closeModal={() => toggle(false)}
      headerContent={<h4>Set Up Two-Factor Authentication</h4>}
    >
      <>
        <Form
          onSubmit={onSubmit}
          validate={(values) => {
            const errors = {};

            return errors;
          }}
          render={({ handleSubmit }) => (
            <div className='flex column'>
              <div className='flex-1'>
                {currentStep === 'enter_phone_number' && (
                  <>
                    <h5>Enter/update your phone number</h5>
                    {showInvalidCodeError && (
                      <p className='text-error'>
                        The code you entered is not correct. Please confirm your phone number is
                        correct and try again on the next page.
                      </p>
                    )}

                    <p>
                      Enabling Two-Factor Authentication will send a single-use code to your phone
                      for you to enter when you sign in. This is an extra security measure that
                      makes it so if someone gets access to your password, it is not enough to get
                      into your account.
                    </p>
                    <p>
                      We will send a verification text and have you enter it on the next screen.
                    </p>

                    <Field
                      initialValue={phone_number}
                      name='phone_number'
                      isphone={true}
                      component={Input}
                      label='Phone Number'
                      inputStyle='w-100'
                      placeholder='e.g. (123) 456-7890'
                    />
                  </>
                )}

                {currentStep === 'enter_secure_access_code' && (
                  <>
                    <h5>Confirm the code sent to your phone number</h5>
                    <p>
                      Please enter the secure access code that was sent to (###) ###-{phoneLastFour}
                      .
                    </p>
                    <Field
                      name='secure_access_code'
                      component={Input}
                      label=''
                      inputStyle='w-100'
                      placeholder='Secure access code'
                    />
                  </>
                )}

                {currentStep === 'confirmed' && (
                  <>
                    <h5>Confirmed!</h5>
                    <p>You have successfully set up Two-Factor Authentication for your account.</p>
                  </>
                )}
              </div>
              <div className='align-self-end m-t-md'>
                {currentStep !== 'confirmed' && (
                  <button
                    onClick={() => {
                      setCurrentStep('enter_phone_number');
                      toggle(false);
                    }}
                    className='hollow m-r-md'
                  >
                    Cancel
                  </button>
                )}
                <button onClick={handleSubmit} className='primary'>
                  {isLoading ? (
                    <Loader type='TailSpin' color='white' height={20} width={20} />
                  ) : currentStep === 'confirmed' ? (
                    'Close'
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          )}
        />
      </>
    </PureModal>
  );
}
