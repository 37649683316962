/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as tagActions } from '../../taggings/sagaSlice';
import { actions as mainActions } from '../../../main/sagaSlice';
import TaggingCheckboxes from '../../taggings/TaggingCheckboxes';
import StaticProviderTags from '../../taggings/StaticProviderTags';
import SortableTagForm from '../../taggings/SortableTagForm';
import PureModal, { awaitModal } from '+/Modal';
import TypeAhead from '+/TypeAhead';
import Loader from '+/forms/SectionLoader';
import { dispatch } from '~/store';
import { flashError, flashWarning, flashSuccess } from '~/shared/flashes/redux';
import EditBox from '+/EditBox';
import _ from 'lodash';
import { maxCustomSpecialties, maxGeneralSpecialties, columns, editFormName } from './constants';
import tagTypeToPlural from '../../taggings/tagTypeToPlural';

//eslint-disable-next=line
export const Specialty = ({
  readEverySpecialty,
  specialties,
  provider,
  updateProvider,
  updateMultipleTaggings,
}) => {
  useEffect(() => {
    if (!Array.isArray(specialties.data)) {
      readEverySpecialty();
    }
  }, [readEverySpecialty]);

  const [editingCustomSpecialties, setEditingCustomSpecialties] = useState(false);
  const [editingGeneralSpecialties, setEditingGeneralSpecialties] = useState(false);

  if (!Array.isArray(specialties.data)) {
    return <Loader />;
  }

  function customSpecialtyTagExists(cs, specialties) {
    return specialties.data.find((s) => s.name.trim() === cs.name.trim());
  }

  /*
   * Updates the provider with the custom specialties. If any of the custom specialties are
   * actual specialty tags, try to associate them with the provider, up to the max. If the provider already has
   * the max, then issue a warning.
   *
   * Note that currently, the flashes are not associated with the result of the API calls, just the number of
   * taggings on the front end.
   */
  const updateCustomSpecialties = (customSpecialties) => {
    updateProvider({
      id: provider.id,
      custom_content: {
        custom_specialties: customSpecialties,
      },
    });
    let count = provider.specialties.length;
    const updates = [];
    const willNotUpdate = [];
    customSpecialties.forEach((cs) => {
      const tag = customSpecialtyTagExists(cs, specialties);
      if (tag && !provider.specialties.find((s) => s.id === tag.id)) {
        if (count < maxGeneralSpecialties) {
          count += 1;
          updates.push(tag);
        } else {
          willNotUpdate.push(tag);
        }
      }
    });

    function joinWithAnd(arr) {
      if (arr.length === 0) {
        return;
      }

      if (arr.length === 1) {
        return arr[0];
      }

      return `${arr.slice(0, arr.length - 1).join(', ')}${arr.length > 2 ? ',' : ''} and ${
        arr[arr.length - 1]
      }`;
    }

    updateMultipleTaggings({
      providerId: provider.id,
      entries: updates.map((u) => ({
        tagTypePlural: tagTypeToPlural(u.type),
        tagId: u.id,
        associate: true,
      })),
    });

    const customSpecialtyNames = joinWithAnd(customSpecialties.map((c) => `"${c.name.trim()}"`));
    const customAndGeneralNames = joinWithAnd(updates.map((c) => `"${c.name}"`));
    const willNotUpdateNames = joinWithAnd(willNotUpdate.map((c) => `"${c.name.trim()}"`));

    dispatch(
      flashSuccess(`
            Success! ${customSpecialtyNames} ${
        customSpecialties.length > 1 ? 'have' : 'has'
      } been added to your 
            Top 6 Specialties.${
              updates.length
                ? ` ${customAndGeneralNames} ${updates.length > 1 ? 'have' : 'has'} also been
            added to your General Areas of Expertise.`
                : ''
            }
        `)
    );

    if (willNotUpdate.length) {
      dispatch(
        flashWarning(`
                ${willNotUpdateNames} could not be added to your General Areas of Expertise because you've reached
                the maximum number (${maxGeneralSpecialties}). Please remove others to add more.
            `)
      );
    }

    setEditingCustomSpecialties(false);
  };

  const customSpecialties = _.get(provider, 'custom_content.custom_specialties', []).map((cs) => {
    if (customSpecialtyTagExists(cs, specialties)) {
      return {
        ...cs,
        nameReadOnly: true,
      };
    }

    return cs;
  });

  const namesOfCustomSpecialtiesThatMatchTags = [];
  _.get(provider, 'custom_content.custom_specialties', []).forEach((cs) => {
    if (customSpecialtyTagExists(cs, specialties)) {
      namesOfCustomSpecialtiesThatMatchTags.push(cs.name.trim());
    }
  });

  const beforeDelete = () =>
    new Promise((resolve, reject) => {
      window.confirm("Are you sure you'd like to delete this top specialty?")
        ? resolve()
        : reject();
    });

  const beforeAdd = (values) =>
    new Promise((resolve, reject) => {
      if (values[editFormName].length >= maxCustomSpecialties) {
        return reject(
          dispatch(
            flashError(`
                You've reached the maximum number of specialties (${maxCustomSpecialties}). 
                Please delete others to add more.
            `)
          )
        );
      }

      resolve(awaitModal(createModal, '#modal-container', values));
    });

  /*
   * Creates a modal that allows the user to select a pre-existing
   * specialty or select 'custom'. If closed, noop. If submitted,
   * the specialty is added to the list of custom specialties
   */
  const createModal = ({ onSubmit, onClose, visible, values }) => {
    let modalValue = -1;
    const customItem = {
      name: 'Custom Specialty Area',
      id: -1,
    };

    if (!Array.isArray(specialties.data)) {
      return <Loader />;
    }

    // Don't show specialties which are already selected as options.
    const unselectedSpecialties = specialties.data.filter((s) =>
      _.isNil(_.find(values['custom-specialties'], (cs) => cs.name.trim() === s.name.trim()))
    );

    return (
      <PureModal
        visible={visible}
        headerContent={<h4>Select Top Specialty Areas</h4>}
        closeModal={() => onClose()}
      >
        <div className='specialty-modal-interior'>
          <h5>Add Specialty</h5>
          <div className='typeahead-container'>
            <TypeAhead
              field='name'
              className='specialty-typeahead'
              selectSingle={true}
              criteria={() => true}
              items={[customItem].concat(unselectedSpecialties)}
              selected={[]}
              placeholder='Select specialty'
              errorItem={customItem}
              onSelect={(i) => (modalValue = i)}
            />
            <button
              onClick={() => {
                if (modalValue === -1) {
                  return onSubmit({
                    name: '',
                    description: '',
                    nameReadOnly: false,
                  });
                }
                const { name, description } = specialties.data.find((s) => s.id === modalValue);
                onSubmit({
                  nameReadOnly: true,
                  name,
                  description,
                });
              }}
              className='primary'
            >
              Add Specialty
            </button>
          </div>
        </div>
      </PureModal>
    );
  };

  return (
    <div>
      <h1 className='m-b-md'>Specialties</h1>
      <EditBox
        title='Top 6 Specialty Areas'
        editable={true}
        description={`These specialties are indicated at the top of your profile. 
                    They do not affect the specialty filters for which your practice displays on the search page.`}
        editing={editingCustomSpecialties}
        setEditing={setEditingCustomSpecialties}
        innerStatic={customSpecialties.map((c) => (
          <div className='sortable-tag-form-label' key={c.name}>
            <h5 className='name'>{c.name}</h5>
            <div>{c.description}</div>
          </div>
        ))}
        innerEditing={
          <SortableTagForm
            beforeDelete={beforeDelete}
            beforeAdd={beforeAdd}
            name={editFormName}
            onEdit={updateCustomSpecialties}
            dataList={customSpecialties}
            columns={columns}
            addText='Top Specialty'
          />
        }
      />
      <EditBox
        title='General Expertise Areas'
        boxClassName='checkbox-container'
        description={`Select up to ${maxGeneralSpecialties} areas in which you have expertise, training,
                and would currently see clients. These determine the specialty search filters your profile is set to appear under.`}
        editable={true}
        editing={editingGeneralSpecialties}
        setEditing={setEditingGeneralSpecialties}
        innerStatic={<StaticProviderTags tagKey='specialties' provider={provider} />}
        innerEditing={
          <TaggingCheckboxes
            tagKey='specialties'
            tagData={specialties.data}
            provider={provider}
            minSelections={3}
            maxSelections={20}
            setEditing={setEditingGeneralSpecialties}
            readOnlyTagNames={namesOfCustomSpecialtiesThatMatchTags}
            disabledTooltip="Areas selected in Top Specialties can't be removed"
          />
        }
      />
      <div id='modal-container'></div>
    </div>
  );
};

const mapStateToProps = ({ specialties, main, auth }) => ({
  specialties: specialties,
  provider: main.provider,
});

const actions = {
  readEverySpecialty: tagActions.specialties.readEvery,
  updateProvider: mainActions.updateProvider,
  updateMultipleTaggings: mainActions.updateMultipleTaggings,
};

export default connect(mapStateToProps, actions)(Specialty);
