import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import useSortable from '+/hooks/useSortable';
import { Link } from 'react-router-dom';
import SubmitButton from '+/forms/SubmitButton';
import { Form } from 'react-final-form';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import { actions as mainActions } from '../../../main/sagaSlice';
import providerName from '#/providerName';

export default connect(({ main }) => ({ allProviders: main.allProviders }), {
  dissociateProvider: mainActions.dissociateProvider,
})(({ provider: practice, providers, allProviders, submit, cancel, dissociateProvider }) => {
  const name = 'registered';
  const [listRef, mutators, resort] = useSortable(name);

  const initialValues = {
    [name]: providers.map((p, oldIdx) => ({
      ...p,
      oldIdx,
    })),
  };

  // key is provider id, value is sort order
  const onSubmit = (values) => {
    // providers which were in the initial values but are no longer in the values to be submitted.
    const providersToDelete = initialValues[name].filter(
      (op) => !values[name].map((p) => p.id).includes(op.id)
    );
    if (providersToDelete.length) {
      if (
        !window.confirm(`By submitting, you will be removing the following providers from your group practice profile:
            ${providersToDelete
              .map((p) => providerName(p))
              .join(
                ', '
              )}\nThis action will not affect billing or plan management. If you wish to re-add these providers to your group practice page at any point, contact the Zencare team.
            `)
      ) {
        return cancel();
      }

      for (const provider of providersToDelete) {
        dissociateProvider({
          practice_id: practice.id,
          provider_id: provider.id,
        });
      }
    }

    return submit(
      values[name].reduce(
        (acc, cur, i) => ({
          ...acc,
          [cur.id]: i,
        }),
        {}
      )
    );
  };

  const accessibleProviders = allProviders.filter((p) => p.hasClinicianAccess);

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        resort,
        remove: ([index], state, { changeValue }) => {
          changeValue(state, name, (oldVal) => {
            const arr = [...oldVal];
            arr.splice(index, 1);

            return arr;
          });
        },
      }}
      initialValues={initialValues}
      initialValuesEqual={() => true}
      render={({ handleSubmit, values, form }) => {
        mutators.current = form.mutators;

        return (
          <>
            <div className='sortable-tag-form'>
              <div className='sortable' ref={listRef}>
                {values[name].map((v, i) => (
                  <div
                    style={{ height: '8rem' }}
                    className='sortable-tag-form-field-group box no-mobile'
                    key={v.oldIdx}
                    handle={v.oldIdx}
                  >
                    <div className='handle' style={{ height: '8rem' }}>
                      <i className='fas fa-ellipsis-v'></i>
                      <i className='fas fa-ellipsis-v'></i>
                    </div>
                    <div className='provider-avatar-container flex w-100 h-100 align-center'>
                      <img
                        className='avatar border-primary md m-l-md m-b-0 m-r-sm'
                        src={imgix(v.image)}
                        alt={v.first_name}
                      />
                      <div className='flex column'>
                        <h5 className='m-b-0'>{`${v.first_name} ${v.last_name}`}</h5>
                        <span>{_.get(v, 'primary_profession.name')}</span>
                      </div>
                    </div>
                    <div
                      className='delete'
                      role='presentation'
                      onClick={() => {
                        mutators.current.remove(i);
                      }}
                    >
                      <i className='far fa-minus-circle'></i>
                    </div>
                  </div>
                ))}
              </div>
              <div className='announcement m-b-md'>
                <p className='p-b-sm'>
                  Create a new Zencare profile for a clinician in your group practice{' '}
                  <Link to='/profiles/create'>here</Link>.
                </p>
                {accessibleProviders.length > 1 && (
                  <p className='p-b-sm'>
                    To make changes to a particular profile within your group practice,{' '}
                    <Link to='/profiles'>switch to that profile</Link> or{' '}
                    <Link to='/contact'>request editing access.</Link>
                  </p>
                )}
                {accessibleProviders.length <= 1 && (
                  <p className='p-b-sm'>
                    To make changes to a particular profile within your group practice,{' '}
                    <Link to='/contact'>request editing access.</Link>
                  </p>
                )}
              </div>
            </div>
            <SubmitButton
              pristine={false}
              valid={true}
              handleSubmit={handleSubmit}
              submittable={!_.isEqual(values, initialValues)}
            />
          </>
        );
      }}
    />
  );
});
