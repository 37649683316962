import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';

import { useSelector, useDispatch } from 'react-redux';

import { Field, Form } from 'react-final-form';
import Input from '+/forms/Input';
import { actions as authActions } from '../../auth/sagaSlice';

interface FormValues {
  phone_number: string;
}

export default function EditPhoneNumberForm() {
  const dispatch = useDispatch();

  const { isLoading, phone_number } = useSelector<State, AuthState>((state) => state.auth);

  const onSubmit = (values: FormValues) => {
    if (isLoading) {
      // eslint-disable-next-line no-console
      console.log('Server request loading. Ignoring click.');
    } else {
      const sagaParam = { phone_number: values.phone_number };
      dispatch(authActions.updatePhone(sagaParam));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};

        return errors;
      }}
      render={({ handleSubmit }) => (
        <div className='flex column'>
          <div className='flex-1'>
            <>
              <h5>Update your phone number</h5>

              <Field
                initialValue={phone_number}
                name='phone_number'
                isphone={true}
                component={Input}
                label='Phone Number'
                inputStyle='w-100'
                placeholder='e.g. (123) 456-7890'
              />
            </>
          </div>
          <div className='align-self-end m-t-md'>
            <button onClick={handleSubmit} className='primary'>
              {isLoading ? (
                <Loader type='TailSpin' color='white' height={20} width={20} />
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      )}
    />
  );
}
