/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ReactTooltip from 'react-tooltip';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import { actions as tagActions } from '@/provider/taggings/sagaSlice';
import { actions as mainActions } from '@/main/sagaSlice';
import { actions as onboardingActions } from './sagaSlice';
import FormCheckbox from '+/forms/FormCheckbox';
import Loader from '+/forms/SectionLoader';
import StartMembershipButton from './StartMembershipButton';

import _ from 'lodash';
import TypeAhead from '+/TypeAhead';
const OTHER_SENTINEL = 'Other';
const shePronouns = 'She/Her/Hers';
const hePronouns = 'He/Him/His';
const theyPronouns = 'They/Them/Theirs';

const curYear = new Date().getUTCFullYear();
const yearOptions = [...Array(70).keys()].map((x) => curYear + 20 - x);

interface Props {
  onboarding: $TSFixMe;
  professions: $TSFixMe;
  readEveryProfession: $TSFixMe;
  degrees: $TSFixMe;
  readEveryDegree: $TSFixMe;
  identities: $TSFixMe;
  readEveryIdentity: $TSFixMe;
  genders: $TSFixMe;
  readEveryGender: $TSFixMe;
  submitOnboardingAboutYouInfo: $TSFixMe;
  createForSubmitOnboardingAboutYouInfo: $TSFixMe;
  getMe: $TSFixMe;
  getAllAccountDetails: $TSFixMe;
  providerId: number;
  main: $TSFixMe;
  associatedTags: TagRelation[];
  getAssociatedTags: $TSFixMe;
}

const AboutYouForm = ({
  onboarding,
  professions,
  readEveryProfession,
  degrees,
  readEveryDegree,
  identities,
  readEveryIdentity,
  genders,
  readEveryGender,
  submitOnboardingAboutYouInfo,
  createForSubmitOnboardingAboutYouInfo,
  getMe,
  getAllAccountDetails,
  providerId,
  main,
  associatedTags,
  getAssociatedTags,
}: Props) => {
  const routerParams: $TSFixMe = useParams();
  const [gendersDisabled, setGendersDisabled] = useState(false);
  const [showPocCallout, setShowPocCallout] = useState(false);
  const deselectedTags: number[] = [];

  useEffect(() => {
    getMe();
    getAllAccountDetails();
    readEveryProfession();
    readEveryDegree();
    readEveryIdentity();
    readEveryGender();
    getAssociatedTags();
  }, [
    getMe,
    getAllAccountDetails,
    readEveryProfession,
    readEveryIdentity,
    readEveryDegree,
    readEveryGender,
    getAssociatedTags,
  ]);

  const accounts = _.get(main, 'accounts', []).filter((a: Account) => !a.error);

  const provider =
    main.allProviders.find((p: Provider) => p.id === providerId) ||
    _.flatten(accounts.map((a: Account) => a.providers)).find(
      (p: $TSFixMe) => p.id === providerId
    ) ||
    main.provider;
  // kinda gross but
  const separatedProfessionData: $TSFixMe[] = [];
  const professionData = Array.isArray(professions.data)
    ? _.orderBy(professions.data, 'name', 'asc').reduce((acc, cur) => {
        const tn = cur.name.trim();
        if (
          ![
            'Therapist',
            'Medication prescriber',
            'Dietitian',
            'Group practice',
            'Treatment center',
          ].includes(tn)
        ) {
          acc.push(cur);
        }

        if (tn === 'Therapist') {
          separatedProfessionData.push({
            ...cur,
            name: 'Therapy sessions',
          });
        }

        if (tn === 'Medication prescriber') {
          separatedProfessionData.push({
            ...cur,
            name: 'Medication management',
          });
        }

        return acc;
      }, [])
    : [];
  const degreeData = Array.isArray(degrees.data)
    ? _.orderBy(
        degrees.data.filter((d: $TSFixMe) => !d.name.includes('Life')),
        'name',
        'asc'
      )
    : [];
  const identityData = Array.isArray(identities.data)
    ? _.orderBy(identities.data, 'name', 'asc')
    : [];
  const genderData = Array.isArray(genders.data) ? _.orderBy(genders.data, 'name', 'asc') : [];

  if (!provider) {
    return 'Loading...';
  }

  const states = _.uniq(provider.locations.map((x: $TSFixMe) => x.state));

  const prepareLicencesForSubmit = (values: $TSFixMe) => {
    const licenses: $TSFixMe = [];
    const licenseFields = ['license_type', 'number', 'expiration'];
    states.forEach((state, i) => {
      const license: $TSFixMe = {
        id: null,
        state,
      };
      licenseFields.forEach((field) => {
        const valueKey = `license_${field}_${i}`;
        if (field === 'expiration') {
          license[field] = parseInt(values[valueKey]);
        } else {
          license[field] = values[valueKey];
        }
      });
      licenses.push(license);
    });

    return licenses;
  };

  const onSubmit = (values: $TSFixMe) => {
    const licenses = prepareLicencesForSubmit(values);
    const genders = genderData.filter((g) => values[`g${g.id}`]).map((g) => g.id);
    const professions = values.services.concat(values.professions);

    let pronouns = null;
    if (values.pr === OTHER_SENTINEL) {
      pronouns = values.other_pr;
    } else {
      pronouns = values.pr;
    }

    //eslint-disable-next-line
    const { degrees, identities } = values;
    const payload = {
      provider_id: provider.id,
      genders,
      degrees,
      professions,
      identities,
      pronouns,
      licenses,
    };

    // If in 'create for' mode inside portal
    if (routerParams.providerId) {
      createForSubmitOnboardingAboutYouInfo(payload);
    } else {
      submitOnboardingAboutYouInfo(payload);
    }
  };

  if ((onboarding && onboarding.isLoading) || !provider) {
    return (
      <div key='info-form'>
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      </div>
    );
  }

  const isPractice = provider.type === 'practice';
  const isIndividual = provider.type === 'individual';
  const youPhrase = isPractice ? 'your providers' : 'you';

  const deselectGenders = (e: $TSFixMe, form: $TSFixMe) => {
    if (e.target.checked) {
      genderData.forEach((g) => {
        form.change(`g${g.id}`, undefined);
      });
    }
  };

  function handleDeselect(item: $TSFixMe) {
    deselectedTags.push(item);
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        setField: (
          [name, val]: [string, number[]],
          state: object,
          { changeValue }: { changeValue: Function }
        ) => {
          const associatedTagIds = associatedTags
            // Filter out tag associations that are not associated with the tag being selected
            .filter(
              (tagAssociation) =>
                // Match all tag associations where the child tag is the one being selected
                val.includes(Number(tagAssociation.child_tag_id)) &&
                // And the parent tag is not already deselected
                !deselectedTags.includes(Number(tagAssociation.parent_tag_id))
            )
            // Map to the parent tag ids
            .map((tagAssociation) => Number(tagAssociation.parent_tag_id));
          // Add the parent tag ids to the values
          if (associatedTagIds.length > 0) {
            setShowPocCallout(true);
          }
          changeValue(state, name, () => [...val, ...associatedTagIds]);
        },
      }}
      validate={(values) => {
        const errors: $TSFixMe = {};
        const requiredFields = ['services', 'professions'];
        const selectedGenders = genderData.filter((g) => values[`g${g.id}`]).map((g) => g.id);

        if (isIndividual) {
          requiredFields.push('degrees');
        }

        // Genders are stored on values differently than other values
        // Genders is a required field
        if (values['gender-opt-out']) {
          setGendersDisabled(true);
        } else {
          setGendersDisabled(false);
        }
        if (selectedGenders.length === 0 && !values['gender-opt-out']) {
          errors['genders'] = 'Please select one or more genders.';
        }

        requiredFields.forEach((f) => {
          if (!values[f] || !values[f].length) {
            errors[f] = `Please select one or more ${f}.`;
          }
        });

        const licenseFields = ['number', 'expiration'];
        states.forEach((state, i) => {
          licenseFields.forEach((f) => {
            const valueKey = `license_${f}_${i}`;
            if (!values[valueKey] || !values[valueKey].length) {
              errors[valueKey] = `License ${f} required.`;
            } else if (f === 'number' && values.license && values.license[valueKey].length > 60) {
              errors[valueKey] = 'License number has a max length of 60 characters.';
            }
          });

          const licenseTypeValueKey = `license_license_type_${i}`;
          if (!values[licenseTypeValueKey] || !values[licenseTypeValueKey].length) {
            errors[licenseTypeValueKey] = 'License type required.';
          }
        });

        return errors;
      }}
      render={(props) => (
        <div key='info-form'>
          <div className='box m-b-md'>
            {isIndividual && <h2 className='m-b-sm'>About You</h2>}
            {isPractice && <h2 className='m-b-0'>About the head clinician of your practice</h2>}
            <div className='flex row justify-between m-b-sm wrap tab-container'>
              <div className='field m-b-md w-49'>
                <h5 className='m-b-0'>Which of these services do {youPhrase} offer?*</h5>
                <p className='description'>Select any that apply!</p>
                <TypeAhead
                  handleDeselect={handleDeselect}
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={separatedProfessionData}
                  selected={props.values.services || []}
                  onSelect={props.form.mutators.setField.bind(null, 'services')}
                  placeholder='Select services'
                />
                <ErrorField name='services' />
              </div>
              <div className='field w-49 m-b-md'>
                <h5 className='m-b-0'>Which of these professions apply to {youPhrase}?*</h5>
                <p className='description'>Select any that apply!</p>
                <TypeAhead
                  handleDeselect={handleDeselect}
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={professionData}
                  selected={props.values.professions || []}
                  onSelect={props.form.mutators.setField.bind(null, 'professions')}
                  placeholder='Select professions'
                />
                <ErrorField name='professions' />
              </div>

              <div className='field w-49 m-b-md'>
                <h5 className='m-b-0'>What degrees have {youPhrase} earned?*</h5>
                <p className='description'>Select any that apply!</p>
                <TypeAhead
                  handleDeselect={handleDeselect}
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={degreeData}
                  selected={props.values.degrees || []}
                  onSelect={props.form.mutators.setField.bind(null, 'degrees')}
                  placeholder='Select degrees'
                />
                <ErrorField name='degrees' />
              </div>
              <div className='field w-49 m-b-md'>
                {isIndividual && (
                  <>
                    <span className='flex align-center m-b-0'>
                      <h5 className='m-b-0'>What identities do you openly identify with?</h5>
                      <i
                        className='fas fa-info-circle m-l-xs text-tertiary'
                        data-for='identity-tooltip'
                        data-tip={
                          'For LGBTQ folks and people of color, provider identity is one of the top criteria in searching for a therapist. Please only indicate what you feel comfortable sharing.'
                        }
                      ></i>
                    </span>
                    <p className='description'>
                      Please indicate your identities, not those of clients you see.
                    </p>
                  </>
                )}
                {isPractice && (
                  <>
                    <span className='flex align-center m-b-0'>
                      <h5 className='m-b-0'>
                        What identities does your head clinician openly identify with?
                      </h5>
                      <i
                        className='fas fa-info-circle m-l-xs text-tertiary'
                        data-for='identity-tooltip'
                        data-tip={
                          'For LGBTQ folks and people of color, provider identity is one of the top criteria in searching for a therapist. Please only indicate what you feel comfortable sharing.'
                        }
                      ></i>
                    </span>
                    <p className='description'>
                      Please indicate your head clinician's identities, not those of clients you see
                      or of other clinicians in your practice.
                    </p>
                  </>
                )}

                <TypeAhead
                  handleDeselect={handleDeselect}
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={identityData}
                  selected={props.values.identities || []}
                  onSelect={props.form.mutators.setField.bind(null, 'identities')}
                  placeholder='Select identities'
                />
                <ErrorField name='identities' />
                <p className={`poc-callout ${showPocCallout ? '' : 'hide'}`}>
                  We've added the POC tag for you! If this doesn't apply, you can simply remove it.
                </p>
                <p className='m-t-xs italic gray'>
                  Don’t see your identity listed?{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='mailto:support@zencare.co?subject=Request to Add an Additional Identity'
                  >
                    Send us a suggestion!
                  </a>{' '}
                  Note that we might not be able to add it right away.
                </p>
                <ReactTooltip
                  id='identity-tooltip'
                  effect='solid'
                  place='top'
                  type='light'
                  arrowColor='#37BEC3'
                ></ReactTooltip>
              </div>

              <div className='field w-49 m-b-md'>
                {isIndividual && (
                  <>
                    <h5 className='m-b-0'>What is your gender identity?*</h5>
                    <p className='description'>
                      Please indicate your gender identities, not those of clients you see.
                    </p>
                  </>
                )}
                {isPractice && (
                  <>
                    <h5 className='m-b-0'>What are your head clinician's gender identities?</h5>
                    <p className='description'>
                      Please indicate your head clinician's genders, not those of clients you see or
                      of other clinicians in your practice.
                    </p>
                  </>
                )}

                <div className='flex column justify-between'>
                  {genderData.map((g) => (
                    <Field key={g.id} name={`g${g.id}`}>
                      {(props) => (
                        <FormCheckbox
                          fieldClassName='m-b-xs'
                          {...props}
                          label={g.name}
                          disabled={gendersDisabled}
                          labelStyle={gendersDisabled ? { opacity: 0.5 } : {}}
                        />
                      )}
                    </Field>
                  ))}
                  <Field name='gender-opt-out'>
                    {(input) => (
                      <FormCheckbox
                        fieldClassName='m-b-xs '
                        {...input}
                        label='Prefer not to say'
                        mutator={(e: Event) => deselectGenders(e, props.form)}
                      />
                    )}
                  </Field>
                  {gendersDisabled && (
                    <p className='italic'>
                      Please note that your profile will not show up on any gender-specific search
                      results.
                    </p>
                  )}
                </div>
                <ErrorField name='genders' />
              </div>

              <div className='field w-49 m-b-md'>
                <div className='flex column justify-between'>
                  <div>
                    {isIndividual && (
                      <>
                        <h5>Which pronouns do you use?</h5>
                        <p className='instruction m-b-md'>
                          Please select the pronouns you use, not those of the clients you see.
                        </p>
                      </>
                    )}
                    {isPractice && (
                      <>
                        <h5>What are your head clinician's pronouns?</h5>
                        <p className='instruction m-b-md'>
                          Please indicate your head clinician's pronouns, not those of clients you
                          see or of other clinicians in your practice.
                        </p>
                      </>
                    )}

                    <div className='flex column pronoun-fields'>
                      <div className='flex align-baseline'>
                        <Field
                          name='pr'
                          component='input'
                          type='radio'
                          value={shePronouns}
                          id='she-pronouns'
                        />
                        <label className='m-b-sm' htmlFor='she-pronouns'>
                          {shePronouns}
                        </label>
                      </div>
                      <div className='flex align-baseline'>
                        <Field
                          name='pr'
                          component='input'
                          type='radio'
                          value={hePronouns}
                          id='he-pronouns'
                        />
                        <label className='m-b-sm' htmlFor='he-pronouns'>
                          {hePronouns}
                        </label>
                      </div>
                      <div className='flex align-baseline'>
                        <Field
                          name='pr'
                          component='input'
                          type='radio'
                          value={theyPronouns}
                          id='they-pronouns'
                        />
                        <label className='m-b-sm' htmlFor='they-pronouns'>
                          {theyPronouns}
                        </label>
                      </div>
                      <div className='flex align-baseline m-b-sm'>
                        <Field
                          name='pr'
                          component='input'
                          type='radio'
                          value={OTHER_SENTINEL}
                          id='other-pronouns'
                        />
                        <label className='m-b-sm m-r-sm' htmlFor='other-pronouns'>
                          Other
                        </label>
                        <div className='flex column'>
                          <Field id='custom-pronoun-input' name='other_pr' component='input' />
                          <ErrorField name='other_pr' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='box m-b-md'>
            <h2 className='m-b-sm'>Licensure</h2>
            {isIndividual && (
              <p className='instruction'>
                Please indicate at least one license for the state(s) in which you practice
              </p>
            )}
            {isPractice && (
              <p className='instruction'>
                Please indicate at least one license for the state(s) in which you practice
              </p>
            )}
            <table className='mobile-hide onboarding-license-table'>
              <thead>
                <tr>
                  <td>
                    <h6 className='m-b-0'>License State</h6>
                  </td>
                  <td>
                    <h6 className='m-b-0'>License Number*</h6>
                  </td>
                  <td>
                    <h6 className='m-b-0'>License Type*</h6>
                  </td>
                  <td>
                    <h6 className='m-b-0'>License Expiration Year*</h6>
                  </td>
                </tr>
              </thead>
              <tbody>
                {states.map((state: $TSFixMe, i) => (
                  <tr key={i}>
                    <td>
                      <div className='onboarding-license-state'>{state}</div>
                    </td>
                    <td>
                      <Field
                        name={`license_number_${i}`}
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='License number'
                      />
                      <ErrorField name={`license_number_${i}`} />
                    </td>
                    <td>
                      <Field
                        name={`license_license_type_${i}`}
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. PhD, LCSW, LCAT'
                      />
                      <ErrorField name={`license_license_type_${i}`} />
                    </td>
                    <td>
                      <Field
                        name={`license_expiration_${i}`}
                        component='select'
                        className='license-expiration-selector'
                        placeholder=''
                        type='select'
                      >
                        <option value='' disabled selected hidden>
                          Select year
                        </option>
                        {yearOptions.map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))}
                        <i className='fas fa-caret-down'></i>
                      </Field>
                      <div className='license-expiration-error'>
                        <ErrorField name={`license_expiration_${i}`} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className='mobile-only onboarding-license-table'>
              <thead>
                <tr>
                  <td>
                    <h6 className='m-b-0'>
                      License State <br />
                      &amp; Expiration Year
                    </h6>
                  </td>
                  <td>
                    <h6 className='m-b-0'>
                      License Number <br />
                      &amp; Type*
                    </h6>
                  </td>
                </tr>
              </thead>
              <tbody>
                {provider.locations.map((location: $TSFixMe, i: $TSFixMe) => (
                  <tr key={i}>
                    <td>
                      <div className='onboarding-license-state'>{location.state}</div>
                      <Field
                        name={`license_expiration_${i}`}
                        component='select'
                        placeholder=''
                        className='license-expiration-selector'
                        type='select'
                      >
                        <option value='' disabled selected hidden>
                          Select year
                        </option>
                        {yearOptions.map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))}
                        <i className='fas fa-caret-down'></i>
                      </Field>
                      <div className='license-expiration-error'>
                        <ErrorField name={`license_expiration_${i}`} />
                      </div>
                    </td>

                    <td>
                      <Field
                        name={`license_number_${i}`}
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='License number'
                      />
                      <ErrorField name={`license_number_${i}`} />

                      <Field
                        name={`license_type_${i}`}
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. PhD, LCSW, LCAT'
                      />
                      <ErrorField name={`license_type_${i}`} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='w-100 flex justify-center m-t-sm m-b-sm'>
            You can edit any of these fields later before going live!
          </div>
          <div className='flex justify-center'>
            {/* Note: We need to pass provider id as prop 
            in so we can set it to id of one being created */}
            <StartMembershipButton providerId={provider.id} />
            <SubmitButton
              buttonClass='lg primary pill'
              buttonText='Save + Next'
              {...props}
              submittable={true}
            />
          </div>
        </div>
      )}
    />
  );
};

export default connect(
  ({ onboarding, professions, identities, degrees, genders, main, tags }: $TSFixMe): $TSFixMe =>
    //eslint-disable-next-line
    ({
      onboarding,
      professions,
      identities,
      degrees,
      genders,
      main,
      associatedTags: tags.associatedTags,
    }),
  {
    // @ts-expect-error
    readEveryProfession: tagActions.professions.readEvery,
    // @ts-expect-error
    readEveryDegree: tagActions.degrees.readEvery,
    // @ts-expect-error
    readEveryIdentity: tagActions.identities.readEvery,
    // @ts-expect-error
    readEveryGender: tagActions.genders.readEvery,
    submitOnboardingAboutYouInfo: onboardingActions.submitOnboardingAboutYouInfo,
    createForSubmitOnboardingAboutYouInfo: onboardingActions.createForSubmitOnboardingAboutYouInfo,
    getAssociatedTags: tagActions.generic.getAssociatedTags,
    getMe: mainActions.getMe,
    getAllAccountDetails: mainActions.getAllAccountDetails,
  }
  // @ts-expect-error
)(AboutYouForm);
