import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { actions as onboardingActions } from './sagaSlice';
import { actions as mainActions } from '@/main/sagaSlice';
import ProgressIndicator from './ProgressIndicator';
import Callout from './Callout';
import steps from './steps';
import history from '#/history';
import providerName from '~/utils/providerName';
import { getQueryAsObject } from '../../utils/queryParams';

interface Props {
  match: { params: { inviteToken: string } };
}

export const OnboardingContainer = ({ match }: Props) => {
  const inviteToken = _.get(match, 'params.inviteToken');
  const urlParams = getQueryAsObject(window.location.search);
  const partner = urlParams.partner;
  const { step: curStep } = useSelector((state: $TSFixMe) => state.onboarding);
  const { provider, licenses, accounts } = useSelector((state: $TSFixMe) => state.main);
  const { isSignedIn, roles } = useSelector((state: $TSFixMe) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSignedIn) return;

    dispatch(mainActions.getMe());
  }, [dispatch, isSignedIn]);

  useEffect(() => {
    if (!isSignedIn || !provider) return;

    dispatch((mainActions as $TSFixMe).getLicenses({ id: provider.id }));
  }, [dispatch, isSignedIn, provider]);

  useEffect(() => {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].checkFn(provider, roles, accounts, licenses)) {
        window.scrollTo({ top: 0 });
        return dispatch((onboardingActions as $TSFixMe).setStep(i));
      }
    }
    history.push('/');
  }, [isSignedIn, provider, roles, accounts, licenses, dispatch]);

  const StepComponent = steps[curStep].component as $TSFixMe;
  const stepName = steps[curStep].step;

  return (
    <div
      className={`onboarding-component-container ${
        stepName === 'Membership' ? 'onboarding-component-container-payment-screen' : ''
      } flex column align-center`}
    >
      <ProgressIndicator steps={steps} curStep={curStep} />
      <div
        style={{
          width: '90%',
          textAlign: 'left',
          maxWidth: '1200px',
        }}
      >
        {partner && partner === 'owl' && stepName === 'Create Account' && (
          <>
            <img src='/images/logos/therapyowl_logo.png' alt='Therapy Owl' />

            <p
              className='m-t-md m-b-md'
              style={{
                fontSize: '1.2rem',
              }}
            >
              TherapyOwl has partnered with Zencare to provide robust marketing and client referral
              support to therapists!
              <br />
              Sign up below for 25% off your first year of membership.
            </p>

            <div>
              <hr
                style={{
                  width: '230px',
                  marginLeft: 0,
                }}
              ></hr>
            </div>
          </>
        )}
        {stepName !== 'Go Live' &&
          (provider ? (
            <h1 className='m-b-md m-l-0'>
              Hello, {providerName(provider, true)}! <i className='fad fa-sparkles'></i>
              <br />
              <p className='heading-5 semi-bold'>We're excited to build your profile with you.</p>
            </h1>
          ) : (
            <h1 className='m-b-md m-l-0'>
              Welcome to Zencare! <i className='fad fa-sun'></i>
              <br />
              <p className='heading-5 semi-bold'>
                {' '}
                {inviteToken
                  ? "It looks like one of your colleagues invited you. Woohoo! Let's get you set up."
                  : "Let's create your account!"}{' '}
              </p>
            </h1>
          ))}
      </div>
      <section className='onboarding-section'>
        <div className='onboarding-form'>
          <StepComponent key={curStep} inviteToken={inviteToken} />
          {/*</CSSTransitionGroup>*/}
        </div>
        <Callout step={curStep} />
      </section>
    </div>
  );
};

export default OnboardingContainer;
