import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Loader from 'react-loader-spinner';

import TextField from '+/forms/TextField';
import rgx from '#/regex';
import { actions as authActions } from '../../auth/sagaSlice';
import ManagePhoneNumber from './ManagePhoneNumber';

const validate = (values) => {
  const { oldPassword, newPassword, confirmNewPassword } = values;

  const errors = {};

  if (!oldPassword) {
    errors.oldPassword = 'Required';
  } else if (!rgx.password.test(values.oldPassword)) {
    errors.oldPassword =
      'Your old password must contain at least six characters, an uppercase letter, a lowercase letter, and a symbol or number';
  }

  if (!newPassword) {
    errors.newPassword = 'Required';
  } else if (!rgx.new_password.test(values.newPassword)) {
    errors.newPassword =
      'Your password must contain at least twelve characters, an uppercase letter, a lowercase letter, and a symbol or number';
  }

  if (!confirmNewPassword) {
    errors.confirmNewPassword = 'Required';
  } else if (!rgx.new_password.test(values.confirmNewPassword)) {
    errors.confirmNewPassword =
      'Your password must contain at least twelve characters, an uppercase letter, a lowercase letter, and a symbol or number';
  }

  if (newPassword !== confirmNewPassword) {
    errors.confirmNewPassword = 'New password does not match';
  }

  return errors;
};

export const AccountPreferences = ({
  updatePassword,
  updateEmail,
  updateName,
  resetFormError,
  formErrors,
  isLoading,
  email,
  emailError,
  user,
  nameError,
}) => (
  <div className='m-b-sm'>
    <h2>Account Settings</h2>
    <EmailForm
      updateEmail={updateEmail}
      isLoading={isLoading}
      email={email}
      emailError={emailError}
    />
    <NameForm updateName={updateName} isLoading={isLoading} user={user} nameError={nameError} />
    <ManagePhoneNumber />
    <PasswordForm
      updatePassword={updatePassword}
      resetFormError={resetFormError}
      formErrors={formErrors}
      isLoading={isLoading}
    />
  </div>
);

const NameForm = ({ updateName, user, nameError, isLoading }) => {
  const onSubmit = (values) => {
    updateName({
      first_name: values.first_name || '',
      middle_name: values.middle_name || '',
      last_name: values.last_name || '',
      company_name: values.company_name || '',
      full_name: values.full_name || '',
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        const isUndefined = (v) => !v || !v.trim();

        if (isUndefined(values.company_name)) {
          if (isUndefined(values.first_name)) {
            errors.first_name = 'First name required.';
          }

          if (isUndefined(values.last_name)) {
            errors.last_name = 'Last name required.';
          }
        } else if (
          isUndefined(values.company_name) &&
          isUndefined(values.first_name) &&
          isUndefined(values.last_name)
        ) {
          errors.first_name = 'Either first and last or company name is required.';
        }

        return errors;
      }}
      render={({ handleSubmit }) => (
        <div className='form portal m-b-sm'>
          <div className='account-preferences'>
            <h4>Update User Info</h4>
            <div className='box text-error m-b-sm error-link'>
              <p>
                <b>Please note:</b>
              </p>
              <p>
                This is the information used to refer to you in communications between Zencare and
                you.
              </p>
              <p>
                To update the name on your public Zencare profile, please contact us on the{' '}
                <a href='/profile/general-information'>General Information page</a>
              </p>
            </div>
            <TextField
              initialValue={user.first_name}
              name='first_name'
              label='First name'
              type='text'
            />
            <TextField
              initialValue={user.middle_name}
              name='middle_name'
              label='Middle name'
              type='text'
            />
            <TextField
              initialValue={user.last_name}
              name='last_name'
              label='Last name'
              type='text'
            />
            <TextField
              initialValue={user.company_name}
              name='company_name'
              label='Company name'
              type='text'
            />

            {nameError && <span className='text-error block'>{nameError}</span>}
            <button onClick={handleSubmit} className='primary'>
              {isLoading ? (
                <Loader type='TailSpin' color='white' height={20} width={20} />
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      )}
    />
  );
};

const EmailForm = ({ updateEmail, email, isLoading, emailError }) => {
  const onSubmit = (values) => {
    updateEmail({
      password: values.password,
      newEmail: values.email,
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'New email required.';
        } else if (!rgx.email.test(values.email)) {
          errors.email = 'Please enter a valid email address.';
        }

        if (!values.password) {
          errors.password =
            'Please enter your current passsword. (We just want to make sure this is you!)';
        } else if (!rgx.password.test(values.password)) {
          errors.password =
            'Your password must contain at least twelve characters, an uppercase letter, a lowercase letter, and a symbol or number';
        }

        return errors;
      }}
      render={({ handleSubmit }) => (
        <div className='form portal m-b-sm'>
          <div className='account-preferences'>
            <h4>Update Account Email Address</h4>
            <div className='box text-error m-b-sm error-link'>
              <p>
                <b>Please note:</b>
              </p>
              <p>
                This is the email you use to log in and at which you will receive profile and
                membership-related emails.
              </p>
              <p>
                To update the email address at which you receive referrals, please contact us on the{' '}
                <a href='/profile/general-information'>General Information page</a>.
              </p>
            </div>
            <p>
              For security purposes, you are required to re-enter your current password to update
              your email address.
            </p>
            <p>Current email address:</p>
            <input className='w-100 m-b-sm' type='text' disabled value={email} />
            <TextField name='email' label='New email address' type='text' />
            <TextField name='password' label='Re-enter password' type='password' />
            {emailError && <span className='text-error block'>{emailError}</span>}
            <button onClick={handleSubmit} className='primary'>
              {isLoading ? (
                <Loader type='TailSpin' color='white' height={20} width={20} />
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      )}
    />
  );
};

const PasswordForm = ({ updatePassword, resetFormError, formErrors, isLoading }) => {
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (values) => {
    const { oldPassword, newPassword } = values;

    updatePassword({
      new_password: newPassword,
      old_password: oldPassword,
    });
  };

  const checkFormErrors = () => {
    if (formErrors) {
      resetFormError();
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div className='form portal m-b-sm'>
          <div className='account-preferences'>
            <h4>Update Password</h4>
            <p>
              This is the password which you use to log in. To update your password, fill out the
              form below.
            </p>

            <TextField
              onFocus={checkFormErrors}
              formError={formErrors.oldPassword}
              name='oldPassword'
              label='Current Password'
              type={showPassword ? 'text' : 'password'}
            />

            <TextField
              name='newPassword'
              label='New Password'
              type={showPassword ? 'text' : 'password'}
            />

            <TextField
              name='confirmNewPassword'
              label='Confirm New Password'
              type={showPassword ? 'text' : 'password'}
            />

            <div
              className='checkbox'
              style={{
                marginBottom: '1rem',
                marginTop: '1rem',
              }}
            >
              <input
                type='checkbox'
                id='showPassword'
                onChange={(e) => setShowPassword(e.target.checked)}
              />
              <label htmlFor='showPassword'>
                <span className='time-of-day'>Show Password</span>
              </label>
            </div>

            <button onClick={handleSubmit} className='primary'>
              {isLoading ? (
                <Loader type='TailSpin' color='white' height={20} width={20} />
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      )}
    />
  );
};

const mapStateToProps = ({ auth }) => ({
  user: {
    first_name: auth.first_name,
    middle_name: auth.middle_name,
    last_name: auth.last_name,
    company_name: auth.company_name,
    full_name: auth.full_name,
  },
  formErrors: auth.formErrors,
  emailError: auth.emailError,
  nameError: auth.nameError,
  email: auth.email,
  isLoading: auth.isLoading,
});
const actions = {
  updatePassword: authActions.updatePassword,
  updateEmail: authActions.updateEmail,
  updateName: authActions.updateName,
  resetFormError: authActions.resetFormError,
};

export default connect(mapStateToProps, actions)(AccountPreferences);
