import React from 'react';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { actions } from '../sagaSlice';
import useWindowSize from '~/shared/hooks/useWindowSize';

const AppointmentRow = ({ client, practiceInbox }) => {
  const dispatch = useDispatch();
  const { contactDate, firstName, lastName, data, type, email, providerName } = client;
  const [width] = useWindowSize();
  const tz = DateTime.local().zoneName;
  const start = DateTime.fromISO(contactDate).setZone(tz).toLocaleString(DateTime.DATE_MED);
  const formattedAppointment = formatAppointment();

  function formatAppointment() {
    const user = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number || data.phone,
    };

    const appointment = {
      legacy: true,
      type: type,
      id: data.id,
      user: user,
      intake_form: {},
    };

    if (type === 'provider_contact') {
      appointment.intake_form['Message to provider:'] = data.message;

      const metaKeyMapping = {
        source: 'Message via:',
        method_of_pay: 'How would you like to pay for sessions?',
        insurance: 'Insurance',
        state: 'State',
        session_type: 'Session type',
        call_times: 'When are some good times to call you?',
        referred_by: 'Who were you referred by?',
      };

      for (const key of Object.keys(metaKeyMapping)) {
        if (data.meta[key]) {
          appointment.intake_form[metaKeyMapping[key]] = data.meta[key];
        }
      }

      appointment.start_time = data.created_at;

      return appointment;
    }

    if (data.forms) {
      for (const form of data.forms) {
        if (form.values) {
          for (const val of form.values) {
            appointment.intake_form[val.name] = val.value;
          }
        }
      }
    }

    appointment.start_time = data.appointment_at;

    return appointment;
  }

  return (
    <tr>
      <td>
        {/* <small>First contacted:</small> */}
        <h6>{start}</h6>
      </td>
      <td>
        <p>{firstName ? firstName.trim() : ''}</p>
      </td>
      <td>
        <p>{lastName ? lastName.trim() : ''}</p>
      </td>
      <td>
        <p>{email ? email.trim() : ''}</p>
      </td>
      {practiceInbox && (
        <td>
          <p>{providerName ? providerName.trim() : ''}</p>
        </td>
      )}
      <td>
        <button
          className={`primary pill hollow sm ${width < 780 && 'mobile-button'}`}
          onClick={() => dispatch(actions.openLegacyReferral(formattedAppointment))}
        >
          {width < 780 ? <i className='far fa-clipboard'></i> : 'Appointment'}
        </button>
      </td>
    </tr>
  );
};

export default AppointmentRow;
