/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions as tagActions } from '@/provider/taggings/sagaSlice';
import { actions as mainActions } from '@/main/sagaSlice';
import { actions as onboardingActions } from './sagaSlice';
import Loader from '+/forms/SectionLoader';
import _ from 'lodash';
import YourPracticeTagsFees from './YourPracticeTagsFees';
import YourPracticeInsurance from './YourPracticeInsurance';

const YourPracticeContainer = ({
  onboarding,
  languages,
  readEveryLanguage,
  clientele,
  readEveryClientele,
  specialties,
  readEverySpecialty,
  approaches,
  readEveryApproach,
  submitOnboardingYourPracticeInfo,
  submitOnboardingYourPracticeInfoWithoutSteppingForward,
  createForSubmitOnboardingYourPracticeInfo,
  stepForwardAfterInsuranceSubmit,
  createForStepForwardAfterInsuranceSubmit,
  getMe,
  providerId,
  main,
}) => {
  const [showInsuranceForm, setShowInsuranceForm] = useState(false);
  const routerParams = useParams();

  useEffect(() => {
    getMe();
    readEveryLanguage();
    readEveryClientele();
    readEverySpecialty();
    readEveryApproach();
  }, [getMe, readEveryClientele, readEveryLanguage, readEverySpecialty, readEveryApproach]);

  const accounts = _.get(main, 'accounts', []).filter((a) => !a.error);

  const provider =
    main.allProviders.find((p) => p.id === providerId) ||
    _.flatten(accounts.map((a) => a.providers)).find((p) => p.id === providerId) ||
    main.provider;

  const languageData = Array.isArray(languages.data)
    ? _.orderBy(languages.data, 'name', 'asc')
    : [];

  const clienteleData = Array.isArray(clientele.data)
    ? _.orderBy(clientele.data, 'name', 'asc')
    : [];
  const topSpecialtyData = Array.isArray(specialties.data)
    ? _.orderBy(specialties.data, 'name', 'asc')
    : [];

  // Top specialties will not show in the regular specialties selector
  const getSpecialtyData = (values) => {
    if (!Array.isArray(specialties.data)) {
      return [];
    }
    const result = specialties.data.filter(
      (x) =>
        !values.top_specialties ||
        !Array.isArray(values.top_specialties) ||
        !values.top_specialties.includes(x.id)
    );

    return _.orderBy(result, 'name', 'asc');
  };

  const approachData = Array.isArray(approaches.data)
    ? _.orderBy(approaches.data, 'name', 'asc')
    : [];

  const onSubmitTagsFees = (values) => {
    // We need to ignore specialties where user chose them but then changed their mind
    // and made them a top specialty instead.
    const specialties = values.specialties.filter((x) => !values.top_specialties.includes(x));

    const payload = {
      provider_id: provider.id,
      languages: values.languages,
      clientele: values.clientele,
      top_specialties: values.top_specialties,
      specialties: specialties,
      approaches: values.approaches,
      session_fee_ongoing: values.session_fee_ongoing,
      session_length_ongoing: values.session_length_ongoing,
      display_sliding_scale_slots: values.display_sliding_scale_slots,
      session_fee_min: values.session_fee_min,
    };
    if (values.takes_insurance && values.takes_insurance === true) {
      // We need to prevent moving to next 'Step' of onboarding
      // if we are going to show the insurance form.
      submitOnboardingYourPracticeInfoWithoutSteppingForward(payload);
      setShowInsuranceForm(true);
      // Scroll to top so the insurance form is scrolled to top.
      window.scrollTo(0, 0);
    } else {
      // If in 'create for' mode inside portal
      if (routerParams.providerId) {
        createForSubmitOnboardingYourPracticeInfo(payload);
      } else {
        submitOnboardingYourPracticeInfo(payload);
      }
    }
  };

  const insuranceSubmitClicked = () => {
    console.log('insuranceSubmitClicked called');
    // The insurance form saves its data on its own.
    // Now we need to save the Your Practice Info since waited to save that

    // If in 'create for' mode inside portal
    if (routerParams.providerId) {
      createForStepForwardAfterInsuranceSubmit({ provider_id: provider.id });
    } else {
      stepForwardAfterInsuranceSubmit({ provider_id: provider.id });
    }
  };

  if ((onboarding && onboarding.isLoading) || !provider) {
    return (
      <div key='info-form'>
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      </div>
    );
  }

  const isPractice = provider.type === 'practice';
  // const isIndividual = provider.type === 'individual';
  const youPhrase = isPractice ? 'your providers' : 'you';
  const yourPhrase = isPractice ? "your providers'" : 'your';

  return showInsuranceForm ? (
    <YourPracticeInsurance
      providerId={providerId}
      provider={provider}
      insuranceSubmitClicked={insuranceSubmitClicked}
    />
  ) : (
    <YourPracticeTagsFees
      providerId={providerId}
      onSubmit={onSubmitTagsFees}
      youPhrase={youPhrase}
      yourPhrase={yourPhrase}
      clienteleData={clienteleData}
      languageData={languageData}
      approachData={approachData}
      topSpecialtyData={topSpecialtyData}
      getSpecialtyData={getSpecialtyData}
    />
  );
};

export default connect(
  ({ onboarding, languages, clientele, specialties, approaches, main }) =>
    //eslint-disable-next-line
    ({ onboarding, languages, clientele, specialties, approaches, main }),
  {
    readEveryLanguage: tagActions.languages.readEvery,
    readEveryClientele: tagActions.clientele.readEvery,
    readEverySpecialty: tagActions.specialties.readEvery,
    readEveryApproach: tagActions.approaches.readEvery,
    submitOnboardingYourPracticeInfo: onboardingActions.submitOnboardingYourPracticeInfo,
    submitOnboardingYourPracticeInfoWithoutSteppingForward:
      onboardingActions.submitOnboardingYourPracticeInfoWithoutSteppingForward,
    createForSubmitOnboardingYourPracticeInfo:
      onboardingActions.createForSubmitOnboardingYourPracticeInfo,
    stepForwardAfterInsuranceSubmit: onboardingActions.stepForwardAfterInsuranceSubmit,
    createForStepForwardAfterInsuranceSubmit:
      onboardingActions.createForStepForwardAfterInsuranceSubmit,
    getMe: mainActions.getMe,
  }
)(YourPracticeContainer);
