import { at } from 'lodash';
import { DateTime } from 'luxon';

// TODO Make this way better
// If time is today, return only TIME
// If time is this week, return DAY OF WEEK AND TIME
// If time is neither, return DATE AND TIME
// Currently returns 'Friday, May 06, 02:51 PM'
export function formatMessageTime(date) {
  return DateTime.fromISO(date).toLocaleString({
    weekday: 'long',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}

function formatDate(date) {
  return DateTime.fromISO(date).toLocaleString({
    weekday: 'long',
    month: 'short',
    day: '2-digit',
  });
}

function formatTime(date) {
  return DateTime.fromISO(date).toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatAppointmentTime(date) {
  const day = formatDate(date);
  const time = formatTime(date);
  return `${day}, at ${time}`;
}

export default formatMessageTime;
