export const getReimbursifyDomain = () => {
  const isStaging = process.env.ZENCARE_ENV === 'staging';
  let urlPrefix = 'practitioner.reimbursify.com';
  if (isStaging) {
    urlPrefix = 'staging-practitioner.reimbursify.com';
  }
  return urlPrefix;
};

export const redirectToReimbursify = (reimbursifyJwt: string) => {
  if (reimbursifyJwt) {
    const urlPrefix = `https://${getReimbursifyDomain()}/auth?jwt=`;
    window.location.href = `${urlPrefix}${reimbursifyJwt}`;
  } else {
    alert('Sorry, we ran into an error. Please contact support.');
  }
};

export const transformDateFormat = (dateString: string | null) => {
  if (!dateString) {
    return '';
  }
  const parts = dateString.split('-');
  const transformedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
  return transformedDate;
};

export const commaSeparateValues = (value: number | null): string => {
  if (value === null) return '';
  const numericValue = Number(value);
  // Check if the conversion was successful and the value is a finite number
  if (Number.isFinite(numericValue)) {
    // Use toLocaleString() with the appropriate options
    // 'en-US' specifies the locale for English (United States)
    // { minimumFractionDigits: 2, maximumFractionDigits: 2 } specifies the decimal places
    // The thousands separator will automatically be added based on the locale
    return numericValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    // Return the original input if it couldn't be converted to a number
    return String(value);
  }
};

// @ts-check

/**
 * Calculates an estimated session fee (on the low end) if user had already fulfilled their deductible.
 *
 * @param {number} sessionFee - Provider's session fee
 * @param {number} reimbursementPercent - Client's reimbursement percent after deductible e.g. 10 for 10%
 * @returns {string} - The result as a string
 */
export const calculateEffectiveSessionFeeLowerRange = (
  sessionFee: number,
  reimbursementPercent: number
) => {
  const result = Math.round(100 * (sessionFee - sessionFee * (reimbursementPercent / 100))) / 100;

  if (result < 1) {
    return result.toFixed(2);
  } else {
    return Math.round(result).toString();
  }
};

/**
 * Calculates an estimated session fee (on the higher end) if user had already fulfilled their deductible.
 *
 * @param {number} sessionFee - Provider's session fee
 * @param {number} reimbursementPercent - Client's reimbursement percent after deductible e.g. 10 for 10%
 * @returns {string} - The result as a string
 */
export const calculateEffectiveSessionFeeHigherRange = (
  sessionFee: number,
  reimbursementPercent: number
) => {
  const ALLOWED_AMOUNT_PLACEHOLDER = 60;
  const effectiveSessionFeeLowerRange =
    // Work incoming on Monday -- TODO think about this more...
    // Math.round(100 * (sessionFee - sessionFee * (reimbursementPercent / 100))) / 100;
    Math.round(100 * (sessionFee - ALLOWED_AMOUNT_PLACEHOLDER * (reimbursementPercent / 100))) /
    100;

  let result = effectiveSessionFeeLowerRange * 1.25;

  if (result > sessionFee) {
    result = sessionFee;
  }

  if (result < 1) {
    return result.toFixed(2);
  } else {
    return Math.round(result).toString();
  }
};
