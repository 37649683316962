// $TSFixMe
/* eslint-disable max-lines-per-function */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import constants, { statesWithPhotographers, statesComingSoon, statesAccepting } from './constants';
import _ from 'lodash';
import PaymentFormSeatBased from './PaymentFormSeatBased';
import providerName from '#/providerName';
import storage from '#/storage';
import { getQueryAsObject } from '../../../utils/queryParams';
import { actions } from './sagaSlice';

interface SubscribeToPlanSeatBasedProps {
  provider: $TSFixMe;
  accounts: Array<object>;
  onSubscribed: Function;
  showStartForLine?: boolean;
  title?: string;
  subtitle?: string;
  showDoThisLater?: boolean;
  doThisLaterClicked: Function;
  paymentStep: 'customize' | 'pay' | 'success';
  // setPaymentStep: (step: 'customize' | 'pay' | 'success') => void;
  upgrade: boolean;
  setPaymentStep: $TSFixMeFunction;
  convertingToMonthly?: boolean;
  upgradeReimbursify?: boolean;
}

export const SubscribeToPlanSeatBased = ({
  provider,
  accounts,
  onSubscribed,
  showStartForLine = false,
  title = 'Start Your Membership',
  subtitle = 'Just one client will pay for the whole year.',
  showDoThisLater = false,
  doThisLaterClicked,
  upgrade,
  setPaymentStep,
  paymentStep,
  convertingToMonthly,
  upgradeReimbursify,
}: SubscribeToPlanSeatBasedProps) => {
  const stateHasPhotographers =
    provider && provider.locations.some((l: $TSFixMe) => statesWithPhotographers.includes(l.state));
  const stateComingSoon =
    provider && provider.locations.some((l: $TSFixMe) => statesComingSoon.includes(l.state));
  const lskey = `photo_avail:${provider ? provider.id : null}`;
  // In case provider comes back later to pay, don't remember previous selection - photographers might have udpated.
  const initPhotoAvail = window.location.pathname.includes('/profiles/pay-for')
    ? undefined
    : storage.get(lskey);
  const [photoAvail, setPhotoAvail] = useState(initPhotoAvail);

  const [onlySetupOptions, setOnlySetupOptions] = useState<
    typeof constants.setup_remote | typeof constants.setup_remote_practice | null
  >(null);

  // Prevent white screen error upon logout.
  if (!provider) {
    return 'Loading...';
  }

  if (['none_near', 'new_geo'].includes(photoAvail) && !onlySetupOptions) {
    if (provider.type === 'individual') {
      setOnlySetupOptions(constants.setup_remote);
    } else {
      setOnlySetupOptions(constants.setup_remote_practice);
    }
  } else if (!onlySetupOptions) {
    if (provider.type === 'individual') {
      setOnlySetupOptions(constants.setup_individual);
    } else {
      setOnlySetupOptions(constants.setup_practice);
    }
  }

  if (!photoAvail && !upgrade && !upgradeReimbursify) {
    return (
      <div className='subscription-container w-100 flex justify-around column'>
        <h5>
          Our photographers are based in the following cities. Are you in, within a one hour drive
          from, or able to travel to one of these locations for a photo/videoshoot in the next six
          months?
        </h5>
        <iframe
          title='Cities with photographers'
          src='https://therapist.zencare.co/photographer-cities'
          style={{
            width: '100%',
            height: 300,
            margin: 10,
          }}
        />
        <div className='flex'>
          <button
            className='primary pill md m-r-sm'
            onClick={() => {
              const o =
                stateHasPhotographers || stateComingSoon ? 'available' : 'available_new_geo';
              storage.set(lskey, o);
              setPhotoAvail(o);
              if (provider && provider.type === 'individual') {
                setOnlySetupOptions(constants.setup_individual);
              }
              if (provider && provider.type === 'practice') {
                setOnlySetupOptions(constants.setup_practice);
              }
            }}
          >
            Yes
          </button>
          <button
            className='primary pill hollow md'
            onClick={() => {
              const o = stateHasPhotographers || stateComingSoon ? 'none_near' : 'new_geo';
              storage.set(lskey, o);
              if (provider && provider.type === 'individual') {
                setOnlySetupOptions(constants.setup_remote);
              }
              if (provider && provider.type === 'practice') {
                setOnlySetupOptions(constants.setup_remote_practice);
              }
              setPhotoAvail(o);
            }}
          >
            No
          </button>
        </div>
      </div>
    );
  }

  // if (paymentStep === 'success') {
  //   return (
  //     <div className='subscription-container success w-100 '>
  //       <h2>A new seat was successfully added!</h2>
  //       <img
  //         src='https://images.unsplash.com/photo-1553735934-2d11a9f60cb1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
  //         alt='A dog lying on a blanket waiting for you to log in'
  //         className='w-100'
  //       />
  //       <p className='success-message'>
  //         You’ve added a new seat to your Zencare Practice Management account.
  //       </p>
  //       <p className='success-message'>
  //         To grant access to your clinician, log in and head over to{' '}
  //         <a href='/plan-management'>{`Settings > Therapists.`}</a>
  //       </p>
  //       <a href='/plan-management'>
  //         <button className='primary pill md' onClick={() => setPaymentStep('customize')}>
  //           Log in to Practice Management
  //         </button>
  //       </a>
  //     </div>
  //   );
  // }
  const stripeCustomers = _.get(accounts, '[0].stripe_customers', []);
  const primaryCustomer = stripeCustomers.find((s: $TSFixMe) => s.primary);
  let initialCoupon = null;
  const urlParams = getQueryAsObject(window.location.search);
  const partner = urlParams.partner;
  if (partner === 'owl') {
    initialCoupon = 'TherapyOwl2022';
  }

  const interior = (
    <PaymentFormSeatBased
      onSubscribed={onSubscribed}
      showDoThisLater={showDoThisLater}
      doThisLaterClicked={doThisLaterClicked}
      provider={provider}
      customer={primaryCustomer}
      onlySetupOptions={onlySetupOptions}
      initialCoupon={initialCoupon}
      upgrade={upgrade}
      reactivate={false}
      convertingToMonthly={convertingToMonthly}
      upgradeReimbursify={upgradeReimbursify}
    />
  );

  return (
    <div className='subscription-container w-100 flex justify-around column align-center'>
      <nav className='subscription-nav'>
        <button
          className={paymentStep === 'customize' ? 'current' : ''}
          onClick={() => setPaymentStep('customize')}
        >
          Your plan
        </button>
        <i className='fas fa-caret-right current'></i>
        <button
          className={paymentStep === 'pay' ? 'current' : ''}
          onClick={() => setPaymentStep('pay')}
        >
          Payment
        </button>
      </nav>
      <h1 className='m-t-sm' style={{ textAlign: 'center' }}>
        {title}
      </h1>
      <p className='m-b-md'>{subtitle}</p>
      {(photoAvail === 'none_near' || photoAvail === 'new_geo') &&
        !upgrade &&
        !upgradeReimbursify && (
          <div
            className='w-100 m-b-md'
            style={{
              textAlign: 'center',
              maxWidth: '760px',
            }}
          >
            <p>
              We're so excited you're here, {providerName(provider, true)}! Get set up with our
              remotely coached photo and videoshoot: our professional photographers will guide you
              through the set up on a video call!
            </p>
          </div>
        )}

      {interior}
    </div>
  );
};

export default connect(
  ({ main, paymentSeatBased }: $TSFixMe, ownProps: $TSFixMe) => ({
    accounts: (main.accounts || []).filter((a: $TSFixMe) => !a.error),
    provider: _.get(ownProps, 'provider') || main.provider,
    unusedReferral: _.get(main, 'unusedReferrals[0].referring_user_id'),
    loading: paymentSeatBased.isLoading,
    paymentStep: paymentSeatBased.paymentStep,
  }),
  { setPaymentStep: actions.setPaymentStep }
  // @ts-ignore: $TSFixMe
)(SubscribeToPlanSeatBased);
