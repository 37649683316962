/* eslint-disable max-lines-per-function */
import React from 'react';

export const PhoneConsultations = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      maxWidth: '1080px',
      margin: '0 auto',
    }}
  >
    <h1 className='title m-b-md'>How to Ace Phone Consultations</h1>
    <p className='m-b-sm'>
      There are two ways you can opt for clients to contact you via Zencare: via email form, or a
      requested phone consultation. When clients request a phone consultation, this initial call is
      your chance to get to know why the client is seeking care now, and express if your services
      may be a fit.
    </p>
    <p className='m-b-sm'>
      Even if the prospective client doesn’t ultimately book a session, acing the intake process can
      help spread positive word of mouth and lead to more referrals moving forward. Here’s how you
      can make the most of each requested phone consultation!
    </p>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>Cover logistics in advance</h3>
      <ul>
        <li>
          <strong>Email the client back to confirm </strong>
          you received the phone consultation request, and are looking forward to speaking. Even a
          quick note such as “Thank you for reaching out! I’m looking forward to learning more about
          what brings you to therapy and will give you a call at the requested time,” can help keep
          prospective clients stay engaged and interested in working with you.
        </li>

        <li>
          <strong>Share additional logistic information</strong> over email, so that you can focus
          phone time on getting to know the prospective client and sharing your services. If you
          have limited availability in your practice, let them know in advance what session times
          would be available so there’s a shared sense of if it will be feasible to see you. And, if
          you are an out-of-network clinician, it’s helpful to reaffirm your fees over email in
          advance of the call, so that both you and the client have the right expectation and your
          time can be respected if it’s not a fit.
        </li>

        <li>
          <strong>If the time doesn’t work for you,</strong> you can reschedule by emailing the
          client directly. Their contact information can be found at the bottom of the confirmation
          email, and replies to the email will be sent directly to the client. Once you’ve settled
          on a new time, you can use the link in the confirmation email to adjust the schedule, so
          you both receive reminders (via text and email) for the correct time.
        </li>

        <li>
          <strong>Feel free to give the client a call sooner</strong> than the scheduled time, e.g.
          the day they scheduled the call, if you’re able. The sooner you catch them in their
          search, the better! :)
        </li>
      </ul>
    </div>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>Ace the call</h3>
      <ul>
        <li>
          <strong>
            Ask questions that make the prospective client feel comfortable and heard,
          </strong>{' '}
          such as:
          <ul>
            <li>Why are you considering therapy now?</li>
            <li>Have you been in therapy before?</li>
            <li>What are you looking for in a therapist?</li>
            <li>What has worked in the past and what hasn’t?</li>
            <li>What does [depression/ anxiety / substance abuse] look like for you?</li>
          </ul>
        </li>
        <li>
          <strong>Speak in a shared language and affirm their experience</strong>
          <ul>
            <li>
              <strong>Avoid jargon:</strong> rather than listing credential or modalities that you
              use, explain in common language exactly what happens in sessions with you.
            </li>
            <li>
              <strong>Reference any details</strong> they may have mentioned in their initial call
              request, to tailor the conversation to what the prospective client is experiencing
              now. Use the words they use to describe their current challenges in a way that is
              validating, and offers relief for the future through work together.
            </li>
          </ul>
        </li>
        <li>
          <strong>Express your unique value</strong>
          <ul>
            <li>
              Cover all the details that make your practice unique, such as
              <ul>
                <li>Your experience working with clients facing similar challenges</li>
                <li>
                  The structure of sessions and how you seek to build a relationship with your
                  clients
                </li>
                <li>
                  How the work will provide symptom relief and help them reach their therapy goals
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a
            href='https://blog.zencare.co/turn-initial-calls-into-clients/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Get the full guide on acing phone consultations here.
          </a>
        </li>
      </ul>
    </div>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>Following up</h3>
      <ul>
        <li>
          <strong>If the client is not a good fit</strong> for you due to scheduling challenges or
          scope of your practice, please direct them back to{' '}
          <a href='https://zencare.co' target='_blank'>
            Zencare
          </a>{' '}
          or let them know to contact us at <a href='mailto:hello@zencare.co'>hello@zencare.co</a>.{' '}
          We’ll follow up to get them connected to an excellent provider!
        </li>
        <li>
          <strong>Ask the client how they’d like to proceed.</strong> At the end of the call, ask:
          “Do you have other therapists you’re speaking with or would you like to schedule an
          in-person appointment now?” This gives clients a chance to politely decline scheduling an
          intake, or to reflect on the conversation and get back to you later.
        </li>
        <li>
          <strong>If the client confirms, be sure to follow up!</strong> If the client does want to
          move forward, send them a confirmation email or text with your address, phone number, and
          the date and time of the appointment. This vastly reduces no-show rates and decreases the
          chance of your new client writing down the appointment details incorrectly.
        </li>
      </ul>

      <h3 className='subtitle m-b-sm'>Curious to learn more?</h3>
      <ul>
        <li>
          Here are{' '}
          <a
            rel='noopener noreferrer'
            target='_blank'
            href='https://blog.zencare.co/questions-to-ask-initial-call/'
          >
            questions you can ask a client on the initial call.
          </a>
        </li>
        <li>
          Learn{' '}
          <a
            rel='noopener noreferrer'
            target='_blank'
            href='https://blog.zencare.co/increase-client-referrals-tips/'
          >
            how two therapists introduce themselves to clients
          </a>
          , how they answer questions like "can you tell me about your practice," and how they
          discuss fees with prospective clients.
        </li>
        <li>
          Put yourself in the clients' shoes with these
          <a
            rel='noopener noreferrer'
            target='_blank'
            href='https://blog.zencare.co/private-practice-boost-conversion-rates/'
          >
            {' '}
            3 helpful things to keep in mind on the initial call.
          </a>
        </li>
      </ul>
    </div>
  </div>
);
