import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '~/components/main/sagaSlice';
import ReactTooltip from 'react-tooltip';
import { formatDate } from './helpers';
import { formatDatePickerInput } from '~/utils/formatDatePickerInput';

function VacationResponder() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<'inactive' | 'active'>('inactive');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const outOfOfficeMessage = useSelector<State, OutOfOfficeMessage | null>(
    (state) => state?.main?.provider?.out_of_office_message || null
  );
  const { provider } = useSelector<State, MainState>((state) => state.main);

  useEffect(() => {
    if (outOfOfficeMessage) {
      setStatus(outOfOfficeMessage.status);
      setStartDate(new Date(outOfOfficeMessage.start_date).toISOString().slice(0, 10));
      setEndDate(new Date(outOfOfficeMessage.end_date).toISOString().slice(0, 10));
      setMessage(outOfOfficeMessage.message);
    }
  }, [outOfOfficeMessage]);

  // Update form error message
  useEffect(() => {
    setErrorMessage('');
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const now = new Date(); // Get the current date
    const difference = endDateObj.getTime() - startDateObj.getTime();
    const oneDayInMillis = 1000 * 60 * 60 * 24; // 1 day in milliseconds
    const oneYearInMillis = oneDayInMillis * 365; // 1 year in milliseconds
    const isValid = difference >= oneDayInMillis && difference <= oneYearInMillis;

    if (!startDate || !endDate || !inputsHaveChanged()) {
      setErrorMessage('');
    } else if (endDateObj <= now) {
      setErrorMessage('The selected end date must be in the future.');
    } else if (difference <= oneDayInMillis) {
      setErrorMessage(
        'Please select an end date at least one full day after the selected start date.'
      );
    } else if (difference >= oneYearInMillis) {
      setErrorMessage('Please select an end date less than a year beyond the selected start date.');
    } else if (isValid) {
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  if (!provider) {
    return null;
  }

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    // Disable End Date input when there's no Start Date
    if (!e.target.value) {
      setEndDate('');
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here

    const payload = {
      status: 'active',
      start_date: formatDatePickerInput(startDate),
      end_date: formatDatePickerInput(endDate),
      message,
      provider_id: provider.id,
    };
    // @ts-ignore
    dispatch(actions.updateOutOfOfficeMessage(payload));
  };

  const toggleStatus = (newStatus: 'active' | 'inactive') => {
    let confirm = true;
    if (inputsHaveChanged()) {
      // This is a quick fix to prevent toggling status from deleting changed inputs without changing, as
      // toggling a status only sends the status to the backend, and not all updated values. A long term fix
      // if this UX is too cumbersome is to update the updateOutOfOfficeMessageStatus to accept all form values.
      confirm = window.confirm(
        "You have not saved your updates. Toggling your Vacation Responder without saving will reinstate previously saved values. Are you sure you'd like to proceed?"
      );
    }
    if (confirm) {
      return dispatch(
        // @ts-ignore
        actions.updateOutOfOfficeMessageStatus({
          status: newStatus,
          provider_id: provider.id,
        })
      );
    }
  };

  const endDateIsValid = () => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const now = new Date(); // Get the current date

    if (!startDate || !endDate) {
      return true; // No validation if either date is missing
    }

    // Check if the end date is in the future
    if (endDateObj <= now) {
      return false; // End date is not in the future
    }

    // Calculate the difference in milliseconds
    const difference = endDateObj.getTime() - startDateObj.getTime();
    const oneDayInMillis = 1000 * 60 * 60 * 24; // 1 day in milliseconds
    const oneYearInMillis = oneDayInMillis * 365; // 1 year in milliseconds

    // Check if the difference is within the allowed range
    const isValid = difference >= oneDayInMillis && difference <= oneYearInMillis;

    return isValid;
  };

  const inputsHaveChanged = () => {
    if (!outOfOfficeMessage) return true;
    const endDateHasChanged =
      endDate !== new Date(outOfOfficeMessage.end_date).toISOString().slice(0, 10);
    const startDateHasChanged =
      startDate !== new Date(outOfOfficeMessage.start_date).toISOString().slice(0, 10);
    const messageHasChanged = message !== outOfOfficeMessage.message;
    return endDateHasChanged || startDateHasChanged || messageHasChanged;
  };

  const allInputsHaveValues = () => Boolean(message && startDate && endDate);

  const tooltipText = `Your vacation responder starts at 12:00 AM UTC on ${
    startDate ? formatDate(startDate) : 'the start date'
  } and ends at 11:59 PM UTC on ${
    endDate ? formatDate(endDate) : 'the end date'
  }, unless you turn it off earlier.`;

  const toggleDisabled = !outOfOfficeMessage || !endDateIsValid();

  return (
    <div className='vacation-responder box m-b-md'>
      <form action=''>
        <div className='flex justify-between m-b-sm'>
          <h3 className='m-b-0'>Vacation Responder</h3>
          <div
            className='tab-select'
            data-for='vacation-responder-tooltip'
            data-tip={
              toggleDisabled
                ? 'Update the form below to activate your Vacation Responder.'
                : 'Your vacation responder will not go live until the Start Date. Ensure the toggle is set to “On” so it will start as scheduled.'
            }
          >
            <div className={`container ${toggleDisabled ? 'disabled' : ''}`}>
              <input
                type='radio'
                id='vacation_responder_toggle_off'
                value='off'
                checked={status === 'inactive'}
                name='off'
                onChange={(e) => toggleStatus('inactive')}
                disabled={toggleDisabled}
              />
              <label htmlFor='vacation_responder_toggle_off'>Off</label>
            </div>
            <div className={`container ${outOfOfficeMessage ? '' : 'disabled'}`}>
              <input
                type='radio'
                id='vacation_responder_toggle_on'
                value='on'
                checked={status === 'active'}
                name='on'
                onChange={(e) => toggleStatus('active')}
                disabled={toggleDisabled}
              />
              <label htmlFor='vacation_responder_toggle_on'>On</label>
            </div>
          </div>
        </div>
        <p className='secondary-text'>
          Add an automatic reply to clients informing them why you may not respond right away. This
          will also display a warning to therapy seekers within contact forms if they attempt to
          submit a referral to you while you are out of office.{' '}
          <span className='bold'>
            To change the availability displayed on your profile, use the toggles in the
            "Availability Updates" section above.
          </span>
        </p>

        <p className='secondary-text italic'>
          <span className='bold'>Note: </span> While you are away, you will continue to get intake
          messages, but you will not get any other new message emails.{' '}
          {status === 'inactive' ? (
            <span>
              Your vacation responder is currently <span className='bold'>"Off"</span> meaning
              therapy seekers will not receive your automated message.
            </span>
          ) : (
            ''
          )}
        </p>

        <div className='flex justify-between m-b-sm'>
          <div className='flex column w-100 m-r-sm'>
            <label className='bold' htmlFor='startDate'>
              <i
                className='fas fa-info-circle m-r-xs text-tertiary'
                data-for='vacation-responder-tooltip'
                data-tip={tooltipText}
              ></i>
              Start Date:
            </label>
            <input
              type='date'
              id='startDate'
              value={startDate}
              onChange={handleStartDateChange}
              required
            />
          </div>
          <div className='flex column w-100'>
            <label className='bold' htmlFor='endDate'>
              <i
                className='fas fa-info-circle m-r-xs text-tertiary'
                data-for='vacation-responder-tooltip'
                data-tip={tooltipText}
              ></i>
              End Date:
            </label>
            <input
              type='date'
              id='endDate'
              value={endDate}
              onChange={handleEndDateChange}
              required
            />
          </div>
        </div>
        <p className='text-error'>{errorMessage}</p>
        <div className='flex column m-b-sm'>
          <label className='bold' htmlFor='message'>
            <i
              className='fas fa-info-circle m-r-xs text-tertiary'
              data-for='vacation-responder-tooltip'
              data-tip='This message will be sent to therapy seekers if they submit a referral to you while you are out of office.'
            ></i>
            Out of Office Message:
          </label>
          <textarea
            id='message'
            placeholder='Enter your out of office message here'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className='flex end flex-end w-100 justify-end -right'>
          <button
            disabled={!inputsHaveChanged() || !endDateIsValid() || !allInputsHaveValues()}
            className='primary'
            type='submit'
            onClick={handleSubmit}
            data-for='vacation-responder-tooltip'
            data-tip={
              !endDateIsValid() || !allInputsHaveValues()
                ? allInputsHaveValues()
                  ? 'Please fix your start and end date selections.'
                  : 'Please complete the form.'
                : ''
            }
          >
            Save
          </button>
        </div>
      </form>
      <ReactTooltip
        id='vacation-responder-tooltip'
        effect='solid'
        place='top'
        type='light'
        arrowColor='#37BEC3'
      />
    </div>
  );
}

export default VacationResponder;
