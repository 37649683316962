import React from 'react';
import PureModal from '+/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './sagaSlice.js';

function StandaloneZencarePracticeManagementRedirectModal() {
  const dispatch = useDispatch();
  const {
    practiceManagementSubdomain,
    practiceManagementRedirectLink,
    practiceManagementClinicStatus,
    showPracticeManagementRedirectModal,
  } = useSelector<State, OnboardingState>((state) => state.onboarding);
  const actionText =
    practiceManagementClinicStatus === 'ZPM_STANDALONE_PAID'
      ? 'If you are trying to access your Zencare Practice Management EHR, continue to your clinic at '
      : 'If you are trying to access your Zencare Practice Management EHR, activate your membership for ';
  const buttonText =
    practiceManagementClinicStatus === 'ZPM_STANDALONE_PAID'
      ? 'Continue to my Clinic'
      : 'Activate my Zencare Practice Management Membership';

  // @ts-ignore
  const closeModal = () => dispatch(actions.setShowPracticeManagementRedirectModal(false));

  return (
    <PureModal
      visible={showPracticeManagementRedirectModal}
      additionalCssClasses='shorter-modal'
      closeModal={closeModal}
      headerContent={<h4>Welcome to Zencare </h4>}
    >
      <div>
        <p>
          This email is already in use. In order to create a new Zencare Marketing account, please
          use a new email.
        </p>
        <p>
          {actionText}
          <a
            className='bold'
            href={`https://${practiceManagementSubdomain}.zencarepractice.com/`}
          >{` ${practiceManagementSubdomain}.zencarepractice.com`}</a>
          .
        </p>
        <button className='primary pill hollow m-r-sm' onClick={closeModal}>
          Change email and continue
        </button>
        <a href={practiceManagementRedirectLink}>
          <button className='primary pill'>{buttonText}</button>
        </a>
        <p className='m-t-sm' style={{ opacity: '.5' }}>
          Questions? Reach out to our dedicated customer support team at support@zencarepractice.com
        </p>
      </div>
    </PureModal>
  );
}

export default StandaloneZencarePracticeManagementRedirectModal;
