import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import rgx from '#/regex';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';
import storage from '#/storage';

export const RequestReset = (props) => {
  const email = new URLSearchParams(_.get(props, 'location.search', '')).get('email');
  const iv = {
    email,
  };
  const { requestReset, resetPasswordError } = props;

  const [rerequest, setRereqest] = useState(false);
  const [prevUsedEmail, setPrevUsedEmail] = useState(null);
  const [timesRequested, setTimesRequested] = useState(0);

  const handleSubmit = (v) => {
    let { email } = v;

    if (email && email.trim) {
      email = email.trim();
    }

    setPrevUsedEmail(email);
    requestReset({ email });

    const attempts = timesRequested;
    setTimesRequested(attempts + 1);
  };

  const troubleLoggingInLink =
    timesRequested >= 3 ? (
      <p style={{ textAlign: 'left' }} className='text-error w-100'>
        <i className='fas fa-exclamation-triangle'></i> Trouble logging in?{' '}
        <a href='/account/recover'>Recover your account.</a>
      </p>
    ) : (
      <></>
    );

  if (props.resetPasswordSuccess) {
    return (
      <>
        {rerequest ? (
          <p style={{ textAlign: 'center' }}>
            We've re-sent instructions for resetting your password to the email {prevUsedEmail}.
          </p>
        ) : (
          <p style={{ textAlign: 'center' }}>
            Thanks! Check your email for instructions to reset your password.
          </p>
        )}
        <p style={{ textAlign: 'center' }}>
          Didn't get the email? Check your spam folder or{' '}
          <a
            href='/#'
            onClick={(e) => {
              e.preventDefault();
              setRereqest(true);
              handleSubmit({ email: prevUsedEmail });
            }}
          >
            resend.
          </a>
        </p>
        {troubleLoggingInLink}
      </>
    );
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={iv}
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = 'Email required.';
        }

        if (values.email && values.email.trim && !rgx.email.test(values.email.trim())) {
          errors.email = 'Please enter a valid email address.';
        }

        return errors;
      }}
      render={({ handleSubmit, pristine, invalid, errors }) => (
        <div style={{ textAlign: 'center' }} className='reset-password'>
          <p>Reset your password by inputting the email associated with your Zencare account.</p>
          <p>You'll receive an email with instructions to create a new password.</p>
          <Field name='email' component='input' type='input' />
          {!pristine && errors.email && (
            <p style={{ textAlign: 'left' }} className='text-error w-100'>
              {errors.email}
            </p>
          )}
          {resetPasswordError && (
            <p style={{ textAlign: 'left' }} className='text-error w-100'>
              <i className='fas fa-exclamation-triangle'></i>
              {resetPasswordError}
            </p>
          )}
          {troubleLoggingInLink}
          <button className='primary md m-t-md' disabled={invalid} onClick={handleSubmit}>
            Reset Password
          </button>
        </div>
      )}
    />
  );
};

export default connect((state) => state.auth, { requestReset: actions.requestReset })(RequestReset);
