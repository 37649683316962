import React from 'react';
import { connect } from 'react-redux';
import providerName from '#/providerName';
import steps from '@/onboarding/steps';
import ProgressIndicator from '@/onboarding/ProgressIndicator';
import PhotosForm from '~/components/onboarding/PhotosForm';

import _ from 'lodash';

export const PhotosFor = ({ match, allProviders, accounts }) => {
  let provider;
  try {
    const providerId = _.get(match, 'params.providerId', -1);
    provider =
      allProviders.find((p) => parseInt(p.id) === parseInt(providerId)) ||
      _.flatten(accounts.map((a) => a.providers)).find(
        (p) => parseInt(p.id) === parseInt(providerId)
      );
  } catch (e) {}

  const currentStep = 4;

  if (!provider) {
    return null;
  }

  return (
    <div className='flex column align-center'>
      <ProgressIndicator steps={steps} curStep={currentStep} />
      <div className='w-100'>
        <h1 className='m-b-md p-l-xl'>
          Complete {providerName(provider)}'s profile!{' '}
          <i className='fad fa-stars text-quaternary'></i>
          <br></br>
        </h1>
      </div>
      <div
        className='onboarding-form'
        style={{
          maxWidth: '90%',
          minWidth: '90%',
        }}
      >
        {/* Note: We need to pass provider id as prop
            in so we can set it to id of one being created */}
        <PhotosForm key={currentStep} providerId={provider.id} />
      </div>
    </div>
  );
};

export default connect(
  ({ main }) => ({
    allProviders: main.allProviders,
    accounts: _.get(main, 'accounts', []).filter((a) => !a.error),
  }),
  {}
)(PhotosFor);
