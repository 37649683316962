import { createModule } from 'saga-slice';
import { put } from 'redux-saga/effects';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';

interface AddPracticeManagementAccountSagaParamBinding {
  payload: AddPracticeManagementAccountSagaParam;
}

interface AddPracticeManagementAccountFromZpmSignupSagaParamBinding {
  payload: AddPracticeManagementAccountFromZpmSignupSagaParam;
}

interface ListPracticeManagementAccountsSagaParamBinding {
  payload: ListPracticeManagementAccountsSagaParam;
}

const sagaSliceModule = createModule({
  name: 'practiceManagement',
  initialState: {
    isLoading: false,
    hasPracticeManagementAccountStatusLoaded: false,
    hasPracticeManagementAccount: false,
    firstPracticeManagementAccount: null,
    standaloneZencareAccountId: null,
    previewTotal: 0,
    previewSubtotal: 0,
    previewCoupon: null,
    setupComplete: false,
    practiceManagementSubdomainName: '',
    isClinicBookingPageReady: false,
  },
  reducers: {
    addPracticeManagementAccount: loadingReducer,
    addPracticeManagementAccountFailure: failReducer,
    addPracticeManagementAccountSuccess: notLoadingReducer,

    addPracticeManagementAccountFromZpmSignup: loadingReducer,
    addPracticeManagementAccountFromZpmSignupFailure: failReducer,
    addPracticeManagementAccountFromZpmSignupSuccess: notLoadingReducer,

    listPracticeManagementAccounts: loadingReducer,
    listPracticeManagementAccountsFailure: failReducer,
    listPracticeManagementAccountsSuccess: (state, payload) => {
      state.isLoading = false;
      // First reset state
      state.firstPracticeManagementAccount = null;
      state.hasPracticeManagementAccount = false;

      if (payload && payload.length) {
        state.firstPracticeManagementAccount = payload[0];
        state.hasPracticeManagementAccount = true;
      }
      state.hasPracticeManagementAccountStatusLoaded = true;
    },
    createStandaloneZencareAccount: loadingReducer,
    createStandaloneZencareAccountSuccess: (state, payload) => {
      state.standaloneZencareAccountId = payload.account_id;
    },
    createStandaloneZencareAccountFailure: failReducer,
    setStandaloneAccountId: (state, payload) => {
      state.standaloneZencareAccountId = payload.account_id;
    },
    zpmSignupPay: loadingReducer,
    zpmSignupPayFailure: failReducer,
    zpmSignupPaySuccess: (state, payload) => {
      state.practiceManagementSubdomainName = payload.practice_management_subdomain;
      state.setupComplete = true;
      state.isLoading = false;
    },

    // Loading UX updates often on the button due to this loading reducer,
    // but it is helpful for displaying up to date error messages etc.
    zpmSignupPayPreview: loadingReducer,
    zpmSignupPayPreviewFailure: failReducer,
    zpmSignupPayPreviewSuccess: (state, payload) => {
      state.previewTotal = payload?.preview?.total / 100;
      state.previewSubtotal = payload?.preview?.subtotal / 100;
      state.previewCoupon = payload?.preview?.discount?.coupon;
      state.isLoading = false;
    },
    setPracticeManagementSubdomainName: (state, payload) => {
      state.practiceManagementSubdomainName = payload;
    },
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    removePreviewCoupon: (state) => {
      state.previewCoupon = null;
    },
    setSetupComplete: (state) => {
      state.setupComplete = true;
    },

    isClinicBookingPageReady: loadingReducer,
    isClinicBookingPageReadySuccess: (state, payload) => {
      state.isClinicBookingPageReady = payload.isReady || false;
      state.isLoading = false;
    },
    isClinicBookingPageReadyFailure: failReducer,
  },

  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.addPracticeManagementAccount]: function* ({
        payload: { params, apiPayload },
      }: AddPracticeManagementAccountSagaParamBinding) {
        const { provider_id } = params;
        yield sagaApi.post(
          `/portal/practice-management-account/add/${provider_id}`,
          apiPayload,
          A.addPracticeManagementAccountSuccess,
          A.addPracticeManagementAccountFailure
        );
        yield put(A.listPracticeManagementAccounts({ params }));
      },
      [A.listPracticeManagementAccounts]: function* ({
        payload: { params },
      }: ListPracticeManagementAccountsSagaParamBinding) {
        const { provider_id } = params;
        yield sagaApi.get(
          `/portal/practice-management-account/list/${provider_id}`,
          A.listPracticeManagementAccountsSuccess,
          A.listPracticeManagementAccountsFailure
        );
      },
      [A.createStandaloneZencareAccount]: function* () {
        // Should return account_id
        yield sagaApi.post(
          `/zpm-signup/create-standalone-zencare-account`,
          {},
          A.createStandaloneZencareAccountSuccess,
          A.createStandaloneZencareAccountFailure
        );
      },

      [A.addPracticeManagementAccountFromZpmSignup]: function* ({
        payload: { apiPayload },
      }: AddPracticeManagementAccountFromZpmSignupSagaParamBinding) {
        yield sagaApi.post(
          '/zpm-signup/add-practice-management-account',
          apiPayload,
          A.addPracticeManagementAccountFromZpmSignupSuccess,
          A.addPracticeManagementAccountFromZpmSignupFailure
        );
      },
      // Create ZPM standalone subscription
      [A.zpmSignupPay]: function* ({
        payload: { params, apiPayload },
      }: {
        payload: ZpmSignupPayPayload;
      }) {
        yield sagaApi.post(
          `/zpm-signup/pay/${params.account_id}`,
          apiPayload,
          A.zpmSignupPaySuccess,
          A.zpmSignupPayFailure
        );
      },
      // Preview ZPM standalone subscription
      [A.zpmSignupPayPreview]: function* ({
        payload: { apiPayload },
      }: {
        payload: ZpmSignupPayPreviewPayload;
      }) {
        yield sagaApi.post(
          `/zpm-signup/pay-preview`,
          apiPayload,
          A.zpmSignupPayPreviewSuccess,
          A.zpmSignupPayPreviewFailure
        );
      },
      [A.isClinicBookingPageReady]: function* ({
        payload: { params },
      }: {
        payload: IsClinicBookingPageReadyPayload;
      }) {
        yield sagaApi.get(
          `/portal/practice-management/clinic-booking-page-status/${params.provider_id}`,
          A.isClinicBookingPageReadySuccess,
          A.isClinicBookingPageReadyFailure
        );
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
