import React from 'react';
import { tabOptions } from './constants';

export default ({ isOnline, setOnline }) => (
  <>
    <h5 className='w-100'>Is this an in-person or an online practice?</h5>
    <div className='tab-select m-b-sm'>
      {Object.entries(tabOptions).map(([key, val], j) => (
        <div key={j} className='container'>
          <input
            type='radio'
            component='input'
            id={`remote_${val}`}
            value={val}
            checked={isOnline === val}
            name={`remote_${val}`}
            onChange={setOnline && setOnline.bind(null, val)}
          />
          <label className='flex-center' htmlFor={`remote_${val}`}>
            {key}
          </label>
        </div>
      ))}
    </div>
  </>
);
