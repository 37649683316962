import { Link } from 'react-router-dom';
import _ from 'lodash';
import { statesWithMailservs } from '../../components/static-pages/ProviderCommunity';
import providerHasSubscriptionInAccount from '#/providerHasSubscriptionInAccount';

const noActiveSubscription = (provider, accounts) => {
  if (!accounts || !accounts.length) {
    return false;
  }

  if (provider.hasPaidPlan) {
    return false;
  }

  return !accounts.some((a) => providerHasSubscriptionInAccount(provider, a));
};

const showProviderCommunity = (provider) => {
  const validatedLicenseProviderStates = provider?.locations
    ?.filter((x) => x.should_display === 1)
    .map((x) => x.state);

  const statesWithMailservesArray = Object.keys(statesWithMailservs);

  return validatedLicenseProviderStates.some((state) => statesWithMailservesArray.includes(state));
};

const sideNavGroup = [
  {
    auth: true,
    title: 'Dashboard',
    link: {
      icon: 'home',
      text: 'Dashboard',
      to: '/',
      component: Link,
    },
  },
  {
    auth: true,
    title: 'Availability & Locations',
    link: {
      icon: 'calendar-check',
      text: 'Availability & Locations',
      to: '/profile/availability-locations',
      component: Link,
    },
  },
  {
    auth: true,
    title: 'Client Referrals',
    link: {
      icon: 'users-medical',
      text: 'Client Referrals',
      to: '/client-referrals',
      component: Link,
      showFn: (provider) => !provider.calendar || !provider.calendar.length,
    },
  },
  {
    title: 'Client Referrals',
    auth: true,
    icon: 'couch',
    showFn: (provider) => provider.calendar && provider.calendar.length,
    links: [
      {
        icon: 'calendar-day',
        text: 'Call Calendar',
        to: '/calendar',
        component: Link,
      },
      {
        icon: 'envelope',
        text: 'Client Messages',
        to: '/messages',
        component: Link,
      },
      {
        icon: 'list',
        text: 'Full Client List',
        to: '/client-list',
        component: Link,
      },
    ],
  },
  {
    auth: true,
    title: 'View Profile',
    link: {
      icon: 'user',
      text: 'View Profile',
      href: ({ slug, primaryProfession, type }) => {
        if (type === 'practice') {
          return `${process.env.ZENCARE_WEBSITE_URL}/practice/${slug}`;
        }

        return `${process.env.ZENCARE_WEBSITE_URL}/provider/${primaryProfession}/${slug}`;
      },
      target: '_blank',
      showFn: (provider) => ['active', 'reviewing', 'pending'].includes(provider.status),
    },
  },

  {
    auth: true,
    title: 'Edit Profile',
    icon: 'user-edit',
    links: [
      {
        icon: 'user-circle',
        text: 'General Information',
        to: '/profile/general-information',
        component: Link,
      },
      {
        icon: 'sack-dollar',
        text: 'Insurance & Fees',
        to: '/profile/insurance-fees',
        component: Link,
      },
      {
        icon: 'user-graduate',
        text: 'Specialties',
        to: '/profile/specialties',
        component: Link,
      },
      {
        icon: 'user-friends',
        text: 'Clientele & Approach',
        to: '/profile/clientele-approach',
        component: Link,
      },
      {
        icon: 'users-medical',
        text: 'Practice Providers',
        to: '/practice/providers',
        component: Link,
        showFn: (p) => p.type === 'practice',
      },
      {
        icon: 'briefcase',
        text: 'Work & Education',
        to: '/profile/education-employment',
        component: Link,
        showFn: (p) => p.type === 'individual',
      },
      {
        icon: 'file-alt',
        text: 'Statement & Message',
        to: '/profile/message-statement',
        component: Link,
        showFn: (p) => p.type === 'individual',
      },
      {
        icon: 'file-alt',
        text: 'Practice Description',
        to: '/profile/message-statement',
        component: Link,
        showFn: (p) => p.type === 'practice',
      },
      {
        icon: 'photo-video',
        text: 'Photos & Video',
        to: '/profile/photos-video',
        component: Link,
      },
      {
        icon: 'user-friends',
        text: 'Therapy Groups',
        to: '/therapy-groups',
        component: Link,
      },
      {
        icon: 'comment-dots',
        text: 'Testimonials',
        to: '/testimonials',
        component: Link,
      },
    ],
  },
  {
    title: 'Account Benefits',
    auth: true,
    icon: 'stars',
    links: [
      {
        icon: 'award',
        text: 'Trust Badge',
        to: '/benefits/web-badge',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'camera',
        text: 'Photo/Videoshoot',
        to: '/benefits/photo-videoshoot',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'newspaper',
        text: 'Blog Feature',
        to: '/benefits/blog-features',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'video-plus',
        text: 'Embed Video',
        to: '/benefits/video-embedding',
        component: Link,
        showFn: (provider) => _.get(provider, 'custom_content.videos', []).length,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'asterisk',
        text: 'Collect References',
        to: '/benefits/collect-references',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'couch',
        text: 'Provider Community',
        to: '/benefits/provider-community',
        component: Link,
        showFn: showProviderCommunity,
        lockFn: noActiveSubscription,
      },

      /* Only link hidden as per issue #630 {
                icon: 'hands-heart',
                text: 'Client Matching',
                to: '/benefits/client-matching',
                component: Link,
                lockFn: noActiveSubscription
            },*/
    ],
  },
  {
    auth: true,
    title: 'Practice Resources',
    icon: 'lightbulb-on',
    links: [
      {
        icon: 'fas phone-volume',
        text: 'Phone Consult Tips',
        to: '/tips/phone-consultations',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'rss',
        to: '/tips/increasing-referrals',
        text: 'Increasing Referrals',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'ellipsis-h',
        to: '/tips/membership',
        text: 'Membership Tips',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'camera-alt',
        text: 'Photo Licensing',
        to: '/benefits/photo-licensing',
        component: Link,
      },
      {
        icon: 'search-dollar',
        text: 'Fee Calculator',
        to: '/fee-calculator',
        component: Link,
        lockFn: noActiveSubscription,
      },
      {
        icon: 'gift',
        text: 'Refer + Earn',
        to: '/refer-and-earn',
        component: Link,
        lockFn: noActiveSubscription,
      },
    ],
  },
  {
    auth: true,
    title: 'Support',
    icon: 'question-circle',
    links: [
      {
        icon: 'comments',
        text: 'Contact Zencare',
        to: '/contact',
        component: Link,
      },
      {
        icon: 'concierge-bell',
        text: 'Help Desk',
        to: '/help-desk',
        component: Link,
      },
    ],
  },
];

const practiceInboxSideNavGroup = [
  {
    auth: true,
    title: 'Practice Appointments',
    link: {
      icon: 'calendar-day',
      text: 'Practice Appointments',
      to: '/practice-inbox/appointments',
      exact: true,
      component: Link,
    },
  },
  {
    auth: true,
    title: 'Practice Client Messages',
    link: {
      icon: 'envelope',
      text: 'Client Messages',
      to: '/practice-inbox/messages',
      exact: true,
      component: Link,
    },
  },
  {
    auth: true,
    title: 'Practice Full Client List',
    link: {
      icon: 'list',
      text: 'Full Client List',
      to: '/practice-inbox/client-list',
      exact: true,
      component: Link,
    },
  },
];

const canShow = ({ roles, auth, noauth }, role, isAuthenticated) => {
  // console.log({
  //     roles,
  //     auth,
  //     noauth
  // }, role, isAuthenticated);

  // if explicitly only for unauthed
  if (noauth === true) {
    return noauth && !isAuthenticated;
  }

  // default allow
  let allow = true;

  // if explicitly only for auth, default not allow
  if (auth !== undefined) {
    allow = false;
  }

  // check if authenticated
  if (auth === true && isAuthenticated) {
    allow = auth;
  }

  // if allowed and has roles
  if (allow && roles) {
    allow = roles.includes(role);
  }

  return allow;
};

export const scopedLinks = (role, auth = false) =>
  sideNavGroup
    .filter((item) => canShow(item, role, auth))
    .map((item) => {
      const i = { ...item };
      if (item.links) {
        i.links = item.links.filter((link) => canShow(link, role, auth));
      }

      return i;
    });
export const practiceInboxScopedLinks = (role, auth = false) =>
  practiceInboxSideNavGroup
    .filter((item) => canShow(item, role, auth))
    .map((item) => {
      const i = { ...item };
      if (item.links) {
        i.links = item.links.filter((link) => canShow(link, role, auth));
      }

      return i;
    });
