import React from 'react';

export const FeeCalculatorContainer = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      width: '100%',
      height: '100%',
      overflowY: 'hidden',
    }}
  >
    <iframe
      title='Fee Calculator'
      src='https://therapist.zencare.co/therapy-fee-calculator-members'
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  </div>
);

export default FeeCalculatorContainer;
