import { put } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { sagaApi } from '#/apis';
import { failReducer, loadingReducer } from 'saga-slice-helpers';
import { flashError } from '+/flashes/redux';

const initialState = {
  isFetching: false,
  isLoading: false,
  error: null,
  submitCancelRequestFailure: false,
  submitCancelRequestResult: null,
  referralsCountByYearResult: null,
};

const sagaSliceModule = createModule({
  name: 'support',

  initialState: {
    ...initialState,
  },

  reducers: {
    getReferralsCountByYear: loadingReducer,
    getReferralsCountByYearSuccess: (state, payload) => {
      state.isLoading = false;
      state.referralsCountByYearResult = payload;
    },
    getReferralsCountByYearFailure: failReducer,
    submitProviderCancelRequest: (state, payload) => {
      state.isLoading = true;
      state.submitCancelRequestResult = null;
      state.submitCancelRequestFailure = false;
    },
    submitProviderCancelRequestSuccess: (state, payload) => {
      state.isLoading = false;
      state.submitCancelRequestResult = payload;
    },
    submitProviderCancelRequestFailure: (state, payload) => {
      state.submitCancelRequestFailure = true;
      state.isLoading = false;
    },
  },
  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.submitProviderCancelRequest]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.put(
          `/portal/provider/${id}/submit-cancel-request`,
          payload,
          A.submitProviderCancelRequestSuccess,
          A.submitProviderCancelRequestFailure
        );
      },
      [A.submitProviderCancelRequestFailure]: function* () {
        yield put(
          flashError(
            'We are sorry but there was an error submitting your cancel request. Please email support@zencare.co'
          )
        );
      },
      [A.getReferralsCountByYear]: function* ({ payload }) {
        yield sagaApi.get(
          `portal/provider/${payload.provider_id}/referrals/count-by-year`,
          A.getReferralsCountByYearSuccess,
          A.getReferralsCountByYearFailure
        );
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
