import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as mainActions } from '../components/main/sagaSlice';

import Flashes from '+/flashes/Container';

export const AuthLayout = (props) => {
  useEffect(() => {
    if (!props.isSignedIn && props.provider) {
      props.clearStore();
    }
  });

  return (
    <div id='layout' className='auth'>
      <Flashes />

      <div className='form-container'>
        <div className='logo-container'>
          <a href='/login'>
            <img
              alt='Zencare Trusted Therapist'
              src='https://zencareassets.s3.amazonaws.com/zencare+trusted+therapist.png'
            ></img>
          </a>
        </div>

        {props.route && props.route.title && (
          <h1 className='text-center p-t-lg'>{props.route.title}</h1>
        )}

        {props.children}
      </div>
      <div className='image-container'>
        <img
          src='https://zencareassets.s3.amazonaws.com/zencare+onboarding+-+coffee.jpg'
          alt='Zencare'
          style={{ height: '100vh' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, main }) => ({
  isSignedIn: auth.isSignedIn,
  provider: main.provider,
});
const actions = {
  clearStore: mainActions.clearStore,
};

export default connect(mapStateToProps, actions)(AuthLayout);
