import { createModule } from 'saga-slice';
import { call } from 'redux-saga/effects';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';
import _ from 'lodash';
import dayjs from 'dayjs';
import { ConvertToMonthlySagaParamBinding } from '../payment/seat-based/types/types';

interface SubscribeToPlanApiTestCallSagaParamBinding {
  payload: SubscribeToPlanApiTestCallSagaParam;
}

interface UpgradeToPaidReimbursifyProductParamBinding {
  payload: UpgradeToPaidReimbursifyProductParam;
}

interface UpgradeToPaidReimbursifyProductPreviewParamBinding {
  payload: UpgradeToPaidReimbursifyProductPreviewParam;
}

interface UpgradeProductSagaParamBinding {
  payload: UpgradeProductSagaParam;
}

interface AddSeatSagaParamBinding {
  payload: AddSeatSagaParam;
}

interface GetAccountConversationsSagaParamBinding {
  payload: GetAccountConversationsSagaParam;
}

interface GetAccountAppointmentsSagaParamBinding {
  payload: GetAccountAppointmentsSagaParam;
}

interface GetAccountClientsSagaParamBinding {
  payload: GetAccountClientsSagaParam;
}

const sagaSliceModule = createModule({
  name: 'developerTools',
  initialState: {
    isLoading: false,
    stripeCustomerId: '',
    stripeSubscriptionId: '',
    previewedSubscriptionProrationDate: null,
    standaloneZencareAccountId: null,
  },
  reducers: {
    updateAddressTestCall: loadingReducer,
    updateAddressTestCallFailure: failReducer,
    updateAddressTestCallSuccess: notLoadingReducer,

    createStandaloneZencareAccount: loadingReducer,
    createStandaloneZencareAccountSuccess: (state, payload) => {
      state.standaloneZencareAccountId = payload.account_id;
    },
    createStandaloneZencareAccountFailure: failReducer,

    zpmSignupPayTestCall: loadingReducer,
    zpmSignupPayTestCallFailure: failReducer,
    zpmSignupPayTestCallSuccess: notLoadingReducer,

    zpmSignupPayPreviewTestCall: loadingReducer,
    zpmSignupPayPreviewTestCallFailure: failReducer,
    zpmSignupPayPreviewTestCallSuccess: notLoadingReducer,

    subscribeToPlanApiTestCall: loadingReducer,
    subscribeToPlanApiTestCallFailure: failReducer,
    subscribeToPlanApiTestCallSuccess: (state, payload) => {
      state.isLoading = false;

      console.log(`subscribeToPlanApiTestCallSuccess payload: ${JSON.stringify(payload)}`);

      console.log(
        `subscribeToPlanApiTestCallSuccess payload.stripe_customers[0].id: ${JSON.stringify(
          payload.stripe_customers[0].id
        )}`
      );

      console.log(
        `subscribeToPlanApiTestCallSuccess stripe_subscriptions: ${JSON.stringify(
          payload.stripe_customers[0].stripe_subscriptions
        )}`
      );
      const ordered = _.orderBy(
        payload.stripe_customers[0].stripe_subscriptions,
        (x) => dayjs(x.created).unix(),
        'desc'
      );
      const mostRecentSubscription = ordered[0];

      state.stripeCustomerId = payload.stripe_customers[0].id;
      state.stripeSubscriptionId = mostRecentSubscription.id;

      console.log(
        `subscribeToPlanApiTestCallSuccess state.stripeCustomerId: ${JSON.stringify(
          state.stripeCustomerId
        )}`
      );
      console.log(
        `subscribeToPlanApiTestCallSuccess state.stripeSubscriptionId: ${JSON.stringify(
          state.stripeSubscriptionId
        )}`
      );

      /* Example of what payload looks like
      const examplePayload = {
        id: 2911,
        name: 'TestSeatBasedAug8at1142 TestSeatBasedAug8at1142',
        created_at: '2022-08-08T15:42:51.000Z',
        updated_at: '2022-08-08T15:42:51.000Z',
        settings: null,
        meta: null,
        stripe_customers: [
          {
            id: 'cus_MCy72EqqmOamKM',
            created: '2022-08-08T15:47:39.000Z',
            delinquent: 0,
            livemode: 0,
            name: 'TestSeatBasedAug8at1142 TestSeatBasedAug8at1142',
            description: 'TestSeatBasedAug8at1142 TestSeatBasedAug8at1142',
            phone: null,
            metadata: { user_id: 10940, account_id: 2911, provider_id: 3966 },
            email: 'elijah.lofgren+TestSeatBasedAug8at1142@zencare.co',
            default_source_object: {
              id: 'src_1LUYB4HEu9I8ej85nRqM1h5x',
              object: 'source',
              amount: null,
              card: {
                exp_month: 4,
                exp_year: 2042,
                last4: '4242',
                country: 'US',
                brand: 'Visa',
                address_zip_check: 'pass',
                cvc_check: 'pass',
                funding: 'credit',
                fingerprint: '1KnizRhSF5QwvSy0',
                three_d_secure: 'optional',
                name: null,
                address_line1_check: null,
                tokenization_method: null,
                dynamic_last4: null,
              },
              client_secret: 'src_client_secret_JPGNJmQ4xnCn5iyPepuAToCx',
              created: 1659973658,
              currency: 'usd',
              customer: 'cus_MCy72EqqmOamKM',
              flow: 'none',
              livemode: false,
              metadata: {},
              owner: {
                address: {
                  city: null,
                  country: null,
                  line1: null,
                  line2: null,
                  postal_code: '43333',
                  state: null,
                },
                email: null,
                name: null,
                phone: null,
                verified_address: null,
                verified_email: null,
                verified_name: null,
                verified_phone: null,
              },
              statement_descriptor: null,
              status: 'chargeable',
              type: 'card',
              usage: 'reusable',
            },
            sources: [
              {
                id: 'src_1LUYB4HEu9I8ej85nRqM1h5x',
                object: 'source',
                amount: null,
                card: {
                  exp_month: 4,
                  exp_year: 2042,
                  last4: '4242',
                  country: 'US',
                  brand: 'Visa',
                  address_zip_check: 'pass',
                  cvc_check: 'pass',
                  funding: 'credit',
                  fingerprint: '1KnizRhSF5QwvSy0',
                  three_d_secure: 'optional',
                  name: null,
                  address_line1_check: null,
                  tokenization_method: null,
                  dynamic_last4: null,
                },
                client_secret: 'src_client_secret_JPGNJmQ4xnCn5iyPepuAToCx',
                created: 1659973658,
                currency: 'usd',
                customer: 'cus_MCy72EqqmOamKM',
                flow: 'none',
                livemode: false,
                metadata: {},
                owner: {
                  address: {
                    city: null,
                    country: null,
                    line1: null,
                    line2: null,
                    postal_code: '43333',
                    state: null,
                  },
                  email: null,
                  name: null,
                  phone: null,
                  verified_address: null,
                  verified_email: null,
                  verified_name: null,
                  verified_phone: null,
                },
                statement_descriptor: null,
                status: 'chargeable',
                type: 'card',
                usage: 'reusable',
              },
            ],
            stripe_subscriptions: [
              {
                id: 'sub_1LUYB6HEu9I8ej85RRx8XrWG',
                customer_id: 'cus_MCy72EqqmOamKM',
                cancel_at_period_end: 0,
                livemode: 0,
                status: 'active',
                created: '2022-08-08T15:47:40.000Z',
                start_date: '2022-08-08T15:47:40.000Z',
                ended_at: null,
                cancel_at: null,
                canceled_at: null,
                current_period_start: '2022-08-08T15:47:40.000Z',
                current_period_end: '2023-08-08T15:47:40.000Z',
                items: {
                  object: 'list',
                  data: [
                    {
                      id: 'si_MCy7ZMtiUDHNCt',
                      object: 'subscription_item',
                      billing_thresholds: null,
                      created: 1659973661,
                      metadata: {},
                      plan: {
                        id: 'price_1LSo5iHEu9I8ej85BZSV84Qq',
                        object: 'plan',
                        active: true,
                        aggregate_usage: null,
                        amount: null,
                        amount_decimal: null,
                        billing_scheme: 'tiered',
                        created: 1659558174,
                        currency: 'usd',
                        interval: 'year',
                        interval_count: 1,
                        livemode: false,
                        metadata: {},
                        nickname: 'Zencare Premium - Annual',
                        product: 'prod_MBAQxQYzEb8tFi',
                        tiers_mode: 'graduated',
                        transform_usage: null,
                        trial_period_days: null,
                        usage_type: 'licensed',
                      },
                      price: {
                        id: 'price_1LSo5iHEu9I8ej85BZSV84Qq',
                        object: 'price',
                        active: true,
                        billing_scheme: 'tiered',
                        created: 1659558174,
                        currency: 'usd',
                        custom_unit_amount: null,
                        livemode: false,
                        lookup_key: 'zencare_premium_annual_v2',
                        metadata: {},
                        nickname: 'Zencare Premium - Annual',
                        product: 'prod_MBAQxQYzEb8tFi',
                        recurring: {
                          aggregate_usage: null,
                          interval: 'year',
                          interval_count: 1,
                          trial_period_days: null,
                          usage_type: 'licensed',
                        },
                        tax_behavior: 'unspecified',
                        tiers_mode: 'graduated',
                        transform_quantity: null,
                        type: 'recurring',
                        unit_amount: null,
                        unit_amount_decimal: null,
                      },
                      quantity: 1,
                      subscription: 'sub_1LUYB6HEu9I8ej85RRx8XrWG',
                      tax_rates: [],
                    },
                  ],
                  has_more: false,
                  total_count: 1,
                  url: '/v1/subscription_items?subscription=sub_1LUYB6HEu9I8ej85RRx8XrWG',
                },
                discount: null,
                metadata: {},
                default_payment_method_object: {
                  id: 'src_1LUYB4HEu9I8ej85nRqM1h5x',
                  object: 'payment_method',
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: '43333',
                      state: null,
                    },
                    email: null,
                    name: null,
                    phone: null,
                  },
                  card: {
                    brand: 'visa',
                    checks: {
                      address_line1_check: null,
                      address_postal_code_check: 'pass',
                      cvc_check: 'pass',
                    },
                    country: 'US',
                    exp_month: 4,
                    exp_year: 2042,
                    fingerprint: '1KnizRhSF5QwvSy0',
                    funding: 'credit',
                    generated_from: null,
                    last4: '4242',
                    networks: { available: ['visa'], preferred: null },
                    three_d_secure_usage: { supported: true },
                    wallet: null,
                  },
                  created: 1659973658,
                  customer: 'cus_MCy72EqqmOamKM',
                  livemode: false,
                  metadata: {},
                  type: 'card',
                },
              },
            ],
          },
        ],
      };
      */
    },
    upgradeToPaidReimbursifyProduct: loadingReducer,
    upgradeToPaidReimbursifyProductFailure: failReducer,
    upgradeToPaidReimbursifyProductSuccess: notLoadingReducer,

    upgradeToPaidReimbursifyProductPreview: loadingReducer,
    upgradeToPaidReimbursifyProductPreviewFailure: failReducer,
    upgradeToPaidReimbursifyProductPreviewSuccess: notLoadingReducer,

    upgradeProduct: loadingReducer,
    upgradeProductFailure: failReducer,
    upgradeProductSuccess: notLoadingReducer,

    upgradeProductPreview: loadingReducer,
    upgradeProductPreviewFailure: failReducer,
    upgradeProductPreviewSuccess: (state, payload) => {
      state.isLoading = false;
      state.previewedSubscriptionProrationDate = payload.previewedSubscriptionProrationDate;
    },

    convertToMonthly: loadingReducer,
    convertToMonthlyFailure: failReducer,
    convertToMonthlySuccess: notLoadingReducer,

    addSeat: loadingReducer,
    addSeatFailure: failReducer,
    addSeatSuccess: notLoadingReducer,

    addSeatPreview: loadingReducer,
    addSeatPreviewFailure: failReducer,
    addSeatPreviewSuccess: (state, payload) => {
      state.isLoading = false;
      state.previewedSubscriptionProrationDate = payload.previewedSubscriptionProrationDate;
    },

    getAccountConversations: loadingReducer,
    getAccountConversationsFailure: failReducer,
    getAccountConversationsSuccess: (state, payload) => {
      state.isLoading = false;
      console.log(`getAccountConversationsSuccess: payload = ${payload}`);
    },

    getAccountAppointments: loadingReducer,
    getAccountAppointmentsFailure: failReducer,
    getAccountAppointmentsSuccess: (state, payload) => {
      state.isLoading = false;
      console.log(`getAccountAppointmentsSuccess: payload = ${payload}`);
    },

    getAccountClients: loadingReducer,
    getAccountClientsFailure: failReducer,
    getAccountClientsSuccess: (state, payload) => {
      state.isLoading = false;
      console.log(`getAccountClientsSuccess: payload = ${payload}`);
    },
  },

  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.updateAddressTestCall]: function* ({
        payload: { params, apiPayload },
      }: {
        payload: UpdateAddressTestCallSagaParam;
      }) {
        yield sagaApi.post(
          `portal/account/update-address/${params.account_id}`,
          apiPayload,
          A.updateAddressTestCallSuccess,
          A.updateAddressTestCallFailure
        );
      },
      [A.createStandaloneZencareAccount]: function* () {
        // Should return account_id
        yield sagaApi.post(
          `/zpm-signup/create-standalone-zencare-account`,
          {},
          A.createStandaloneZencareAccountSuccess,
          A.createStandaloneZencareAccountFailure
        );
      },
      // Create ZPM standalone subscription
      [A.zpmSignupPayTestCall]: function* ({
        payload: { params, apiPayload, sourcePromise },
      }: {
        payload: ZpmSignupPayPayload;
      }) {
        console.log(`zpmSignupPayTestCall called with params.account_id = ${params.account_id}`);

        // @ts-expect-error - $TSFixMe
        const result = yield call(sourcePromise);

        if (result.error) {
          throw result.error;
        }

        apiPayload.source = result.source;

        yield sagaApi.post(
          `/zpm-signup/pay/${params.account_id}`,
          apiPayload,
          A.zpmSignupPayTestCallSuccess,
          A.zpmSignupPayTestCallFailure
        );
      },
      // Preview ZPM standalone subscription
      [A.zpmSignupPayPreviewTestCall]: function* ({
        payload: { apiPayload },
      }: {
        payload: ZpmSignupPayPreviewPayload;
      }) {
        console.log(`zpmSignupPayPreviewTestCall called`);

        yield sagaApi.post(
          `/zpm-signup/pay-preview`,
          apiPayload,
          A.zpmSignupPayPreviewTestCallSuccess,
          A.zpmSignupPayPreviewTestCallFailure
        );
      },
      [A.getAccountConversations]: function* ({
        payload: { params },
      }: GetAccountConversationsSagaParamBinding) {
        yield sagaApi.get(
          `portal/account/${params.account_id}/conversations`,
          A.getAccountConversationsSuccess,
          A.getAccountConversationsFailure
        );
      },
      [A.getAccountAppointments]: function* ({
        payload: { params },
      }: GetAccountAppointmentsSagaParamBinding) {
        yield sagaApi.get(
          `portal/account/${params.account_id}/appointments`,
          A.getAccountAppointmentsSuccess,
          A.getAccountAppointmentsFailure
        );
      },
      [A.getAccountClients]: function* ({
        payload: { params },
      }: GetAccountClientsSagaParamBinding) {
        yield sagaApi.get(
          `portal/account/${params.account_id}/clients`,
          A.getAccountClientsSuccess,
          A.getAccountClientsFailure
        );
      },
      // Create subscription
      [A.subscribeToPlanApiTestCall]: function* ({
        payload: { params, apiPayload, sourcePromise },
      }: SubscribeToPlanApiTestCallSagaParamBinding) {
        console.log('subscribeToPlanApiTestCall called');

        // @ts-expect-error - $TSFixMe
        const result = yield call(sourcePromise);

        if (result.error) {
          throw result.error;
        }

        apiPayload.source = result.source;

        yield sagaApi.post(
          `/portal/seat-based/subscribe/${params.provider_id}`,
          apiPayload,
          A.subscribeToPlanApiTestCallSuccess,
          A.subscribeToPlanApiTestCallFailure
        );
      },

      [A.upgradeToPaidReimbursifyProduct]: function* ({
        payload: { params, apiPayload },
      }: UpgradeToPaidReimbursifyProductParamBinding) {
        const { stripe_customer_id, stripe_subscription_id } = params;
        yield sagaApi.post(
          `/portal/seat-based/upgrade-reimbursify/${stripe_customer_id}/${stripe_subscription_id}`,
          apiPayload,
          A.upgradeToPaidReimbursifyProductSuccess,
          A.upgradeToPaidReimbursifyProductFailure
        );
      },

      [A.upgradeToPaidReimbursifyProductPreview]: function* ({
        payload: { params, apiPayload },
      }: UpgradeToPaidReimbursifyProductPreviewParamBinding) {
        const { stripe_customer_id, stripe_subscription_id } = params;
        yield sagaApi.post(
          `/portal/seat-based/upgrade-reimbursify-preview/${stripe_customer_id}/${stripe_subscription_id}`,
          apiPayload,
          A.upgradeToPaidReimbursifyProductPreviewSuccess,
          A.upgradeToPaidReimbursifyProductPreviewFailure
        );
      },

      // Modify subscription
      [A.upgradeProduct]: function* ({
        payload: { params, apiPayload },
      }: UpgradeProductSagaParamBinding) {
        const { stripe_customer_id, stripe_subscription_id } = params;
        yield sagaApi.post(
          `/portal/seat-based/upgrade-product/${stripe_customer_id}/${stripe_subscription_id}`,
          apiPayload,
          A.upgradeProductSuccess,
          A.upgradeProductFailure
        );
      },
      [A.upgradeProductPreview]: function* ({
        payload: { params, apiPayload },
      }: UpgradeProductSagaParamBinding) {
        const { stripe_customer_id, stripe_subscription_id } = params;
        yield sagaApi.post(
          `/portal/seat-based/upgrade-product-preview/${stripe_customer_id}/${stripe_subscription_id}`,
          apiPayload,
          A.upgradeProductPreviewSuccess,
          A.upgradeProductPreviewFailure
        );
      },

      [A.convertToMonthly]: function* ({
        payload: { params, apiPayload },
      }: ConvertToMonthlySagaParamBinding) {
        const { stripe_customer_id, stripe_subscription_id } = params;
        yield sagaApi.post(
          `/portal/seat-based/convert-to-monthly/${stripe_customer_id}/${stripe_subscription_id}`,
          apiPayload,
          A.convertToMonthlySuccess,
          A.convertToMonthlyFailure
        );
      },

      [A.addSeat]: function* ({ payload: { params, apiPayload } }: AddSeatSagaParamBinding) {
        const { provider_id, stripe_customer_id, stripe_subscription_id } = params;
        yield sagaApi.post(
          `/portal/seat-based/add-seat/${provider_id}/${stripe_customer_id}/${stripe_subscription_id}`,
          apiPayload,
          A.addSeatSuccess,
          A.addSeatFailure
        );
      },
      [A.addSeatPreview]: function* ({ payload: { params, apiPayload } }: AddSeatSagaParamBinding) {
        const { stripe_customer_id, stripe_subscription_id } = params;
        yield sagaApi.post(
          `/portal/seat-based/add-seat-preview/${stripe_customer_id}/${stripe_subscription_id}`,
          apiPayload,
          A.addSeatPreviewSuccess,
          A.addSeatPreviewFailure
        );
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
