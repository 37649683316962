import { put } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';

import _ from 'lodash';

import { sagaApi, api } from '#/apis';

const sagaSliceModule = createModule({
  name: 'photos',

  initialState: {
    isFetching: false,
    isLoading: false,
    error: null,
  },

  reducers: {
    setProfilePic: loadingReducer,
    setProfilePicSuccess: notLoadingReducer,
    setProfilePicFailure: failReducer,

    createProfilePic: loadingReducer,
    createProfilePicSuccess: notLoadingReducer,
    createProfilePicFailure: failReducer,

    createGalleryPics: loadingReducer,
    createGalleryPicsSuccess: notLoadingReducer,
    createGalleryPicsFailure: failReducer,
  },
  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.setProfilePic]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;

        yield sagaApi.post(
          `portal/provider/${id}/profile-image`,
          payload,
          A.setProfilePicSuccess,
          A.setProfilePicFailure
        );
      },
      [A.setProfilePicSuccess]: function* (response) {
        yield put({
          type: 'main/getMe',
        });
      },

      [A.createProfilePic]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;

        yield sagaApi.put(
          `portal/provider/${id}/profile-image`,
          payload,
          A.createProfilePicSuccess,
          A.createProfilePicFailure
        );
      },
      [A.createProfilePicSuccess]: function* ({ payload }) {
        yield put({
          type: 'main/getMe',
        });
      },
      [A.createGalleryPics]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;

        yield sagaApi.put(
          `portal/provider/${id}/gallery-images`,
          payload.images,
          A.createGalleryPicsSuccess,
          A.createGalleryPicsFailure
        );
      },
      [A.createGalleryPicsSuccess]: function* () {
        yield put({
          type: 'main/getMe',
        });
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
