import mixpanel from 'mixpanel-browser';

const initMixpanel = () => {
  mixpanel.init(process.env.ZENCARE_MIXPANEL_TOKEN, {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
  });

  // This is an SPA so unlikely to capture most link clicks but let's capture perhaps first action after hitting login page.
  document.addEventListener('DOMContentLoaded', () => {
    mixpanel.track_links('a', 'link_click', {
      referrer: document.referrer,
    });
  });
};

export default initMixpanel;
