/* eslint-disable max-lines-per-function */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import { DateTime } from 'luxon';
import AppointmentModal from './AppointmentModal';
import useQueryParams from '+/hooks/useQueryParams';

export default function () {
  const provider_id = useSelector((state) => _.get(state, 'main.provider.id'));
  let appointments = useSelector((state) => _.get(state, 'appointments.appointments'));
  const currentAppointment = useSelector((state) => state.appointments.currentAppointment);
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.main.provider);
  const calendar = _.get(provider, 'calendar[0]');
  const tz = useSelector((state) =>
    calendar && calendar.timezone ? calendar.timezone : _.get(state, 'calendars.tz')
  );
  const [view, setView] = useState('upcoming');
  const [search, setSearch] = useState(null);
  const query = useQueryParams();

  useEffect(() => {
    const appointmentId = query.get('id');
    if (appointmentId) {
      dispatch(actions.openAppointment({ id: decodeURIComponent(appointmentId) }));
    }
  }, []);

  useEffect(() => {
    if (provider_id) {
      dispatch(actions.getAllAppointments({ provider_id }));
    }
  }, [provider_id]);

  if (!appointments) {
    return <p>Loading...</p>;
  }

  const dateKey = (datetime) =>
    datetime.setZone(tz).toLocaleString({
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });

  const groupByFunction = (a) => dateKey(DateTime.fromISO(a.start_time));

  const searchTerm = search ? search.trim().toLowerCase() : null;
  if (search && search.length) {
    appointments = appointments.filter(
      (a) =>
        _.get(a, 'user.first_name', '').toLowerCase().includes(searchTerm) ||
        _.get(a, 'user.last_name', '').toLowerCase().includes(searchTerm) ||
        _.get(a, 'user.email', '').toLowerCase().includes(searchTerm)
    );
  }

  const now = DateTime.utc().toISO();
  const pastAppointments = _.groupBy(
    _.orderBy(
      appointments.filter((a) => now > a.end_time && !a.canceled),
      'start_time',
      'desc'
    ),
    groupByFunction
  );

  const upcomingAppointments = _.groupBy(
    appointments.filter((a) => now <= a.end_time && !a.canceled),
    groupByFunction
  );

  const canceledAppointments = _.groupBy(
    _.orderBy(
      appointments.filter((a) => a.canceled),
      'start_time',
      'desc'
    ),
    groupByFunction
  );

  // Convert to ISO from formatted string and sort
  const sortByDate = (a, b) => {
    const dateA = DateTime.fromFormat(a, 'DDDD');
    const dateB = DateTime.fromFormat(b, 'DDDD');
    return dateA - dateB;
  };

  const appointmentKeys = Object.keys(upcomingAppointments);
  const upcomingEntries = appointmentKeys.sort(sortByDate);
  const upcomingSection = (
    <div className='appointments'>
      {upcomingEntries.map((k) => (
        <div key={k} className='appointment-day-group'>
          <div className='flex m-b-xs'>
            <h5 className='m-b-0'>{k}</h5>
            {dateKey(DateTime.utc()) === k && (
              <span
                className='text-quaternary m-l-sm'
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  letterSpacing: 1,
                  fontSize: '0.8rem',
                  alignSelf: 'end',
                }}
              >
                Today!
              </span>
            )}
            {dateKey(DateTime.utc().plus({ days: 1 })) === k && (
              <span
                className='text-secondary m-l-sm'
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  letterSpacing: 1,
                  fontSize: '0.8rem',
                  alignSelf: 'end',
                }}
              >
                Tomorrow
              </span>
            )}
          </div>
          {upcomingAppointments[k].map((a) => (
            <Appointment key={a.id} appointment={a} />
          ))}
        </div>
      ))}
      {upcomingEntries.length === 0 && !searchTerm && (
        <p>You have no upcoming appointments at this time.</p>
      )}
      {upcomingEntries.length === 0 && searchTerm && (
        <p>You have no upcoming appointments matching the search term "{searchTerm}."</p>
      )}
    </div>
  );

  const pastEntries = Object.keys(pastAppointments);
  const pastSection = (
    <div>
      {pastEntries.map((k) => (
        <div key={k} className='appointment-day-group'>
          <h5>{k}</h5>
          {pastAppointments[k].map((a) => (
            <Appointment key={a.id} appointment={a} />
          ))}
        </div>
      ))}
      {pastEntries.length === 0 && !searchTerm && (
        <p>You have no past appointments at this time.</p>
      )}
      {pastEntries.length === 0 && searchTerm && (
        <p>You have no past appointments matching the search term "{searchTerm}."</p>
      )}
    </div>
  );

  const canceledEntries = Object.keys(canceledAppointments);
  const canceledSection = (
    <div>
      {canceledEntries.map((k) => (
        <div key={k} className='appointment-day-group'>
          <h5>{k}</h5>
          {canceledAppointments[k].map((a) => (
            <Appointment key={a.id} appointment={a} />
          ))}
        </div>
      ))}
      {canceledEntries.length === 0 && !searchTerm && (
        <p>You have no canceled appointments at this time.</p>
      )}
      {canceledEntries.length === 0 && searchTerm && (
        <p>You have no canceled appointments matching the search term "{searchTerm}."</p>
      )}
    </div>
  );

  return (
    <div className='p-t-md'>
      <h5 id='appt-view'>List of phone consultations</h5>
      <div className='appointment-view-container m-t-md'>
        <div className='appointment-tab-wrapper'>
          <button
            className={`md appointment-tab ${view === 'upcoming' ? 'active' : ''}`}
            onClick={() => setView('upcoming')}
          >
            <h5 className='m-b-0'>Upcoming</h5>
          </button>
          <button
            className={`md appointment-tab ${view === 'past' ? 'active' : ''}`}
            onClick={() => setView('past')}
          >
            <h5 className='m-b-0'>Past</h5>
          </button>
          <button
            className={`md appointment-tab ${view === 'canceled' ? 'active' : ''}`}
            onClick={() => setView('canceled')}
          >
            <h5 className='m-b-0'>Canceled</h5>
          </button>
        </div>
        <div className='box inner-container flex column'>
          <input
            className='m-b-md appointment-search-bar'
            type='text'
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Search by client name or email.'
          />
          {view === 'upcoming' && upcomingSection}
          {view === 'past' && pastSection}
          {view === 'canceled' && canceledSection}
        </div>
        <AppointmentModal key={currentAppointment ? currentAppointment.id : 'NONE'} />
      </div>
    </div>
  );
}

const Appointment = ({ appointment: a }) => {
  const provider = useSelector((state) => state.main.provider);
  const calendar = _.get(provider, 'calendar[0]');
  const tz = useSelector((state) =>
    calendar && calendar.timezone ? calendar.timezone : _.get(state, 'calendars.tz')
  );
  const dispatch = useDispatch();
  const startTime = DateTime.fromISO(a.start_time).setZone(tz);
  const start = startTime.setZone(tz).toLocaleString(DateTime.TIME_SIMPLE);
  const end = DateTime.fromISO(a.end_time).setZone(tz).toLocaleString(DateTime.TIME_SIMPLE);

  return (
    <div className='appointment-item m-b-sm'>
      <div className='date-container'>
        <h6>
          {start}-{end} ({startTime.toFormat('ZZZZ')})
        </h6>
        {!!a.canceled && <p className='text-error'>This appointment was canceled.</p>}
      </div>
      <div className='text-left name-container'>
        {a.user.first_name} {a.user.last_name}
      </div>
      <div className='button-container'>
        <button
          onClick={() => dispatch(actions.openAppointment({ id: a.id }))}
          className='primary sm hollow pill'
        >
          View Details
        </button>
      </div>
    </div>
  );
};
