import _ from 'lodash';

const checklist = [
  {
    name: 'Basic Information',
    tooltip: 'Your basic information must be filled out.',
    cardName: 'Basic Information',
    cardDescription: 'Check that your name is correct!',
    cardLink: '/profile/general-information',
  },
  {
    name: 'Contact Information',
    tooltip: 'You must indicate your email and phone number.',
    cardName: 'Contact Information',
    cardDescription: 'Add contact details to ensure client referrals reach you!',
    cardLink: '/profile/general-information',
  },
  {
    name: 'Degrees',
    tooltip: 'You must have at least 1 degree selected.',
    cardName: 'Degrees',
    cardDescription: "Indicate the degrees you've earned!",
    cardLink: '/profile/general-information',
    providerDisplayFn: (provider) => provider.type === 'individual',
  },
  {
    name: 'Professions',
    tooltip: 'You must have at least 1 profession selected.',
    cardName: 'Professions',
    cardDescription: 'Indicate your professions!',
    cardLink: '/profile/general-information',
  },
  {
    name: 'Languages',
    tooltip: 'You must have at least 1 language selected.',
    cardName: 'Languages',
    cardDescription: 'Select the languages you offer sessions in!',
    cardLink: '/profile/general-information',
  },
  {
    name: 'Locations',
    tooltip: 'Indicate at least 1 practice location.',
    cardName: 'Locations',
    cardDescription: 'Share your availability for in-person and online sessions!',
    cardLink: '/profile/availability-locations',
  },
  {
    name: 'Licensure',
    tooltip: 'Indicate at least 1 license for your practice location.',
    cardName: 'Licensure',
    cardDescription:
      'Please indicate a license for your location(s) so we can verify your credentials!',
    cardLink: '/profile/general-information#licensure',
  },
  {
    name: 'Ongoing session fee',
    tooltip: 'You must have your ongoing session fee and duration indicated.',
    cardName: 'Ongoing session fee',
    cardDescription: 'Set your typical session fee!',
    cardLink: '/profile/insurance-fees',
  },
  {
    name: 'Clientele',
    tooltip: 'You must have at least 1 clientele selected.',
    cardName: 'Clientele',
    cardDescription: 'Indicate the client populations you work with!',
    cardLink: '/profile/clientele-approach',
  },

  /*{
        name: 'Work experience',
        tooltip: 'You must have at least 1 work experience line item.',
        cardName: 'Fill out your work & education',
        cardDescription: 'Share the work experience you\'ve gained!',
        cardLink: '/profile/education-employment',
        providerDisplayFn: provider => provider.type === 'individual'
    },*/
  {
    name: 'Profile photo',
    tooltip: 'You must have a profile photo selected.',
    cardName: 'Profile photo',
    cardDescription: 'Choose your profile photo!',
    cardLink: '/profile/photos-video',
  },
  {
    name: 'Top specialties',
    tooltip: 'You must have at least 1 top specialty indicated.',
    cardName: 'Top specialties',
    cardDescription: 'Indicate the top specialties you treat!',
    cardLink: '/profile/specialties',
  },
  {
    name: 'General expertise',
    tooltip: 'You must have at least 1 specialty selected.',
    cardName: 'General expertise',
    cardDescription:
      "Select up to 20 expertise areas you'd like your practice to display in searches for!",
    cardLink: '/profile/specialties',
  },
  {
    name: 'Treatment approaches',
    tooltip: 'You must have at least 1 approach selected.',
    cardName: 'Treatment approaches',
    cardDescription:
      'Select the treatment approaches you have training in and use in your practice!',
    cardLink: '/profile/clientele-approach',
  },

  /*{
        name: 'Professional statement',
        tooltip: 'You must have a professional statement written.',
        cardName: 'Professional statement',
        cardDescription: 'Write your professional statement!',
        cardLink: '/profile/message-statement',
        providerDisplayFn: provider => provider.type === 'individual'
    },
    {
        name: 'Practice Introduction',
        tooltip: 'You must have a practice introduction written.',
        cardName: 'Practice introduction',
        cardDescription: 'Write your practice introduction!',
        cardLink: '/profile/message-statement',
        providerDisplayFn: provider => provider.type === 'practice'
    },
    {
        name: 'Message to clients',
        tooltip: 'You must have a message to clients written.',
        cardName: 'Message to clients',
        cardDescription: 'Share a brief, hopeful message to clients!',
        cardLink: '/profile/message-statement',
        providerDisplayFn: provider => provider.type === 'individual'
    },*/
  {
    name: 'Start membership',
    tooltip: 'You must start your membership before your profile goes live.',
    cardName: 'Start Your Membership',
    cardDescription:
      'Begin your membership to make your profile live and take advantage of Zencare member benefits!',
    cardLink: '/plan-management',
  },
];

export default checklist;
