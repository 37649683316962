import React, { useState } from 'react';
import _ from 'lodash';

export default ({ provider }) => {
  const [copied, setCopied] = useState(false);

  const video = _.get(provider, 'custom_content.videos[0]');
  const videoId = video ? video.split('.com/')[1] : null;
  const videoSrc =
    video &&
    (video.includes('vimeo')
      ? `https://player.vimeo.com/video/${videoId}`
      : `https://www.youtube.com/embed/${videoId}`);

  const iframeTxt = `
        <iframe src="${videoSrc}" width="640" height="360" 
        frameborder="0" allow="autoplay; fullscreen" allowfullscreen="">
    `;

  if (!videoSrc) {
    return <p>You don't have a video yet.</p>;
  }

  return (
    <div className='video'>
      <iframe
        className='m-b-sm'
        width='600'
        title='video'
        height='315'
        src={videoSrc}
        frameborder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      ></iframe>
      <code className='iframe-txt m-b-xs'>{iframeTxt}</code>
      <div className='flex justify-between'>
        <p>
          Copy this code to embed your video on your own website! Learn more{' '}
          <a href='/marketing/video-embedding'>here</a>.
        </p>
        <button
          className='primary pill'
          onClick={() => {
            navigator.clipboard.writeText(iframeTxt);
            setCopied(true);
          }}
        >
          Copy Code
        </button>
      </div>
      {copied && <span className='flex justify-end text-primary'>Code copied!</span>}
    </div>
  );
};
