import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions as mainActions } from '../../../main/sagaSlice';
import { actions as photoActions } from './sagaSlice';
import _ from 'lodash';
import EditBox from '+/EditBox';
import PhotoUploader from '+/PhotoUploader';
import Loader from '+/forms/SectionLoader';

import PhotoSelectForm from './PhotoSelectForm';
import ProfileSelectModal from './ProfileSelectModal';
import VideoStatus from './VideoStatus';
import { imgix, photoSrc } from './helpers';
import VideoIntroduction from './VideoIntroduction';

const ProfilePhoto = connect(({ photos }) => photos, {
  createProfilePic: photoActions.createProfilePic,
  logEvent: mainActions.logEvent,
})(
  ({
    isLoading,
    numProfilePhotos,
    profilePhoto,
    createProfilePic,
    logEvent,
    provider,
    setEditingProfile,
  }) => (
    <div>
      <div className='flex column align-start'>
        {console.log(profilePhoto)}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!profilePhoto && (
              <div className='announcement quaternary w-100'>
                <p>You currently do not have a profile photo.</p>
              </div>
            )}
            <div className='flex column align-center'>
              {!!profilePhoto && (
                <img
                  className='avatar border-primary lg m-b-xs'
                  alt={`${provider.first_name} ${provider.last_name}`}
                  src={photoSrc(profilePhoto)}
                ></img>
              )}
              {numProfilePhotos > 1 && (
                <button
                  className='primary hollow md'
                  onClick={() => {
                    setEditingProfile(true);
                  }}
                >
                  Change photo
                </button>
              )}
            </div>
            <p className='m-b-0 m-t-sm'>Click the button below to upload a new profile photo.</p>
            <p>
              <b>
                Once you select a photo, you will have the{' '}
                <a
                  href='https://support.zencare.co/help/how-to-crop-headshot-profile-photo'
                  target='_blank'
                  className='underline'
                >
                  option to crop and center your image
                </a>{' '}
                <i>before</i> hitting "Upload."
              </b>
            </p>
            <PhotoUploader
              providerId={provider.id}
              logEvent={logEvent}
              containerClass='w-100'
              preventGrayscale={true}
              minSizeInKB={100}
              onSuccess={(image) => {
                image.provider_id = provider.id;
                createProfilePic(image);
              }}
            />
          </>
        )}
      </div>
    </div>
  )
);

//eslint-disable-next-line
const PhotosVideo = ({
  provider,
  updateProvider,
  setProfilePic,
  contactZencare,
  createGalleryPics,
  logEvent,
}) => {
  const activePhotos = _.get(
    provider,
    'custom_content.images',
    _.get(provider, 'gallery.images', [])
  );
  const selectedPhotos = _.get(provider, 'custom_content.images', []);
  const allGalleryPhotos = _.get(provider, 'gallery.images', []);
  const galleryPhotos = _.uniqBy(
    selectedPhotos.concat(allGalleryPhotos).filter((p) => !p.profile_ar),
    'key'
  );
  const profilePhotos = allGalleryPhotos.filter((p) => p.profile_ar);
  const profilePhoto = _.get(provider, 'image', {});

  const [editingGallery, setEditingGallery] = useState(false);
  const [editingProfile, setEditingProfile] = useState(false);
  //TODO rename
  const photosWithFaces = allGalleryPhotos.filter((p) => p.profile_ar);

  const initValues = {
    images: galleryPhotos.map((p, i) => ({
      oldIdx: i,
      checked: !_.isNil(_.find(selectedPhotos, (s) => s.handle === p.handle)),
    })),
  };

  const activeGallery = galleryPhotos.map((p) => (
    <img
      style={{ opacity: activePhotos.find((x) => x.handle === p.handle) ? 1 : 0.3 }}
      className='gallery-photo border-primary m-b-xs'
      key={p.handle}
      alt='1'
      src={imgix(p)}
    ></img>
  ));
  const inactiveGallery = galleryPhotos.map((p) => (
    <img
      style={{ opacity: 0.3 }}
      className='gallery-photo border-primary m-b-xs m-r-sm'
      key={p.handle}
      alt='1'
      src={imgix(p)}
    ></img>
  ));

  function onSubmitProfile(form) {
    const photo = _.find(profilePhotos, (p) => p.handle === form.handle);

    const payload = {
      provider_id: provider.id,
      handle: photo.handle,
      key: photo.key,
    };

    setProfilePic(payload);
    setEditingProfile(false);
  }

  function onSubmitGallery(form) {
    const customImages = form.images.filter((i) => i.checked).map((i) => galleryPhotos[i.oldIdx]);

    updateProvider({
      id: provider.id,
      custom_content: {
        images: customImages,
      },
    });

    setEditingGallery(false);
  }

  return (
    <div>
      <h1 className='m-b-md'>Photos & Video</h1>
      {!(provider.custom_content.images && provider.custom_content.images.length > 0) && (
        <div className='announcement m-b-md'>
          <h4>Want new photos of your office or yourself? Book a Zencare photoshoot!</h4>
          <div className='flex m-t-sm'>
            <Link to='/benefits/photo-videoshoot'>
              <button className='pill primary md m-r-sm'>Book a photoshoot</button>
            </Link>
          </div>
        </div>
      )}
      {provider.status !== 'active' && !provider.custom_content.photo_edit_used && (
        <div className='announcement m-b-sm'>
          <p>
            To make a one-time photoshop edit to your photos and/or video, please{' '}
            <a href='/contact' target='_blank' className='contact'>
              contact the Zencare team.
            </a>
          </p>
        </div>
      )}

      <EditBox
        title='Profile Photo'
        headerClassName='m-b-0'
        editable={false}
        description={
          <div className='instruction m-b-md'>
            {profilePhoto && 'Select your preferred headshot for your profile photo. '}Your profile
            photo is a client’s first impression of your practice, so we suggest choosing a photo
            with a warm smile! Here are some{' '}
            <a
              target='_blank'
              href='https://support.zencare.co/help/what-are-the-criteria-to-upload-a-headshot'
            >
              Do's and Don'ts
            </a>{' '}
            for profile photos.
          </div>
        }
        innerStatic={
          <ProfilePhoto
            setEditingProfile={setEditingProfile}
            numProfilePhotos={photosWithFaces.length}
            profilePhoto={profilePhoto}
            provider={provider}
          />
        }
      />
      <EditBox
        title='Gallery Photos'
        headerClassName='m-b-0'
        editable={true}
        description={
          editingGallery ? (
            <>
              <p>
                Select 4-8 photos, including headshots and office photos. Click on any photo to see
                it larger, and drag and drop to change the order.
                <br></br>
                <b>
                  {' '}
                  Please order photos so that your headshots display first, followed by your office
                  photos!
                </b>
              </p>
            </>
          ) : (
            'These images show in the gallery of your profile.'
          )
        }
        editing={editingGallery}
        setEditing={setEditingGallery}
        innerStatic={
          <>
            {activePhotos && activePhotos.length > 0 && (
              <div className='gallery'>{activeGallery}</div>
            )}
            {!(activePhotos && activePhotos.length > 0) &&
              (inactiveGallery && inactiveGallery.length > 0 ? (
                <>
                  <div className='announcement quaternary'>
                    <p>
                      None of your gallery photos are currently visible on your profile. Click
                      “Edit” and unhide your gallery photos to make them visible!
                    </p>
                  </div>
                  {inactiveGallery}
                </>
              ) : (
                <div className='announcement quaternary'>
                  <p>
                    You currently do not have any gallery photos. Click “Edit” to upload photos, or{' '}
                    <Link to='/benefits/photo-videoshoot'>book a Zencare photo/videoshoot</Link> if
                    you haven’t yet!
                  </p>
                </div>
              ))}
          </>
        }
        innerEditing={
          <>
            <PhotoSelectForm
              images={galleryPhotos}
              initialValues={initValues}
              onSubmit={onSubmitGallery}
            />
            <PhotoUploader
              providerId={provider.id}
              logEvent={logEvent}
              buttonText='Upload new photos'
              containerClass='w-100 m-t-md'
              pickerOptions={{
                maxFiles: 15,
              }}
              preventGrayscale={true}
              minSizeInKB={100}
              onSuccess={(images) => {
                createGalleryPics({
                  provider_id: provider.id,
                  images,
                });
              }}
            />
          </>
        }
      />
      <VideoStatus
        provider={provider}
        updateProvider={updateProvider}
        contactZencare={contactZencare}
      />
      <EditBox
        title='Video Introduction'
        headerClassName='m-b-0'
        editable={false}
        description={
          'Videos are hosted on Vimeo and YouTube so that they are easily accessible in search engines.'
        }
        innerStatic={<VideoIntroduction provider={provider} />}
      />
      <ProfileSelectModal
        open={editingProfile}
        toggle={setEditingProfile}
        onSubmit={onSubmitProfile}
        images={photosWithFaces}
        currentImage={provider.image}
        modalHeader='Select Profile Photo'
        modalDescription='Select the profile photo you would like on your profile:'
      />
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {
  updateProvider: mainActions.updateProvider,
  setProfilePic: photoActions.setProfilePic,
  createProfilePic: photoActions.createProfilePic,
  contactZencare: mainActions.contactZencare,
  logEvent: mainActions.logEvent,
  createGalleryPics: photoActions.createGalleryPics,
};

export default connect(mapStateToProps, actions)(PhotosVideo);
