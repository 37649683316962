import React from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';

export const Confirm = (props) => {
  const { match = { params: {} }, confirm } = props;
  const { token } = match.params;

  confirm(token);

  return (
    <div>
      <h1>Confirm Account</h1>
    </div>
  );
};

export default connect(null, { confirm: actions.confirm })(Confirm);
