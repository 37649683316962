import React from 'react';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import SubmitButton from '+/forms/SubmitButton';
import ErrorField from '+/forms/ErrorField';
import { getPlainText, getHTMLFromText } from '@/provider/profile/MessageStatement/helpers';

export default function PlainTextArea({
  placeholder,
  initText,
  submit,
  validate = (values) => ({}),
  name,
  convertToHTML = true,
}) {
  const initialValues = {
    [name]: getPlainText(initText),
  };

  const onSubmit = (values) =>
    submit(convertToHTML ? getHTMLFromText(_.get(values, [name], '')) : values[name]);

  const passProps = {
    onSubmit,
    validate,
    initialValues,
  };

  return (
    <Form
      {...passProps}
      initialValuesEqual={() => true}
      render={({ handleSubmit, pristine, valid }) => (
        <>
          <Field
            className='w-100'
            name={name}
            component='textarea'
            type='textarea'
            placeholder={placeholder}
          />
          <ErrorField name={name} />
          <SubmitButton pristine={pristine} valid={valid} handleSubmit={handleSubmit} />
        </>
      )}
    />
  );
}
