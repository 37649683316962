import React from 'react';
import _ from 'lodash';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import providerName from '#/providerName';

export default ({ provider: p }) => (
  <div className='flex column m-r-xs m-b-xs'>
    <img
      className='avatar border-primary md m-b-xs'
      src={p.image ? imgix(p.image) : require('~/assets/placeholder.png')}
      alt={p.first_name}
    />
    <h5 className='m-b-0'>{providerName(p)}</h5>
    <span>{_.get(p, 'primary_profession.name')}</span>
  </div>
);
