/* eslint-disable max-lines-per-function */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import { DateTime } from 'luxon';
import AppointmentModal from './AppointmentModal';
import useQueryParams from '+/hooks/useQueryParams';
import LinkButton from '~/shared/LinkButton';

export default function () {
  const provider_id = useSelector((state) => _.get(state, 'main.provider.id'));
  const appointments = useSelector((state) => _.get(state, 'appointments.appointments'));
  const currentAppointment = useSelector((state) => state.appointments.currentAppointment);
  const practiceManagement = useSelector((state) => state.practiceManagement);
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.main.provider);
  const calendar = _.get(provider, 'calendar[0]');
  const tz = useSelector((state) =>
    calendar && calendar.timezone ? calendar.timezone : _.get(state, 'calendars.tz')
  );
  const query = useQueryParams();

  useEffect(() => {
    const appointmentId = query.get('id');
    if (appointmentId) {
      dispatch(actions.openAppointment({ id: decodeURIComponent(appointmentId) }));
    }
  }, [dispatch, query]);

  useEffect(() => {
    if (provider_id) {
      dispatch(actions.getAllAppointments({ provider_id }));
    }
  }, [dispatch, provider_id]);

  // No provider
  if (!provider) {
    return null;
  }

  const { practice_management_booking_link } = provider;
  const { firstPracticeManagementAccount } = practiceManagement;

  const bookingLinkIsActive =
    firstPracticeManagementAccount && practice_management_booking_link?.status === 'active';

  if (bookingLinkIsActive) {
    // Link to practice management calendar
    const calendarLink = `https://${firstPracticeManagementAccount.subdomain}.zencarepractice.com/calendar `;
    // Link to booking page or admin entered link override if one exists
    const bookingLink = practice_management_booking_link.link_override
      ? practice_management_booking_link.link_override
      : `https://aws-portal.zencarepractice.com/${firstPracticeManagementAccount.subdomain}/booking`;
    return (
      <div className='box no-mobile appointment-view-container'>
        <h3>
          <i className='fal fa-calendar-day m-r-sm' />
          Upcoming Phone Consultations
        </h3>
        <p className='m-b-0'>
          Your booking link is enabled - Clients will able to book with you directly at:
        </p>
        <a className='bold' rel='noopener noreferrer' target='_blank' href={bookingLink}>
          {bookingLink}
        </a>
        <div>
          <a className='m-r-xs' rel='noopener noreferrer' target='_blank' href={calendarLink}>
            <button className='pill primary'>See Calendar</button>
          </a>
          <LinkButton className='pill primary hollow md m-t-sm' to='/calendar'>
            Hide Link
          </LinkButton>
        </div>{' '}
      </div>
    );
  }

  // No calendar
  if (!(provider.calendar && provider.calendar.length)) {
    return null;
  }
  if (!appointments) {
    return null;
  }

  const dateKey = (datetime) =>
    datetime.setZone(tz).toLocaleString({
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });

  const groupByFunction = (a) => dateKey(DateTime.fromISO(a.start_time));

  const now = DateTime.utc().toISO();

  const upcomingAppointments = _.groupBy(
    appointments.filter((a) => now <= a.end_time && !a.canceled),
    groupByFunction
  );

  // Convert to ISO from formatted string and sort
  const sortByDate = (a, b) => {
    const dateA = DateTime.fromFormat(a, 'DDDD');
    const dateB = DateTime.fromFormat(b, 'DDDD');
    return dateA - dateB;
  };

  // Show only next 2 days of appts
  const appointmentKeys = Object.keys(upcomingAppointments);
  const appointmentKeysSorted = appointmentKeys.sort(sortByDate);
  const upcomingEntries = _.take(appointmentKeysSorted, 2);

  const upcomingSection = (
    <div className='appointments'>
      <h3>
        <i className='fal fa-calendar-day m-r-sm' />
        Upcoming Phone Consultations
      </h3>
      {upcomingEntries.map((k) => (
        <div key={k} className='appointment-day-group'>
          <div className='flex m-b-xs'>
            <h5 className='m-b-0'>{k}</h5>
            {dateKey(DateTime.utc()) === k && (
              <span
                className='text-quaternary m-l-sm'
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  letterSpacing: 1,
                  fontSize: '0.8rem',
                  alignSelf: 'end',
                }}
              >
                Today!
              </span>
            )}
            {dateKey(DateTime.utc().plus({ days: 1 })) === k && (
              <span
                className='text-secondary m-l-sm'
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  letterSpacing: 1,
                  fontSize: '0.8rem',
                  alignSelf: 'end',
                }}
              >
                Tomorrow
              </span>
            )}
          </div>
          {upcomingAppointments[k].map((a) => (
            <Appointment key={a.id} appointment={a} />
          ))}
        </div>
      ))}
      {upcomingEntries.length === 0 && <p>You have no upcoming appointments at this time.</p>}
    </div>
  );

  return (
    <div className='box no-mobile appointment-view-container'>
      <div className='inner-container flex column'>{upcomingSection}</div>
      <div>
        <LinkButton className='pill primary md m-t-sm' to='/calendar'>
          View all
        </LinkButton>
      </div>

      <AppointmentModal key={currentAppointment ? currentAppointment.id : 'NONE'} />
    </div>
  );
}

const Appointment = ({ appointment: a }) => {
  const provider = useSelector((state) => state.main.provider);
  const calendar = _.get(provider, 'calendar[0]');
  const tz = useSelector((state) =>
    calendar && calendar.timezone ? calendar.timezone : _.get(state, 'calendars.tz')
  );
  const dispatch = useDispatch();
  const startTime = DateTime.fromISO(a.start_time).setZone(tz);
  const start = startTime.setZone(tz).toLocaleString(DateTime.TIME_SIMPLE);
  const end = DateTime.fromISO(a.end_time).setZone(tz).toLocaleString(DateTime.TIME_SIMPLE);

  return (
    <div className='appointment-item m-b-sm'>
      <div className='date-container'>
        <h6>
          {start}-{end} ({startTime.toFormat('ZZZZ')})
        </h6>
        {!!a.canceled && <p className='text-error'>This appointment was canceled.</p>}
      </div>
      <div className='text-left name-container'>
        {a.user.first_name} {a.user.last_name}
      </div>
      <div className='button-container'>
        <button
          onClick={() => dispatch(actions.openAppointment({ id: a.id }))}
          className='primary sm hollow pill'
        >
          View Details
        </button>
      </div>
    </div>
  );
};
