/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';
import _ from 'lodash';
import { SideNav } from '@zencare/ui';
import NavLink from './NavLink';
import storage from '#/storage';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router';
import { scopedLinks, practiceInboxScopedLinks } from './links';
import PracticeManagementCta from './PracticeManagementCta';
import ReimbursifyCta from './ReimbursifyCta';

const parseLink = (item, vars, provider) => {
  const video = get(provider, 'custom_content.videos', []);

  const link = { ...item };

  if (typeof item.href === 'function') {
    link.href = item.href(vars);
  }

  if (item.to === '/marketing/video-embedding' && !video.length) {
    return '';
  }

  return link;
};

function linkIsActive(link, route) {
  return route.path === link.to;
}

function groupShouldBeActive(group, route) {
  return (
    (group.link && route.path === group.link.to) ||
    (group.links && group.links.map((l) => l.to).includes(route.path))
  );
}

function generateLinks(props) {
  if (props?.route?.practiceInbox) {
    return practiceInboxScopedLinks(props.auth.scope, props.auth.isSignedIn, props.auth.roles);
  } else {
    return scopedLinks(props.auth.scope, props.auth.isSignedIn, props.auth.roles);
  }
}

const SideNavContainer = (props) => {
  const links = generateLinks(props);

  const [activeSection, setActiveSection] = useState(
    get(
      links.find((g) => groupShouldBeActive(g, props.route)),
      'title'
    )
  );
  const [isCollapsed, setIsCollapsed] = useState(
    storage.has('menu-collapsed') ? storage.get('menu-collapsed') : false
  );
  const location = useLocation();
  const isOnboarding = location.pathname.includes('/signup');
  const { checklist, provider } = props.main;
  /**
   * We want to display unread indicators alongside relevant NavLinks
   * only in the context in which they're relevant. We'll use these values
   * to determine if the user is viewing conversations at the provider level
   * or at the account level.
   */
  const practiceInbox = location.pathname.includes('/practice-inbox');

  /**
   * Similar logic exists in Dashboard in order to display the profile preview instead
   * of the Dashboard itself. The below 3 values are used to hide the sidenav when the
   * profile preview is shown
   */
  const checklistIsDoneOrOnLastStep =
    checklist?.length === 0 || (checklist?.length === 1 && checklist.includes('Start membership'));
  const isIncompleteOrReviewing = ['reviewing', 'incomplete'].includes(provider.status);
  const onDashboard = location.pathname === '/';

  const showGoLiveForm = isIncompleteOrReviewing && checklistIsDoneOrOnLastStep && onDashboard;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [links, activeSection]);
  useEffect(() => {
    // Collapse SideNav on specific routes
    if (
      location.pathname.startsWith('/calendar') ||
      location.pathname.startsWith('/client-list') ||
      showGoLiveForm ||
      location.pathname.startsWith('/messages')
    ) {
      setIsCollapsed(true);
      // storage.set('menu-collapsed', true);
      setActiveSection(null);
    }
  }, [location]);

  const toggleSection = function (title) {
    if (title === activeSection) {
      return setActiveSection(null);
    }

    return setActiveSection(title);
  };

  return (
    <SideNav
      className={`portal-side-nav ${isCollapsed ? 'portal-side-nav-collapsed' : ''}`}
      mobileShow={props.mobileShow}
      toggleMobileShow={() => props.toggleMobileShow()}
    >
      <div className='nav-inner'>
        {links.map((group, i) => (
          <React.Fragment key={`frag${i}`}>
            {!(
              get(group, 'link.showFn') &&
              !group.link.showFn(props.main.provider, props.main.accounts)
            ) &&
              (group.link ? (
                <NavLink
                  tooltip={isCollapsed ? group.link.text : ''}
                  className={activeSection === group.title ? 'primary' : ''}
                  {...parseLink(
                    group.link,
                    {
                      slug: get(props.main.provider, 'slug', ''),
                      type: get(props.main.provider, 'type', 'individual'),
                      primaryProfession: get(props.main.provider, 'primary_profession.slug', ''),
                    },
                    props.main.provider
                  )}
                  key={`nav-link${i}`}
                  practiceInbox={practiceInbox}
                >
                  <span className='menu-link-text'>{group.link.text}</span>
                </NavLink>
              ) : (
                !(
                  get(group, 'showFn') && !group.showFn(props.main.provider, props.main.accounts)
                ) && (
                  <>
                    <div
                      key={`nav-section${i}`}
                      className={
                        activeSection === group.title
                          ? 'active nav-section-title'
                          : 'nav-section-title'
                      }
                      role='menu'
                      tabIndex={i}
                      onClick={(e) => {
                        setIsCollapsed(false);
                        storage.set('menu-collapsed', false);

                        return toggleSection(group.title, e);
                      }}
                      onKeyDown={(e) => {
                        setIsCollapsed(false);
                        storage.set('menu-collapsed', false);

                        return toggleSection(group.title);
                      }}
                    >
                      <div className='nav-title-left' role='presentation'>
                        <i
                          data-for='menu-tooltip'
                          data-tip={isCollapsed ? group.title : ''}
                          className={`fal fa-fw fa-${group.icon} side-nav-icon`}
                        >
                          {!practiceInbox && props.hasUnreadMessages && group.icon === 'couch' ? (
                            <span className='unread'></span>
                          ) : null}
                          {practiceInbox &&
                          props.hasUnreadPracticeMessages &&
                          group.icon === 'couch' ? (
                            <span className='unread'></span>
                          ) : null}
                        </i>
                        <span className='menu-group-title'>{group.title}</span>
                      </div>
                      <i className={'fal fa-fw fa-chevron-down toggle'}></i>
                    </div>
                    <div
                      className={activeSection === group.title ? 'active link-group' : 'link-group'}
                      key={`link-group${i}`}
                    >
                      {group.links
                        .filter(
                          (l) => !l.showFn || l.showFn(props.main.provider, props.main.accounts)
                        )
                        .map((l) =>
                          parseLink(
                            l,
                            {
                              slug: get(props.main.provider, 'slug', ''),
                              primaryProfession: get(
                                props.main.provider,
                                'primary_profession.slug',
                                ''
                              ),
                            },
                            props.main.provider
                          )
                        )
                        .map((l) => {
                          if (l.lockFn && l.lockFn(props.main.provider, props.main.accounts)) {
                            l.icon = 'lock-alt';
                          }

                          return l;
                        })
                        .map((link, j) => (
                          <NavLink
                            tabIndex={i + j}
                            key={`navlink${i + j}`}
                            className={linkIsActive(link, props.route) ? 'primary' : ''}
                            {..._.omit(link, ['showFn', 'activeClassName'])}
                            practiceInbox={practiceInbox}
                          >
                            <span className='menu-link-text'>{link.text}</span>
                          </NavLink>
                        ))}
                    </div>
                  </>
                )
              ))}
          </React.Fragment>
        ))}
        {!isCollapsed && !isOnboarding && (
          <>
            <hr />
            <div className='cta-wrapper'>
              <PracticeManagementCta />
              <ReimbursifyCta />
            </div>
          </>
        )}
      </div>

      <div className={`menu-toggle-button-wrapper ${isCollapsed ? 'collapsed' : 'expanded'}`}>
        <hr />
        {!props.isMobile && !isCollapsed && (
          <button
            className='menu-toggle-button'
            onClick={(e) => {
              setIsCollapsed(true);
              setActiveSection(null);
              storage.set('menu-collapsed', true);
            }}
          >
            <span className='chevron'>
              <i className='far fa-chevron-double-left'></i>
            </span>{' '}
            Collapse sidebar
          </button>
        )}
        {isCollapsed && (
          <button
            className='menu-toggle-button'
            data-for='menu-tooltip'
            data-tip='Expand sidebar'
            onClick={(e) => {
              setIsCollapsed(false);
              storage.set('menu-collapsed', false);
            }}
          >
            <span className='chevron'>
              <i className='far fa-chevron-double-right'></i>
            </span>
          </button>
        )}
      </div>
      <ReactTooltip
        id='menu-tooltip'
        effect='solid'
        place='right'
        type='light'
        arrowColor='#37BEC3'
      ></ReactTooltip>
    </SideNav>
  );
};

const LockedLink = (props) => (
  <div tabIndex={props.tabIndex} className='link locked text-Membership Tips'>
    <i className='far fa-lock fa-fw m-r-sm'></i>
    <span tabIndex={props.tabIndex} className={props.className} activeclassname='active'>
      {props.children}
    </span>
  </div>
);

const mapStateToProps = (state) => ({
  auth: state.auth,
  main: state.main,
  hasUnreadMessages: state.messages.hasUnreadMessages,
  hasUnreadPracticeMessages: state.practiceInbox.hasUnreadPracticeMessages,
});

export default connect(mapStateToProps)(SideNavContainer);
