/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import get from 'lodash/get';
import Loader from 'react-loader-spinner';
import TopNav from '+/nav/TopNav';
import SideNav from '+/nav/SideNav';
import Footer from '+/nav/Footer.jsx';
import Flashes from '+/flashes/Container';
import StatusNotification from '~/components/provider/profile/StatusNotification';
import history from '#/history';
import steps from '@/onboarding/steps';
import { actions as mainActions } from '../components/main/sagaSlice';
import { actions as messageActions } from '../components/provider/appointments/messages/sagaSlice';
import providerHasSubscriptionInAccount from '#/providerHasSubscriptionInAccount';
import storage from '#/storage';
import useWindowSize from '+/hooks/useWindowSize';
import _ from 'lodash';

interface Props {
  children: JSX.Element;
  route: $TSFixMe;
}

export const DefaultLayout: React.FC<Props> = ({ children, route }) => {
  const { isSignedIn, roles } = useSelector((state: $TSFixMe) => state.auth);
  const { provider, isLoading, accounts, licenses } = useSelector((state: $TSFixMe) => state.main);
  const { conversations } = useSelector((state: $TSFixMe) => state.messages);
  const [sideNavShowing, toggleSideNav] = useState(false);
  const [checkingRoute, setCheckingRoute] = useState(true);
  const [childComponent, setChildComponent] = useState<ReactNode>();
  const location = useLocation();
  const [hideMenu, setHideMenu] = useState(false);
  const [hideStatusBar, setHideStatusBar] = useState(false);
  const [width] = useWindowSize();
  const isMobile = width <= 1024;
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname === '/plan-management' ||
      location.pathname === '/profiles' ||
      _.startsWith(location.pathname, '/profiles/create') ||
      _.startsWith(location.pathname, '/profiles/pay-for/') ||
      _.startsWith(location.pathname, '/profiles/about-you-for/') ||
      _.startsWith(location.pathname, '/profiles/your-practice-for/') ||
      _.startsWith(location.pathname, '/profiles/photos-for/') ||
      _.startsWith(location.pathname, '/profiles/go-live-for/') ||
      _.startsWith(location.pathname, '/practice-management') ||
      _.startsWith(location.pathname, '/reimbursify/upgrade') ||
      _.startsWith(location.pathname, '/referral-data')
    ) {
      if (!isMobile) {
        setHideMenu(true);
      }
      if (location.pathname !== '/profiles') {
        setHideStatusBar(true);
      }
    } else {
      setHideMenu(false);
      setHideStatusBar(false);
    }
  }, [isMobile, location]);

  useEffect(() => {
    if (!provider) {
      dispatch(mainActions.getMe());
      dispatch(mainActions.getAllAccountDetails());
    }
  }, [dispatch, provider]);

  useEffect(() => {
    if (!provider) {
      dispatch(mainActions.getAllMyProviders());
    }
  }, [dispatch, isSignedIn, provider]);

  useEffect(() => {
    if (!isSignedIn || !provider) return;

    dispatch((mainActions as $TSFixMe).getLicenses({ id: provider.id }));
  }, [dispatch, isSignedIn, provider]);

  // Redirect to Onboarding Wizard if haven't completed, hasn't finished
  // and profile has created_after_new_onboarding_wizard flag in custom_content
  useEffect(() => {
    if (
      location.pathname === '/' &&
      provider &&
      !storage.get(`exit-onboarding-wizard-provider-id-${provider.id}`) &&
      get(provider, 'custom_content.created_after_new_onboarding_wizard') === true &&
      provider.status === 'incomplete' &&
      steps.some((s: $TSFixMe) => s.checkFn(provider, roles, accounts, licenses) && !s.skippable)
    ) {
      console.log('Redirecting to onboarding wizard');
      history.push('/signup');
    }
    setCheckingRoute(false);
  }, [roles, accounts, provider, licenses, location]);

  // Check for pay wall for route
  useEffect(() => {
    if (!accounts || !provider) {
      return;
    }

    if (
      accounts.length &&
      !provider.hasPaidPlan &&
      !accounts.some((a: $TSFixMe) => providerHasSubscriptionInAccount(provider, a)) &&
      route.requireSubscription
    ) {
      setChildComponent(
        <div
          className='page402 flex column align-center justify-center'
          style={{ minHeight: 'calc(100vh - 21rem)' }}
        >
          {/* <img
            className='m-b-md'
            alt='doggo'
            width={400}
            height={400}
            src='https://zencare.s3.us-east-2.amazonaws.com/401-page/sad-valk-1.png'
          ></img> */}
          <p
            className='m-t-sm'
            style={{
              fontSize: '1.5rem',
              textAlign: 'center',
              maxWidth: '600px',
            }}
          >
            <b>
              To access this page and all of your account benefits benefits, please{' '}
              <a
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                }}
                href='/plan-management'
              >
                activate your membership
              </a>
            </b>
          </p>
        </div>
      );
    } else {
      setChildComponent(children);
    }
  }, [accounts, children, provider, route.requireSubscription]);

  useEffect(() => {
    if (provider && !conversations) {
      const payload = { provider_id: provider.id };
      dispatch((messageActions as $TSFixMe).getConversations(payload));
    }
  }, [conversations, dispatch, provider]);

  const providerStatus = get(provider, 'status', false);

  if (((!provider || !accounts || !childComponent) && isLoading) || checkingRoute) {
    return (
      <div className='flex flex-1 justify-center align-center' style={{ height: '90vh' }}>
        <Loader type='Oval' color='#37BEC3' height={50} width={50} />
      </div>
    );
  }

  if (!provider) {
    return (
      <div className='flex flex-1 justify-center align-center' style={{ height: '90vh' }}>
        <div className='flex align-center justify-center column'>
          {/* Removed error message here -- If users are stuck in loading this would be why */}
          <Loader type='Oval' color='#37BEC3' height={50} width={50} />
        </div>
      </div>
    );
  }

  // @ts-ignore-next-line
  const extraClass = ['reviewing', 'incomplete', 'inactive', 'pending'].includes(providerStatus)
    ? 'banner-showing'
    : '';
  const routeNameClass = route.containerLayoutClass ? route.containerLayoutClass : '';

  return (
    <div id='layout' className='default'>
      <Flashes />
      <div className='nav-top-container'>
        <TopNav toggleSideNav={() => toggleSideNav(!sideNavShowing)} status={providerStatus} />
        {provider && !hideStatusBar && (
          <StatusNotification provider={provider} licenses={licenses} />
        )}
      </div>
      <div className={`content-container ${extraClass} ${routeNameClass}`}>
        {!hideMenu && (
          <>
            <SideNav
              isMobile={isMobile}
              mobileShow={sideNavShowing}
              provider={provider}
              route={route}
              toggleMobileShow={() => toggleSideNav(!sideNavShowing)}
            />
          </>
        )}

        <div
          className={`content-inner-container ${extraClass}`}
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              maxWidth: '1500px',
              margin: '0 auto',
            }}
            className={route.layoutClass ? route.layoutClass : 'content'}
          >
            {childComponent}
          </div>
          {!route.noFooter && <Footer provider={provider} />}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
