import React from 'react';
import history from '#/history';
import PureModal from '+/Modal';
import { actions as mainActions } from '../../sagaSlice';
import { useDispatch } from 'react-redux';

export const DuplicateProfileWarningModal = ({
  open,
  toggle,
  values,
  submitCreateProvider,
  duplicateProvider,
}: {
  open: boolean;
  toggle: $TSFixMeFunction;
  values: $TSFixMe;
  submitCreateProvider: $TSFixMeFunction;
  duplicateProvider: $TSFixMe;
}) => {
  const dispatch = useDispatch();

  return (
    <PureModal
      visible={open}
      additionalCssClasses='shorter-modal'
      closeModal={() => toggle(false)}
      headerContent={<h4>Duplicate Profile Found</h4>}
      footerContent={
        <div className='actions'>
          <button
            onClick={() => {
              toggle(false);
              submitCreateProvider(values);
            }}
            className='hollow'
          >
            Create new therapist profile
          </button>
          <button
            onClick={() => {
              toggle(false);
              dispatch(mainActions.selectActiveProvider({ id: duplicateProvider.id }));
              // If the card is clicked, navigate to the home page of the portal.
              history.push('/');
            }}
            className='primary'
          >
            Edit existing profile
          </button>
        </div>
      }
    >
      <>
        <p>A profile with this name already exists. Did you intend to edit the existing profile?</p>
      </>
    </PureModal>
  );
};

export default DuplicateProfileWarningModal;
