import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actions as mainActions } from '../../../main/sagaSlice';
import EditBox from '+/EditBox';
import _ from 'lodash';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import UnregisteredProviderForm from './UnregisteredProviderForm';
import RegisteredProviderForm from './RegisteredProviderForm';

const ProviderGrid = ({ providers }) => (
  <div className='practice-provider-grid'>
    {_.sortBy(providers, (p) => _.get(p, 'custom_content.practice_sort_order', 0), 'asc').map(
      (p) => (
        <div className='flex-center flex column m-r-md m-b-md'>
          <img
            className='avatar border-primary md m-b-xs'
            src={imgix(p.image)}
            alt={p.first_name}
          />
          <h5 className='m-b-0'>{`${p.first_name} ${p.last_name}`}</h5>
          <span>{_.get(p, 'primary_profession.name')}</span>
        </div>
      )
    )}
  </div>
);

export const PracticeProviders = ({ provider, updateProvider, updatePracticeSort }) => {
  const [editingRegisteredProviders, setEditingRegisteredProviders] = useState(false);
  const [editingUnregisteredProviders, setEditingUnregisteredProviders] = useState(false);

  const registeredProviders = _.sortBy(
    _.get(provider, 'providers', []),
    'custom_content.practice_sort_order'
  );
  const unregisteredProviders = _.get(provider, 'custom_content.providers', []);

  if (provider.type !== 'practice') {
    return <Redirect to='/' />;
  }

  return (
    <div>
      <h1 className='m-b-md'>Practice Providers</h1>
      <EditBox
        title='Zencare Providers in Your Practice'
        description={`These are providers who are in your practice and have a Zencare profile. 
                    Clicking on their photo on your Practice page will take the client to the clinician's profile.`}
        editable={true}
        editing={editingRegisteredProviders}
        setEditing={setEditingRegisteredProviders}
        innerStatic={<ProviderGrid providers={registeredProviders} />}
        innerEditing={
          <RegisteredProviderForm
            provider={provider}
            providers={registeredProviders}
            submit={(newProviderOrder) => {
              updatePracticeSort({
                newProviderOrder,
                id: provider.id,
              });

              setEditingRegisteredProviders(false);
            }}
            cancel={() => setEditingRegisteredProviders(false)}
          />
        }
      />
      {unregisteredProviders.length > 0 && (
        <EditBox
          title='Additional Providers in Your Practice'
          description={
            'These are providers who are in your practice but do not have a Zencare profile.'
          }
          editable={true}
          editing={editingUnregisteredProviders}
          setEditing={setEditingUnregisteredProviders}
          innerStatic={<ProviderGrid providers={unregisteredProviders} />}
          innerEditing={
            <UnregisteredProviderForm
              providers={unregisteredProviders}
              submit={(providers) => {
                updateProvider({
                  id: provider.id,
                  custom_content: {
                    providers,
                  },
                });
                setEditingUnregisteredProviders(false);
              }}
            />
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {
  updateProvider: mainActions.updateProvider,
  updatePracticeSort: mainActions.updatePracticeSort,
};

export default connect(mapStateToProps, actions)(PracticeProviders);
