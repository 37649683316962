import React from 'react';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';
import SubmitButton from '+/forms/SubmitButton';
import ErrorField from '+/forms/ErrorField';
import useWindowSize from '~/shared/hooks/useWindowSize';

const AddableForm = ({
  onEdit,
  dataList = [],
  columns,
  beforeAdd = () => new Promise((resolve) => resolve()),
  beforeDelete = () => new Promise((resolve) => resolve()),
  validate = () => new Promise((resolve) => resolve({})),
  name,
  addText,
  defaultNewItem,
  extraFields = () => <></>,
  extraValues = {},
}) => {
  const [width, height] = useWindowSize();

  /*
   * Filter out any empty entries.
   */
  function onEditWrapper(formValues) {
    let values = _.clone(formValues[name]);
    // filter out any values with no value for a required column
    values = values.filter((v) =>
      _.some(
        columns.map((c) => c.key).map((key) => !_.isNil(_.get(v, key)) && _.get(v, key) !== '')
      )
    );

    values.forEach((v) => {
      delete v.deletable;
      delete v.nameReadOnly;
    });

    return onEdit(values, formValues);
  }

  /*
   * Renders a single field based on column structure
   */
  const renderField = ({ header, key, type, placeholder, options, inputType }, name, i, v) => (
    <div>
      {width < 768 && <span className='m-r-sm'>{header}:</span>}
      <Field
        name={`${name}[${i}].${key}`}
        component={type}
        placeholder={placeholder}
        required={type === 'select'}
        type={inputType}
        initialValue={v[key]}
      >
        {type === 'select' ? (
          <>
            <option value='' disabled selected hidden>
              {placeholder}
            </option>
            {options.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
            <i className='fas fa-caret-down'></i>
          </>
        ) : null}
      </Field>
      {key === 'fee' && <span className='units'>$</span>}
      {key === 'length' && <span className='units'>min</span>}
    </div>
  );

  /*
   * Renders fields based on column structure.
   */
  const renderFields = (values, remove) => (
    <>
      <div className='row-container'>
        {columns.map((c) => (
          <div className='flex column addable-tag-form-field-group m-b-xs'>
            {width > 768 && (
              <>
                <h5 className='m-b-0 header'>{c.header}</h5>
                {c.subheader && <span>{c.subheader}</span>}
              </>
            )}
          </div>
        ))}
        <div></div>
      </div>

      {values.map((v, i) => (
        <div className='row-container'>
          {columns.map((c) => (
            <div className={`field ${c.key}`}>
              {
                // If name read only, then don't insert an actual editable field
                c.key === 'name' && v.nameReadOnly ? (
                  <div className='fixed-name'>{v[c.key]}</div>
                ) : (
                  renderField(c, name, i, v)
                )
              }
            </div>
          ))}
          {/* Delete line button*/}
          <div className='delete flex-center'>
            {v.deletable && (
              <i
                role='presentation'
                onClick={() => {
                  beforeDelete().then(() => {
                    remove(i);
                  });
                }}
                className='far fa-minus-circle'
              ></i>
            )}
          </div>
          {
            // Errors are put on a separate line for css grid reasons
            columns.map((c) => (
              <span>
                <ErrorField name={`${name}[${i}].${c.key}`} />
              </span>
            ))
          }
          <span></span>
          {width < 768 && <hr style={{ width: '100%' }}></hr>}
        </div>
      ))}
    </>
  );

  const mutators = {
    add: ([newItem], state, { changeValue }) => {
      changeValue(state, name, (oldVal) => [...oldVal, newItem]);
    },
    remove: ([index], state, { changeValue }) => {
      changeValue(state, name, (oldVal) => {
        const arr = [...oldVal];
        arr.splice(index, 1);

        return arr;
      });
    },
  };

  const initialValues = {
    [name]: dataList,
    ...extraValues,
  };

  return (
    <Form
      onSubmit={onEditWrapper}
      mutators={mutators}
      initialValues={initialValues}
      //Don't re-render
      initialValuesEqual={() => true}
      validate={validate}
      render={({ handleSubmit, values, valid, errors, pristine, form: { mutators } }) => (
        <>
          <div className={`addable-form ${name}`}>
            {extraFields(values, errors)}
            <div className='addable-form-inner'>{renderFields(values[name], mutators.remove)}</div>
            {/* Add another item button*/}
            <button
              className='add'
              onClick={(evt) => {
                evt.target.blur();
                beforeAdd(values).then((newItem) => {
                  if (!newItem) {
                    newItem = _.clone(defaultNewItem);
                  }
                  mutators.add(defaultNewItem);
                });
              }}
            >
              <i className='far fa-plus-circle'></i>
              Add {addText}
            </button>
            <ErrorField
              name='values'
              metaOverride={{
                modified: true,
                visited: true,
              }}
            />
          </div>
          <SubmitButton
            pristine={pristine}
            valid={valid}
            handleSubmit={handleSubmit}
            submittable={!_.isEqual(values, initialValues)}
          />
        </>
      )}
    />
  );
};

export default AddableForm;
