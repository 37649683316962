import React from 'react';

export default ({ licenses, curYear }) =>
  licenses.length ? (
    <>
      <div className='license-display'>
        <h5 className='m-y-xs'>State</h5>
        <h5 className='m-y-xs'>Number</h5>
        <h5 className='m-y-xs'>Type</h5>
        <h5 className='m-y-xs'>Expiration</h5>
        <h5 className='m-y-xs'>Verification</h5>
        <h5 className='m-y-xs'>Notes</h5>
        {licenses.map((c, i) => (
          <React.Fragment key={i}>
            <h6>{c.state}</h6>
            <div>{c.number}</div>
            <div>{c.license_type}</div>
            <div>
              {c.expiration}

              {parseInt(c.expiration) < curYear && (
                <p className='text-error'>
                  &nbsp;It looks like your license is past its expiration date!
                </p>
              )}
            </div>
            <div>
              {c.verification === 'Unverified' && (
                <>
                  Not verified{' '}
                  <i
                    className='fal fa-info-circle'
                    data-for='license-tooltip'
                    data-tip='The Zencare team is working on verifying your license.'
                  ></i>
                </>
              )}
              {c.verification === 'Verified' && (
                <>
                  Verified{' '}
                  <i
                    className='fal fa-info-circle'
                    data-for='license-tooltip'
                    data-tip='License has been verified through a manual lookup by the Zencare team'
                  ></i>
                </>
              )}
              {c.verification === 'Not found' && (
                <>
                  Not found{' '}
                  <i
                    className='fal fa-info-circle'
                    data-for='license-tooltip'
                    data-tip='License could not be found under the indicated name and license number.'
                  ></i>
                </>
              )}
            </div>
            <div>{c.notes}</div>
          </React.Fragment>
        ))}
      </div>
    </>
  ) : (
    <p className='instruction'>You have no licenses saved. Click "Edit" to add your licenses.</p>
  );
