import React from 'react';
import { connect } from 'react-redux';
import { actions as mainActions } from '~/components/main/sagaSlice';

import { useHistory } from 'react-router-dom';

export const ContactLicenseStuck = ({ provider }) => {
  const history = useHistory();

  return (
    <>
      {provider && (
        <>
          <span
            className='contact contact-licenses-stuck'
            role='presentation'
            onClick={() => history.push('/contact')}
          >
            let us know
          </span>
          <span className='text-error'>.</span>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {};
export default connect(mapStateToProps, actions)(ContactLicenseStuck);
