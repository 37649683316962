import React from 'react';

export const ClientMatching = () => (
  <div id='client-matching'>
    <h1>Client-Therapist Matching Service</h1>

    <p>
      {' '}
      If you need help matching a client to a therapist, fill out the matching form below or send
      this link to the individual to fill out!
    </p>

    <p> This is a free service to support clients who are looking for personalized matching.</p>

    <div className='buttons'>
      <a
        href='https://zencare.typeform.com/to/My6ZnzaX#source=therapist-match-page'
        rel='noopener noreferrer'
        target='_blank'
        className='button lg pill primary block m-r-md'
      >
        Match Form
      </a>

      <a
        href='mailto:hello@zencare.co?body=Hi%20Zencare%20team,%0d%0dI%20have%20a%20client%20I%20would%20like%20to%20find%20a%20referral%20for.%20Below%20are%20details%20about%20this%20client:%0d%0dLocation:%0dClient Name (optional):%0dWhat%20they%20are%20seeking:%0dClient%20email%20(optional):%0d%0dPlease%20let%20me%20know%20if%20you%20can%20recommend%20a%20provider%20for%20this%20individual.%0d%0dThank%20you!%0d%0d[Your%20name]'
        rel='EMAIL'
        className='button lg pill primary block'
      >
        Email Zencare Team
      </a>
    </div>
  </div>
);
