import * as React from 'react';
import { getReimbursifyDomain } from './reimbursifyUtils';
import { actions } from '@/reimbursify/sagaSlice';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  message: Message;
  textAreaInput: React.RefObject<HTMLTextAreaElement>;
}

function VobDeepLink({ message, textAreaInput }: Props) {
  const { vob_data, client_short_name } = message;
  const { reimbursify_eligibility_id, status } = vob_data;
  const dispatch = useDispatch();
  const { provider } = useSelector((state: $TSFixMe) => state.main);
  const handleClick = () => {
    // Checks if the message form is populated before proceeding to redirect the user
    const inputIsPopulated = Boolean(textAreaInput?.current?.value);

    if (inputIsPopulated) {
      if (
        !window.confirm(
          'Warning: Clicking "OK" will erase your drafted message and navigate to Private Pay Booster. You can click "Cancel" to return to your message without losing any content.'
        )
      ) {
        return;
      }
    }

    const sagaParam: ReimbursifyGenerateSsoJwtSagaParam = {
      params: {
        provider_id: provider.id,
      },
      onSuccess: (reimbursifyJwt: string) => {
        if (reimbursifyJwt) {
          const domain = getReimbursifyDomain();
          const urlPrefix = `https://${domain}/auth?jwt=`;
          const redirectUrl = encodeURIComponent(
            `https://${domain}/eligibility?ID=${reimbursify_eligibility_id}`
          );

          window.open(`${urlPrefix}${reimbursifyJwt}&redirect=${redirectUrl}`);
        } else {
          alert('Sorry, we ran into an error. Please contact support.');
        }
      },
    };
    // @ts-expect-error - NOTE: Need to look at example Jake made for typing this with JSDoc
    dispatch(actions.reimbursifyGenerateSsoJwt(sagaParam));
  };

  if (status !== 'run') {
    return null;
  }

  return (
    <button className='vob-deeplink' onClick={handleClick}>
      See full benefits verification for {client_short_name}
      <i className='far fa-external-link m-l-xs'></i>
    </button>
  );
}

export default VobDeepLink;
