import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import useStripeCardElement from '+/hooks/useStripeCardElement';
import ErrorField from '~/shared/forms/ErrorField';
import { useForm } from 'react-final-form';

const WrappedCardElement = () => {
  const { cardElRef } = useStripeCardElement();
  const form = useForm();
  const hasSubmitErrors = form.getState()?.hasSubmitErrors;
  const sourceError = form.getState()?.submitErrors?.source;

  return (
    <div ref={cardElRef}>
      <span className=''>Credit Card</span>
      <div className='card-input'>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#666666',
                },
              },
              invalid: {
                color: '#F2474E',
              },
            },
          }}
        />
      </div>
      {hasSubmitErrors && <p className='text-error'>{sourceError}</p>}
    </div>
  );
};

export default WrappedCardElement;
