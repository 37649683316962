/* eslint-disable max-lines-per-function */
import _ from 'lodash';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import AppointmentModal from '../AppointmentModal';
import Loader from '+/forms/SectionLoader';
import AppointmentRow from './AppointmentRow.jsx';
import ConversationRow from './ConversationRow.jsx';
import PageButtons from './PageButtons';

const PAGE_SIZE = 10;

interface Props {
  route: {
    practiceInbox: boolean;
  };
}

export default function ClientList(props: Props) {
  const { practiceInbox } = props.route;
  const dispatch = useDispatch();
  const provider = useSelector((state) => _.get(state, 'main.provider'));
  const isLoading = useSelector((state) => _.get(state, 'clients.isLoading'));

  const { clientList } = useSelector<State, ClientListState>((state) => state.clients);
  const { practiceClientList } = useSelector<State, ClientListState>((state) => state.clients);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [displayedClientList, setDisplayedClientList] = useState<
    null | ClientListState['clientList']
  >(null);
  const [sortIsAscending, setSortIsAscending] = useState(true);
  const [sortBy, setSortBy] = useState('DATE');
  const provider_id = provider.id;
  const account_id = provider.account_id;

  useEffect(() => {
    if (provider_id) {
      if (practiceInbox) {
        if (!practiceClientList) {
          // @ts-ignore
          dispatch(actions.getPracticeClientList({ account_id }));
        }
      } else {
        // @ts-ignore
        dispatch(actions.getClientList({ provider_id }));
      }
    }
  }, [dispatch, provider_id, practiceInbox, account_id]);

  useEffect(() => {
    if (practiceInbox) {
      // setDisplayedClientList(practiceClientList);
      setDisplayedClientList(practiceClientList);
    } else {
      setDisplayedClientList(clientList);
    }
  }, [clientList, practiceClientList, practiceInbox]);

  // Sort displayedClientList when sort parameters chance
  useEffect(() => {
    // Determine iteratee based on table heading clicked
    const iteratee =
      sortBy === 'DATE'
        ? 'contactDate'
        : sortBy === 'FIRST'
        ? 'firstName'
        : sortBy === 'LAST'
        ? 'lastName'
        : sortBy === 'EMAIL'
        ? 'email'
        : sortBy === 'PROVIDER'
        ? 'providerName'
        : 'contactDate';

    // Determine sort order
    // Flip default sort order for dates
    const order =
      iteratee === 'contactDate'
        ? sortIsAscending
          ? 'desc'
          : 'asc'
        : sortIsAscending
        ? 'asc'
        : 'desc';

    setDisplayedClientList((previous) => _.orderBy(previous, iteratee, order));
  }, [sortBy, sortIsAscending]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setSearch(value);
    setPage(1);
    updateSearchResults(value);
  };

  const updateSearchResults = (input: string) => {
    const currentList = clientList || practiceClientList;
    if (input === '') {
      return setDisplayedClientList(currentList);
    }
    // Prevent page break when search occurs before page load
    // An alternate solution here is to initialize clientList and practiceClientList
    // as empty arrays, but so much logic already relies on them getting initialized
    // as `null` that this fix was deemed safer
    if (!currentList) {
      return setDisplayedClientList([]);
    }

    setDisplayedClientList(() =>
      currentList.filter((client) => {
        const firstNameMatch =
          client.firstName && client.firstName.toLowerCase().includes(input.toLowerCase());
        const lastNameMatch =
          client.lastName && client.lastName.toLowerCase().includes(input.toLowerCase());
        // TODO display emails... for now, make them unsearchable
        const emailMatch = client.email.toLowerCase().includes(input.toLowerCase());
        const providerNameMatch = client.providerName?.toLowerCase().includes(input.toLowerCase());
        // return firstNameMatch || lastNameMatch;
        return firstNameMatch || lastNameMatch || emailMatch || providerNameMatch;
      })
    );
  };

  const renderRow = (client: $TSFixMe) => {
    const { providerId, data } = client;
    const { id } = data;
    const key = `${id}${providerId}`;
    if (client.type === 'acuity_appointment')
      return <AppointmentRow key={key} client={client} practiceInbox={practiceInbox} />;
    if (client.type === 'provider_contact')
      return <AppointmentRow key={key} client={client} practiceInbox={practiceInbox} />;
    if (client.type === 'conversation')
      return <ConversationRow key={key} client={client} practiceInbox={practiceInbox} />;
    return null;
  };

  type param = 'DATE' | 'FIRST' | 'LAST' | 'EMAIL' | 'PROVIDER';
  // Determine where and how sort indicating caret should be displayed
  const renderCaret = (param: param) => {
    if (param !== sortBy) return <i className='fas fa-sort'></i>;
    return <i className={`fas fa-caret-${sortIsAscending ? 'down' : 'up'}`}></i>;
  };

  // Adjust sorting parameters based on table header clicked
  const handleSort = (param: param) => {
    if (param === sortBy) setSortIsAscending(!sortIsAscending);
    if (param !== sortBy) setSortIsAscending(true);
    setSortBy(param);
  };

  if (isLoading || displayedClientList === null) {
    return (
      <div className='client-list-container'>
        <h1 className='m-b-xs'>{practiceInbox ? 'Practice Inbox | ' : ''}Full Client List </h1>
        <div className='box loader'>
          <input
            className='searchbar m-b-md'
            type='text'
            placeholder='Search by client name or email'
            onChange={handleChange}
            value={search}
          />
          <div className='placeholder-container'>
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  if (provider.status === 'active' && search.length > 0 && displayedClientList.length < 1) {
    return (
      <div className='client-list-container'>
        <h1 className='m-b-xs'>{practiceInbox ? 'Practice Inbox | ' : ''}Full Client List </h1>
        <div className='box loader'>
          <input
            className='searchbar m-b-md'
            type='text'
            placeholder='Search by client name or email'
            onChange={handleChange}
            value={search}
          />
          <div className='placeholder-container'>
            <h5 className='placeholder-content'>No clients match your search</h5>
          </div>
        </div>
      </div>
    );
  }

  if (displayedClientList.length < 1) {
    return (
      <div className='client-list-container'>
        <h1 className='m-b-xs'>{practiceInbox ? 'Practice Inbox | ' : ''}Full Client List </h1>
        <div className='box loader'>
          <input
            className='searchbar m-b-md'
            type='text'
            placeholder='Search by client name or email'
            onChange={handleChange}
            value={search}
          />
          <div className='placeholder-container'>
            {provider.status === 'active' ? (
              <h5 className='placeholder-content'>You haven't received any referrals yet!</h5>
            ) : (
              <h5 className='placeholder-content'>
                Your profile is not live yet! Complete your profile to go live and start getting
                referrals.
              </h5>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`client-list-container ${practiceInbox ? 'practice-inbox' : ''}`}>
      <h1 className='m-b-xs'>{practiceInbox ? 'Practice Inbox | ' : ''}Full Client List </h1>
      <div className='box'>
        <input
          className='searchbar'
          type='text'
          onChange={handleChange}
          value={search}
          placeholder='Search by client name or email'
        />
        <table>
          <thead>
            <tr>
              <th
                className={`${sortBy === 'DATE' ? 'active' : ''}`}
                onClick={() => handleSort('DATE')}
              >
                First contacted{renderCaret('DATE')}
              </th>
              <th
                className={`${sortBy === 'FIRST' ? 'active' : ''}`}
                onClick={() => handleSort('FIRST')}
              >
                First name{renderCaret('FIRST')}
              </th>
              <th
                className={`${sortBy === 'LAST' ? 'active' : ''}`}
                onClick={() => handleSort('LAST')}
              >
                Last name{renderCaret('LAST')}
              </th>
              <th
                className={`${sortBy === 'EMAIL' ? 'active' : ''}`}
                onClick={() => handleSort('EMAIL')}
              >
                Email{renderCaret('EMAIL')}
              </th>
              {practiceInbox && (
                <th
                  className={`${sortBy === 'PROVIDER' ? 'active' : ''}`}
                  onClick={() => handleSort('PROVIDER')}
                >
                  Provider{renderCaret('PROVIDER')}
                </th>
              )}
              <th>{''}</th>
            </tr>
          </thead>
          <tbody>
            {displayedClientList
              .slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page)
              .map((client) => renderRow(client))}
          </tbody>
        </table>
        <div className='w-100 flex justify-around p-t-sm'>
          <PageButtons
            page={page}
            setPage={setPage}
            clientList={displayedClientList}
            pageSize={PAGE_SIZE}
          />
        </div>
      </div>
      <AppointmentModal practiceInbox={!!practiceInbox} />
    </div>
  );
}
