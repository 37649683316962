import React from 'react';

import PureModal from '+/Modal';

export default function ConfirmHoursModal({ open, toggle }) {
  // eslint-disable-line
  return (
    <PureModal
      visible={open}
      closeModal={() => toggle(false)}
      headerContent={<h4>Location Availability</h4>}
    >
      <h2>Thank you for updating your availability!</h2>
      <h5>
        Please update your open appointment times and regular practice hours to keep these accurate
        too.
      </h5>
      <button className='pill primary md' onClick={() => toggle(false)}>
        Update appointment times and hours
      </button>
    </PureModal>
  );
}
