import React from 'react';
import { SummaryItemItemType } from './constants';

function SummaryItem({
  item,
  regionSeats = 0,
  nonRegionSeats = 0,
  upgrade = false,
  upgradingFromLegacy = false,
  upgradeReimbursify = false,
}: {
  item: SummaryItemItemType;
  regionSeats?: number;
  nonRegionSeats?: number;
  upgrade?: boolean;
  upgradingFromLegacy?: boolean;
  upgradeReimbursify?: boolean;
}) {
  const { recurring, description, price, locale_price } = item;
  const displayedPrice = (
    Math.round(
      (((upgrade || upgradeReimbursify) && !upgradingFromLegacy ? 0 : price) +
        regionSeats * price +
        nonRegionSeats * locale_price) *
        100
    ) / 100
  ).toFixed(2);
  return (
    <div className='flex justify-between appear'>
      <p>
        {description}
        {recurring && (
          <span>
            {' '}
            x
            {((upgrade || upgradeReimbursify) && !upgradingFromLegacy ? 0 : 1) +
              regionSeats +
              nonRegionSeats}
          </span>
        )}
      </p>
      <p className='payment-form-summary-number'>
        ${displayedPrice}
        <span>{recurring ? ' / Month' : ' Once'}</span>
      </p>
    </div>
  );
}

export default SummaryItem;
