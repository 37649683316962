import React from 'react';
import { Field } from 'react-final-form';
import Error from '+/forms/Error';

interface Props {
  name: string;
  className?: string | undefined;
  metaOverride?: object | undefined;
}

// Subscribes to errors for a field and renders below
const ErrorField = ({ name, className, metaOverride }: Props): JSX.Element => (
  <Field
    name={name}
    subscription={{
      error: true,
      visited: true,
      modified: true,
      submitFailed: true,
    }}
    render={({ meta }) => <Error {...meta} className={className} {...metaOverride} />}
  />
);

export default ErrorField;
