// Given a provider and account, check if any of the subscriptions attached to the account cover the provider.
// This is contained in the subscriptions metadata in provider_id or provider_ids (for legacy group practices)
export default function providerHasSubscriptionInAccount(provider, account) {
  return (
    account &&
    account.stripe_customers &&
    account.stripe_customers.length &&
    account.stripe_customers.some((sc) =>
      sc.stripe_subscriptions.some((sub) => isActiveProviderSub(provider, sub))
    )
  );
}

export function getProviderSubscription(provider, accounts) {
  for (const acc of accounts) {
    if (!acc || acc.error || !acc.stripe_customers || !acc.stripe_customers.length) {
      continue;
    }

    for (const customer of acc.stripe_customers) {
      if (!customer.stripe_subscriptions || !customer.stripe_subscriptions.length) {
        continue;
      }

      for (const sub of customer.stripe_subscriptions) {
        if (isActiveProviderSub(provider, sub)) {
          return sub;
        }
      }
    }
  }
}

const isActiveProviderSub = (provider, sub) =>
  (!['canceled', 'incomplete_expired'].includes(sub.status) &&
    sub.metadata &&
    sub.metadata.provider_id === provider.id) ||
  (sub.metadata && sub.metadata.provider_ids && sub.metadata.provider_ids.includes(provider.id)) ||
  (provider.provider_paid_seats &&
    provider.provider_paid_seats.length > 0 &&
    provider.provider_paid_seats[0].canceled_at === null);
