import React from 'react';
import { genericProps, createComponentClasses } from '../../utils/componentProps';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

/**
 *
 * @example
 *
 * <NavLink success href="#link">My Text</NavLink>
 * <NavLink icon="user" onClick={() => {}}>My Text</NavLink>
 *
 * <NavLink
 *      icon="fas user spin 2xl"
 *      href="#link"
 *      linkProps={{ ...props }}
 *      iconProps={{ ...props }}
 *      component={ Link }
 *      to={ '/react/link' }
 * />
 *
 */
export const NavLink = (props) => {
  const {
    children,
    icon,
    href,
    component,
    avatar,
    avatarBefore,
    practiceInbox,
    linkProps: _linkProps = {},
    iconProps: _iconProps = {},
    tooltip = '',
    ...rest
  } = props;
  const { hasUnreadMessages } = useSelector((state) => state.messages);
  const { hasUnreadPracticeMessages } = useSelector((state) => state.practiceInbox);

  // Pass html props and ZC utility classes to container
  const containerProps = genericProps(props);
  const containerClassName = createComponentClasses(props);
  containerProps.className = `link ${containerClassName}`.trim();

  // Pass html props and ZC utility classes to link
  const linkProps = genericProps(_linkProps);
  linkProps.className = createComponentClasses(_linkProps);

  let iconElement = icon || null;

  if (icon && typeof icon === 'string') {
    // support icon="fas user"
    const faMatch = icon.match(/(fa\w\s)/i);

    const faType = faMatch ? faMatch[0].trim() : 'fal';

    const faClasses = icon
      // remove 'fas ' from string
      .replace(`${faType} `, '')

      // support multiple fa classes 'spinner spin 2xl'
      .split(' ')

      // map names to fa classes
      .map((c) => `fa-${c}`)

      // arr to string
      .join(' ');

    // Pass html props and ZC utility classes to icon
    const iconProps = genericProps(_iconProps);
    const iconClassName = createComponentClasses(_iconProps);
    iconProps.className = `${faType} ${faClasses} ${iconClassName} fa-fw side-nav-icon`.trim();

    iconElement = (
      <i data-for='menu-tooltip' data-tip={tooltip} {...iconProps}>
        {!practiceInbox && hasUnreadMessages && icon === 'envelope' ? (
          <span className='unread'></span>
        ) : null}
        {practiceInbox && hasUnreadPracticeMessages && icon === 'envelope' ? (
          <span className='unread'></span>
        ) : null}
      </i>
    );
  }

  // Enforce value and do not allow <NavLink icon />
  if (typeof icon === 'boolean') {
    throw TypeError('icon must be a string or a jsx');
  }

  // console.log(props);
  const linkComponent = () => {
    if (href && component) {
      throw TypeError('cannot combine href with custom component');
    }

    const linkComponentProps = {
      ...rest,
      ...linkProps,
    };

    if (component) {
      linkComponentProps.activeClassName = 'active';

      return React.createElement(
        component,
        linkComponentProps,
        <>
          {iconElement} {children}
        </>
      );
    }

    return (
      <a href={href} {...linkComponentProps}>
        {iconElement}
        {children}
      </a>
    );
  };

  return (
    <div {...containerProps}>
      {avatarBefore && (avatar || null)}
      {linkComponent()}
      {!avatarBefore && (avatar || null)}
    </div>
  );
};

export default NavLink;
