import React from 'react';
import { onlyOnEnter } from '../utils';

interface Props {
  text: String;
  isOpen: boolean;
  toggleMethod: Function;
  children: JSX.Element;
}

function Accordian({ text, isOpen, toggleMethod, children }: Props) {
  return (
    <>
      <div className='accordian'>
        <div
          className='toggle-text-and-icon'
          role='button'
          tabIndex={0}
          onKeyDown={onlyOnEnter(() => toggleMethod())}
          onClick={() => {
            toggleMethod();
          }}
        >
          <i className={`toggle fal fa-fw fa-chevron-down ${isOpen ? 'active' : ''}`}></i> {text}
        </div>

        <div className={`accordian-content ${isOpen ? 'active' : ''}`}>{children}</div>
      </div>
    </>
  );
}

export default Accordian;
