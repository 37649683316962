import _ from 'lodash';

export function photoSrc(image) {
  return `${process.env.ZENCARE_IMGIX_URL}/${encodeURIComponent(_.get(image, 'key'))}`;
}

export function imgix(
  image,
  opts = {
    thumbnail: true,
    gallery: true,
  }
) {
  if (!image || !image.key) {
    return;
  }

  const { thumbnail, gallery, format, maxH, maxW, lightbox } = opts;

  const qry = ['q=75'];

  if (thumbnail && gallery) {
    qry.push('w=400&h=400&fit=crop&crop=faces');
  }

  if (thumbnail && !gallery) {
    qry.push('w=400&fit=facearea&facepad=10');
  }

  if (!thumbnail && gallery) {
    qry.push('w=400&h=400&fit=crop&crop=faces');
  }

  if (lightbox) {
    qry.push('fm=jpg&&max-h=960&&max-w=1920');
  }

  if (format) {
    qry.push(`fm=${format}`);
  }

  if (maxH) {
    qry.push(`&max-h=${maxH}`);
  }

  if (maxW) {
    qry.push(`&max-w=${maxW}`);
  }

  return `${process.env.ZENCARE_IMGIX_URL}/${encodeURIComponent(image.key)}?${qry.join('&')}`;
}
