import React from 'react';
import _ from 'lodash';
import PhotoVideoshootIframe from '@/onboarding/PhotoVideoshootIframe';

const PhotoVideoshootIframePage = function (props) {
  const type = _.get(props, 'match.params.type');

  /* eslint-disable*/
  return (
    <div
      id='static-page'
      className='main-content'
      style={{
        width: '100%',
        height: 'calc(100vh - 65px)',
        overflowY: 'hidden',
      }}
    >
      <h1 className='title m-b-0  m-l-sm m-t-sm'>Schedule Your Photo / Videoshoot</h1>
      <hr></hr>
      <PhotoVideoshootIframe type={type} />
    </div>
  );
};

export default PhotoVideoshootIframePage;
