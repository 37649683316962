import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import mixpanel from 'mixpanel-browser';
import { commaSeparateValues, transformDateFormat } from './reimbursifyUtils';

interface Props {
  message: Message;
}

function UpgradeText({
  children,
  isBold = false,
  handleClick,
}: {
  children: string;
  isBold?: boolean;
  handleClick: $TSFixMe;
}) {
  return (
    <span
      className={`red ${isBold ? 'bold' : ''}`}
      role='button'
      onKeyDown={handleClick}
      onClick={handleClick}
      tabIndex={0}
    >
      {children}
    </span>
  );
}

function InsuranceDetailsContainer({ message }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { client_short_name, vob_data, vob_input_info } = message;
  const {
    estimated_reimbursement_after_deductible_percent,
    oon_individual_deductible_remaining_dollars,
    oon_family_deductible_remaining_dollars,
    status,
    id,
    user_id,
    provider_id,
  } = vob_data;

  const reimbursementRate = estimated_reimbursement_after_deductible_percent
    ? `${estimated_reimbursement_after_deductible_percent}% `
    : `${0}% `;

  const upgradeText = `You've hit your limit on benefit verifications. Upgrade Private Pay Booster to see ${client_short_name}'s out-of-network insurance details.`;

  // VOB info will only display if VOB status is not 'run' or missing critical info
  const showVobInputInfo =
    status !== 'run' || estimated_reimbursement_after_deductible_percent === null;

  // ex. CaraZero McTest, DOB 02/22/2023 - Aetna #CaraMcTest1234
  const vobInputInfo = showVobInputInfo
    ? `${vob_input_info.insurance_first_name} ${
        vob_input_info.insurance_last_name
      }, DOB ${transformDateFormat(vob_input_info.dob)} - ${
        vob_input_info.reimbursify_insurance_name
      } #${vob_input_info.insurance_member_id}`
    : '';

  function trackEvent(data: { action?: string; provider_id: number | null; card_name: string }) {
    try {
      mixpanel.track('therapist_portal_cta_event', data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  function handleRunVobClick() {
    trackEvent({
      action: 'follow_cta_link',
      provider_id: provider_id,
      card_name: 'vob_intake_rerun_vob',
    });
    dispatch(
      // @ts-ignore
      actions.runPendingVob({ user_vob_id: id, provider_id: provider_id, user_id })
    );
  }
  function handleUpgradeClick() {
    trackEvent({
      action: 'follow_cta_link',
      provider_id: provider_id,
      card_name: 'vob_intake_upgrade_ppb',
    });
    history.push('/plan-management');
  }

  useEffect(() => {
    if (status === 'pending_rerun') {
      trackEvent({
        action: 'display_cta',
        provider_id,
        card_name: 'vob_intake_rerun_vob',
      });
    }
    if (status === 'vob_limit_reached') {
      trackEvent({
        action: 'display_cta',
        provider_id,
        card_name: 'vob_intake_upgrade_ppb',
      });
    }
  }, [provider_id, status]);

  if (status === 'pending_rerun') {
    return (
      <div className='insurance-details-container'>
        <p className='insurance-details-status'>
          See {client_short_name}’s out-of-network insurance details.
        </p>
        <button onClick={handleRunVobClick} className='primary pill md insurance-details-upgrade'>
          Run benefit verification
        </button>
      </div>
    );
  }

  if (status === 'vob_limit_reached') {
    return (
      <div className='insurance-details-container'>
        <p className='insurance-details-status'>
          {client_short_name} has out-of-network benefits for therapy!{' '}
          <UpgradeText handleClick={handleUpgradeClick}>{upgradeText}</UpgradeText>
        </p>
        <div className='reimbursement-rate'>
          <h6>Reimbursement Rate</h6>
          <p className='m-b-sm'>
            After their deductible, their insurance will pay{' '}
            <UpgradeText isBold={true} handleClick={handleUpgradeClick}>
              UPGRADE
            </UpgradeText>{' '}
            of the allowed amount.
          </p>
        </div>
        {
          <div className='deductible-info'>
            <h6>Deductible Info</h6>

            <p>
              <UpgradeText isBold={true} handleClick={handleUpgradeClick}>
                UPGRADE
              </UpgradeText>{' '}
              until they fulfill their individual deductible.
            </p>

            <p>
              <UpgradeText isBold={true} handleClick={handleUpgradeClick}>
                UPGRADE
              </UpgradeText>{' '}
              until they fulfill their family deductible.
            </p>
          </div>
        }
        <button onClick={handleUpgradeClick} className='primary pill md insurance-details-upgrade'>
          Upgrade
        </button>
      </div>
    );
  }

  if (status === 'unknown_error') {
    return (
      <div className='insurance-details-container'>
        <p className='insurance-details-status'>
          We're unable to check if {client_short_name} has out-of-network benefits for therapy. You
          can verify coverage with their insurance company.
        </p>
        {showVobInputInfo && <p className='insurance-details'>{vobInputInfo}</p>}
      </div>
    );
  }

  if (estimated_reimbursement_after_deductible_percent === null) {
    return (
      <div className='insurance-details-container'>
        <p className='insurance-details-status'>
          We're unable to check if {client_short_name} has out-of-network benefits for therapy. You
          can verify coverage with their insurance company.
        </p>
        {showVobInputInfo && <p className='insurance-details'>{vobInputInfo}</p>}
      </div>
    );
  }

  if (Number(estimated_reimbursement_after_deductible_percent) === 0) {
    return (
      <div className='insurance-details-container'>
        <p className='insurance-details-status'>
          {client_short_name} does not have out-of-network benefits for therapy.
        </p>
      </div>
    );
  }

  return (
    <div className='insurance-details-container'>
      <p className='insurance-details-status'>
        {client_short_name} has out-of-network benefits for therapy!
      </p>
      <div className='reimbursement-rate'>
        <h6>Reimbursement Rate</h6>
        <p className='m-b-sm'>
          After their deductible, their insurance will pay{' '}
          <span className='bold'>{reimbursementRate}</span>
          of the allowed amount.
        </p>
      </div>
      {(oon_individual_deductible_remaining_dollars || oon_family_deductible_remaining_dollars) && (
        <div className='deductible-info'>
          <h6>Deductible Info</h6>
          {oon_individual_deductible_remaining_dollars && (
            <p>
              <span className='bold'>
                ${commaSeparateValues(oon_individual_deductible_remaining_dollars)}
              </span>{' '}
              until they fulfill their individual deductible.
            </p>
          )}
          {oon_family_deductible_remaining_dollars && (
            <p>
              <span className='bold'>
                ${commaSeparateValues(oon_family_deductible_remaining_dollars)}
              </span>{' '}
              until they fulfill their family deductible.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default InsuranceDetailsContainer;
