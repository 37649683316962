import { crudSlice, noop, loadingReducer } from 'saga-slice-helpers';
import { createModule } from 'saga-slice';
import { sagaApi } from '#/apis';
import TagTypes from './types';

const modules = {};
export const actions = {};

// eslint-disable-next-line no-unused-vars
for (const [singular, plural] of TagTypes) {
  modules[plural] = crudSlice({
    name: plural,
    sagaApi,
    reducers: {
      readEvery: loadingReducer,
      readEverySuccess: function (state, payload) {
        state.data = payload;
      },
      readEveryFail: noop,
    },
    sagas: (A) => ({
      [A.readEvery]: {
        *saga() {
          yield sagaApi.get(`/${plural}/all`, A.readEverySuccess, A.readEveryFail);
        },
      },
    }),
  });

  actions[plural] = modules[plural].actions;
}
modules.generic = createModule({
  name: 'tags',
  initialState: {
    associatedTags: [],
  },
  reducers: {
    getAssociatedTags: noop,
    getAssociatedTagsSuccess: (state, payload) => {
      state.associatedTags = payload;
    },
    getAssociatedTagsFail: noop,
  },
  sagas: (A) => ({
    [A.getAssociatedTags]: {
      *saga() {
        yield sagaApi.get(
          `/portal/tags/associations/all`,
          A.getAssociatedTagsSuccess,
          A.getAssociatedTagsFail
        );
      },
    },
  }),
});

actions.generic = modules.generic.actions;

export default modules;
