import { put } from 'redux-saga/effects';
import _ from 'lodash';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';
import { flashSuccess } from '+/flashes/redux';

const initialState = {
  isLoading: false,
  currentlyOpenId: null,
  legacyReferrals: {},
  currentAppointment: null,
  appointments: null,
  cancelInProgress: false,
  cancelError: null,
  rescheduleInProgress: false,
  rescheduleError: null,
  rescheduleSuccess: false,
};

const sagaSliceModule = createModule({
  name: 'appointments',
  initialState,
  reducers: {
    clearStore: (state) => {
      state = {
        ...initialState,
      };

      return state;
    },
    getAppointment: loadingReducer,
    getAppointmentSuccess: (state, payload) => {
      state.isLoading = false;
      state.currentAppointment = payload;
    },
    getAppointmentFail: failReducer,
    openAppointment: (state, payload) => {
      state.currentlyOpenId = payload.id;
      state.currentAppointment = null;
    },
    closeAppointment: (state, __) => {
      state.currentlyOpenId = null;
      state.currentAppointment = null;
      state.cancelInProgress = false;
      state.cancelError = null;
      state.rescheduleInProgress = false;
      state.rescheduleSuccess = false;
      state.rescheduleError = null;
    },

    rescheduleAppointment: (state, payload) => {
      state.rescheduleInProgress = true;
      state.rescheduleError = null;
    },
    rescheduleAppointmentSuccess: (state, payload) => {
      state.rescheduleInProgress = false;
      state.rescheduleSuccess = true;
    },
    rescheduleAppointmentFail: (state, payload) => {
      state.rescheduleInProgress = false;
      state.rescheduleError = payload.toString();
    },

    getAllAppointments: loadingReducer,
    getAllAppointmentsSuccess: (state, payload) => {
      state.isLoading = false;
      state.appointments = payload;
    },
    getAllAppointmentsFail: failReducer,
    cancelAppointment: (state, payload) => {
      state.cancelInProgress = true;
      state.cancelError = null;
    },
    cancelAppointmentSuccess: (state, payload) => {
      state.cancelInProgress = false;
    },
    cancelAppointmentFail: (state, payload) => {
      state.cancelInProgress = false;
      state.cancelError = payload.toString();
    },

    getLegacyReferrals: loadingReducer,
    getLegacyReferralsSuccess: (state, payload) => {
      state.legacyReferrals = payload;
      state.isLoading = false;
    },
    getLegacyReferralsFail: failReducer,

    openLegacyReferral: (state, payload) => {
      state.currentAppointment = payload;
    },
  },
  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.openAppointment]: function* ({ payload }) {
        yield put(A.getAppointment(payload));
      },
      [A.getAppointment]: function* ({ payload }) {
        const { id } = payload;
        yield sagaApi.get(
          `/portal/appointment/${id}`,
          A.getAppointmentSuccess,
          A.getAppointmentFail
        );
      },
      [A.getAllAppointments]: function* ({ payload }) {
        const { provider_id } = payload;
        yield sagaApi.get(
          `/portal/provider/${provider_id}/appointments`,
          A.getAllAppointmentsSuccess,
          A.getAllAppointmentsFail
        );
      },
      [A.rescheduleAppointment]: function* ({ payload }) {
        const { id } = payload;
        delete payload.id;
        yield sagaApi.put(
          `/portal/appointment/${id}/reschedule`,
          payload,
          A.rescheduleAppointmentSuccess,
          A.rescheduleAppointmentFail
        );
        yield sagaApi.get(
          `/portal/appointment/${id}`,
          A.getAppointmentSuccess,
          A.getAppointmentFail
        );
      },
      [A.rescheduleAppointmentSuccess]: function* ({ payload }) {
        yield put(A.getAllAppointments({ provider_id: payload.provider_id }));
        // Appointments appear on calendar affect availability, so we want to update the calendar, if we're on that screen.
        yield put(flashSuccess('Your phone consultation has been rescheduled.'));
        yield put({ type: 'calendars/availabilitySuccess' });
      },
      [A.cancelAppointment]: function* ({ payload }) {
        const { id } = payload;
        delete payload.id;
        yield sagaApi.put(
          `/portal/appointment/${id}/cancel`,
          payload,
          A.cancelAppointmentSuccess,
          A.cancelAppointmentFail
        );
      },
      [A.cancelAppointmentSuccess]: function* ({ payload }) {
        yield put(A.closeAppointment());
        yield put(A.getAllAppointments({ provider_id: payload.provider_id }));
        // Appointments appear on calendar affect availability, so we want to update the calendar, if we're on that screen.
        yield put(flashSuccess('Your phone consultation has been cancelled.'));
        yield put({ type: 'calendars/availabilitySuccess' });
      },
      [A.getLegacyReferrals]: function* ({ payload }) {
        const { provider_id } = payload;
        yield sagaApi.get(
          `/portal/provider/${provider_id}/referrals`,
          A.getLegacyReferralsSuccess,
          A.getLegacyReferralsFail
        );
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
