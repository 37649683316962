/* eslint-disable max-lines-per-function */
import React from 'react';
import _ from 'lodash';

import { Form, Field } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import { actions } from './sagaSlice';
import PureModal from '+/Modal';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import { RRule } from 'rrule';
import GeneralTabSelect from '+/forms/GeneralTabSelect';
import FormCheckbox from '+/forms/FormCheckbox';
import TimeSelector from '../../../shared/forms/TimeSelector';
import { militaryTimeTo12HourTime } from '../../../utils/timeUtils';

import {
  daysOfWeek,
  dateOfMonth,
  nthWeekdayOfMonth,
  suffixDate,
  rruleStringToFormOptions,
  validateFormValues,
  submit,
  deleteEvent,
} from './availabilityHelpers';

window.rrule = RRule;

export default function AvailabilityModal() {
  const dispatch = useDispatch();
  const originalEvent = useSelector((state) => state.calendars.currentlyOpen) || {};
  const defaultRecurrence = useSelector((state) => state.calendars.defaultRecurrence) || null;
  const availabilityModalGoogleCalendarDefaultWorkingHoursMode =
    useSelector(
      (state) => state.calendars.availabilityModalGoogleCalendarDefaultWorkingHoursMode
    ) || false;
  const calendarsState = useSelector((state) => state.calendars);
  const open = !!originalEvent.start;
  const tz = useSelector((state) => state.calendars.tz);
  const provider = useSelector((state) => state.main.provider);
  const calendar = _.get(provider, 'calendar[0]');
  const editing = !!originalEvent.id;
  const isException = originalEvent.parent_id;

  let day;
  let startTime;
  let endTime;
  let recurrence;

  if (originalEvent.start) {
    day = originalEvent.start.toFormat('yyyy-LL-dd');
    startTime = originalEvent.start.toFormat('HH:mm');
    endTime = originalEvent.end.toFormat('HH:mm');

    if (originalEvent.rrule) {
      recurrence = 'Custom';
    }
  }

  if (defaultRecurrence) {
    recurrence = defaultRecurrence;
  }

  const minDay = DateTime.local({ zone: tz }).toFormat('yyyy-LL-dd');
  const maxDay = DateTime.local({ zone: tz }).plus({ days: 90 }).toFormat('yyyy-LL-dd');

  const isRegularEvent = !originalEvent.is_recurring && !originalEvent.parent_id;
  //eslint-disable-next-line
  const deleteEventByType = (type) =>
    deleteEvent({ dispatch, originalEvent, calendar, options: { type } });

  const editAvailabilityInterior = (
    <div className='edit-availability-modal-interior'>
      <Form
        initialValues={{
          startTime: militaryTimeTo12HourTime(startTime),
          endTime: militaryTimeTo12HourTime(endTime),
          date: day,
          free: originalEvent.free ? 'free' : 'busy',
          recurrence: recurrence || 'Does not repeat',
          editSelection: originalEvent.is_recurring ? 'instance' : null,
          name: originalEvent.name || (originalEvent.free ? 'Available' : 'Unavailable'),
          r: rruleStringToFormOptions(originalEvent.rrule, originalEvent.start),
        }}
        mutators={{
          setField: ([name, val], state, { changeValue }) => {
            changeValue(state, name, () => val);
          },
        }}
        onSubmit={(values) =>
          submit({
            values,
            dispatch,
            originalEvent,
            tz,
            calendar,
            provider,
            googleCalendarDefaultWorkingHoursCompleted:
              availabilityModalGoogleCalendarDefaultWorkingHoursMode,
          })
        }
        validate={(values) => validateFormValues(values, tz)}
        render={({ form, handleSubmit, valid, values, errors }) => (
          <div>
            {originalEvent.id && originalEvent.is_recurring && (
              <div className='flex column m-b-md'>
                <p>
                  This is one instance of a recurring event that occurs{' '}
                  {RRule.fromString(originalEvent.rrule).toText()}. You can edit every instance of
                  this event or edit just this instance.
                </p>
                <h6 className='m-b-0'>What do you want to edit?</h6>
                <GeneralTabSelect
                  tabOptions={{
                    instance: 'This instance',
                    all_recurring: 'All instances',
                  }}
                  name='editSelection'
                />
              </div>
            )}
            {originalEvent.id && !!originalEvent.parent_id && (
              <div className='flex column'>
                <p>
                  This instance is an exception to a recurring event. You can make changes to this
                  instance or you can restore the original instance of the recurring event.
                </p>
              </div>
            )}
            {calendarsState.showTypeToggle && (
              <div className='flex wrap m-b-sm'>
                {/* Name is left unchanged -- name display is determined based on item.free in CalendarView > Block -- keeping this for future versioning */}
                {/* <div className='flex column m-b-sm m-r-sm'>
              <h6 className='m-b-0'>Event name</h6>
              <TextField name='name' value={'doot'} />
            </div> */}
                <div className='flex column m-b-sm' style={{ width: 260 }}>
                  <h5 className='m-b-xs m-t-xs'>Availability</h5>
                  <GeneralTabSelect
                    tabOptions={{
                      free: 'Available',
                      busy: 'Unavailable',
                    }}
                    name='free'
                  />
                </div>
              </div>
            )}
            {values.free === 'busy' && (
              <p className='info text-error'>
                Clients can only schedule phone consultations with you during your “Available”
                blocks. “Unavailable” blocks will override available blocks.
              </p>
            )}
            <div className='flex wrap date-wrapper'>
              {!(editing && values.editSelection === 'all_recurring') && (
                <div className='flex column m-r-md' style={{ flex: '0 0 160px' }}>
                  <h5 className='m-b-0 m-t-sm'>Date</h5>
                  <Field name='date'>
                    {({ input }) => <input {...input} type='date' min={minDay} max={maxDay} />}
                  </Field>
                  {(errors.date || errors.startTime || errors.endTime) && (
                    <div style={{}}>
                      <ErrorField name='date' />
                    </div>
                  )}
                </div>
              )}
              <div className='start-end-wrapper'>
                <div className='start-time flex column'>
                  <h5 className='m-b-0 m-t-sm'>Start</h5>

                  <Field name='startTime'>
                    {(fieldProps) => (
                      <TimeSelector
                        fieldProps={fieldProps}
                        setValueFromDropdownSelection={(newValue) => {
                          form.mutators.setField('startTime', newValue);
                        }}
                      />
                    )}
                  </Field>
                  {(errors.date || errors.startTime || errors.endTime) && (
                    <div className='date-error'>
                      <ErrorField
                        name='startTime'
                        metaOverride={{
                          modified: true,
                          visited: true,
                        }}
                      />
                    </div>
                  )}
                </div>
                <span
                  className='m-l-sm m-r-sm m-t-md date-dash'
                  style={{
                    fontSize: '24px',
                  }}
                >
                  —
                </span>
                <div className='flex column' style={{ flex: '0 0 160px' }}>
                  <h5 className='m-b-0 m-t-sm'>End</h5>
                  <Field name='endTime'>
                    {(fieldProps) => (
                      <TimeSelector
                        fieldProps={fieldProps}
                        setValueFromDropdownSelection={(newValue) => {
                          form.mutators.setField('endTime', newValue);
                        }}
                      />
                    )}
                  </Field>
                  {(errors.date || errors.startTime || errors.endTime) && (
                    <div className='date-error'>
                      <ErrorField
                        name='endTime'
                        metaOverride={{
                          modified: true,
                          visited: true,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!((editing && values.editSelection === 'instance') || isException) && (
              <div className='m-b-md m-t-sm'>
                <h5 className='m-b-xs m-t-lg'>This timeslot repeats&hellip;</h5>
                <div className='flex column'>
                  <Field name='recurrence' component='select' type='select'>
                    {['Does not repeat', 'Daily', 'Every Weekday (Monday to Friday)', 'Custom'].map(
                      (o) => (
                        <option
                          key={o}
                          disabled={originalEvent.is_recurring && o === 'Does not repeat'}
                          value={o}
                        >
                          {o}
                        </option>
                      )
                    )}
                    <i className='fas fa-caret-down'></i>
                  </Field>
                </div>
              </div>
            )}
            <div className='m-b-md m-t-sm recurrence-tip-text'>
              {values.recurrence === 'Does not repeat' ? (
                <>Tip: This timeslot does not repeat. You can make it a repeating timeslot above.</>
              ) : null}
              {values.recurrence === 'Every Weekday (Monday to Friday)' ? (
                <>Tip: This timeslot repeats every weekday. You can change this above.</>
              ) : null}
              {values.recurrence === 'Daily' ? (
                <>Tip: This timeslot repeats every day. You can change this above.</>
              ) : null}
            </div>
            {/* Adjusting recurrence box */}
            {!((editing && values.editSelection === 'instance') || isException) &&
              values.recurrence === 'Custom' && (
                <div className='custom-recurrence-section'>
                  <div>
                    <span className='m-r-sm' style={{ fontWeight: 500 }}>
                      Repeat every:
                    </span>
                    <Field name='r.interval'>
                      {({ input }) => (
                        <input
                          {...input}
                          className='m-r-sm'
                          type='number'
                          min={1}
                          max={100}
                          placeholder='1'
                        ></input>
                      )}
                    </Field>
                    <Field name='r.freq' component='select' type='select'>
                      {['day', 'week', 'month', 'year'].map((o) => (
                        <option key={o} value={o}>
                          {o}
                          {parseInt(values.r.interval) !== 1 && 's'}
                        </option>
                      ))}
                      <i className='fas fa-caret-down'></i>
                    </Field>
                    <ErrorField name='r.interval' />
                  </div>
                  {values.r.freq === 'week' && (
                    <div className='m-t-xs m-b-xs'>
                      <div className='flex'>
                        <span style={{ fontWeight: 500 }} className='m-r-sm'>
                          On:
                        </span>
                        {daysOfWeek.map((d) => (
                          <Field name={`r.days.${d}`}>
                            {(props) => (
                              <FormCheckbox
                                fieldClassName='days-of-week'
                                {...props}
                                label={d.slice(0, 2)}
                              />
                            )}
                          </Field>
                        ))}
                      </div>
                      <ErrorField
                        name='r.days'
                        metaOverride={{
                          modified: true,
                          visited: true,
                        }}
                      />
                    </div>
                  )}
                  {values.r.freq === 'month' && (
                    <div className='m-t-xs m-b-xs'>
                      <Field name='r.monthly_type' component='select' type='select'>
                        {['exact_date', 'weekday'].map((o) => {
                          let text;
                          const date = DateTime.fromFormat(values.date, 'yyyy-LL-dd', {
                            zone: tz,
                          });
                          const monthDate = dateOfMonth(date);
                          if (o === 'exact_date') {
                            text = `Monthly on the ${monthDate}${suffixDate(monthDate)}`;
                          } else {
                            const nthDayOfWeek = nthWeekdayOfMonth(date);
                            text = `Monthly on the ${nthDayOfWeek.n}${suffixDate(nthDayOfWeek.n)} ${
                              nthDayOfWeek.weekday
                            }`;
                          }

                          return (
                            <option key={o} value={o}>
                              {text}
                            </option>
                          );
                        })}
                        <i className='fas fa-caret-down'></i>
                      </Field>
                    </div>
                  )}
                  <div className='flex column recurrence-ending-container'>
                    <span style={{ fontWeight: 500 }}>Ending:</span>
                    <Field
                      name='r.endType'
                      component='input'
                      type='radio'
                      value='never'
                      id='r.endType.never'
                    />
                    <label className='m-b-sm' htmlFor='r.endType.never'>
                      Never
                    </label>
                    <Field
                      name='r.endType'
                      component='input'
                      type='radio'
                      value='onDate'
                      id='r.endType.onDate'
                    />
                    <label className='m-b-sm' htmlFor='r.endType.onDate'>
                      <span style={{ marginRight: 28 }}>On</span>
                      <Field name='r.endsOnDate'>
                        {({ input }) => <input {...input} type='date' min={minDay} />}
                      </Field>
                    </label>
                    <ErrorField name='r.endsOnDate' />
                    <Field
                      name='r.endType'
                      component='input'
                      type='radio'
                      value='afterCount'
                      id='r.endType.afterCount'
                    />
                    <label className='m-b-sm' htmlFor='r.endType.afterCount'>
                      <span className='m-r-sm'>After</span>
                      <Field name='r.endsAfterCount'>
                        {({ input }) => (
                          <input {...input} type='number' min={1} max={100} placeholder='1'></input>
                        )}
                      </Field>
                      <span className='m-l-sm'> occurence{values.r.endsAfterCount > 1 && 's'}</span>
                    </label>
                    <ErrorField name='r.endsAfterCount' />
                  </div>
                </div>
              )}
            <div style={{ visibility: 'hidden' }} id='availability-actions'>
              <button
                id='availability-submit'
                onClick={() => {
                  if (!valid) return;
                  handleSubmit();
                }}
                className='primary'
              >
                Save
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );

  let title = `${editing ? 'Edit' : 'Create'} Availability`;
  if (originalEvent?.provider_external_calendar_id) {
    title = 'View Availability';
  }
  if (availabilityModalGoogleCalendarDefaultWorkingHoursMode) {
    title = 'Set Default Working Hours';
  }

  return (
    <PureModal
      visible={open}
      headerContent={<h4>{title}</h4>}
      footerContent={
        <div className='actions flex justify-between'>
          {editing && isRegularEvent && !originalEvent?.provider_external_calendar_id && (
            <button onClick={deleteEventByType} className='secondary hollow'>
              Delete
            </button>
          )}
          {editing && originalEvent.is_recurring && (
            <div>
              <button onClick={() => deleteEventByType('instance')} className='secondary hollow'>
                Delete this instance
              </button>
              <button onClick={() => deleteEventByType('all')} className='secondary hollow'>
                Delete recurring event
              </button>
            </div>
          )}

          {editing && isException && (
            <div>
              <button onClick={() => deleteEventByType('instance')} className='secondary hollow'>
                Delete this instance
              </button>
              <button onClick={() => deleteEventByType('restore')} className='secondary hollow'>
                Restore instance
              </button>
            </div>
          )}

          {originalEvent?.provider_external_calendar_id ? (
            <button
              onClick={() => {
                dispatch(actions.closeAvailability());
              }}
              className='primary'
            >
              Close
            </button>
          ) : (
            <button
              onClick={() => {
                const button = document.querySelector('#availability-submit');
                if (button) {
                  button.click();
                }
              }}
              className='primary'
            >
              Save
            </button>
          )}
        </div>
      }
      closeModal={() => dispatch(actions.closeAvailability())}
    >
      {originalEvent?.provider_external_calendar_id ? (
        <div>
          <p className='m-b-sm'>{originalEvent.name}</p>
          <p>Day: {originalEvent.start.toFormat('cccc, LLLL d')}</p>
          <p>
            Time: {originalEvent.start.toFormat('t')} - {originalEvent.end.toFormat('t')}
          </p>
          <p>This event is synced from an external calendar and cannot be edited here.</p>
        </div>
      ) : (
        editAvailabilityInterior
      )}
    </PureModal>
  );
}
