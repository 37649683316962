import React from 'react';
import { Form, Field } from 'react-final-form';
import FormCheckbox from '+/forms/FormCheckbox';
import PureModal from '+/Modal';

export default function DeleteLocationModal({ open, toggle, onSubmit, locations }) {
  // eslint-disable-line
  return (
    <PureModal
      visible={open}
      headerContent={<h4>Remove a location</h4>}
      footerContent={
        <div className='actions'>
          <button
            onClick={() => {
              const button = document.querySelector(
                '.delete-location-modal-interior .actions button.hollow'
              );
              if (button) {
                button.click();
              }
            }}
            className='hollow'
          >
            Cancel
          </button>
          <button
            onClick={() => {
              const button = document.querySelector(
                '.delete-location-modal-interior .actions button.primary'
              );
              if (button) {
                button.click();
              }
            }}
            className='secondary'
          >
            Remove Location
          </button>
        </div>
      }
      closeModal={() => toggle(false)}
    >
      <div className='delete-location-modal-interior'>
        <h5>Which locations would you like to remove?</h5>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, valid }) => (
            <>
              <div className='flex column justify-between m-b-sm'>
                {(locations || []).map((l) => (
                  <div className='checkbox' key={l.id}>
                    <Field name={`l-${l.id}`}>
                      {(props) => (
                        <FormCheckbox
                          {...props}
                          fieldClassName='m-b-xs'
                          label={`${l.location_name}${l.address ? ` (${l.address})` : ''}`}
                        />
                      )}
                    </Field>
                  </div>
                ))}
              </div>
              <div className='actions'>
                <button
                  onClick={() => {
                    toggle(false);
                  }}
                  className='hollow'
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (!valid) return;
                    handleSubmit();
                  }}
                  className='primary'
                >
                  Save
                </button>
              </div>
            </>
          )}
        />
      </div>
    </PureModal>
  );
}
