import React from 'react';
import { Field } from 'react-final-form';

const TextField = ({ name, placeholder, label, formError, initialValue, ...restProps }) => (
  <Field name={name} initialValue={initialValue}>
    {({ input, meta }) => (
      <div className={`field ${meta.error && meta.touched && 'error'} ${formError && 'error'}`}>
        <label htmlFor={name}>{label}</label>
        <input placeholder={placeholder} {...input} {...restProps} />
        {meta.error && meta.touched && <span className='text-error'>{meta.error}</span>}
        {formError && <span className='text-error'>{formError}</span>}
      </div>
    )}
  </Field>
);

export default TextField;
