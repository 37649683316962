export const sessionTypesAndNames = [
  ['ongoing', 'Individual sessions'],
  ['initial', 'Initial session'],
  ['couples', 'Couples sessions'],
  ['family', 'Family sessions'],
  ['group', 'Group sessions'],
  ['med_management_and_therapy', 'Medication management and therapy sessions'],
  ['med_management', 'Medication management sessions'],
];

export const sessionFeeColumns = [
  {
    header: 'Session Type',
    key: 'name',
    type: 'input',
    placeholder: 'Session Type',
  },
  {
    header: 'Session Fee',
    key: 'fee',
    type: 'input',
    inputType: 'number',
  },
  {
    header: 'Session Length',
    key: 'length',
    type: 'input',
    inputType: 'number',
  },
];

export const sessionEditFormName = 'sessions';

export const tabOptions = {
  Yes: 1,
  No: 0,
};

export const oonBillingInfo = [
  {
    field: 'offer_oon_reimbursement_superbills',
    bold: 'Super billing',
    text: 'Give clients a claim which they will submit to the insurance company for reimbursement',
  },
  {
    field: 'offer_oon_reimbursement_courtesy',
    bold: 'Courtesy billing',
    text: 'Submit the claim directly to the insurance company for the client, client is reimbursed',
  },
  {
    field: 'offer_oon_reimbursement_direct',
    bold: 'Direct billing',
    text: 'Submit the claim directly to the insurance company for the client, therapist is reimbursed',
  },
];

export const paymentInfo = [
  {
    name: 'Cash',
    key: 'cash',
  },
  {
    name: 'Check',
    key: 'check',
  },
  {
    name: 'Credit Card',
    key: 'credit_card',
  },
  {
    name: 'Debit Card',
    key: 'debit_card',
  },
  {
    name: 'PayPal',
    key: 'paypal',
  },
  {
    name: 'Venmo',
    key: 'venmo',
  },
  {
    name: 'Zelle',
    key: 'zelle',
  },
  {
    name: 'IvyPay',
    key: 'ivypay',
  },
  {
    name: 'Nirvana Health',
    key: 'nirvana_health',
  },
];

export const frequencyInfo = [
  {
    name: 'Weekly',
    key: 'weekly',
  },
  {
    name: 'Monthly',
    key: 'monthly',
  },
  {
    name: 'Every other week',
    key: 'every_other_week',
  },
  {
    name: 'Every other month',
    key: 'every_other_month',
  },
  {
    name: 'Multiple times a week',
    key: 'multiple_times_per_week',
  },
  {
    name: 'As needed',
    key: 'any_frequency',
  },
];
