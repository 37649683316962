import React from 'react';
import { useDispatch } from 'react-redux';
import { actions as mainActions } from '../../sagaSlice';
import _ from 'lodash';
import dayjs from 'dayjs';
import { formatInvoiceDiscount, formatInvoiceBalanceApplied } from './helpers';
import { displayMoneyString } from '../../../../utils/money';

function formatLineName(description) {
  description = description.replace(/\d × /g, '');
  description = description.replace(/\(at .*\)/g, '');

  return description;
}

function formatLineItems(lines) {
  return lines
    .filter(
      (l) => !l.description.includes('Trial period') && !l.description.includes(['Unused time'])
    )
    .map((l) => (
      <span>
        {formatLineName(l.description)} (${l.price.unit_amount / 100})
      </span>
    ));
}

function formatMoney(cents) {
  return displayMoneyString(cents / 100);
}

export default ({ invoices, charges }) => {
  const dispatch = useDispatch();

  const paidInvoices = invoices
    .filter((invoice) => _.get(invoice, 'status_transitions.paid_at'))
    .filter((invoice) => formatLineItems(invoice.lines.data).length)
    .map((invoice) => ({
      date: new Date(invoice.status_transitions.paid_at * 1000),
      component: (
        <>
          <span className='m-r-sm first'>
            {dayjs(invoice.status_transitions.paid_at * 1000).format('MMMM D, YYYY')}
          </span>
          <span className='m-r-sm'>{formatMoney(invoice.amount_due)}</span>
          <span className='m-r-sm flex column m-b-sm'>
            {formatLineItems(invoice.lines.data)}
            <span>{formatInvoiceDiscount(invoice)}</span>
            <span>{formatInvoiceBalanceApplied(invoice)}</span>
          </span>
          <span className='m-b-sm m-r-sm last'>
            <button
              className='primary md'
              onClick={() => {
                dispatch(
                  mainActions.getHostedInvoiceLinkFromStripe({
                    stripe_customer_id: invoice.customer_id,
                    stripe_invoice_id: invoice.id,
                  })
                );
              }}
            >
              View
            </button>
          </span>
        </>
      ),
    }));

  // We want to show the original charge and the refund, so duplicate refunded charges and make the original one read not refunded.
  const separateRefunds = charges
    .filter((c) => _.get(c, 'refunds.data', []).length)
    .map((c) => ({
      ...c,
      refunded: true,
      created: _.get(c, 'refunds.data[0].created') * 1000,
    }));

  charges = charges.map((c) => ({
    ...c,
    refunded: false,
  }));

  const chargesToShow = charges
    .concat(separateRefunds)
    .filter((c) => c.refunded || !c.invoice_id)
    .map((charge) => ({
      //use refund creation date instead of charge creation date if refund
      date: new Date(charge.created),
      component: (
        <>
          <span className='m-r-sm first'>{dayjs(charge.created).format('MMMM D, YYYY')}</span>
          <span className='m-r-sm'>
            {charge.refunded ? `-$${charge.amount_refunded / 100}` : `$${charge.amount / 100}`}
          </span>
          <span className='m-r-sm flex column m-b-sm'>
            {charge.refunded
              ? charge.description
                ? `Refund for ${charge.description}`
                : `Refund`
              : charge.description || 'One-time charge'}
          </span>
          <span className='m-b-sm m-r-sm last'>
            {charge.receipt_url && (
              <button
                className='primary md'
                onClick={() => {
                  dispatch(
                    mainActions.getHostedChargeInvoiceLinkFromStripe({
                      stripe_customer_id: charge.customer_id,
                      stripe_charge_id: charge.id,
                    })
                  );
                }}
              >
                View
              </button>
            )}
          </span>
        </>
      ),
    }));

  const orderedItems = _.orderBy(paidInvoices.concat(chargesToShow), 'date', 'desc');

  return (
    <div className='invoice-section'>
      <h5 className='m-r-sm'>Payment Date</h5>
      <h5 className='m-r-sm'>Amount Paid</h5>
      <h5 className='m-r-sm'>Description</h5>
      <h5 className='m-r-sm'>Invoice</h5>
      {orderedItems.map((o) => o.component)}
    </div>
  );
};
