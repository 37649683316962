export const educationEditFormName = 'education';
export const employmentEditFormName = 'employment';
export const curYear = new Date().getUTCFullYear();
export const yearOptions = ['', 'Present'].concat([...Array(70).keys()].map((x) => curYear - x));
export const yearOptionsWithoutPresent = [yearOptions[0]].concat(
  yearOptions.slice(2, yearOptions.length)
);
export const employmentColumns = [
  {
    header: 'Employer',
    key: 'employer',
    type: 'input',
    placeholder: 'Employer',
  },
  {
    header: 'Position',
    key: 'position',
    type: 'input',
    placeholder: 'Position',
  },
  {
    header: 'Start Year',
    key: 'when.start',
    type: 'select',
    options: yearOptionsWithoutPresent,
    placeholder: 'Start Year',
  },
  {
    header: 'End Year',
    key: 'when.end',
    type: 'select',
    options: yearOptions,
    placeholder: 'End Year',
  },
];

export const educationColumns = [
  {
    header: 'Institution',
    key: 'institution',
    type: 'input',
    placeholder: 'Institution',
  },
  {
    header: 'Degree or Certificate',
    key: 'degree',
    type: 'input',
    placeholder: 'Degree or Certificate',
  },
  {
    header: 'Completion Year',
    key: 'completion',
    type: 'select',
    options: yearOptionsWithoutPresent,
    placeholder: 'Completion Year',
  },
];
