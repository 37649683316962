import AuthRoutes, { AuthRoute } from '@/auth/routes';
import StaticRoutes, { StaticPageRoute } from '@/static-pages/routes';
import MainRoutes, { MainRoute } from '@/main/routes';
import DeveloperToolRoutes, { DeveloperToolRoute } from '@/developer-tools/routes';

const routes: (AuthRoute | StaticPageRoute | MainRoute | DeveloperToolRoute)[] = [
  ...AuthRoutes,
  ...StaticRoutes,
  ...MainRoutes,
  ...DeveloperToolRoutes,
];

export default routes;
