const colorClasses = ['primary', 'secondary', 'tertiary', 'quaternary', 'light', 'dark'];

const stateClasses = [
  'success',
  'error',
  'warning',
  'info',
  'disabled',
  'active',
  'show',
  'hide',
  'hidden',
];

const widthClasses = ['full', 'half', 'third', 'quarter', 'eight'];

const sizeClasses = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

const modificationClasses = ['hollow', 'pill'];

/**
 * Filter props from an array of whitelisted keys
 * @param {array} whitelist array of keys to extract
 * @param {object} props component props
 * @param {object} extend object to extend
 */
const filterProps = (whitelist, props, extend = {}) =>
  whitelist
    .filter((key) => props[key] !== undefined)
    .reduce((p, key) => {
      p[key] = props[key];

      return p;
    }, extend);

/**
 * Extracts generic HTML props and creates an object
 * with props to pass into HTML element.
 * Only passes defined and non-null values.
 * @param {*} props
 *
 * @example
 *
 * const buttonProps = genericProps(props);
 *
 * <button {...buttonProps}>Hello World</button>
 */
export const genericProps = (props) => {
  const keys = [
    'id',
    'style',
    'placeholder',
    'value',
    'role',
    'title',
    'alt',
    'tabIndex',
    'onClick',
    'onKeyUp',
    'onKeyDown',
    'onKeyPress',
  ];

  return filterProps(keys, props);
};

/**
 * Extracts library related class names from props and constructs
 * a string to be used as className attribute. It allows the following:
 *
 * <Button success disabled>
 * <Button color="primary" size="sm" hollow>
 * <Tag tertiary sm>
 * <Tag color="success" size="sm">
 * <Avatar border="success">
 * <Avatar text="success">
 * <Avatar bg="success">
 *
 * @param {*} props
 */
export const createComponentClasses = (props) => {
  const { className, color, state, size, border, text, bg } = props;
  const propKeys = Object.keys(props);

  // Include all config classes such as
  // primary, success, sm, disabled
  const availableClasses = colorClasses.concat(
    stateClasses,
    sizeClasses,
    widthClasses,
    modificationClasses
  );

  // Include color="", state="", size="" attributes
  if (color) propKeys.push(color);
  if (state) propKeys.push(state);
  if (size) propKeys.push(size);

  const classes = availableClasses.filter((k) => propKeys.includes(k));

  if (text) classes.push(`text-${text}`);
  if (border) classes.push(`border-${border}`);
  if (bg) classes.push(`bg-${bg}`);

  return `${className || ''} ${classes.join(' ')}`.trim();
};

export default {
  genericProps,
  createComponentClasses,
};
