import { put } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'therapyGroups',
  initialState: {
    isFetching: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    createTherapyGroup: loadingReducer,
    createTherapyGroupSuccess: notLoadingReducer,
    createTherapyGroupFailure: failReducer,

    editTherapyGroup: loadingReducer,
    editTherapyGroupSuccess: notLoadingReducer,
    editTherapyGroupFailure: failReducer,

    deleteTherapyGroup: loadingReducer,
    deleteTherapyGroupSuccess: notLoadingReducer,
    deleteTherapyGroupFailure: failReducer,
  },

  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.createTherapyGroup]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.post(
          `portal/provider/${id}/therapy-group`,
          payload,
          A.createTherapyGroupSuccess,
          A.createTherapyGroupFailure
        );
      },

      [A.editTherapyGroup]: function* ({ payload }) {
        const id = payload.provider_id;
        const { therapy_group_id } = payload;
        delete payload.therapy_group_id;
        delete payload.provider_id;
        yield sagaApi.put(
          `portal/provider/${id}/therapy-group/${therapy_group_id}`,
          payload,
          A.editTherapyGroupSuccess,
          A.editTherapyGroupFailure
        );
      },

      [A.deleteTherapyGroup]: function* ({ payload }) {
        const id = payload.provider_id;
        const { therapy_group_id } = payload;
        delete payload.therapy_group_id;
        delete payload.provider_id;
        yield sagaApi.delete(
          `portal/provider/${id}/therapy-group/${therapy_group_id}`,
          {},
          A.deleteTherapyGroupSuccess,
          A.deleteTherapyGroupFailure
        );
      },

      [A.createTherapyGroupSuccess]: function* () {
        yield put({ type: 'main/getMe' });
      },

      [A.editTherapyGroupSuccess]: function* () {
        yield put({ type: 'main/getMe' });
      },

      [A.deleteTherapyGroupSuccess]: function* () {
        yield put({ type: 'main/getMe' });
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
