import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { api } from '#/apis';
import { Form, Field } from 'react-final-form';
import Loader from '+/forms/SectionLoader';
import SubmitButton from '+/forms/SubmitButton';

export const VettingQuiz = ({ provider }) => {
  const vettingStatus = _.get(provider, 'custom_content.vetting_status');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [quiz, setQuiz] = useState([]);

  useEffect(() => {
    if (vettingStatus !== 'pre-vetting') {
      return;
    }

    setLoading(true);

    api.get(`portal/vetting/quiz/${provider.id}`).then(
      (r) => {
        setLoading(false);
        setQuiz(r.data);
      },
      () => setLoading(false)
    );
  }, [vettingStatus]);

  if (vettingStatus !== 'pre-vetting') {
    return (
      <div>
        <h1>Clinical Vetting</h1>
        <p>You've already been vetted!</p>
      </div>
    );
  }

  if (results) {
    return (
      <div>
        <h1>Clinical Vetting</h1>
        {results.passed && (
          <>
            <h3>You passed!</h3>
            <p>
              Thank you so much for taking the time to share more about your practice! Based on your
              responses, the “Zencare Vetted” badge will be added to your profile. Thank you for
              providing great care to clients!
            </p>
          </>
        )}
        {!results.passed && (
          <p>
            Thanks so much for taking the time to share more about your practice; unfortunately,
            based on your responses, the “Zencare Vetted” badge will not be added to your profile at
            this time. Answers to this quiz are not publicly displayed, and while we’re not able to
            provide specific feedback at this time, we greatly appreciate your time and
            consideration.
          </p>
        )}
      </div>
    );
  }

  if (results && !results.passed) {
    return <div>You failed!</div>;
  }

  if (loading) {
    return (
      <div>
        <h1>Clinical Vetting</h1>
        <Loader />
      </div>
    );
  }

  const submitQuiz = (values) => {
    const payload = {};

    for (const qid of Object.keys(values)) {
      const question = quiz
        .map((q, i) => ({
          ...q,
          id: `q${i}`,
        }))
        .find((x) => x.id === qid).question;
      payload[question] = values[qid];
    }

    setLoading(true);
    api.post(`portal/vetting/quiz/${provider.id}`, payload).then(
      (r) => {
        setLoading(false);
        setResults(r.data);
      },
      () => setLoading(false)
    );
  };

  return (
    <div>
      <h1>Clinical Vetting</h1>
      <Form
        onSubmit={submitQuiz}
        validate={(v) => {
          const errors = {};

          for (const k in quiz) {
            if (!v[`q${k}`]) {
              errors[`q${k}`] = 'Question required';
            }
          }

          return errors;
        }}
        render={({ handleSubmit, pristine, valid }) => (
          <>
            <div>
              <p className='instruction m-b-md'>
                The Zencare-vetted badge signals to clients that they’ll be in trusted care. Take
                our 5min clinical standards assessment to earn a vetted badge for adherence to best
                practices.
              </p>
              {quiz.map((questionAndChoices, i) => {
                const question = questionAndChoices.question;
                const questionParts = question.split(/\(|\)/i);
                const q = questionParts[0];
                let desc;

                if (questionParts.length > 1) {
                  desc = `${questionParts[1]}`;
                }

                return (
                  <div className='box flex column m-b-md'>
                    <h3>{q}</h3>
                    {desc && <p className='instruction m-b-md'>{desc}</p>}

                    {questionAndChoices.choices.map((c, j) => {
                      const id = `q${i}c${j}`;

                      return (
                        <div className='flex align-baseline'>
                          <Field name={`q${i}`} component='input' type='radio' value={c} id={id} />
                          <label style={{ color: '#222' }} className='m-b-sm' htmlFor={id}>
                            {c}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <SubmitButton pristine={pristine} valid={valid} handleSubmit={handleSubmit} />
            </div>
          </>
        )}
      ></Form>
    </div>
  );
};

const mapStateToProps = ({ main, auth }) => ({
  provider: main.provider,
});

const actions = {};

export default connect(mapStateToProps, actions)(VettingQuiz);
