/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EditBox from '+/EditBox';
import EditHealthInsurances from './EditHealthInsurances';

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

export const HealthInsurances = ({ provider }) => {
  const [editing, setEditing] = useState(false);

  const renderReadOnlyInsurances = _.get(provider, 'insurances', [])
    .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1))
    .map((insurance) => (
      <li key={insurance.id}>
        <span className='location-name'>{insurance.name}</span>
      </li>
    ));

  const readOnlyInsurances = (
    <>
      {renderReadOnlyInsurances.length === 0 && (
        <div className='announcement quaternary'>
          You do not have any in-network insurances listed.
        </div>
      )}
      {renderReadOnlyInsurances}
    </>
  );

  const editModeUI = (
    <>
      <EditHealthInsurances setEditing={setEditing} />
    </>
  );

  return (
    <div className='health-insurances'>
      <EditBox
        title='Health Insurances'
        editable={true}
        editing={editing}
        setEditing={setEditing}
        innerStatic={readOnlyInsurances}
        innerEditing={editModeUI}
      />
    </div>
  );
};

export default connect(mapStateToProps, {})(HealthInsurances);
