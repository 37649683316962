import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import ReviewPlanBox from './ReviewPlanBox';
import AccountDetailsBox from './AccountDetailsBox';
import { products } from '../payment/seat-based/constants';
import StandaloneSummaryBox from './StandaloneSummaryBox';
import { getQueryAsObject } from '~/utils/queryParams';
import useStripeCardElement from '+/hooks/useStripeCardElement';
import { CardElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './sagaSlice';
import ReCAPTCHA from 'react-google-recaptcha';

interface FormValues {
  email: string;
  first_name: string;
  last_name: string;
  count: number;
  tier: 'professional' | 'premium' | string;
  'terms-of-service'?: boolean;
  coupon: string | null;
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_postal_code: string;
}

function StandaloneCheckout() {
  // @ts-ignore-next-line
  const recaptchaRef: MutableRefObject<ReCAPTCHA> = React.useRef();
  const [recaptchaTokenWasCheckedBefore, setRecaptchaTokenWasCheckedBefore] = useState(false);

  const dispatch = useDispatch();
  const { email, first_name, last_name } = getQueryAsObject(window.location.search);
  const { stripe, elements } = useStripeCardElement();
  const { standaloneZencareAccountId, isLoading, practiceManagementSubdomainName } = useSelector<
    State,
    PracticeManagementState
  >((state) => state.practiceManagement);

  const initialValues: FormValues = {
    email: email,
    first_name,
    last_name,
    count: 1,
    tier: products.PRACTICE_MANAGEMENT_PRO_STANDALONE.TIER,
    coupon: null,
    address_line1: '',
    address_line2: '',
    address_city: '',
    address_state: '',
    address_postal_code: '',
  };

  const onSubmit = async (values: FormValues) => {
    // We might already have a recaptcha token if we already tried to submit and got server validation error.
    // If so, we need to call reset before calling recaptchaRef.current.executeAsync
    if (recaptchaTokenWasCheckedBefore) {
      recaptchaRef.current.reset();
    }

    recaptchaRef.current.executeAsync().then(async (token: string) => {
      setRecaptchaTokenWasCheckedBefore(true);

      if (!standaloneZencareAccountId) {
        return;
      }
      const { first_name, last_name, email, count, tier, coupon } = values;

      // @ts-expect-error
      dispatch(actions.setIsLoading(true));
      if (elements && CardElement && stripe) {
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
          try {
            const { source, error } = await stripe.createSource(cardElement, {
              type: 'card',
              currency: 'usd',
            });

            if (error) {
              throw new Error(error.message);
            }

            const checkoutPayload: ZpmSignupPayPayload = {
              params: {
                account_id: standaloneZencareAccountId,
              },
              apiPayload: {
                first_name,
                last_name,
                email,
                quantity: count,
                zpm_tier: tier,
                source,
                coupon,
                token,
                address_line1: values.address_line1,
                address_line2: values.address_line2,
                address_city: values.address_city,
                address_state: values.address_state,
                address_postal_code: values.address_postal_code,
              },
            };

            // @ts-expect-error
            dispatch(actions.zpmSignupPay(checkoutPayload));
          } catch (error) {
            // @ts-expect-error
            dispatch(actions.setIsLoading(false));
            if (error instanceof Error) {
              return { source: error.message };
            } else {
              return { source: 'Please double-check your credit card information' };
            }
          }
        }
      }
    });
  };

  type FormErrors = {
    first_name?: string;
    last_name?: string;
    email?: string;
    'terms-of-service'?: string;
    address_line1?: string;
    address_line2?: string;
    address_city?: string;
    address_state?: string;
    address_postal_code?: string;
  };

  const validateForm = (values: FormValues) => {
    const errors: FormErrors = {};

    // Validate first name
    if (!values.first_name) {
      errors.first_name = 'First name required.';
    }

    // Validate last name
    if (!values.last_name) {
      errors.last_name = 'Last name required.';
    }

    // Validate email
    if (!values.email) {
      errors.email = 'Email required.';
    }

    // Validate terms of service
    if (!values['terms-of-service']) {
      errors['terms-of-service'] = 'Please agree to terms.';
    }

    // Validate address line 1
    if (!values.address_line1) {
      errors.address_line1 = 'Address line 1 required.';
    }

    // Validate address city
    if (!values.address_city) {
      errors.address_city = 'City required.';
    }

    // Validate address state
    if (!values.address_state) {
      errors.address_state = 'State required.';
    }

    // Validate address postal code
    if (!values.address_postal_code) {
      errors.address_postal_code = 'Postal code required.';
    }

    return errors;
  };

  const handleSkip = (e: $TSFixMe) => {
    e.preventDefault();
    if (!practiceManagementSubdomainName) return;
    return (window.location.href = `https://${practiceManagementSubdomainName}.${process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME}/`);
  };

  return (
    <div className='standalone-checkout box'>
      <h1>Zencare Practice Management</h1>
      <p>Streamline your private practice</p>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validateForm}
        render={({ handleSubmit }) => (
          <form className='standalone-checkout-form'>
            <div>
              <h2>Review your Plan</h2>
              <ReviewPlanBox />
            </div>
            <div>
              <h2>Summary</h2>
              <StandaloneSummaryBox />
            </div>
            <div>
              <h2>Account Details</h2>
              <AccountDetailsBox />
              {process.env.ZENCARE_GOOGLE_CAPTCHA_KEY && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size='invisible'
                  sitekey={process.env.ZENCARE_GOOGLE_CAPTCHA_KEY}
                />
              )}
              <div className='flex justify-center align-center'>
                {practiceManagementSubdomainName && (
                  <button
                    onClick={handleSkip}
                    className='hollow primary pill md m-t-md w-100 m-r-sm'
                  >
                    Skip
                  </button>
                )}
                <button onClick={handleSubmit} className='primary pill md m-t-md w-100'>
                  {isLoading ? 'Processing...' : 'Start Membership'}
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default StandaloneCheckout;
