import _ from 'lodash';

const checklist = [
  {
    cardName: 'Add your licenses',
    cardDescription:
      'It looks like you have practice locations in states where you haven’t indicated a license yet! These locations are not displayed on your profile until a license for that state is verified. Add a license to list your practice in those states!',
    cardLink: '/profile/general-information',
    cardLinkText: 'Add license',
    // Display if there is any location that isn't displayed and for which no license
    providerDisplayFn: (provider) =>
      ['active', 'reviewing'].includes(provider.status) &&
      provider.all_licenses &&
      provider.locations &&
      provider.locations.find(
        (loc) =>
          !loc.should_display && !provider.all_licenses.find((lic) => lic.state === loc.state)
      ),
  },
  {
    cardName: 'Work & education',
    cardDescription: "Share the work experience you've gained!",
    cardLink: '/profile/education-employment',
    cardLinkText: 'Update',
    providerDisplayFn: (provider) =>
      provider.type === 'individual' &&
      provider.custom_content &&
      provider.custom_content.employment_v2 &&
      provider.custom_content.employment_v2.length < 1,
  },
  {
    cardName: 'Office hours',
    cardDescription: 'Set your office hours so potential clients know when you are available',
    cardLink: '/profile/availability-locations#practice-locations',
    cardLinkText: 'Update',
    providerDisplayFn: (provider) => {
      if (!provider.locations) {
        return true;
      }

      const locationsWithNonDefaultHours = provider.locations.filter(
        (x) => x.hours.filter((y) => y !== 'Please inquire about hours').length > 0
      );

      return locationsWithNonDefaultHours.length === 0;
    },
  },
  {
    cardName: 'Professional statement',
    cardDescription: 'Write your professional statement!',
    cardLink: '/profile/message-statement',
    cardLinkText: 'Update',
    providerDisplayFn: (provider) =>
      provider.type === 'individual' &&
      (!provider.professional_statement || provider.professional_statement.length < 1),
  },
  {
    cardName: 'Practice introduction',
    cardDescription: 'Write your practice introduction!',
    cardLink: '/profile/message-statement',
    cardLinkText: 'Update',
    providerDisplayFn: (provider) =>
      provider.type === 'practice' &&
      (!provider.professional_statement || provider.professional_statement.length < 1),
  },
  {
    cardName: 'Message to clients',
    cardDescription: 'Share a brief, hopeful message to clients!',
    cardLink: '/profile/message-statement',
    cardLinkText: 'Update',
    providerDisplayFn: (provider) =>
      provider.type === 'individual' &&
      (!provider.message_to_clients || provider.message_to_clients.length < 1),
  },
  {
    cardName: 'Get Vetted',
    cardDescription:
      "The Zencare-vetted badge signals to clients that they'll be in trusted care. Take our 5min clinical standards assessment to earn a vetted badge for adherence to best practices.",
    cardLink: '/vetting/quiz',
    cardLinkText: 'Get Vetted',
    providerDisplayFn: (provider) =>
      provider.status === 'active' &&
      _.get(provider, 'custom_content.vetting_status') === 'pre-vetting',
  },
  {
    cardName: 'Show Phone Consult Calendar',
    cardDescription:
      'Show your phone consultation calendar to enable clients to book phone consultations!',
    cardLink: '/calendar',
    cardLinkText: 'Show Calendar',
    providerDisplayFn: (provider, actionItemData) =>
      actionItemData &&
      !actionItemData.hasCalendarOpenEvent &&
      provider.calendar &&
      provider.calendar.length &&
      provider.calendar[0].open === 0,
  },
  {
    cardName: 'Add Phone Consult Availability',
    cardDescription:
      "You don't have any available slots coming up in the next month. Add times when you are available for calls so clients can book phone consultations with you!",
    cardLink: '/calendar',
    cardLinkText: 'Add Availability',
    providerDisplayFn: (provider, actionItemData) =>
      provider.status === 'active' &&
      actionItemData &&
      !actionItemData.hasUpcomingAvailabilityEvent &&
      provider.calendar &&
      provider.calendar.length &&
      provider.calendar[0].open === 1,
  },
];

export default checklist;
