import { useState, useEffect, useRef } from 'react';
import SortableJS from 'sortablejs';

/*
 * Given the name of the Final Form field which should be a sortable array,
 * set up refs and state so that sortable can change order of the field.
 *
 * The component using this hook needs to:
 *   1. Pass the resort function to the form's mutators prop
 *   2. Inside the form's render function, assign mutatorRef.current to the form.mutators
 *   3. Add ref={listRef} to the container element of the sortable elements.
 *
 */
export default function useSortable(arrFieldName) {
  const listRef = useRef(null);
  const [__, setSortable] = useState(null);
  const mutatorRef = useRef(null);

  function resort([oldI, newI], state, { changeValue }) {
    changeValue(state, arrFieldName, (oldVal) => {
      const arr = oldVal.map((v, i) => ({
        oldIdx: i,
        ...v,
      }));
      const removedOld = arr.splice(oldI, 1);
      arr.splice(newI, 0, removedOld[0]);

      return arr;
    });
  }

  useEffect(() => {
    if (listRef.current) {
      setSortable(
        SortableJS.create(listRef.current, {
          swapThreshold: 0.66,
          swap: true,
          swapClass: 'highlight',
          animation: 150,
          handle: '.handle',
          onEnd: (evt) => {
            if (!mutatorRef.current.resort) {
              return;
            }
            mutatorRef.current.resort(evt.oldIndex, evt.newIndex);
          },
        })
      );
    }
  }, [listRef, mutatorRef]);

  return [listRef, mutatorRef, resort];
}
