import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button } from '@zencare/ui';
import { connect } from 'react-redux';
import { actions as mainActions } from '../../../main/sagaSlice';
import AddableForm from '../AddableForm';
import _ from 'lodash';
import EditBox from '+/EditBox';
import {
  educationEditFormName,
  employmentEditFormName,
  curYear,
  employmentColumns,
  educationColumns,
} from './constants';

const sortBy = (el) =>
  _.orderBy(
    el,
    (e) => {
      const endDate = _.get(e, 'when.end') || _.get(e, 'completion');

      if (endDate === 'Present') {
        return 9001;
      }

      return endDate;
    },
    'desc'
  );

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {
  updateProvider: mainActions.updateProvider,
};

export const EducationEmployment = ({ provider, updateProvider }) => {
  const [editingEmployment, setEditingEmployment] = useState(false);
  const [editingYear, setEditingYear] = useState(false);
  const [editingEducation, setEditingEducation] = useState(false);

  const updateGraduationYear = (field) => {
    updateProvider({
      id: provider.id,
      custom_content: {
        year_earned_grad_degree: parseInt(field.year),
      },
    });

    setEditingYear(false);
  };

  const updateEducation = (education) => {
    updateProvider({
      id: provider.id,
      custom_content: {
        education_v2: education,
      },
    });

    setEditingEducation(false);
  };

  const updateEmployment = (employment) => {
    updateProvider({
      id: provider.id,
      custom_content: {
        employment_v2: employment,
      },
    });

    setEditingEmployment(false);
  };

  const education = sortBy(_.get(provider, 'custom_content.education_v2', [])).map((x) => ({
    ...x,
    deletable: true,
  }));
  const employment = sortBy(_.get(provider, 'custom_content.employment_v2', [])).map((x) => ({
    ...x,
    deletable: true,
  }));
  const gradYear = provider.custom_content.year_earned_grad_degree;

  const yearInnerEditing = (
    <Form
      onSubmit={updateGraduationYear}
      initialValues={{
        year: gradYear,
      }}
      //Don't re-render
      initialValuesEqual={() => true}
      render={({ handleSubmit, values }) => (
        <>
          <span>Year earned graduate degree: </span>
          <Field
            name='year'
            component='select'
            required={true}
            type='select'
            initialValue={values.year}
            label='Year earned graduate degree:'
          >
            {[''].concat([...Array(70).keys()].map((x) => curYear - x)).map((o) => (
              <option value={o}>{o}</option>
            ))}
            <i className='fas fa-caret-down'></i>
          </Field>
          <div className='actions'>
            <Button className='primary' onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    />
  );

  const yearInnerStatic = (
    <>
      {_.isNil(gradYear) && (
        <div className='announcement quaternary'>
          <p>Indicate your graduation year!</p>
        </div>
      )}
      <p>Year earned graduate degree: {_.isNil(gradYear) ? 'N/A' : gradYear}</p>
      <p>
        Years of experience:{' '}
        {_.isNil(gradYear) ? 'N/A' : new Date().getUTCFullYear() - gradYear || '<1'}
      </p>
    </>
  );

  const validate = (columns, name, formValues) => {
    const values = formValues[name];
    const errors = {};
    values.forEach((v, i) => {
      columns.forEach((c) => {
        if (c.key === 'position') return;
        if (!_.get(v, c.key)) {
          _.set(errors, `${name}[${i}].${c.key}`, `${c.header} required`);
        }
      });
    });

    if (!values || values.length < 1) {
      errors.values = 'You must have at least one line item.';
    }

    return errors;
  };

  const employmentInnerEditing = (
    <AddableForm
      name={employmentEditFormName}
      onEdit={updateEmployment}
      validate={validate.bind(null, employmentColumns, employmentEditFormName)}
      dataList={employment}
      columns={employmentColumns}
      defaultNewItem={{
        employer: '',
        position: '',
        when: {
          start: '',
          end: '',
        },
        deletable: true,
      }}
      addText='Work Experience'
    />
  );

  const employmentInnerStatic = (
    <div className='employment-display'>
      <h5 className='m-y-xs'>Employer</h5>
      <h5 className='m-y-xs'>Position</h5>
      <h5 className='m-y-xs'>Years</h5>
      {employment.map((c) => (
        <>
          <h6>{c.employer}</h6>
          <div>{c.position}</div>
          <div>
            {c.when.start}
            {c.when.start && c.when.end ? '-' : ''}
            {c.when.end}
          </div>
        </>
      ))}
    </div>
  );

  const educationInnerEditing = (
    <AddableForm
      name={educationEditFormName}
      onEdit={updateEducation}
      dataList={education}
      columns={educationColumns}
      validate={validate.bind(null, educationColumns, educationEditFormName)}
      defaultNewItem={{
        institution: '',
        degree: '',
        completion: '',
        deletable: true,
      }}
      addText='Education or Training'
    />
  );

  const educationInnerStatic = (
    <div className='employment-display'>
      <h5 className='m-y-xs'>Institution</h5>
      <h5 className='m-y-xs'>Degree or Certificate</h5>
      <h5 className='m-y-xs'>Completion Year</h5>
      {education.map((c) => (
        <>
          <h6> {c.institution}</h6>
          <div> {c.degree} </div>
          <div> {c.completion} </div>
        </>
      ))}
    </div>
  );

  return (
    <div>
      <h1 className='m-b-md'>Work & Education</h1>
      <EditBox
        title='Years of Experience'
        editable={true}
        editing={editingYear}
        setEditing={setEditingYear}
        innerEditing={yearInnerEditing}
        innerStatic={yearInnerStatic}
      />
      <EditBox
        title='Work Experience'
        editable={true}
        editing={editingEmployment}
        setEditing={setEditingEmployment}
        innerEditing={employmentInnerEditing}
        innerStatic={employmentInnerStatic}
      />
      <EditBox
        title='Education & Training'
        editable={true}
        editing={editingEducation}
        setEditing={setEditingEducation}
        innerEditing={educationInnerEditing}
        innerStatic={educationInnerStatic}
      />
    </div>
  );
};

export default connect(mapStateToProps, actions)(EducationEmployment);
