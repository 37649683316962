import React from 'react';
import _ from 'lodash';
import { Field } from 'react-final-form';
import FormCheckbox from '+/forms/FormCheckbox';

export const filterEntriesBySearch = (entryType, relevantInsurances, search) => {
  let result = relevantInsurances ? relevantInsurances[entryType] : [];
  if (result.length && search && search.length) {
    result = result.filter((x) => _.includes(x.tag.name.toUpperCase(), search.toUpperCase()));
  }

  return result;
};

export const checkForSubInsurancesRequired = (
  values,
  validationErrorParagraphs,
  insuranceEntries
) => {
  const nowValueIndexes = Object.keys(values);
  nowValueIndexes
    .filter((x) => _.startsWith(x, 'tag-id-'))
    .forEach((newValueIndex) => {
      const tagId = parseInt(newValueIndex.replace('tag-id-', ''));
      // Only check for requires child selection if this tag is currently checked
      if (values[`tag-id-${tagId}`] === true) {
        // See if the current insurance entries list is the one that has this tag.
        const insuranceEntry = insuranceEntries.find((x) => x.tag.id === tagId);
        if (insuranceEntry) {
          insuranceEntry.childTagGroups.forEach((childTagGroup) => {
            if (childTagGroup.requiresChildSelect) {
              let childTagSelectionFound = false;
              childTagGroup.childTags.forEach((tag) => {
                if (
                  nowValueIndexes.includes(`tag-id-${tag.id}`) &&
                  values[`tag-id-${tag.id}`] === true
                ) {
                  childTagSelectionFound = true;
                }
              });
              if (!childTagSelectionFound) {
                validationErrorParagraphs.push(
                  <p className='text-error'>{childTagGroup.description}</p>
                );
              }
            }
          });
        }
      }
    });
};

export const renderCheckboxHierarchy = (values, entries) => {
  if (!entries.length) {
    return null;
  }

  return (
    <>
      {entries.map((entry) => {
        const allChildInsurances = [];

        if (values[`tag-id-${entry.tag.id}`]) {
          entry.childTagGroups.forEach((childTagGroup) => {
            allChildInsurances.push(
              childTagGroup.childTags.length > 0 && (
                <div key={`parent-rule-${childTagGroup.parentRuleId}`}>
                  <p className='instruction'>{childTagGroup.description}</p>
                  <ul>
                    {childTagGroup.childTags.map((childTag) => (
                      <li className='sub-insurance' key={`child-tag-${childTag.id}`}>
                        <Field name={`tag-id-${childTag.id}`}>
                          {(props) => <FormCheckbox {...props} label={childTag.name} />}
                        </Field>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            );
          });
        }

        return (
          <div className='insurance' key={`common-insurance-tag-${entry.tag.id}`}>
            <Field name={`tag-id-${entry.tag.id}`}>
              {(props) => <FormCheckbox {...props} label={entry.tag.name} />}
            </Field>
            {allChildInsurances}
          </div>
        );
      })}
    </>
  );
};
