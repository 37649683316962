import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as paymentActions } from './sagaSlice';
import _ from 'lodash';
import { useStripe } from '@stripe/react-stripe-js';
import Loader from '+/forms/SectionLoader';
import useQueryParams from '+/hooks/useQueryParams';
import wrapStripeElements from '~/utils/wrapStripeElements';

export const PriceCheckout = ({
  accounts,
  checkoutId,
  getCheckoutId,
  isSignedIn,
  match,
  loading,
}) => {
  const stripe = useStripe();
  const priceId = _.get(match, 'params.priceId');
  const coupon = useQueryParams().get('coupon');
  const [res, setRes] = useState({});

  if (!priceId) {
    setRes({
      error: 'Invalid price id',
    });
  }

  // If not logged in, just get price checkout and return link and redirect
  // If logged in, check provider first, then,
  useEffect(() => {
    let customer_id;

    if (priceId && accounts && accounts.length && isSignedIn) {
      customer_id = _.get(accounts, '[0].stripe_customers[0].id');
    }

    getCheckoutId({
      priceId,
      customer_id,
      coupon: coupon ? coupon : undefined,
    });
  }, [accounts, coupon, getCheckoutId, isSignedIn, priceId]);

  useEffect(() => {
    async function redirect() {
      if (checkoutId) {
        const r = await stripe.redirectToCheckout({
          sessionId: checkoutId,
        });

        setRes(r);
      }
    }

    redirect();
  }, [checkoutId, stripe]);

  if (loading) {
    return (
      <div className='w-100 flex justify-center'>
        <Loader />
      </div>
    );
  }

  if (res.error) {
    return (
      <div>
        <h5>Sorry, there appears to be a problem with your checkout link.</h5>
        <h5>Error: {res.error} </h5>
      </div>
    );
  }

  return <></>;
};

export default connect(
  ({ main, payment, auth }) => ({
    accounts: (main.accounts || []).filter((a) => !a.error),
    checkoutId: payment.checkoutId,
    loading: payment.isLoading,
    isSignedIn: auth.isSignedIn,
  }),
  {
    getCheckoutId: paymentActions.getCheckoutId,
  }
)(wrapStripeElements(PriceCheckout));
