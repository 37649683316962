import React from 'react';
import ComboBox from './ComboBox';
import { times } from './TimeSelectorTimes';

const TimeSelector = ({ fieldProps, setValueFromDropdownSelection }) => (
  <ComboBox
    fieldProps={fieldProps}
    setValueFromDropdownSelection={setValueFromDropdownSelection}
    items={times}
  />
);

export default TimeSelector;
