/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions as mainActions } from '../../../main/sagaSlice';
import AddableForm from '../AddableForm';
import _ from 'lodash';
import { dispatch } from '~/store';
import { flashError } from '~/shared/flashes/redux';
import EditBox from '+/EditBox';
import { Field } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import { sessionTypesAndNames, sessionFeeColumns, sessionEditFormName } from './constants';
import { getPlainText, getHTMLFromText } from '../MessageStatement/helpers';
import { regex as TextAreaRegex } from '../MessageStatement/constants';
import PlainTextArea from '+/forms/PlainTextArea';
import HealthInsurances from './HealthInsurances';
import OtherSessionInfo from './OtherSessionInfo';
import { tabOptions } from './constants';
import InsuranceByState from './InsuranceByState';

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
  locations: Object.values(main.locations),
});

const actions = {
  updateProvider: mainActions.updateProvider,
  updateTaggingSearchability: mainActions.updateTaggingSearchability,
};

export const InsuranceFees = ({
  provider,
  updateProvider,
  updateTaggingSearchability,
  locations,
}) => {
  const [editingSessionFees, setEditingSessionFees] = useState(false);
  const [editingSessionNote, setEditingSessionNote] = useState(false);
  const states = _.uniq(locations.map((x) => x.state));

  /*
   * Remap the form fields to the individual custom content fields. session_fee_ongoing
   * is a special case which is actually a column on providers instead of part of cc.
   * Add the fields that are part of state vs. actual form values to the output as well.
   */
  const updateSessionFees = (sessionFees, allValues) => {
    const cc = sessionFees.reduce((acc, field) => {
      // We'll be overwriting the saved custom_sessions no matter what.
      if (!acc.custom_sessions) {
        acc.custom_sessions = [];
      }

      //ugh
      const key = sessionTypesAndNames.find(
        (n) => n[1] === field.name || n[1] === _.get(field, 'name.props.children[0]')
      );
      if (!key) {
        delete field.deletable;
        acc.custom_sessions.push({
          ...field,
          fee: parseInt(field.fee),
          length: String(field.length),
        });

        return acc;
      }

      acc[`session_fee_${key[0]}`] = _.isNil(field.fee) ? null : parseInt(field.fee);
      acc[`session_length_${key[0]}`] = _.isNil(field.length) ? null : String(field.length);

      return acc;
    }, {});

    let sfo = {};

    if (cc.session_fee_ongoing) {
      sfo = { session_fee_ongoing: cc.session_fee_ongoing };
      delete cc.session_fee_ongoing;
    }

    cc.display_sliding_scale_slots = allValues.sliding;
    if (allValues.sliding) {
      if ([null, undefined, ''].includes(allValues.sliding_min)) {
        return dispatch(flashError('To display a sliding scale, please indicate a lower limit'));
      }
      sfo.session_fee_min = parseInt(allValues.sliding_min);
    }

    updateProvider({
      id: provider.id,
      ...sfo,
      custom_content: {
        ...cc,
      },
    });

    setEditingSessionFees(false);
  };

  const cc = _.get(provider, 'custom_content', {});

  const sessionFees = sessionTypesAndNames
    .map(([t, n]) => ({
      name:
        t === 'ongoing' ? (
          <>
            Individual sessions
            <br />
            <small className='help' style={{ color: '#6a727b' }}>
              Required*
            </small>
          </>
        ) : (
          n
        ),
      fee:
        t === 'ongoing'
          ? provider.session_fee_ongoing
          : parseInt(cc[`session_fee_${t}`]) || undefined,
      length: parseInt(cc[`session_length_${t}`]) || undefined,
      nameReadOnly: true,
      required: t === 'ongoing',
    }))
    .concat(
      cc.custom_sessions.map((cs) => ({
        ...cs,
        length: parseInt(cs.length),
        deletable: true,
      }))
    );

  const validate = (values) => {
    const errors = {};
    values[sessionEditFormName].forEach((v, i) => {
      sessionFeeColumns.forEach((c) => {
        if ((v.required || v.deletable) && _.isNil(v[c.key])) {
          _.set(errors, `${sessionEditFormName}[${i}].${c.key}`, `${c.header} required`);
        }

        const nChar = provider.type === 'individual' ? 40 : 80;
        if (v.deletable && _.get(v[c.key], 'length', 0) > nChar) {
          _.set(
            errors,
            `${sessionEditFormName}[${i}].${c.key}`,
            `Please keep your session name < ${nChar} characters`
          );
        }
      });

      if (
        (_.isNil(v['length']) && !_.isNil(v['fee'])) ||
        (!_.isNil(v['length']) && _.isNil(v['fee']))
      ) {
        _.set(errors, `${sessionEditFormName}[${i}].fee`, 'Fee and length required');
      }
    });

    if (
      values.sliding &&
      parseInt(values.sliding_min) >= parseInt(values[sessionEditFormName][0].fee)
    ) {
      _.set(
        errors,
        'sliding_min',
        'Please indicate a sliding scale fee that is lower than your ongoing session fee!'
      );
    }

    if (values.sliding && ['', null, undefined].includes(values.sliding_min)) {
      _.set(errors, 'sliding_min', 'To display a sliding scale, please indicate a lower limit.');
    }

    return errors;
  };

  const slidingScaleFields = (values, errors) => (
    <>
      <div className='sliding-scale flex align-center justify-between m-b-sm'>
        <h6>Do you have openings for sliding scale spots?</h6>
        <Field name={'sliding'} type='checkbox'>
          {(props) => (
            <div className='switch'>
              <input
                type='checkbox'
                id='sliding-on'
                defaultChecked={values.sliding}
                onChange={props.input.onChange}
              />
              <label className='md' htmlFor='sliding-on'>
                <span className='toggle'></span>
              </label>
            </div>
          )}
        </Field>
      </div>

      {values.sliding && (
        <>
          <div
            className={`sliding-scale flex align-center justify-between m-b-${
              errors.sliding_min ? '0' : 'md'
            }`}
          >
            <h6>What is your sliding scale lowest limit?</h6>
            <div style={{ position: 'relative' }}>
              <Field
                name='sliding_min'
                component='input'
                type='number'
                initialValue={values['sliding_min']}
              ></Field>
              <span className='units'>$</span>
            </div>
          </div>
          <span
            className='w-100'
            style={{
              display: 'block',
              textAlign: 'right',
            }}
          >
            <ErrorField name='sliding_min' />
          </span>
        </>
      )}
    </>
  );

  const sessionFeesInnerEditing = (
    <>
      <AddableForm
        name={sessionEditFormName}
        onEdit={updateSessionFees}
        dataList={sessionFees}
        columns={sessionFeeColumns}
        defaultNewItem={{
          name: '',
          deletable: true,
        }}
        validate={validate}
        extraFields={slidingScaleFields}
        extraValues={{
          sliding: provider.custom_content.display_sliding_scale_slots || false,
          sliding_min: provider.custom_content.display_sliding_scale_slots
            ? provider.session_fee_min
            : provider.custom_content.session_fee_min_old || provider.session_fee_min,
        }}
        addText='Custom Session Type'
      />
    </>
  );

  const sessionFeesInnerStatic = (
    <>
      <div className='flex wrap align-center m-b-xs'>
        <h5 className='m-b-0 m-r-xs'>Sliding Scale Status:</h5>
        {cc.display_sliding_scale_slots ? (
          <>
            <span>
              {' '}
              <i className='fas fa-check-circle text-primary m-r-xs m-l-sx'></i>
              Sliding Scale Spots Open
            </span>
          </>
        ) : (
          <>
            <span>
              {' '}
              <i className='fas fa-times-circle text-secondary m-r-xs m-l-sx'></i>
              No Sliding Scale Spots Open
            </span>
          </>
        )}
      </div>
      {cc.display_sliding_scale_slots && (
        <div className='announcement m-b-md'>
          <h6 className='m-b-0'>
            Your profile will display in searches for session fees between: $
            {provider.session_fee_min} - ${provider.session_fee_ongoing} per session
          </h6>
        </div>
      )}
      <div className='employment-display'>
        <h5 className='m-y-xs'>Session Type</h5>
        <h5 className='m-y-xs'>Session Fee</h5>
        <h5 className='m-y-xs'>Session Length</h5>
        {sessionFees.map(
          (c) =>
            (!_.isNil(c.fee) || !_.isNil(c.length)) && (
              <>
                <h6>{c.name}</h6>
                <div>${c.fee}/session</div>
                <div>{c.length}min</div>
              </>
            )
        )}
      </div>
    </>
  );

  const toggleSearchForInsurance = (insurance, evt) =>
    updateTaggingSearchability({
      provider_id: provider.id,
      tag_id: insurance.id,
      enable: evt.currentTarget.value,
    });

  const editAcceptingInsurances = _.get(provider, 'insurances', [])
    .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1))
    .map((insurance, i) => (
      <div key={insurance.id} className='tab-container m-b-sm flex-center'>
        <span className='location-name'>{insurance.name}</span>
        <div className='tab-container-right'>
          <div className='tab-select'>
            {Object.entries(tabOptions).map(([key, val], j) => (
              <div key={i + j} className='container'>
                <input
                  type='radio'
                  component='input'
                  id={`${insurance.name.toLowerCase()}_${key.toLowerCase()}`}
                  value={val}
                  checked={insurance.search_enabled === val}
                  name={insurance.slug}
                  onChange={toggleSearchForInsurance.bind(null, insurance)}
                />
                <label
                  className='flex-center'
                  htmlFor={`${insurance.name.toLowerCase()}_${key.toLowerCase()}`}
                >
                  {key}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    ));

  return (
    <div className='insurance-fees'>
      <h1 className='m-b-md'>Insurance &amp; Fees</h1>
      <h2>Health Insurances</h2>
      {editAcceptingInsurances.length > 0 && (
        <div className='box m-b-sm'>
          <h3 className='m-b-0'>Open Slots by Insurance</h3>
          <p className='instruction'>
            Indicate whether you are accepting new clients with these health insurances you are
            paneled with.
          </p>

          <div className='insurance-accepting-toggles'>{editAcceptingInsurances}</div>
        </div>
      )}

      {editAcceptingInsurances.length > 0 && states && states.length > 1 ? (
        <InsuranceByState />
      ) : (
        ''
      )}
      <HealthInsurances />

      <h2>Fees</h2>
      <EditBox
        title='Session Fees'
        editable={true}
        editing={editingSessionFees}
        setEditing={setEditingSessionFees}
        innerEditing={sessionFeesInnerEditing}
        innerStatic={sessionFeesInnerStatic}
      />
      <OtherSessionInfo />
      <EditBox
        title='Custom Fee Note'
        boxClassName='w-100'
        headerClassName='m-b-0'
        description='Share any additional details about payment, billing, and fee options you offer.'
        editable={true}
        editing={editingSessionNote}
        setEditing={setEditingSessionNote}
        innerStatic={
          <div className='custom-fee-message'>
            {getPlainText(_.get(provider, 'custom_content.custom_fee_message', '')) || (
              <p>You do not have a custom fee note at this time.</p>
            )}
          </div>
        }
        innerEditing={
          <PlainTextArea
            name='custom_fee_message'
            placeholder='Write your custom fee note here.'
            validate={(values) => {
              const errors = {};
              const str = values.custom_fee_message;

              if (str && str.length > 1000) {
                errors.custom_fee_message = 'Please keep your note under 1000 characters!';
              }

              if (
                TextAreaRegex.phone.test(str) ||
                TextAreaRegex.email.test(str) ||
                TextAreaRegex.url.test(str)
              ) {
                errors.custom_fee_message =
                  'Please do not include URLs, phone numbers, or email addresses in your custom fee note!';
              }

              return errors;
            }}
            initText={_.get(provider, 'custom_content.custom_fee_message')}
            submit={(msg) => {
              updateProvider({
                id: provider.id,
                custom_content: {
                  display_custom_fee_message: !!msg.length,
                  custom_fee_message: getHTMLFromText(msg),
                },
              });
              setEditingSessionNote(false);
            }}
          />
        }
      />
    </div>
  );
};

export default connect(mapStateToProps, actions)(InsuranceFees);
