/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import FormCheckbox from '+/forms/FormCheckbox';
import { connect } from 'react-redux';
import { actions as mainActions } from '../../../main/sagaSlice';
import utils from '../../../../../src/utils/index';
import InsurancesUpdatedModal from './InsurancesUpdatedModal';
import {
  filterEntriesBySearch,
  checkForSubInsurancesRequired,
  renderCheckboxHierarchy,
} from './editInsuranceUtils';

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
  relevantInsurances: main.relevantInsurances,
  isLoading: main.isLoading,
});

const actions = {
  updateMultipleTaggings: mainActions.updateMultipleTaggings,
  getRelevantInsurances: mainActions.getRelevantInsurances,
  contactZencare: mainActions.contactZencare,
};

const currentlySelectedInsurances = (provider) =>
  _.get(provider, 'insurances', [])
    .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1))
    .map((tag) => (
      <div className='insurance' key={`current-insurance-${tag.id}`}>
        <Field name={`tag-id-${tag.id}`}>
          {(props) => <FormCheckbox {...props} label={tag.name} />}
        </Field>
      </div>
    ));

export const EditHealthInsurances = ({
  setEditing,
  provider,
  getRelevantInsurances,
  relevantInsurances,
  updateMultipleTaggings,
  contactZencare,
  isLoading,
}) => {
  const [search, setSearch] = useState('');
  const [relevantInsurancesLoaded, setRelevantInsurancesLoaded] = useState(false);
  const [updatedModalOpen, setUpdatedModalOpen] = useState(false);
  const [showUnlistedTextBox, setShowUnlistedTextBox] = useState(false);
  const initialValues = {};
  _.get(provider, 'insurances', []).forEach((initialValuesTag) => {
    initialValues[`tag-id-${initialValuesTag.id}`] = true;
  });

  useEffect(() => {
    if (!relevantInsurancesLoaded && provider) {
      getRelevantInsurances({ id: provider.id });
      setRelevantInsurancesLoaded(true);
    }
  }, [relevantInsurancesLoaded, provider, getRelevantInsurances]);

  const handleSearch = ({ target }) => utils.debounce(() => setSearch(target.value));

  const generateUpdatesList = (values) => {
    const updates = [];
    const oldTrueTags = Object.keys(initialValues);
    const nowValueIndexes = Object.keys(values);
    nowValueIndexes
      .filter((x) => _.startsWith(x, 'tag-id-'))
      .forEach((newValueIndex) => {
        const tagId = parseInt(newValueIndex.replace('tag-id-', ''));
        // Add new tag only if if needed
        if (!oldTrueTags.includes(newValueIndex) && values[newValueIndex]) {
          updates.push({
            tagTypePlural: 'insurances',
            tagId: tagId,
            associate: true,
          });
        }

        // Remove tag only if needed
        if (oldTrueTags.includes(newValueIndex) && !values[newValueIndex]) {
          updates.push({
            tagTypePlural: 'insurances',
            tagId: tagId,
            associate: false,
          });
        }
      });

    return updates;
  };

  const saveInsuranceUpdates = (values) => {
    const updates = generateUpdatesList(values);
    updateMultipleTaggings({
      providerId: provider.id,
      entries: updates,
    });

    if (values['unlisted-health-insurance'] && values['unlisted-health-insurance'].length) {
      sendUnlistedInsuranceEmail(values);
      setUpdatedModalOpen(true);
    } else {
      closeEditor();
    }
  };

  const sendUnlistedInsuranceEmail = (values) => {
    const payload = {
      provider_id: provider.id,
      template: 'insurance-edit',
      locals: {
        insurances: values['unlisted-health-insurance'],
        changes: '',
      },
    };

    contactZencare(payload);
  };

  const closeEditor = () => {
    setEditing(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const closeInsuranceUpdatedModal = () => {
    setUpdatedModalOpen(false);
    closeEditor();
  };

  const submitDisabled = (formProps) => {
    if (!formProps.valid) {
      return true;
    }
    if (confirmVisible(formProps) && !formProps.values.update_insurances_confirmed) {
      return true;
    }

    return false;
  };

  const confirmVisible = (formProps) => {
    const updates = generateUpdatesList(formProps.values);
    const addUpdates = updates.filter((x) => x.associate);

    return addUpdates.length > 0;
  };

  const validate = (values) => {
    const errors = {};
    const validationErrorParagraphs = [];
    // If data is not yet ready, return now.
    if (!relevantInsurances || !relevantInsurances.commonInsurancesEntries) {
      return errors;
    }

    checkForSubInsurancesRequired(
      values,
      validationErrorParagraphs,
      relevantInsurances.commonInsurancesEntries
    );
    checkForSubInsurancesRequired(
      values,
      validationErrorParagraphs,
      relevantInsurances.allInsuranceEntries
    );

    if (validationErrorParagraphs.length > 0) {
      errors.validationErrorParagraphs = validationErrorParagraphs;
    }

    return errors;
  };

  return (
    <div className='edit-health-insurances'>
      <div>
        <Form
          initialValues={initialValues}
          onSubmit={saveInsuranceUpdates}
          validate={validate}
          render={(formProps) => (
            <>
              <div className='box m-b-sm'>
                <h4 className='m-b-0' id='edit-health-insurances-header'>
                  In-Network Health Insurances
                </h4>
                <p className='instruction'>Select the health insurances you are paneled with.</p>
                {currentlySelectedInsurances(provider).length ? (
                  <div className='currently-selected-insurances'>
                    <h5>Currently selected insurances</h5>
                    <div className='insurances'>{currentlySelectedInsurances(provider)}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className='add-remove-health-insurances'>
                  <h5>Add/Remove Health Insurances</h5>
                  <div className='filter-insurances m-t-md m-b-sm'>
                    <input
                      type='text'
                      className='block w-100'
                      placeholder='Filter insurances'
                      onKeyUp={handleSearch}
                    />
                  </div>
                  <div className='common-health-insurances'>
                    {filterEntriesBySearch('commonInsurancesEntries', relevantInsurances, search)
                      .length > 0 ? (
                      <>
                        <h6>Common health insurances</h6>
                        <div className='insurances'>
                          {renderCheckboxHierarchy(
                            formProps.values,
                            filterEntriesBySearch(
                              'commonInsurancesEntries',
                              relevantInsurances,
                              search
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='all-health-insurances'>
                    <h6 className='p-t-md'>All health insurances</h6>
                    <div className='insurances'>
                      {renderCheckboxHierarchy(
                        formProps.values,
                        filterEntriesBySearch('allInsuranceEntries', relevantInsurances, search)
                      )}
                    </div>
                  </div>
                  <div className='m-t-lg p-b-md' style={{ borderTop: '1px solid lightgray' }}>
                    <h5 className='m-t-md  semi-bold'>
                      Insurance not listed? Request for it to be added.
                    </h5>
                    {!showUnlistedTextBox && (
                      <button
                        className='md pill primary'
                        onClick={() => setShowUnlistedTextBox(true)}
                      >
                        Add Unlisted Insurance
                      </button>
                    )}
                    {showUnlistedTextBox && (
                      <div className='add-unlisted-insurance'>
                        <h6>Add an unlisted health insurance</h6>
                        <Field name='unlisted-health-insurance' component='textarea' />
                        <button
                          className='md pill primary'
                          onClick={() => setShowUnlistedTextBox(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='actions'>
                {confirmVisible(formProps) && (
                  <div className='confirm-paneled m-t-lg m-b-sm'>
                    <Field name='update_insurances_confirmed'>
                      {(props) => (
                        <FormCheckbox
                          {...props}
                          label={
                            <div>
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: '1.1rem',
                                }}
                              >
                                I confirm that I am&nbsp;
                                <span style={{ textDecoration: 'underline' }}>in-network</span>{' '}
                                and&nbsp;
                                <span style={{ textDecoration: 'underline' }}>paneled</span> with
                                these health insurances.
                              </span>
                              <span
                                style={{
                                  color: 'gray',
                                  fontWeight: 'normal',
                                  lineHeight: 'inherit',
                                }}
                              >
                                Please only indicate health insurances that you are in-network with,
                                not those that you offer out-of-network billing support for.
                              </span>
                            </div>
                          }
                        />
                      )}
                    </Field>
                  </div>
                )}
                {formProps.errors.validationErrorParagraphs
                  ? formProps.errors.validationErrorParagraphs
                  : ''}
                <button
                  disabled={submitDisabled(formProps)}
                  onClick={() => {
                    formProps.handleSubmit();
                  }}
                  className={submitDisabled(formProps) ? '' : 'primary'}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        />
      </div>
      {/* Set to never appear -- TODO: delete. Not deleting now in case this change is rolled back during QA */}
      {updatedModalOpen && (
        <InsurancesUpdatedModal
          isLoading={isLoading}
          open={updatedModalOpen}
          addedUnlisted={showUnlistedTextBox}
          toggle={closeInsuranceUpdatedModal}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, actions)(EditHealthInsurances);
