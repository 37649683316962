import React from 'react';

export const HelpDesk = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      width: '100%',
      height: '100%',
      overflowY: 'hidden',
    }}
  >
    <iframe
      title='Fee Calculator'
      src='https://support.zencare.co/help'
      style={{
        width: '100%',
        height: '100vh',
      }}
    />
  </div>
);

export default HelpDesk;
