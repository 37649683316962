import React from 'react';
import { onlyOnEnter } from '../../../utils';
interface Props {
  option: $TSFixMe;
  onlyIcon: $TSFixMe;
  setShowModal: $TSFixMeFunction;
  setCurrentModalSetupOption: $TSFixMeFunction;
}

const ProductDescription = ({
  option,
  onlyIcon,
  setShowModal,
  setCurrentModalSetupOption,
}: Props) => (
  <>
    {option.mostPopular && (
      <div className='text-secondary bold' style={{ fontSize: '0.85rem' }}>
        MOST POPULAR
      </div>
    )}
    {!onlyIcon && (
      <>
        <span className='bolded'>{option.name} setup</span> (${option.price}){' '}
      </>
    )}{' '}
    <i
      role='button'
      onClick={(e) => {
        setCurrentModalSetupOption(option);
        setShowModal(true);
      }}
      tabIndex={0}
      onKeyDown={onlyOnEnter(() => {
        setCurrentModalSetupOption(option);
        setShowModal(true);
      })}
      className='far fa-question-circle'
    ></i>
    {!onlyIcon && (
      <>
        <br />
        {option.blurb}
      </>
    )}
  </>
);
export default ProductDescription;
