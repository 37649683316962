import React, { useState } from 'react';
import _ from 'lodash';
import EditBox from '+/EditBox';
import { Button } from '@zencare/ui';
import { Form, Field } from 'react-final-form';

function VideoStatus(props) {
  const { provider, updateProvider, contactZencare } = props;
  const [editing, setEditing] = useState(false);

  const video = _.get(provider, 'custom_content.videos[0]');
  const displayVideo = _.get(provider, 'custom_content.display_video_introduction');

  function onSubmit(form) {
    // eslint-disable-next-line default-case
    switch (form.video) {
      case 'hidden':
        updateProvider({
          id: provider.id,
          custom_content: {
            display_video_introduction: false,
          },
        });
        break;
      case 'visible':
        updateProvider({
          id: provider.id,
          custom_content: {
            display_video_introduction: true,
          },
        });
        break;
      case 'no-video':
        contactZencare({
          template: 'photo-video-edit',
          provider_id: provider.id,
          locals: {
            edits: ['Videos'],
            requestedVideoEdits: `
                        This provider wishes to permanently delete the following video:  
                        ${video}. This video url has already been deleted from the provider's
                        custom content and will not display on their profile. Please delete the 
                        video from YouTube/Vimeo, or, if you believe this was done in error, contact
                        the provider.
                    `,
          },
        });

        updateProvider({
          id: provider.id,
          custom_content: {
            videos: [],
          },
        });

        break;
    }
    setEditing(false);
  }

  let status;
  if (_.isNil(video)) {
    status = 'no-video';
  } else if (_.isNil(displayVideo) || !displayVideo) {
    status = 'hidden';
  } else {
    status = 'visible';
  }

  const icons = {
    'no-video': <i className='fas fa-times-circle text-error m-r-xs'></i>,
    hidden: <i className='fas fa-info-circle text-warning m-r-xs'></i>,
    visible: <i className='fas fa-check-circle text-primary m-r-xs'></i>,
  };

  const formOptions = [
    {
      label: <>{icons.visible}Display video on profile, Vimeo, and YouTube</>,
      value: 'visible',
    },
    {
      label: <>{icons.hidden}Hide video from profile, but accessible on YouTube and Vimeo</>,
      value: 'hidden',
    },
    {
      label: <>{icons['no-video']}Delete video from profile and from YouTube/Vimeo</>,
      value: 'no-video',
    },
  ];

  return (
    <>
      <EditBox
        title='Video Status'
        boxClassName='video-section'
        editable={false}
        innerStatic={
          <>
            {!editing && (
              <div className={`announcement video m-b-sm ${status}`}>
                {status === 'no-video' && (
                  <span>
                    {icons[status]}
                    You currently do not have a video.{' '}
                    <a
                      target='_blank'
                      href='https://support.zencare.co/help/what-are-my-options-for-a-new-introductory-video'
                    >
                      Read here for options!
                    </a>
                  </span>
                )}
                {status === 'hidden' && (
                  <span>
                    {icons[status]}
                    Your video is hidden from your profile but accessible on YouTube and Vimeo.
                  </span>
                )}
                {status === 'visible' && (
                  <span>
                    {icons[status]}
                    Your video is displayed on your profile.
                  </span>
                )}
              </div>
            )}
            {editing && (
              <>
                <p>How would you like your video displayed?</p>
                <Form
                  initialValues={{ video: status }}
                  initialValuesEqual={() => true}
                  onSubmit={onSubmit}
                  render={({ handleSubmit, values }) => (
                    <div className='video-options flex column'>
                      {formOptions.map((o) => (
                        <>
                          <Field
                            name='video'
                            value={o.value}
                            key={o.value}
                            type='radio'
                            component='input'
                            id={o.value}
                          />
                          <label htmlFor={o.value} className='m-b-sm'>
                            <span>{o.label}</span>
                          </label>
                        </>
                      ))}
                      {values.video === 'no-video' && (
                        <span className='text-secondary'>
                          Are you sure? This will permanently delete your video.
                        </span>
                      )}
                      <div className='actions'>
                        <Button className='hollow m-r-sm' onClick={() => setEditing(false)}>
                          Cancel
                        </Button>
                        <Button className='primary' onClick={handleSubmit}>
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </>
            )}
            {video && !editing && (
              <button
                onClick={() => setEditing(!editing)}
                className={`${editing ? '' : 'primary'}`}
              >
                {editing ? 'Cancel' : 'Change Video Status'}
              </button>
            )}
          </>
        }
      />
    </>
  );
}

export default VideoStatus;
