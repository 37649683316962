import React from 'react';
// @ts-ignore
import { HashLink } from 'react-router-hash-link';
import mixpanel from 'mixpanel-browser';

interface Props {
  provider: Provider;
  item: ChecklistItem;
  dismiss?: React.MouseEventHandler<HTMLButtonElement>;
}

const DashboardCard = ({ provider, item, dismiss }: Props) => (
  <div
    key={item.cardName}
    className={`checklist-card box no-mobile m-b-md ${
      item.isUrgent
        ? item.cardType === 'Action Needed'
          ? 'urgent'
          : item.cardType === 'Attention'
          ? 'warn'
          : ''
        : ''
    }`}
  >
    <div style={{ flex: '1 1 100%' }}>
      {item.cardType && item.cardType === 'Profile Creation Steps' && (
        <span className='checklist-card-title text-quaternary'>
          <i className='fal fa-check-square'></i>
          {item.cardType}
        </span>
      )}
      {item.cardType && item.cardType === 'Action Needed' && (
        <span className='checklist-card-title text-error'>
          <i className='fal fa-exclamation-triangle'></i>
          {item.cardType}
        </span>
      )}
      {item.cardType && item.cardType === 'Attention' && (
        <span className='checklist-card-title text-warning'>
          <i className='fal fa-exclamation-triangle'></i>
          {item.cardType}
        </span>
      )}
      <h4 className='m-b-xs'>{item.cardName}</h4>
      <p className='description'>{item.cardDescription}</p>
    </div>
    <div
      style={{
        flex: '1 0',
        flexDirection: 'column',
      }}
    >
      {item.cardLink[0] === '/' ? (
        <HashLink to={item.cardLink}>
          <button
            onClick={() => {
              try {
                mixpanel.track('therapist_portal_cta_event', {
                  action: 'follow_cta_link',
                  provider_id: provider.id,
                  card_name: item.cardName,
                });
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
              }
            }}
            style={{ width: '150px' }}
            className='primary pill m-r-sm m-b-sm'
          >
            {item.cardLinkText}
          </button>
        </HashLink>
      ) : (
        <a target='_blank' rel='noopener noreferrer' href={item.cardLink}>
          <button style={{ width: '150px' }} className='primary pill m-r-sm m-b-sm'>
            {item.cardLinkText}
          </button>
        </a>
      )}

      {dismiss && (
        <button style={{ width: '150px' }} className='primary pill hollow' onClick={dismiss}>
          {item.dismissText}
        </button>
      )}
    </div>
  </div>
);

export default DashboardCard;
