import React from 'react';
import PureModal from '+/Modal';

const ZpmPortalLinkModal = ({ open, toggle, practiceManagementSubdomain }) => (
  <PureModal
    visible={open}
    additionalCssClasses='shorter-modal'
    closeModal={() => toggle(false)}
    headerContent={<h4>Welcome to Zencare Practice Management!</h4>}
  >
    <div>
      <p>
        Continue to your clinic at
        <a
          className='bold'
          href={`https://${practiceManagementSubdomain}.zencarepractice.com/`}
        >{` ${practiceManagementSubdomain}.zencarepractice.com`}</a>
        .
      </p>
      <p style={{ opacity: '.5' }}>
        Questions? Reach out to our dedicated customer support team at support@zencarepractice.com
      </p>
      <a href={`https://${practiceManagementSubdomain}.zencarepractice.com/`}>
        <button className='primary pill'>Continue to my Clinic</button>
      </a>
    </div>
  </PureModal>
);

export default ZpmPortalLinkModal;
