import * as React from 'react';
import { Field } from 'react-final-form';
import { stateAbbreviations } from '~/utils/stateAbbrDict';

/**
 * This component represents the inputs in a form meant to collect Billing Address details.
 * Any form title, validation logic, and submission handling should go in the parent component. See
 * plan-management/BillingAddress.tsx or below for an example.
 *
 * @example
 * import { validateBillingAddress } from '~/utils/validateBillingAddress';
 *
 * <Form
 *      onSubmit={onSubmit}
 *      validate={validate}
 *      render={({ handleSubmit, form, submitting, pristine, errors, submitFailed }) => (
 *          <form className='billing-address-form box w-49' onSubmit={handleSubmit}>
 *              <h5>Billing Address</h5>
 *              <BillingAddressInputs />
 *              <div className='w-100 flex justify-end'>
 *                  <button className='primary' type='submit' disabled={...}>
 *                      Save
 *                  </button>
 *              </div>
 *
 *              {submitFailed && (
 *                  <div style={{ color: 'red' }}>...</div>
 *              )}
 *          </form>
 *      )}
 * />
 *
 */
function BillingAddressInputs() {
  return (
    <>
      <div className='flex w-100 m-b-xs'>
        <div className='m-r-xs w-100'>
          <label htmlFor='address_line1'>Street address</label>
          <Field name='address_line1'>
            {({ input, meta }) => (
              <div className='flex column'>
                <input {...input} type='text' placeholder='123 Main St' id='address_line1' />
                {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
              </div>
            )}
          </Field>
        </div>

        <div className='w-100'>
          <label htmlFor='address_line2'>Floor, Suite (optional)</label>
          <Field name='address_line2'>
            {({ input, meta }) => (
              <div className='flex column'>
                <input {...input} type='text' placeholder='Suite #7' id='address_line2' />
                {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
              </div>
            )}
          </Field>
        </div>
      </div>

      <div className='flex w-100 m-b-sm'>
        <div className='m-r-xs w-100'>
          <label htmlFor='address_city'>City</label>
          <Field name='address_city'>
            {({ input, meta }) => (
              <div className='flex column'>
                <input {...input} type='text' placeholder='Brooklyn' id='address_city' />
                {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
              </div>
            )}
          </Field>
        </div>
        <div className='flex w-100'>
          <div className='m-r-xs w-100'>
            <label htmlFor='address_state'>State</label>
            <Field name='address_state' component='select'>
              {({ input, meta }) => (
                <div className='flex column'>
                  <select {...input} id='address_state'>
                    {/* Default option */}
                    <option key='--' value={''}>
                      --
                    </option>
                    {/* State options */}
                    {stateAbbreviations.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
                </div>
              )}
            </Field>
          </div>
          <div className='w-100'>
            <label htmlFor='address_postal_code'>Zip</label>
            <Field name='address_postal_code'>
              {({ input, meta }) => (
                <div className='flex column'>
                  <input {...input} type='text' placeholder='10001' id='address_postal_code' />
                  {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
                </div>
              )}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingAddressInputs;
