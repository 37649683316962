/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import _, { set } from 'lodash';
import Voca from 'voca';
import dayjs from 'dayjs';
import ProviderAvatar from './ProviderAvatar';
import { ACTIVE_SUBSCRIPTION_STATUSES, CANCELED_SUBSCRIPTION_STATUSES } from './constants';
import { getSubscriptionProviders, getAssociatedAccount } from './helpers';
import history from '#/history';
import UpcomingInvoiceModal from './UpcomingInvoiceModal';
import ResubscribeModalSeatBased from '~/components/payment/seat-based/ResubscribeModalSeatBased';
import hasFeatureFlag from '~/utils/hasFeatureFlag';
import { HIDE_REIMBURSIFY_SSO_UPGRADE } from '~/shared/constants/featureFlags';

const SubscriptionProviders = ({
  providers,
  subscription,
  setOpen,
  open,
  // only used for for interim support of subscriptions with provider_ids property
  openContactModal,
  // only used for for interim support of subscriptions with provider_ids property
  subProviders,
}: $TSFixMe) => {
  const handleReactivateClick = () => {
    // If subscription has provider_ids property instead of provider_id property
    // use contact modal to handle the their reactivation
    if (subscription?.metadata?.provider_ids?.length > 0) {
      openContactModal({
        account_id: subProviders[0].account_id,
        provider_ids: subscription.provider_ids,
        provider_names: subProviders.map((p: $TSFixMe) =>
          p.first_name ? `${p.first_name} ${p.last_name}` : p.company_name
        ),
      });
    }
    setOpen(true);
  };
  return (
    <div className='practice-provider-grid'>
      {providers.map((p: $TSFixMe) => (
        <div>
          <ProviderAvatar provider={p} />
          {CANCELED_SUBSCRIPTION_STATUSES.includes(subscription.status) && (
            <>
              <button className='primary pill md' onClick={handleReactivateClick}>
                Reactivate Membership
              </button>
              <ResubscribeModalSeatBased
                open={open}
                toggle={setOpen}
                onSubmit={() => ({})}
                provider={p}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export const Subscription = ({
  subscription,
  customer,
  allProviders,
  getUpcomingInvoice,
  upgradePlan,
  openContactModal,
  accounts,
  upgradeReimbursify,
  pendingZencarePracticeManagementClinicData,
}: $TSFixMe) => {
  const [open, setOpen] = useState(false);
  const [viewUpcomingInvoiceOpen, setViewUpcomingInvoiceOpen] = useState(false);
  const [upcomingInvoiceDto, setUpcomingInvoiceDto] = useState(null);

  const subProviders = getSubscriptionProviders(subscription, allProviders);
  const associatedAccount = getAssociatedAccount(accounts, subProviders);
  const isAnnualSubscription = subscription?.items.data[0]?.plan?.interval === 'year';
  const subscriptionHasProviders = subProviders && subProviders.length > 0;

  const openSubscriptionModal = (stripe_customer_id: string, stripe_subscription_id: string) => {
    getUpcomingInvoice({
      stripe_customer_id,
      stripe_subscription_id,
      onSuccess: (data: $TSFixMe) => {
        setUpcomingInvoiceDto(data);
        setViewUpcomingInvoiceOpen(true);
      },
      onError: (error: $TSFixMe) => {
        alert(error.message);
        throw new Error(
          `Error getting upcoming invoice for stripe_customer_id ${stripe_customer_id} and stripe_subscription_id ${stripe_subscription_id}: ${error}`
        );
      },
    });
  };

  const convertToMonthly = () => {
    if (
      // Subscription is both the only legacy stripe subscription and is annually paid
      associatedAccount?.sole_legacy_stripe_subscription_is_yearly &&
      // There is no Seat Based subscription associated with the associated account
      !associatedAccount?.seat_based &&
      // Subscription is not in trial mode
      subscription?.status !== 'trialing'
    ) {
      history.push(`/membership/switch-to-monthly/${subscription.id}`);
    } else {
      history.push('/contact');
    }
  };

  const formatSubscriptionItem = (
    item: $TSFixMe,
    subscription: $TSFixMe,
    customer: $TSFixMe,
    subProviders: $TSFixMe
  ) => {
    const name =
      _.get(item, 'price.product.name') ||
      _.get(customer, 'metadata.form_title') ||
      'Zencare Membership';

    const statusColor = (status: $TSFixMe) => {
      if (['incomplete', 'past_due', 'unpaid'].includes(status)) {
        return 'quaternary';
      }

      if (ACTIVE_SUBSCRIPTION_STATUSES.includes(status)) {
        return 'primary';
      }

      return 'secondary';
    };

    const statusName = (status: $TSFixMe) => {
      if (status === 'trialing') {
        status = 'active';
      }

      if (status === 'incomplete_expired') {
        status = 'expired';
      }

      return Voca.capitalize(status);
    };

    const nextPaymentLine = () => {
      const oneYearFromSubscriptionStart = dayjs(subscription.created).add(1, 'year');
      const isMonthly = item.price.recurring && item.price.recurring.interval === 'month';
      if (
        ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) &&
        isMonthly &&
        dayjs().isBefore(oneYearFromSubscriptionStart)
      ) {
        return (
          <p className='m-b-0'>
            <span className='semi-bold'>Next Payment Date</span>:{' '}
            {dayjs(subscription.current_period_end).format('MMMM D, YYYY')}
          </p>
        );
      }

      return null;
    };

    const subscriptionTermsAndRenewalLine = () => {
      const oneYearFromSubscriptionStart = dayjs(subscription.created).add(1, 'year');
      const isBeforeOneYearFromSubscriptionStart = dayjs().isBefore(oneYearFromSubscriptionStart);
      const isMonthly = item.price.recurring && item.price.recurring.interval === 'month';
      // Conditionally showing yearly is needed because we extend dates for yearly after profile goes live
      const shouldShowForYearly = subProviders.find((p: $TSFixMe) => p.status === 'active');
      // Only shown during first year for monthly subscriptions
      if (ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status)) {
        return (
          <>
            {isMonthly && isBeforeOneYearFromSubscriptionStart && (
              <>
                <p>
                  <span className='semi-bold'>Renewal Date:</span>{' '}
                  {oneYearFromSubscriptionStart.format('MMMM D, YYYY')}
                  <div>
                    <button
                      className='view-next-invoice-button'
                      onClick={() => {
                        openSubscriptionModal(subscription.customer_id, subscription.id);
                      }}
                    >
                      View invoice
                    </button>
                  </div>
                </p>
              </>
            )}
            {((isMonthly && !isBeforeOneYearFromSubscriptionStart) ||
              (!isMonthly && shouldShowForYearly)) && (
              <>
                {/* The renewal date for annual subscriptions or monthly ones after 1 year is always the next payment date */}
                <p>
                  <span className='semi-bold'>Renewal Date:</span>{' '}
                  {dayjs(subscription.current_period_end).format('MMMM D, YYYY')}
                  <div>
                    <button
                      className='view-next-invoice-button'
                      onClick={() => {
                        openSubscriptionModal(subscription.customer_id, subscription.id);
                      }}
                    >
                      View next invoice
                    </button>
                  </div>
                </p>
              </>
            )}
            <p
              style={{
                color: '#484848',
                fontSize: '0.9rem',
                lineHeight: '1.25rem',
              }}
            >
              <span className='semi-bold'>Terms:</span> "I agree to the{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://zencare.co/policy/terms'
                style={{
                  fontSize: '0.9rem',
                  lineHeight: '1.25rem',
                }}
              >
                Terms Of Service
              </a>
              . I understand that the Zencare membership is an annual commitment, that I will be
              responsible for the remainder of the year if I cancel early, and that no refunds are
              issued. When eligible, subscription change requests must be made by providing fifteen
              (15) days' notice prior to your renewal date, or next payment date."
            </p>
          </>
        );
      }

      return null;
    };

    const upgradePayload = {
      account_id: subProviders[0]?.account_id,
      provider_ids: subProviders.map((p: $TSFixMe) => p.id),
      provider_names: subProviders.map((p: $TSFixMe) =>
        p.first_name ? `${p.first_name} ${p.last_name}` : p.company_name
      ),
      isAnnual: isAnnualSubscription,
    };

    // Used to adjust the layout of subscription CTAs
    const showZpmButton =
      ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) &&
      !pendingZencarePracticeManagementClinicData;

    const showReimbursifyButton =
      !hasFeatureFlag('account', associatedAccount, HIDE_REIMBURSIFY_SSO_UPGRADE) &&
      ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) &&
      subscriptionHasProviders;

    const showZpmOrReimbursifyButton = showZpmButton || showReimbursifyButton;

    return (
      <div key={subscription.id}>
        <div>
          <div className='flex align-center m-b-sm'>
            <h5 className='m-b-0 m-r-sm'>{name}</h5>
            <span
              className={`subscription-status-bubble m-r-sm text-${statusColor(
                subscription.status
              )}`}
            >
              {statusName(subscription.status)}
            </span>
          </div>
          <div className='flex align-center'>
            {showZpmButton && subscriptionHasProviders && (
              <button
                className='primary pill md m-r-sm'
                onClick={() => upgradePlan(upgradePayload, subscription)}
              >
                Add Practice Management
              </button>
            )}
            {showReimbursifyButton && (
              <button
                className='primary pill md'
                onClick={() => upgradeReimbursify(upgradePayload)}
              >
                Upgrade Private Pay Booster
              </button>
            )}
          </div>
          {isAnnualSubscription && (
            <div className={`flex align-center ${showZpmOrReimbursifyButton && 'm-t-sm'}`}>
              <button className='primary pill md hollow m-r-sm' onClick={() => convertToMonthly()}>
                Switch to monthly payments
              </button>
            </div>
          )}
        </div>
        {ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) && (
          <>
            <hr className='m-t-sm m-b-sm' />
            {nextPaymentLine()}
            {subscriptionTermsAndRenewalLine()}
            <hr className='m-t-sm m-b-sm' />
          </>
        )}

        <UpcomingInvoiceModal
          dto={upcomingInvoiceDto}
          showModal={viewUpcomingInvoiceOpen}
          setShowModal={setViewUpcomingInvoiceOpen}
        />
      </div>
    );
  };
  return (
    <div className='account-box m-r-sm'>
      {subscription.items.data.map((i: $TSFixMe) =>
        formatSubscriptionItem(i, subscription, customer, subProviders)
      )}
      <p>Profiles covered by this membership:</p>
      <SubscriptionProviders
        providers={subProviders}
        subscription={subscription}
        setOpen={setOpen}
        open={open}
        openContactModal={openContactModal}
        subProviders={subProviders}
      />
    </div>
  );
};

export const IncompleteSubscription = ({ provider }: $TSFixMe) => (
  <div>
    <div className='flex align-center'>
      <h5 className='m-b-0 m-r-sm'>
        {provider.type === 'individual' ? 'Individual Provider' : 'Group Practice'} Profile
      </h5>
      <span className={'subscription-status-bubble text-secondary'}>Membership Not Started</span>
    </div>
    <ProviderAvatar provider={provider} />
    <button
      className='primary pill md'
      onClick={() => {
        if (provider.locations && provider.locations.length) {
          history.push(`profiles/pay-for/${provider.id}?return_to_plan_management=1`);
        } else {
          history.push(`profiles/create/${provider.id}`);
        }
      }}
    >
      Begin Membership
    </button>
  </div>
);
export const IncompletePracticeManagementSubscription = ({ data }: { data: PendingClinicData }) => {
  const { subdomain, status, link } = data;
  const clinicUrl = `https://${subdomain}.${process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME}`;
  const displayClinicUrl = `${subdomain}.${process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME}`;

  if (status !== 'pending') {
    return null;
  }

  return (
    <div className='account-box'>
      <div className='flex align-center'>
        <h5 className='m-b-0 m-r-sm'>Zencare Practice Management EHR</h5>
        <span className={'subscription-status-bubble text-secondary'}>Trialing</span>
      </div>
      <p>
        <a target='_blank' rel='noopener noreferrer' href={clinicUrl}>
          {displayClinicUrl}
        </a>
      </p>
      <a target='_blank' rel='noopener noreferrer' href={link}>
        <button className='primary pill md'>Begin Zencare Practice Management Membership</button>
      </a>
    </div>
  );
};
