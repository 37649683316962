import React from 'react';
import { Field } from 'react-final-form';

export const TabSelect = ({ tabOptions, name }) => {
  const renderOptions = () =>
    Object.entries(tabOptions).map(([key, val], i) => (
      <div key={i} className='container'>
        <Field
          type='radio'
          component='input'
          id={`${name}_${key.toLowerCase()}`}
          value={`${key.toLowerCase()}`}
          name={name}
        />
        <label htmlFor={`${name}_${key.toLowerCase()}`}>{val}</label>
      </div>
    ));

  return <div className='tab-select'>{renderOptions()}</div>;
};

export default TabSelect;
