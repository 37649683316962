import React, { useState } from 'react';
import { onlyOnEnter } from '../../../src/utils';
import { useForm } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import stripeCouponToString from '~/utils/stripeCouponToString';

const StandaloneCoupon = () => {
  const dispatch = useDispatch();
  const form = useForm();
  const [value, setValue] = useState('');
  const [manuallyAddingCoupon, setManuallyAddingCoupon] = useState(false);
  const { coupon } = form.getState().values;
  const { previewCoupon, previewTotal, previewSubtotal, isLoading } = useSelector<
    State,
    PracticeManagementState
  >((state) => state.practiceManagement);

  const couponError =
    !isLoading && coupon && previewTotal === previewSubtotal ? 'Invalid or expired coupon' : '';

  const couponDescription = Boolean(couponError) ? '' : stripeCouponToString(previewCoupon);

  if (!manuallyAddingCoupon) {
    return (
      <div className='coupon-wrapper'>
        <button
          className='add-discount-code-link'
          onClick={() => {
            setManuallyAddingCoupon(true);
          }}
        >
          Add discount code
        </button>
      </div>
    );
  }

  return (
    <div className='coupon-wrapper'>
      {coupon ? (
        <div className='applied-coupon'>
          <i className='fas fa-tag'></i> {coupon}{' '}
          <span
            className='remove-coupon'
            title='Remove coupon'
            role='button'
            tabIndex={0}
            onKeyDown={onlyOnEnter(() => {})}
            onClick={() => {
              setValue('');
              form.change('coupon', undefined);
              dispatch(actions.removePreviewCoupon());
            }}
          >
            X
          </span>
        </div>
      ) : (
        <div className='coupon-input-wrapper'>
          <input
            style={{
              height: '38px',
              color: '#666666',
            }}
            placeholder='Coupon code (optional)'
            id='coupon-code'
            name='coupon-code'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <button
            type='button'
            className='primary hollow md'
            style={{ height: '38px' }}
            onClick={() => form.change('coupon', value)}
          >
            Apply
          </button>
        </div>
      )}
      <div className='text-error'>{couponError}</div>
      <div className='coupon-duration-phrase'>{couponDescription}</div>
    </div>
  );
};

export default StandaloneCoupon;
