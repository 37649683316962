import { createApis } from 'saga-slice-helpers';

const baseURL = process.env.ZENCARE_API_URL || 'http://localhost:4000';
const apis = createApis({
  baseURL: `${baseURL}`,
  // Default timeout of 10 minutes
  timeout: 10 * 60 * 1000,
});

export const { api, sagaApi } = apis;

export const { addAuthorization, removeAuthorization, addHeader, removeHeader } = api;
