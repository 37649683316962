/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, MouseEventHandler } from 'react';
import { Field, Form } from 'react-final-form';
import { actions as mainActions } from '~/components/main/sagaSlice';
import { useDispatch } from 'react-redux';

interface Props {
  provider: Provider;
  licenses: License[];
  setEditingLicenses: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingSupervisorLicenses: React.Dispatch<React.SetStateAction<boolean>>;
  setNudgeLicenseWithoutLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function EditSupervisorLicenseForm({ provider, licenses, setEditingSupervisorLicenses }: Props) {
  const dispatch = useDispatch();
  const getInitialValues = () => {
    const license = licenses[0];
    const name = license?.supervisor_name || '';
    const number = license?.supervisor_license || '';
    const type = license?.supervisor_license_type || '';
    return { name, number, type };
  };
  const [initialValues, setInitialValues] = useState(getInitialValues);
  const showRemove = Boolean(initialValues.name || initialValues.number || initialValues.type);

  useEffect(() => {
    if (provider && provider.id) {
      dispatch(mainActions.getLicenses({ id: provider.id }));
    }
  }, [dispatch, provider]);

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const confirm = window.confirm(
      'Are you sure you want to remove this record of supervisor licensure from this profile? This cannot be undone.'
    );
    if (confirm) {
      const updatedLicenses = licenses.map((l) => ({
        id: l.id,
        supervisor_license: null,
        supervisor_license_type: null,
        supervisor_name: null,
      }));
      const deleteSupervisorLicensePayload = {
        provider_id: provider.id,
        licenses: updatedLicenses,
        deleteLicenseIds: [],
      };
      dispatch(mainActions.deleteSupervisorLicense(deleteSupervisorLicensePayload));
      return setEditingSupervisorLicenses(false);
    }
  };

  const onSubmit = (values: $TSFixMe) => {
    const updatedLicenses = licenses.map((l) => ({
      id: l.id,
      supervisor_license: values.number,
      supervisor_license_type: values.type,
      supervisor_name: values.name,
    }));
    const updateSupervisorLicensePayload = {
      provider_id: provider.id,
      licenses: updatedLicenses,
      deleteLicenseIds: [],
    };
    dispatch(mainActions.updateSupervisorLicense(updateSupervisorLicensePayload));
    return setEditingSupervisorLicenses(false);
  };

  const submitDisabled = (values: $TSFixMe) => {
    const { name, number, type } = values;
    return Boolean(!name || !number || !type);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className='flex column m-b-sm w-49'>
            <label htmlFor='supervisor_name'>
              <h5>Name</h5>
            </label>
            <Field
              name='name'
              id='supervisor_name'
              component='input'
              type='text'
              placeholder="Supervisor's name"
            />
          </div>
          <div className='flex column m-b-sm w-49'>
            <label htmlFor='supervisor_license_number'>
              <h5>License Number</h5>
            </label>
            <Field
              name='number'
              id='supervisor_license_number'
              component='input'
              type='text'
              placeholder='e.g. Psy222592'
            />
          </div>
          <div className='flex column m-b-sm w-49'>
            <label htmlFor='supervisor_license_type'>
              <h5>License Type</h5>
            </label>
            <Field
              name='type'
              id='supervisor_license_type'
              component='input'
              type='text'
              placeholder='e.g. PhD, LCSW, LCAT'
            />
          </div>
          <button type='submit' className='primary md' disabled={submitDisabled(values)}>
            Submit
          </button>
          {showRemove && (
            <button onClick={handleDelete} className='error hollow md m-l-sm'>
              Remove
            </button>
          )}
        </form>
      )}
    />
  );
}

export default EditSupervisorLicenseForm;
