import React from 'react';
import _ from 'lodash';

export default (props) => (
  <ul className='static-tags'>
    {_.get(props.provider, props.tagKey, []).map((p) => (
      <li key={p.id}>{p.name}</li>
    ))}
  </ul>
);
