import AuthLayout from '##/Auth';
import DefaultLayout from '##/Default';
import OnboardingLayout from '##/Onboarding';

import Login from './Login';
import Logout from './Logout';
import Confirm from './Confirm';
import OnboardingContainer from '../onboarding/OnboardingContainer';
import OnboardingFromClient from '../onboarding/OnboardingFromClient';
import RequestReset from './RequestReset';
import ResetPassword from './ResetPassword';
import UpdatePassword from './UpdatePassword';
import RecoverAccount from './RecoverAccount';
import RecoverAccountSuccess from './RecoverAccountSuccess';
import ChooseHelpOption from './ChooseHelpOption';

// TODO Some of these optional properties shouldn't be optional
export interface AuthRoute extends BaseRoute {
  auth?: boolean;
}

const routes: Array<AuthRoute> = [
  {
    path: '/login',
    exact: true,
    layout: AuthLayout,
    component: Login,
    title: 'Zencare Provider Portal',
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    auth: true,
  },
  {
    path: '/account/confirm/:token',
    layout: AuthLayout,
    component: Confirm,
    title: 'Confirm New Account',
  },
  {
    path: ['/signup', '/signup/invite/:inviteToken'],
    exact: true,
    layout: OnboardingLayout,
    component: OnboardingContainer,
    title: 'Sign Up',
  },
  {
    path: ['/signup-from-client'],
    exact: true,
    layout: OnboardingLayout,
    component: OnboardingFromClient,
    title: 'Sign Up',
  },
  {
    path: '/password/request',
    exact: true,
    layout: AuthLayout,
    component: RequestReset,
    title: 'Request Password Reset',
  },
  {
    path: '/account/recover',
    exact: true,
    layout: AuthLayout,
    component: RecoverAccount,
    title: 'Verify Personal Information',
  },
  {
    path: '/account/recovered',
    exact: true,
    layout: AuthLayout,
    component: RecoverAccountSuccess,
    title: "Here's your account information",
  },
  {
    path: '/password/reset/:reset_token',
    exact: true,
    layout: AuthLayout,
    component: ResetPassword,
  },
  {
    path: '/login/help',
    exact: true,
    layout: AuthLayout,
    component: ChooseHelpOption,
    title: 'How can we help?',
  },
  {
    path: '/password/update',
    exact: true,
    layout: DefaultLayout,
    component: UpdatePassword,
    auth: true,
    title: 'Update Your Password',
  },
];

export default routes;
