import roleNameConstants from './roleNameConstants';

export default function hasClinicianScope(accountRoles, accountId) {
  const rolesThatCanSso = [
    roleNameConstants.owner,
    roleNameConstants.reimbursifyPractitioner,
    roleNameConstants.reimbursifyLeadPractitioner,
    roleNameConstants.reimbursifyAdministrator,
  ];

  return (
    accountRoles &&
    accountId &&
    accountRoles.length &&
    accountRoles.some((r) => r.account_id === accountId && rolesThatCanSso.includes(r.role))
  );
}
