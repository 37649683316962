import { put, select, take } from 'redux-saga/effects';
import _ from 'lodash';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import { flashError } from '+/flashes/redux';
import { api } from '#/apis';
import history from '#/history';
import { statesAccepting } from '@/payment/seat-based/constants';

const sagaSliceModule = createModule({
  name: 'onboarding',
  initialState: {
    isFetching: false,
    isLoading: false,
    justRegistered: false,
    error: null,
    step: 0,
    practiceManagementSubdomain: '',
    practiceManagementClinicStatus: '',
    showPracticeManagementRedirectModal: false,
  },
  reducers: {
    registerAndCreateProvider: loadingReducer,
    registerAndCreateProviderFromExistingClientAccount: loadingReducer,
    createForSubmitOnboardingAboutYouInfo: loadingReducer,
    submitOnboardingAboutYouInfo: loadingReducer,
    createForSubmitOnboardingYourPracticeInfo: loadingReducer,
    submitOnboardingYourPracticeInfo: loadingReducer,
    submitOnboardingYourPracticeInfoWithoutSteppingForward: notLoadingReducer,
    stepForwardAfterInsuranceSubmit: notLoadingReducer,
    createForStepForwardAfterInsuranceSubmit: notLoadingReducer,
    createForSubmitOnboardingPhotos: loadingReducer,
    submitOnboardingPhotos: loadingReducer,
    createInitialLocations: loadingReducer,

    createProvider: loadingReducer,

    stepSuccess: notLoadingReducer,
    stepFailure: failReducer,

    setStep: (state, stepIndex) => {
      state.step = stepIndex;
    },

    setJustRegistered: (state, a) => {
      state.justRegistered = a;
    },
    updatePracticeManagementState: (state, payload) => {
      state.practiceManagementSubdomain = payload.practiceManagementSubdomain;
      state.practiceManagementRedirectLink = payload.practiceManagementRedirectLink;
      state.practiceManagementClinicStatus = payload.practiceManagementClinicStatus;
      state.showPracticeManagementRedirectModal = true;
    },
    setShowPracticeManagementRedirectModal: (state, payload) => {
      state.showPracticeManagementRedirectModal = payload;
    },
  },

  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      // This is used in onboarding self-signup. Create new user, set up auth for api requests, and create new provider
      [A.registerAndCreateProvider]: function* ({ payload }) {
        const { authPayload, providerPayload } = payload;
        try {
          const { data } = yield api.post('/auth', authPayload);

          if (data.practice_management_redirect_link) {
            yield put({
              type: 'onboarding/updatePracticeManagementState',
              payload: {
                practiceManagementSubdomain: data.practice_management_subdomain,
                practiceManagementRedirectLink: data.practice_management_redirect_link,
                practiceManagementClinicStatus: data.practice_management_clinic_status,
              },
            });
            return;
          }

          // add new auth to requests
          yield put({
            type: 'auth/storeLocalAuth',
            payload: data,
          });

          //If invited, provider already created. Just fetch data after auth
          if (payload.inviteToken) {
            yield put({ type: 'main/getMe' });
            yield take([
              'main/getMeSuccess',
              'main/getMeFailure',
              'main/getAllMyProvidersSuccess',
              'main/getAllMyProvidersFailure',
            ]);
            //patch the phone number
            const { provider } = yield select((state) => state.main);

            if (provider && providerPayload.phone_number) {
              yield put({
                type: 'main/updateProvider',
                payload: {
                  id: provider.id,
                  phone_number: providerPayload.phone_number,
                },
              });
            }
          } else {
            const provider = yield api.post('portal/provider', providerPayload);

            yield put({
              type: 'main/patchProvider',
              payload: provider.data,
            });
          }

          yield put(A.setJustRegistered(true));

          yield put({
            type: 'auth/loginSuccess',
            payload: data,
          });

          yield put(A.stepSuccess());
        } catch (e) {
          if (_.get(e, 'response.data.statusCode') === 403) {
            yield put(A.stepFailure('email-cannot-be-used'));

            return;
          }
          const error =
            _.get(e, 'response.data.message') || 'There was an error creating your account.';
          if (/(e|E)mail.*already.*used/.test(error)) {
            yield put(A.stepFailure('email-already-used'));
          } else {
            yield put(A.stepFailure());
            yield put(flashError(error));
          }
        }
      },
      // This is used in onboarding self-signup. Create new user, set up auth for api requests, and create new provider
      [A.registerAndCreateProviderFromExistingClientAccount]: function* ({ payload }) {
        const { providerPayload } = payload;
        try {
          const { data } = yield api.post('portal/provider-from-client', providerPayload);
          // add new auth to requests
          yield put({
            type: 'auth/storeLocalAuth',
            payload: data,
          });
          yield put({ type: 'main/getMe' });

          // Now that provider record is created, send to the normal onboarding wizard, yes a full window refresh is needed
          window.location.href = '/signup';
        } catch (e) {
          yield put(A.stepFailure());
          yield put(flashError(e));
        }
      },
      // For when an existing user creates a new provider through /profiles/create.
      [A.createProvider]: function* ({ payload }) {
        try {
          const { account_id } = payload;
          delete payload.account_id;

          const response = yield api.post(`portal/provider/${account_id}`, payload);

          const provider = response.data;

          yield put({
            type: 'main/addProvider',
            payload: provider,
          });
          yield put({ type: 'main/getAllMyProviders' });
          yield take(['main/getAllMyProvidersSuccess', 'main/getAllMyProvidersFailure']);
          yield put({ type: 'main/getAllAccountDetails' });
          yield put(A.stepSuccess());
          yield history.push(`/profiles/create/${provider.id}`);
        } catch (e) {
          yield put(A.stepFailure());
          yield put(
            flashError(
              _.get(e, 'response.data.message') || 'There was an error creating your account.'
            )
          );
        }
      },
      [A.createForSubmitOnboardingAboutYouInfo]: function* ({ payload }) {
        const providerId = payload.provider_id;
        yield put(A.submitOnboardingAboutYouInfo(payload));
        yield history.push(`/profiles/your-practice-for/${providerId}`);
      },
      [A.submitOnboardingAboutYouInfo]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;
        try {
          yield api.put(`/portal/provider/${id}/onboarding/about-you`, payload);
          yield put(A.stepSuccess());
          yield put({ type: 'main/getMe' });
        } catch (e) {
          yield put(A.stepFailure());
          yield put(flashError('There was an error saving your About You info.'));
        }
      },
      [A.createForSubmitOnboardingYourPracticeInfo]: function* ({ payload }) {
        const providerId = payload.provider_id;
        yield put(A.submitOnboardingYourPracticeInfo(payload));
        yield history.push(`/profiles/photos-for/${providerId}`);
      },
      [A.submitOnboardingYourPracticeInfo]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;
        try {
          yield api.put(`/portal/provider/${id}/onboarding/your-practice`, payload);
          yield put(A.stepSuccess());
          yield put({ type: 'main/getMe' });
        } catch (e) {
          yield put(flashError('There was an error saving your practice info.'));
        }
      },
      [A.submitOnboardingYourPracticeInfoWithoutSteppingForward]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;
        try {
          yield api.put(`/portal/provider/${id}/onboarding/your-practice`, payload);
        } catch (e) {
          yield put(A.stepFailure());
          yield put(flashError('There was an error saving your practice info.'));
        }
      },
      [A.createForStepForwardAfterInsuranceSubmit]: function* ({ payload }) {
        const providerId = payload.provider_id;
        yield history.push(`/profiles/photos-for/${providerId}`);
      },
      [A.stepForwardAfterInsuranceSubmit]: function* ({ payload }) {
        try {
          yield put(A.stepSuccess());
          yield put({ type: 'main/getMe' });
        } catch (e) {
          yield put(A.stepFailure());
          yield put(flashError('There was an error saving your practice info.'));
        }
      },
      [A.createForSubmitOnboardingPhotos]: function* ({ payload }) {
        const providerId = payload.provider_id;
        yield put(A.submitOnboardingPhotos(payload));
        yield history.push(`/profiles/go-live-for/${providerId}`);
      },
      [A.submitOnboardingPhotos]: function* ({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;
        try {
          yield api.put(`/portal/provider/${id}/onboarding/photos`, payload);
          yield put(A.stepSuccess());
          yield put({ type: 'main/getMe' });
        } catch (e) {
          yield put(A.stepFailure());
          yield put(flashError('There was an error saving your profile photo.'));
        }
      },

      [A.createInitialLocations]: function* ({ payload }) {
        const id = payload.provider_id;
        try {
          for (const locationPayload of payload.locations) {
            locationPayload.should_display = false;
            yield api.post(`/portal/provider/${id}/location`, locationPayload);
          }

          yield put({ type: 'main/getAllMyProviders' });
          yield take(['main/getAllMyProvidersSuccess', 'main/getAllMyProvidersFailure']);
          yield put({ type: 'main/getAllAccountDetails' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          yield put(A.stepSuccess());
        } catch (e) {
          yield put(A.stepFailure());
          yield put(flashError('There was an error creating your locations.'));
          console.error(e);
        }
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
