export const editFormName = 'custom-specialties';

export const columns = [
  {
    header: 'Specialty Name',
    key: 'name',
    type: 'textarea',
    maxlength: 60,
    placeholder: 'Name',
  },
  {
    header: 'Description',
    key: 'description',
    type: 'textarea',
    maxlength: 500,
    placeholder: 'Description',
  },
];

export const maxCustomSpecialties = 6;
export const maxGeneralSpecialties = 20;
