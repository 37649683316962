import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions as mainActions } from '@/main/sagaSlice';
import { actions as tgActions } from './sagaSlice';
import _ from 'lodash';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import TherapyGroupForm from './TherapyGroupForm';
import Loader from '+/forms/SectionLoader';

function TherapyGroup({ therapyGroup, editTherapyGroup, provider, deleteGroup, logEvent }) {
  const [editing, setEditing] = useState(false);

  const submit = (payload) => {
    editTherapyGroup({
      ...payload,
      therapy_group_id: therapyGroup.id,
      provider_id: provider.id,
    });
    setEditing(false);
  };

  return (
    <div className='box therapy-group m-b-md mobile-border-bottom'>
      {editing ? (
        <TherapyGroupForm
          therapyGroup={therapyGroup}
          toggle={setEditing}
          submit={submit}
          provider={provider}
          logEvent={logEvent}
          deleteGroup={deleteGroup}
          galleryImages={_.get(provider, 'gallery.images', []).filter((p) => !p.profile_ar)}
        />
      ) : (
        <TherapyGroupStatic therapyGroup={therapyGroup} toggle={setEditing} />
      )}
    </div>
  );
}

function TherapyGroupStatic({ therapyGroup, toggle }) {
  const location = _.get(therapyGroup, 'locations[0]');
  const address = location.remote_location
    ? `Online ${location.state}`
    : [location.address, location.city, location.state, location.zip].join(', ');
  const img = therapyGroup.image || _.get(therapyGroup, 'seo.image');

  return (
    <>
      <div className='image-container m-r-sm'>
        {img && (
          <img
            width={200}
            height='auto'
            alt={therapyGroup.name}
            src={imgix(img, {
              maxW: 200,
            })}
          />
        )}
      </div>
      <div className='therapy-group-info m-r-sm'>
        <h3 className='m-b-sm'>{therapyGroup.name}</h3>
        {therapyGroup.status === 'active' ? (
          <span className='text-primary'>
            <i className='fas fa-check-circle'></i>
            Active therapy group
          </span>
        ) : (
          <span className='text-secondary'>
            <i className='fas fa-times-circle'></i>
            Inactive therapy group
          </span>
        )}
        <span className='m-t-xs m-b-xs'>
          <span className='label'>Location: </span>
          {address}
        </span>
        <span className='m-b-xs'>
          <span className='label'>Times: </span>
          {therapyGroup.hours}
        </span>
        <span className='m-b-xs'>
          <span className='label'>Cost: </span>
          {therapyGroup.costs}
        </span>
        {therapyGroup.status === 'active' && therapyGroup.expire_at && (
          <span className='m-b-xs'>
            <span className='label'>Post expiration date: </span>
            {therapyGroup.expire_at}
          </span>
        )}
        <span className='m-b-xs'>
          <span className='label'>Short description: </span>
          {therapyGroup.short_description}
        </span>
      </div>
      <div className='actions'>
        <button className='primary m-b-xs w-100' onClick={() => toggle(true)}>
          Edit
        </button>
        {therapyGroup.status === 'active' && (
          <a
            target='_blank'
            href={`${process.env.ZENCARE_WEBSITE_URL}/therapy-group/${therapyGroup.slug}`}
          >
            <button className='primary hollow w-100 m-b-xs'>View</button>
          </a>
        )}
      </div>
    </>
  );
}

function TherapyGroups({
  provider,
  createTherapyGroup,
  editTherapyGroup,
  deleteTherapyGroup,
  isLoading,
}) {
  const [adding, setAdding] = useState(false);

  const therapyGroups = provider.therapy_groups.map((tg) => (
    <TherapyGroup
      key={`${tg.id}${tg.status}`}
      therapyGroup={tg}
      editTherapyGroup={editTherapyGroup}
      provider={provider}
      deleteGroup={deleteTherapyGroup.bind(null, {
        provider_id: provider.id,
        therapy_group_id: tg.id,
      })}
    />
  ));

  const defaultTherapyGroup = {
    locations: [
      {
        remote_location: 1,
      },
    ],
    seo: {},
    image: {},
    status: 'active',
  };

  return (
    <div className='therapy-groups-container'>
      <h1 className='m-b-md'>Therapy Groups</h1>
      {therapyGroups.length === 0 && <p>You do not have any therapy groups.</p>}
      {therapyGroups}
      {adding && (
        <div className='box therapy-group m-b-md'>
          <TherapyGroupForm
            therapyGroup={defaultTherapyGroup}
            provider={provider}
            galleryImages={_.get(provider, 'gallery.images', []).filter((p) => !p.profile_ar)}
            toggle={() => setAdding(false)}
            submit={(payload) => {
              createTherapyGroup({
                ...payload,
                provider_id: provider.id,
              });
              setAdding(false);
            }}
          />
        </div>
      )}
      {isLoading && <Loader />}

      {provider.status === 'active' ? (
        !adding && (
          <button
            className='pill primary add'
            onClick={() => {
              setAdding(true);
            }}
          >
            <i className='far fa-plus-circle'></i>
            Add Therapy Group
          </button>
        )
      ) : (
        <p className='text-error'>
          Your profile is not live yet. Return here once your profile is live to create therapy
          groups!
        </p>
      )}
      {}
    </div>
  );
}

const mapStateToProps = ({ main, therapyGroups }) => ({
  provider: main.provider,
  isLoading: therapyGroups.isLoading,
});

const actions = {
  updateProvider: mainActions.updateProvider,
  logEvent: mainActions.logEvent,
  createTherapyGroup: tgActions.createTherapyGroup,
  editTherapyGroup: tgActions.editTherapyGroup,
  deleteTherapyGroup: tgActions.deleteTherapyGroup,
};

export default connect(mapStateToProps, actions)(TherapyGroups);
