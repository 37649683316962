import React, { useRef, useEffect, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import PropTypes from 'prop-types';

export const PureModal = ({
  children,
  headerContent,
  footerContent,
  visible,
  closeModal,
  additionalCssClasses,
}) => {
  const modalHeaderNode = useRef();
  const modalFooterNode = useRef();

  const [modalSectionHeight, setModalSectionHeight] = useState(null);

  useEffect(() => {
    if (modalHeaderNode.current && modalFooterNode.current) {
      const height = `calc(100% - (${modalFooterNode.current.offsetHeight}px + ${modalHeaderNode.current.offsetHeight}px))`;

      setModalSectionHeight(height);
    }
  }, [visible]);

  return (
    <>
      <div className={`overlay ${visible ? '' : 'hidden'}`}></div>
      <div
        className={`modal ${visible ? '' : 'hidden'} therapist-portal-modal ${
          additionalCssClasses ? additionalCssClasses : ''
        }`}
      >
        {headerContent && <header ref={modalHeaderNode}>{headerContent}</header>}
        {closeModal && (
          <button
            onClick={(e) => {
              if (e) {
                e.preventDefault();
              }
              closeModal();
            }}
            className='close'
          >
            &times;
          </button>
        )}
        <section className='therapist-portal-modal-section' style={{ height: modalSectionHeight }}>
          {children}
        </section>

        {footerContent && <footer ref={modalFooterNode}>{footerContent}</footer>}
      </div>
    </>
  );
};

PureModal.propTypes = {
  headerContent: PropTypes.element,
  children: PropTypes.element,
  footerContent: PropTypes.element,
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
};

export function awaitModal(modalFn, selector, values) {
  const container = document.querySelector(selector);

  const promise = new Promise((resolve, reject) => {
    const hideModal = () => {
      render(
        modalFn({
          onSubmit: onSubmit,
          onClose: onClose,
          visible: false,
          values: values,
        }),
        container
      );
      setTimeout(() => unmountComponentAtNode(container), 200);
    };

    const showModal = () =>
      render(
        modalFn({
          onSubmit: onSubmit,
          onClose: onClose,
          visible: true,
          values: values,
        }),
        container
      );

    const onSubmit = (v) => {
      hideModal();
      resolve(v);
    };
    const onClose = () => {
      hideModal();
      reject();
    };

    showModal();
  });

  return promise;
}

export default PureModal;
