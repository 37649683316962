/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as mainActions } from '../../../main/sagaSlice';
import _ from 'lodash';
import FormCheckbox from '+/forms/FormCheckbox';
import EditBox from '+/EditBox';
import { Form, Field } from 'react-final-form';
import { oonBillingInfo, paymentInfo, frequencyInfo } from './constants';

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {
  updateProvider: mainActions.updateProvider,
};

export const OtherSessionInfo = ({ provider, updateProvider }) => {
  const [editingOtherSessionInfo, setEditingOtherSessionInfo] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (provider) {
      setLoaded(true);
    }
  }, [provider]);

  const oonBillingInfoSet = oonBillingInfo.filter((c) => provider.custom_content[c.field] === true);

  const paymentInfoSet = paymentInfo.filter(
    (p) => _.get(provider, `custom_content.payment_methods[${p.key}]`) === true
  );

  const frequencyInfoSet = frequencyInfo.filter(
    (f) => _.get(provider, `custom_content.session_frequency[${f.key}]`) === true
  );

  const otherSessionInfoStatic = (
    <>
      {oonBillingInfoSet.length === 0 &&
      paymentInfoSet.length === 0 &&
      frequencyInfoSet.length === 0 ? (
        <div className='announcement quaternary'>
          Share your billing, payment, and session frequency options!
        </div>
      ) : (
        <div className='other-session-info'>
          <div className='m-b-md w-100 box'>
            <div className='header'>
              <h5 className='m-b-0'>Out-of-Network Billing</h5>
            </div>
            <p className='instruction'>What out-of-network billing options do you offer?</p>
            <ul>
              {oonBillingInfo.map(
                (c) => provider.custom_content[c.field] && <li key={c.field}>{c.bold}</li>
              )}
            </ul>
          </div>

          <div className='m-b-md w-49 box'>
            <div className='header'>
              <h5 className='m-b-0'>Payment Options</h5>
            </div>
            <p className='instruction'>What forms of payment do you accept?</p>
            <ul>
              {paymentInfo.map(
                (p) =>
                  _.get(provider, `custom_content.payment_methods[${p.key}]`) && (
                    <li key={p.key}>{p.name}</li>
                  )
              )}
            </ul>
          </div>

          <div className='m-b-md w-49 box'>
            <div className='header'>
              <h5 className='m-b-0'>Session Frequency</h5>
            </div>
            <p className='instruction'>
              How frequently can clients see you once they are an established client?
            </p>
            <ul>
              {frequencyInfo.map(
                (f) =>
                  _.get(provider, `custom_content.session_frequency[${f.key}]`) && (
                    <li key={f.key}>{f.name}</li>
                  )
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );

  const saveOtherSessionInfo = (values) => {
    const payload = {
      id: provider.id,
      custom_content: {
        session_frequency: {},
        payment_methods: {},
      },
    };

    oonBillingInfo.forEach((c) => {
      const checkboxValue = values[`oon_billing_info_${c.field}`];
      payload.custom_content[c.field] = checkboxValue;
    });

    frequencyInfo.forEach((f) => {
      const checkboxValue = values[`session_frequency_${f.key}`];
      payload.custom_content.session_frequency[f.key] = checkboxValue;
    });

    paymentInfo.forEach((p) => {
      const checkboxValue = values[`payment_methods_${p.key}`];
      payload.custom_content.payment_methods[p.key] = checkboxValue;
    });

    updateProvider(payload);
    setEditingOtherSessionInfo(false);
  };

  const computeInitialValues = () => {
    const initialValues = {};
    oonBillingInfo.forEach((c) => {
      initialValues[`oon_billing_info_${c.field}`] = provider.custom_content[c.field] === true;
    });

    frequencyInfo.forEach((f) => {
      initialValues[`session_frequency_${f.key}`] =
        _.get(provider, `custom_content.session_frequency[${f.key}]`) === true;
    });

    paymentInfo.forEach((p) => {
      initialValues[`payment_methods_${p.key}`] =
        _.get(provider, `custom_content.payment_methods[${p.key}]`) === true;
    });

    return initialValues;
  };

  const renderEditForm = () => {
    if (!loaded) {
      return '';
    }

    return (
      <Form
        initialValues={computeInitialValues()}
        onSubmit={saveOtherSessionInfo}
        render={(formProps) => (
          <>
            <div className='other-session-info'>
              <div className='oon-billing-edit-mode m-b-md w-100 box'>
                <div className='header'>
                  <h5 className='m-b-0'>Out-of-Network Billing</h5>
                </div>
                <p className='instruction'>What out-of-network billing options do you offer?</p>
                <div className='checkbox-container'>
                  {oonBillingInfo.map((c) => (
                    <>
                      <Field key={c.field} name={`oon_billing_info_${c.field}`}>
                        {(props) => (
                          <FormCheckbox
                            {...props}
                            label={
                              <>
                                <b>{c.bold}:</b>&nbsp;{c.text}
                              </>
                            }
                          />
                        )}
                      </Field>
                    </>
                  ))}
                </div>
              </div>

              <div className='m-b-md w-49 box'>
                <div className='header'>
                  <h5 className='m-b-0'>Payment Options</h5>
                </div>
                <p className='instruction'>What forms of payment do you accept?</p>
                <div className='checkbox-container'>
                  {paymentInfo.map((p) => (
                    <Field key={p.key} name={`payment_methods_${p.key}`}>
                      {(props) => (
                        <FormCheckbox fieldClassName='checkbox' {...props} label={p.name} />
                      )}
                    </Field>
                  ))}
                </div>
              </div>

              <div className='m-b-md w-49 box'>
                <div className='header'>
                  <h5 className='m-b-0'>Session Frequency</h5>
                </div>
                <p className='instruction'>
                  How frequently can clients see you once they are an established client?
                </p>
                <div className='checkbox-container'>
                  {frequencyInfo.map((f) => (
                    <Field key={f.key} name={`session_frequency_${f.key}`}>
                      {(props) => (
                        <FormCheckbox fieldClassName='checkbox' {...props} label={f.name} />
                      )}
                    </Field>
                  ))}
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                formProps.handleSubmit();
              }}
              className='primary'
            >
              Submit
            </button>
          </>
        )}
      />
    );
  };

  return (
    <EditBox
      title='Other Session Information'
      editable={true}
      editing={editingOtherSessionInfo}
      setEditing={setEditingOtherSessionInfo}
      innerStatic={otherSessionInfoStatic}
      innerEditing={renderEditForm()}
    />
  );
};

export default connect(mapStateToProps, actions)(OtherSessionInfo);
