import React from 'react';
import PureModal from '+/Modal';
import storage from '#/storage';

export default function AskIfClientLoginDesiredModal({ open, toggle }) {
  return (
    <PureModal
      visible={open}
      additionalCssClasses='shorter-modal'
      closeModal={() => toggle(false)}
      headerContent={<h4>Are you trying to sign up as a therapist?</h4>}
      footerContent={
        <div className='actions'>
          <button
            onClick={() => {
              toggle(false);
              window.location.href = '/signup-from-client';
            }}
            className='hollow'
          >
            Create new therapist profile
          </button>
          <button
            onClick={() => {
              toggle(false);
              // Remove auth to prevent white screen if they return to therapist portal by hitting back button.
              storage.rmv('auth');
              window.location.href = `${process.env.ZENCARE_WEBSITE_URL}/login`;
            }}
            className='primary'
          >
            Take me to client log in
          </button>
        </div>
      }
    >
      <h5>
        We found a client account with this email address. Would you like to add a therapist profile
        to your existing account?
      </h5>
    </PureModal>
  );
}
