import React from 'react';

interface Props {
  total: number;
  text: string;
  recurring: boolean;
  totalNotYetAvailable: boolean;
  totalNotYetAvailableText: string;
}

function SummaryTotal({
  total,
  text,
  recurring,
  totalNotYetAvailable,
  totalNotYetAvailableText,
}: Props) {
  const displayedTotal = Math.round(total * 100) / 100;
  return (
    <div className='payment-form-summary-total'>
      <p>{text}</p>
      <p
        className={`payment-form-summary-number ${
          totalNotYetAvailable ? 'payment-form-summary-number-loading' : ''
        }`}
      >
        {totalNotYetAvailable ? (
          totalNotYetAvailableText
        ) : (
          <>
            {/* Display negative sign before $ - used when displaying proration discount for legacy yearly => ZPM */}
            {displayedTotal >= 0 ? (
              <>${displayedTotal.toFixed(2)}</>
            ) : (
              <>-${(displayedTotal * -1).toFixed(2)}</>
            )}
          </>
        )}
        <span>{recurring ? ' / Month' : ''}</span>
      </p>
    </div>
  );
}

export default SummaryTotal;
