/** Get name of provider based on practice/individual */
export default function providerName(provider: Provider, firstNameOnly: boolean = false): string {
  if (!provider || !provider.type) {
    return '';
  }

  if (firstNameOnly) {
    return provider.type === 'individual' ? provider.first_name : provider.company_name;
  }

  return provider.type === 'individual'
    ? `${provider.first_name} ${provider.last_name}`
    : provider.company_name;
}
