interface FormValues {
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_postal_code: string;
}

interface ValidationErrors {
  [key: string]: string;
}

export const validateBillingAddress = (values: FormValues): ValidationErrors => {
  const errors: ValidationErrors = {};

  // Validation for address_line1
  if (!values.address_line1) {
    errors.address_line1 = 'Street address is required';
  }

  // Validation for address_line2 (assuming it's optional, so no validation here)

  // Validation for city
  if (!values.address_city) {
    errors.address_city = 'City is required';
  }

  // Validation for state
  if (!values.address_state) {
    errors.address_state = 'State is required';
  }

  // Validation for zip
  if (!values.address_postal_code) {
    errors.address_postal_code = 'Zip code is required';
  }

  return errors;
};
