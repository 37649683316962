import React, { useEffect } from 'react';
import SubscribeToPlanSeatBased from './SubscribeToPlanSeatBased';
import history from '#/history';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '+/forms/SectionLoader';
import { match } from 'react-router-dom';
import { dispatch as storeDispatch } from '~/store';
import { flashSuccess } from '~/shared/flashes/redux';
import { actions } from './sagaSlice';

function ConvertToMonthly({ match }: $TSFixMe) {
  const dispatch = useDispatch();
  const { subscriptionId } = match.params;
  const { subscriptions } = useSelector<{ main: $TSFixMe }, { subscriptions: Subscription[] }>(
    (state) => state.main
  );

  useEffect(() => {
    if (!subscriptions) return;
    const subscription = subscriptions.filter((s) => s.id === subscriptionId)[0];
    return dispatch(
      // @ts-ignore
      actions.setConvertToMonthlyData({
        stripeCustomerId: subscription.customer_id,
        stripeSubscriptionId: subscription.id,
        legacyProviderId: subscription.metadata.provider_id,
      })
    );
  }, [subscriptions]);

  // Go back to Plan Management if there's no id
  if (!subscriptionId.includes('sub_')) {
    // TODO: Is it worth comparing the subscriptionId param to all the account params?
    history.push('/plan-management');
  }

  const onSubscribed = () => {
    history.push('/plan-management');
    storeDispatch(flashSuccess('Success! You have switched from annual to monthly payments!'));
  };

  return (
    <>
      <div className='box'>
        <SubscribeToPlanSeatBased
          onSubscribed={onSubscribed}
          showStartForLine={true}
          showDoThisLater={false}
          doThisLaterClicked={null}
          provider={null}
          upgrade={true}
          title={'Switch to Monthly Payments'}
          // I don't know about this but it just looks weird blank
          subtitle={'Same Zencare benefits, now with more convenient payments.'}
          convertingToMonthly={true}
        />
      </div>
    </>
  );
}

export default ConvertToMonthly;
