import React from 'react';

export const CollectReferences = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      maxWidth: '1080px',
      margin: '0 auto',
    }}
  >
    <h1>Collect References</h1>

    <p>Below are links for collecting professional references.</p>

    <p>
      <strong>Please note this is optional.</strong> Most users place the heaviest importance on
      factors like the provider's message in the video, their specialty, and insurance. Providers
      who have strong professional endorsements receive just as many requests as those who do not
      have professional endorsements.
    </p>

    <p>
      The Codes of Ethics for certain professions recommend against soliciting testimonials from
      current and/or former clients. Please see below.
    </p>

    <a
      href='http://bit.ly/2fghDen'
      className='sqs-block-button-element--small sqs-block-button-element'
      target='_blank'
      rel='noopener noreferrer'
      data-initialized='true'
    >
      Professional endorsement form
    </a>

    <hr />

    <p>Relevant Codes of Ethics</p>

    <p>
      <em>
        "Counselors who use testimonials do not solicit them from current clients nor former clients
        nor any other persons who may be vulnerable to undue influence."
        <br />
        ACA Code of Ethics, 2014, C.3.b
        <br />
        <br />
        "Psychologists do not solicit testimonials from current therapy clients/patients or other
        persons who because of their particular circumstances are vulnerable to undue influence."
        <br />
        Ethical Principles of Psychologists and Code of Conduct, 2010, 5.05
        <br />
        <br />
        "Social workers should not engage in solicitation of testimonial endorsements(including
        solicitation of consent to use a client’s prior statement as a testimonial endorsement) from
        current clients or from other people who, because of their particular circumstances, are
        vulnerable to undue influence."
        <br />
        NASW Code of Ethics, 1996, Revised 2008, 4.07.b
      </em>
    </p>
  </div>
);
