/* eslint-disable max-lines-per-function */
import React from 'react';
import { formatMessageTime, formatAppointmentTime } from '~/utils/formatTime';

import { actions } from '../../sagaSlice';
import { useDispatch } from 'react-redux';

function Reschedule({ message }) {
  const {
    send_date,
    sender,
    read_at,
    message_content,
    appointment_info: { id, start_time },
  } = message;
  const dispatch = useDispatch();

  return (
    <div className={`message ${sender === 'provider' ? 'message_provider' : ''}`}>
      <div
        className={`message-bubble rich-content ${
          sender === 'provider' ? 'message-bubble_provider' : ''
        }`}
      >
        <div className='message-header-wrapper'>
          <button onClick={() => dispatch(actions.openAppointment({ id }))}>
            <h6>Phone Consultation Rescheduled</h6>
            <h6 className='appointment-date medium'>{`Your phone consultation has been rescheduled to ${formatAppointmentTime(
              start_time
            )}`}</h6>
          </button>
        </div>
        {message_content && (
          <>
            <hr />
            <div className='session-info'>
              <p>
                <span className='semi-bold'>Message: </span>
                {message_content}
              </p>
            </div>
          </>
        )}
        <div className='toggle-buttons'>
          <button className='manage-appt' onClick={() => dispatch(actions.openAppointment({ id }))}>
            Manage appointment
          </button>
        </div>
        {sender === 'client' && !read_at ? (
          <span className='message-unread-notification'></span>
        ) : null}
      </div>
      <small className='message-date'>Sent {formatMessageTime(send_date)}</small>
    </div>
  );
}

export default Reschedule;
