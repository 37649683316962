import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';

function ConversationList({ showConversation, setShowConversation }) {
  const ref = useRef();
  const { conversations, isLoadingConversations } = useSelector((state) => state.messages);
  const [inputValue, setInputValue] = useState('');
  const [displayedConversations, setDisplayedConversations] = useState([]);

  // Initialize displayed names
  useEffect(() => {
    if (!conversations) return;
    setDisplayedConversations(conversations);
  }, [conversations]);

  // This mess makes sure active conversations links are in view
  useEffect(() => {
    const activeLink = ref.current.querySelector('.message-link-active');
    if (activeLink) {
      // Conversation list dimensions
      const conversationListRect = ref.current.getBoundingClientRect();
      // Link dimensions
      const linkRect = activeLink.getBoundingClientRect();
      // If active link is out of view
      if (activeLink.offsetTop + linkRect.height > conversationListRect.bottom) {
        // Scroll to display active link in the middle of the conversation list
        ref.current.scrollTo({
          top:
            linkRect.top -
            conversationListRect.bottom +
            (conversationListRect.bottom - conversationListRect.top) / 2 +
            linkRect.height / 2,
        });
      }
    }
  }, [ref, conversations]);

  function handleInputChange(e) {
    const { value } = e.target;
    setInputValue(value);
    updateSearchResults(value);
  }

  function updateSearchResults(input) {
    if (input === '') {
      return setDisplayedConversations(conversations);
    }
    setDisplayedConversations((previous) =>
      previous.filter((conversation) => {
        const shortNameMatch =
          conversation.shortName &&
          conversation.shortName.toLowerCase().includes(input.toLowerCase());
        const fullNameMatch =
          conversation.fullName &&
          conversation.fullName.toLowerCase().includes(input.toLowerCase());
        return shortNameMatch || fullNameMatch;
      })
    );
  }

  return (
    <div className={`messages-list ${showConversation ? 'show-conversation' : ''}`}>
      <h4 className='title'> Messages</h4>
      <input
        id='search-messages'
        className='input'
        type='text'
        value={inputValue}
        onChange={handleInputChange}
        placeholder='Search by client name'
      />
      <ul className='message-link-list' ref={ref}>
        {isLoadingConversations ? (
          <div className='loader-container'>
            <Loader type='Oval' color='#37BEC3' height={50} width={50} />
          </div>
        ) : (
          displayedConversations.map((conversation) => (
            <li key={conversation.id} className='message-link-list-item'>
              <NavLink
                onClick={() => setShowConversation(true)}
                className={`message-link ${conversation.isUnread ? 'message-link-unread' : ''}`}
                activeClassName='message-link-active'
                to={`/messages/${conversation.id}`}
              >
                {conversation.fullName}
                {conversation.isUnread ? <i className='fas fa-circle unread-indicator'></i> : null}
              </NavLink>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

export default ConversationList;
