import { put, select, takeLatest, take, fork, cancel, delay } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
// @ts-ignore: Noop is not exported
import { failReducer, loadingReducer, notLoadingReducer, noop } from 'saga-slice-helpers';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import storage from '#/storage';
import history from '#/history';
import { api, addAuthorization, removeAuthorization, sagaApi } from '#/apis';
import { flashSuccess, flashError } from '+/flashes/redux';
import { getQueryAsObject } from '../../utils/queryParams';
import { $CombinedState } from 'redux';

import Cookies from 'js-cookie';

dayjs.extend(utc);

interface LoginSuccessResponseData {
  expiresAt: string;
  refreshBy: string;
  scope: string;
  authorization: string;
  user_id: number;
}

interface LoginSuccessResponse {
  data: LoginSuccessResponseData;
}

interface ErrorResponse {
  data?: {
    errorReservedName?: string;
  };
}

interface CustomError {
  response?: ErrorResponse;
  message?: string;
}

// Local storage auth
const lsAuth = storage.get('auth');

const initialState = {
  isSignedIn: false,
  askIfClientLoginDesired: false,
  token: null,
  roles: null,
  scope: null,
  lastLogin: null,
  authorization: null,
  settings: {},
  expiresAt: null,
  id: null,
  isLoading: false,
  error: null,
  isCheckingSessions: false,
  twoFactorCodeNeeded: false,
  phoneLastFour: '',
  phoneNumberSentCodeMatched: null,
  resetPasswordSuccess: false,
  resetTokenSuccess: false,
  formErrors: {},
  shouldRefreshSession: false,
  enrolledInTwoFactor: false,
  redirectToZpmStandalonePayment: false,
  practiceManagementClinicCreatorInfo: null,
};

if (lsAuth) {
  const expiry = dayjs.utc(lsAuth.expiresAt);
  const timeout = dayjs.utc(lsAuth.refreshBy);
  const now = dayjs().utc();

  if (now.isAfter(expiry) || now.isAfter(timeout)) {
    storage.rmv('auth');
    storage.rmv('current_provider_id');
  } else {
    // Add auth header to API
    addAuthorization(lsAuth.authorization);
  }
}

// Restore from local storage
const sagaSliceModule = createModule<AuthState>({
  name: 'auth',

  initialState: {
    ...initialState,
    ...(lsAuth || {}),
    isSignedIn: !!lsAuth,
  },
  reducers: {
    loginSuccess: (state, payload) => {
      // See if 2FA is turned on, if so, prompt for the 2FA code to be entered
      if (payload.twoFactorCodeNeeded === true) {
        Object.assign(state, {
          isLoading: false,
          twoFactorCodeNeeded: true,
          phoneLastFour: payload.phoneLastFour,
          error: null,
        });
      } else {
        if (payload.scope === 'client') {
          Object.assign(state, {
            askIfClientLoginDesired: true,
            isLoading: false,
            error: null,
          });
        } else if (payload.statusReservedName) {
          // ZPM Standalone handling
          if (payload.statusReservedName === 'ZPM_STANDALONE_NOT_PAID') {
            Object.assign(state, {
              redirectToZpmStandalonePayment: true,
              practiceManagementClinicCreatorInfo: payload,
              isLoading: false,
              error: null,
            });
          } else if (payload.statusReservedName === 'ZPM_STANDALONE_PAID') {
            Object.assign(state, {
              showZpmPortalLinkModal: true,
              practiceManagementSubdomain: payload.practiceManagementSubdomain,
              isLoading: false,
              error: null,
            });
          }
        } else {
          Object.assign(state, {
            ...payload,
            isLoading: false,
            isSignedIn: true,
            error: null,
          });

          addAuthorization(payload.authorization);
        }
      }
    },
    loginVerifyTwoFactorSuccess: (state, payload) => {
      Object.assign(state, {
        ...payload,
        isLoading: false,
        isSignedIn: true,
        error: null,
      });
      if (payload.scope === 'client') {
        Object.assign(state, {
          askIfClientLoginDesired: true,
          isLoading: false,
          error: null,
        });
      } else {
        addAuthorization(payload.authorization);

        Cookies.set('two_factor_remember_token', payload.two_factor_remember_token, {
          // Expire in 30 days
          expires: 30,
          sameSite: 'strict',
          secure: true,
        });
      }
    },
    setUserSettings: (state, payload) => {
      state.id = payload.id;
      state.email = payload.email;
      state.first_name = payload.first_name;
      state.last_name = payload.last_name;
      state.middle_name = payload.middle_name;
      state.roles = payload.roles;
      state.settings = payload.settings;
      state.username = payload.username;
      state.phone_number = payload.phone_number;
      state.enrolledInTwoFactor = payload.enrolledInTwoFactor;
    },

    logoutDone: (state) => {
      // eslint-disable-next-line no-unused-vars
      for (const key in initialState) {
        // @ts-ignore: TODO Fix this type
        state[key] = initialState[key];
      }
      state.isSignedIn = false;
      state.lsAuth = {};
    },

    logout: noop,
    logoutSuccess: notLoadingReducer,

    login: loadingReducer,
    loginVerifyTwoFactor: loadingReducer,

    loginFail: failReducer,

    loginFailWithErrorReservedName: (state, errorReservedName) => {
      state.loginErrorReservedName = errorReservedName;
    },

    loginDone: noop,

    logoutFail: failReducer,

    register: noop,
    registerSuccess: noop,
    registerFail: noop,
    registerDone: noop,

    confirm: noop,
    confirmSuccess: noop,
    confirmFail: noop,
    confirmDone: noop,

    requestReset: noop,
    requestResetSuccess: (state, payload) => {
      state.resetPasswordSuccess = true;
    },
    requestResetFail: (state, payload) => {
      state.resetPasswordError = _.get(payload, 'response.data.message');
    },
    requestResetDone: noop,

    recoverAccountEmail: (state, payload) => {
      state.recoverAccountEmailValue = null;
      state.recoverAccountEmailError = null;
    },
    recoverAccountEmailSuccess: (state, payload) => {
      state.recoverAccountEmailValue = payload;
    },
    recoverAccountEmailFail: (state, payload) => {
      state.recoverAccountEmailError = _.get(payload, 'response.data.message');
    },
    recoverAccountEmailDone: noop,

    resetPassword: noop,
    resetPasswordSuccess: noop,
    resetPasswordFail: noop,
    resetPasswordDone: noop,

    storeLocalAuth: noop,
    deleteLocalAuth: noop,

    checkResetToken: loadingReducer,
    checkResetTokenSuccess: (state, payload) => {
      state.resetTokenSuccess = true;
      state.isLoading = false;
    },
    checkResetTokenFail: (state, payload) => {
      state.resetTokenSuccess = false;
      state.isLoading = false;
    },
    checkResetTokenDone: noop,

    updatePassword: loadingReducer,
    updatePasswordSuccess: notLoadingReducer,
    updatePasswordFail: (state, payload) => {
      const { message, field } = payload.response.data;

      state.isLoading = false;

      if (field) {
        if (message.constructor === Array) {
          state.formErrors[field] = message.join(', ');
        } else {
          state.formErrors[field] = message;
        }
      }
    },
    updatePasswordDone: noop,

    updateEmail: loadingReducer,
    updateEmailSuccess: (state, payload) => {
      state.isLoading = false;
      state.emailError = null;
    },
    updateEmailFail: (state, payload) => {
      state.isLoading = false;
      state.emailError = payload.response.data.message;
    },
    updateEmailDone: noop,

    updateName: loadingReducer,
    updateNameSuccess: (state, payload) => {
      state.isLoading = false;
      state.nameError = null;
    },
    updateNameFail: (state, payload) => {
      state.isLoading = false;
      state.nameError = payload.response.data.message;
    },
    updateNameDone: noop,

    updatePhone: loadingReducer,
    updatePhoneSuccess: notLoadingReducer,
    updatePhoneFail: notLoadingReducer,

    disableTwoFactorEnrollment: loadingReducer,
    disableTwoFactorEnrollmentSuccess: notLoadingReducer,
    disableTwoFactorEnrollmentFail: notLoadingReducer,

    verifyNewPhoneNumber: loadingReducer,
    verifyNewPhoneNumberSuccess: (state, payload) => {
      state.isLoading = false;
      state.phoneLastFour = payload;
      state.phoneNumberSentCodeMatched = null;
    },
    verifyNewPhoneNumberFail: notLoadingReducer,

    finalizeTwoFactorEnrollment: loadingReducer,
    finalizeTwoFactorEnrollmentSuccess: (state, payload) => {
      state.isLoading = false;
      state.phoneNumberSentCodeMatched = payload;
    },
    finalizeTwoFactorEnrollmentFail: notLoadingReducer,

    // We need to constantly check for expired auth
    setCheckingSessions: (state, checking) => {
      state.isCheckingSessions = checking;
    },
    checkSession: noop,
    checkSessionStart: noop,
    checkSessionLoop: noop,
    checkSessionCancel: noop,
    checkSessionSuccess: noop,
    checkSessionFail: noop,
    checkSessionDone: noop,
    refreshSession: noop,
    refreshSessionSuccess: noop,
    refreshSessionFail: noop,
    setShouldRefreshSession: (state, payload) => {
      state.shouldRefreshSession = payload;
    },
    resetFormError: (state) => {
      state.formErrors = initialState.formErrors;
    },
  },
  // @ts-ignore: Structure of this saga is off. Come back to this.
  // eslint-disable-next-line max-lines-per-function
  sagas: (A) => {
    // Handle 401s to /auth/session
    const sagaApi401 = function* ({ payload }: $TSFixMe) {
      const {
        data,
        config: { method, url },
      } = payload;

      const isAuthSession = /\/auth\/session/.test(url);
      const invalidCredentials = /invalid credentials/i.test(data.message);
      const missingAuthentication = /Missing authentication/i.test(data.message);
      const noPermission = /permission/i.test(data.message);

      // Boolean checks that should generate a logout
      const cases = [
        // Check to auth session
        method === 'get' && isAuthSession,

        // When checking against API and have invalid credentials
        !isAuthSession && invalidCredentials,

        // When auth is not present in store
        !isAuthSession && missingAuthentication,

        !isAuthSession && noPermission,
      ];

      if (cases.includes(true)) {
        yield delay(500);
        yield put(A.logoutDone());
      }
    };

    const sagas = {
      [A.login]: {
        *saga({ payload }: $TSFixMe) {
          const newPayload = { ...payload };

          const two_factor_remember_token = Cookies.get('two_factor_remember_token');
          if (two_factor_remember_token) {
            newPayload.two_factor_remember_token = two_factor_remember_token;
          }

          try {
            const response: LoginSuccessResponse = yield api.put('/auth/provider', newPayload);
            yield put(A.loginSuccess(response.data));
            yield put(A.loginDone(response));
            // @ts-ignore
          } catch (e: CustomError) {
            if (e?.response?.data?.errorReservedName) {
              yield put(A.loginFailWithErrorReservedName(e.response.data.errorReservedName));
            }

            if (e?.response?.data?.message) {
              yield put(flashError(e?.response?.data?.message));
            } else {
              if (e?.message) {
                yield put(flashError(e.message));
              } else {
                yield put(flashError(e));
              }
            }
          }
        },
      },
      [A.loginVerifyTwoFactor]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.put(
            '/auth/provider-verify-2fa',
            payload,
            A.loginVerifyTwoFactorSuccess,
            A.loginFail
          );
        },
      },
      [A.loginDone]: function* ({ payload: { data } }: $TSFixMe) {
        // Make sure this is actually an auth token (might be better to switch this to checking each expected property!)
        if (data && !data.statusReservedName && !data.twoFactorCodeNeeded) {
          storage.set('auth', data);
        }
        yield;
      },
      [A.logout]: {
        *saga() {
          yield sagaApi.delete('/auth', null, A.logoutSuccess, A.logoutFail, A.logoutDone);
        },
      },
      [A.logoutDone]: {
        *saga({ payload = {} }: $TSFixMe) {
          const { route = true } = payload;

          yield put(A.deleteLocalAuth());
          removeAuthorization();
          yield put({ type: 'main/clearStore' });
          yield put(A.setCheckingSessions(false));
          yield put(A.checkSessionCancel());

          if (route) {
            const urlParams = getQueryAsObject(window.location.search);
            const email = urlParams.email;
            let search: string | $TSFixMe | undefined = undefined;
            if (email) {
              search = `?email=${email}`;
            }
            yield history.push({
              pathname: '/login',
              search,
              state: {
                from: {
                  pathname: window.location.pathname,
                  search: window.location.search,
                },
              },
            });
            yield delay(100);
            yield put(flashSuccess('you have been logged out'));
          }
        },
      },
      [A.deleteLocalAuth]: function* () {
        storage.rmv('auth');
        storage.rmv('current_provider_id');
        removeAuthorization();
      },
      [A.storeLocalAuth]: function* ({ payload }: $TSFixMe) {
        storage.set('auth', payload);
        addAuthorization(payload.authorization);
      },

      [A.register]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.post(
            '/auth/provider',
            payload,
            A.loginSuccess,
            A.registerFail,
            A.registerDone
          );
        },
      },
      [A.registerDone]: {
        *saga({ payload }: $TSFixMe) {
          console.log('registerDone, redirecting to /login');
          if (payload.data) yield history.push('/login');
          yield;
        },
      },

      [A.confirm]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.get(`/auth/confirm/${payload}`, A.confirmSuccess, noop, A.confirmDone);
        },
      },
      [A.confirmSuccess]: {
        *saga({ payload }: $TSFixMe) {
          console.log('confirmSuccess, redirecting to /login');
          if (payload.data) yield history.push('/login');
          yield;
        },
      },
      [A.confirmFail]: {
        *saga() {
          yield history.push('/');
        },
      },
      [A.checkResetToken]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.put(
            '/auth/password/reset/check',
            payload,
            A.checkResetTokenSuccess,
            A.checkResetTokenFail,
            A.checkResetTokenDone
          );
        },
      },
      [A.requestReset]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.post(
            '/auth/password/reset',
            payload,
            A.requestResetSuccess,
            A.requestResetFail,
            A.requestResetDone
          );
        },
      },
      [A.recoverAccountEmail]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.post(
            '/auth/email/recover',
            payload,
            A.recoverAccountEmailSuccess,
            A.recoverAccountEmailFail,
            A.recoverAccountEmailDone
          );
        },
      },
      [A.resetPassword]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.put(
            '/auth/password/reset',
            payload,
            A.resetPasswordSuccess,
            A.resetPasswordFail,
            A.resetPasswordDone
          );
        },
      },
      [A.resetPasswordSuccess]: {
        *saga() {
          yield history.push('/login');
        },
      },
      [A.updatePassword]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.post(
            'auth/password/update',
            payload,
            A.updatePasswordSuccess,
            A.updatePasswordFail
          );
        },
      },
      [A.updateEmail]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.put('auth/email/update', payload, A.updateEmailSuccess, A.updateEmailFail);
        },
      },
      [A.updateName]: {
        *saga({ payload }: $TSFixMe) {
          yield sagaApi.put('portal/me', payload, A.updateNameSuccess, A.updateNameFail);
        },
      },
      [A.updatePhone]: {
        *saga({ payload }: UpdatePhoneParamsParamBinding) {
          yield sagaApi.put(
            'portal/user/phone/update',
            payload,
            A.updatePhoneSuccess,
            A.updatePhoneFail
          );
        },
      },
      [A.updatePasswordSuccess]: {
        *saga({ payload }: $TSFixMe) {
          yield put(A.storeLocalAuth(payload));

          // yield delay(1000);
          yield history.push('/');

          yield delay(100);
          yield put(flashSuccess('Your password has been successfully reset.'));
        },
      },
      [A.updateEmailSuccess]: {
        *saga({ payload }: $TSFixMe) {
          yield put(A.storeLocalAuth(payload));

          yield put({
            type: 'main/getMe',
          });
          yield delay(100);
          yield history.push('/account-preferences');
          yield put(flashSuccess('Your email has been successfully changed.'));
        },
      },
      [A.updateNameSuccess]: {
        *saga() {
          yield put({
            type: 'main/getMe',
          });
          yield take(['main/getMeSuccess', 'main/getAllMyProvidersSuccess']);
          yield put(flashSuccess('Your name has been successfully changed.'));
        },
      },
      [A.updatePhoneSuccess]: {
        *saga() {
          yield put({
            type: 'main/getMe',
          });
          yield take(['main/getMeSuccess', 'main/getAllMyProvidersSuccess']);
          yield put(flashSuccess('Your phone number has been successfully changed.'));
        },
      },
      [A.verifyNewPhoneNumber]: {
        *saga({ payload }: VerifyNewPhoneNumberParamBinding) {
          yield sagaApi.post(
            '/portal/user/phone/verify',
            payload,
            A.verifyNewPhoneNumberSuccess,
            A.verifyNewPhoneNumberFail
          );
        },
      },
      [A.finalizeTwoFactorEnrollment]: {
        *saga({ payload }: FinalizeTwoFactorEnrollmentParamBinding) {
          yield sagaApi.post(
            '/portal/user/phone/finalize-2fa-enrollment',
            payload,
            A.finalizeTwoFactorEnrollmentSuccess,
            A.finalizeTwoFactorEnrollmentFail
          );
        },
      },
      [A.finalizeTwoFactorEnrollmentSuccess]: {
        *saga() {
          yield put({
            type: 'main/getMe',
          });
        },
      },
      [A.disableTwoFactorEnrollment]: {
        *saga() {
          yield sagaApi.post(
            '/portal/user/phone/disable-2fa-enrollment',
            null,
            A.disableTwoFactorEnrollmentSuccess,
            A.disableTwoFactorEnrollmentFail
          );
        },
      },
      [A.disableTwoFactorEnrollmentSuccess]: {
        *saga() {
          yield put({
            type: 'main/getMe',
          });
          yield put(flashSuccess('You have sucessfully unenrolled from 2FA.'));
        },
      },
      [A.checkSession]: {
        *saga() {
          yield sagaApi.get(
            '/auth/session',
            A.checkSessionSuccess,
            A.checkSessionFail,
            A.checkSessionDone
          );
        },
        taker: takeLatest,
      },
      [A.checkSessionFail]: {
        *saga() {
          yield put(A.logout());
        },
      },
      [A.refreshSession]: {
        *saga() {
          yield sagaApi.post(
            '/auth/session/refresh',
            undefined,
            A.refreshSessionSuccess,
            A.logoutDone
          );
        },
        taker: takeLatest,
      },
      [A.refreshSessionSuccess]: {
        *saga({ payload }: $TSFixMe) {
          storage.set('auth', payload);
          addAuthorization(payload.authorization);
          yield put(A.setShouldRefreshSession(false));
        },
        taker: takeLatest,
      },

      /**
       * Asserts that session is valid.
       * Checks session every 60 seconds.
       * Checks if session is expired.
       * If any case is true, trigger a logout
       */
      [A.checkSessionLoop]: {
        *saga() {
          const isCheckingSessions: boolean = yield select(
            (state: State) => state.auth.isCheckingSessions
          );
          if (isCheckingSessions) {
            return;
          }

          yield put(A.setCheckingSessions(true));
          let mod = 0;

          try {
            while (true) {
              yield delay(1000);

              mod = (mod + 1) % 5;

              const { isSignedIn, shouldRefreshSession } = yield select((state) => state.auth);

              if (!isSignedIn) {
                yield put(A.checkSessionCancel());
                break;
              }

              if (mod === 4 && shouldRefreshSession) {
                yield put(A.refreshSession());
              } else {
                yield put(A.checkSession());
              }

              yield delay(1 * 60 * 1000);
            }
          } finally {
          }
        },
      },
    };

    const checkSessionStart = function* () {
      const isCheckingSessions: boolean = yield select(
        (state: State) => state.auth.isCheckingSessions
      );
      if (isCheckingSessions) {
        return;
      }

      yield put(A.checkSessionCancel());

      // @ts-ignore: TODO Fix this type
      const recur: $TSFixMe = yield fork(sagas[A.checkSessionLoop].saga, {});

      yield take(A.checkSessionCancel.type);
      yield cancel(recur);
    };

    sagas[A.checkSessionStart] = {
      // @ts-ignore: TODO Fix this type
      saga: checkSessionStart,
      // @ts-ignore: TODO Fix this type
      taker: takeLatest,
    };

    // @ts-ignore: TODO Fix this type
    sagas['sagaApi/401'] = {
      saga: sagaApi401,
      taker: takeLatest,
    };

    return sagas;
  },
});

interface VerifyNewPhoneNumberParams {
  phone_number: string;
}

interface VerifyNewPhoneNumberParamBinding {
  payload: VerifyNewPhoneNumberParams;
}

interface FinalizeTwoFactorEnrollmentParams {
  token: string;
}

interface FinalizeTwoFactorEnrollmentParamBinding {
  payload: FinalizeTwoFactorEnrollmentParams;
}

interface UpdatePhoneParams {
  phone_number: string;
}

interface UpdatePhoneParamsParamBinding {
  payload: UpdatePhoneParams;
}

interface AuthActions {
  actions: {
    /**
     * Demonstration detailed documentation
     * @description pass an object containing a provider's ID to adjust the provider in scope
     */
    checkSessionStart: () => void;
    recoverAccountEmail: () => void;
    logout: () => void;
    login: () => void;
    loginVerifyTwoFactor: () => void;
    updatePhone: (parms: UpdatePhoneParams) => void;
    disableTwoFactorEnrollment: () => void;
    verifyNewPhoneNumber: (params: VerifyNewPhoneNumberParams) => void;
    finalizeTwoFactorEnrollment: (params: FinalizeTwoFactorEnrollmentParams) => void;
  };
}

// @ts-ignore
export const { actions }: AuthActions = sagaSliceModule;
export default sagaSliceModule;
