import constants from '../../components/payment/seat-based/constants';

// @ts-check
const basic = 'basic';
const professional = 'professional';
const premium = 'premium';

// NOTE: Server has a similar method
const seatBasedComputeLookupKey = function (new_tier: string, isNyOrCa: boolean): string {
  let computedLookupKey = '';

  // Note: I could compute a string value but prefer to use consts
  // for easier search/replace in codebase.
  if (isNyOrCa) {
    // NY/CA prices
    if (new_tier === basic) {
      computedLookupKey = constants.subscriptions_individual_monthly.ny.lookup_key;
    } else if (new_tier === professional) {
      computedLookupKey = constants.subscriptions_individual_monthly_professional.ny.lookup_key;
    } else if (new_tier === premium) {
      computedLookupKey = constants.subscriptions_individual_monthly_premium.ny.lookup_key;
    }
  } else {
    // Non-NY/CA prices
    if (new_tier === basic) {
      computedLookupKey = constants.subscriptions_individual_monthly.standard.lookup_key;
    } else if (new_tier === professional) {
      computedLookupKey =
        constants.subscriptions_individual_monthly_professional.standard.lookup_key;
    } else if (new_tier === premium) {
      computedLookupKey = constants.subscriptions_individual_monthly_premium.standard.lookup_key;
    }
  }

  return computedLookupKey;
};

export default seatBasedComputeLookupKey;
