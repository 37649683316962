import DefaultLayout from '##/Default';

import { BlogFeatures } from './BlogFeatures';
import ReferAndEarn from './ReferAndEarn';
import { CollectReferences } from './CollectReferences';
import { ListServ } from './ListServ';
import { IncreasingReferrals } from './IncreasingReferrals';
import { MembershipTips } from './MembershipTips';
import { Partnerships } from './Partnerships';
import { PhoneConsultations } from './PhoneConsultations';
import { PhotoLicensing } from './PhotoLicensing';
import VideoEmbedding from './VideoEmbedding';
import ContactZencare from '../support-flow/ContactZencare';
import { ClientMatching } from './ClientMatching';
import ProviderCommunity from './ProviderCommunity';
import PhotoVideoshoot from './PhotoVideoshoot';
import PhotoVideoshootIframePage from './PhotoVideoshootIframePage';
import FeeCalculatorContianer from './FeeCalculatorContainer';
import WebBadge from './WebBadge';
import HelpDesk from './HelpDesk';

// TODO Some of these optional properties shouldn't be optional
export interface StaticPageRoute extends BaseRoute {
  requireSubscription?: boolean;
  auth?: boolean;
  layoutClass?: string;
  noFooter?: true;
}

const routes: Array<StaticPageRoute> = [
  {
    path: '/benefits/collect-references',
    exact: true,
    layout: DefaultLayout,
    component: CollectReferences,
    title: 'Collect References',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/marketing/collect-references',
    redirect: '/benefits/collect-references',
  },
  {
    path: '/benefits/blog-features',
    exact: true,
    layout: DefaultLayout,
    component: BlogFeatures,
    title: 'Blog Features',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/marketing/blog-features',
    redirect: '/benefits/blog-features',
  },
  {
    path: '/benefits/web-badge',
    exact: true,
    layout: DefaultLayout,
    component: WebBadge,
    title: 'Web Badge',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/marketing/web-badge',
    redirect: '/benefits/web-badge',
  },
  {
    path: '/benefits/photo-licensing',
    exact: true,
    layout: DefaultLayout,
    component: PhotoLicensing,
    title: 'Photo Licensing',
    auth: true,
  },
  {
    path: '/marketing/photo-licensing',
    redirect: '/benefits/photo-licensing',
  },
  {
    path: '/benefits/video-embedding',
    exact: true,
    layout: DefaultLayout,
    component: VideoEmbedding,
    title: 'Video Embedding',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/marketing/video-embedding',
    redirect: '/benefits/video-embedding',
  },
  {
    path: '/benefits/partnerships',
    exact: true,
    layout: DefaultLayout,
    component: Partnerships,
    title: 'Partnerships',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/marketing/partnerships',
    redirect: '/benefits/partnerships',
  },
  {
    path: '/tips/phone-consultations',
    exact: true,
    layout: DefaultLayout,
    component: PhoneConsultations,
    title: 'Phone Consultations',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/tips/increasing-referrals',
    exact: true,
    layout: DefaultLayout,
    component: IncreasingReferrals,
    title: 'Increasing Referrals',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/tips/membership',
    exact: true,
    layout: DefaultLayout,
    component: MembershipTips,
    title: 'Membership Tips',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/listserv',
    exact: true,
    layout: DefaultLayout,
    component: ListServ,
    title: 'Listserv',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/refer-and-earn',
    exact: true,
    layout: DefaultLayout,
    component: ReferAndEarn,
    title: 'Refer and Earn',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/client-matching',
    redirect: '/benefits/client-matching',
  },
  {
    path: '/benefits/client-matching',
    exact: true,
    layout: DefaultLayout,
    component: ClientMatching,
    title: 'Client Matching',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/benefits/provider-community',
    exact: true,
    layout: DefaultLayout,
    component: ProviderCommunity,
    title: 'Provider Community',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/benefits/photo-videoshoot',
    exact: true,
    layout: DefaultLayout,
    component: PhotoVideoshoot,
    title: 'Photo/Videoshoot',
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/benefits/photo-videoshoot/:type',
    exact: true,
    layout: DefaultLayout,
    component: PhotoVideoshootIframePage,
    title: 'Photo/Videoshoot',
    layoutClass: 'h-100 flex',
    noFooter: true,
    requireSubscription: true,
    auth: true,
  },
  {
    path: '/provider-community',
    redirect: '/benefits/provider-community',
    auth: true,
  },
  {
    path: '/fee-calculator',
    exact: true,
    layout: DefaultLayout,
    component: FeeCalculatorContianer,
    title: 'Fee Calculator',
    layoutClass: 'h-100 flex',
    noFooter: true,
    auth: true,
  },
  {
    path: '/contact',
    exact: true,
    layout: DefaultLayout,
    component: ContactZencare,
    title: 'Contact Zencare Team',
    auth: true,
  },
  {
    path: '/help-desk',
    exact: true,
    layout: DefaultLayout,
    component: HelpDesk,
    title: 'Help Desk',
    auth: true,
    layoutClass: 'p-0',
  },
];

export default routes;
