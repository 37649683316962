import _ from 'lodash';

const ctaItems = {
  photovideoshoot: {
    cardName: 'Schedule a Photo/Videoshoot',
    cardDescription:
      'Did you know that clients who view a video of a provider are four times more likely to reach out? Professional photos and video can go a long way in helping a client get a sense of your approach and style, find the right fit, and feel encouraged to reach out! Book your photo or videoshoot with us; and if you\'ve already had yours or prefer to hold off, you can simply mark "Done."',
    cardLink: '/benefits/photo-videoshoot',
    cardLinkText: 'Book now',
    dismissText: 'Done',
    providerDisplayFn: (provider) => provider && provider.hasPaidPlan,
  },
  insuranceOptOuts: {
    cardName: 'Indicate Insurance Per State',
    cardDescription:
      'Don’t take insurance in all the states where you practice? You can indicate insurance by state! Let your clients know!',
    cardLink: '/profile/insurance-fees#insurance-by-state',
    cardLinkText: 'Edit insurance',
    dismissText: 'Done',
    providerDisplayFn: (provider) =>
      provider &&
      provider.insurances &&
      provider.insurances.length &&
      _.uniq(provider.locations.map((x) => x.state)).length > 1 &&
      provider.insurance_opt_outs.length < 1,
  },
};

export default ctaItems;
