import { put } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer } from 'saga-slice-helpers';
import { api } from '#/apis';
import { flashSuccess, flashError } from '+/flashes/redux';
import { redirectToReimbursify } from './reimbursifyUtils';

interface ReimbursifyGenerateSsoJwtSagaParamBinding {
  payload: ReimbursifyGenerateSsoJwtSagaParam;
}

interface CheckIfStandaloneReimbursifyAccountAlreadyExistsSagaParamBinding {
  payload: CheckIfStandaloneReimbursifyAccountAlreadyExistsSagaParam;
}

interface RunPendingVobSagaParamBinding {
  payload: RunPendingVobSagaParam;
}

const sagaSliceModule = createModule({
  name: 'reimbursify',
  initialState: {
    isLoading: false,
  },
  reducers: {
    reimbursifyGenerateSsoJwt: loadingReducer,
    reimbursifyGenerateSsoJwtFailure: failReducer,
    reimbursifyGenerateSsoJwtSuccess: (state) => {
      state.isLoading = false;
    },

    checkIfStandaloneReimbursifyAccountAlreadyExists: loadingReducer,
    checkIfStandaloneReimbursifyAccountAlreadyExistsFailure: failReducer,
    checkIfStandaloneReimbursifyAccountAlreadyExistsSuccess: (state) => {
      state.isLoading = false;
    },

    runPendingVob: loadingReducer,
    runPendingVobSuccess: (state) => {
      state.isLoading = false;
    },
    runPendingVobFailure: failReducer,
  },

  sagas: (A) => {
    const sagas = {
      [A.reimbursifyGenerateSsoJwt]: function* ({
        payload: { params, onSuccess },
      }: ReimbursifyGenerateSsoJwtSagaParamBinding) {
        const { provider_id } = params;

        try {
          const { data } = yield api.post(
            `/portal/reimbursify/generate-sso-token/${provider_id}`,
            {},
            A.reimbursifyGenerateSsoJwtSuccess,
            A.reimbursifyGenerateSsoJwtFailure
          );

          yield put(A.reimbursifyGenerateSsoJwtSuccess());
          if (onSuccess) {
            onSuccess(data);
          }
        } catch (error) {
          alert(`Sorry, we ran into an error. Please contact support: ${error}`);
          yield put(A.reimbursifyGenerateSsoJwtFailure());
        }
      },
      [A.checkIfStandaloneReimbursifyAccountAlreadyExists]: function* ({
        payload: { params, onSuccess },
      }: CheckIfStandaloneReimbursifyAccountAlreadyExistsSagaParamBinding) {
        const { provider_id } = params;

        try {
          const { data } = yield api.get(
            `/portal/reimbursify/check-if-standalone-reimbursify-account-already-exists/${provider_id}`,
            A.checkIfStandaloneReimbursifyAccountAlreadyExistsSuccess,
            A.checkIfStandaloneReimbursifyAccountAlreadyExistsFailure
          );

          yield put(A.checkIfStandaloneReimbursifyAccountAlreadyExistsSuccess());
          if (onSuccess) {
            onSuccess(data.alreadyHasStandaloneReimbursifyAccount);
          }
        } catch (error) {
          alert(
            `Sorry, we ran into an error. Error checking for existing standalone non-SSO account. Please contact support: ${error}`
          );
          yield put(A.checkIfStandaloneReimbursifyAccountAlreadyExistsFailure());
        }
      },
      [A.runPendingVob]: function* ({
        payload: { user_vob_id, provider_id, user_id },
      }: RunPendingVobSagaParamBinding) {
        try {
          yield api.post(
            `/portal/vob/rerun-vob/${user_vob_id}`,
            {},
            A.runPendingVobSuccess,
            A.runPendingVobFailure
          );
          // Reload conversations and conversation messages
          yield put({
            type: 'messages/getConversationMessages',
            payload: {
              provider_id,
              client_user_id: user_id,
            },
          });
        } catch (error) {
          alert(`Sorry, we ran into an error. Please contact support: ${error}`);
          yield put(A.reimbursifyGenerateSsoJwtFailure());
        }
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
