import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { actions as mainActions } from '../../main/sagaSlice';
import { dispatch } from '~/store';
import SubmitButton from '+/forms/SubmitButton';
import Loader from '+/forms/SectionLoader';
import FormCheckbox from '+/forms/FormCheckbox';
import tagTypeToPlural from './tagTypeToPlural';
import ReactTooltip from 'react-tooltip';

const { flashError } = require('~/shared/flashes/redux');

const TaggingCheckboxes = ({
  tagKey,
  tagData,
  provider,
  minSelections = 1,
  maxSelections = 20,
  updateMultipleTaggings,
  setEditing,
  readOnlyTagNames = [],
  disabledTooltip = '',
}) => {
  const onSubmit = (values) => {
    const selected = Object.values(values).filter((v) => v);
    if (selected.length < minSelections) {
      dispatch(
        flashError(
          `You must have at least ${minSelections} selection${minSelections === 1 ? '' : 's'}.`
        )
      );

      return;
    }

    if (selected.length > maxSelections) {
      dispatch(
        flashError(
          `You've reached the maximum number of selections (${maxSelections}). Please remove other selections to add more.`
        )
      );

      return;
    }

    const updates = [];
    Object.keys(values).forEach((k) => {
      const providerIsTagged = provider[tagKey] && provider[tagKey].find((x) => x.slug === k);
      if ((values[k] && !providerIsTagged) || (!values[k] && providerIsTagged)) {
        const tag = tagData.find((t) => t.slug === k);
        updates.push({
          tagTypePlural: tagTypeToPlural(tag.type),
          tagId: tag.id,
          associate: values[k],
        });
      }
    });
    updateMultipleTaggings({
      providerId: provider.id,
      entries: updates,
    });

    setEditing(false);
  };

  return Array.isArray(tagData) ? (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={tagData.reduce((acc, cur) => {
          acc[cur.slug] =
            provider[tagKey] && provider[tagKey].find((x) => x.id === cur.id) !== undefined;

          return acc;
        }, {})}
        initialValuesEqual={() => true}
        render={({ handleSubmit, pristine, valid }) => (
          <>
            <div className='select-items'>
              {tagData.map((a) => {
                // Don't allow unchecking if already checked and part of top specialities
                if (
                  provider[tagKey] &&
                  provider[tagKey].find((x) => x.id === a.id) !== undefined &&
                  readOnlyTagNames.includes(a.name.trim())
                ) {
                  return (
                    <Field key={a.slug} name={a.slug}>
                      {(props) => (
                        <FormCheckbox
                          {...props}
                          disabled={true}
                          disabledTooltip={disabledTooltip}
                          label={a.name}
                        />
                      )}
                    </Field>
                  );
                } else {
                  return (
                    <Field key={a.slug} name={a.slug}>
                      {(props) => <FormCheckbox {...props} label={a.name} />}
                    </Field>
                  );
                }
              })}
            </div>
            <SubmitButton pristine={pristine} valid={valid} handleSubmit={handleSubmit} />
          </>
        )}
      />{' '}
      <ReactTooltip
        id='checkbox-tooltip'
        effect='solid'
        place='right'
        type='light'
        arrowColor='#37BEC3'
      ></ReactTooltip>
    </>
  ) : (
    Loader()
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {
  updateMultipleTaggings: mainActions.updateMultipleTaggings,
};

export default connect(mapStateToProps, actions)(TaggingCheckboxes);
