import React, { useState } from 'react';
import _ from 'lodash';
import { PickerOverlay as ReactFilestack } from 'filestack-react';
import { dispatch } from '~/store';
import { flashError } from '~/shared/flashes/redux';

export default (props) => {
  const [picking, setPicking] = useState(false);

  const {
    providerId,
    logEvent,
    preventGrayscale,
    minSizeInKB,
    pickerOptions = {
      maxFiles: 1,
    },
    onSuccess = () => undefined,
    onError = () => undefined,
    containerClass = '',
    buttonClass = 'pill primary md',
    buttonText = 'Upload photo',
  } = props;

  return (
    <div className={containerClass}>
      <button
        className={buttonClass}
        onClick={() => {
          setPicking(true);
        }}
      >
        {buttonText}
      </button>
      {picking && (
        <ReactFilestack
          apikey={process.env.ZENCARE_FILESTACK_API_KEY}
          onSuccess={(response) => {
            setPicking(false);
            const pickProps = (image) =>
              _.pick(image, ['filename', 'handle', 'key', 'mimetype', 'size', 'url']);

            if (pickerOptions.maxFiles && pickerOptions.maxFiles > 1) {
              return onSuccess(response.filesUploaded.map(pickProps));
            }

            return onSuccess(pickProps(response.filesUploaded[0]));
          }}
          onError={(err) => {
            setPicking(false);
            dispatch(flashError(err));
            onError();
          }}
          pickerOptions={{
            onFileSelected: async (file) =>
              new Promise(async (resolve, reject) => {
                if (minSizeInKB && file.size < minSizeInKB * 1000) {
                  const error = `Please select a higher quality photo (at least ${minSizeInKB}kb)`;

                  if (providerId && logEvent) {
                    logEvent({
                      type: 'image-is-too-small',
                      data: {
                        file_size: file.size,
                        minSizeInKB,
                      },
                      provider_id: providerId,
                    });
                  }

                  // Removed to prevent duplicate errors
                  // dispatch(flashError(error));

                  // OPS-892: Sent the error string into this reject call to prevent filestack from sending errors on to bugsnag
                  // Remove "error" from this reject call to remove the filestack error message popup that shows in the top of the screen.
                  // without this though, there are noisy error logs in bugsnag and the console that are not helpful to the user or developer
                  return reject(error);
                }

                if (!preventGrayscale) {
                  return resolve();
                }

                const image = new Image();
                const buffer = await file.originalFile.arrayBuffer();
                const bytes = new Uint8Array(buffer);
                const blob = new Blob([bytes], { type: 'image/jpeg' });
                const urlCreator = window.URL || window.webkitURL;
                const src = urlCreator.createObjectURL(blob);
                image.src = src;

                image.onload = () => {
                  const canvas = document.createElement('canvas');

                  const cleanup = () => {
                    canvas.remove();
                    image.remove();
                    urlCreator.revokeObjectURL(src);
                  };

                  const ctx = canvas.getContext('2d');
                  ctx.drawImage(image, 0, 0);
                  const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                  const data = imgData.data;
                  cleanup();

                  if (imageIsGrayscale(data)) {
                    const error =
                      "We've detected that the image you uploaded is black-and-white or grayscale. Please upload a more colorful picture!";

                    if (providerId && logEvent) {
                      logEvent({
                        type: 'image-is-grayscale',
                        data: {},
                        provider_id: providerId,
                      });
                    }
                    // Removed to prevent duplicate errors
                    // dispatch(flashError(error));

                    // OPS-892: Sent the error string into this reject call to prevent filestack from sending errors on to bugsnag
                    // Remove "error" from this reject call to remove the filestack error message popup that shows in the top of the screen.
                    // without this though, there are noisy error logs in bugsnag and the console that are not helpful to the user or developer
                    return reject(error);
                  }

                  return resolve();
                };
              }),
            onClose: () => setPicking(false),
            onCancel: () => setPicking(false),
            transformations: {
              crop: true,
              circle: false,
              rotate: false,
            },
            fromSources: ['local_file_system', 'googledrive'],
            exposeOriginalFile: true,
            uploadInBackground: false,
            accept: 'image/*',
            ...pickerOptions,
          }}
        />
      )}
    </div>
  );
};

/*
 * Given image data from a canvas, returns true if the image is grayscale or black-and-white and false otherwise.
 * This is done by comparing the rgb values for each pixel. For each pixel,
 * if the difference between any of the rgb values is greater than 2,
 * return false. Otherwise return true. 2 is chosen as a kind of fuzzy threshold
 * nb some false positives with 2, changing to 1
 */
function imageIsGrayscale(data) {
  const threshold = 1;

  // We add 4 because the data is RGBA; we don't care about the A here.
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];

    if (Math.abs(r - g) > threshold || Math.abs(r - b) > threshold || Math.abs(g - b) > threshold) {
      return false;
    }
  }

  return true;
}
