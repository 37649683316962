import React, { useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

export const VideoEmbedding = ({ provider }) => {
  const [textVideoButton, setVideoButton] = useState('Copy Video Link!');
  const [textEmbedButton, setEmbedButton] = useState('Copy Video Link!');

  const vimeoUrl = get(provider, 'custom_content.videos[0]', '');
  const vimeoId = vimeoUrl.split('/').pop();

  const onCopyVideo = () => {
    const el = document.createElement('textarea');
    el.value = document.getElementById('codeVideo').innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setVideoButton('Copied!');
    setEmbedButton('Copy Video Link!!');
  };

  const onCopyEmbed = () => {
    const el = document.createElement('textarea');
    el.value = document.getElementById('codeEmbed').innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setEmbedButton('Copied!');
    setVideoButton('Copy Video Link!!');
  };

  return (
    <div
      id='static-page'
      className='main-content video-embed'
      style={{
        maxWidth: '1080px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <h1>Embed Your Video</h1>
      <p>
        Embed your Zencare video on your personal website! You can use the video link or embed code
        below to add the video to your personal website. See below for instructions per website
        creation platform.
      </p>

      <h3>Your Video</h3>
      <div className='m-b-lg'>
        <iframe
          title='uservideo'
          src={`https://player.vimeo.com/video/${vimeoId}`}
          width='640'
          height='360'
          frameborder='0'
          allow='autoplay; fullscreen'
          allowfullscreen
        ></iframe>
      </div>

      <h3>Video Link</h3>
      <div className='m-b-lg'>
        <code
          id='codeVideo'
          className='preview-area__code scroll-x p-sm'
        >{`https://player.vimeo.com/video/${vimeoId}`}</code>
        <button onClick={onCopyVideo} className='pill primary lg'>
          {textVideoButton}
        </button>
      </div>

      <h3>Video Embed Code</h3>
      <code id='codeEmbed' className='preview-area__code scroll-x p-sm'>
        &lt;iframe src="{`https://player.vimeo.com/video/${vimeoId}`}" width="640" height="360"
        frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""&gt;
      </code>
      <button onClick={onCopyEmbed} className='pill primary lg m-b-lg'>
        {textEmbedButton}
      </button>

      <h3>How to Embed Your Video</h3>
      <ul>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://support.squarespace.com/hc/en-us/articles/206544187'
          >
            Squarespace
          </a>
        </li>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://support.wix.com/en/article/wix-editor-adding-and-setting-up-the-single-video-player'
          >
            Wix
          </a>
        </li>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://wordpress.com/support/videos/vimeo/'
          >
            Wordpress
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer' href='mailto:support@brightervision.com'>
            Brightervision (email the support team)
          </a>
        </li>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://my.therapysites.com/support/index.php/kb/article/29-How-do-I-add-multimedia-to-my-website-(audio-or-video)'
          >
            TherapySites
          </a>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = ({ auth, main }) => ({
  provider: main.provider,
});

export default connect(mapStateToProps, null)(VideoEmbedding);
