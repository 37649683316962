import * as React from 'react';
import InsuranceDetailsContainer from './InsuranceDetailsContainer';
import VobDeepLink from './VobDeepLink';
import SampleVobMessage from './SampleVobMessage';

type Props = {
  message: Message;
  textAreaInput: React.RefObject<HTMLTextAreaElement>;
};

function VobIntakeMessageFooter({ message, textAreaInput }: Props) {
  const { vob_data } = message;

  if (!vob_data) {
    return null;
  }

  return (
    <div className='vob-intake-message-footer'>
      <hr />
      <h5>Insurance Details</h5>
      <p className='footer-subtitle'>
        This client submitted insurance details to see if they have out-of-network benefits.
      </p>
      <InsuranceDetailsContainer message={message} />
      <VobDeepLink message={message} textAreaInput={textAreaInput} />
      <SampleVobMessage vobData={vob_data} />
    </div>
  );
}

export default VobIntakeMessageFooter;
