import React from 'react';

export const Partnerships = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      maxWidth: '1080px',
      margin: '0 auto',
    }}
  >
    <h1 className='title m-b-lg'>Partnerships</h1>
    <p className='m-b-sm'>
      We’re proud to partner with these companies, who generously offer discounts for Zencare
      members!{' '}
    </p>

    <div className='subsection m-t-sm m-b-md'>
      <h4 className='subtitle m-b-sm'>TheraNest</h4>
      <a href='http://www.theranest.com/partnerships-zencare/'>
        <img
          src='https://images.squarespace-cdn.com/content/v1/563a0909e4b03923f8ec4b09/1561661740791-NK2EK3HD991AWCL9RZSL/ke17ZwdGBToddI8pDm48kI_S-3bilax7tywFZXUMCzYUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcWLDNGxorRdAgf47mfm-41KmveqoSf2T3bOPaK5xDcc4ZxnCQOaowHhKRS2leNNez/TheraNest+logo'
          alt='TheraNest logo'
          style={{
            width: '100%',
            objectFit: 'contain',
            maxHeight: '600px',
            objectPosition: 'center',
            marginBottom: '24px',
          }}
        />
      </a>
      <p className='m-b-sm'>
        <a
          href='http://www.theranest.com/partnerships-zencare/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <strong>TheraNest </strong>
        </a>
        is a therapy practice management software with a dedicated client portal. It is designed for
        practitioners including social workers, psychologists, and counselors, and it features
        support around billing, notes, scheduling, and more.
      </p>

      <p className='m-b-sm'>
        Read our{' '}
        <a href='https://blog.zencare.co/theranest/' target='_blank' rel='noopener noreferrer'>
          comprehensive review{' '}
        </a>
        and get{' '}
        <a
          href='http://www.theranest.com/partnerships-zencare/'
          target='_blank'
          rel='noopener noreferrer'
        >
          20% off your first 3 months of membership here
        </a>
        !
      </p>
    </div>
  </div>
);
