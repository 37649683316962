import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function () {
  const [index, selectIndex] = useState(0);

  const options = [
    {
      text: "I'm booking a remote photo/videoshoot for the first time with Zencare",
      slug: 'remote-shoot',
    },
    {
      text: "I'm booking an in-person photo/videoshoot for the first time with Zencare",
      slug: 'new-photoshoot',
    },
    {
      text: "I've had an in-person Zencare photo/videoshoot and am booking a reshoot",
      slug: 'reshoot',
    },
    {
      text: "I've had a remote setup and am now booking an in-person photo/videoshoot",
      slug: 'remote-new-photoshoot',
    },
    {
      text: 'I had to cancel my first photo/videoshoot last minute and am scheduling a new one',
      slug: 'reschedule-shoot',
    },
  ];

  return (
    <div
      id='static-page'
      className='main-content web-badge'
      style={{
        maxWidth: '1080px',
        margin: '0 auto',
      }}
    >
      <h1 className='title m-b-md'>Schedule Your Photo / Videoshoot</h1>
      <div className='box'>
        <h3 className='m-b-md'>Which type of photo/videoshoot are you scheduling?</h3>
        <form className='flex column'>
          {options.map((o, i) => (
            <>
              <input
                key={i}
                checked={i === index}
                name='photoshoot-option'
                type='radio'
                id={`photoshoot-option-${i}`}
                onChange={() => selectIndex(i)}
              />
              <label className='checkbox m-b-sm' htmlFor={`photoshoot-option-${i}`}>
                {o.text}
              </label>
            </>
          ))}
        </form>
        <Link to={`/benefits/photo-videoshoot/${options[index].slug}`}>
          <button className='primary m-t-sm lg'>Next</button>
        </Link>
      </div>
    </div>
  );
}
