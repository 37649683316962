/* eslint-disable max-lines-per-function */
import React from 'react';
import { Link } from 'react-router-dom';

export const IncreasingReferrals = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      maxWidth: '1080px',
      margin: '0 auto',
    }}
  >
    <h1 className='title m-b-md'>How can I increase my referrals?</h1>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>What can I expect from Zencare?</h3>
      <p className='m-b-sm'>
        We specialize in creating a beautiful, professional online presence for outstanding
        clinicians, helping you reach new prospective clients with your best foot forward.
      </p>
      <p className='m-b-sm'>
        Referral numbers vary based on a variety of factors, primarily location, insurance status,
        and fees.
      </p>
      <p className='m-b-sm'>
        In general, therapists who do not accept insurances and have higher fees typically receive
        fewer, but more profitable referrals. For most private pay clinicians, if you receive only
        two client referrals and see both for 4 sessions, you will have already doubled your annual
        investment. By nature, referrals tend to ebb and flow over time: we find that some
        clinicians receive a flood of referrals one month and then referrals slow the next month,
        for little identifiable reason.
      </p>
    </div>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>What can I do?</h3>
      <p className='m-b-sm'>
        There is a lot you can do to make the most out of your Zencare profile. Here are some tips
        for increasing referrals through your page.
      </p>
      <ul>
        <li>
          <strong>
            Share your profile with referral agents so they know you have an online presence:
          </strong>{' '}
          Have you shared your profile with the 20 colleagues you used to work with at the hospital,
          university, or agency? Is your Zencare link in your email signature, business card, and/or
          personal website? If the people who want to refer to you know that you have an online
          presence, they will be more likely to share your link with prospective clients and those
          clients will be more inclined to reach out to you as informed consumers.
        </li>
        <li>
          <strong>
            Add the <a href='/marketing/web-badge'>Zencare therapist web badge</a> to your website:{' '}
          </strong>
          Google search rankings (also known as 'search engine optimization,' or SEO) depend heavily
          on the number of page views your page receives. Adding the web badge to your website shows
          that you’re a member of a trusted community, and helps drive more visitors to your page so
          that it moves up in search rankings!
        </li>
        <li>
          <strong>Increase your phone consultation times: </strong>
          If you are only offering one hour time slots for phone consultations, it may help to{' '}
          <a href='/profile/availability-locations'>add a morning and evening time</a>.
        </li>
      </ul>
      <p className='m-b-sm'>
        We have also learned that sometimes, clinicians receive referrals through Zencare but they
        don't turn into ongoing clients. Here are some tips on ensuring your clients have a great
        experience with you and you're able to build a client base:
      </p>
      <ul>
        <li>
          <strong>Ace your phone consults: </strong>Read more about how to leave a lasting impact on
          clients from that first phone call with them <a href='/tips/phone-consultations'>here</a>.
        </li>
        <li>
          <strong>Lower cancellation and no-show rates:</strong> Confirm appointments with clients
          after your phone consult by text or email, and again 24 hours before the appointment.
        </li>
        <li>
          <strong>Give your clients a great experience:</strong> It goes without saying that
          word-of-mouth is a fantastic way to grow your practice, especially coupled with a great
          online presence to reach a different audience and help prospective clients be informed
          consumers. Clients who have great experiences will tell their friends and family members,
          as well as their psychiatrist, hospital case manager, and/or university referral
          coordinator. Make sure every client feels heard and understood, and every referral agent
          feel appreciated for working with you.
        </li>
      </ul>
    </div>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>Final thoughts</h3>
      <p className='m-b-sm'>
        Time is a funny thing. We receive emails from therapists asking about low referral numbers
        that month when actually, they received a handful of referrals -- which would, in total,
        amount to hundreds of dollars in session fees. It's easy to feel a sense of panic (to use a
        psychology term, "catastrophizing") when you have a slow period of even a week, but it's
        important to keep things in perspective.
      </p>
      <p className='m-b-sm'>
        If you are a private-pay only clinician, we understand how that can affect referral numbers.
        But we also understand you're private-pay only for a reason: You're good at what you do, and
        offer a unique service!
      </p>
      <p className='m-b-sm'>
        We're happy to work with you to review your profile, whether to check your referral numbers
        together or to revisit your video or professional statement.{' '}
        <Link to='/contact'>Contact us to learn more</Link>!
      </p>
    </div>
  </div>
);
