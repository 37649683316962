export const counts = {
  professional_statement: {
    suggestedWords: 240,
    maxWords: 300,
    minWords: 100,
  },
  message_to_clients: {
    suggestedWords: 150,
    maxWords: 250,
    minWords: 100,
  },
  answer: {
    suggestedWords: 75,
    maxWords: 150,
    minWords: 0,
  },
};

export const regex = {
  /**
   * @description Note: Regex's have been updates as of Aug. 2022 -- See regex.ts.
   * This phone number regex is intentionally far reaching and was thus left as is
   */
  phone: /\(*[2-9]\d{2}\)*(-| )*\(*\d{3}\)*(-| )*\(*\d{4}\)*/,
  email: /@|gmail|hotmail|hushmail/i,
  url: /https*:\/\/|www\.|\.com|\.org|\.co|\[dot\]|\[com\]|\.net|\.info/,
};

export const practiceBoxes = [
  {
    title: 'What services does your practice offer?',
    description:
      'Keep to one paragraph maximum. (e.g. "Life Changes Psychotherapy is a full-service therapy group practice in Midtown Manhattan. We offer individual, couples, group, and family therapy sessions.")',
    existingRegex: /What.*services.*offer/,
  },
  {
    title: "What are your practice's specialties?",
    description:
      'Keep to one paragraph maximum (e.g. "The therapists at Life Changes Psychotherapy specialize in a variety of areas, including anxiety, depression, and trauma. We have a particular special interest in working with children...")',
    existingRegex: /What.*(specialties?|specialize in)/,
  },
  {
    title: 'Why should I choose your practice?',
    description:
      'Keep to one paragraph maximum (e.g. "Life Changes Psychotherapy is unique in that all our providers are doctorate providers and have extensive training in...")',
    existingRegex: /Why should I choose/,
  },
  {
    title: 'How does payment work at your practice?',
    description:
      'Keep to one paragraph maximum (e.g. "At Life Changes Psychotherapy, we are in-network with Blue Cross Blue Shield and Cigna health insurances. If you do not have one of these health insurances, our fees range from $100-$200 depending on the provider and service offered. We are happy to submit claims on your behalf directly to your insurance company...")',
    existingRegex: /(How does payment|Do you (take|accept) insurance)/,
  },
];
