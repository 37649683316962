import { put, select, call, delay, take } from 'redux-saga/effects';
import _ from 'lodash';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import { api } from '#/apis';
import history from '#/history';
import { flashSuccess } from '+/flashes/redux';
import { ConvertToMonthlySagaParamBinding, ConvertToMonthlySagaParam } from './types/types';

const sagaSliceModule = createModule({
  name: 'paymentSeatBased',
  initialState: {
    isLoading: false,
    coupon: null,
    checkoutId: null,
    paymentStep: 'customize', // 'customize', 'pay', 'success'
    paymentType: 'subscribe', // 'subscribe', 'add', 'upgrade'
    convertToMonthlyData: null,
  },
  reducers: {
    subscribeToPlan: loadingReducer,
    // subscribeToPlanSuccess: notLoadingReducer,

    subscribeToPlanSuccess: notLoadingReducer,
    // subscribeToPlanSuccess: (state, payload) => {
    //   state.isLoading = false;
    //   state.paymentStep = 'success';
    // },

    subscribeToPlanFailure: failReducer,

    addSeat: loadingReducer,

    addSeatSuccess: notLoadingReducer,

    addSeatFailure: failReducer,

    upgradePlan: loadingReducer,

    upgradePlanSuccess: notLoadingReducer,

    upgradePlanFailure: failReducer,

    getUpcomingInvoice: loadingReducer,

    getUpcomingInvoiceSuccess: notLoadingReducer,

    getUpcomingInvoiceFailure: failReducer,

    upgradeProductPreview: (state, payload) => {
      console.log('Previewing...');
    },

    upgradeProductPreviewSuccess: notLoadingReducer,

    upgradeProductPreviewFailure: failReducer,

    // addSeatPreview: loadingReducer,
    addSeatPreview: (state, payload) => {
      console.log('Previewing...');
    },

    addSeatPreviewSuccess: notLoadingReducer,

    addSeatPreviewFailure: failReducer,

    updateSource: loadingReducer,

    updateSourceSuccess: notLoadingReducer,

    updateSourceFailure: failReducer,

    checkCoupon: loadingReducer,

    checkCouponSuccess: notLoadingReducer,

    checkCouponFailure: failReducer,

    getCheckoutId: loadingReducer,

    getCheckoutIdSuccess: (state, payload) => {
      state.isLoading = false;
      state.checkoutId = payload;
      state.paymentStep = 'success';
    },

    getCheckoutIdFailure: failReducer,

    getPriceProduct: loadingReducer,

    getPriceProductSuccess: notLoadingReducer,

    getPriceProductFailure: failReducer,

    setPaymentStep: (state, payload) => {
      state.paymentStep = payload;
    },

    setPaymentType: (state, payload) => {
      state.paymentType = payload;
    },

    convertToMonthly: loadingReducer,
    convertToMonthlyFailure: failReducer,
    convertToMonthlySuccess: notLoadingReducer,

    setConvertToMonthlyData: (state, payload) => {
      state.convertToMonthlyData = payload;
    },

    upgradeToPaidReimbursify: loadingReducer,

    upgradeToPaidReimbursifySuccess: notLoadingReducer,

    upgradeToPaidReimbursifyFailure: failReducer,

    upgradeReimbursifyPreview: loadingReducer,

    upgradeReimbursifyPreviewSuccess: notLoadingReducer,

    upgradeReimbursifyPreviewFailure: failReducer,
  },

  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.subscribeToPlan]: function* ({ payload }: $TSFixMe): $TSFixMe {
        try {
          let source = payload.source;

          if (!source && payload.sourcePromise) {
            const result = yield call(payload.sourcePromise);

            if (result.error) {
              return payload.onError(result.error);
            }

            source = result.source;
          }

          const subPayload = {
            lookup_key: payload.lookup_key,
            setup_lookup_key: payload.setup_lookup_key,
            add_on_lookup_key: payload.add_on_lookup_key,
            source,
            customer: payload.customer,
            coupon: payload.coupon,
            referrer: payload.referrer,
            is_reactivation: payload.is_reactivation,
            reimbursify_tier: payload.reimbursify_tier,
            address_line1: payload.address_line1,
            address_line2: payload.address_line2,
            address_city: payload.address_city,
            address_state: payload.address_state,
            address_postal_code: payload.address_postal_code,
          };

          const { data } = yield api.post(
            `/portal/seat-based/subscribe/${payload.provider_id}`,
            subPayload,
            {
              timeout: 150000,
            }
          );

          yield put({ type: 'main/getMe' });
          yield put(A.subscribeToPlanSuccess());
          yield put({ type: 'main/getAllAccountDetails' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          payload.onSuccess();
        } catch (err) {
          yield put(A.subscribeToPlanFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.addSeat]: function* ({ payload }: $TSFixMe): $TSFixMe {
        const {
          provider_id,
          stripe_customer_id,
          stripe_subscription_id,
          lookup_key,
          setup_lookup_key,
          add_on_lookup_key,
          coupon,
        } = payload;
        try {
          const subPayload = {
            lookup_key,
            setup_lookup_key,
            add_on_lookup_key,
            coupon,

            // Pass this if you showed the provider a preview (previewedSubscriptionProrationDate comes back in preview)
            // this ensure the preview will exactly match the actual change
            previewed_subscription_proration_date: payload.previewed_subscription_proration_date,
          };

          const { data } = yield api.post(
            `portal/seat-based/add-seat/${provider_id}/${stripe_customer_id}/${stripe_subscription_id}`,
            subPayload,
            {
              timeout: 150000,
            }
          );

          yield put({ type: 'main/getMe' });
          yield put({ type: 'main/getAllAccountDetails' });
          yield put({ type: 'main/getAllMyProviders' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          payload.onSuccess();
          yield put(A.addSeatSuccess());
        } catch (err) {
          yield put(A.addSeatFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.upgradeToPaidReimbursify]: function* ({ payload }: $TSFixMe): $TSFixMe {
        const { stripe_customer_id, stripe_subscription_id, coupon, new_tier } = payload;
        try {
          const subPayload = {
            coupon,
            new_tier,
          };

          const { data } = yield api.post(
            `portal/seat-based/upgrade-reimbursify/${stripe_customer_id}/${stripe_subscription_id}`,
            subPayload,
            {
              timeout: 350000,
            }
          );

          yield put({ type: 'main/getMe' });
          yield put({ type: 'main/getAllAccountDetails' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          payload.onSuccess();
          yield put(A.upgradeToPaidReimbursifySuccess());
        } catch (err) {
          yield put(A.upgradeToPaidReimbursifyFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.upgradePlan]: function* ({ payload }: $TSFixMe): $TSFixMe {
        const {
          // either "professional" or "premium"
          new_tier,
          // true if this is upgrading from legacy non-seat based plan, false otherwise
          is_legacy,
          // required if is_legacy is true, whether provider is a NY or CA provider
          // and thus needs NY/CA pricing.
          legacy_is_ny_or_ca,
          // required if is_legacy is true, the provider id
          legacy_provider_id,
          coupon,
          previewed_subscription_proration_date,
          stripe_customer_id,
          stripe_subscription_id,
        } = payload;
        try {
          const subPayload = {
            // required
            new_tier,
            // required
            is_legacy,
            // required if is_legacy is true
            legacy_is_ny_or_ca,
            // required if is_legacy is true
            legacy_provider_id,
            coupon,
            // Pass this if you showed the provider a preview (previewedSubscriptionProrationDate comes back in preview)
            // this ensure the preview will exactly match the actual change
            previewed_subscription_proration_date,
          };

          const { data } = yield api.post(
            `portal/seat-based/upgrade-product/${stripe_customer_id}/${stripe_subscription_id}`,
            subPayload,
            {
              timeout: 350000,
            }
          );

          yield put({ type: 'main/getMe' });
          yield put({ type: 'main/getAllAccountDetails' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          payload.onSuccess();
          yield put(A.upgradePlanSuccess());
        } catch (err) {
          yield put(A.upgradePlanFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.getUpcomingInvoice]: function* ({ payload }: $TSFixMe): $TSFixMe {
        try {
          const { data } = yield api.get(
            `/portal/invoice/upcoming/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
            { timeout: 150000 }
          );

          yield put(A.getUpcomingInvoiceSuccess());

          payload.onSuccess(data);
        } catch (err) {
          console.error(err); // tmp
          yield put(A.getUpcomingInvoiceFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      // TO DO: Remove after confirming never used
      // updateSource in src/components/payment/sagaSlice.js is used instead
      [A.updateSource]: function* ({ payload }: $TSFixMe): $TSFixMe {
        try {
          const oldSource = payload.oldSource;
          let newSource = payload.newSource;

          if (!newSource && payload.sourcePromise) {
            const { source, error } = yield call(payload.sourcePromise);

            if (error) {
              return payload.onError(error);
            }

            newSource = source;
          }

          const sourcePayload = {
            oldSource,
            newSource,
          };

          yield api.post(`/portal/update-source/${payload.customer_id}`, sourcePayload, {
            timeout: 1500000,
          });

          yield put({ type: 'main/getMe' });
          yield take(['main/getMeSuccess', 'main/getMeFailure']);
          yield put({ type: 'main/getAllAccountDetails' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          yield put(A.updateSourceSuccess());

          payload.onUpdated();
        } catch (err) {
          console.error(err);

          yield put(A.updateSourceFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.checkCoupon]: function* ({ payload }: $TSFixMe): $TSFixMe {
        try {
          const response = yield api.get(`/portal/coupon/${payload.coupon}`);

          yield put(A.checkCouponSuccess());
          return payload.onSuccess(response.data);
        } catch (err) {
          yield put(A.checkCouponFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.getCheckoutId]: function* ({ payload }: $TSFixMe): $TSFixMe {
        try {
          const response = yield api.post(`/portal/checkout/${payload.priceId}`, {
            customer_id: payload.customer_id,
            coupon: payload.coupon,
          });

          yield put(A.getCheckoutIdSuccess(response.data));
        } catch (err) {
          yield put(A.getCheckoutIdFailure());
        }
      },
      [A.getPriceProduct]: function* ({ payload }: $TSFixMe): $TSFixMe {
        try {
          const { data } = yield api.get(`/portal/price/product/${payload.priceId}`);

          yield history.push('/');
          yield put(A.getPriceProductSuccess());
          yield delay(1000);
          yield put(
            flashSuccess(
              `Thank you for purchase of ${data.product.name}! Please check your email for next steps.`
            )
          );
        } catch (err) {
          yield history.push('/');
          yield put(A.getPriceProductFailure());
        }
      },
      // start new
      [A.upgradeProductPreview]: function* ({ payload }: $TSFixMe) {
        const { params, apiPayload, onSuccess, onError } = payload;
        const { stripe_customer_id, stripe_subscription_id } = params;
        try {
          const { data } = yield api.post(
            `/portal/seat-based/upgrade-product-preview/${stripe_customer_id}/${stripe_subscription_id}`,
            apiPayload
          );

          yield put(A.upgradeProductPreviewSuccess());
          onSuccess(data);
        } catch (error) {
          yield put(A.upgradeProductPreviewFailure());
          onError(error);
        }
      },
      [A.addSeatPreview]: function* ({ payload }: $TSFixMe) {
        const { params, apiPayload, onSuccess, onError } = payload;
        const { stripe_customer_id, stripe_subscription_id } = params;
        try {
          const { data } = yield api.post(
            `/portal/seat-based/add-seat-preview/${stripe_customer_id}/${stripe_subscription_id}`,
            apiPayload
          );

          yield put(A.addSeatPreviewSuccess());
          onSuccess(data);
        } catch (error) {
          yield put(A.addSeatPreviewFailure());
          onError(error);
        }
      },
      [A.upgradeReimbursifyPreview]: function* ({ payload }: $TSFixMe) {
        const { params, apiPayload, onSuccess, onError } = payload;
        const { stripe_customer_id, stripe_subscription_id } = params;
        try {
          const { data } = yield api.post(
            `/portal/seat-based/upgrade-reimbursify-preview/${stripe_customer_id}/${stripe_subscription_id}`,
            apiPayload
          );

          yield put(A.upgradeReimbursifyPreviewSuccess());
          onSuccess(data);
        } catch (error) {
          yield put(A.upgradeReimbursifyPreviewFailure());
          onError(error);
        }
      },
      [A.convertToMonthly]: function* ({
        payload: { params, apiPayload, onSuccess, onError },
      }: ConvertToMonthlySagaParamBinding) {
        const { stripe_customer_id, stripe_subscription_id } = params;
        try {
          yield api.post(
            `/portal/seat-based/convert-to-monthly/${stripe_customer_id}/${stripe_subscription_id}`,
            apiPayload
          );
          yield put(A.convertToMonthlySuccess());
          onSuccess();
        } catch (error) {
          yield put(A.convertToMonthlyFailure());
          return onError(
            {
              message: _.get(error, 'response.data.message'),
            } || error
          );
        }
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
