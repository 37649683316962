/* eslint-disable default-case */
import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import { actions } from './sagaSlice';
import {
  twelveHourTimeToMilitaryTime,
  isMilitaryTime2AfterMilitaryTime1,
} from '../../../utils/timeUtils';

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const icalDaysOfWeek = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
];

export const stringToRRuleFreq = {
  day: RRule.DAILY,
  week: RRule.WEEKLY,
  month: RRule.MONTHLY,
  year: RRule.YEARLY,
};

export const dateOfMonth = (date) => date.day;

export const nthWeekdayOfMonth = (date) => {
  const dayOfWeek = date.weekday % 7;
  const dayOfMonth = dateOfMonth(date);
  const nthDayOfWeek = Math.ceil(dayOfMonth / 7);

  return {
    n: nthDayOfWeek,
    weekday: daysOfWeek[dayOfWeek],
  };
};

const suffixes = Array(32).fill('th');
suffixes[1] = 'st';
suffixes[21] = 'st';
suffixes[31] = 'st';
suffixes[2] = 'nd';
suffixes[22] = 'nd';
suffixes[3] = 'rd';
suffixes[23] = 'rd';

export const suffixDate = (n) => suffixes[n];

export const formValuesToWeekdayArray = (days) =>
  daysOfWeek
    .map((day, i) => {
      if (days[day]) {
        return icalDaysOfWeek[i];
      }
    })
    .filter((x) => !!x);

export const maxDBTimestamp = DateTime.fromISO('2038-01-19T03:14:07Z').toUTC();

const optionDaysToFormOptionDays = (byweekday) =>
  byweekday.reduce((acc, cur) => {
    const idx = icalDaysOfWeek.findIndex((d) => d.weekday === cur);

    return {
      ...acc,
      [daysOfWeek[idx]]: true,
    };
  }, {});

export const rruleStringToFormOptions = (rruleString, start = DateTime.local()) => {
  const defaultValues = {
    days: {
      [daysOfWeek[start.weekday % 7]]: true,
    },
    freq: 'week',
    interval: 1,
    endType: 'never',
    endsOnDate: DateTime.local().plus({ years: 1 }).toFormat('yyyy-LL-dd'),
    endsAfterCount: 3,
  };

  try {
    const options = RRule.fromString(rruleString).options;
    const values = {};
    values.interval = parseInt(options.interval);

    switch (options.freq) {
      case RRule.DAILY:
        values.days = {};
        values.freq = 'day';
        break;
      case RRule.WEEKLY:
        values.freq = 'week';
        values.days = optionDaysToFormOptionDays(options.byweekday);
        break;
      case RRule.MONTHLY:
        values.freq = 'month';
        if (options.bymonthday.length) {
          values.days = {};
          values.monthly_type = 'exact_date';
        } else if (options.bysetpos.length && options.byweekday.length) {
          values.monthly_type = 'weekday';
          values.days = optionDaysToFormOptionDays(options.byweekday);
        }
        break;
      case RRule.YEARLY:
        values.days = {};
        values.freq = 'year';
        break;
    }

    if (DateTime.fromISO(options.until.toISOString()).toUTC().toISO() === maxDBTimestamp.toISO()) {
      values.endType = 'never';
    } else if (options.count) {
      values.endType = 'afterCount';
      values.endsAfterCount = options.count;
    } else {
      values.endType = 'onDate';
      values.endsOnDate = DateTime.fromISO(options.until.toISOString())
        .toUTC()
        .toFormat('yyyy-LL-dd'); //utc?
    }

    return {
      ...defaultValues,
      ...values,
    };
  } catch (e) {
    return defaultValues;
  }
};

const convertAndCalcDuration = (values, tz) => {
  const militaryStartTime = twelveHourTimeToMilitaryTime(values.startTime);
  const militaryEndTime = twelveHourTimeToMilitaryTime(values.endTime);

  const dateAsTZ = (date, time) => DateTime.fromISO(`${date}T${time}:00.000`, { zone: tz });
  const dateAsUTC = (date, time) => DateTime.fromISO(`${date}T${time}:00.000Z`).toUTC();

  const tzStart = dateAsTZ(values.date, militaryStartTime);
  let tzEnd = dateAsTZ(values.date, militaryEndTime);

  let durationInMinutes = 0;
  // Special handling needed to compute a duration that ends at midnight.
  if (militaryEndTime === '00:00') {
    // If it ends at midnight we need to add 1 day because technically midnight is start of tomorrow.
    tzEnd = dateAsTZ(values.date, militaryEndTime).plus({ days: 1 });
    const tzEndOneMinBeforeMidnight = dateAsTZ(values.date, '23:59');
    durationInMinutes = 1 + tzEndOneMinBeforeMidnight.diff(tzStart, 'minutes').toObject().minutes;
  } else {
    durationInMinutes = tzEnd.diff(tzStart, 'minutes').toObject().minutes;
  }

  return {
    militaryStartTime,
    dateAsTZ,
    dateAsUTC,
    tzStart,
    tzEnd,
    durationInMinutes,
  };
};

export const formValuesToSubmitPayload = ({ values, calendar, provider, originalEvent, tz }) => {
  const { militaryStartTime, dateAsTZ, dateAsUTC, tzStart, tzEnd, durationInMinutes } =
    convertAndCalcDuration(values, tz);

  const payload = {
    calendar_id: calendar.id,
    name: values.name,
    free: values.free === 'free',
    provider_id: provider.id,
    location_id: null,
    appointment_type_id: 1,
    start_time: tzStart.toUTC().toISO(),
    end_time: tzEnd.toUTC().toISO(),
    is_recurring: values.recurrence !== 'Does not repeat' && values.editSelection !== 'instance',
    duration_in_minutes: durationInMinutes,
  };

  if (payload.is_recurring) {
    let dtstart = dateAsUTC(values.date, militaryStartTime).toJSDate();
    const hour = tzStart.hour;
    const minute = tzStart.minute;

    if (originalEvent.rrule) {
      // If we're editing a recurring event, we want to keep the original start
      // date of the recurrence. We may need to change the hours and minutes, however.
      const originalOptions = RRule.fromString(originalEvent.rrule).options;
      delete payload.start_time; // yeah?

      dtstart = DateTime.fromJSDate(originalOptions.dtstart)
        .set({
          hour,
          minute,
        })
        .setZone('utc', { keepLocalTime: true })
        .toJSDate();
    }

    const options = {
      freq: stringToRRuleFreq[values.r.freq],
      interval: parseInt(values.r.interval),
      byhour: hour,
      byminute: minute,
      dtstart,
    };

    switch (options.freq) {
      case RRule.DAILY:
        break;
      case RRule.WEEKLY:
        options.byweekday = formValuesToWeekdayArray(values.r.days);
        break;
      case RRule.MONTHLY:
        if (values.r.monthly_type === 'exact_date') {
          options.bymonthday = dateOfMonth(tzStart);
        } else if ((values.r.monthly_type = 'weekday')) {
          const nthDayOfWeek = nthWeekdayOfMonth(tzStart);
          options.bysetpos = nthDayOfWeek.n;
          options.byweekday = formValuesToWeekdayArray({ [nthDayOfWeek.weekday]: true });
        }
        break;
      case RRule.YEARLY: //TODO YEARLY
        break;
    }

    switch (values.r.endType) {
      case 'never':
        options.until = maxDBTimestamp.toJSDate();
        payload.end_time = maxDBTimestamp.toUTC().toISO();
        break;
      case 'afterCount':
        options.count = values.r.endsAfterCount;
        options.until = dateAsUTC(values.date, militaryStartTime)
          .plus({ [`${values.r.freq}s`]: options.interval * options.count })
          .toJSDate();
        payload.end_time = tzStart
          .plus({ [`${values.r.freq}s`]: options.interval * options.count })
          .toUTC()
          .toISO();
        break;
      case 'onDate':
        options.until = dateAsUTC(values.r.endsOnDate, militaryStartTime).toJSDate();
        payload.end_time = dateAsTZ(values.r.endsOnDate, militaryStartTime);
        break;
    }

    payload.rrule = new RRule(options).toString();
    payload.rrule_tzid = tz;
  }

  return payload;
};

/*
const rToString = (r) => {
    let s = '';

    switch (r.freq) {
    case 'day':
        s = 'day'
        break;
    case 'week':
        const daysOfWeek = Object.keys(r.days).filter((k) => r.days[k]);
        let dayPhrase;
        if (daysOfWeek.length <= 2) {
            dayPhrase = daysOfWeek.join(' and ');
        } else {
            dayPhrase = `${daysOfWeek.slice(0, daysOfWeek.length - 1).join(', ')}, and ${daysOfWeek[daysOfWeek.length - 1]}`;
        }

        s = `week on ${dayPhrase}`

        break;
    case 'month':
        if (r.monthly_type = 'exact_date') {     
        }
        break;
    case 'year':
        values.days = {};
        values.freq = 'year';
        break;
    }
}*/

export const validateFormValues = (values, tz) => {
  const errors = {};

  const militaryStartTime = twelveHourTimeToMilitaryTime(values.startTime);
  const militaryEndTime = twelveHourTimeToMilitaryTime(values.endTime);

  if (!militaryStartTime) {
    errors.startTime = 'Start time invalid. Use 1:00pm format';
  }

  if (!militaryEndTime) {
    errors.endTime = 'End time invalid. Use 2:00pm format';
  }

  if (!values.date) {
    errors.date = 'Date required';
  }

  if (!errors.startTime && !errors.endTime && !errors.date) {
    const { durationInMinutes } = convertAndCalcDuration(values, tz);
    if (durationInMinutes < 10) {
      errors.endTime = 'Must be at least 10 mins long.';
    }
  }

  if (
    militaryStartTime &&
    militaryEndTime &&
    !isMilitaryTime2AfterMilitaryTime1(militaryStartTime, militaryEndTime)
  ) {
    errors.endTime = 'End time must be after start time.';
  }

  const isNotPosInt = (x) => !x || !parseInt(x) || parseInt(x) < 1;

  if (values.recurrence !== 'Does not repeat' && values.editSelection !== 'instance') {
    const rerrors = {};

    if (values.r.freq === 'week' && !daysOfWeek.some((d) => values.r.days[d])) {
      rerrors.days = 'Requires some day of week selected';
    }

    if (values.r.freq === 'month' && !values.r.monthly_type) {
      rerrors.monthly_type = 'Must select option';
    }

    if (isNotPosInt(values.r.interval)) {
      rerrors.interval = 'Valid interval required';
    }

    if (values.r.endType === 'onDate' && !values.r.endsOnDate) {
      rerrors.endsOnDate = 'Date required';
    }

    if (values.r.endType === 'afterCount' && isNotPosInt(values.r.endsAfterCount)) {
      rerrors.endsAfterCount = 'Valid count required';
    }

    if (Object.keys(rerrors).length) {
      errors.r = rerrors;
    }
  }

  return errors;
};

function adjustValuesForRecurrence(values) {
  const { recurrence } = values;

  // Return values unmodified
  if (recurrence === 'Does not repeat') return values;
  if (recurrence === 'Custom') return values;

  // Adjust event recurrence schedule based on user selection
  switch (recurrence) {
    // These case values are likely to change if an error occurs here,
    // ensure that switch case strings match select dropdown options
    case 'Daily':
      values.r.days = {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
        Sunday: true,
      };
      break;
    case 'Every Weekday (Monday to Friday)':
      values.r.days = {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
      };
      break;
    default:
      console.error(`Something went wrong transforming values.`);
  }
  return values;
}

export const submit = ({
  values,
  dispatch,
  originalEvent,
  tz,
  calendar,
  provider,
  googleCalendarDefaultWorkingHoursCompleted,
}) => {
  const isException = !!originalEvent.parent_id;
  const editing = !!originalEvent.id;

  // If 'Daily' or 'Every Weekday' is selected for recurrence, manually adjust r
  values = adjustValuesForRecurrence(values);

  //eslint-disable-next-line
  const payload = formValuesToSubmitPayload({ values, originalEvent, tz, calendar, provider });
  // create any new event
  if (!editing) {
    if (googleCalendarDefaultWorkingHoursCompleted) {
      payload.google_calendar_default_working_hours_completed =
        googleCalendarDefaultWorkingHoursCompleted;
    }
    return dispatch(actions.createAvailability(payload));
  }

  //editing a non-exception
  if (!(originalEvent.is_recurring && values.editSelection === 'instance')) {
    payload.event_id = originalEvent.id;
    for (const k of ['provider_id', 'location_id', 'appointment_type_id']) {
      delete payload[k];
    }

    return dispatch(actions.editAvailability(payload));
  }

  if (originalEvent.is_recurring && values.editSelection === 'instance') {
    payload.event_id = originalEvent.id;
    for (const k of [
      'provider_id',
      'location_id',
      'appointment_type_id',
      'is_recurring',
      'rrule',
      'rrule_tzid',
    ]) {
      delete payload[k];
    }

    payload.original_start_time = originalEvent.start.toUTC().toISO();

    return dispatch(actions.createAvailabilityException(payload));
  }
};

export const deleteEvent = ({ dispatch, originalEvent, calendar, options = {} }) => {
  if (!originalEvent.id) {
    return;
  }

  const isException = !!originalEvent.parent_id;

  if (
    (!originalEvent.is_recurring && !originalEvent.parent_id) ||
    (originalEvent.is_recurring && options.type === 'all')
  ) {
    const text = originalEvent.free
      ? 'Are you sure you want to delete this event? Therapy seekers will not be able to book calls with you at this time if you do!'
      : 'Are you sure you want to delete this event?';

    if (!window.confirm(text)) {
      return;
    }

    const payload = {
      calendar_id: calendar.id,
      event_id: originalEvent.id,
    };

    return dispatch(actions.deleteAvailability(payload));
  }

  if (originalEvent.is_recurring && options.type === 'instance') {
    const text = originalEvent.free
      ? 'Are you sure you want to delete this instance? Therapy seekers will not be able to book calls with you at this time if you do!'
      : 'Are you sure you want to delete this instance?';

    if (!window.confirm(text)) {
      return;
    }

    const utcStart = originalEvent.start.toUTC().toISO();
    const payload = {
      calendar_id: calendar.id,
      event_id: originalEvent.id,
      start_time: utcStart,
      end_time: utcStart,
      original_start_time: utcStart,
      duration_in_minutes: 0,
    };

    return dispatch(actions.createAvailabilityException(payload));
  } else if (isException && options.type === 'instance') {
    const text = originalEvent.free
      ? 'Are you sure you want to delete this instance? Therapy seekers will not be able to book calls with you at this time if you do!'
      : 'Are you sure you want to delete this instance?';

    if (!window.confirm(text)) {
      return;
    }

    const utcStart = originalEvent.start.toUTC().toISO();
    const payload = {
      calendar_id: calendar.id,
      event_id: originalEvent.id,
      start_time: utcStart,
      end_time: utcStart,
      duration_in_minutes: 0,
    };

    return dispatch(actions.editAvailability(payload));
  } else if (isException && options.type === 'restore') {
    const payload = {
      calendar_id: calendar.id,
      event_id: originalEvent.id,
      restoreOriginalInstance: true,
    };

    return dispatch(actions.deleteAvailability(payload));
  }
};
