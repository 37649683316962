/* eslint-disable max-lines-per-function */
/* eslint-disable react/style-prop-object */
import React from 'react';

export const MembershipTips = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      maxWidth: '1080px',
      margin: '0 auto',
    }}
  >
    <h1 className='title m-b-md'>How to Get the Most Out of Your Zencare Membership</h1>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>General benefits</h3>
      <ul data-rte-list='default'>
        <li>
          <strong>Share your profile link</strong> on your business card, website, and email
          signature.
        </li>
        <li>
          <strong>Embed your video</strong> on your personal website so prospective clients can
          continue learning about your work.
        </li>
        <li>
          <strong>Use your photos</strong> on your personal website and promotional materials.
        </li>
        <li>
          <strong>Join us at our events</strong> to build your referral network. Bring business
          cards!
        </li>
        <li>
          <strong>Get a referral bonus</strong>. Introduce colleagues to Zencare with{' '}
          <a href='/refer-and-earn'>your unique referral link</a>, and you’ll both receive $49 off
          membership!
        </li>
        <li>
          <strong>Follow us</strong> on
          <a href='https://www.instagram.com/zencare.co/' target='_blank' rel='noopener noreferrer'>
            {' '}
            Instagram
          </a>{' '}
          and
          <a href='http://www.facebook.com/zencare.co' target='_blank' rel='noopener noreferrer'>
            {' '}
            Facebook
          </a>{' '}
          for mental health articles!
        </li>
      </ul>
    </div>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>Boosting exposure</h3>
      <ul>
        <li>
          <strong>
            <a href='/marketing/blog-features'>Contribute to the Zencare blog</a>{' '}
          </strong>
          through a Therapist Interview, so clients can learn even more about your practice style
          and services.
        </li>
        <li>
          <strong>
            Add the <a href='/marketing/web-badge'>Zencare Trusted Therapist Web Badge</a>
          </strong>{' '}
          to your website to add instant credibility.
        </li>
        <li>
          <strong>
            <a href='/therapy-groups'>Share your therapy groups</a>
          </strong>{' '}
          to advertise new offerings!
        </li>
        <li>
          <strong>
            <a href='/marketing/collect-references'>Collect professional endorsements</a>
          </strong>{' '}
          to feature testimonials from colleagues on your profile.
        </li>
      </ul>
    </div>

    <div className='subsection m-t-lg'>
      <h3 className='subtitle m-b-sm'>Keep your profile up-to-date!</h3>
      <ul>
        <li>
          You can update your <a href='/profile/availability-locations'>availability</a>,{' '}
          <a href='/profile/insurance-fees'>insurance status</a>,{' '}
          <a href='/profile/specialties'>specialties</a>, or{' '}
          <a href='/profile/clientele-approach'>treatment approaches</a> at any time.
        </li>
      </ul>
    </div>
  </div>
);
