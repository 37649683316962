import React from 'react';
import SubscribeToPlanSeatBased from './SubscribeToPlanSeatBased';
import history from '#/history';
import { useSelector } from 'react-redux';
import Loader from '+/forms/SectionLoader';
import { match } from 'react-router-dom';
import { dispatch } from '~/store';
import { flashSuccess } from '~/shared/flashes/redux';

function UpgradePlan({ match }: $TSFixMe) {
  const { accounts } = useSelector((state: $TSFixMe) => state.main);
  const selectedAccount: Account = accounts.find(
    (a: Account) => a.id === parseInt(match.params.accountId)
  );

  if (!selectedAccount) {
    return (
      <div className='loading-content'>
        <Loader />
      </div>
    );
  }
  const { providers, has_practice_management, tier } = selectedAccount;
  const provider = providers ? providers[0] : null;

  if (selectedAccount.legacy_subscription_count > 1 && !selectedAccount.has_practice_management) {
    // Shouldn't be here yet!!
    history.push('/plan-management');
  }

  if (!provider) {
    return (
      <div className='loading-content'>
        <Loader />
      </div>
    );
  }

  const title = has_practice_management
    ? tier === 'professional'
      ? 'Upgrade to Practice Management Premium'
      : ''
    : 'Add Practice Management';

  const onSubscribed = () => {
    history.push('/plan-management');
    dispatch(flashSuccess('Successful payment! Thank you for upgrading your plan!'));
  };

  return (
    <div className='box'>
      <SubscribeToPlanSeatBased
        onSubscribed={onSubscribed}
        showStartForLine={true}
        showDoThisLater={false}
        doThisLaterClicked={null}
        provider={provider}
        upgrade={true}
        title={title}
        subtitle='Streamline your private practice.'
      />
    </div>
  );
}

export default UpgradePlan;
