import { capitalize } from './capitalize';

export const capitalizeName = (s) => {
  // Note: If value is null (e.g. middle name) we want to leave it null
  if (typeof s !== 'string') return null;

  // First we need to trim so that capitalization works.
  s = s.trim();

  // Remove all caps unless just 2 letters e.g. AJ
  if (s.toUpperCase() === s && s.length >= 3) {
    s = s.toLowerCase();
  }

  return capitalize(s);
};
