import React, { useState } from 'react';
import { onlyOnEnter } from '../../../utils';

interface Props {
  defaultCoupon: string;
  defaultCouponSetAndNotRemovable: boolean;
  checkCoupon: $TSFixMeFunction;
  onCheckCouponCompletedOrCouponRemoved: $TSFixMeFunction;
  setCouponError: $TSFixMeFunction;
  setCoupon: $TSFixMeFunction;
  couponError: $TSFixMe;
  generateCouponDescription: $TSFixMeFunction;
  coupon: CouponType | null;
}

export default (props: Props) => {
  const {
    defaultCoupon,
    defaultCouponSetAndNotRemovable,
    checkCoupon,
    onCheckCouponCompletedOrCouponRemoved,
    setCouponError,
    setCoupon,
    couponError,
    generateCouponDescription,
    coupon,
  } = props;

  const [manuallyAddingCoupon, setManuallyAddingCoupon] = useState(false);

  return (
    <div className='coupon-wrapper'>
      {coupon || manuallyAddingCoupon ? (
        <>
          {coupon ? (
            <>
              <div className='applied-coupon'>
                <i className='fas fa-tag'></i> {coupon.id}{' '}
                {!defaultCouponSetAndNotRemovable && (
                  <span
                    className='remove-coupon'
                    title='Remove coupon'
                    role='button'
                    tabIndex={0}
                    onKeyDown={onlyOnEnter(() => setCoupon(null))}
                    onClick={() => {
                      setCoupon(null);
                      onCheckCouponCompletedOrCouponRemoved(null);
                    }}
                  >
                    X
                  </span>
                )}
              </div>
            </>
          ) : (
            <div className='coupon-input-wrapper'>
              <input
                style={{
                  height: '38px',
                  color: '#666666',
                }}
                placeholder='Coupon code (optional)'
                id='coupon-code'
                name='coupon-code'
                defaultValue={defaultCoupon}
              ></input>
              <button
                type='button'
                className='primary md'
                style={{ height: '38px' }}
                onClick={() =>
                  checkCoupon({
                    // @ts-ignore-next-line
                    coupon: document.getElementById('coupon-code').value,
                    onError: (e: $TSFixMe) => {
                      setCouponError(e);
                      setCoupon(null);
                      onCheckCouponCompletedOrCouponRemoved(null);
                    },
                    onSuccess: (data: CouponType) => {
                      if (data.valid) {
                        setCouponError(null);
                        setCoupon(data);
                        onCheckCouponCompletedOrCouponRemoved(data);
                      } else {
                        setCouponError({
                          message: 'Sorry this coupon is not valid or has expired.',
                        });
                        setCoupon(null);
                        onCheckCouponCompletedOrCouponRemoved(null);
                      }
                    },
                  })
                }
              >
                Apply
              </button>
            </div>
          )}
          {couponError && <div className='text-error'>{couponError.message}</div>}
          <div className='coupon-duration-phrase'>{generateCouponDescription()}</div>
        </>
      ) : (
        <button
          className='add-discount-code-link'
          onClick={() => {
            setManuallyAddingCoupon(true);
          }}
        >
          Add discount code
        </button>
      )}
    </div>
  );
};
