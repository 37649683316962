/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import { actions as onboardingActions } from '@/onboarding/sagaSlice';
import Loader from '+/forms/SectionLoader';
import rgx from '#/regex';
import voca from 'voca';
import _ from 'lodash';
import { capitalizeName } from '../../../../utils/capitalizeName';
import PhoneInput from '~/shared/forms/PhoneInput';
import { createPhoneErrorMessage } from '~/utils/createPhoneErrorMessage';
import DuplicateProfileWarningModal from './DuplicateProfileWarningModal';

const RegistrationForm = (props: $TSFixMe) => {
  const { isLoading, createProvider, account, email } = props;

  const [duplicateProfileWarningModalOpen, setDuplicateProfileWarningModalOpen] = useState(false);
  const [duplicateProvider, setDuplicateProvider] = useState(false);

  // @ts-ignore-next-line $TSFixMe
  const allProviders = useSelector((state) => state.main.allProviders) || [];

  const onSubmit = (values: $TSFixMe) => {
    // If this is an individual profile, first see if there's a duplicate, if so, ask before creating provider
    if (values.type.includes('individual')) {
      const duplicate = allProviders.find(
        (x: Provider) =>
          x.first_name === capitalizeName(values.first_name) &&
          x.last_name === capitalizeName(values.last_name)
      );
      if (duplicate) {
        setDuplicateProvider(duplicate);
        setDuplicateProfileWarningModalOpen(true);
      } else {
        submitCreateProvider(values);
      }
    } else {
      submitCreateProvider(values);
    }
  };

  const submitCreateProvider = (values: $TSFixMe) => {
    const providerPayload: $TSFixMe = {
      type: values.type.includes('individual') ? 'individual' : 'practice',
      ...(values.type.includes('individual')
        ? {
            first_name: capitalizeName(values.first_name),
            last_name: capitalizeName(values.last_name),
            middle_name: capitalizeName(values.middle_name),
          }
        : {
            company_name: values.company_name,
          }),
      email: values.email || email,
      contact_email: values.email || email,
      ownerHasClinicianPermission: true,
      account_id: account.id,
      createInviteToken: values.type === 'individual-new-user',
    };

    if (values.phone_number) {
      providerPayload.phone_number = values.phone_number;
    }

    if (values.type === 'individual-new-user') {
      if (values.contact_email === 'my-email') {
        providerPayload.contact_email = email;
      } else if (values.contact_email === 'provider-email') {
        providerPayload.contact_email = values.email;
      } else {
        providerPayload.contact_email = values.other_email_value;
      }

      providerPayload.ownerHasClinicianPermission = values.ownerHasClinicianPermission === 'yes';
    }

    createProvider(providerPayload);
  };

  if (isLoading) {
    return (
      <div className='loader-container w-100 m-t-md flex align-center justify-center'>
        <Loader />
      </div>
    );
  }

  if (!account) {
    return (
      <div
        style={{
          minHeight: '70vh',
          maxWidth: '1080px',
          margin: '0 auto',
        }}
      >
        <p>You do not have access to this part of the Members Portal.</p>
        <p>If a practice owner or another provider paid for your account, please contact them.</p>
        <p>
          If you think you are seeing this in error, please <a href='/contact'>contact support.</a>
        </p>
      </div>
    );
  }

  const checkSetRequired = (values: $TSFixMe, errors: $TSFixMe, c: $TSFixMe) => {
    if (_.isNil(values[c]) || values[c] === '') {
      errors[c] = `${voca.capitalize(c).split('_').join(' ')} required.`;
    }
  };

  return (
    <div>
      <h1 className='m-b-md m-l-xs'>
        Create a new profile! <i className='fad fa-stars text-quaternary'></i>
        <br></br>
        <p className='heading-5 semi-bold'>Let's create a new profile for your account!</p>
      </h1>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          type: 'individual-new-user',
        }}
        validate={(values) => {
          const errors: $TSFixMe = {};

          if (values.type.includes('individual')) {
            ['first_name', 'last_name'].forEach((c) => checkSetRequired(values, errors, c));
          } else {
            ['company_name', 'full_name'].forEach((c) => checkSetRequired(values, errors, c));
          }

          if (values.type === 'practice' || values.type === 'individual') {
            errors.phone_number = createPhoneErrorMessage(values.phone_number, true);
          }

          if (values.type === 'individual-new-user') {
            checkSetRequired(values, errors, 'email');
            checkSetRequired(values, errors, 'ownerHasClinicianPermission');

            if (!rgx.email.test(values.email)) {
              errors.email = 'Invalid email.';
            }

            if (values.contact_email === 'other-email') {
              checkSetRequired(values, errors, 'other_email_value');
            }

            if (values.contact_email === 'provider-email' && !values.email) {
              errors.contact_email = 'Provider email required.';
            }

            errors.phone_number = createPhoneErrorMessage(values.phone_number, true, false);
          }

          return errors;
        }}
        render={(props) => (
          <div className='m-b-lg' key='registration-form'>
            <div className='box m-b-sm'>
              <div className='field m-b-xs flex column show-native-radio'>
                <h4>Which type of new profile are you creating?</h4>
                <Field
                  name='type'
                  component='input'
                  type='radio'
                  value='individual-new-user'
                  id='individual-new-user'
                />
                <label className='m-b-sm' htmlFor='individual-new-user'>
                  Create a new provider profile for another clinician in my practice
                </label>
                <Field
                  name='type'
                  component='input'
                  type='radio'
                  value='practice'
                  id='group-profile'
                />
                <label className='m-b-sm' htmlFor='group-profile'>
                  Create a new group practice profile
                </label>
                <Field
                  name='type'
                  component='input'
                  type='radio'
                  value='individual'
                  id='individual-profile'
                />
                <label className='m-b-sm' htmlFor='individual-profile'>
                  Create a new provider profile for myself
                </label>
              </div>
              <hr></hr>
              <div className='flex row justify-between m-b-sm wrap tab-container'>
                {props.values.type.includes('individual') && (
                  <>
                    <div className='field w-32 m-b-xs'>
                      <h5>First name</h5>
                      <Field
                        name='first_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Cassandra'
                        autocomplete='given-name'
                      />
                      <ErrorField name='first_name' />
                    </div>

                    <div className='field w-32 m-b-xs'>
                      <h5>Middle name</h5>
                      <Field
                        name='middle_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Brené'
                        autocomplete='additional-name'
                      />
                      <ErrorField name='middle_name' />
                    </div>

                    <div className='field w-32 m-b-xs'>
                      <h5>Last name</h5>
                      <Field
                        name='last_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Brown'
                        autocomplete='family-name'
                      />
                      <ErrorField name='last_name' />
                    </div>
                  </>
                )}

                {props.values.type === 'practice' && (
                  <>
                    <div className='field w-49 m-b-xs'>
                      <h5>Group Practice Name</h5>
                      <Field
                        name='company_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Esther Perel Couples Counseling Associates'
                      />
                      <ErrorField name='company_name' />
                    </div>
                    <div className='field w-49 m-b-xs'>
                      <h5>Who is the head of this practice?</h5>
                      <Field
                        name='full_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Esther Perel'
                        autocomplete='name'
                      />
                      <ErrorField name='full_name' />
                    </div>
                  </>
                )}
                {['individual-new-user', 'practice'].includes(props.values.type) && (
                  <div className='field w-49 m-b-xs'>
                    <h5>
                      {props.values.type === 'individual-new-user' && "Provider's email address"}
                      {props.values.type === 'practice' &&
                        'Email address at which to receive referrals'}
                    </h5>
                    <Field
                      name='email'
                      className='w-100 m-b-xs'
                      component='input'
                      autocomplete='email'
                      placeholder={
                        props.values.type.includes('individual')
                          ? 'e.g. brenebrown@gmail.com'
                          : 'e.g. admin@estherperel.com'
                      }
                    />
                    <ErrorField name='email' />
                  </div>
                )}
                {['individual-new-user'].includes(props.values.type) && (
                  <div className='field w-49 m-b-xs'>
                    <h5>Provider's mobile number (optional)</h5>
                    <Field
                      name='phone_number'
                      className='w-100 m-b-xs'
                      component={PhoneInput}
                      inputStyle='w-100'
                      placeholder='e.g. (123) 456-7890'
                    />
                    <ErrorField name='phone_number' />
                  </div>
                )}
                {props.values.type === 'individual-new-user' && (
                  <div className='field w-100 m-b-xs m-t-md'>
                    <h5>
                      An email will be sent to the email address above so your clinician can
                      complete their profile content.
                    </h5>
                  </div>
                )}
                {props.values.type === 'individual-new-user' && (
                  <>
                    <div className='field w-100 m-b-xs m-t-xs'>
                      <h5>To what email address should referrals for this provider be sent?</h5>
                      <Field
                        name='contact_email'
                        component='input'
                        type='radio'
                        value='provider-email'
                        id='provider-email'
                      />
                      <label className='m-b-sm w-100' htmlFor='provider-email'>
                        Provider's email: {props.values.email}
                      </label>
                      <Field
                        name='contact_email'
                        component='input'
                        type='radio'
                        value='my-email'
                        id='my-email'
                      />
                      <label className='m-b-sm w-100' htmlFor='my-email'>
                        My email: {email}
                      </label>
                      <Field
                        name='contact_email'
                        component='input'
                        type='radio'
                        value='other-email'
                        id='other-email'
                      />
                      <label className='m-b-sm w-100 flex' htmlFor='other-email'>
                        Other email:{' '}
                        <Field
                          style={{
                            padding: '0.1rem 0.75rem',
                          }}
                          name='other_email_value'
                          className='w-49 m-b-xs'
                          component='input'
                          autocomplete='email'
                        />
                      </label>
                      <ErrorField name='other_email_value' />
                    </div>
                    <div className='field w-100 m-b-xs m-t-xs'>
                      <h5>Would you like to have edit access to this provider's profile?</h5>
                      <Field
                        name='ownerHasClinicianPermission'
                        component='input'
                        type='radio'
                        value='yes'
                        id='clin-tru'
                      />
                      <label className='m-b-sm w-100' htmlFor='clin-tru'>
                        Yes, I'd like to be able to edit and receive notifications related to this
                        profile's content
                      </label>
                      <Field
                        name='ownerHasClinicianPermission'
                        component='input'
                        type='radio'
                        value='no'
                        id='clin-false'
                      />
                      <label className='m-b-sm w-100' htmlFor='clin-false'>
                        No, I don't want editing access or notifications
                      </label>
                    </div>
                  </>
                )}
                {['individual', 'practice'].includes(props.values.type) && (
                  <div className='field w-49 m-b-xs'>
                    <h5>Mobile number</h5>
                    <Field
                      name='phone_number'
                      className='w-100 m-b-xs'
                      component={PhoneInput}
                      placeholder='e.g. (123) 456-7890'
                      auto-complete='tel-national'
                    />
                    <ErrorField name='phone_number' />
                  </div>
                )}
              </div>
            </div>
            <div className='w-100 flex justify-center'>
              <SubmitButton buttonClass='lg primary pill' buttonText='Save + Next' {...props} />
            </div>
            {duplicateProfileWarningModalOpen && (
              <DuplicateProfileWarningModal
                open={duplicateProfileWarningModalOpen}
                toggle={setDuplicateProfileWarningModalOpen}
                values={props.values}
                submitCreateProvider={submitCreateProvider}
                duplicateProvider={duplicateProvider}
              />
            )}
          </div>
        )}
      />
    </div>
  );
};

export default connect(
  ({ onboarding, main, auth }: $TSFixMe) => ({
    ...onboarding,
    account: _.get(main, 'accounts', []).filter((a: $TSFixMe) => !a.error)[0],
    email: auth.email,
  }),
  {
    createProvider: onboardingActions.createProvider,
  }
)(RegistrationForm);
