import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as tagActions } from '../../taggings/sagaSlice';
import { actions as mainActions } from '../../../main/sagaSlice';
import TaggingCheckboxes from '../../taggings/TaggingCheckboxes';
import SortableTagForm from '../../taggings/SortableTagForm';
import StaticProviderTags from '../../taggings/StaticProviderTags';
import EditBox from '+/EditBox';
import _ from 'lodash';

export const ClienteleApproach = ({
  clientele,
  readEveryClientele,
  approaches,
  readEveryApproach,
  provider,
  updateProvider,
}) => {
  useEffect(() => {
    readEveryClientele();
  }, [readEveryClientele]);

  useEffect(() => {
    readEveryApproach();
  }, [readEveryApproach]);

  const [editingCustomApproaches, setEditingCustomApproaches] = useState(false);
  const [editingClientele, setEditingClientele] = useState(false);
  const [editingGeneralApproaches, setEditingGeneralApproaches] = useState(false);

  const updateCustomApproach = (customApproaches) => {
    updateProvider({
      id: provider.id,
      custom_content: {
        custom_approaches: customApproaches.reduce((a, cur) => {
          if (
            !_.isNil(cur.name) &&
            !_.isNil(cur.description) &&
            cur.name.length > 0 &&
            cur.description.length > 0
          ) {
            return a.concat(cur);
          }

          return a;
        }, []),
      },
    });

    setEditingCustomApproaches(false);
  };

  const customApproaches = _.get(provider, 'custom_content.custom_approaches', []);

  const customForm = (
    <EditBox
      title='Custom Treatment Approaches'
      description={"Don't see your treatment approach listed above? Add it below."}
      editable={true}
      editing={editingCustomApproaches}
      setEditing={setEditingCustomApproaches}
      innerStatic={
        <>
          {customApproaches.map((c) => (
            <div className='sortable-tag-form-label' key={c.name}>
              <h5 className='name'>{c.name}</h5>
              <div>{c.description}</div>
            </div>
          ))}
        </>
      }
      innerEditing={
        <SortableTagForm
          beforeDelete={() =>
            new Promise((resolve, reject) => {
              window.confirm("Are you sure you'd like to delete this custom approach?")
                ? resolve()
                : reject();
            })
          }
          name={editFormName}
          onEdit={updateCustomApproach}
          dataList={customApproaches}
          columns={customApproachColumns}
        />
      }
    />
  );

  return (
    <div>
      <h1 className='m-b-md'>Clientele & Approaches</h1>
      <EditBox
        boxClassName='clientele'
        title='Clientele'
        editable={true}
        editing={editingClientele}
        setEditing={setEditingClientele}
        innerStatic={<StaticProviderTags tagKey='clientele' provider={provider} />}
        innerEditing={
          <TaggingCheckboxes
            tagKey='clientele'
            tagData={clientele.data}
            provider={provider}
            setEditing={setEditingClientele}
          />
        }
      />
      <EditBox
        boxClassName='approaches'
        title='Treatment Approaches'
        description='Select the therapy approaches you have training in and use in your practice.'
        editable={true}
        editing={editingGeneralApproaches}
        setEditing={setEditingGeneralApproaches}
        innerStatic={<StaticProviderTags tagKey='approaches' provider={provider} />}
        innerEditing={
          <TaggingCheckboxes
            tagKey='approaches'
            tagData={approaches.data}
            provider={provider}
            minSelections={1}
            maxSelections={20}
            setEditing={setEditingGeneralApproaches}
          />
        }
      />
      {customForm}
    </div>
  );
};

const editFormName = 'custom-approaches';

const customApproachColumns = [
  {
    header: 'Approach Name',
    key: 'name',
    type: 'textarea',
    maxlength: 45,
    placeholder: 'Name',
  },
  {
    header: null,
    key: 'description',
    type: 'textarea',
    maxlength: 280,
    placeholder: 'Description',
  },
];

const mapStateToProps = ({ clientele, approaches, main, auth }) => ({
  clientele: clientele,
  approaches: approaches,
  provider: main.provider,
});

const actions = {
  readEveryClientele: tagActions.clientele.readEvery,
  readEveryApproach: tagActions.approaches.readEvery,
  updateProvider: mainActions.updateProvider,
};

export default connect(mapStateToProps, actions)(ClienteleApproach);
