/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PracticeMessageForm from './PracticeMessageForm';
import Message from '../provider/appointments/messages/message-types/Message';
import IntakeBooking from '../provider/appointments/messages/message-types/IntakeBooking';
import Reschedule from '../provider/appointments/messages/message-types/Reschedule';
import Cancellation from '../provider/appointments/messages/message-types/Cancellation';
import IntakeMessage from '../provider/appointments/messages/message-types/IntakeMessage';
import OutOfOfficeMessage from '../provider/appointments/messages/message-types/OutOfOfficeMessage';

import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import _ from 'lodash';
import { formatPhoneNumber } from '~/utils/formatPhone';

function CurrentConversation({ showConversation, setShowConversation }) {
  const history = useHistory();
  const [clientIsRedacted, setClientIsRedacted] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [recipientShortName, setRecipientShortName] = useState('');
  const [recipientLongName, setRecipientLongName] = useState('');
  const [recipientPhone, setRecipientPhone] = useState('');
  const [providerName, setProviderName] = useState('');
  const [providerImageUrl, setProviderImageUrl] = useState('');
  const [noConversationFound, setNoConversationFound] = useState(false);
  const [isValidId, setIsValidId] = useState(false);
  const dispatch = useDispatch();
  const { clientId, providerId } = useParams();
  const conversationRef = useRef();
  const { isLoading, conversationMessages, conversations } = useSelector(
    (state) => state.practiceInbox
  );
  const { provider } = useSelector((state) => state.main);
  const account_id = provider?.account_id;

  useEffect(() => {
    setNoConversationFound(false);
    if (clientId && conversations) {
      const matchingConversation = conversations.filter(
        (obj) => obj.id.toString() === clientId && obj.providerId.toString() === providerId
      );
      if (matchingConversation.length) {
        // TODO: handle company names if group practice accounts are allowed to be users
        const shortName = matchingConversation[0].shortName || '';
        const fullName = matchingConversation[0].fullName || '';
        const providerName = matchingConversation[0].providerName || '';
        const providerImageUrl = matchingConversation[0].providerImage || '';
        setClientIsRedacted(matchingConversation[0].isClientRedacted);
        setIsLocked(matchingConversation[0].isLocked);
        setRecipientShortName(shortName);
        setRecipientLongName(fullName);
        setProviderName(providerName);
        setProviderImageUrl(providerImageUrl);
        setRecipientPhone(formatPhoneNumber(matchingConversation[0].phone));
        setShowConversation(true);
        return;
      } else {
        setNoConversationFound(true);
      }
    }
  }, [conversations, clientId]);

  useEffect(() => {
    if (clientId && conversations) {
      setIsValidId(conversations.some((obj) => obj.id.toString() === clientId));
    }
  }, [conversations, clientId]);

  useEffect(() => {
    if (isValidId) {
      return dispatch(
        actions.getConversationMessages({
          provider_id: providerId,
          client_user_id: clientId,
          account_id: account_id,
        })
      );
    }
  }, [dispatch, clientId, isValidId, providerId, account_id]);

  useEffect(() => {
    ReactTooltip.rebuild();
    if (!conversationRef.current) return;
    if (conversationMessages === null) return;
  }, [isLoading, conversationMessages]);

  function renderMessageType(message) {
    const { type } = message;
    let output;
    switch (type) {
      case 'message':
        output = <Message key={message.id} message={message} />;
        break;
      case 'messaging_tool_intake':
        output = <IntakeMessage key={message.id} message={message} />;
        break;
      case 'booking_tool_intake':
        output = <IntakeBooking key={message.id} message={message} />;
        break;
      case 'reschedule_message':
        output = <Reschedule key={message.id} message={message} />;
        break;
      case 'cancel_message':
        output = <Cancellation key={message.id} message={message} />;
        break;
      case 'automated_message_out_of_office':
        output = <OutOfOfficeMessage key={message.id} message={message} />;
        break;
      default:
        console.error(`Invalid message type: ${type}`);
    }
    return output;
  }

  function handleNavigateBack() {
    setShowConversation(false);
    history.push('/practice-inbox/messages');
  }

  if (!conversations || conversations.length < 1) {
    return (
      <div className='current-conversation-placeholder'>
        <h5 className='placeholder-content'>
          You do not have any client messages yet! They will show here once you receive new client
          referrals via Zencare.
        </h5>
      </div>
    );
  }

  if (!clientId) {
    return (
      <div className='current-conversation-placeholder'>
        <h5 className='placeholder-content'>Select a conversation to view message history.</h5>
      </div>
    );
  }

  if (noConversationFound) {
    return (
      <div className='current-conversation-placeholder'>
        <h5 className='placeholder-content'>
          Oops, this conversation doesn’t exist! This may be because this message was removed due to
          inappropriate content or use of the Zencare website. If you think this is a mistake,
          please{' '}
          <a href='/contact' rel='noopener noreferrer' target='_blank' className='placeholder-link'>
            let us know
          </a>
          .
        </h5>
      </div>
    );
  }

  if (!conversationMessages) {
    return <div className='current-conversation-placeholder'>{/* <Loader /> */}</div>;
  }

  return (
    <div className={`current-conversation ${showConversation ? 'show-conversation' : ''}`}>
      <div className='names-wrapper'>
        <button onClick={handleNavigateBack}>
          <i className='far fa-arrow-left'></i>
        </button>
        <div className='names'>
          <h4>{recipientLongName}</h4>
          <div className='provider-info'>
            <h4>{providerName}</h4>
            {providerImageUrl && (
              <img
                src={providerImageUrl}
                alt='Current providers avatar'
                className='provider-avatar'
              />
            )}
          </div>
        </div>
      </div>
      <div ref={conversationRef} className='messages-wrapper'>
        {conversationMessages.map((message) => renderMessageType(message))}
      </div>
      <PracticeMessageForm
        recipientName={recipientShortName}
        recipientPhone={recipientPhone}
        providerName={providerName}
        clientIsRedacted={clientIsRedacted}
        isLocked={isLocked}
      />
      <ReactTooltip
        id='redaction-tooltip'
        effect='solid'
        place='right'
        type='light'
        arrowColor='#37BEC3'
      ></ReactTooltip>
    </div>
  );
}

export default CurrentConversation;
