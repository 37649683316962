import React from 'react';
import history from '#/history';

import PureModal from '+/Modal';

export default function NudgeInsurancesRemovedModal({ open, toggle, statesRemoved }) {
  // eslint-disable-line
  return (
    <PureModal
      visible={open}
      additionalCssClasses='shorter-modal'
      closeModal={() => toggle(false)}
      headerContent={<h4>Update insurances?</h4>}
    >
      <h5>
        It looks like you’re no longer in {statesRemoved}.&nbsp; This is a good time to review your
        listed health insurances and fees.
      </h5>
      <button
        className='pill primary md'
        onClick={() => {
          toggle(false);
          history.push('/profile/insurance-fees');
        }}
      >
        Review insurances
      </button>
    </PureModal>
  );
}
