import { put } from 'redux-saga/effects';
import _ from 'lodash';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';
import { flashSuccess } from '+/flashes/redux';

const sagaSliceModule = createModule<PracticeInboxState>({
  name: 'practiceInbox',
  initialState: {
    appointments: null,
    isLoading: false,
    conversations: [],
    isLoadingConversations: false,
    conversationMessages: [],
    messageSendSuccess: null,
    messageSendError: null,
    messageSendInProgress: false,
    messageDeleteInProgress: false,
    messageDeleteSuccess: false,
    messageDeleteError: null,
    hasUnreadPracticeMessages: false,
    hideLoadMoreButton: false,
  },
  reducers: {
    getAccountAppointments: loadingReducer,
    getAccountAppointmentsSuccess: (state, payload) => {
      state.isLoading = false;
      state.appointments = payload;
    },
    getAccountAppointmentsFail: failReducer,
    sendMessage: loadingReducer,
    // @ts-ignore
    sendMessageSuccess: (state, payload) => {
      state.messageSendInProgress = false;
      state.messageSendSuccess = true;
    },
    // @ts-ignore
    sendMessageFail: (state, payload) => {
      state.messageSendInProgress = false;
      state.messageSendError = payload.toString();
      state.messageSendSuccess = false;
    },
    // @ts-ignore
    deleteMessage: (state, payload) => {
      state.messageDeleteInProgress = true;
      state.messageDeleteSuccess = false;
      state.messageDeleteError = null;
    },
    // @ts-ignore
    deleteMessageSuccess: (state, payload) => {
      state.messageDeleteInProgress = false;
      state.messageDeleteSuccess = true;
    },
    // @ts-ignore
    deleteMessageFail: failReducer,
    // @ts-ignore
    checkForUnreadMessages: (state) => {
      if (!state.conversations) return;
      state.hasUnreadPracticeMessages = state.conversations.some(
        (c: $TSFixMe) => c.isUnread === true
      );
    },

    // @ts-ignore
    getConversations: (state) => {
      // @ts-ignore
      loadingReducer(state);
      state.messageSendSuccess = false;
      state.isLoadingConversations = true;
    },
    // @ts-ignore
    getConversationsSuccess: (state, payload) => {
      const prevCount = state.conversations.length;
      state.isLoading = false;
      state.messageSendSuccess = false;
      state.isLoadingConversations = false;

      // Old non-deduplicated method: 0.06103515625 ms
      // state.conversations = [...state.conversations, ...payload];

      // New deduplication method: 1.289794921875 ms
      const allConversations = [...state.conversations, ...payload];

      state.conversations = allConversations.filter(
        (object, index) =>
          index ===
          allConversations.findIndex(
            (c) => object.id === c.id && object.providerId === c.providerId
          )
      );
      const newCount = state.conversations.length;
      state.hideLoadMoreButton = newCount === prevCount;
    },
    // @ts-ignore
    getConversationsFail: failReducer,
    // @ts-ignore
    getConversationMessages: loadingReducer,
    // @ts-ignore
    getConversationMessagesSuccess: (state, payload) => {
      /**
       * The following array modifications push the loaded conversation
       * to the top of the conversation list and "artificially" mark it
       * as read. While this breaks the ordering of conversations by most
       * recent update and does not reflect our databases record of read receipt,
       * it does prevent the need to reload many conversations (a 6+ sec. request)
       * each time a conversation is selected. Subsequent refreshes of the page do
       * refetch data, which then properly reflect database information.
       * NOTE: Provider level inboxes do not use this method
       */
      const { client_user_id, provider_id } = payload[0];
      const currentConversationIndex = state.conversations.findIndex(
        (c: Conversation) => c.id === client_user_id && c.providerId === provider_id
      );
      const currentConversation = state.conversations.splice(currentConversationIndex, 1)[0];
      currentConversation.isUnread = false;
      state.conversations.unshift(currentConversation);

      state.messageSendSuccess = false;
      state.isLoading = false;
      state.conversationMessages = payload;
    },
    // @ts-ignore
    getConversationMessagesFail: failReducer,
  },
  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.getConversations]: function* ({ payload }: $TSFixMe) {
        const { account_id, limit, offset } = payload;
        yield sagaApi.get(
          `/portal/account/${account_id}/conversations?limit=${limit}&offset=${offset}`,
          A.getConversationsSuccess,
          A.getConversationsFail
        );
        yield put(A.checkForUnreadMessages());
      },
      [A.getAccountAppointments]: function* ({
        payload,
      }: SagaPayload<GetAccountAppointmentsPayload>) {
        const { account_id } = payload;
        yield sagaApi.get(
          `/portal/account/${account_id}/appointments`,
          A.getAccountAppointmentsSuccess,
          A.getAccountAppointmentsFail
        );
      },
      [A.sendMessage]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id, message, account_id } = payload;

        yield sagaApi.post(
          `/portal/provider/${provider_id}/messages/${client_user_id}`,
          { message },
          // Pass as an anonymous function in order to pass account_id down the call stack to getConversations
          (response: { provider_id: number; client_user_id: number }) =>
            A.sendMessageSuccess({ ...response, account_id }),
          A.sendMessageFail
        );
      },
      [A.sendMessageSuccess]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id, account_id } = payload;
        yield put(A.getConversationMessages({ provider_id, client_user_id, account_id }));
      },
      [A.deleteMessage]: function* ({ payload }: $TSFixMe) {
        console.log({ payload });
        const { provider_id, message_id, account_id } = payload;

        yield sagaApi.put(
          `/portal/provider/${provider_id}/messages/${message_id}/delete`,
          payload,
          // Pass as an anonymous function in order to pass account_id
          // down the call stack to getConversations
          (response: { provider_id: number; client_user_id: number }) =>
            A.deleteMessageSuccess({ ...response, account_id }),
          A.deleteMessageFail
        );
      },
      [A.deleteMessageSuccess]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id, account_id } = payload;
        yield put(A.getConversationMessages({ provider_id, client_user_id, account_id }));
      },
      [A.getConversationMessages]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id, account_id } = payload;
        console.log(A.getConversationMessagesSuccess);
        yield sagaApi.get(
          `/portal/provider/${provider_id}/conversation-messages/${client_user_id}`,
          // (response: $TSFixMe) => A.getConversationMessagesSuccess({ ...response, account_id }),
          A.getConversationMessagesSuccess,
          A.getConversationMessagesFail
        );
      },
    };

    return sagas;
  },
});

type SagaPayload<T> = {
  payload: T;
};

interface GetAccountAppointmentsPayload {
  account_id: number;
}

interface PracticeInboxActions {
  actions: {
    getAccountAppointments: (payload: GetAccountAppointmentsPayload) => void;
  };
}

// @ts-ignore: needed to allow override of actions
export const { actions }: PracticeInboxActions = sagaSliceModule;
export default sagaSliceModule;
