import { DateTime } from 'luxon';

export const formatDatePickerInput = (dateString: string): string => {
  let date: DateTime;

  // Try parsing as ISO format first
  date = DateTime.fromISO(dateString);
  if (!date.isValid) {
    // If ISO parsing fails, try with a different format
    date = DateTime.fromFormat(dateString, 'MM/dd/yyyy');
  }
  if (!date.isValid) {
    // If the above format fails, try another format
    date = DateTime.fromFormat(dateString, 'MM/dd/yy');
  }

  // Check if a valid date was parsed
  if (date.isValid) {
    return date.toISODate(); // Format the date to YYYY-MM-DD
  } else {
    // Handle invalid date
    throw new Error('Invalid date format');
  }
};
