import Loader from 'react-loader-spinner';
import React, { useEffect } from 'react';
import { getQueryAsObject } from '~/utils/queryParams';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './sagaSlice';
// import { actions as mainActions } from '../main/sagaSlice';
import StandaloneCheckout from './StandaloneCheckout';
import wrapStripeElements from '~/utils/seat-based/wrapStripeElements';
import { useHistory } from 'react-router-dom';

interface PracticeManagementPostMessageEvent {
  data: {
    url: string;
    clinic_id: number;
  };
  origin: string;
}

interface StandaloneRegistrationProps {
  iframeFileNameOverride?: string;
}

function StandaloneRegistration(props: StandaloneRegistrationProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPayment, setShowPayment] = React.useState(false);
  const { standaloneZencareAccountId, setupComplete, practiceManagementSubdomainName } =
    useSelector<State, PracticeManagementState>((state) => state.practiceManagement);

  // const { accounts } = useSelector<State, MainState>((state) => state.main);

  // if (isSignedIn) {
  // history.push('/practice-management');
  // } else {
  // console.log('NOT SIGNED IN');
  // }

  React.useEffect(() => {
    const { a } = getQueryAsObject(window.location.search);
    // dispatch(mainActions.getAllAccountDetails());
    // dispatch(mainActions.getMe());
    if (a) {
      // @ts-ignore
      dispatch(actions.setStandaloneAccountId({ account_id: a }));
      setShowPayment(true);
    } else {
      dispatch(actions.createStandaloneZencareAccount());
    }
  }, [dispatch]);

  useEffect(() => {
    const handleEvent = (event: PracticeManagementPostMessageEvent) => {
      // Only listen for post messages from our own website (only for local testing) or Owl since they host the iframe on their domain.
      if (
        event.origin === process.env.ZENCARE_PRACTICE_MANAGEMENT_SIGNUP_ORIGIN ||
        event.origin === window.location.origin
      ) {
        console.log(
          `Received postMessage event from ${process.env.ZENCARE_PRACTICE_MANAGEMENT_SIGNUP_ORIGIN} origin (so should be the ZPM iframe)!`
        );
        // console.log(JSON.stringify(event));
        // console.log(JSON.stringify(event.data));

        if (event.data.url && event.data.clinic_id && standaloneZencareAccountId) {
          const sagaParam: AddPracticeManagementAccountFromZpmSignupSagaParam = {
            apiPayload: {
              account_id: standaloneZencareAccountId,
              owl_clinic_id: event.data.clinic_id,
              subdomain: event.data.url,
            },
          };

          // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
          dispatch(actions.addPracticeManagementAccountFromZpmSignup(sagaParam));
          // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
          dispatch(actions.setPracticeManagementSubdomainName(event.data.url));
          history.push(`/zpm-signup?a=${standaloneZencareAccountId}`);
          dispatch(actions.setSetupComplete());
        }
      } else {
        // Note: Keep this logging code commented out. We don't need it. We don't care about other postMessages
        // console.log(`Received postMessage event from a different origin (${event.origin})`);
        // console.log(JSON.stringify(event));
        // console.log(JSON.stringify(event.data));
      }
    };

    window.addEventListener('message', handleEvent, false);
    return function cleanup() {
      window.removeEventListener('message', handleEvent);
    };
  }, [dispatch, history, standaloneZencareAccountId]);

  if (!standaloneZencareAccountId) {
    return (
      <div className='loader-container flex justify-center p-xl w-100'>
        <Loader type='Oval' color='#37BEC3' height={10} width={10} />
      </div>
    );
  }

  if (setupComplete) {
    const practiceManagementDomainName: string | undefined =
      process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME;
    return (
      <>
        <div className='zpm-setup-success w-100'>
          <h2>Welcome to Zencare Practice Management!</h2>
          <img
            src='/zpm-signup/zpm-signup-success-image.png'
            alt='A person using their laptop at a coffee table'
          />
          <p className='success-message'>Log in to your account below to start exploring!</p>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://${practiceManagementSubdomainName}.${practiceManagementDomainName}/`}
          >
            <button className='primary pill md'>Log in to Practice Management</button>
          </a>
        </div>
      </>
    );
  }

  if (showPayment) {
    return (
      <section className='zpm-standalone-registration p-md'>
        <StandaloneCheckout />
      </section>
    );
  }

  return (
    <section className='zpm-standalone-registration p-md'>
      <iframe
        style={{
          width: '100%',
          height: '100vh',
        }}
        title='Zencare Practice Management'
        src={
          props.iframeFileNameOverride
            ? /* Useful if trying to do local dev testing with index-dev-test-immediately-sends-postMessage.html */
              `/zpm-signup/${props.iframeFileNameOverride}?zencare_account_id=${standaloneZencareAccountId}`
            : `${process.env.ZENCARE_PRACTICE_MANAGEMENT_SIGNUP_ORIGIN}/signup-form/index.php?zencare_account_id=${standaloneZencareAccountId}`
        }
      ></iframe>
    </section>
  );
}

export default wrapStripeElements(StandaloneRegistration);
