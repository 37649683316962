import React from 'react';

import Error from './Error';
import PhoneInput from './PhoneInput';

import { fieldHasError, fieldHasErrorAfterSubmission } from '#/forms';

const Input = (formProps) => {
  const {
    input,
    label,
    meta,
    onBlur,
    disabled = false,
    divStyles,
    labelStyle,
    inputStyle,
    isphone,
    placeholder,
    hideErrorsUntilSubmission = false,
  } = formProps;

  const fieldState = fieldHasError(meta) ? 'error' : '';

  const className = `field ${divStyles} ${fieldState}`;

  return (
    <div className={className}>
      <label className={labelStyle}>{label}</label>
      {isphone ? (
        <PhoneInput
          {...input}
          type={input.type || 'text'}
          className={inputStyle}
          onBlur={onBlur || input.onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
      ) : (
        <input
          {...input}
          type={input.type || 'text'}
          className={inputStyle}
          onBlur={onBlur || input.onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
      {hideErrorsUntilSubmission ? (
        // Show existing errors only after form has been submitted once
        fieldHasErrorAfterSubmission(meta) ? (
          <Error {...meta} />
        ) : null
      ) : (
        // Show existing errors prior to submission (default behavior)
        <Error {...meta} />
      )}
    </div>
  );
};

export default Input;
