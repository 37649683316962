// Given roles and a provider id, check if clinician scope exists
export default function hasClinicianScope(accountRoles, providerId) {
  return (
    accountRoles &&
    providerId &&
    accountRoles.length &&
    accountRoles.some(
      (r) =>
        r.role === 'clinician' &&
        r.scope &&
        r.scope.providers &&
        r.scope.providers.includes(providerId)
    )
  );
}
