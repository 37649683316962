/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import Voca from 'voca';
import dayjs from 'dayjs';
import ProviderAvatar from '../ProviderAvatar';
import { ACTIVE_SUBSCRIPTION_STATUSES, CANCELED_SUBSCRIPTION_STATUSES } from '../constants';
import history from '#/history';
import UpcomingInvoiceModal from '../UpcomingInvoiceModal';
import ResubscribeModalSeatBased from '~/components/payment/seat-based/ResubscribeModalSeatBased';
import { useHistory } from 'react-router-dom';
import hasFeatureFlag from '../../../../../utils/hasFeatureFlag';
import { HIDE_REIMBURSIFY_SSO_UPGRADE } from '~/shared/constants/featureFlags';

const SubscriptionProviders = ({
  providers,
  subscription,
  setOpen,
  open,
  // only used for for interim support of subscriptions with provider_ids property
  openContactModal,
  activeAccountProviders,
}: // only used for for interim support of subscriptions with provider_ids property
$TSFixMe) => {
  const handleReactivateClick = () => {
    // If subscription has provider_ids property instead of provider_id property
    // use contact modal to handle the their reactivation
    if (subscription?.metadata?.provider_ids?.length > 0) {
      openContactModal({
        account_id: activeAccountProviders[0].account_id,
        provider_ids: subscription.provider_ids,
        provider_names: activeAccountProviders.map((p: $TSFixMe) =>
          p.first_name ? `${p.first_name} ${p.last_name}` : p.company_name
        ),
      });
    }
    setOpen(true);
  };
  return (
    <div className='practice-provider-grid'>
      {providers.map((p: $TSFixMe) => (
        <div>
          <ProviderAvatar provider={p} />
          {CANCELED_SUBSCRIPTION_STATUSES.includes(subscription.status) && (
            <>
              <button className='primary pill md' onClick={handleReactivateClick}>
                Reactivate Membership
              </button>
              <ResubscribeModalSeatBased
                open={open}
                toggle={setOpen}
                onSubmit={() => ({})}
                provider={p}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export const SeatBasedSubscription = ({
  // subscription,
  // customer,
  allProviders,
  getUpcomingInvoice,
  upgradePlan,
  getAccountById,
  openContactModal,
  account,
  allSubscriptions,
  upgradeReimbursify,
  pendingZencarePracticeManagementClinicData,
}: $TSFixMe) => {
  const [open, setOpen] = useState(false);
  const [viewUpcomingInvoiceOpen, setViewUpcomingInvoiceOpen] = useState(false);
  const [upcomingInvoiceDto, setUpcomingInvoiceDto] = useState(null);
  const subscription = allSubscriptions.find(
    (s: $TSFixMe) => s.id === account.seat_based_stripe_subscription_id
  );

  const activeAccountProviderIds = account.provider_paid_seats
    .filter((seat: $TSFixMe) => seat.canceled_at === null)
    .map((seat: $TSFixMe) => seat.provider_id);

  // DURING NEXT PAIR GET PROVIDERS ON ACCOUNT -- START HERE
  const activeAccountProviders = allProviders.filter((p: Provider) =>
    activeAccountProviderIds.includes(p.id)
  );

  const getZpmButtonText = (account: Account) => {
    // Account is null for legacy subscriptions -- Always provider base button text
    if (!account || !account.tier) return 'Add Practice Management';
    if (account.tier === 'basic') {
      return 'Add Practice Management';
    }
    if (account.tier === 'professional') {
      return 'Upgrade to Practice Management Premium';
    }
    if (account.tier === 'premium') {
      return '';
    }
    return '';
  };
  const zpmButtonText = getZpmButtonText(account);
  const showZpmButton = zpmButtonText && !pendingZencarePracticeManagementClinicData;

  const getReimbursifyButtonText = (account: Account) => {
    // Account is null for legacy subscriptions -- Always provider base button text
    if (!account || !account.has_paid_reimbursify_plan) return 'Upgrade Private Pay Booster';
    if (!account.has_paid_reimbursify_plan) {
      return 'Upgrade Private Pay Booster';
    }
    if (account.reimbursify_tier === 'professional') {
      return 'Upgrade to Private Pay Booster Premium';
    }
    if (account.reimbursify_tier === 'premium') {
      return '';
    }
    return '';
  };
  const reimbursifyButtonText = getReimbursifyButtonText(account);

  const openSubscriptionModal = (stripe_customer_id: string, stripe_subscription_id: string) => {
    getUpcomingInvoice({
      stripe_customer_id,
      stripe_subscription_id,
      onSuccess: (data: $TSFixMe) => {
        setUpcomingInvoiceDto(data);
        setViewUpcomingInvoiceOpen(true);
      },
      onError: (error: $TSFixMe) => {
        alert(error.message);
        throw new Error(
          `Error getting upcoming invoice for stripe_customer_id ${stripe_customer_id} and stripe_subscription_id ${stripe_subscription_id}: ${error}`
        );
      },
    });
  };

  const formatSubscriptionItem = (
    item: $TSFixMe,
    subscription: $TSFixMe,
    activeAccountProviders: $TSFixMe,
    getUpcomingInvoice: $TSFixMe
  ) => {
    const name = `Zencare Membership${
      account.tier === 'basic'
        ? ''
        : account.tier === 'professional'
        ? ' + Practice Management Pro'
        : ' + Practice Management Premium'
    }${
      account.has_paid_reimbursify_plan
        ? account.reimbursify_tier === 'professional'
          ? ' + Private Pay Booster Pro'
          : ' + Private Pay Booster Premium'
        : ''
    }`;

    const statusColor = (status: $TSFixMe) => {
      if (['incomplete', 'past_due', 'unpaid'].includes(status)) {
        return 'quaternary';
      }

      if (ACTIVE_SUBSCRIPTION_STATUSES.includes(status)) {
        return 'primary';
      }

      return 'secondary';
    };

    const statusName = (status: $TSFixMe) => {
      if (status === 'trialing') {
        status = 'active';
      }

      if (status === 'incomplete_expired') {
        status = 'expired';
      }

      return Voca.capitalize(status);
    };

    const nextPaymentLine = () => {
      const oneYearFromSubscriptionStart = dayjs(subscription.created).add(1, 'year');
      const isMonthly = item.price.recurring && item.price.recurring.interval === 'month';
      if (
        ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) &&
        isMonthly &&
        dayjs().isBefore(oneYearFromSubscriptionStart)
      ) {
        return (
          <p className='m-b-0'>
            <span className='semi-bold'>Next Payment Date</span>:{' '}
            {dayjs(subscription.current_period_end).format('MMMM D, YYYY')}
          </p>
        );
      }

      return null;
    };

    const subscriptionTermsAndRenewalLine = () => {
      const oneYearFromSubscriptionStart = dayjs(subscription.created).add(1, 'year');
      const isBeforeOneYearFromSubscriptionStart = dayjs().isBefore(oneYearFromSubscriptionStart);
      const isMonthly = item.price.recurring && item.price.recurring.interval === 'month';
      // Conditionally showing yearly is needed because we extend dates for yearly after profile goes live
      const shouldShowForYearly = activeAccountProviders.find(
        (p: $TSFixMe) => p.status === 'active'
      );
      // Only shown during first year for monthly subscriptions
      if (ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status)) {
        return (
          <>
            {isMonthly && isBeforeOneYearFromSubscriptionStart && (
              <>
                <p>
                  <span className='semi-bold'>Renewal Date:</span>{' '}
                  {oneYearFromSubscriptionStart.format('MMMM D, YYYY')}
                  <div>
                    <button
                      className='view-next-invoice-button'
                      onClick={() => {
                        openSubscriptionModal(subscription.customer_id, subscription.id);
                      }}
                    >
                      View invoice
                    </button>
                  </div>
                </p>
              </>
            )}
            {((isMonthly && !isBeforeOneYearFromSubscriptionStart) ||
              (!isMonthly && shouldShowForYearly)) && (
              <>
                {/* The renewal date for annual subscriptions or monthly ones after 1 year is always the next payment date */}
                <p>
                  <span className='semi-bold'>Renewal Date:</span>{' '}
                  {dayjs(subscription.current_period_end).format('MMMM D, YYYY')}
                  <div>
                    <button
                      className='view-next-invoice-button'
                      onClick={() => {
                        openSubscriptionModal(subscription.customer_id, subscription.id);
                      }}
                    >
                      View next invoice
                    </button>
                  </div>
                </p>
              </>
            )}
            <p
              style={{
                color: '#484848',
                fontSize: '0.9rem',
                lineHeight: '1.25rem',
              }}
            >
              <span className='semi-bold'>Terms:</span> "I agree to the{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://zencare.co/policy/terms'
                style={{
                  fontSize: '0.9rem',
                  lineHeight: '1.25rem',
                }}
              >
                Terms Of Service
              </a>
              . I understand that the Zencare membership is an annual commitment, that I will be
              responsible for the remainder of the year if I cancel early, and that no refunds are
              issued. When eligible, subscription change requests must be made by providing fifteen
              (15) days' notice prior to your renewal date, or next payment date."
            </p>
          </>
        );
      }

      return null;
    };

    const upgradePayload = {
      account_id: account.id,
      provider_ids: account.provider_paid_seats
        .filter((s: $TSFixMe) => s.canceled_at === null)
        .map((s: $TSFixMe) => s.provider_id),
      provider_names: activeAccountProviders.map((p: $TSFixMe) =>
        p.first_name ? `${p.first_name} ${p.last_name}` : p.company_name
      ),
    };

    return (
      <div key={subscription.id}>
        <div className='flex align-center'>
          <h5 className='m-b-0 m-r-sm'>{name}</h5>
          <span
            className={`subscription-status-bubble m-r-sm text-${statusColor(subscription.status)}`}
          >
            {statusName(subscription.status)}
          </span>
          {showZpmButton && (
            <button
              className='primary pill md'
              onClick={() => upgradePlan(upgradePayload, subscription)}
            >
              {zpmButtonText}
            </button>
          )}
          {!hasFeatureFlag('account', account, HIDE_REIMBURSIFY_SSO_UPGRADE) &&
            reimbursifyButtonText && (
              <button
                className='primary pill md m-l-sm'
                onClick={() => upgradeReimbursify(upgradePayload)}
              >
                {reimbursifyButtonText}
              </button>
            )}
        </div>
        {ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) && (
          <>
            <hr className='m-t-sm m-b-sm' />
            {nextPaymentLine()}
            {subscriptionTermsAndRenewalLine()}
            <hr className='m-t-sm m-b-sm' />
          </>
        )}

        <UpcomingInvoiceModal
          dto={upcomingInvoiceDto}
          showModal={viewUpcomingInvoiceOpen}
          setShowModal={setViewUpcomingInvoiceOpen}
        />
      </div>
    );
  };

  return (
    <div className='account-box m-r-sm'>
      {subscription.items.data && subscription.items.data.length && (
        <>
          {/* We only need one subscription item here,
              there is no NY/CA vs non NY/CA or calculations
              where all items are needed. */}
          {formatSubscriptionItem(
            subscription.items.data[0],
            subscription,
            activeAccountProviders,
            getUpcomingInvoice
          )}
        </>
      )}
      <p>Profiles covered by this membership:</p>
      <SubscriptionProviders
        providers={activeAccountProviders}
        subscription={subscription}
        setOpen={setOpen}
        open={open}
        openContactModal={openContactModal}
        activeAccountProviders={activeAccountProviders}
      />
    </div>
  );
};

export const IncompleteSubscription = ({ provider }: $TSFixMe) => (
  <div>
    <div className='flex align-center'>
      <h5 className='m-b-0 m-r-sm'>
        {provider.type === 'individual' ? 'Individual Provider' : 'Group Practice'} Profile
      </h5>
      <span className={'subscription-status-bubble text-secondary'}>Membership Not Started</span>
    </div>
    <ProviderAvatar provider={provider} />
    <button
      className='primary pill md'
      onClick={() => {
        if (provider.locations && provider.locations.length) {
          history.push(`profiles/pay-for/${provider.id}?return_to_plan_management=1`);
        } else {
          history.push(`profiles/create/${provider.id}`);
        }
      }}
    >
      Begin Membership
    </button>
  </div>
);
