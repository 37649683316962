/* eslint-disable line-comment-position */
import { put, select, all, takeEvery } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
// @ts-ignore -- $TSFixMe
import { failReducer, loadingReducer, notLoadingReducer, noop } from 'saga-slice-helpers';
import get from 'lodash/get';
import _ from 'lodash';
import { downloadCSV } from '../../utils/csv';

import { sagaApi, api } from '#/apis';

import { flashError, flashSuccess } from '+/flashes/redux';
import storage from '#/storage';
import mixpanel from 'mixpanel-browser';

const initialState = {
  isFetching: false,
  isLoading: false,
  error: null,
  locations: [],
  // current provider
  provider: null,
  // all providers associated with user
  allProviders: [],
  accounts: [],
  unusedReferrals: [],
  checklist: [],
  testimonials: [],
  feedback: [],
  licenses: [],
  insuranceOptOuts: [],
  prevState: null,
  goLiveFailure: false,
  goLiveResult: null,
  actionItemData: null,
  pendingZencarePracticeManagementClinicData: null,
};

const sagaSliceModule = createModule<MainState>({
  name: 'main',

  initialState: {
    ...initialState,
  },

  reducers: {
    clearStore: (state) => {
      state = {
        ...initialState,
      };

      return state;
    },

    clearDashboardData: (state) => {
      state.checklist = [];
      state.licenses = [];
    },

    toggleChangeRequest: (state) => {
      state.changeRequestSubmitted = !state.changeRequestSubmitted;
    },

    selectActiveProvider: (state, payload) => {
      state.isLoading = true;

      const provider = state.allProviders.find((x: $TSFixMe) => x.id === payload.id);
      if (provider) {
        storage.set('current_provider_id', payload.id);
        state.provider = provider;
        state.locations = provider.locations;
      }
    },
    selectActiveProviderSuccess: notLoadingReducer,
    selectActiveProviderFailure: failReducer,

    getMe: loadingReducer,
    getMeSuccess: (state, payload) => {
      const { providers, unusedReferrals } = payload;
      //state.allProviders = providers;
      if (payload.user && payload.user.id) {
        mixpanel.identify(payload.user.id);
      }
      if (payload.providers.length) {
        const locallyStoredId = storage.get('current_provider_id');
        const id = locallyStoredId || _.get(state, 'provider.id');
        const p =
          providers.find((x: $TSFixMe) => x.id === id) ||
          providers.find((x: $TSFixMe) => x.type === 'practice') ||
          providers[0];
        if (p.id !== locallyStoredId) {
          storage.set('current_provider_id', p.id);
        }
        state.provider = p;
        state.locations = p.locations;
        state.insuranceOptOuts = p.insurance_opt_outs;
      }

      state.unusedReferrals = unusedReferrals;
    },
    getMeFailure: failReducer,

    getAllMyProviders: loadingReducer,
    getAllMyProvidersSuccess: (state, payload) => {
      const { providers, unusedReferrals } = payload;
      state.allProviders = providers;

      if (payload.providers.length) {
        const locallyStoredId = storage.get('current_provider_id');
        const id = locallyStoredId || _.get(state, 'provider.id');
        const p =
          providers.find((x: $TSFixMe) => x.id === id) ||
          providers.find((x: $TSFixMe) => x.type === 'practice') ||
          providers[0];
        if (p.id !== locallyStoredId) {
          storage.set('current_provider_id', p.id);
        }
        state.provider = p;
        state.locations = p.locations;
      }

      state.unusedReferrals = unusedReferrals;
    },
    getAllMyProvidersFailure: failReducer,

    getAllAccountDetails: loadingReducer,
    getAllAccountDetailsSuccess: (state, payload) => {
      state.isLoading = false;
      state.accounts = payload;
      // TODO: Is this an undesirable patter, a copy from PlanManagement
      // Goal is to have this data be accessible outside of PlanManagement
      // Parsing Accounts data on fetch seemed to be a reasonable way to make this information accessible
      // end goal is enabling CSX to be able to link to membership/switch-to-monthly instead of linking to plan management
      // or refresh the page and still use the information in state
      if (payload && payload.length) {
        const accountsWithPermission = payload.filter((a: Account) => !a.error);
        if (accountsWithPermission) {
          const customers = _.flatten(
            accountsWithPermission.map((a: Account) => a.stripe_customers)
          ).filter((x) => !!x);
          const subs = _.flattenDeep(
            customers.map((c: $TSFixMe) =>
              c.stripe_subscriptions.map((s: $TSFixMe) => ({
                ...s,
                customer: c,
              }))
            )
          );
          // @ts-ignore: Not certain how to solve this "unknown" TS error. Note that state.customer is an array of customers
          state.customer = customers;
          state.subscriptions = subs;
        }
      }
    },
    getAllAccountDetailsFailure: failReducer,

    setLoading: (state, loading) => {
      state.isLoading = loading;
    },

    updateProvider: loadingReducer,
    updateProviderSuccess: notLoadingReducer,
    updateProviderFailure: failReducer,

    updateAvailability: loadingReducer,
    updateAvailabilitySuccess: notLoadingReducer,
    updateAvailabilityFailure: failReducer,

    updateLocation: loadingReducer,
    updateLocationSuccess: notLoadingReducer,
    updateLocationFailure: failReducer,

    updateTagging: loadingReducer,
    updateTaggingSuccess: (state, payload) => {
      state.isLoading = false;
    },
    updateTaggingFailure: failReducer,
    updateMultipleTaggings: loadingReducer,
    updateMultipleTaggingsSuccess: (state, payload) => {
      state.isLoading = false;
    },
    updateMultipleTaggingsFailure: failReducer,

    getLicenses: loadingReducer,
    getLicensesSuccess: (state, data) => {
      state.licenses = data;
    },
    getLicensesFailure: failReducer,

    getInsuranceOptOuts: loadingReducer,
    getInsuranceOptOutsSuccess: (state, data) => {
      state.insuranceOptOuts = data;
    },
    getInsuranceOptOutsFailure: failReducer,

    addInsuranceOptOuts: loadingReducer,
    addInsuranceOptOutsSuccess: notLoadingReducer,
    addInsuranceOptOutsFailure: failReducer,

    removeInsuranceOptOuts: loadingReducer,
    removeInsuranceOptOutsSuccess: notLoadingReducer,
    removeInsuranceOptOutsFailure: failReducer,

    updateLicenses: loadingReducer,
    updateLicensesSuccess: (state, payload) => {
      state.isLoading = false;
    },
    updateLicensesFailure: failReducer,

    deleteSupervisorLicense: loadingReducer,
    deleteSupervisorLicenseSuccess: (state, payload) => {
      state.isLoading = false;
    },
    deleteSupervisorLicenseFailure: failReducer,

    updateSupervisorLicense: loadingReducer,
    updateSupervisorLicenseSuccess: (state, payload) => {
      state.isLoading = false;
    },
    updateSupervisorLicenseFailure: failReducer,

    updateTaggingSearchability: loadingReducer,
    updateTaggingSearchabilityFailure: failReducer,
    updateTaggingSearchabilitySuccess: notLoadingReducer,

    setPrevState: (state, data) => {
      state.prevState = data;
    },

    patchProvider: (state, provider) => {
      if (state.provider) {
        state.provider = {
          ...state.provider,
          ...provider,
        };
      } else {
        state.provider = provider;
      }
    },

    addProvider: (state, provider) => {
      if (!state.allProviders.find((p: $TSFixMe) => p.id === provider.id)) {
        state.allProviders.push(provider);
      }
    },

    updatePracticeSort: loadingReducer,
    updatePracticeSortSuccess: notLoadingReducer,
    updatePracticeSortFailure: failReducer,

    dissociateProvider: loadingReducer,
    dissociateProviderSuccess: notLoadingReducer,
    dissociateProviderFailure: failReducer,

    createLocationWithLicense: loadingReducer,
    createLocationWithLicenseSuccess: notLoadingReducer,
    createLocationWithLicenseFailure: failReducer,

    deleteLocation: loadingReducer,
    deleteLocationSuccess: notLoadingReducer,
    deleteLocationFailure: failReducer,

    contactZencare: loadingReducer,
    contactZencareSuccess: notLoadingReducer,
    contactZencareFailure: failReducer,

    goLive: loadingReducer,
    goLiveSuccess: (state, payload) => {
      state.isLoading = false;
      state.goLiveResult = payload;
    },
    goLiveFailure: (state, payload) => {
      state.goLiveFailure = true;
      state.isLoading = false;
    },

    getChecklist: loadingReducer,
    getChecklistSuccess: (state, data) => {
      state.checklist = data;
    },
    getChecklistFailure: failReducer,

    getProviderFeedback: loadingReducer,
    getProviderFeedbackSuccess: (state, data) => {
      state.testimonials = data.filter((x: $TSFixMe) => x.testimonial && x.testimonial.length);
      state.feedback = data.filter((x: $TSFixMe) => x.feedback && x.feedback.length);
    },
    getProviderFeedbackFailure: failReducer,

    updateTestimonialDisplay: loadingReducer,
    updateTestimonialDisplaySuccess: notLoadingReducer,
    updateTestimonialDisplayFailure: failReducer,

    logEvent: noop,
    logEventSuccess: noop,
    logEventFailure: failReducer,

    getRelevantInsurances: loadingReducer,
    getRelevantInsurancesSuccess: (state, data) => {
      state.relevantInsurances = data;
    },
    getRelevantInsurancesFailure: failReducer,

    getActionItemData: loadingReducer,
    getActionItemDataSuccess: (state, data) => {
      state.actionItemData = data;
    },
    getActionItemDataFailure: failReducer,

    exportIntakes: loadingReducer,
    exportIntakesSuccess: (state, payload) => downloadCSV(payload, 'referral_data'),
    exportIntakesFailure: failReducer,

    getHostedInvoiceLinkFromStripe: noop,
    getHostedInvoiceLinkFromStripeSuccess: (state, hostedStripeUrl) => {
      window.open(hostedStripeUrl);
    },
    getHostedInvoiceLinkFromStripeFailure: () => {
      alert(
        'Unable to get link to invoice. Please contact support by heading to https://members.zencare.co/contact and filling out the form.'
      );
    },

    getHostedChargeInvoiceLinkFromStripe: noop,
    getHostedChargeInvoiceLinkFromStripeSuccess: (state, hostedStripeUrl) => {
      window.open(hostedStripeUrl);
    },
    getHostedChargeInvoiceLinkFromStripeFailure: () => {
      alert(
        'Unable to get link to charge/refund. Please contact support by heading to https://members.zencare.co/contact and filling out the form.'
      );
    },
    getPendingZencarePracticeManagementClinics: loadingReducer,
    getPendingZencarePracticeManagementClinicsSuccess: (state, payload) => {
      state.isLoading = false;
      state.pendingZencarePracticeManagementClinicData = payload;
    },
    getPendingZencarePracticeManagementClinicsFailure: failReducer,

    updateOutOfOfficeMessage: loadingReducer,
    updateOutOfOfficeMessageSuccess: notLoadingReducer,
    updateOutOfOfficeMessageFailure: failReducer,

    updateOutOfOfficeMessageStatus: loadingReducer,
    updateOutOfOfficeMessageStatusSuccess: notLoadingReducer,
    updateOutOfOfficeMessageStatusFailure: failReducer,

    toggleBookingLink: loadingReducer,
    toggleBookingLinkSuccess: notLoadingReducer,
    toggleBookingLinkFailure: failReducer,
  },
  takers: {
    // updateTagging: takeEvery,
    takeEvery: ['updateTagging'],
  },
  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.selectActiveProvider]: function* (payload: $TSFixMe) {
        // Clear Message data
        yield put({ type: 'messages/clearStore' });
        // Clear Dashboard data
        yield put(A.clearDashboardData());
        yield put({ type: 'appointments/clearStore' });
        // General Information data should be clear automatically
        if (payload.id) {
          yield sagaApi.get(
            `portal/me/${payload.id}`,
            A.selectActiveProviderSuccess,
            A.selectActiveProviderFailure
          );
        } else {
          yield sagaApi.get(
            `portal/me`,
            A.selectActiveProviderSuccess,
            A.selectActiveProviderFailure
          );
        }
      },
      [A.selectActiveProviderSuccess]: function* () {
        const provider: Provider = yield select((state) => state.main.provider);
        const name =
          provider.type === 'individual'
            ? `${provider.first_name} ${provider.last_name}`
            : provider.company_name;
        yield put(flashSuccess(`You've switched to ${name}'s profile`));
        yield put({
          type: 'messages/getConversations',
          payload: {
            provider_id: provider.id,
          },
        });
      },
      [A.getMe]: function* () {
        yield put(A.setLoading(true));
        const locallyStoredId = storage.get('current_provider_id');

        if (locallyStoredId) {
          yield sagaApi.get(`portal/me/${locallyStoredId}`, A.getMeSuccess, A.getMeFailure);
        } else {
          yield sagaApi.get(`portal/me`, A.getAllMyProvidersSuccess, A.getAllMyProvidersFailure);
        }
        yield put(A.setLoading(false));
      },
      [A.getAllAccountDetails]: function* () {
        yield sagaApi.get(
          'portal/account-details',
          A.getAllAccountDetailsSuccess,
          A.getAllAccountDetailsFailure
        );
      },
      [A.getMeFailure]: function* () {
        yield put(flashError('There was an error loading your profile.'));
      },
      [A.getMeSuccess]: function* ({ payload }: $TSFixMe) {
        yield put({
          type: 'auth/setUserSettings',
          payload: {
            ...payload.user,
            roles: payload.roles,
            enrolledInTwoFactor: payload.enrolledInTwoFactor,
          },
        });
      },

      [A.getAllMyProviders]: function* () {
        yield put(A.setLoading(true));
        yield sagaApi.get(`portal/me`, A.getAllMyProvidersSuccess, A.getAllMyProvidersFailure);

        yield put(A.setLoading(false));
      },
      [A.getAllMyProvidersFailure]: function* () {
        yield put(flashError('There was an error loading your profile.'));
      },
      [A.getAllMyProvidersSuccess]: function* ({ payload }: $TSFixMe) {
        yield put({
          type: 'auth/setUserSettings',
          payload: {
            ...payload.user,
            roles: payload.roles,
            enrolledInTwoFactor: payload.enrolledInTwoFactor,
          },
        });

        const provider: Provider = yield select((state: State) => state.main.provider);

        const locations: MainState['locations'] = yield select(
          (state: State) => state.main.locations
        );

        const prevState: MainState['prevState'] = yield select(
          (state: State) => state.main.prevState
        );
        const bothOff =
          (prevState || []).filter((a: $TSFixMe) => a === 0).length === locations.length;
        // const display_acuity_link = get(provider, 'custom_content.display_acuity_scheduling_link', false);
        const acuity_link = get(provider, 'custom_content.acuity_link', '');
        // const display_contact_form = get(provider, 'custom_content.display_contact_form', false);
        if (bothOff && acuity_link) {
          yield put(A.setPrevState(null));
          yield put(
            A.updateProvider({
              id: provider.id,
              custom_content: {
                display_acuity_scheduling_link: true,
              },
            })
          );
        }
      },

      [A.updateProvider]: function* ({ payload }: $TSFixMe) {
        const id = payload.id;
        delete payload.id;
        yield sagaApi.put(
          `portal/provider/${id}`,
          payload,
          A.updateProviderSuccess,
          A.updateProviderFailure
        );
      },
      [A.updateProviderSuccess]: function* ({ payload }: $TSFixMe) {
        yield put(A.patchProvider(payload));
      },
      [A.updateProviderFailure]: function* () {
        yield put(flashError('There was an error updating your profile.'));
      },

      [A.updatePracticeSort]: function* ({ payload }: $TSFixMe) {
        const id = payload.id;
        delete payload.id;
        const response: Provider = yield sagaApi.put(
          `portal/provider/${id}/practice-sort`,
          payload,
          A.updatePracticeSortSuccess,
          A.updatePracticeSortFailure
        );
        yield put(A.patchProvider(response));
      },
      [A.updatePracticeSortSuccess]: function* () {
        yield put(A.getMe());
      },

      [A.dissociateProvider]: function* ({ payload }: $TSFixMe) {
        const practiceId = payload.practice_id;
        const providerId = payload.provider_id;

        yield sagaApi.delete(
          `portal/provider/${practiceId}/practice-providers/${providerId}`,
          null,
          A.dissociateProviderSuccess,
          A.dissociateProviderFailure
        );
      },
      [A.updateAvailability]: function* ({ payload }: $TSFixMe) {
        const id = payload.id;
        delete payload.id;
        yield sagaApi.put(
          `portal/locations/${id}`,
          payload,
          A.updateAvailabilitySuccess,
          A.updateAvailabilityFailure
        );
      },
      [A.updateAvailabilitySuccess]: function* () {
        const locations: MainState['locations'] = yield select((state) =>
          Object.values(state.main.locations)
        );
        const prevState = locations.map((l: $TSFixMe) => l.accepting_patients);
        yield put(A.setPrevState(prevState));

        yield put(A.getMe());
      },
      [A.updateAvailabilityFailure]: function* () {
        yield put(flashError('There was an error updating your availability.'));
      },

      [A.updateLocation]: function* ({ payload }: $TSFixMe) {
        const id = payload.id;
        delete payload.id;
        yield sagaApi.put(
          `/portal/locations/${id}`,
          payload,
          A.updateLocationSuccess,
          A.updateLocationFailure
        );
      },
      [A.updateLocationSuccess]: function* () {
        yield put(A.getMe());
      },
      [A.updateLocationFailure]: function* () {
        yield put(flashError('There was an error updating your location.'));
      },
      [A.createLocationWithLicense]: function* ({ payload }: $TSFixMe) {
        const id = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.post(
          `/portal/provider/${id}/location-with-license`,
          payload,
          A.createLocationWithLicenseSuccess,
          A.createLocationWithLicenseFailure
        );
      },
      [A.createLocationWithLicenseSuccess]: function* () {
        yield put(A.getMe());
      },
      [A.deleteLocation]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.delete(
          `/portal/location/${payload.id}`,
          {},
          A.deleteLocationSuccess,
          A.deleteLocationFailure
        );
      },
      [A.deleteLocationSuccess]: function* () {
        yield put(A.getMe());
      },
      [A.updateTagging]: function* ({ payload }: $TSFixMe) {
        if (payload.associate) {
          yield sagaApi.put(
            `portal/providers/${payload.id}/${payload.tagTypeSingular}/${payload.tagId}`,
            payload,
            A.updateTaggingSuccess,
            A.updateTaggingFailure
          );
        } else {
          yield sagaApi.delete(
            `portal/providers/${payload.id}/${payload.tagTypeSingular}/${payload.tagId}`,
            payload,
            A.updateTaggingSuccess,
            A.updateTaggingFailure
          );
        }
      },
      [A.updateTaggingSuccess]: function* () {
        yield put(A.getMe());
      },
      [A.updateMultipleTaggings]: function* ({ payload }: $TSFixMe) {
        try {
          yield api.put(
            `portal/provider/${payload.providerId}/taggings`,
            { entries: payload.entries },
            { timeout: 150000 }
          );
        } catch (e) {
          yield put(
            flashError(
              'There was an error saving your changes. Please contact us at hello@zencare.co if you continue to have this issue.'
            )
          );
        }
        yield put(A.getMe());
      },
      [A.getLicenses]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.get(
          `portal/provider/${payload.id}/licenses`,
          A.getLicensesSuccess,
          A.getLicensesFailure
        );
      },
      [A.updateLicenses]: function* ({ payload }: $TSFixMe) {
        const providerId = payload.providerId;
        delete payload.providerId;
        yield sagaApi.put(
          `portal/provider/${providerId}/licenses`,
          payload,
          A.updateLicensesSuccess,
          A.updateLicensesFailure
        );
        yield sagaApi.get(
          `portal/provider/${providerId}/licenses`,
          A.getLicensesSuccess,
          A.getLicensesFailure
        );
      },
      [A.deleteSupervisorLicense]: function* ({ payload }: { payload: SupervisorLicensePayload }) {
        const { provider_id } = payload;
        delete payload.provider_id;
        yield sagaApi.put(
          `portal/provider/${provider_id}/supervisor-license/delete`,
          payload,
          A.deleteSupervisorLicenseSuccess,
          A.deleteSupervisorLicenseFailure
        );
        yield sagaApi.get(
          `portal/provider/${provider_id}/licenses`,
          A.getLicensesSuccess,
          A.getLicensesFailure
        );
      },
      [A.updateSupervisorLicense]: function* ({ payload }: { payload: SupervisorLicensePayload }) {
        const { provider_id } = payload;
        delete payload.provider_id;
        yield sagaApi.put(
          `portal/provider/${provider_id}/supervisor-license/update`,
          payload,
          A.updateSupervisorLicenseSuccess,
          A.updateSupervisorLicenseFailure
        );
        yield sagaApi.get(
          `portal/provider/${provider_id}/licenses`,
          A.getLicensesSuccess,
          A.getLicensesFailure
        );
      },
      [A.getInsuranceOptOuts]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.get(
          `portal/provider/${payload.id}/insurance-opt-outs`,
          A.getInsuranceOptOutsSuccess,
          A.getInsuranceOptOutsFailure
        );
      },
      [A.addInsuranceOptOuts]: function* ({ payload }: $TSFixMe) {
        const providerId = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.put(
          `portal/insurance-opt-outs/${providerId}`,
          payload,
          A.addInsuranceOptOutsSuccess,
          A.addInsuranceOptOutsFailure
        );
      },
      [A.addInsuranceOptOutsSuccess]: function* () {
        yield put(A.getMe());
      },
      [A.removeInsuranceOptOuts]: function* ({ payload }: $TSFixMe) {
        const providerId = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.post(
          `portal/insurance-opt-outs/remove/${providerId}`,
          payload,
          A.removeInsuranceOptOutsSuccess,
          A.removeInsuranceOptOutsFailure
        );
      },
      [A.removeInsuranceOptOutsSuccess]: function* () {
        yield put(A.getMe());
      },
      [A.updateTaggingSearchability]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.put(
          `portal/provider/${payload.provider_id}/tag/${payload.tag_id}/search/${payload.enable}`,
          null,
          A.updateTaggingSearchabilitySuccess,
          A.updateTaggingSearchabilityFailure
        );
      },
      [A.updateTaggingSearchabilitySuccess]: function* () {
        yield put(A.getMe());
      },

      [A.contactZencare]: function* ({ payload }: $TSFixMe) {
        const id = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.post(
          `portal/contact-zencare/${id}`,
          payload,
          A.contactZencareSuccess,
          A.contactZencareFailure
        );
      },
      [A.goLive]: function* ({ payload }: $TSFixMe) {
        const id = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.post(`portal/go-live/${id}`, null, A.goLiveSuccess, A.goLiveFailure);
      },
      [A.getChecklist]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.get(
          `portal/provider/${payload.id}/checklist`,
          A.getChecklistSuccess,
          A.getChecklistFailure
        );
      },
      [A.getChecklistSuccess]: function* () {
        yield put(A.getMe());
      },

      [A.getProviderFeedback]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.get(
          `portal/provider/${payload.id}/provider-feedback`,
          A.getProviderFeedbackSuccess,
          A.getProviderFeedbackFailure
        );
      },
      [A.updateTestimonialDisplay]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.put(
          `/portal/provider/${payload.provider_id}/provider-feedback/testimonial/${payload.provider_feedback_id}`,
          { display_testimonial: payload.display_testimonial },
          A.updateTestimonialDisplaySuccess,
          A.updateTestimonialDisplayFailure
        );
      },
      [A.updateTestimonialDisplayFailure]: function* () {
        yield put(
          flashError('There was an error changing the display setting for the testimonial.')
        );
      },
      [A.logEvent]: function* ({ payload }: $TSFixMe) {
        const id = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.post(
          `portal/event/${id}/log-event`,
          payload,
          A.logEventSuccess,
          A.logEventFailure
        );
      },
      [A.getRelevantInsurances]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.get(
          `portal/provider/${payload.id}/relevant-insurances`,
          A.getRelevantInsurancesSuccess,
          A.getRelevantInsurancesFailure
        );
      },
      [A.updateInsurances]: function* ({ payload }: $TSFixMe) {
        try {
          yield all(
            payload.updates.map(function* (p: $TSFixMe) {
              if (p.associate) {
                yield api.put(`portal/providers/${p.id}/${p.tagTypeSingular}/${p.tagId}`, p);
              } else {
                yield api.delete(`portal/providers/${p.id}/${p.tagTypeSingular}/${p.tagId}`, p);
              }
            })
          );
          yield put(A.getMe());
        } catch (error) {
          yield put(flashError('There was an error updating your profile.'));
        }
      },
      [A.getActionItemData]: function* ({ payload }: $TSFixMe) {
        yield sagaApi.get(
          `portal/provider/${payload.id}/action-item-data`,
          A.getActionItemDataSuccess,
          A.getActionItemDataFailure
        );
      },
      [A.exportIntakes]: function* ({ payload }: SagaPayload<ExportIntakesPayload>) {
        const { account_id } = payload;

        yield sagaApi.get(
          `portal/account/export-intakes/${account_id}`,
          A.exportIntakesSuccess,
          A.exportIntakesFailure
        );
      },
      [A.getHostedInvoiceLinkFromStripe]: function* ({
        payload,
      }: SagaPayload<{ stripe_customer_id: string; stripe_invoice_id: string }>) {
        const { stripe_customer_id, stripe_invoice_id } = payload;

        yield sagaApi.get(
          `portal/invoice/get-hosted-link/${stripe_customer_id}/${stripe_invoice_id}`,
          A.getHostedInvoiceLinkFromStripeSuccess,
          A.getHostedInvoiceLinkFromStripeFailure
        );
      },
      [A.getHostedChargeInvoiceLinkFromStripe]: function* ({
        payload,
      }: SagaPayload<{ stripe_customer_id: string; stripe_charge_id: string }>) {
        const { stripe_customer_id, stripe_charge_id } = payload;

        yield sagaApi.get(
          `portal/charge/get-hosted-invoice-link/${stripe_customer_id}/${stripe_charge_id}`,
          A.getHostedChargeInvoiceLinkFromStripeSuccess,
          A.getHostedChargeInvoiceLinkFromStripeFailure
        );
      },
      [A.getPendingZencarePracticeManagementClinics]: function* () {
        yield sagaApi.get(
          `portal/pending-zencare-practice-management-clinics`,
          A.getPendingZencarePracticeManagementClinicsSuccess,
          A.getPendingZencarePracticeManagementClinicsFailure
        );
      },
      [A.updateOutOfOfficeMessage]: function* ({
        payload,
      }: SagaPayload<{
        provider_id: number;
        status: string;
        start_date: string;
        end_date: string;
        message: string;
      }>) {
        const { provider_id } = payload;
        yield sagaApi.post(
          `portal/provider/out-of-office-message/${provider_id}`,
          payload,
          A.updateOutOfOfficeMessageSuccess,
          A.updateOutOfOfficeMessageFailure
        );
        yield put(A.getMe());
        yield put(flashSuccess(`Your Vacation Responder has been updated!`));
      },
      [A.updateOutOfOfficeMessageStatus]: function* ({
        payload,
      }: SagaPayload<{
        provider_id: number;
        status: string;
      }>) {
        const { provider_id, status } = payload;
        yield sagaApi.post(
          `portal/provider/out-of-office-message/${provider_id}/status`,
          payload,
          A.updateOutOfOfficeMessageStatusSuccess,
          A.updateOutOfOfficeMessageStatusFailure
        );
        yield put(A.getMe());
        yield put(
          flashSuccess(
            `Your Vacation Responder has been turned ${status === 'active' ? 'on' : 'off'}!`
          )
        );
      },
      [A.toggleBookingLink]: function* ({
        payload,
      }: SagaPayload<{ status: 'active' | 'inactive'; provider_id: number }>) {
        const { status, provider_id } = payload;
        yield sagaApi.post(
          `portal/provider/practice-management-booking-link/${provider_id}`,
          { status },
          () => A.toggleBookingLinkSuccess({ status }),
          () => A.toggleBookingLinkFailure()
        );
      },
      [A.toggleBookingLinkSuccess]: function* ({ payload }: SagaPayload<{ status: string }>) {
        const { status } = payload;
        yield put(A.getMe());
        yield put(
          flashSuccess(
            `Your Zencare Practice Management EHR booking link has been ${
              status === 'active' ? 'enabled' : 'disabled'
            }!`
          )
        );
      },
    };

    return sagas;
  },
});

type SagaPayload<T> = {
  payload: T;
};

type ExportIntakesPayload = {
  account_id: number;
};
type SupervisorLicensePayload = {
  provider_id?: number;
  deleteLicenseIds: Array<number>;
  licenses: Array<{
    id: number;
    supervisor_name: string | null;
    supervisor_license_type: string | null;
    supervisor_license: string | null;
  }>;
};

/**
 * Actions related to the primary store
 */
interface MainActions {
  actions: {
    /**
     * Demonstration detailed documentation
     * @description pass an object containing a provider's ID to adjust the provider in scope
     */
    selectActiveProvider: (payload: { id: number }) => void;
    selectActiveProviderSuccess: () => void;
    getMe: () => void;
    getAllAccountDetails: () => void;
    getMeFailure: () => void;
    getMeSuccess: () => void;
    getAllMyProviders: () => {};
    getAllMyProvidersFailure: () => {};
    getAllMyProvidersSuccess: (payload: $TSFixMe) => {};
    updateProvider: (payload: { id: number }) => {};
    updateProviderSuccess: (payload: any) => {};
    updateProviderFailure: () => void;
    updatePracticeSort: (payload: { id: number }) => {};
    updatePracticeSortSuccess: () => void;
    dissociateProvider: (payload: { practice_id: number; provider_id: number }) => {};
    updateAvailability: (payload: { id: number }) => void;
    updateAvailabilitySuccess: () => void;
    updateAvailabilityFailure: () => void;
    updateLocation: (payload: { id: number }) => void;
    updateLocationSuccess: () => void;
    updateLocationFailure: () => void;
    createLocationWithLicense: (payload: { provider_id: number; [key: string]: any }) => void;
    createLocationWithLicenseSuccess: () => void;
    deleteLocation: (payload: { id: number }) => void;
    deleteLocationSuccess: () => void;
    updateTagging: (payload: { associate: $TSFixMe; [key: string]: any }) => void;
    updateTaggingSuccess: () => void;
    updateMultipleTaggings: (payload: { providerId: number; entries: $TSFixMe[] }) => void;
    getLicenses: (payload: { id: number }) => void;
    getInsuranceOptOuts: (payload: { id: number }) => void;
    addInsuranceOptOuts: (payload: {
      provider_id: number;
      tag_ids: number[];
      staste: string;
    }) => void;
    removeInsuranceOptOuts: (payload: {
      provider_id: number;
      tag_ids: number[];
      staste: string;
    }) => void;
    updateLicenses: (payload: { providerId: number; [key: string]: any }) => void;
    deleteSupervisorLicense: (payload: SupervisorLicensePayload) => void;
    updateSupervisorLicense: (payload: SupervisorLicensePayload) => void;
    updateTaggingSearchability: (payload: {
      provider_id: number;
      tag_id: number;
      enable: $TSFixMe;
    }) => void;
    updateTaggingSearchabilitySuccess: () => void;
    contactZencare: (payload: { provider_id: number; [key: string]: any }) => void;
    goLive: (payload: { provider_id: number }) => void;
    getChecklist: (payload: { id: number }) => void;
    getChecklistSuccess: () => void;
    getProviderFeedback: (payload: {
      provider_id: number;
      provider_feedback_id: number;
      display_testimonial: $TSFixMe;
    }) => void;
    updateTestimonialDisplayFailure: () => void;
    logEvent: (payload: { provider_id: number; [key: string]: any }) => void;
    getRelevantInsurances: (payload: { id: number }) => void;
    updateInsurances: (payload: {
      updates: { associate: $TSFixMe; id: number; tagTypeSingular: $TSFixMe; tagId: number }[];
    }) => void;
    getActionItemData: (payload: { id: number }) => void;
    exportIntakes: (payload: ExportIntakesPayload) => void;
    getHostedInvoiceLinkFromStripe: (payload: {
      stripe_customer_id: string;
      stripe_invoice_id: string;
    }) => void;
    getHostedChargeInvoiceLinkFromStripe: (payload: {
      stripe_customer_id: string;
      stripe_charge_id: string;
    }) => void;
    getPendingZencarePracticeManagementClinics: $TSFixMeFunction;
  };
}

// @ts-ignore: needed to allow override of actions
export const { actions }: MainActions = sagaSliceModule;
export default sagaSliceModule;
