import _ from 'lodash';

import constants from '../../components/payment/seat-based/constants';

/**
 * Returns coupon amount in dollars e.g. 20.50.
 *
 * @param productsToEvaluate {CouponProductList} - List of products that could be discounted
 * @param coupon {CouponType | null} - The coupon
 * @param defaultCouponSetAndNotRemovable {boolean} - Used if coupon should be considered valid since already attached
 * @param [reduceAvailableDollarOffCouponAmountBy] {number | null | undefined} - Used for dollar off coupons to
 * make sure we don't double-count coupon value.
 * @returns {number} Returns coupon amount in dollars e.g. 20.50
 */
export const getCouponAmount = (
  productsToEvaluate: CouponProductList,
  coupon: CouponType | null,
  defaultCouponSetAndNotRemovable: boolean,
  reduceAvailableDollarOffCouponAmountBy?: number | null | undefined
) => {
  let applicableProducts = [];
  let couponAmountOff = 0;
  if (coupon && (defaultCouponSetAndNotRemovable || _.get(coupon, 'valid'))) {
    const { percent_off, amount_off } = coupon;

    if (coupon.stripeProducts && coupon.stripeProducts.length) {
      const couponValidProducts = coupon.stripeProducts.map((y) => y.name);

      // Filter to only products that coupon applies to
      applicableProducts = productsToEvaluate.filter((x) =>
        // @ts-ignore-next-line
        couponValidProducts.includes(x.product_name)
      );
    } else {
      applicableProducts = productsToEvaluate;
    }

    const totalCostOfApplicableProductsInDollars = _.sum(
      applicableProducts.map((x) => x.price * x.quantity)
    );

    if (percent_off) {
      couponAmountOff = totalCostOfApplicableProductsInDollars * (percent_off / 100);
    }
    if (amount_off) {
      let amountOff = amount_off;
      if (reduceAvailableDollarOffCouponAmountBy) {
        const reduceAvailablePenniesOffCouponAmountBy =
          reduceAvailableDollarOffCouponAmountBy * 100;
        amountOff = amountOff - reduceAvailablePenniesOffCouponAmountBy;
      }
      // Convert to dollars
      const dollarsCouponAmountOff = amountOff / 100;
      let couponDollarsApplied = 0;
      // If we can actually discount anything
      if (totalCostOfApplicableProductsInDollars > 0) {
        if (totalCostOfApplicableProductsInDollars < dollarsCouponAmountOff) {
          // The amount we can discount is less than the coupon amount
          // Thus, we can only use up to what the products codes
          couponDollarsApplied = totalCostOfApplicableProductsInDollars;
        } else {
          // The amount we can discount greater than or equal to the coupon amount, use entire coupon
          couponDollarsApplied = dollarsCouponAmountOff;
        }
      }

      couponAmountOff = couponDollarsApplied;
    }
  }

  // Round to the nearest penny
  couponAmountOff = Math.round(couponAmountOff * 100) / 100;
  return couponAmountOff;
};

/**
 * Returns array of product items (i.e. no mention of NY/CA, Pratice Management is listed separately) from constants.ts
 *
 * @param {CouponProductList} productsToEvaluate
 * @param {CouponType} coupon
 * @returns Array<string> list of product items
 */
export const getCouponApplicableProductItems = (
  productsToEvaluate: CouponProductList,
  coupon: CouponType | null
) => {
  const results: Array<string> = [];

  let applicableProducts = [];
  if (coupon) {
    if (coupon.stripeProducts && coupon.stripeProducts.length) {
      // Filter to only products that coupon applies to
      applicableProducts = productsToEvaluate.filter((x) =>
        coupon.stripeProducts.map((y) => y.name).includes(x.product_name ? x.product_name : '')
      );
    } else {
      applicableProducts = productsToEvaluate;
    }

    applicableProducts.forEach((productEntry) => {
      const summaryItemEntry = constants.summary_items.find(
        (x) => x.exactStripeProductNameForCouponMatching === productEntry.product_name
      );
      if (summaryItemEntry) {
        summaryItemEntry.items.forEach((item) => {
          if (!results.includes(item.description)) {
            results.push(item.description);
          }
        });
      }
    });
  }

  return results;
};
