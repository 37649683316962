import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

export default function useStripeCardElement() {
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const [cardError, setCardError] = useState();

  const cardElRef = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          if (!stripe || !elements) return;

          const cardElement = elements.getElement(CardElement);

          if (!cardElement) return; //should be guaranteed

          cardElement.on('change', (event) => {
            if (event.complete) {
              // if complete/valid, enable button
              return setCardComplete(true);
            }

            //otherwise, button is disabled
            setCardComplete(false);

            if (event.error) {
              return setCardError(event.error);
            }

            setCardError(null);
          });
        }, 100);
      }
    },
    [stripe, elements]
  );

  return {
    stripe,
    elements,
    cardElRef,
    cardComplete,
    setCardComplete,
    cardError,
    setCardError,
  };
}
