import React from 'react';

import PureModal from '+/Modal';

/**
 * This component is imported into EditHealthInsurances.jsx and displayed
 * as confirmaiton of update health insurance information.
 *
 * As of Messaging Tool shipment, this component is never set to display.
 *
 * It was a staple piece of UX and appears to be the only confirmation of
 * update success for the user. Thus so, it felt safer to prevent this from
 * showing via useState refactors, than it did to delete this component all
 * together.
 *
 * This file can be safely deleted along side all relevant rendering react
 * logic inside the parent component once this item is deemed uneeded.
 */

export default function InsurancesUpdatedModal({ open, toggle, addedUnlisted, isLoading }) {
  // eslint-disable-line
  return (
    <PureModal
      visible={open}
      additionalCssClasses='shorter-modal'
      closeModal={() => toggle(false)}
      headerContent={addedUnlisted ? <h4>We're on it!</h4> : <h4>Insurances updated</h4>}
    >
      <>
        {isLoading ? (
          'Saving changes...'
        ) : (
          <>
            {addedUnlisted ? (
              <>
                <h5 className='semi-bold'>Thank you for your updates!</h5>
                <h5 className='semi-bold'>
                  The updates will be reflected on your profile within 24 business hours.
                </h5>
                <h5 className='semi-bold'>We will be in touch with any questions.</h5>
                <h5 className='semi-bold'>Yours,</h5>
                <h5 className='semi-bold'>Zencare Team</h5>
              </>
            ) : (
              <>
                <h5 className='semi-bold'>
                  Thanks for updating your insurances! These changes are now shown on your profile
                  and contact form.
                </h5>
              </>
            )}
            <button
              style={{ minWidth: '150px' }}
              className='pill primary md'
              onClick={() => {
                toggle(false);
              }}
            >
              Got it!
            </button>
          </>
        )}
      </>
    </PureModal>
  );
}
