import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

export default ({ curStep, steps }) => {
  const ballLeft = (i) => `${(100 / (steps.length - 1)) * i}%`;
  const segmentLeft = (i) => `calc(${(100 / (steps.length - 1)) * i}% + 5px)`;
  const segmentWidth = (i) => `${100 / (steps.length - 1)}%`;

  // Don't show progress bar if on last step.
  if (curStep + 1 === steps.length) {
    return <div className='m-b-sm'></div>;
  }

  return (
    <div className='onboarding-progress-indicator-container flex column m-b-sm'>
      <div className='onboarding-progress-indicator'>
        <CSSTransitionGroup
          transitionName='progress'
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {steps.map((__, i) => (
            <div
              key={i}
              className={`progress-text ${i === curStep ? 'current-step' : 'not-current-step'}`}
              style={{
                left: segmentLeft(i),
                transform: i === steps.length - 1 ? 'translateX(-70%)' : 'translateX(-50%)', //hackish reevaluate
              }}
            >
              {steps[i].step}
            </div>
          ))}
        </CSSTransitionGroup>

        <div className='line'></div>
        {steps.map((__, i) => (
          <React.Fragment key={i}>
            <div
              className='step-indicator'
              style={{
                left: ballLeft(i),
              }}
            ></div>
            <div
              className='line-segment'
              style={{
                left: segmentLeft(i),
                width: segmentWidth(i),
              }}
            ></div>
          </React.Fragment>
        ))}

        <CSSTransitionGroup
          transitionName='progress'
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {steps.slice(0, curStep + 1).map((s, i) => (
            <div
              key={s.step}
              className='step-indicator selected'
              style={{
                left: ballLeft(i),
              }}
            ></div>
          ))}
        </CSSTransitionGroup>

        <CSSTransitionGroup
          transitionName='progress'
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {steps.slice(0, curStep).map((s, i) => (
            <div
              key={s.step}
              className='line-segment selected'
              style={{
                left: segmentLeft(i),
                width: segmentWidth(i),
              }}
            ></div>
          ))}
        </CSSTransitionGroup>
      </div>
    </div>
  );
};
