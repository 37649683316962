import React from 'react';
import { HashLink } from 'react-router-hash-link';
import ContactLicenseStuck from './ContactLicenseStuck';

export default ({ licenses, location }) => {
  if (licenses.length) {
    const licensesForState = licenses.filter((license) => license.state === location.state);
    const verifiedLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Verified'
    );
    if (verifiedLicensesForState.length) {
      return (
        <div className='text-success'>
          <i className={'fal fa-check-circle'}></i> State credential verified
        </div>
      );
    }

    const legacyLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Legacy'
    );
    if (legacyLicensesForState.length) {
      // For existing locations that have a Legacy license, we're not going to show any info
      // about license status
      return <></>;
    }

    const unverifiedLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Unverified'
    );
    if (unverifiedLicensesForState.length) {
      return (
        <div>
          <i className='fal fa-hourglass-half'></i>{' '}
          <span className='text-error'>
            State credential is pending verification by Zencare. If you've seen this message for
            longer than 48 business hours please
          </span>{' '}
          <ContactLicenseStuck license={unverifiedLicensesForState[0]} />
        </div>
      );
    }

    const notFoundLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Not found'
    );
    if (notFoundLicensesForState.length) {
      return (
        <div className='text-error'>
          <p>
            <i className='fal fa-times-circle'></i> State credential unable to be verified.
          </p>
          <p>
            Please{' '}
            <HashLink to='/profile/general-information#licensure'>
              add a valid state credential
            </HashLink>{' '}
            for this location to display on your profile!
          </p>
        </div>
      );
    }
  }

  // No relevant license found for this state.
  if (!location.should_display) {
    return (
      <div className='text-error'>
        <p>
          <i className='fal fa-times-circle'></i> State credential not verified.
        </p>
        <p>
          Please{' '}
          <HashLink to='/profile/general-information#licensure'>
            add a valid state credential
          </HashLink>{' '}
          for this location to display on your profile!
        </p>
      </div>
    );
  }

  // For existing locations that are already live we're not going to show any info
  // about license status if we don't have a corresponding record with status.
  return <></>;
};
