import React from 'react';
import { connect } from 'react-redux';
import Loader from '+/forms/SectionLoader';
import YourPracticeInsuranceForm from './YourPracticeInsuranceForm';

const YourPracticeInsurance = ({ providerId, provider, onboarding, insuranceSubmitClicked }) => {
  if ((onboarding && onboarding.isLoading) || !provider) {
    return (
      <div key='info-form'>
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className='health-insurances'>
      <YourPracticeInsuranceForm
        providerId={providerId}
        provider={provider}
        insuranceSubmitClicked={insuranceSubmitClicked}
      />
    </div>
  );
};

export default connect(
  ({ onboarding, main }) =>
    //eslint-disable-next-line
    ({ onboarding, main }),
  {}
)(YourPracticeInsurance);
