import React from 'react';
import { connect } from 'react-redux';

export const statesWithMailservs = {
  'Rhode Island': 'rhodeisland@zencare.co',
  Massachusetts: 'massachusetts@zencare.co',
  Illinois: 'illinois@zencare.co',
  'New Jersey': 'newjersey@zencare.co',
  'New York': 'newyork@zencare.co',
  Washington: 'washington@zencare.co',
  California: 'california@zencare.co',
  Connecticut: 'connecticut@zencare.co',
  Florida: 'florida@zencare.co',
  Texas: 'texas@zencare.co',
  'Washington DC': 'washingtondc@zencare.co',
};

export const ProviderCommunity = ({ provider }) => {
  const validatedLicenseProviderStates = provider?.locations
    ?.filter((x) => x.should_display === 1)
    .map((x) => x.state);

  const statesWithMailservesArray = Object.keys(statesWithMailservs);

  const listServeStatesToShow = validatedLicenseProviderStates.filter((state) =>
    statesWithMailservesArray.includes(state)
  );

  return (
    <div
      id='static-page'
      style={{
        maxWidth: '1080px',
        margin: '0 auto',
      }}
    >
      <h1>Provider Community</h1>

      {listServeStatesToShow.map((state) => (
        <div
          className='column m-b-sm p-sm'
          style={{
            border: 'solid #37bec3 2px',
            background: '#EFFCFD',
          }}
        >
          <p className='heading-4'>{state} Provider Community</p>
          <p>
            Email <a href={`mailto:${statesWithMailservs[state]}`}>{statesWithMailservs[state]}</a>{' '}
            to reach providers in the Zencare {state} Provider Community!
          </p>
          <a href={`mailto:${statesWithMailservs[state]}`}>
            <button className='pill primary lg'>Email {state} Community</button>
          </a>
        </div>
      ))}

      <h3>What is the Zencare listserv? </h3>
      <p>
        When you send an email to the listserv, every member of the list will receive the email from
        your email address. Our team moderates the listservs, so you will receive a confirmation
        upon your message being sent out.
        <br />
        You can toggle your&nbsp;settings to receive emails immediately, or as one daily digest.
      </p>
      <h3>What can I use the Zencare listserv for? </h3>
      <p>Many Zencare clinicians look to their peers for:</p>
      <ul>
        <li>
          <p>Referrals and local service recommendations</p>
        </li>
        <li>
          <p>Supervisors, supervisees, and peer groups</p>
        </li>
        <li>
          <p>Office sublets, hiring for a group practice</p>
        </li>
        <li>
          <p>Conference announcements and requests for speakers</p>
        </li>
        <li>
          <p>New practice announcements</p>
        </li>
      </ul>
      <h3>Can I use the Zencare listserv to find clinicians for client referrals?&nbsp;&nbsp;</h3>
      <p>
        Yes, but please be mindful of client privacy!&nbsp;Share as little information as possible
        to make a good match, and don’t include any identifiers.&nbsp;
      </p>
      <h3>Can I promote my therapy groups and practice?</h3>
      <p>
        Absolutely! If you’ve opened a new practice or are looking for more clients to join a
        therapy group, you’re welcome to share that with the rest of the Zencare network.
      </p>
      <h3>Do I have to pay extra for the listserv?</h3>
      <p>
        Nope – the listserv is included with your Zencare membership.&nbsp;Happy e-connecting!&nbsp;
      </p>
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

export default connect(mapStateToProps, null)(ProviderCommunity);
