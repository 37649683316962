import React from 'react';
import PureModal from '+/Modal';

export default function WaitingOnOwnerToPayModal({ open, toggle }) {
  // eslint-disable-next-line implicit-arrow-linebreak
  return (
    <PureModal
      visible={open}
      additionalCssClasses='shorter-modal'
      closeModal={() => toggle(false)}
      headerContent={<h4>Congratulations on completing your profile content!</h4>}
    >
      <>
        <p>
          We’ve let your account owner know that you’ve completed these steps. Once they start your
          Zencare membership, you’ll receive an email to publish your profile.
        </p>
        <p>
          In the meantime, head over to your Members Portal for additional fields that will
          strengthen your profile!
        </p>
        <button
          className='pill primary md'
          onClick={() => {
            toggle(false);
          }}
        >
          Strengthen my profile
        </button>
      </>
    </PureModal>
  );
}
