/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';

export default ({ fieldProps, setValueFromDropdownSelection, items }) => {
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setResults(items);
  }, [items]);

  const menuRef = useRef();
  const inputRef = useRef();

  // If click outside menu, close menu.
  useEffect(() => {
    if (!open) {
      return;
    }

    const handleEvent = (event) => {
      if (event.target && menuRef.current) {
        if (!isInElement(event.target, menuRef.current)) {
          setOpen(false);
        }
      }
    };

    const defocusEvents = ['mousedown', 'touchend', 'focus'];
    defocusEvents.forEach((event) => document.addEventListener(event, handleEvent));

    return () => defocusEvents.forEach((event) => document.removeEventListener(event, handleEvent));
  }, [open]);

  const toggleDropdown = (evt) => {
    evt.preventDefault();
    setOpen(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleItemChosen = (item) => {
    setOpen(false);
    setValueFromDropdownSelection(item);
  };

  const DropDownOption = ({ item }) => (
    <span
      onClick={() => {
        handleItemChosen(item);
      }}
      className='combo-box-item'
    >
      {item}
    </span>
  );

  return (
    <div className='combo-box' ref={menuRef}>
      <button onClick={toggleDropdown}>
        <input
          type='text'
          value={fieldProps.input.value}
          onChange={fieldProps.input.onChange}
          ref={inputRef}
        ></input>
      </button>

      {open && (
        <div className='search-menu'>
          <div>
            {results && results.length > 0
              ? results.map((item) => <DropDownOption key={item} item={item} />)
              : ''}
          </div>
        </div>
      )}
    </div>
  );
};

const crawlDomTree = (el, foundIf) => {
  if (!el) {
    return false;
  }

  if (el.parentElement === document.body) {
    return false;
  }

  if (!foundIf(el)) {
    return crawlDomTree(el.parentElement, foundIf);
  }

  return el;
};

const isInElement = (current, element) => !!crawlDomTree(current, (el) => el === element);
