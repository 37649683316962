import { counts } from './constants';

export function wordCount(text) {
  return text ? text.split(' ').length : 0;
}

export function getStyle(values, key) {
  const wc = wordCount(values[key]);
  if (wc > counts[key].maxWords - 10) {
    return { color: '#F2474E' };
  }

  if (wc < counts[key].minWords) {
    return { color: '#EFB52E' };
  }

  return {};
}

export function getMaxLength(values, key) {
  return wordCount(values[key]) >= counts[key].maxWords ? values[key].length : 99999;
}

/*
 * Add a line break after any </p> tag. Then insert into a temporary dom node and get the
 * inner text, which will strip out any html formatting.
 */
export function getPlainText(initText) {
  if (typeof initText !== 'string') return '';

  const reg = RegExp('</p>(\\r)*(\\n)*<', 'g');
  while (reg.test(initText)) {
    initText = initText.replace(/<\/p>(\r)*(\n)*</g, '</p>\n\n<');
  }

  const tmp = document.createElement('div');
  tmp.innerHTML = initText;
  const result = tmp.textContent || tmp.innerText || '';
  tmp.remove();

  return result;
}

/*
 * Split at line breaks and replace with <p> tags
 */
export function getHTMLFromText(text) {
  if (!text || !text.length) {
    return text;
  }

  const anchorStart = /<a.*>/;
  const anchorEnd = /<\a>/;
  while (anchorStart.test(text)) {
    text = text.replace(anchorStart, '');
  }

  while (anchorEnd.test(text)) {
    text = text.replace(anchorEnd, '');
  }

  const paragraphs = text.split(/\n+/);

  return paragraphs.map((p) => `<p>${p}</p>`).join('');
}
