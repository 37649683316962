import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@zencare/ui';
import useSortable from '+/hooks/useSortable';
import { Form, Field } from 'react-final-form';
import { imgix } from './helpers';
import Lightbox from './Lightbox';

const PhotoField = (props) => {
  const { value, name, disabled, onChange } = props.input; // eslint-disable-line
  const id = `photo-${name}`;

  return (
    <div className={`image-selector flex column ${value.checked ? 'selected' : 'not-selected'}`}>
      <input
        type='checkbox'
        id={id}
        defaultChecked={value.checked}
        onChange={(a) => {
          onChange({
            ...value,
            checked: a.target.checked,
          });
        }}
        disabled={disabled}
      />
      <div className='controls flex'>
        <label className='flex' htmlFor={id}>
          {value.checked ? (
            <>
              {' '}
              Visible
              <i className='fas fa-eye text-primary'></i>
            </>
          ) : (
            <>
              {' '}
              Hidden
              <i className='fas fa-eye-slash text-primary'></i>
            </>
          )}
        </label>
      </div>
      {props.image && (
        <img
          className={`handle gallery-photo border-primary ${value.checked ? '' : 'o-3'}`}
          alt='1'
          src={imgix(props.image)}
        ></img>
      )}
      <span
        className='w-100 flex m-b-md lightbox-toggle'
        role='presentation'
        onClick={() => props.openLightbox()}
      >
        <i className='fas fa-search-plus text-primary'></i>
        View larger
      </span>
    </div>
  );
};

const PhotoSelectForm = (props) => {
  const { images, onSubmit, initialValues } = props;
  const a = 1;
  const [listRef, mutatorRef, resort] = useSortable('images');
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(null);

  if (!images.length) {
    return <p className='m-b-md'>You don't have any gallery photos yet.</p>;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{
        resort,
      }}
      render={({ handleSubmit, pristine, valid, form, values }) => {
        mutatorRef.current = form.mutators;

        return (
          <>
            <div className='gallery' ref={listRef}>
              {values['images'].map((v, i) => (
                <Field name={`images[${i}]`} key={v.oldIdx} handle={v.oldIdx}>
                  {(props) => (
                    <PhotoField
                      {...props}
                      image={images[v.oldIdx]}
                      openLightbox={() => {
                        setLightboxIndex(v.oldIdx);
                        setLightboxOpen(true);
                      }}
                    />
                  )}
                </Field>
              ))}
            </div>
            <div className='actions'>
              {!pristine && !valid ? (
                // Have to do this right now because storybook button class
                // won't change when disabled is changed..
                <Button className='primary' onClick={handleSubmit} disabled>
                  Submit
                </Button>
              ) : (
                <Button className='primary' onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </div>
            {lightboxOpen && (
              <Lightbox
                images={images}
                open={lightboxOpen}
                toggle={setLightboxOpen}
                curIndex={lightboxIndex}
                setIndex={setLightboxIndex}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default PhotoSelectForm;
