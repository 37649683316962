import RegistrationForm from './RegistrationForm';
import AboutYouForm from './AboutYouForm';
import YourPracticeContainer from './YourPracticeContainer';
import PhotosForm from './PhotosForm';
import LocationForm from './LocationForm';
import GoLiveForm from './GoLiveForm';
// import { getQueryAsObject } from '../../utils/queryParams';
import storage from '#/storage';
// import hasOwnerScope from '#/hasOwnerScope';
//import _ from 'lodash';
//import providerHasSubscriptionInAccount from '#/providerHasSubscriptionInAccount';

const aboutYouCheckFn = (provider, roles, accounts, licenses) => {
  if (!provider) return false;
  if (storage.get(`exit-onboarding-wizard-provider-id-${provider.id}`)) {
    return false;
  }

  if (!provider.professions) {
    return true;
  }

  if (!provider.professions.length) {
    return true;
  }

  if (!licenses || !licenses.length) {
    return true;
  }

  return false;
};

export default [
  {
    step: 'Create Account',
    component: RegistrationForm,
    checkFn: (provider) => !provider,
  },
  {
    step: 'Locations',
    component: LocationForm,
    createForPathPrefix: '/profiles/create',
    checkFn: (provider) => provider && (!provider.locations || !provider.locations.length),
  },
  {
    step: 'About You',
    component: AboutYouForm,
    createForPathPrefix: '/profiles/about-you-for',
    checkFn: aboutYouCheckFn,
  },
  {
    step: 'Your Practice',
    component: YourPracticeContainer,
    createForPathPrefix: '/profiles/your-practice-for',
    checkFn: (provider) => {
      if (!provider) return false;
      if (storage.get(`exit-onboarding-wizard-provider-id-${provider.id}`)) {
        return false;
      }

      if (
        !provider.clientele ||
        !provider.languages ||
        !provider.specialties ||
        !provider.approaches
      ) {
        return true;
      }

      if (
        !provider.clientele.length ||
        !provider.languages.length ||
        !provider.specialties.length ||
        !provider.approaches.length
      ) {
        return true;
      }

      if (!provider.session_fee_ongoing) {
        return true;
      }
      if (!provider.custom_content.session_length_ongoing) {
        return true;
      }

      return false;
    },
  },
  {
    step: 'Photos',
    component: PhotosForm,
    createForPathPrefix: '/profiles/photos-for',
    checkFn: (provider, roles, accounts, licenses) => {
      if (!provider) return false;
      if (storage.get(`exit-onboarding-wizard-provider-id-${provider.id}`)) {
        return false;
      }

      if (!provider.image) {
        return true;
      }

      return false;
    },
  },
  {
    step: 'Go Live',
    component: GoLiveForm,
    createForPathPrefix: '/profiles/go-live-for',
    checkFn: (provider) => {
      if (!provider) return false;
      if (storage.get(`exit-onboarding-wizard-provider-id-${provider.id}`)) {
        return false;
      }

      if (!['active', 'pending'].includes(provider.status)) {
        return true;
      }

      return false;
    },
  },
];
