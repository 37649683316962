import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from '~/store';
import { flashSuccess } from '~/shared/flashes/redux';
import _ from 'lodash';
import StartSeatBasedMembershipModal from './seat-based/StartSeatBasedMembershipModal';
import WaitingOnOwnerToPayModal from './WaitingOnOwnerToPayModal';
import hasOwnerScope from '#/hasOwnerScope';
import providerHasSubscriptionInAccount from '#/providerHasSubscriptionInAccount';

const StartMembershipButton = ({ providerId, main, roles }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [waitingOnOwnerToPayModalOpen, setWaitingOnOwnerToPayModalOpen] = useState(false);
  const [justPaid, setJustPaid] = useState(false);

  const accounts = _.get(main, 'accounts', []).filter((a) => !a.error);

  const provider =
    main.allProviders.find((p) => p.id === providerId) ||
    _.flatten(accounts.map((a) => a.providers)).find((p) => p.id === providerId) ||
    main.provider;

  const isOwner = hasOwnerScope(accounts, roles, _.get(provider, 'id'));
  const hasSubscription =
    provider.hasPaidPlan || accounts.some((a) => providerHasSubscriptionInAccount(provider, a));

  const invalidTierLevels = accounts.some((a) => a.has_practice_management && a.tier === 'basic');

  const toggleWaitingOnOwnerToPayModal = () => {
    setWaitingOnOwnerToPayModalOpen(false);
    startMembershipClicked();
  };

  const startMembershipClicked = () => {
    setModalOpen(true);
  };

  /*const startSeatBasedMembershipClicked = () => {
    setSeatBasedModalOpen(true);
  };*/

  const onSubscribed = () => {
    console.log('StartMembershipButton: onSubscribed called');
    setModalOpen(false);
    setJustPaid(true);
    dispatch(flashSuccess('Successful payment! Thank you for starting your Zencare membership!'));
  };

  return (
    <>
      {!justPaid && !hasSubscription && isOwner && !invalidTierLevels ? (
        <>
          <button onClick={startMembershipClicked} className='lg primary pill hollow m-r-sm'>
            Start Membership
          </button>

          {/* <button
            onClick={startSeatBasedMembershipClicked}
            className='lg primary pill hollow m-r-sm'
          >
            Start Membership (new seat-based mode)
          </button>*/}
        </>
      ) : (
        ''
      )}

      <StartSeatBasedMembershipModal
        open={modalOpen}
        toggle={setModalOpen}
        onSubscribed={onSubscribed}
        provider={provider}
      />
      <WaitingOnOwnerToPayModal
        open={waitingOnOwnerToPayModalOpen}
        toggle={toggleWaitingOnOwnerToPayModal}
      />
    </>
  );
};

export default connect(
  ({ onboarding, main, auth }) => ({
    onboarding,
    main,
    roles: auth.roles,
  }),
  {}
)(StartMembershipButton);
