import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as paymentActions } from './sagaSlice';
import _ from 'lodash';

export const PriceCheckout = ({ match, getPriceProduct }) => {
  const priceId = _.get(match, 'params.priceId');
  // If not logged in, just get price checkout and return link and redirect
  // If logged in, check provider first, then,
  useEffect(() => {
    getPriceProduct({
      priceId,
    });
  }, [getPriceProduct, priceId]);

  return <></>;
};

export default connect(({ main, payment, auth }) => ({}), {
  getPriceProduct: paymentActions.getPriceProduct,
})(PriceCheckout);
