import React from 'react';
import ErrorField from '+/forms/ErrorField';
import { Field } from 'react-final-form';
import stateAbbrDict from '../../../../utils/stateAbbrDict';

const stateOptions = Object.keys(stateAbbrDict);

export default ({ isOnline, stateText, disabled, stateDisabled }) => (
  <>
    {!isOnline && (
      <>
        <div className='field w-100 m-b-xs'>
          <h5>Street address</h5>
          <Field
            disabled={disabled}
            name='address'
            className='w-100 m-b-xs'
            component='input'
            required={true}
            type='input'
            placeholder='e.g. 123 Main St'
          />
          <ErrorField name='address' />
        </div>
        <div className='field w-100 m-b-xs'>
          <h5>Floor, Suite (optional)</h5>
          <Field
            disabled={disabled}
            name='address_2'
            component='input'
            className='w-100 m-b-xs'
            required={false}
            type='input'
            placeholder='e.g. Suite #7'
          />
        </div>
        <div className='field w-49 m-b-xs'>
          <h5>City</h5>
          <Field
            disabled={disabled}
            name='city'
            component='input'
            className='w-100 m-b-xs'
            required={true}
            type='input'
            placeholder='e.g. Brooklyn'
          />
          <ErrorField name='city' />
        </div>
      </>
    )}
    <div className={`field w-${isOnline ? 100 : 49} m-b-xs`}>
      <h5>{isOnline ? stateText || 'State in which you offer online therapy' : 'State'}</h5>
      <Field
        disabled={disabled || stateDisabled}
        name='state'
        component='select'
        className='w-100 m-b-xs'
        required={true}
        type='select'
      >
        <option value='' disabled selected hidden>
          e.g. NY
        </option>
        {stateOptions.map((o) => (
          <option value={o}>{o}</option>
        ))}
        <i className='fas fa-caret-down'></i>
      </Field>
      <ErrorField name='state' />
    </div>

    {!isOnline && (
      <div className='field w-49 m-b-xs'>
        <h5>Zipcode</h5>
        <Field
          disabled={disabled}
          name='zip'
          component='input'
          className='w-100 m-b-xs'
          required={true}
          type='input'
          placeholder='e.g. 10001'
        />
        <ErrorField name='zip' />
      </div>
    )}
  </>
);
