import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import Input from '+/forms/Input';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import rgx from '#/regex';

const validate = (values) => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = 'You must enter an password';
  } else if (!rgx.password.test(values.newPassword)) {
    errors.newPassword =
      'Your password must contain at least twelve characters, an uppercase letter, a lowercase letter, and a symbol or number';
  }

  return errors;
};

export const UpdatePassword = (props) => {
  const { updatePassword } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form
      onSubmit={updatePassword}
      validate={validate}
      render={({ handleSubmit, values: { password, confirm_password } }) => (
        <form onSubmit={handleSubmit} className='grid justify-between column align-center'>
          <div>
            <h1>Update Password</h1>
            <p>
              Your password must be at least 8 characters long and have at least one character from
              three of the following four categories: lowercase, uppercase, number, special
              character.
            </p>
          </div>
          <div className='w-75'>
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label='Old Password:'
              name='oldPassword'
            />
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label='New Password:'
              name='newPassword'
            />
            <div>
              <p>
                <input type='checkbox' onChange={(e) => setShowPassword(e.target.checked)} /> Show
                password
              </p>
              <button type='submit' className='primary' disabled={password !== confirm_password}>
                Update Password
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default connect(null, { updatePassword: actions.updatePassword })(UpdatePassword);
