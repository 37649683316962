import React from 'react';
import Modal from '+/Modal';
import { ProductDescriptionType } from './constants';

interface Props {
  option: ProductDescriptionType | null;
  showModal: boolean;
  setShowModal: $TSFixMeFunction;
}

const ProductDescriptionModal = ({ option, showModal, setShowModal }: Props) => (
  <>
    {option && (
      <Modal
        // additionalCssClasses={`setup-option-modal setup-option-modal-${option.reservedName}`}
        additionalCssClasses={`setup-option-modal`}
        visible={showModal}
        closeModal={() => setShowModal(false)}
      >
        <>
          <h3>{option.name}</h3>
          <h5>Benefits</h5>
          <p>{option.description}</p>
          <h5>Features</h5>
          <ul>
            {option.features.map((feature: $TSFixMe, index: number) => (
              <li key={index}>{feature.detail}</li>
            ))}
          </ul>
          <h5 className='setup-option-modal-rating'>
            {Array.from({ length: option.rating }, (v, i) => (
              <span key={i}>★</span>
            ))}
          </h5>
          <blockquote>"{option.testimonial}"</blockquote>
          <p className='setup-option-testimonial-source'>{option.testimonial_source}</p>
        </>
      </Modal>
    )}
  </>
);

export default ProductDescriptionModal;
