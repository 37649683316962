import React from 'react';
import StandaloneRegistration from './StandaloneRegistration';

const StandaloneRegistrationPostMessageTest = () => (
  <StandaloneRegistration
    iframeFileNameOverride={'index-dev-test-immediately-sends-postMessage.html'}
  />
);

export default StandaloneRegistrationPostMessageTest;
