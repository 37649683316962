import { connect } from 'react-redux';

import { actions } from './sagaSlice';

export const Logout = (props) => {
  props.logout();

  return null;
};

export default connect(null, actions)(Logout);
