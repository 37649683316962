import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

export const ReferAndEarn = ({ email: userEmail }) => {
  const onCopy = () => {
    const copyText = document.getElementById('copyInput');
    copyText.select();
    document.execCommand('copy');
  };

  const shareLink = `${process.env.ZENCARE_MEMBERS_URL}/signup?referrer=${encodeURIComponent(
    btoa(userEmail)
  )}`;

  return (
    <div id='static-page' className='main-content refer-and-earn'>
      <h1 className='title'>
        Refer a Colleague
        <i className='fad fa-gift text-quaternary'></i>
      </h1>
      <h2 className='sub-title'>
        Love Zencare? Refer a colleague and you’ll both receive $49 off.
      </h2>

      <hr />

      <div className='share-link'>
        <div className='form portal w-100'>
          <h3>Copy and share your link</h3>

          <div className='link-container'>
            <div className='link'>
              <input id='copyInput' type='input' readonly='' value={shareLink} />
              <button onClick={onCopy} className='primary lg'>
                Copy link
              </button>
            </div>

            <div className='social-links'>
              <span>Share the link on: </span>
              <a
                rel='noopener noreferrer'
                target='_blank'
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
              >
                Facebook
              </a>{' '}
              |{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href={`https://twitter.com/intent/tweet?url=${shareLink}`}
              >
                Twitter
              </a>{' '}
              |{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href={`http://www.linkedin.com/shareArticle?mini=true&url=${shareLink}`}
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className='form portal how-it-works'>
        <h3>How it works</h3>

        <div className='box2'>
          <div className='refer-icon-container'>
            <i className='fad fa-heart text-secondary'></i>
          </div>
          <h5>1. Share your link with a colleague</h5>
        </div>

        <div className='box2'>
          <div className='refer-icon-container'>
            <i className='fad fa-handshake-alt text-tertiary'></i>
          </div>
          <h5>2. They sign up with Zencare</h5>
        </div>
        <div className='box2'>
          <div className='refer-icon-container'>
            <i className='fad fa-gift text-quaternary'></i>
          </div>
          <h5>3. You both get a $49 discount!</h5>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  email: auth.email,
});

export default connect(mapStateToProps, null)(ReferAndEarn);
