import React from 'react';
import { Prompt } from 'react-router';

export default function EditBox({
  boxClassName,
  headerClassName = 'm-b-xs',
  title,
  description,
  editable,
  editing,
  setEditing,
  innerEditing,
  innerStatic,
}) {
  return (
    <div className={`box m-b-md ${boxClassName} mobile-border-bottom`}>
      <div className='header'>
        <h3 className={headerClassName}>{title}</h3>
        {editable && (
          <button
            onClick={() => setEditing(!editing)}
            className={`mobile-hide md ${editing ? '' : 'primary'}`}
          >
            {editing ? 'Cancel' : 'Edit'}
          </button>
        )}
      </div>
      {description && typeof description === 'string' && (
        <p className='instruction'>{description}</p>
      )}
      {description && typeof description !== 'string' && description}
      {editable && editing ? innerEditing : innerStatic}
      {editable && (
        <button
          onClick={() => setEditing(!editing)}
          className={`m-y-sm mobile-only ${editing ? '' : 'primary'}`}
        >
          {editing ? 'Cancel' : 'Edit'}
        </button>
      )}
      <Prompt
        when={!!editable && !!editing}
        message='You are currently editing! Are you sure you want to leave without saving?'
      />
    </div>
  );
}
