//mutator for final form
const toggleHours = (arg: $TSFixMe, state: $TSFixMe, { changeValue }: $TSFixMe): void => {
  const [value, day] = arg;
  changeValue(state, day, () => {
    if (value) {
      return 'Please inquire about hours';
    }

    return 'Closed';
  });
};

export { toggleHours };

/**
 * Formats an ISO 8601 date string into a human readable format
 *
 * @param {string} isoTimestamp A string in ISO 8601 format or any format accepted by the Date object
 * @returns {string} Returns a string in human readable format ex. "October 12, 2023"
 */
export function formatDate(isoTimestamp: string): string {
  const date = new Date(isoTimestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
  //@ts-ignore
  return date.toLocaleDateString(undefined, options);
}
