import React from 'react';
import { imgix } from './helpers';
import _ from 'lodash';

const Lightbox = ({ open, toggle, images, curIndex, setIndex }) => {
  //eslint-disable-line

  function next() {
    setIndex(curIndex + 1 === images.length ? 0 : curIndex + 1);
  }

  function prev() {
    setIndex(curIndex - 1 >= 0 ? curIndex - 1 : images.length - 1);
  }

  function onKeyDown(e) {
    if (e.keyCode === 27) {
      toggle(false);
    }
    if (e.keyCode === 37) {
      prev();
    }
    if (e.keyCode === 39) {
      next();
    }
  }

  return (
    <>
      <div className={`overlay lightbox-overlay ${open ? '' : 'hidden'}`}></div>
      <div
        className={`lightbox lightbox-content ${open ? '' : 'hidden'}`}
        role='menu'
        tabIndex={-2}
        onKeyDown={(e) => onKeyDown(e)}
      >
        <div className='header'>
          <button onClick={() => toggle(false)} className='close'>
            &times;
          </button>
        </div>
        <div className='main'>
          <button onClick={prev} className='prev'>
            {'❮'}
          </button>
          {!_.isNil(curIndex) && (
            <img alt={1} src={imgix(images[curIndex], { lightbox: true })}></img>
          )}
          <button onClick={next} className='next'>
            {'❯'}
          </button>
        </div>
      </div>
    </>
  );
};

export default Lightbox;
