import React from 'react';

export default function PageButtons({ page, setPage, clientList, pageSize }) {
  const numPages = Math.ceil(clientList.length / pageSize);

  if (numPages <= 1) {
    return null;
  }

  const pages = getPages(page, numPages);

  const pageLinks = pages.map((p, i) => {
    if (p === '...') {
      return (
        <span key={i} className='m-r-sm m-l-sm page-link'>
          {p}
        </span>
      );
    }

    return (
      <span
        role='button'
        tabIndex={0}
        className={`m-r-sm m-l-sm page-link ${p === page ? 'active' : ''}`}
        onClick={() => setPage(p)}
        onKeyDown={() => setPage(p)}
        key={i}
      >
        {p}
      </span>
    );
  });

  const forwardsButton = (
    <span
      role='button'
      tabIndex={0}
      className='text-primary page-button forwards m-l-sm'
      onClick={() => setPage(page + 1)}
      onKeyDown={() => setPage(page + 1)}
    >
      <i className='fas fa-chevron-right'></i>
    </span>
  );

  const backwardsButton = (
    <span
      role='button'
      tabIndex={0}
      className='text-primary page-button backwards m-r-sm'
      onClick={() => setPage(page - 1)}
      onKeyDown={() => setPage(page - 1)}
    >
      <i className='fas fa-chevron-left'></i>
    </span>
  );

  return (
    <div className='pagination'>
      {page !== 1 && backwardsButton}
      {pageLinks}
      {page !== numPages && forwardsButton}
    </div>
  );
}

function getPages(page, numPages) {
  let pages = [1];

  let innerPages = [page - 1, page, page + 1];
  if (page === 1) {
    innerPages = [page + 1, page + 2, page + 3];
  }

  if (page === numPages) {
    innerPages = [page - 3, page - 2, page - 1];
  }

  innerPages = innerPages.filter((x) => !pages.includes(x) && x > 1 && x < numPages);

  if (innerPages[0] > 2) {
    pages.push('...');
  }

  pages = pages.concat(innerPages);

  if (innerPages[innerPages.length - 1] < numPages - 1) {
    pages.push('...');
  }

  if (numPages > 1 && !pages.includes(numPages)) {
    pages.push(numPages);
  }

  return pages;
}
