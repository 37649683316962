/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Flash from './Flash';
import { setInterceptor } from './redux';

import './styles.scss';

export default () => {
  const [messages, setMessages] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const remove = (id) => {
    const newMessages = messages.filter((message) => message.id !== id);

    setMessages(newMessages);
  };

  const addMessage = (message) => {
    const newMessage = {
      ...message,
      type: message.type || 'info',
      message: message.message,
    };

    setMessages((oldMessages) => [...oldMessages, newMessage]);

    if (message.permanent) {
      return;
    }

    setTimeout(() => {
      setMessages((oldMessages) => oldMessages.filter((_message) => _message.id !== message.id));
    }, 7000);
  };

  useEffect(() => {
    if (!loaded) {
      setInterceptor(addMessage);
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <div id='flashes'>
      {Object.values(messages).map((message) => (
        <Flash key={message.id} {...message} remove={remove} />
      ))}
    </div>
  );
};
