import React from 'react';
import { Link } from 'react-router-dom';
import LinkButton from '+/LinkButton';

const ErrorLayout = () => (
  <section id='error' className='text-center p-lg'>
    <Link
      className='brand-zencare pointer'
      to='/'
      style={{
        fontSize: '4rem',
        marginBottom: '4rem',
        display: 'block',
      }}
    />
    <h1 className='m-b-sm'>Page Not Found</h1>
    <p className='m-b-sm'>It appears you landed on a page that doesn't exist</p>
    <LinkButton to='/' color='primary'>
      Go Home
    </LinkButton>
    <hr />
    <LinkButton to='/marketing/collect-references'>Dev Link</LinkButton>
  </section>
);

export default ErrorLayout;
