import AuthSlice from '@/auth/sagaSlice';
import MainSlice from '@/main/sagaSlice';
import PhotoSlice from '@/provider/profile/PhotosVideo/sagaSlice';
import TagSlices from '@/provider/taggings/sagaSlice';
import TherapistGroupSlice from '@/provider/therapy-groups/sagaSlice';
import OnboardingSlice from '@/onboarding/sagaSlice';
import PaymentSlice from '@/payment/sagaSlice';
import PracticeManagement from '@/practice-management/sagaSlice';
import PaymentSeatBasedSlice from '@/payment/seat-based/sagaSlice';
import CalendarSlice from '@/provider/calendars/sagaSlice';
import AppointmentSlice from '@/provider/appointments/sagaSlice';
import MessageSlice from '@/provider/appointments/messages/sagaSlice';
import ClientListSlice from '@/provider/appointments/client-list/sagaSlice';
import SupportSlice from '@/support-flow/sagaSlice';
import DeveloperToolsSlice from '@/developer-tools/sagaSlice';
import PracticeInboxSlice from '@/practice-inbox/sagaSlice';
import Reimbursify from '@/reimbursify/sagaSlice';

export default [
  AuthSlice,
  MainSlice,
  TherapistGroupSlice,
  OnboardingSlice,
  PaymentSlice,
  PracticeManagement,
  PaymentSeatBasedSlice,
  PhotoSlice,
  CalendarSlice,
  AppointmentSlice,
  MessageSlice,
  ClientListSlice,
  ...Object.values(TagSlices),
  SupportSlice,
  DeveloperToolsSlice,
  PracticeInboxSlice,
  Reimbursify,
];
