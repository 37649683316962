import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MessageForm from './MessageForm';
import Message from './message-types/Message';
import IntakeBooking from './message-types/IntakeBooking';
import Reschedule from './message-types/Reschedule';
import Cancellation from './message-types/Cancellation';
import IntakeMessage from './message-types/IntakeMessage';
import OutOfOfficeMessage from './message-types/OutOfOfficeMessage';
import { none } from '~/utils/none';

import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import _ from 'lodash';
import { formatPhoneNumber } from '~/utils/formatPhone';

interface Props {
  showConversation: boolean;
  setShowConversation: $TSFixMeFunction;
}

function CurrentConversation({ showConversation, setShowConversation }: Props) {
  const history = useHistory();
  const [clientIsRedacted, setClientIsRedacted] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [recipientShortName, setRecipientShortName] = useState('');
  const [recipientLongName, setRecipientLongName] = useState('');
  const [recipientPhone, setRecipientPhone] = useState('');
  const [noConversationFound, setNoConversationFound] = useState(false);
  const [isValidId, setIsValidId] = useState(false);
  const provider = useSelector((state) => _.get(state, 'main.provider'));
  const provider_id = provider.id;
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const conversationRef = useRef<$TSFixMe>();
  const { isLoading, conversationMessages, conversations } = useSelector(
    (state: { messages: $TSFixMe }) => state.messages
  );
  const textAreaInput = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setNoConversationFound(false);
    if (id && conversations) {
      const matchingConversation = conversations.filter(
        (obj: $TSFixMe) => obj.id.toString() === id
      );
      if (matchingConversation.length) {
        // TODO: handle company names if group practice accounts are allowed to be users
        const shortName = matchingConversation[0].shortName || '';
        const fullName = matchingConversation[0].fullName || '';
        setClientIsRedacted(matchingConversation[0].isClientRedacted);
        setIsLocked(matchingConversation[0].isLocked);
        setRecipientShortName(shortName);
        setRecipientLongName(fullName);
        setRecipientPhone(formatPhoneNumber(matchingConversation[0].phone));
        setShowConversation(true);
        return;
      } else {
        // TODO: ensure no longer redirecting
        // return history.push('/messages');
        setNoConversationFound(true);
      }
    }
  }, [conversations, id]);

  useEffect(() => {
    if (id && conversations) {
      setIsValidId(conversations.some((obj: $TSFixMe) => obj.id.toString() === id));
    }
  }, [conversations, id]);

  useEffect(() => {
    if (isValidId) {
      // @ts-ignore: Still need to type actions
      return dispatch(actions.getConversationMessages({ provider_id, client_user_id: id }));
    }
  }, [dispatch, id, isValidId, provider_id]);

  useEffect(() => {
    if (!conversationRef.current) return;
    if (conversationMessages === null) return;
  }, [isLoading, conversationMessages]);

  // TODO Fix such that unread UX is not interrupted
  // useEffect(() => {
  //   const messageFetch = setInterval(() => {
  //     if (provider_id) {
  //       dispatch(actions.updateMessages({ provider_id, client_user_id: id }));
  //     }
  //   }, 5000);
  //   return () => clearInterval(messageFetch);
  // }, [dispatch, id, provider_id]);

  function renderMessageType(message: Message) {
    const { type } = message;
    let output;
    switch (type) {
      case 'message':
        output = <Message key={message.id} message={message} />;
        break;
      case 'messaging_tool_intake':
        output = <IntakeMessage key={message.id} message={message} textAreaInput={textAreaInput} />;
        break;
      case 'booking_tool_intake':
        output = <IntakeBooking key={message.id} message={message} textAreaInput={textAreaInput} />;
        break;
      case 'reschedule_message':
        output = <Reschedule key={message.id} message={message} />;
        break;
      case 'cancel_message':
        output = <Cancellation key={message.id} message={message} />;
        break;
      case 'automated_message_out_of_office':
        output = <OutOfOfficeMessage key={message.id} message={message} />;
        break;
      default:
        console.error(`Invalid message type: ${type}`);
    }
    return output;
  }

  function handleNavigateBack() {
    setShowConversation(false);
    history.push('/messages');
  }

  if (!conversations || conversations.length < 1) {
    return (
      <div className='current-conversation-placeholder'>
        <h5 className='placeholder-content'>
          You do not have any client messages yet! They will show here once you receive new client
          referrals via Zencare.
        </h5>
      </div>
    );
  }

  if (!id) {
    return (
      <div className='current-conversation-placeholder'>
        <h5 className='placeholder-content'>Select a conversation to view message history.</h5>
      </div>
    );
  }

  if (noConversationFound) {
    return (
      <div className='current-conversation-placeholder'>
        <h5 className='placeholder-content'>
          Oops, this conversation doesn’t exist! This may be because this message was removed due to
          inappropriate content or use of the Zencare website. If you think this is a mistake,
          please{' '}
          <a href='/contact' rel='noopener noreferrer' target='_blank' className='placeholder-link'>
            let us know
          </a>
          .
        </h5>
      </div>
    );
  }

  if (!conversationMessages) {
    return <div className='current-conversation-placeholder'>{/* <Loader /> */}</div>;
  }

  const hasNotResponded = none(conversationMessages, (m: Message) => m.sender === 'provider');

  return (
    <div className={`current-conversation ${showConversation ? 'show-conversation' : ''}`}>
      <div className='names-wrapper'>
        <button onClick={handleNavigateBack}>
          <i className='far fa-arrow-left'></i>
        </button>
        <div className='names'>
          <h4>{recipientLongName}</h4>
          <h4>You</h4>
        </div>
      </div>
      <div ref={conversationRef} className='messages-wrapper'>
        {conversationMessages.map((message: Message) => renderMessageType(message))}
      </div>
      <MessageForm
        recipientName={recipientShortName}
        recipientPhone={recipientPhone}
        shouldShowDialog={hasNotResponded}
        textAreaInput={textAreaInput}
        clientIsRedacted={clientIsRedacted}
        isLocked={isLocked}
      />
      <ReactTooltip
        id='redaction-tooltip'
        effect='solid'
        place='right'
        type='light'
        arrowColor='#37BEC3'
      ></ReactTooltip>
    </div>
  );
}

export default CurrentConversation;
