const blogPosts = [
  {
    link: 'https://blog.zencare.co/managing-private-practice-burnout/',
    title: 'How to Manage Private Practice Burnout',
    img: 'https://assets.zencare.co/2020/08/private-practice-burnout-2-1.jpg',
    description:
      'By making a plan to proactively identify and reduce the potential for burnout, you can learn to care for yourself alongside your clients, and build a sustainable private practice.',
    time: 7,
  },
  {
    link: 'https://blog.zencare.co/online-couples-counseling-therapist-private-practice-tips/',
    title: 'How to Hold a Successful Online Couples Counseling Session',
    img: 'https://assets.zencare.co/2020/07/online-counseling-2.jpg',
    description:
      'Counselors from across the Zencare community share the insight and tips that have helped them successfully conduct online counseling sessions for couples throughout the pandemic.',
    time: 7,
  },
  {
    link: 'https://blog.zencare.co/how-white-therapists-address-racism-black-clients/',
    title: '10 Ways White Therapists Can Address Racism in Therapy With Black Clients',
    img: 'https://assets.zencare.co/2020/06/black_woman_in_therapy-1-2-1.jpg',
    description:
      'How White and non-Black POC therapists can create a safe and knowledgeable therapy space for Black clients.',
    time: 12,
  },
];

export default blogPosts;
