import React from 'react';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import useWindowSize from '~/shared/hooks/useWindowSize';
import { useSelector } from 'react-redux';

const ConversationRow = ({ client, practiceInbox }) => {
  const history = useHistory();
  const { contactDate, firstName, lastName, clientId, email, providerName, providerId } = client;
  const [width] = useWindowSize();
  const tz = DateTime.local().zoneName;
  const start = DateTime.fromISO(contactDate).setZone(tz).toLocaleString(DateTime.DATE_MED);
  const { conversations } = useSelector((state) => state.practiceInbox);

  // Determines the loading state of the "Message" button within practiceInbox
  const hasBeenFetched = conversations.some(
    (c) => c.id === clientId && c.providerId === providerId
  );

  return (
    <tr>
      <td>
        {/* <small>First contacted:</small> */}
        <h6>{start}</h6>
      </td>
      <td>
        <p>{firstName ? firstName.trim() : ''}</p>
      </td>
      <td>
        <p>{lastName ? lastName.trim() : ''}</p>
      </td>
      <td>
        <p>{email ? email.trim() : 'Not Available'}</p>
      </td>
      {practiceInbox && (
        <td>
          <p>{providerName ? providerName.trim() : ''}</p>
        </td>
      )}
      <td>
        <button
          disabled={practiceInbox ? !hasBeenFetched : false}
          className={`primary pill hollow sm ${width < 780 && 'mobile-button'}`}
          onClick={() => {
            if (practiceInbox) {
              return history.push(`/practice-inbox/messages/${clientId}/${providerId}`);
            }
            return history.push(`/messages/${clientId}`);
          }}
        >
          {width < 780 ? (
            <i className='far fa-comments-alt'></i>
          ) : practiceInbox ? (
            hasBeenFetched ? (
              'Message'
            ) : (
              'Loading...'
            )
          ) : (
            'Message'
          )}
        </button>
      </td>
    </tr>
  );
};

export default ConversationRow;
