import React, { useState } from 'react';
import { connect } from 'react-redux';
import PureModal from '+/Modal';
import { actions as paymentActions } from './sagaSlice';
import { actions as mainActions } from '@/main/sagaSlice';
import PaymentFormSeatBased from './PaymentFormSeatBased';
import { flashSuccess } from '~/shared/flashes/redux';
import { dispatch } from '~/store';

const ResubscribeModalSeatBased = ({
  open,
  toggle,
  switchableProviders,
  getMe,
  provider,
}: $TSFixMe) => {
  const [succeeded, setSucceeded] = useState(false);
  const onClose = () => {
    getMe && getMe();
    toggle && toggle(false);
  };

  const onSubscribed = () => {
    dispatch(flashSuccess('You have successfully reactivated your subscription!'));
    toggle && toggle(false);
  };

  return (
    <PureModal
      visible={open}
      headerContent={<h4>Reactivate membership</h4>}
      closeModal={onClose}
      additionalCssClasses={'resubscribe'}
    >
      {succeeded ? (
        <>
          <h4>Congratulations! Your membership has been reactivated!</h4>

          <h5 className='m-t-md'>Here are next steps: </h5>
          <ul>
            <li>
              <b>Profile Checklist:</b>{' '}
              {switchableProviders.length >= 2 ? (
                <>
                  <a className='underline semi-bold' target='_blank' href='/profiles'>
                    Switch to your reactivated profile
                  </a>{' '}
                  and complete your checklist to make your profile live!
                </>
              ) : (
                <>
                  Complete your{' '}
                  <a href='/' target='_blank' className='semi-bold underline'>
                    profile checklist
                  </a>{' '}
                  and make your profile live!
                </>
              )}
            </li>
            <li>
              <b>Photo/Video Reshoot:</b> If you would like a new video and/or photos, you can{' '}
              <a
                href='https://therapist.zencare.co/photo-video-reshoot'
                target='_blank'
                className='underline semi-bold'
              >
                schedule a reshoot
              </a>
              .
            </li>
            <li>
              <b>Professional Statement Copywriting:</b> If you'd like support rewriting your
              professional statement, you can{' '}
              <a
                href='/checkout/price_1Ij3SVHEu9I8ej85WkaG4UH5'
                target='_blank'
                className='underline semi-bold'
              >
                request copywriting from our team
              </a>
              .
            </li>
          </ul>
        </>
      ) : (
        <div className='subscription-container'>
          <h5>Woohoo! You are reactivating your membership.</h5>
          {open && (
            <PaymentFormSeatBased
              setSucceeded={setSucceeded}
              showDoThisLater={false}
              reactivate={true}
              provider={provider}
              onSubscribed={onSubscribed}
            />
          )}
        </div>
      )}
    </PureModal>
  );
};

export default connect(
  ({ payment, main }: $TSFixMe) => ({
    isLoading: payment.isLoading,
    switchableProviders: main.allProviders.filter((p: $TSFixMe) => p.hasClinicianAccess),
  }),
  {
    resubscribeToPlan: paymentActions.resubscribeToPlan,
    getMe: mainActions.getMe,
  }
)(ResubscribeModalSeatBased);
