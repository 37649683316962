import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export const RecoverAccountSuccess = () => (
  <div className='flex wrap justify-around m-t-lg'>
    <Link to='/password/request'>
      <button style={{ width: 250 }} className='primary md m-b-md'>
        I forgot my password
      </button>
    </Link>
    <Link to='/account/recover'>
      <button style={{ width: 250 }} className='primary md m-b-md'>
        I forgot my email address
      </button>
    </Link>
  </div>
);

export default connect((state) => state.auth)(RecoverAccountSuccess);
