/* eslint-disable max-lines-per-function */
import React from 'react';
import { useSelector } from 'react-redux';
import LinkButton from '~/shared/LinkButton';
import Loader from 'react-loader-spinner';

function UnreadMessagesWidget() {
  // const provider_id = useSelector((state) => _.get(state, 'main.provider.id'));
  const { conversations, hasUnreadMessages, isLoadingConversations } = useSelector(
    (state) => state.messages
  );

  return (
    <div
      className={`box no-mobile messages-widget-container ${hasUnreadMessages ? 'has-unread' : ''}`}
    >
      <div className='inner-container flex column'>
        <div className='messages'>
          <h3>
            <i className='fal fa-envelope m-r-sm'></i>Unread Client Messages
          </h3>
          {isLoadingConversations ? (
            <div className='loader-container'>
              <Loader type='Oval' color='#37BEC3' height={50} width={50} />
            </div>
          ) : (
            <>
              {conversations
                ? conversations.map((c) => {
                    if (c.isUnread) {
                      return (
                        <div className='messages-widget-row'>
                          <h6 className='messages-widget-name'>{c.fullName}</h6>
                          <a
                            href={`/messages/${c.id}`}
                            className='messages-widget-button button primary sm hollow pill'
                          >
                            {/* <i className='far fa-comments-alt'></i> */}
                            View Conversation
                          </a>
                        </div>
                      );
                    }
                    return null;
                  })
                : ''}
              {!hasUnreadMessages && <p>You have no unread messages at this time.</p>}
            </>
          )}
        </div>
      </div>
      <div>
        <LinkButton className='pill md primary m-t-sm' to='/messages'>
          View all
        </LinkButton>
      </div>
    </div>
  );
}

// const Appointment = ({ appointment: a }) => {
//   const provider = useSelector((state) => state.main.provider);
//   const calendar = _.get(provider, 'calendar[0]');
//   const tz = useSelector((state) =>
//     calendar && calendar.timezone ? calendar.timezone : _.get(state, 'calendars.tz')
//   );
//   const dispatch = useDispatch();
//   const startTime = DateTime.fromISO(a.start_time).setZone(tz);
//   const start = startTime.setZone(tz).toLocaleString(DateTime.TIME_SIMPLE);
//   const end = DateTime.fromISO(a.end_time).setZone(tz).toLocaleString(DateTime.TIME_SIMPLE);

//   return (
//     <div className='appointment-item m-b-sm'>
//       <div className='date-container'>
//         <h6>
//           {start}-{end} ({startTime.toFormat('ZZZZ')})
//         </h6>
//         {!!a.canceled && <p className='text-error'>This appointment was canceled.</p>}
//       </div>
//       <div className='text-left name-container'>
//         {a.user.first_name} {a.user.last_name}
//       </div>
//       <div className='button-container'>
//         <button
//           onClick={() => dispatch(actions.openAppointment({ id: a.id }))}
//           className='primary sm hollow pill'
//         >
//           View Details
//         </button>
//       </div>
//     </div>
//   );
// };

export default UnreadMessagesWidget;
