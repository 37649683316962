import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '#/history';

import providerName from '#/providerName';
import providerHasSubscriptionInAccount from '#/providerHasSubscriptionInAccount';
import hasClinicianScope from '#/hasClinicianScope';

import { getQueryAsObject } from '../../../../utils/queryParams';
import SubscribeToPlanSeatBased from '~/components/payment/seat-based/SubscribeToPlanSeatBased';
import { dispatch } from '~/store';
import { flashSuccess } from '~/shared/flashes/redux';

const PayForProvider = ({ allProviders, match, accounts, roles }) => {
  let provider;
  try {
    const providerId = _.get(match, 'params.providerId', -1);
    provider =
      allProviders.find((p) => parseInt(p.id) === parseInt(providerId)) ||
      _.flatten(accounts.map((a) => a.providers)).find(
        (p) => parseInt(p.id) === parseInt(providerId)
      );
  } catch (e) {}

  if (!provider) {
    return null;
  }

  const hasSubscription =
    provider.hasPaidPlan || accounts.some((a) => providerHasSubscriptionInAccount(provider, a));
  const isClinician = provider && hasClinicianScope(roles, provider.id);
  const moreThanOneClinician = allProviders.filter((p) => p.hasClinicianAccess).length > 1;

  const onSubscribed = () => {
    console.log('onSubscribed called');
    const urlParams = getQueryAsObject(window.location.search);
    const returnToPlanManagement = urlParams.return_to_plan_management
      ? parseInt(urlParams.return_to_plan_management)
      : 0;
    console.log(`returnToPlanManagement = ${returnToPlanManagement}`);
    if (returnToPlanManagement === 1) {
      history.push(`/plan-management`);
      dispatch(flashSuccess('Successful payment! Thank you for starting your Zencare membership!'));
    } else if (isClinician) {
      history.push(`/profiles/about-you-for/${provider.id}`);
    }

    // Otherwise, do nothing
    // HasSubscription will be auto-set to true, and the congratulations will show.
  };

  const doThisLaterClicked = () => {
    if (isClinician) {
      history.push(`/profiles/about-you-for/${provider.id}?doPaymentLater=1`);
    } else {
      // This provider is still responsible for eventually paying for this provider
      // they can pay for this provider later from the Plan Management page.
      history.push('/plan-management');
    }
  };

  if (hasSubscription) {
    return (
      <>
        <h1 className='m-b-md m-l-xs'>
          Create a new profile! <i className='fad fa-stars text-quaternary'></i>
          <br></br>
          <p className='heading-5 semi-bold'>
            High five! {isClinician ? 'Your new' : `${providerName(provider)}'s'`} profile has been
            created.
          </p>
        </h1>
        <div className='box'>
          {isClinician ? (
            <>
              <h5>
                Congratulations on creating a new{' '}
                {provider.type === 'practice' ? 'group practice ' : ''}profile!
              </h5>
              <p>
                {moreThanOneClinician ? (
                  <>
                    <Link to='/profiles'>Switch to your new profile</Link> and complete your
                    checklist to make your profile live!
                  </>
                ) : (
                  <>Complete your checklist to make your profile live!</>
                )}
              </p>
            </>
          ) : (
            <>
              <p>Congratulations on creating a new profile!</p>
              <p>
                An email has been sent to {provider.custom_content.email} so that{' '}
                {providerName(provider)} can complete their profile content.
              </p>
            </>
          )}
          <p>
            If you have any questions, please visit{' '}
            <a target='_blank' rel='noopener noreferrer' href='https://support.zencare.co/help'>
              our knowledge base
            </a>{' '}
            for step-by-step instructions, guides, and answers for therapist member FAQs, or{' '}
            <a href='/contact'>contact us</a>.
          </p>
          <br></br>
          Have a beautiful day!
          <br></br>
          Zencare team
        </div>
      </>
    );
  } else {
    return (
      <div className='box'>
        <SubscribeToPlanSeatBased
          onSubscribed={onSubscribed}
          provider={provider}
          showStartForLine={true}
          showDoThisLater={false}
          doThisLaterClicked={doThisLaterClicked}
        />
      </div>
    );
  }
};

export default connect(
  ({ main, auth }) => ({
    allProviders: main.allProviders,
    accounts: _.get(main, 'accounts', []).filter((a) => !a.error),
    roles: auth.roles,
  }),
  {}
)(PayForProvider);
