import React from 'react';
import PureModal from '+/Modal';
import SubscribeToPlanSeatBased from '@/payment/seat-based/SubscribeToPlanSeatBased';

interface Props {
  open: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  onSubscribed: $TSFixMeFunction;
  provider: $TSFixMe;
}

const PayAndGoLiveModal = ({ open, toggle, onSubscribed, provider }: Props) => (
  // eslint-disable-next-line implicit-arrow-linebreak
  <PureModal visible={open} additionalCssClasses='payment-modal' closeModal={() => toggle(false)}>
    <div className='m-b-lg' key='subscription-form'>
      <SubscribeToPlanSeatBased
        provider={provider}
        onSubscribed={onSubscribed}
        showDoThisLater={false}
        title='Start Your Membership and Make Your Profile Live'
      />
    </div>
  </PureModal>
);

export default PayAndGoLiveModal;
