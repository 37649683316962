/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { actions } from './sagaSlice';

interface PracticeManagementProps {
  iframeFileNameOverride?: string;
}

interface Account {
  id: number;
  error?: Object;
}

interface PracticeMangementPostMessageEvent {
  data: {
    url: string;
    clinic_id: number;
  };
  origin: string;
}

const PracticeManagement = (props: PracticeManagementProps) => {
  const [setupComplete, setSetupComplete] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const hasPracticeManagementAccount = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.practiceManagement.hasPracticeManagementAccount
  );
  const firstPracticeManagementAccount = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.practiceManagement.firstPracticeManagementAccount
  );

  const [practiceManagementSubdomainName, setPracticeManagementSubdomainName] = useState('');

  const dispatch = useDispatch();

  // @ts-ignore-next-line $TSFixMe
  const provider = useSelector((state) => state.main.provider);
  // @ts-ignore-next-line $TSFixMe
  const accounts = useSelector((state) => state.main.accounts);

  useEffect(() => {
    if (provider && accounts) {
      const possibleAccounts = accounts.filter((a: Account) => !a.error);
      for (let i = 0; i < possibleAccounts.length; i++) {
        const account = possibleAccounts[i];
        if (account.providers.find((x: { id: number }) => x.id === provider.id)) {
          setCurrentAccountId(account.id);
        }
      }
    }
  }, [accounts, provider]);

  useEffect(() => {
    if (provider) {
      const sagaParam: ListPracticeManagementAccountsSagaParam = {
        params: {
          provider_id: provider.id,
        },
      };

      // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
      dispatch(actions.listPracticeManagementAccounts(sagaParam));
    }
  }, [dispatch, provider]);

  useEffect(() => {
    const handleEvent = (event: PracticeMangementPostMessageEvent) => {
      // Only listen for post messages from our own website (only for local testing) or Owl since they host the iframe on their domain.
      if (
        event.origin === process.env.ZENCARE_PRACTICE_MANAGEMENT_SIGNUP_ORIGIN ||
        event.origin === window.location.origin
      ) {
        // console.log(
        //   `Received postMessage event from ${process.env.ZENCARE_PRACTICE_MANAGEMENT_SIGNUP_ORIGIN} origin (so should be the ZPM iframe)!`
        // );
        // console.log(JSON.stringify(event));
        // console.log(JSON.stringify(event.data));

        if (event.data.url && event.data.clinic_id) {
          const sagaParam: AddPracticeManagementAccountSagaParam = {
            params: {
              provider_id: provider.id,
            },
            apiPayload: {
              owl_clinic_id: event.data.clinic_id,
              subdomain: event.data.url,
            },
          };

          // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
          dispatch(actions.addPracticeManagementAccount(sagaParam));
          setPracticeManagementSubdomainName(event.data.url);
          setSetupComplete(true);
        }
      } else {
        // Note: Keep this logging code commented out. We don't need it. We don't care about other postMessages
        // console.log(`Received postMessage event from a different origin (${event.origin})`);
        // console.log(JSON.stringify(event));
        // console.log(JSON.stringify(event.data));
      }
    };

    window.addEventListener('message', handleEvent, false);
    return function cleanup() {
      window.removeEventListener('message', handleEvent);
    };
  });

  if (currentAccountId) {
    if (setupComplete) {
      const practiceManagementDomainName: string | undefined =
        process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME;
      return (
        <>
          <div className='zpm-setup-success w-100'>
            <h2>Welcome to Zencare Practice Management!</h2>
            <img
              src='/zpm-signup/zpm-signup-success-image.png'
              alt='A person using their laptop at a coffee table'
            />
            <p className='success-message'>Log in to your account below to start exploring!</p>

            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://${practiceManagementSubdomainName}.${practiceManagementDomainName}/`}
            >
              <button className='primary pill md'>Log in to Practice Management</button>
            </a>
          </div>
        </>
      );
    } else {
      if (hasPracticeManagementAccount) {
        return (
          <>
            <div className='zpm-setup-success w-100'>
              <h2>Welcome to Zencare Practice Management!</h2>
              <img
                src='/zpm-signup/zpm-signup-success-image.png'
                alt='A person using their laptop at a coffee table'
              />
              <p className='success-message'>Log in to your account below to start exploring!</p>

              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`https://${firstPracticeManagementAccount.subdomain}.${process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME}/`}
              >
                <button className='primary pill md'>Log in to Practice Management</button>
              </a>
            </div>
          </>
        );
      } else {
        return (
          <>
            <h1 className='m-b-xs m-t-l'>Zencare Practice Management Tool</h1>

            <p className='heading-5 semi-bold'>
              Sign up to start using Zencare's practice management tool.
            </p>

            {props.iframeFileNameOverride ? (
              <>
                {/* Useful if trying to do local dev testing with 
                  index-dev-test-immediately-sends-postMessage.html */}
                <iframe
                  style={{
                    width: '100%',
                    height: '100vh',
                  }}
                  title='Zencare Practice Management'
                  src={`/zpm-signup/${props.iframeFileNameOverride}?zencare_account_id=${currentAccountId}`}
                ></iframe>
              </>
            ) : (
              <>
                <iframe
                  style={{
                    width: '100%',
                    height: '100vh',
                  }}
                  title='Zencare Practice Management'
                  src={`${process.env.ZENCARE_PRACTICE_MANAGEMENT_SIGNUP_ORIGIN}/signup-form/index.php?zencare_account_id=${currentAccountId}`}
                ></iframe>
              </>
            )}
          </>
        );
      }
    }
  } else {
    return <div>Loading...</div>;
  }
};

export default PracticeManagement;
