import regex from '#/regex';

export const errorMessages = {
  required: 'Phone number required.',
  invalid: 'Please enter a valid US phone number.',
  international:
    'If you need to add an international phone number, please contact support@zencare.co.',
};

/** Creates an error message depending on the contents of the provider invalid phone number */
export function createPhoneErrorMessage(
  phoneNumber: string,
  isRegistration: boolean = false,
  isRequired: boolean = true
): string[] | undefined {
  const errors = [];
  const isUndefined = (v: string) => !v || !v.trim();

  if (isUndefined(phoneNumber)) {
    if (isRequired) {
      errors.push(errorMessages.required);
    }
  } else {
    if (!regex.phone.test(phoneNumber)) {
      // While appearing generic, this error message is specific
      // enough, as PhoneInputs do not allow the entry of symbols or spaces
      errors.push(errorMessages.invalid);
    }
    if (isRegistration && phoneNumber.length > 12) {
      errors.push(errorMessages.international);
      // TODO: Do we want a "If you need to use an international phone number,
      // please contact support!" here?
    }
  }

  return errors.length ? errors : undefined;
}
