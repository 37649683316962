import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import AppointmentModal from '../AppointmentModal';
import ConversationList from './ConversationList';
import CurrentConversation from './CurrentConversation';
import { Route } from 'react-router-dom';
import HelpdeskLinks from './HelpdeskLinks';

export default function () {
  const [showConversation, setShowConversation] = useState(false);

  return (
    <>
      <h1 className='messages-text-content'>Client Messages</h1>
      <p className='messages-text-content'>
        Message any client who has contacted you or booked a phone consultation with you through
        Zencare.
      </p>
      <HelpdeskLinks mobile={false} />
      <div className='box messages-box'>
        <ConversationList
          showConversation={showConversation}
          setShowConversation={setShowConversation}
        />
        <Route
          exact
          path='/messages'
          children={
            <CurrentConversation
              showConversation={showConversation}
              setShowConversation={setShowConversation}
            />
          }
        />
        <Route
          path='/messages/:id'
          children={
            <CurrentConversation
              showConversation={showConversation}
              setShowConversation={setShowConversation}
            />
          }
        />
      </div>
      <HelpdeskLinks mobile={true} />
      <AppointmentModal />
    </>
  );
}
