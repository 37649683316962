import React from 'react';
import PureModal from '+/Modal';
import PropTypes from 'prop-types';

interface Props {
  open: boolean;
  toggle: $TSFixMeFunction;
  submitted: boolean;
  form: JSX.Element;
  confirmationMessage: JSX.Element;
  headerContent: PropTypes.ReactElementLike | null | undefined;
  footerContent: PropTypes.ReactElementLike | null | undefined;
  className: string;
}

export default function ContactModal({
  open,
  toggle,
  submitted,
  form,
  confirmationMessage,
  headerContent = <h4>Contact the Zencare Team</h4>,
  footerContent,
  className,
}: Props) {
  return (
    <PureModal
      visible={open}
      headerContent={headerContent}
      footerContent={footerContent ? footerContent : null}
      closeModal={() => toggle(false)}
      additionalCssClasses={className}
    >
      {submitted ? (
        confirmationMessage ? (
          confirmationMessage
        ) : (
          <div>
            <h3>We're on it!</h3>
            <h5>Thank you for your message!</h5>
            <h5>The updates will be reflected on your profile within 24 business hours.</h5>
            <h5>We will be in touch with any questions.</h5>
            <h5>Yours,</h5>
            <h5>Zencare Team</h5>
          </div>
        )
      ) : (
        form
      )}
    </PureModal>
  );
}
