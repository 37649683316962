import React from 'react';
import { connect } from 'react-redux';
import urgentActionItemList from './urgentActionItemList';
import DashboardCard from './DashboardCard';
import { actions } from '../sagaSlice';

/**
 * These cards will display at the top of the Therapist Portal Dashboard,
 * above the "Unread Client Messages" and "Upcoming Phone Consultations" widgets
 */
const UrgentActionItems = connect(
  (state: State) => ({
    accounts: state.main.accounts,
    provider: state.main.provider,
  }),
  actions
)(({ accounts, provider }) => {
  const itemsToDisplay = urgentActionItemList.filter((item) =>
    item.providerDisplayFn({ accounts, provider })
  );

  if (!itemsToDisplay.length) {
    return null;
  }

  return (
    <>
      {itemsToDisplay.map((item) => (
        <DashboardCard provider={provider} key={item.cardName} item={item} />
      ))}
    </>
  );
});

export default UrgentActionItems;
