import { put, select } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import { api, sagaApi } from '#/apis';
import { flashSuccess } from '+/flashes/redux';
import { DateTime } from 'luxon';

const sagaSliceModule = createModule({
  name: 'calendars',
  initialState: {
    isLoading: false,
    calendarStartTime: null,
    calendarEndTime: null,
    tz: DateTime.local().zoneName || 'America/New_York',
    currentlyOpen: null,
    defaultRecurrence: null,
    showTypeToggle: true,
    edits: 0,
    fullscreen: false,
    availableGoogleCalendars: [],
    googleAccountJustLinkedToProvider: false,
  },
  reducers: {
    setFullScreen: (state, payload) => {
      state.fullscreen = payload;
    },
    getAvailability: (state, payload) => {
      state.isLoading = true;
    },
    getAvailabilitySuccess: (state, payload) => {
      state.isLoading = false;
      state.availability = payload;
    },
    getAvailabilityFailure: failReducer,
    setStartTime: (state, payload) => {
      state.calendarStartTime = payload;
    },
    setEndTime: (state, payload) => {
      state.calendarEndTime = payload;
    },
    setTZ: (state, payload) => {
      state.tz = payload;
    },
    openDefaultWorkingHoursModal: (state, payload) => {
      state.availabilityModalGoogleCalendarDefaultWorkingHoursMode = true;
      state.currentlyOpen = payload.currentlyOpen;
      state.defaultRecurrence = payload.recurrence;
      state.showTypeToggle = payload.showTypeToggle;
    },

    openAvailability: (state, payload) => {
      state.availabilityModalGoogleCalendarDefaultWorkingHoursMode = false;
      state.currentlyOpen = payload.currentlyOpen;
      state.defaultRecurrence = payload.recurrence;
      state.showTypeToggle = payload.showTypeToggle;
    },

    closeAvailability: (state, __) => {
      state.currentlyOpen = null;
      state.showTypeToggle = true;
    },

    createAvailability: loadingReducer,
    editAvailability: loadingReducer,
    deleteAvailability: loadingReducer,
    createAvailabilityException: loadingReducer,
    availabilitySuccess: (state) => {
      state.edits++;
      state.isLoading = false;
      state.currentlyOpen = null;
    },
    editAvailabilityFailure: failReducer,

    updateCalendar: loadingReducer,
    updateCalendarFailure: failReducer,

    updateCalendarSuccess: notLoadingReducer,
    createAvailabilitySuccess: notLoadingReducer,
    editAvailabilitySuccess: notLoadingReducer,
    createAvailabilityExceptionSuccess: notLoadingReducer,
    deleteAvailabilitySuccess: notLoadingReducer,

    linkGoogleCalendarAccessTokenToProvider: loadingReducer,
    linkGoogleCalendarAccessTokenToProviderFailure: failReducer,
    linkGoogleCalendarAccessTokenToProviderSuccess: (state) => {
      state.googleAccountJustLinkedToProvider = true;
      state.isLoading = false;
    },

    getPotentialGoogleCalendars: loadingReducer,
    getPotentialGoogleCalendarsFailure: failReducer,
    getPotentialGoogleCalendarsSuccess: (state, payload) => {
      // Reverse order so that Simple Practice shows up first
      state.availableGoogleCalendars = payload.reverse();
      state.isLoading = false;
    },

    linkGoogleCalendarToProvider: loadingReducer,
    linkGoogleCalendarToProviderFailure: failReducer,
    linkGoogleCalendarToProviderSuccess: notLoadingReducer,

    unlinkGoogleCalendarFromProvider: loadingReducer,
    unlinkGoogleCalendarFromProviderFailure: failReducer,
    unlinkGoogleCalendarFromProviderSuccess: notLoadingReducer,

    unlinkGoogleAccountFromProvider: loadingReducer,
    unlinkGoogleAccountFromProviderFailure: failReducer,
    unlinkGoogleAccountFromProviderSuccess: notLoadingReducer,

    getProviderExternalCalendars: loadingReducer,
    getProviderExternalCalendarsFailure: failReducer,
    getProviderExternalCalendarsSuccess: (state, payload) => {
      state.providerExternalCalendars = payload;
      state.isLoading = false;
    },

    getGoogleAccountLinkStatus: loadingReducer,
    getGoogleAccountLinkStatusFailure: failReducer,
    getGoogleAccountLinkStatusSuccess: (state, payload) => {
      state.googleAccountLinkStatus = payload;
      state.isLoading = false;
    },
  },

  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.getAvailability]: function* (...args) {
        const payload = args[0].payload;
        const { calendar_id } = payload;
        delete payload.calendar_id;
        const queryString = Object.keys(payload)
          .map((k) => `${k}=${payload[k]}`)
          .join('&');
        try {
          const { data } = yield api.get(
            `/portal/calendar/${calendar_id}/availability?${queryString}`
          );
          if (!data) {
            return yield put(A.getAvailabilityFailure());
          }

          return yield put(A.getAvailabilitySuccess(data));
        } catch (e) {
          console.error(e);
          yield put(A.getAvailabilityFailure());
        }
      },

      [A.createAvailability]: function* ({ payload }) {
        const { calendar_id } = payload;
        delete payload.calendar_id;
        yield sagaApi.post(
          `/portal/calendar/${calendar_id}/availability`,
          payload,
          A.createAvailabilitySuccess,
          A.editAvailabilityFailure
        );

        yield put(A.getGoogleAccountLinkStatus({ provider_id: payload.provider_id }));
      },
      [A.editAvailability]: function* ({ payload }) {
        const { calendar_id, event_id } = payload;
        delete payload.calendar_id;
        delete payload.event_id;
        yield sagaApi.put(
          `/portal/calendar/${calendar_id}/availability/${event_id}`,
          payload,
          A.editAvailabilitySuccess,
          A.editAvailabilityFailure
        );
      },
      [A.createAvailabilityException]: function* ({ payload }) {
        const { calendar_id, event_id } = payload;
        delete payload.calendar_id;
        delete payload.event_id;
        yield sagaApi.post(
          `/portal/calendar/${calendar_id}/availability/${event_id}/exception`,
          payload,
          A.createAvailabilityExceptionSuccess,
          A.editAvailabilityFailure
        );
      },
      [A.deleteAvailability]: function* ({ payload }) {
        const { calendar_id, event_id, restoreOriginalInstance } = payload;
        const qs = restoreOriginalInstance ? '?restoreOriginalInstance=true' : '';
        yield sagaApi.delete(
          `/portal/calendar/${calendar_id}/availability/${event_id}${qs}`,
          null,
          A.deleteAvailabilitySuccess,
          A.editAvailabilityFailure
        );
      },
      [A.updateCalendar]: function* ({ payload }) {
        const { calendar_id } = payload;
        delete payload.calendar_id;
        yield sagaApi.put(
          `/portal/calendar/${calendar_id}`,
          payload,
          A.updateCalendarSuccess,
          A.updateCalendarFailure
        );
      },
      [A.updateCalendarSuccess]: function* () {
        yield put({ type: 'main/getMe' });
        yield put(flashSuccess('Your calendar has been updated.'));
        yield put(A.availabilitySuccess());
      },
      [A.editAvailabilitySuccess]: function* () {
        // yield put(flashSuccess('Your call schedule has been updated.'));
        yield put(A.availabilitySuccess());
      },
      [A.createAvailabilitySuccess]: function* () {
        yield put(flashSuccess('Your call schedule has been updated.'));
        yield put(A.availabilitySuccess());
      },
      [A.createAvailabilityExceptionSuccess]: function* () {
        yield put(flashSuccess('Your call schedule has been updated.'));
        yield put(A.availabilitySuccess());
      },
      [A.deleteAvailabilitySuccess]: function* () {
        yield put(flashSuccess('Your call schedule has been updated.'));
        yield put(A.availabilitySuccess());
      },
      [A.linkGoogleCalendarAccessTokenToProvider]: function* ({ payload }) {
        const { google_calendar_access_token_id } = payload;

        yield sagaApi.post(
          `/portal/provider/google-calendars/link-access-token/${payload.provider_id}`,
          { google_calendar_access_token_id },
          A.linkGoogleCalendarAccessTokenToProviderSuccess,
          A.linkGoogleCalendarAccessTokenToProviderFailure
        );

        yield put(A.getGoogleAccountLinkStatus({ provider_id: payload.provider_id }));
      },
      [A.unlinkGoogleAccountFromProvider]: function* ({ payload }) {
        yield sagaApi.delete(
          `/portal/provider/google-calendars/google-account-link/${payload.provider_id}`,
          null,
          A.unlinkGoogleAccountFromProviderSuccess,
          A.unlinkGoogleAccountFromProviderFailure
        );

        yield put(A.getGoogleAccountLinkStatus({ provider_id: payload.provider_id }));
      },
      [A.getPotentialGoogleCalendars]: function* ({ payload }) {
        yield sagaApi.get(
          `/portal/provider/google-calendars/potential-list/${payload.provider_id}`,
          A.getPotentialGoogleCalendarsSuccess,
          A.getPotentialGoogleCalendarsFailure
        );
      },
      [A.linkGoogleCalendarToProvider]: function* ({ payload }) {
        const { google_calendar_id } = payload;

        yield sagaApi.post(
          `/portal/provider/google-calendars/link-google-calendar/${payload.provider_id}`,
          { google_calendar_id },
          A.linkGoogleCalendarToProviderSuccess,
          A.linkGoogleCalendarToProviderFailure
        );

        yield put(A.getProviderExternalCalendars({ provider_id: payload.provider_id }));

        yield put(flashSuccess('Google calendar linked successfully.'));
      },
      [A.unlinkGoogleCalendarFromProvider]: function* ({ payload }) {
        yield sagaApi.delete(
          `/portal/provider/external-calendars/${payload.provider_id}/${payload.provider_external_calendar_id}`,
          null,
          A.unlinkGoogleCalendarFromProviderSuccess,
          A.unlinkGoogleCalendarFromProviderFailure
        );

        yield put(A.getProviderExternalCalendars({ provider_id: payload.provider_id }));
      },
      [A.getProviderExternalCalendars]: function* ({ payload }) {
        yield sagaApi.get(
          `/portal/provider/external-calendars/${payload.provider_id}`,
          A.getProviderExternalCalendarsSuccess,
          A.getProviderExternalCalendarsFailure
        );
      },
      [A.getGoogleAccountLinkStatus]: function* ({ payload }) {
        yield sagaApi.get(
          `/portal/provider/google-calendars/google-account-status/${payload.provider_id}`,
          A.getGoogleAccountLinkStatusSuccess,
          A.getGoogleAccountLinkStatusFailure
        );
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
