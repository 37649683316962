import DefaultLayout from '##/Default';
import DeveloperTools from './DeveloperTools';

export interface DeveloperToolRoute extends BaseRoute {
  auth: boolean;
  containerLayoutClass?: string;
}

const routes: Array<DeveloperToolRoute> = [
  {
    path: '/developer-tools',
    exact: true,
    layout: DefaultLayout,
    component: DeveloperTools,
    title: 'DeveloperTools',
    auth: true,
  },
];

export default routes;
