import * as React from 'react';
import IsolatedEitherOrToggle from '~/shared/forms/IsolatedEitherOrToggle';
import { useDispatch, useSelector } from 'react-redux';
import { actions as mainActions } from '../../main/sagaSlice';
import { actions as practiceManagementActions } from '~/components/practice-management/sagaSlice';
import mixpanel from 'mixpanel-browser';
import BookingLinkSetupModal from './BookingLinkSetupModal';

function PracticeManagementBookingLinkWidget() {
  const dispatch = useDispatch();
  const provider = useSelector<State, MainState['provider']>((state) => state?.main?.provider);
  const practiceManagement = useSelector<State, $TSFixMe>((state) => state?.practiceManagement);
  const [shouldShowModal, setShouldShowModal] = React.useState(false);

  React.useEffect(() => {
    if (provider) {
      dispatch(
        // @ts-expect-error
        practiceManagementActions.isClinicBookingPageReady({ params: { provider_id: provider.id } })
      );
    }
  }, [provider, dispatch]);

  if (!provider) return null;

  const { practice_management_booking_link } = provider;
  const { firstPracticeManagementAccount, isClinicBookingPageReady } = practiceManagement;
  const linkIsActive = practice_management_booking_link?.status === 'active';

  // Link to booking page or admin entered link override if one exists
  const portalLink = practice_management_booking_link?.link_override
    ? practice_management_booking_link.link_override
    : `https://aws-portal.zencarepractice.com/${firstPracticeManagementAccount.subdomain}/booking`;
  // Link to settings page
  const configureLink = `https://${firstPracticeManagementAccount.subdomain}.zencarepractice.com/settings/client-portal`;
  const helpDeskLink =
    'https://practicemanagement.zencare.co/help/how-do-i-set-up-phone-consultations-and-add-them-to-zencare';

  const trackClickEvent = (action: string) => {
    try {
      mixpanel.track('therapist_portal_booking_link_event', {
        action,
        provider_id: provider.id,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const disableBookingLink = () => {
    trackClickEvent('disable_booking_link');
    dispatch(
      // @ts-expect-error
      mainActions.toggleBookingLink({ status: 'inactive', provider_id: provider.id })
    );
  };

  const enableBookingLink = () => {
    trackClickEvent('enable_booking_link');
    if (!practice_management_booking_link?.link_override && !isClinicBookingPageReady) {
      setShouldShowModal(true);
      return;
    }
    // @ts-expect-error
    dispatch(mainActions.toggleBookingLink({ status: 'active', provider_id: provider.id }));
  };

  return (
    <div className='m-b-sm practice-management-booking-link-widget'>
      <h2>Use your Zencare Practice Management EHR booking link</h2>
      {linkIsActive ? (
        <p className='m-b-0'>
          Your booking link is enabled. Therapy seekers will be able to book with you directly on
          your{' '}
          <a className='bold underline' href={portalLink} target='_blank' rel='noopener noreferrer'>
            EHR Client Portal
          </a>
          . Make sure it’s set up correctly and follow{' '}
          <a
            href={helpDeskLink}
            target='_blank'
            rel='noopener noreferrer'
            className='bold underline'
          >
            these instructions
          </a>{' '}
          to make any changes.
        </p>
      ) : (
        <p className='m-b-0'>
          You can display your Zencare EHR calendar directly on your profile. Check that your{' '}
          <a className='bold underline' href={portalLink} target='_blank' rel='noopener noreferrer'>
            EHR Client Portal
          </a>{' '}
          is set up and follow{' '}
          <a
            href={helpDeskLink}
            target='_blank'
            rel='noopener noreferrer'
            className='bold underline'
          >
            these instructions
          </a>{' '}
          to make any changes. When you're ready, enable the booking link below!
        </p>
      )}
      <p>
        <a
          href={configureLink}
          target='_blank'
          rel='noopener noreferrer'
          className='bold'
          onClick={() => trackClickEvent('configure_booking_link')}
        >
          Configure Client Portal Booking Page<i className='far fa-external-link m-l-xs'></i>
        </a>
      </p>
      <div>
        <IsolatedEitherOrToggle
          offText='Enable Booking Link'
          onText='Hide Booking Link'
          toggleName={'booking-link'}
          handleToggleOn={disableBookingLink}
          handleToggleOff={enableBookingLink}
          isOn={!linkIsActive}
          toggleTooltipText=''
        />
      </div>
      <BookingLinkSetupModal toggleModal={setShouldShowModal} visible={shouldShowModal} />
    </div>
  );
}

export default PracticeManagementBookingLinkWidget;
