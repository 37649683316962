// Given roles and a provider id, check if owner scope exists
export default function hasOwnerScope(accounts, accountRoles, providerId) {
  if (!providerId || !accounts || !accounts.length || !accountRoles || !accountRoles.length) {
    return false;
  }

  /* Example of accounts
    [
    {
        "id": 9369,
        "name": "LocalTestFeb21at1040 LocalTestFeb21at1040",
        "settings": null,
        "meta": null,
        "providers": [
            {
                "id": 2242,
                "etc": "etc..."
            }
        ],
        "stripe_customers": []
    }
    ] */

  /* Example of accountRoles
       [
        {"id":19441,"account_id":9369,"user_id":2157,"role":"owner","scope":null},
        {"id":19442,"account_id":9369,"user_id":2157,"role":"clinician","scope":{"providers":[2242]}}
    ] */
  let isOwner = false;

  const ownerRoles = accountRoles.filter((x) => x.role === 'owner');

  ownerRoles.forEach((ownerRole) => {
    const ownedAccount = accounts.find((x) => x.id === ownerRole.account_id);
    if (ownedAccount && ownedAccount.providers) {
      const matchingProvider = ownedAccount.providers.find((x) => x.id === providerId);
      if (matchingProvider) {
        isOwner = true;
      }
    }
  });

  return isOwner;
}
