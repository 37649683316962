/* eslint-disable max-lines-per-function */
import React, { MutableRefObject, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import FormCheckbox from '+/forms/FormCheckbox';
import EitherOrToggle from '+/forms/EitherOrToggle';
import { actions as onboardingActions } from './sagaSlice';
import Loader from '+/forms/SectionLoader';
import rgx from '#/regex';
import voca from 'voca';
import _ from 'lodash';
import providerName from '#/providerName';
import useQueryParams from '+/hooks/useQueryParams';
import { capitalizeName } from '../../utils/capitalizeName';
import storage from '#/storage';
import replaceSpecialCharacters from 'replace-special-characters';
import PhoneInput from '~/shared/forms/PhoneInput';
import { createPhoneErrorMessage } from '~/utils/createPhoneErrorMessage';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import StandaloneZencarePracticeManagementRedirectModal from './StandaloneZencarePracticeManagementRedirectModal';

function decode(encoded: $TSFixMe) {
  if (_.isNil(encoded)) {
    return undefined;
  }

  return decodeURIComponent(encoded);
}

const RegistrationForm = ({ isFromExistingClientUser, isLoading, inviteToken }: $TSFixMe) => {
  // @ts-ignore-next-line
  const recaptchaRef: MutableRefObject<ReCAPTCHA> = React.useRef();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [prevEmail, setPrevEmail] = useState();
  const [prevOwnerEmail, setPrevOwnerEmail] = useState();
  // @ts-ignore: Type defaultRootState
  const { error } = useSelector((state) => state.onboarding);
  const [recaptchaTokenWasCheckedBefore, setRecaptchaTokenWasCheckedBefore] = useState(false);

  const query = useQueryParams();

  const qFirstName = decode(query.get('first_name'));
  const qMiddleName = decode(query.get('middle_name'));
  const qLastName = decode(query.get('last_name'));
  const qEmail = decode(query.get('email'));
  const qPhoneNumber = decode(query.get('phone_number'));
  const qReferrer = decode(query.get('referrer'));
  const qType = decode(query.get('type'));
  const qFrom = decode(query.get('from'));
  let referrer: $TSFixMe;

  if (qReferrer) {
    referrer = atob(qReferrer);
  }

  const initialValues = {
    practice_type: qType || 'individual',
    type: 'practice',
    first_name: qFirstName,
    middle_name: qMiddleName,
    last_name: qLastName,
    email: qEmail,
    phone_number: qPhoneNumber,
    agree_to_terms: false,
  };

  const onSubmit = (values: $TSFixMe) => {
    const providerPayload: $TSFixMe = {
      type: isIndividualProfile(values) ? 'individual' : 'practice',
      ...(isIndividualProfile(values)
        ? {
            first_name: capitalizeName(values.first_name),
            last_name: capitalizeName(values.last_name),
            middle_name: capitalizeName(values.middle_name),
          }
        : {
            company_name: values.company_name,
          }),
      email: values.email,
      phone_number: values.phone_number,
    };

    if (qFrom) {
      providerPayload.account_attributes_signup_source = qFrom;
    }

    console.log('Determining what to set for contact_email');
    if (isIndividualNewUserMode(values)) {
      if (values.contact_email_type === 'my-email') {
        providerPayload.contact_email = values.owner_email;
      } else if (values.contact_email_type === 'provider-email') {
        providerPayload.contact_email = values.email;
      } else {
        providerPayload.contact_email = values.other_email_value;
      }

      // Give owner clinician permission.
      providerPayload.ownerHasClinicianPermission = values.ownerHasClinicianPermission === 'yes';
    } else {
      providerPayload.contact_email = values.contact_email;
    }
    console.log(`providerPayload.contact_email = ${providerPayload.contact_email}`);

    if (isFromExistingClientUser) {
      dispatch(
        // @ts-ignore: Expects 0 arguments got 1
        onboardingActions.registerAndCreateProviderFromExistingClientAccount({ providerPayload })
      );
    } else {
      // We might already have a recaptcha token if we already tried to submit and got server validation error.
      // If so, we need to call reset before calling recaptchaRef.current.executeAsync
      if (recaptchaTokenWasCheckedBefore) {
        recaptchaRef.current.reset();
      }

      recaptchaRef.current.executeAsync().then((token) => {
        setRecaptchaTokenWasCheckedBefore(true);
        values.email = values.email.trim();
        if (values.phone_number) {
          values.phone_number = values.phone_number.trim();
        }
        if (values.owner_phone_number) {
          values.owner_phone_number = values.owner_phone_number.trim();
        }
        setPrevEmail(values.email);
        setPrevOwnerEmail(values.owner_email);

        let userNameInfo;
        if (isIndividualProfile(values)) {
          userNameInfo = {
            first_name: capitalizeName(values.first_name),
            last_name: capitalizeName(values.last_name),
            middle_name: capitalizeName(values.middle_name),
            full_name: values.middle_name
              ? `${capitalizeName(values.first_name)} ${capitalizeName(
                  values.middle_name
                )} ${capitalizeName(values.last_name)}`
              : `${capitalizeName(values.first_name)} ${capitalizeName(values.last_name)}`,
          };
        } else {
          userNameInfo = {
            company_name: values.company_name,
            full_name: values.full_name,
          };
        }

        if (isIndividualNewUserMode(values)) {
          const authPayload = {
            username: voca.snakeCase(
              // Coerces String to string to satisfy type definition
              `${replaceSpecialCharacters(`${values.owner_first_name} ${values.owner_last_name}`)}`
            ),
            email: values.owner_email,
            phone_number: values.owner_phone_number,
            password: values.password,
            first_name: capitalizeName(values.owner_first_name),
            last_name: capitalizeName(values.owner_last_name),
            middle_name: capitalizeName(values.owner_middle_name),
            full_name: values.owner_middle_name
              ? `${capitalizeName(values.owner_first_name)} ${capitalizeName(
                  values.owner_middle_name
                )} ${capitalizeName(values.owner_last_name)}`
              : `${capitalizeName(values.owner_first_name)} ${capitalizeName(
                  values.owner_last_name
                )}`,
            inviteToken,
            referrer,
            token,
          };

          providerPayload.createInviteToken = true;

          dispatch(
            // @ts-ignore: Expected 0 arguments but got 1
            onboardingActions.registerAndCreateProvider({
              authPayload,
              providerPayload,
              inviteToken,
            })
          );
        } else {
          const authPayload = {
            // Coerces String to string to satisfy type definition
            username: voca.snakeCase(`${replaceSpecialCharacters(providerName(providerPayload))}`),
            email: values.email,
            phone_number: values.phone_number,
            password: values.password,
            ...userNameInfo,
            inviteToken,
            referrer,
            token,
          };

          dispatch(
            // @ts-ignore: Expected 0 arguments but got 1
            onboardingActions.registerAndCreateProvider({
              authPayload,
              providerPayload,
              inviteToken,
            })
          );
        }
      });
    }
  };

  const checkSetRequired = (values: $TSFixMe, errors: $TSFixMe, c: $TSFixMe) => {
    if (_.isNil(values[c]) || values[c] === '') {
      errors[c] = `${voca.capitalize(c).split('_').join(' ')} required.`;
    }
  };

  const isIndividualProfile = (values: $TSFixMe) =>
    values.practice_type === 'individual' ||
    values.type === 'individual' ||
    values.type === 'individual-new-user';

  const isIndividualNewUserMode = (values: $TSFixMe) =>
    values.practice_type === 'practice' && values.type === 'individual-new-user';

  const AgreeToTerms = () => (
    <>
      <div className='mx-md m-b-sm w-100'>
        <Field name='agree_to_terms'>
          {(props) => (
            <FormCheckbox
              {...props}
              label={
                <p className='i-agree-label-inner'>
                  I agree to the{' '}
                  <a
                    href='https://zencare.co/policy/terms'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Zencare Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://reimbursify.com/terms-of-use/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Reimbursify Terms of Service
                  </a>
                  . This site is protected by reCAPTCHA and the Google{' '}
                  <a
                    href='https://policies.google.com/privacy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://policies.google.com/terms'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Terms of Service
                  </a>{' '}
                  apply.
                </p>
              }
            />
          )}
        </Field>
      </div>
    </>
  );

  return (
    <>
      {isLoading && (
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) => {
          if (values.email) {
            values.email = values.email.trim();
          }

          const errors: $TSFixMe = {};

          if (inviteToken) {
            ['first_name', 'last_name', 'email'].forEach((c) =>
              checkSetRequired(values, errors, c)
            );

            errors.phone_number = createPhoneErrorMessage(values.phone_number, true);

            if (values.password !== values.confirm_password) {
              errors.confirm_password = 'Passwords do not match.';
            }

            if (!rgx.new_password.test(values.password)) {
              errors.password =
                'Your password must be at least 8 characters long and have at least one character from three of the following four categories: lowercase, uppercase, number, special character';
            }

            if (!rgx.email.test(values.email)) {
              errors.email = 'Invalid email.';
            }

            return errors;
          }

          if (isIndividualProfile(values)) {
            ['first_name', 'last_name'].forEach((c) => checkSetRequired(values, errors, c));
          } else {
            ['company_name', 'full_name'].forEach((c) => checkSetRequired(values, errors, c));

            if (isIndividualNewUserMode(values)) {
              if (!values.contact_email_type) {
                errors.contact_email_type = 'Please choose where to send referrals';
              }
            }
          }

          if (!isFromExistingClientUser) {
            ['email', 'password', 'confirm_password'].forEach((c) =>
              checkSetRequired(values, errors, c)
            );

            if (!values['agree_to_terms']) {
              errors['agree_to_terms'] = 'You must agree to the terms of service.';
            }

            if (isIndividualNewUserMode(values)) {
              // Require and validate owner phone number
              errors.owner_phone_number = createPhoneErrorMessage(values.owner_phone_number, true);
              // Only validate provider phone number if one is entered
              errors.phone_number = createPhoneErrorMessage(values.phone_number, true, false);
            } else {
              // Require and validate provider phone number if registering an individual profile
              errors.phone_number = createPhoneErrorMessage(values.phone_number, true);
            }

            if (values.password !== values.confirm_password) {
              errors.confirm_password = 'Passwords do not match.';
            }

            if (!rgx.password.test(values.password)) {
              errors.password =
                'Your password must be at least 8 characters long and have at least one character from three of the following four categories: lowercase, uppercase, number, special character';
            }

            if (!rgx.email.test(values.email)) {
              errors.email = 'Invalid email.';
            }
          }

          return errors;
        }}
        render={({ handleSubmit, pristine, valid, values, errors }) => (
          <div className={` ${isLoading ? 'hide' : ''}`} key='registration-form'>
            <div className='box m-b-sm'>
              <h2 className='m-b-sm'>Create your account</h2>
              <div className='flex row justify-between m-b-sm wrap tab-container'>
                <div className='w-100'>
                  {!inviteToken && (
                    <div className='field m-b-xs'>
                      <h5>Which type of practice do you have?</h5>
                      <EitherOrToggle
                        name='practice_type'
                        options={[
                          {
                            value: 'individual',
                            displayName: 'Solo Practice',
                          },
                          {
                            value: 'practice',
                            displayName: 'Group Practice',
                          },
                        ]}
                      />
                      <ErrorField name='practice_type' />
                    </div>
                  )}
                </div>
                {values.practice_type === 'practice' && (
                  <>
                    <div className='w-100 field m-b-xs flex column show-native-radio'>
                      <h4>Which type of new profile are you creating?</h4>
                      <Field
                        name='type'
                        component='input'
                        type='radio'
                        value='practice'
                        id='group-profile'
                      />
                      <label className='m-b-sm' htmlFor='group-profile'>
                        Create a new group practice profile
                      </label>

                      <Field
                        name='type'
                        component='input'
                        type='radio'
                        value='individual'
                        id='individual-profile'
                      />
                      <label className='m-b-sm' htmlFor='individual-profile'>
                        Create a new provider profile for myself
                      </label>

                      <Field
                        name='type'
                        component='input'
                        type='radio'
                        value='individual-new-user'
                        id='individual-new-user'
                      />
                      <label className='m-b-sm' htmlFor='individual-new-user'>
                        Create a new provider profile for another clinician in my practice
                      </label>
                    </div>

                    <div className='w-100'>
                      <hr></hr>
                    </div>
                  </>
                )}
                {!isFromExistingClientUser && isIndividualNewUserMode(values) && (
                  <>
                    <div className='field w-49 m-b-xs'>
                      <h5>Your First Name</h5>
                      <Field
                        name='owner_first_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Esther'
                        autocomplete='given-name'
                        readOnly={qFirstName}
                      />
                      <ErrorField name='owner_first_name' />
                    </div>

                    <div className='field w-49 m-b-xs'>
                      <h5>Your Last Name</h5>
                      <Field
                        name='owner_last_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Perel'
                        autocomplete='family-name'
                        readOnly={qLastName}
                      />
                      <ErrorField name='owner_last_name' />
                    </div>

                    <div className='field w-49 m-b-xs'>
                      <h5>Your Email Address</h5>
                      <Field
                        name='owner_email'
                        className='w-100 m-b-xs'
                        component='input'
                        autocomplete='email'
                        placeholder={'e.g. esther@estherperel.com'}
                        readOnly={qEmail}
                      />
                      <ErrorField name='owner_email' />
                      {error === 'email-already-used' && values.owner_email === prevOwnerEmail && (
                        <p className='help text-error'>
                          Oops! A user with this email address already exists! To create a new
                          individual or group practice profile for your practice, please{' '}
                          <a href='/profiles/create'>log in</a>.
                        </p>
                      )}
                    </div>

                    <div className='field w-49 m-b-xs'>
                      <h5>Your Phone Number</h5>
                      <Field
                        name='owner_phone_number'
                        className='w-100 m-b-xs'
                        component={PhoneInput}
                        placeholder='e.g. (123) 456-7890'
                        auto-complete='tel-national'
                      />
                      <ErrorField name='owner_phone_number' />
                    </div>

                    <div className='field w-49 m-b-xs'>
                      <h5>Password</h5>
                      <Field
                        type={showPassword ? 'input' : 'password'}
                        className='w-100 m-b-xs'
                        component='input'
                        name='password'
                        autocomplete='new-password'
                      />
                      <ErrorField name='password' />
                    </div>

                    <div className='field w-49 m-b-xs'>
                      <h5>Confirm password</h5>
                      <Field
                        type={showPassword ? 'input' : 'password'}
                        className='w-100 m-b-xs'
                        component='input'
                        autocomplete='new-password'
                        name='confirm_password'
                      />
                      <ErrorField name='confirm_password' />
                    </div>
                    <div className='mx-md m-b-sm checkbox'>
                      <input
                        type='checkbox'
                        id='show-password'
                        checked={showPassword}
                        onChange={() => setShowPassword(!showPassword)}
                      />
                      <label className='checkbox' htmlFor='show-password'>
                        Show Password
                      </label>
                    </div>

                    <AgreeToTerms />

                    <div className='w-100'>
                      <hr></hr>
                    </div>
                  </>
                )}
                {isIndividualProfile(values) && (
                  <>
                    <div className='field w-32 m-b-xs'>
                      <h5>
                        {isIndividualNewUserMode(values) ? "Provider's First Name" : 'First name'}
                      </h5>
                      <Field
                        name='first_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Cassandra'
                        autocomplete='given-name'
                        readOnly={qFirstName}
                      />
                      <ErrorField name='first_name' />
                    </div>

                    <div className='field w-32 m-b-xs'>
                      <h5>
                        {isIndividualNewUserMode(values) ? "Provider's Middle Name" : 'Middle name'}
                      </h5>
                      <Field
                        name='middle_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Brené'
                        autocomplete='additional-name'
                        readOnly={qMiddleName}
                      />
                      <ErrorField name='middle_name' />
                    </div>

                    <div className='field w-32 m-b-xs'>
                      <h5>
                        {isIndividualNewUserMode(values) ? "Provider's Last Name" : 'Last name'}
                      </h5>
                      <Field
                        name='last_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Brown'
                        autocomplete='family-name'
                        readOnly={qLastName}
                      />
                      <ErrorField name='last_name' />
                    </div>
                  </>
                )}
                {!isIndividualProfile(values) && (
                  <>
                    <div className='field w-49 m-b-xs'>
                      <h5>Group Practice Name</h5>
                      <Field
                        name='company_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Esther Perel Couples Counseling Associates'
                      />
                      <ErrorField name='company_name' />
                    </div>
                    <div className='field w-49 m-b-xs'>
                      <h5>Who is the head of this practice?</h5>
                      <Field
                        name='full_name'
                        className='w-100 m-b-xs'
                        component='input'
                        placeholder='e.g. Esther Perel'
                        autocomplete='name'
                      />
                      <ErrorField name='full_name' />
                    </div>
                  </>
                )}
                {(isIndividualNewUserMode(values) || !isFromExistingClientUser) && (
                  <>
                    <div className='field w-49 m-b-xs'>
                      <h5>
                        {isIndividualNewUserMode(values)
                          ? "Provider's email address"
                          : 'Email address'}{' '}
                        {isIndividualProfile(values) ? '' : 'for account owner'}
                      </h5>
                      <Field
                        name='email'
                        className='w-100 m-b-xs'
                        component='input'
                        autocomplete='email'
                        placeholder={
                          isIndividualProfile(values)
                            ? 'e.g. brenebrown@gmail.com'
                            : 'e.g. owner@estherperel.com'
                        }
                        readOnly={qEmail}
                      />
                      <ErrorField name='email' />
                      {!isIndividualNewUserMode(values) &&
                        error === 'email-already-used' &&
                        values.email === prevEmail && (
                          <p className='help text-error'>
                            Oops! A user with this email address already exists! To create a new
                            individual or group practice profile for your practice, please{' '}
                            <a href='/profiles/create'>log in</a>.
                          </p>
                        )}
                    </div>
                  </>
                )}
                {!isIndividualProfile(values) && (
                  <div className='field w-49 m-b-xs'>
                    <h5>Email address at which to receive referrals</h5>
                    <Field
                      name='contact_email'
                      className='w-100 m-b-xs'
                      component='input'
                      autocomplete='email'
                      placeholder='e.g. referrals@estherperel.com'
                    />
                    <ErrorField name='contact_email' />
                  </div>
                )}
                {(isIndividualNewUserMode(values) || !isFromExistingClientUser) && (
                  <>
                    <div className='field w-49 m-b-xs'>
                      <h5>
                        {isIndividualNewUserMode(values)
                          ? "Provider's mobile number (optional)"
                          : 'Mobile number'}{' '}
                      </h5>
                      <Field
                        name='phone_number'
                        className='w-100 m-b-xs'
                        component={PhoneInput}
                        placeholder='e.g. (123) 456-7890'
                        auto-complete='tel-national'
                      />
                      <ErrorField name='phone_number' />
                    </div>
                  </>
                )}
                {isIndividualNewUserMode(values) ? (
                  <>
                    <div className='field w-100 m-b-xs m-t-md'>
                      <h6>
                        An email will be sent to the email address above so your clinician can
                        complete their profile content.
                      </h6>
                    </div>

                    <div className='field w-100 m-b-xs m-t-xs'>
                      <h6>To what email address should referrals for this provider be sent?</h6>
                      <Field
                        name='contact_email_type'
                        component='input'
                        type='radio'
                        value='provider-email'
                        id='provider-email'
                      />
                      <label className='m-b-sm w-100' htmlFor='provider-email'>
                        Provider's email: {values.email}
                      </label>
                      <Field
                        name='contact_email_type'
                        component='input'
                        type='radio'
                        value='my-email'
                        id='my-email'
                      />
                      <label className='m-b-sm w-100' htmlFor='my-email'>
                        My email
                        {isFromExistingClientUser || !values || !values.owner_email
                          ? ''
                          : `: ${values.owner_email}`}
                      </label>
                      <Field
                        name='contact_email_type'
                        component='input'
                        type='radio'
                        value='other-email'
                        id='other-email'
                      />
                      <label className='m-b-sm w-100 flex' htmlFor='other-email'>
                        Other email:{' '}
                        <Field
                          style={{
                            padding: '0.1rem 0.75rem',
                          }}
                          name='other_email_value'
                          className='w-49 m-b-xs'
                          component='input'
                          autocomplete='email'
                        />
                      </label>
                      <ErrorField name='contact_email_type' />
                    </div>
                  </>
                ) : (
                  !isFromExistingClientUser && (
                    <>
                      <div className='w-100'>
                        <hr></hr>
                      </div>
                      <div className='field w-49 m-b-xs'>
                        <h5>Password</h5>
                        <Field
                          type={showPassword ? 'input' : 'password'}
                          className='w-100 m-b-xs'
                          component='input'
                          name='password'
                          autocomplete='new-password'
                        />
                        <ErrorField name='password' />
                      </div>

                      <div className='field w-49 m-b-xs'>
                        <h5>Confirm password</h5>
                        <Field
                          type={showPassword ? 'input' : 'password'}
                          className='w-100 m-b-xs'
                          component='input'
                          autocomplete='new-password'
                          name='confirm_password'
                        />
                        <ErrorField name='confirm_password' />
                      </div>
                      <div className='mx-md m-b-sm checkbox'>
                        <input
                          type='checkbox'
                          id='show-password'
                          checked={showPassword}
                          onChange={() => setShowPassword(!showPassword)}
                        />
                        <label className='checkbox' htmlFor='show-password'>
                          Show Password
                        </label>
                      </div>

                      <AgreeToTerms />
                    </>
                  )
                )}
              </div>

              {error === 'email-cannot-be-used' && (
                <p className='help text-error'>
                  Uh oh! This account cannot be accessed. If you think this is an error, please{' '}
                  <a
                    style={{
                      color: 'unset',
                      textDecoration: 'underline',
                    }}
                    href='mailto:support@zencare.co'
                  >
                    contact the Zencare team
                  </a>
                  .
                </p>
              )}
            </div>
            {process.env.ZENCARE_GOOGLE_CAPTCHA_KEY && (
              <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={process.env.ZENCARE_GOOGLE_CAPTCHA_KEY}
              />
            )}
            <div className='w-100 flex justify-center'>
              <SubmitButton
                buttonClass='lg primary pill'
                buttonText='Save + Next'
                pristine={pristine}
                valid={valid}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        )}
      />
      <StandaloneZencarePracticeManagementRedirectModal />
    </>
  );
};

export default RegistrationForm;
