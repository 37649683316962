import React from 'react';
import { Field } from 'react-final-form';

export default ({
  name,
  options,
  containerClassName = 'tab-select m-b-sm',
  innerContainerClassName = 'container',
  labelClassName = 'flex-center',
}) => (
  <div className={containerClassName}>
    {
      <>
        {options.map((o) => (
          <div key={o.value} className={innerContainerClassName}>
            <Field
              type='radio'
              component='input'
              value={o.value}
              name={name}
              id={`${name}-${o.value}`}
            />
            <label className={labelClassName} htmlFor={`${name}-${o.value}`}>
              {o.displayName}
            </label>
          </div>
        ))}
      </>
    }
  </div>
);
