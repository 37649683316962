/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import AddressFields from '@/provider/profile/AvailabilityLocations/AddressFields';
import { getHTMLFromText, getPlainText } from '@/provider/profile/MessageStatement/helpers';
import { regex } from '@/provider/profile/MessageStatement/constants';
import useWindowSize from '+/hooks/useWindowSize';
import PhotoUploader from '+/PhotoUploader';
import voca from 'voca';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import SubmitButton from '+/forms/SubmitButton';
import _ from 'lodash';
import EitherOrToggle from '+/forms/EitherOrToggle';
import ProfileSelectModal from '../profile/PhotosVideo/ProfileSelectModal';
import stateAbbrDict from '~/utils/stateAbbrDict';

dayjs.extend(utc);
const lookupAbbrByFullState = _.invert(stateAbbrDict);

function DoubleErrorContainer({ left, right, errors }) {
  return (
    <div className='w-100 flex justify-between'>
      {!errors[left] && <span className='w-49'></span>}
      <ErrorField className='w-49' name={left} />
      <ErrorField className='w-49' name={right} />
      {!errors[right] && <span className='w-49'></span>}
    </div>
  );
}

export default ({
  therapyGroup = {},
  galleryImages = [],
  submit = () => ({}),
  toggle,
  deleteGroup,
  logEvent,
  provider,
}) => {
  const [imagesOpen, setImagesOpen] = useState(false);
  const [width] = useWindowSize();
  const isMobile = width <= 1024;
  const location = _.get(therapyGroup, 'locations[0]', {});

  if (_.get(therapyGroup, 'image.handle')) {
    galleryImages = _.uniqBy([therapyGroup.image].concat(galleryImages), 'key');
  }

  const handleSubmit = (values) => {
    const tg = {
      ..._.pick(values, ['name', 'hours', 'costs', 'status']),
      expired_at: dayjs(values.expired_at),
      description: getHTMLFromText(values.description),
      short_description: values.short_description, //should not have html in it, will not render anywhere
    };

    const image = {
      ..._.pick(values.image, ['handle', 'url', 'filename', 'size', 'mimetype', 'key']),
    };

    const location =
      values.remote_location === 'online'
        ? {
            state: values.state,
            remote_location: 1,
          }
        : {
            ..._.pick(values, ['address', 'city', 'state', 'zip', 'address_2']),
            remote_location: 0,
          };

    submit({
      therapyGroup: tg,
      image,
      location,
    });
  };

  const initialValues = {
    ...therapyGroup,
    remote_location: location.remote_location ? 'online' : 'inperson',
    expired_at: dayjs.utc(therapyGroup.expired_at || dayjs().add(4, 'month')).format('YYYY-MM-DD'),
    description: getPlainText(therapyGroup.description),
    short_description: getPlainText(therapyGroup.short_description),
    address: location.address,
    city: location.city,
    state: lookupAbbrByFullState[location.state],
    zip: location.zip,
    address_2: location.address_2,
    image: therapyGroup.image || _.get(therapyGroup, 'seo.image'),
  };

  return (
    <div className='therapy-group-form-container'>
      <div className='header'>
        <h2>{therapyGroup.name || 'Therapy Group'}</h2>
        <button className='primary' onClick={() => toggle(false)}>
          Cancel
        </button>
      </div>
      <div className='body'>
        <Form
          onSubmit={handleSubmit}
          initialValues={_.clone(initialValues)}
          mutators={{
            addImage: ([name, imageDetails], state, { changeValue }) => {
              changeValue(state, name, (value) => imageDetails);
            },
          }}
          validate={(values) => {
            const errors = {};
            ['name', 'hours', 'costs', 'description', 'short_description', 'state'].forEach((c) => {
              if (_.isNil(values[c]) || values[c] === '') {
                errors[c] = `${voca.capitalize(c).split('_').join(' ')} required.`;
              }
            });

            if (provider.status !== 'active' && values.status === 'active') {
              errors.status = 'Your group may not become active until your profile is active!';
            }

            if (values.short_description && values.short_description.length > 255) {
              errors.short_description =
                'Your short description exceeds the maximum length. (255 characters)';
            }

            ['short_description', 'description'].forEach((field) => {
              if (
                regex.phone.test(values[field]) ||
                regex.email.test(values[field]) ||
                regex.url.test(values[field])
              ) {
                errors[field] =
                  'Please do not include URLs, phone numbers, or email addresses in your description!';
              }
            });

            if (values.remote_location === 'inperson') {
              ['address', 'city', 'zip'].forEach((c) => {
                if (_.isNil(values[c]) || values[c] === '') {
                  errors[c] = `${voca.capitalize(c)} required.`;
                }
              });
            }

            if (values.status === 'active') {
              if (_.isNil(values.expired_at)) {
                errors.expired_at = 'Expiration date required.';
              } else if (new Date() > new Date(values.expired_at)) {
                errors.expired_at = 'Expiration date must be in the future.';
              } else if (dayjs(new Date(values.expired_at)).isAfter(dayjs().add(1, 'year'))) {
                errors.expired_at = 'Expiration date must be within 12 months.';
              }
            }

            if (!values.image || !values.image.handle) {
              errors.image = 'Image required.';
            }

            if (values.name && values.name.indexOf('"') !== -1) {
              errors.name = 'Double quotes are not allowed in the name.';
            }

            return errors;
          }}
          render={(props) => (
            <div className='flex row justify-between m-b-sm wrap tab-container'>
              {therapyGroup.id && (
                <div className='field w-100 m-b-xs'>
                  <h5 className='w-100'>Therapy Group</h5>
                  <p className='description'>Is this therapy group active?</p>
                  <EitherOrToggle
                    name='status'
                    options={[
                      {
                        value: 'active',
                        displayName: 'Active',
                      },
                      {
                        value: 'inactive',
                        displayName: 'Inactive',
                      },
                    ]}
                  />
                  <ErrorField name='status' />
                </div>
              )}

              <div className='field w-49 m-b-xs'>
                <h5>Therapy Group name</h5>
                <Field
                  name='name'
                  className='w-100 m-b-xs'
                  component='input'
                  required={true}
                  type='input'
                  placeholder='Therapy group name'
                />
                {isMobile && <ErrorField name='name' />}
              </div>

              <div className='field w-49 m-b-xs'>
                <h5>Post expiration date</h5>
                <p className='description'>When should this therapy group posting expire?</p>
                <Field
                  name='expired_at'
                  className='w-100 m-b-xs'
                  component='input'
                  required={true}
                  type='date'
                  placeholder='Select Date'
                  disabled={props.values.status === 'inactive'}
                />
                {isMobile && <ErrorField name='expired_at' />}
              </div>
              {!isMobile && (
                <DoubleErrorContainer left='name' right='expired_at' errors={props.errors} />
              )}

              <div className='field w-49 m-b-xs'>
                <h5>Time</h5>
                <p className='description'>When group takes place, i.e. Thursdays 7:00pm-9:00pm</p>
                <Field
                  name='hours'
                  className='w-100 m-b-xs'
                  component='input'
                  required={true}
                  type='input'
                  placeholder='Times when groups are offered'
                />
                {isMobile && <ErrorField name='hours' />}
              </div>
              <div className='field w-49 m-b-xs'>
                <h5>Cost</h5>
                <Field
                  name='costs'
                  className='w-100 m-b-xs'
                  component='input'
                  required={true}
                  type='input'
                  placeholder='Therapy group fee or insurances accepted'
                />
                {isMobile && <ErrorField name='costs' />}
              </div>
              {!isMobile && (
                <DoubleErrorContainer left='hours' right='costs' errors={props.errors} />
              )}
              <hr></hr>
              <EitherOrToggle
                name='remote_location'
                options={[
                  {
                    value: 'inperson',
                    displayName: 'In-person',
                  },
                  {
                    value: 'online',
                    displayName: 'Online',
                  },
                ]}
              />
              <AddressFields
                isOnline={props.values.remote_location === 'online'}
                stateText={'State in which group is offered'}
              />
              <div className='field w-100 m-b-xs'>
                <h5>Short description</h5>
                <Field
                  name='short_description'
                  className='w-100 m-b-xs'
                  component='textarea'
                  required={true}
                  type='textarea'
                  placeholder='Add a 2-3 sentence summary of your therapy group. Who is it for, and what will they get out of it?'
                />
                <ErrorField name='short_description' />
              </div>
              <div className='field w-100 m-b-xs'>
                <h5>Full description</h5>
                <Field
                  name='description'
                  className='w-100 m-b-xs'
                  component='textarea'
                  required={true}
                  type='textarea'
                  placeholder='Add a 2-3 paragraph description of your therapy group. Consider who can attend, what happens in a group sessions, any intake information or prerequisites for joining, and what makes you uniquely qualified to lead.'
                />
                <ErrorField name='description' />
              </div>
              <div className='flex column image-container m-b-sm'>
                {_.get(props, 'values.image.key') && (
                  <div className='m-b-sm'>
                    <img
                      alt={props.val}
                      src={imgix(props.values.image, {
                        maxH: 300,
                        maxW: 600,
                      })}
                    />
                  </div>
                )}
                <div className='w-100 flex justify-around'>
                  {galleryImages && galleryImages.length > 0 && (
                    <button
                      className='pill primary hollow m-r-sm'
                      onClick={() => setImagesOpen(true)}
                    >
                      {_.get(props, 'values.image.handle') ? 'Change image' : 'Add image'}
                    </button>
                  )}

                  <PhotoUploader
                    providerId={provider.id}
                    logEvent={logEvent}
                    preventGrayscale={true}
                    minSizeInKB={100}
                    onSuccess={(image) => props.form.mutators.addImage('image', image)}
                  />
                </div>
              </div>

              <ProfileSelectModal
                open={imagesOpen}
                toggle={setImagesOpen}
                onSubmit={(values) => {
                  props.form.mutators.addImage(
                    'image',
                    galleryImages.find((i) => i.handle === values.handle)
                  );
                  setImagesOpen(false);
                }}
                images={galleryImages}
                currentImage={props.values.image || {}}
              />
              <Field name='image' render={() => <></>} />
              <ErrorField name='image' />
              {therapyGroup.id && deleteGroup && (
                <div className='w-100'>
                  <div
                    className='m-b-sm text-secondary delete'
                    role='presentation'
                    onClick={() =>
                      new Promise((resolve, reject) => {
                        window.confirm(
                          'Are you sure you want to delete this therapy group? This action is not reversible!'
                        )
                          ? resolve()
                          : reject();
                      }).then(() => {
                        deleteGroup();
                      })
                    }
                  >
                    <i className='far fa-minus-circle'></i> Delete this therapy group
                  </div>
                </div>
              )}

              <div className='w-100'>
                <SubmitButton {...props} />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};
