import React from 'react';
import PracticeManagement from './PracticeManagement';

interface PracticeManagementPostMessageTestProps {}

const PracticeManagementPostMessageTest = (props: PracticeManagementPostMessageTestProps) => (
  <PracticeManagement
    iframeFileNameOverride={'index-dev-test-immediately-sends-postMessage.html'}
  />
);

export default PracticeManagementPostMessageTest;
