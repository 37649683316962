// TODO fix all this garbage
import React from 'react';
import ProductDescription from './ProductDescription';
import constants, { ProductDescriptionType, SetupOptionType, products } from './constants';

interface Props {
  onlySetupOptions?: SetupOptionType;
  setShowSetupOptionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentModalSetupOption: React.Dispatch<React.SetStateAction<ProductDescriptionType | null>>;
  lookupKeys: Array<string | null>;
  tier: 'basic' | 'professional' | 'premium';
  upgradingPracticeManagement?: boolean;
  reimbursifyTier: null | 'premium' | 'professional';
  upgradeReimbursify: boolean;
}

function MembershipBox({
  setShowSetupOptionModal,
  setCurrentModalSetupOption,
  onlySetupOptions,
  lookupKeys,
  tier,
  upgradingPracticeManagement,
  reimbursifyTier,
  upgradeReimbursify,
}: Props) {
  // TODO should be a higher level boolean view account-details
  const hasPracticeManagement = tier === 'premium' || tier === 'professional';
  const hasReimbursify = reimbursifyTier === 'premium' || reimbursifyTier === 'professional';
  return (
    <div className='payment-form-membership-box box'>
      <div className='flex'>
        <h5 className='m-y-0 payment-form-product-title'>
          Zencare Membership
          <ProductDescription
            onlyIcon={true}
            setShowModal={setShowSetupOptionModal}
            setCurrentModalSetupOption={setCurrentModalSetupOption}
            option={constants.product_descriptions.zencare_membership}
          />
        </h5>
      </div>
      {lookupKeys.map((lookupKey) => {
        if (lookupKey && constants.plan_items[lookupKey]) {
          const { text, price, recurring } = constants.plan_items[lookupKey];
          return (
            <div key={`key_${lookupKey}`} className='payment-form-product'>
              <p className='payment-form-product-description m-y-0'>{text}</p>
              <p className='price'>
                ${price}
                <span>{recurring ? ' / Month' : ' Once'}</span>
              </p>
            </div>
          );
        }
      })}
      {hasPracticeManagement && (
        <>
          <hr />
          <div className='flex justify-between'>
            <div>
              <h5 className='payment-form-product-title'>
                {tier === 'professional' &&
                  !upgradingPracticeManagement &&
                  'Zencare Practice Management Pro'}
                {(tier === 'premium' || upgradingPracticeManagement) &&
                  'Zencare Practice Management Premium'}
                <ProductDescription
                  onlyIcon={true}
                  setShowModal={setShowSetupOptionModal}
                  setCurrentModalSetupOption={setCurrentModalSetupOption}
                  option={constants.product_descriptions.zencare_practice_management}
                />
              </h5>
              <p className='payment-form-product-description'>
                An EHR to streamline appointments, billing, notes, telehealth, and more.{' '}
                <a
                  href='https://therapist.zencare.co/practice-management-feature-comparison'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Compare plans
                </a>
              </p>
            </div>
            <p className='price'>
              {tier === 'professional' &&
                !upgradingPracticeManagement &&
                `$${products.PROFESSIONAL.PRICE.PRACTICE_MANAGEMENT}`}
              {(tier === 'premium' || upgradingPracticeManagement) &&
                `$${products.PREMIUM.PRICE.PRACTICE_MANAGEMENT}`}
              <span> /Month</span>
            </p>
          </div>
        </>
      )}
      {hasReimbursify && (
        <>
          <hr />
          <div className='flex justify-between'>
            <div>
              <h5 className='payment-form-product-title'>
                {reimbursifyTier === 'professional' &&
                  !upgradeReimbursify &&
                  'Private Pay Booster Pro'}
                {(reimbursifyTier === 'premium' || upgradeReimbursify) &&
                  'Private Pay Booster Premium'}
                <ProductDescription
                  onlyIcon={true}
                  setShowModal={setShowSetupOptionModal}
                  setCurrentModalSetupOption={setCurrentModalSetupOption}
                  option={constants.product_descriptions.reimbursify}
                />
              </h5>
              <p className='payment-form-product-description'>
                Instantly verify existing or prospective clients' out-of-network benefits.{' '}
                <a
                  href='https://therapist.zencare.co/private-pay-booster-feature-comparison'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Compare plans
                </a>
              </p>
            </div>
            <p className='price'>
              {reimbursifyTier === 'professional' &&
                !upgradeReimbursify &&
                `$${products.REIMBURSIFY_PRO.PRICE}`}
              {(reimbursifyTier === 'premium' || upgradeReimbursify) &&
                `$${products.REIMBURSIFY_PREMIUM.PRICE}`}
              <span> /Month</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default MembershipBox;
