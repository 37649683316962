import React from 'react';
import { Form } from 'react-final-form';
import BillingAddressInputs from '~/shared/forms/BillingAddressInputs';
import { validateBillingAddress } from '~/utils/validateBillingAddress';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@/payment/sagaSlice';

interface FormValues {
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_postal_code: string;
}

const BillingAddress: React.FC = () => {
  const dispatch = useDispatch();
  const { customer, provider } = useSelector<State, MainState>((state) => state.main);

  if (!customer || !customer.length || !provider) return null;

  const customerObject = customer[0];
  const { account_id } = provider;

  const initialValues = {
    address_city: customerObject.address_city,
    address_line1: customerObject.address_line1,
    address_line2: customerObject.address_line2,
    address_postal_code: customerObject.address_postal_code,
    address_state: customerObject.address_state,
    // Note: No field for address country exists at this time
  };

  const onSubmit = (values: FormValues) => {
    dispatch(
      // @ts-ignore
      actions.updateAddress({
        params: { account_id },
        apiPayload: {
          ...values,
        },
      })
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateBillingAddress}
      render={({ handleSubmit, form, submitting, pristine, errors, submitFailed }) => (
        <form className='billing-address-form box' onSubmit={handleSubmit}>
          <h5>Billing Address</h5>
          <BillingAddressInputs />
          <div className='w-100 flex justify-end'>
            <button className='primary' disabled={submitting || pristine}>
              Save
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default BillingAddress;
