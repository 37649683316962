import React from 'react';
import { availabilityTabOptions } from '../provider/profile/AvailabilityLocations/constants';

export default ({ acceptingPatients, setAcceptingPatients, propertyName, where, disabled }) => (
  <>
    <h5 className='w-100'>Are you accepting new clients {where}?</h5>
    <div className={`tab-select m-b-sm ${disabled ? 'disabled' : ''}`}>
      {Object.entries(availabilityTabOptions).map(([key, val], j) => (
        <div key={j} className='container'>
          <input
            type='radio'
            disabled={disabled}
            component='input'
            id={`${propertyName}_accepting_${val}`}
            value={val}
            checked={acceptingPatients === val}
            name={`${propertyName}_accepting_${val}`}
            onChange={setAcceptingPatients && setAcceptingPatients.bind(null, val)}
          />
          <label className='flex-center' htmlFor={`${propertyName}_accepting_${val}`}>
            {key}
          </label>
        </div>
      ))}
    </div>
  </>
);
