// @ts-nocheck
// $TSFixMe

import React from 'react';
import PureModal from '+/Modal';
import SubscribeToPlanSeatBased from '~/components/payment/seat-based/SubscribeToPlanSeatBased';

interface StartSeatBasedMembershipModalProps {
  open: boolean;
  toggle: Function;
  onSubscribed: Function;
  provider: $TSFixMe;
}

const StartSeatBasedMembershipModal = ({
  open,
  toggle,
  onSubscribed,
  provider,
}: StartSeatBasedMembershipModalProps) => (
  // eslint-disable-next-line implicit-arrow-linebreak
  <PureModal visible={open} additionalCssClasses='payment-modal' closeModal={() => toggle(false)}>
    <div className='m-b-lg' key='subscription-form'>
      <SubscribeToPlanSeatBased
        provider={provider}
        onSubscribed={onSubscribed}
        showDoThisLater={false}
        title='Start Your Membership'
      />
    </div>
  </PureModal>
);

export default StartSeatBasedMembershipModal;
