import React, { useContext } from 'react';
import StripeContext from '../../stripe';
import { Elements } from '@stripe/react-stripe-js';

const wrapStripeElements = (Component: $TSFixMe) => (props: $TSFixMe) => {
  const stripeContext = useContext(StripeContext);

  return (
    // @ts-ignore: $TSFixMe
    <Elements stripe={stripeContext}>
      <Component {...props} />
    </Elements>
  );
};

export default wrapStripeElements;
