import React from 'react';
import _ from 'lodash';
import Voca from 'voca';
import { connect } from 'react-redux';
import { actions as paymentActions } from '@/payment/sagaSlice';
import { Form, Field } from 'react-final-form';
import { getCardFromSource } from './helpers';
import { CardElement } from '@stripe/react-stripe-js';
import useStripeCardElement from '+/hooks/useStripeCardElement';
import SubmitButton from '+/forms/SubmitButton';
import Loader from '+/forms/SectionLoader';

const UpdatePaymentSection = ({ customer, source, setUpdating, updateSource, loading }) => {
  const { stripe, elements, cardElRef, cardComplete, cardError, setCardError } =
    useStripeCardElement();

  if (!source) {
    return null;
  }

  const otherSources = customer.sources.filter((s) => s.id !== source.id);
  const NEW_SOURCE_SENTINEL = 'newsource';

  const sourceOptions = otherSources.map((s) => {
    const card = getCardFromSource(s) || {};

    return (
      <div className='flex align-baseline' key={s.id}>
        <Field name='source' component='input' type='radio' value={s.id} id={`source${s.id}`} />
        <label className='m-b-sm' htmlFor={`source${s.id}`}>
          {Voca.capitalize(card.brand)} ····{card.last4} (Exp {card.exp_month}/{card.exp_year})
        </label>
      </div>
    );
  });

  const onSubmit = async (values) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    let newSource;
    let sourcePromise;

    if (values.source === NEW_SOURCE_SENTINEL) {
      if (!cardComplete) {
        return;
      }

      sourcePromise = () =>
        stripe.createSource(cardElement, {
          type: 'card',
          currency: 'usd',
          owner: {
            name: _.get(source, 'name'),
          },
        });
    } else {
      newSource = customer.sources.find((s) => s.id === values.source);
    }

    return updateSource({
      sourcePromise: sourcePromise,
      onUpdated: () => setUpdating(false),
      oldSource: source,
      newSource,
      customer_id: customer.id,
      onError: (e) => setCardError(e),
    });
  };

  return (
    <div className='payment-section m-b-sm'>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, valid, values }) => (
          <>
            <div className='m-t-sm' ref={cardElRef}>
              <h6>Choose a new payment method for these subscriptions:</h6>
              {sourceOptions}
              <Field
                name='source'
                component='input'
                type='radio'
                value={NEW_SOURCE_SENTINEL}
                id={NEW_SOURCE_SENTINEL}
              />
              <label className='m-b-sm' htmlFor={NEW_SOURCE_SENTINEL}>
                Use a new card
              </label>

              <div className='flex column m-b-sm'>
                <div
                  className={`card-input m-b-xs ${
                    values.source !== NEW_SOURCE_SENTINEL && 'disabled'
                  }`}
                >
                  <CardElement
                    options={{
                      disabled: values.source !== NEW_SOURCE_SENTINEL,
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#dce0e0',
                          },
                        },
                        invalid: {
                          color: '#F2474E',
                        },
                      },
                    }}
                  />
                </div>
                {cardError && values.source === NEW_SOURCE_SENTINEL && (
                  <span className='text-error'>{cardError.message}</span>
                )}
                {values.source === NEW_SOURCE_SENTINEL && (
                  <p className='text-error'>
                    <span className='bold'>Reminder:</span> Make sure to update your billing address
                    below!
                  </p>
                )}
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <SubmitButton
                pristine={pristine}
                valid={
                  valid && (values.source !== NEW_SOURCE_SENTINEL || (!cardError && cardComplete))
                }
                handleSubmit={handleSubmit}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

export default connect(
  ({ payment }) => ({
    loading: payment.isLoading,
  }),
  { updateSource: paymentActions.updateSource }
)(UpdatePaymentSection);
