/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions as mainActions } from '~/components/main/sagaSlice';
import _ from 'lodash';
import EditBox from '+/EditBox';
import PronounForm from './PronounForm';
import PlainTextArea from '+/forms/PlainTextArea';
import { regex as TextAreaRegex } from '../MessageStatement/constants';
import { getPlainText } from '../MessageStatement/helpers';
import ProviderLicenses from './ProviderLicenses';
import AboutYourPracticeForm from './AboutYourPracticeForm';

// These are 'behind the scenes' tags that providers should not be able to
// add or remove themselves.
const ignoredProfessionTags = [
  'Therapist',
  'Medication prescriber',
  'Dietitian',
  'Group practice',
  'Treatment center',
];

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {
  updateProvider: mainActions.updateProvider,
  contactZencare: mainActions.contactZencare,
};

export const GeneralInformation = ({ provider, updateProvider, contactZencare }) => {
  const [editingPronouns, setEditingPronouns] = useState(false);

  const [editingTagline, setEditingTagline] = useState(false);
  const [editingAboutYourPractice, setEditingAboutYourPractice] = useState(false);

  const tagline = _.get(provider, 'custom_content.tagline');
  const pronouns = _.get(provider, 'custom_content.pronouns');

  const ContactSection = () => (
    <>
      <EditBox
        boxClassName='no-box'
        headerClassName='m-b-0'
        title='Contact Information'
        description='This is where emails and text messages regarding Zencare client referrals are sent. This information is not shared with clients.'
        editable={false}
        innerStatic={
          <div className='general-info-flex'>
            <div>
              <h5 className='m-y-xs'>Email address</h5>
              <p>{provider.custom_content.email}</p>
            </div>
            <div>
              <h5 className='m-y-xs'>Phone number</h5>
              <p>{provider.phone_number}</p>
            </div>
          </div>
        }
      />
      <div className='announcement m-b-sm'>
        <p>
          To make any changes to the above, please{' '}
          <a href='/contact' target='_blank' className='contact'>
            contact the Zencare team.
          </a>
        </p>
      </div>
    </>
  );

  const currentProfessions = _.get(provider, 'professions', []).filter(
    (x) => !ignoredProfessionTags.includes(x.name.trim())
  );
  const currentDegrees = _.get(provider, 'degrees', []);
  const currentIdentities = _.get(provider, 'identities', []);
  const currentLanguages = _.get(provider, 'languages', []);
  const currentGenders = _.get(provider, 'genders', []);

  return (
    <div>
      <h1 className='m-b-md'>General Information</h1>
      {provider.type === 'individual' ? (
        <EditBox
          title='Basic Information'
          editable={false}
          innerStatic={
            <>
              <div className='general-info-flex'>
                <div>
                  <h5 className='m-y-xs'>First name</h5>
                  <p>{provider.first_name}</p>
                </div>
                <div>
                  <h5 className='m-y-xs'>Middle name</h5>
                  <p>{provider.middle_name}</p>
                </div>
                <div>
                  <h5 className='m-y-xs'>Last name</h5>
                  <p>{provider.last_name}</p>
                </div>
              </div>
              <hr></hr>
              <ContactSection />
            </>
          }
        />
      ) : (
        <EditBox
          title='Basic Information'
          editable={false}
          innerStatic={
            <>
              <div className='general-info-flex' style={{ gridTemplateColumns: '1fr' }}>
                <div>
                  <h5 className='m-y-xs'>Practice Name</h5>
                  <p>{provider.company_name}</p>
                </div>
              </div>
              <hr></hr>
              <ContactSection />
            </>
          }
        />
      )}
      {provider.type === 'practice' && (
        <EditBox
          title='Practice Tagline'
          description='Your tagline will be displayed under your practice name on your profile.'
          boxClassName='w-100'
          editable={true}
          editing={editingTagline}
          setEditing={setEditingTagline}
          innerStatic={tagline && <p>{getPlainText(tagline)}</p>}
          innerEditing={
            <PlainTextArea
              name='tagline'
              placeholder='Write your tagline here.'
              convertToHTML={false}
              validate={(values) => {
                const errors = {};
                if (values.tagline && values.tagline.length > 75) {
                  errors.tagline = 'Tagline must be 75 characters or less.';
                }

                if (
                  TextAreaRegex.phone.test(values.tagline) ||
                  TextAreaRegex.email.test(values.tagline) ||
                  TextAreaRegex.url.test(values.tagline)
                ) {
                  errors.tagline =
                    'Please do not include URLs, phone numbers, or email addresses in your tagline!';
                }

                return errors;
              }}
              initText={tagline}
              submit={(tagline) => {
                updateProvider({
                  id: provider.id,
                  custom_content: {
                    tagline,
                  },
                });
                setEditingTagline(false);
              }}
            />
          }
        />
      )}
      <div className='flex-between'>
        <EditBox
          boxClassName='w-100'
          title='About Your Practice'
          editable={true}
          setEditing={setEditingAboutYourPractice}
          editing={editingAboutYourPractice}
          innerStatic={
            <div className='flex row justify-between wrap tab-container'>
              <div className='w-49'>
                <h5 className='m-y-xs'>Professions</h5>
                <ul>
                  {currentProfessions.map((p) => (
                    <li key={p.name}>{p.name}</li>
                  ))}
                </ul>
                <h5 className='m-y-xs'>Degrees</h5>
                <ul>
                  {currentDegrees.map((d) => (
                    <li key={d.name}>{d.name}</li>
                  ))}
                </ul>
              </div>
              <div className='w-49'>
                <h5 className='m-y-xs'>
                  {provider.type === 'individual' ? 'Identities' : "Head Clinician's Identities"}
                </h5>
                <ul>
                  {currentIdentities.map((i) => (
                    <li key={i.name}>{i.name}</li>
                  ))}
                </ul>
                <h5 className='m-y-xs'>Gender</h5>
                <ul>
                  {currentGenders.map((g) => (
                    <li key={g.name}>{g.name}</li>
                  ))}
                </ul>
                <h5 className='m-y-xs'>Languages</h5>
                <ul>
                  {currentLanguages.map((l) => (
                    <li key={l.name}>{l.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          }
          innerEditing={
            <AboutYourPracticeForm
              ignoredProfessionTags={ignoredProfessionTags}
              currentProfessions={currentProfessions}
              currentDegrees={currentDegrees}
              currentIdentities={currentIdentities}
              currentLanguages={currentLanguages}
              currentGenders={currentGenders}
              provider={provider}
              setEditingAboutYourPractice={setEditingAboutYourPractice}
            />
          }
        />
      </div>
      <div id='licensure'>{/* for links to this section */}</div>
      <ProviderLicenses />
      {provider.type === 'individual' && (
        <EditBox
          title='Pronouns'
          editable={true}
          setEditing={setEditingPronouns}
          editing={editingPronouns}
          innerEditing={
            <PronounForm
              pronouns={pronouns}
              updateProvider={updateProvider}
              provider={provider}
              setEditingPronouns={setEditingPronouns}
            />
          }
          innerStatic={
            pronouns ? (
              <p>{pronouns}</p>
            ) : (
              <p className='instruction'>
                Click "Edit" to share your pronouns with prospective clients on your profile.
              </p>
            )
          }
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, actions)(GeneralInformation);
