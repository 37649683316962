import React from 'react';

export const ListServ = () => (
  <div id='static-page' className='main-content'>
    <h3 className='title text-center m-b-lg'>
      Referrals, sublets, events – oh my! The benefits of Zencare listservs
    </h3>
    <p className='m-b-md'>
      Every Zencare therapist can opt into our mailing list to receive and send updates from other
      clinicians in their local area.
    </p>

    <img
      src='https://images.squarespace-cdn.com/content/v1/563a0909e4b03923f8ec4b09/1543443105449-W6ETZYFXV5RYXOHJ80Y9/ke17ZwdGBToddI8pDm48kDHPSfPanjkWqhH6pl6g5ph7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0mwONMR1ELp49Lyc52iWr5dNb1QJw9casjKdtTg1_-y4jz4ptJBmI9gQmbjSQnNGng/zencare-listserv.jpg'
      alt='zencare-listserv.jpg'
      style={{
        width: '100%',
        objectFit: 'cover',
        maxHeight: '600px',
        objectPosition: 'center',
        marginBottom: '24px',
      }}
    />

    <div>
      <div className='subsection m-b-md'>
        <h4 className='subtitle m-b-sm'>What is the Zencare listserv?</h4>

        <p className='m-b-md'>
          When you send an email to the listserv, every member of the list will receive the email
          from your email address. Our team moderates the listservs, so you will receive a
          confirmation upon your message being sent out. You can toggle your&nbsp;settings to
          receive emails immediately, or as one daily digest.
        </p>
      </div>

      <div className='subsection m-b-md'>
        <h4 className='subtitle m-b-sm'>What can I use the Zencare listserv for?</h4>

        <p className='m-b-md'>Many Zencare clinicians look to their peers for:</p>

        <ul>
          <li>Referrals and local service recommendations</li>
          <li>Supervisors, supervisees, and peer groups</li>
          <li>Office sublets, hiring for a group practice</li>
          <li>Conference announcements and requests for speakers</li>
          <li>New practice announcements</li>
        </ul>
      </div>

      <div className='subsection m-b-md'>
        <h4 className='subtitle m-b-sm'>
          Can I use the ZC listserv to find clinicians for client referrals?&nbsp;&nbsp;
        </h4>

        <p className='m-b-md'>
          Yes, but please be mindful of client privacy!&nbsp;Share as little information as possible
          to make a good match, and don’t include any identifiers.&nbsp;
        </p>
      </div>

      <div className='subsection m-b-md'>
        <h4 className='subtitle m-b-sm'>Can I promote my therapy groups and practice?</h4>

        <p className='m-b-md'>
          Absolutely! If you’ve opened a new practice or are looking for more clients to join a
          therapy group, you’re welcome to share that with the rest of the Zencare network.
        </p>
      </div>

      <div className='subsection m-b-md'>
        <h4 className='subtitle m-b-sm'>Do I have to pay extra for the listserv ?</h4>

        <p className='m-b-md'>
          Nope – the listserv is included with your Zencare membership.&nbsp;Happy
          e-connecting!&nbsp;
        </p>
      </div>
    </div>
  </div>
);
