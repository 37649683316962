import _ from 'lodash';
import stateAbbrDict from '~/utils/stateAbbrDict';

export const licenseFilledIn = (values) => {
  if (!values.state) {
    return false;
  }

  if (
    values[`license_number_${values.state}`] &&
    values[`license_type_${values.state}`] &&
    values[`license_expiration_${values.state}`]
  ) {
    return true;
  }

  return false;
};

export const initializeLicensePrefill = (licenses, initialValues) => {
  // Prefill information from all provider's licenses so that form is ready regardless of state chosen.
  // Only prefill license with status of 'Verified', 'Unverified', or 'Legacy'. Don't prefill any 'Not found' ones.
  // If there is more than one license for the state being added, prefill a 'Verified' one over any 'Unverified' one.
  const verifiedLicenses = licenses.filter((x) => x.verification === 'Verified');
  if (verifiedLicenses.length) {
    prefillLicenses(verifiedLicenses, initialValues);
  }

  const legacyLicenses = licenses.filter((x) => x.verification === 'Legacy');
  if (legacyLicenses.length) {
    prefillLicenses(legacyLicenses, initialValues);
  }

  const unverifiedLicenses = licenses.filter((x) => x.verification === 'Unverified');
  if (unverifiedLicenses.length) {
    prefillLicenses(unverifiedLicenses, initialValues);
  }
};

const prefillLicenses = (licenses, initialValues) => {
  licenses.forEach((license) => {
    // Don't overwrite existing values.
    // That way verified licenses will take precedence over unverified ones.
    const lookupAbbrByFullState = _.invert(stateAbbrDict);
    const stateAbbrev = lookupAbbrByFullState[license.state];

    if (!initialValues[`license_number_${stateAbbrev}`]) {
      initialValues[`license_number_${stateAbbrev}`] = license.number;
      initialValues[`license_type_${stateAbbrev}`] = license.license_type;
      initialValues[`license_expiration_${stateAbbrev}`] = license.expiration.toString();
    }
  });
};
