import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { actions as practiceManagmentActions } from '@/practice-management/sagaSlice';
import hasOwnerScope from '#/hasOwnerScope';

interface Props {
  className?: string;
}

export const PracticeManagementCta: React.FC<Props> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const { provider, accounts, checklist } = useSelector((state: $TSFixMe) => state.main);
  const { roles } = useSelector((state: $TSFixMe) => state.auth);

  const hasPracticeManagementAccountStatusLoaded = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.practiceManagement.hasPracticeManagementAccountStatusLoaded
  );

  const hasPracticeManagementAccount = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.practiceManagement.hasPracticeManagementAccount
  );

  const firstPracticeManagementAccount = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.practiceManagement.firstPracticeManagementAccount
  );

  useEffect(() => {
    if (provider) {
      const sagaParam: ListPracticeManagementAccountsSagaParam = {
        params: {
          provider_id: provider.id,
        },
      };

      // @ts-expect-error - NOTE: Need to figure out how to get calls to actions to not have TS error.
      dispatch(practiceManagmentActions.listPracticeManagementAccounts(sagaParam));
    }
  }, [dispatch, provider]);

  // Hide Try ZPM if showing go live CTA. (We decided against this for now)
  /* if (provider && checklist) {
    const checklistIsDoneOrOnLastStep =
      checklist?.length === 0 ||
      (checklist?.length === 1 && checklist.includes('Start membership'));
    const isIncompleteOrReviewing = ['reviewing', 'incomplete'].includes(provider.status);

    const showGoLiveForm = isIncompleteOrReviewing && checklistIsDoneOrOnLastStep;

    if (showGoLiveForm) {
      return <></>;
    }
  }*/

  const practiceManagementDomainName: string | undefined =
    process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME;

  let isOwner = false;
  if (provider && accounts && roles) {
    isOwner = hasOwnerScope(accounts, roles, provider.id);
  }

  const shouldShowLogInLink =
    (hasPracticeManagementAccountStatusLoaded &&
      hasPracticeManagementAccount &&
      firstPracticeManagementAccount &&
      provider.hasPaidPracticeManagementPlan) ||
    (firstPracticeManagementAccount &&
      firstPracticeManagementAccount.trial_end &&
      dayjs().isBefore(dayjs(firstPracticeManagementAccount.trial_end)));

  return (
    <>
      {hasPracticeManagementAccountStatusLoaded && !hasPracticeManagementAccount && isOwner && (
        <Link to='/practice-management' className={className}>
          <button className='primary md pill nav-cta'>
            {provider.hasPaidPracticeManagementPlan
              ? 'Set Up Practice Management'
              : 'Try Practice Management'}
          </button>
        </Link>
      )}

      {shouldShowLogInLink && (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={`https://${firstPracticeManagementAccount.subdomain}.${practiceManagementDomainName}/`}
          className={className}
        >
          <button className='primary md pill nav-cta'>Log in to Practice Management</button>
        </a>
      )}
      {hasPracticeManagementAccountStatusLoaded &&
        hasPracticeManagementAccount &&
        firstPracticeManagementAccount &&
        !provider.hasPaidPracticeManagementPlan &&
        firstPracticeManagementAccount.trial_end &&
        dayjs().isAfter(dayjs(firstPracticeManagementAccount.trial_end)) &&
        isOwner && (
          <Link to='/plan-management' className={className}>
            <button className='primary md pill nav-cta'>Activate Practice Management</button>
          </Link>
        )}
    </>
  );
};

export default PracticeManagementCta;
