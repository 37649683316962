/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import TypeAhead from '+/TypeAhead';
import _ from 'lodash';
import { actions as tagActions } from '@/provider/taggings/sagaSlice';
import { actions as mainActions } from '../../../main/sagaSlice';
import ReactTooltip from 'react-tooltip';
import FormCheckbox from '+/forms/FormCheckbox';
import { Link } from 'react-router-dom';

export const AboutYourPracticeForm = ({
  ignoredProfessionTags,
  currentProfessions,
  currentDegrees,
  currentIdentities,
  currentLanguages,
  currentGenders,
  provider,
  updateMultipleTaggings,
  setEditingAboutYourPractice,
  readEveryProfession,
  readEveryDegree,
  readEveryLanguage,
  readEveryIdentity,
  readEveryGender,
  professions,
  degrees,
  identities,
  languages,
  genders,
}) => {
  useEffect(() => {
    readEveryLanguage();
    readEveryProfession();
    readEveryDegree();
    readEveryIdentity();
    readEveryGender();
  }, [readEveryProfession, readEveryIdentity, readEveryDegree, readEveryGender, readEveryLanguage]);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const newInitialValues = {};
    if (currentGenders && currentGenders.length) {
      currentGenders.forEach((currentGender) => {
        newInitialValues[`gender_${currentGender.id}`] = true;
      });
    }

    if (currentProfessions && currentProfessions.length) {
      newInitialValues.professions = [];
      currentProfessions.forEach((tag) => {
        const tn = tag.name.trim();
        if (!ignoredProfessionTags.includes(tn)) {
          newInitialValues.professions.push(tag.id);
        }
      });
    }

    if (currentDegrees && currentDegrees.length) {
      newInitialValues.degrees = [];
      currentDegrees.forEach((tag) => {
        newInitialValues.degrees.push(tag.id);
      });
    }

    if (currentIdentities && currentIdentities.length) {
      newInitialValues.identities = [];
      currentIdentities.forEach((tag) => {
        newInitialValues.identities.push(tag.id);
      });
    }

    if (currentLanguages && currentLanguages.length) {
      newInitialValues.languages = [];
      currentLanguages.forEach((tag) => {
        newInitialValues.languages.push(tag.id);
      });
    }

    setInitialValues(newInitialValues);
  }, [
    currentDegrees,
    currentGenders,
    currentIdentities,
    currentLanguages,
    currentProfessions,
    ignoredProfessionTags,
  ]);

  const generateGenderUpdatesList = (values) => {
    const updates = [];
    const oldTrueTags = Object.keys(initialValues);
    const nowValueIndexes = Object.keys(values);
    nowValueIndexes
      .filter((x) => _.startsWith(x, 'gender_'))
      .forEach((newValueIndex) => {
        const tagId = parseInt(newValueIndex.replace('gender_', ''));
        // Add new tag only if if needed
        if (!oldTrueTags.includes(newValueIndex) && values[newValueIndex]) {
          updates.push({
            tagTypePlural: 'genders',
            tagId: tagId,
            associate: true,
          });
        }

        // Remove tag only if needed
        if (oldTrueTags.includes(newValueIndex) && !values[newValueIndex]) {
          updates.push({
            tagTypePlural: 'genders',
            tagId: tagId,
            associate: false,
          });
        }
      });

    return updates;
  };

  const generateTagUpdatesList = (nowValues, initialTagValues, tagTypePlural) => {
    const updates = [];
    let newTags = null;
    if (initialTagValues && initialTagValues.length) {
      newTags = nowValues.filter((x) => !initialTagValues.includes(x));
      const removedTags = initialTagValues.filter((x) => !nowValues.includes(x));

      removedTags.forEach((tagId) => {
        updates.push({
          tagTypePlural: tagTypePlural,
          tagId: tagId,
          associate: false,
        });
      });
    } else {
      newTags = nowValues;
    }

    if (newTags && newTags.length) {
      newTags.forEach((tagId) => {
        updates.push({
          tagTypePlural: tagTypePlural,
          tagId: tagId,
          associate: true,
        });
      });
    }

    return updates;
  };

  const updateYourPracticeInfo = (values) => {
    let updates = [];

    const categoriesOfUpdates = ['professions', 'degrees', 'identities', 'languages'];

    categoriesOfUpdates.forEach((tagNamePlural) => {
      const tagOperations = generateTagUpdatesList(
        values[tagNamePlural],
        initialValues[tagNamePlural],
        tagNamePlural
      );
      updates = _.concat(updates, tagOperations);
    });

    const genderUpdates = generateGenderUpdatesList(values);
    updates = _.concat(updates, genderUpdates);

    updateMultipleTaggings({
      providerId: provider.id,
      entries: updates,
    });

    setEditingAboutYourPractice(false);
  };

  const professionData = Array.isArray(professions.data)
    ? _.orderBy(professions.data, 'name', 'asc').reduce((acc, cur) => {
        const tn = cur.name.trim();
        if (!ignoredProfessionTags.includes(tn)) {
          acc.push(cur);
        }

        return acc;
      }, [])
    : [];

  const languageData = Array.isArray(languages.data)
    ? _.orderBy(languages.data, 'name', 'asc')
    : [];
  const degreeData = Array.isArray(degrees.data)
    ? _.orderBy(
        degrees.data.filter((d) => !d.name.includes('Life')),
        'name',
        'asc'
      )
    : [];
  const identityData = Array.isArray(identities.data)
    ? _.orderBy(identities.data, 'name', 'asc')
    : [];
  const genderData = Array.isArray(genders.data) ? _.orderBy(genders.data, 'name', 'asc') : [];

  const isPractice = provider.type === 'practice';
  const isIndividual = provider.type === 'individual';

  return (
    <Form
      onSubmit={updateYourPracticeInfo}
      mutators={{
        setField: ([name, val], state, { changeValue }) => {
          changeValue(state, name, () => val);
        },
      }}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        const validationErrorParagraphs = [];
        const requiredFields = ['professions', 'languages'];

        if (isIndividual) {
          requiredFields.push('degrees');
        }

        requiredFields.forEach((f) => {
          if (!values[f] || !values[f].length) {
            errors[f] = `Please select one or more ${f}.`;
            validationErrorParagraphs.push(<p className='text-error'>{errors[f]}</p>);
          }
        });

        if (validationErrorParagraphs.length > 0) {
          errors.validationErrorParagraphs = validationErrorParagraphs;
        }

        return errors;
      }}
      render={(props) => (
        <>
          <div>
            <div className='field w-100 m-b-md'>
              <h5 className='m-b-0'>Professions</h5>
              <p className='description'>Select all professions that apply to you</p>
              <TypeAhead
                field='name'
                selectSingle={false}
                criteria={() => true}
                items={professionData}
                selected={props.values.professions || []}
                onSelect={props.form.mutators.setField.bind(null, 'professions')}
                placeholder='Select professions'
              />
              <ErrorField name='professions' />
            </div>
            <div className='field w-100 m-b-md'>
              <h5 className='m-b-0'>Degrees</h5>
              <p className='description'>Select all degrees that you have earned.</p>
              <TypeAhead
                field='name'
                selectSingle={false}
                criteria={() => true}
                items={degreeData}
                // selected={currentDegrees.map((x) => x.id)}
                selected={props.values.degrees || []}
                onSelect={props.form.mutators.setField.bind(null, 'degrees')}
                placeholder='Select degrees'
              />
              <ErrorField name='degrees' />
            </div>
            <div className='field w-100 m-b-md'>
              <h5 className='m-b-0'>Languages</h5>
              <p className='description'>Select all languages that you offer sessions in.</p>
              <TypeAhead
                field='name'
                selectSingle={false}
                criteria={() => true}
                items={languageData}
                selected={props.values.languages || []}
                onSelect={props.form.mutators.setField.bind(null, 'languages')}
                placeholder='Select languages'
              />
              <ErrorField name='languages' />
            </div>

            <div className='field w-100 m-b-md'>
              {isIndividual && (
                <>
                  <span className='flex align-center m-b-0'>
                    <h5 className='m-b-0'>What identities do you openly identify with?</h5>
                    <i
                      className='fas fa-info-circle m-l-xs text-tertiary'
                      data-for='identity-tooltip'
                      data-tip={
                        'For LGBTQ folks and people of color, provider identity is one of the top criteria in searching for a therapist. Please only indicate what you feel comfortable sharing.'
                      }
                    ></i>
                  </span>
                  <p className='description'>
                    Please indicate your identities, not those of clients you see.
                  </p>
                </>
              )}
              {isPractice && (
                <>
                  <span className='flex align-center m-b-0'>
                    <h5 className='m-b-0'>
                      What identities does your head clinician openly identify with?
                    </h5>
                    <i
                      className='fas fa-info-circle m-l-xs text-tertiary'
                      data-for='identity-tooltip'
                      data-tip={
                        'For LGBTQ folks and people of color, provider identity is one of the top criteria in searching for a therapist. Please only indicate what you feel comfortable sharing.'
                      }
                    ></i>
                  </span>
                  <p className='description'>
                    Please indicate your head clinician's identities, not those of clients you see
                    or of other clinicians in your practice.
                  </p>
                </>
              )}

              <TypeAhead
                field='name'
                selectSingle={false}
                criteria={() => true}
                items={identityData}
                selected={props.values.identities || []}
                onSelect={props.form.mutators.setField.bind(null, 'identities')}
                placeholder='Select identities'
              />
              <ErrorField name='identities' />
              <p className='m-t-xs italic gray'>
                Don’t see your identity listed?{' '}
                <a href='/contact' target='_blank' rel='noopener noreferrer'>
                  Send us a suggestion!
                </a>{' '}
                Note that we might not be able to add it right away.
              </p>

              <ReactTooltip
                id='identity-tooltip'
                effect='solid'
                place='top'
                type='light'
                arrowColor='#37BEC3'
              ></ReactTooltip>
            </div>

            <div className='field w-49 m-b-md'>
              {isIndividual && (
                <>
                  <h5 className='m-b-0'>Gender</h5>
                  <p className='description'>
                    Please indicate your gender identities, not those of clients you see.
                  </p>
                </>
              )}
              {isPractice && (
                <>
                  <h5 className='m-b-0'>Gender</h5>
                  <p className='description'>
                    Please indicate your head clinician's genders, not those of clients you see or
                    of other clinicians in your practice.
                  </p>
                </>
              )}

              <div className='flex column justify-between'>
                {genderData.map((g) => (
                  <Field key={g.id} name={`gender_${g.id}`}>
                    {(props) => <FormCheckbox fieldClassName='m-b-xs' {...props} label={g.name} />}
                  </Field>
                ))}
              </div>
            </div>
          </div>
          <div>
            {props.errors.validationErrorParagraphs ? props.errors.validationErrorParagraphs : ''}
          </div>
          <SubmitButton
            pristine={props.pristine}
            valid={props.valid}
            handleSubmit={props.handleSubmit}
          />
        </>
      )}
    ></Form>
  );
};

export default connect(
  ({ professions, degrees, identities, languages, genders }) => ({
    professions,
    degrees,
    identities,
    languages,
    genders,
  }),
  {
    updateMultipleTaggings: mainActions.updateMultipleTaggings,
    readEveryProfession: tagActions.professions.readEvery,
    readEveryLanguage: tagActions.languages.readEvery,
    readEveryDegree: tagActions.degrees.readEvery,
    readEveryIdentity: tagActions.identities.readEvery,
    readEveryGender: tagActions.genders.readEvery,
  }
)(AboutYourPracticeForm);
