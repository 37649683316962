import React from 'react';
import { capitalize } from '#/capitalize';

const ToggleStateSwitch = (props) => {
  const { checked, location_key, label, updateFn, type, disabled, input } = props;

  const handleSubmit = (e) => {
    updateFn({ [label]: e.target.checked });
  };

  if (type === 'appointment') {
    const timesOfDay = {
      morning_availability: 'Before 10am',
      daytime_availability: '10am - 1pm',
      evening_availability: 'After 5pm',
      afternoon_availability: '1pm- 5pm',
      weekend_availability: 'Saturdays & Sundays',
    };

    return (
      <div className='checkbox' style={{ marginBottom: '1rem' }}>
        <input
          type='checkbox'
          id={location_key}
          defaultChecked={checked}
          onChange={handleSubmit}
          disabled={disabled}
        />
        <label htmlFor={location_key}>
          <span className='time-of-day'>
            {label === 'daytime_availability'
              ? 'Midday'
              : capitalize(label).replace('_availability', '')}
          </span>
        </label>
        <span className='hours-of-the-day'>({timesOfDay[label]})</span>
      </div>
    );
  }

  if (type === 'details') {
    const questions = {
      ada_accessible: 'Is this office ADA Accessible?',
      in_home: 'Do you offer in-home therapy sessions in this state?',
      remote_location: 'Do you offer remote online video sessions in this state?',
      display_address: 'Would you like this address displayed on your profile?',
    };

    return (
      <div className='item flex row'>
        <span>{questions[label]}</span>
        <div className='switch'>
          <input
            type='checkbox'
            id={location_key}
            defaultChecked={checked}
            onChange={handleSubmit}
            disabled={disabled}
          />
          <label htmlFor={location_key} className='md'>
            <span className='toggle' />
          </label>
        </div>
      </div>
    );
  }

  if (type === 'contact') {
    return (
      <>
        <input
          type='checkbox'
          id={label}
          checked={checked}
          defaultChecked={checked}
          onChange={handleSubmit}
          // disabled={!!disabled}
        />
        <label htmlFor={label}>
          <span className='toggle' />
        </label>
      </>
    );
  }

  const labelFor = typeof label === 'string' ? label.split(' ').join('') : `checkbox-${input.name}`;

  return (
    <div className='field'>
      <div className='mx-md checkbox'>
        <input
          type='checkbox'
          id={labelFor}
          defaultChecked={checked}
          onChange={handleSubmit}
          disabled={disabled}
        />
        <label className='checkbox' htmlFor={labelFor}>
          {label}
        </label>
      </div>
    </div>
  );
};

export default ToggleStateSwitch;
