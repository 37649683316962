const Regex = {
  username: /^((?!\.)([a-z]))(?!.*\.$)(?!.*?\.\.)[a-zA-Z0-9_.]+$/i,
  email: /^[a-z0-9][a-z0-9.\-_\+]*@[a-z0-9.\-_]+\.[a-z0-9]{1,}$/i,
  // Old passwords were only required to be 6 chars, so we really need
  // to only test new passwords for the 8 character password length requirement.
  new_password: /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])((?=.*?[0-9])|(?=.*?\W)).*$/,
  password: /^(?=.{6,}$)(?=.*?[a-z])(?=.*?[A-Z])((?=.*?[0-9])|(?=.*?\W)).*$/,
  uri: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  slug: /^[a-z0-9]+(?:-[a-z0-9]+)*$/i,
  // Old phone Regex -- Keeping for code review
  // phone: /\(*[2-9]\d{2}\)*(-| )*\(*\d{3}\)*(-| )*\(*\d{4}\)*/,
  phone: /^(\+1)\d{10}$/,
  // If we want to validate all country codes, use: /^(\+\d{1,2})\d{10}$/;
};

export default Regex;
