import React from 'react';

import { fieldHasError } from '#/forms';

interface Meta extends $TSFixMe {
  error?: string | string[];
  className?: string;
}

const Error = (meta: Meta): JSX.Element | null => {
  if (fieldHasError(meta)) {
    if (Array.isArray(meta.error)) {
      return (
        <div className='m-b-0 w-100'>
          {meta.error.map((error: string, index: number) => (
            <p
              key={index}
              className={`m-b-0 help text-error ${meta.className ? meta.className : ''}`}
            >
              {error}
            </p>
          ))}
        </div>
      );
    } else {
      return (
        <p className={`m-b-0 help text-error ${meta.className ? meta.className : ''}`}>
          {meta.error}
        </p>
      );
    }
  }

  return null;
};

export default Error;
