import React from 'react';
import ProviderAvatar from '../ProviderAvatar';
import ResubscribeModalSeatBased from '~/components/payment/seat-based/ResubscribeModalSeatBased';
import history from '#/history';

import getAccountData from '../../../../../utils/getAccountData';

// import { ACTIVE_SUBSCRIPTION_STATUSES, CANCELED_SUBSCRIPTION_STATUSES } from './constants';
// import { formatPrice, getSubscriptionProviders, getCardFromSource } from './helpers';
// import history from '#/history';
// import UpcomingInvoiceModal from './UpcomingInvoiceModal';
// import ResubscribeModalSeatBased from '~/components/payment/seat-based/ResubscribeModalSeatBased';

// const SubscriptionProviders = ({ providers }) => (
//   <div className='practice-provider-grid'>
//     {providers.map((p) => (
//       <div></div>
//     ))}
//   </div>
// );

function CanceledProvider({ canceledProvider, accounts }: $TSFixMe) {
  const [open, setOpen] = React.useState<boolean>(false);
  // const {
  //   seat_based,
  //   has_practice_management,
  //   tier,
  //   seat_based_stripe_customer_id,
  //   seat_based_stripe_subscription_id,
  //   seat_based_stripe_subscription_seats,
  //   legacy_subscription_count,
  //   sole_legacy_stripe_customer_id,
  //   sole_legacy_stripe_subscription_id,
  // } = getAccountData(canceledProvider, accounts);

  /**
   * This method only charges setup fees when all of the following conditions are met:
   * - User only has access to a single account
   * - The account only has a single stripe customer
   * - The stripe customer only has a single subscription
   * - That subscription has status 'incomplete_expired'
   */
  const checkForIncompleteExpired = () => {
    let chargeSetupFee = false;

    if (accounts.length === 1 && accounts[0].stripe_customers.length === 1) {
      const stripeCustomer = accounts[0].stripe_customers[0];
      if (
        stripeCustomer.stripe_subscriptions.length === 1 &&
        stripeCustomer.stripe_subscriptions[0].status === 'incomplete_expired'
      ) {
        chargeSetupFee = true;
      }
    }

    if (chargeSetupFee) {
      history.push(
        `profiles/pay-for/${canceledProvider.id}?return_to_plan_management=1&prior_subscription_was_incomplete_expired=1`
      );
    } else {
      setOpen(true);
    }
  };

  return (
    <div className='account-box m-r-sm'>
      {/* {subscription.items.data.map((i) =>
        formatSubscriptionItem(i, subscription, customer, subProviders, getUpcomingInvoice)
      )} */}
      {/* <p>Profiles covered by this membership:</p> */}
      {/* <SubscriptionProviders providers={subProviders} /> */}
      {/* {CANCELED_SUBSCRIPTION_STATUSES.includes(subscription.status) && ( */}
      <>
        <ProviderAvatar provider={canceledProvider} />
        <button
          className='primary pill md'
          onClick={() => {
            checkForIncompleteExpired();
          }}
        >
          Reactivate Seat
        </button>
        <ResubscribeModalSeatBased
          provider={canceledProvider}
          open={open}
          toggle={setOpen}
          onSubmit={() => ({})}
          //   customer={customer}
          //   subscription={subscription}
          plan={
            <div className='flex column m-b-sm'>
              {/* {subscription.items.data.map((i) => (
                  <>
                    <span>{subscriptionItemName(i)}</span>
                    <span>{formatItemPrice(i, subscription, false)}</span>
                  </>
                ))} */}
              {/* <div>
                  <span className='credit-card'>
                    {Voca.capitalize(card.brand)} ····{card.last4}
                  </span>{' '}
                  <span>
                    Expires {card.exp_month}/{card.exp_year}
                  </span>
                </div> */}
            </div>
          }
        />
      </>
      {/* )} */}
    </div>
  );
}

export default CanceledProvider;

// /* eslint-disable max-lines-per-function */
// import React, { useState } from 'react';
// import _ from 'lodash';
// import Voca from 'voca';
// import dayjs from 'dayjs';

// const formatItemPrice = (item, sub) => (
//   <>
//     <div style={{ lineHeight: '1rem' }}>
//       <div>{formatPrice(item)}</div>
//     </div>
//   </>
// );

// const subscriptionItemName = (item) => _.get(item, 'price.product.name') || 'Zencare Subscription';

// export const Subscription = ({
//   subscription,
//   customer,
//   allProviders,
//   getUpcomingInvoice,
//   upgradePlan,
// }) => {
//   const [open, setOpen] = useState(false);
//   const [viewUpcomingInvoiceOpen, setViewUpcomingInvoiceOpen] = useState(false);
//   const [upcomingInvoiceDto, setUpcomingInvoiceDto] = useState(null);
//   const card =
//     getCardFromSource(subscription.default_payment_method_object) ||
//     getCardFromSource(customer.default_source_object) ||
//     {};
//   const subProviders = getSubscriptionProviders(subscription, allProviders);

//   const openSubscriptionModal = (stripe_customer_id, stripe_subscription_id) => {
//     getUpcomingInvoice({
//       stripe_customer_id,
//       stripe_subscription_id,
//       onSuccess: (data) => {
//         setUpcomingInvoiceDto(data);
//         setViewUpcomingInvoiceOpen(true);
//       },
//       onError: (error) => {
//         alert(error.message);
//         throw new Error(
//           `Error getting upcoming invoice for stripe_customer_id ${stripe_customer_id} and stripe_subscription_id ${stripe_subscription_id}: ${error}`
//         );
//       },
//     });
//   };

//   const formatSubscriptionItem = (
//     item,
//     subscription,
//     customer,
//     subProviders,
//     getUpcomingInvoice
//   ) => {
//     const name =
//       _.get(item, 'price.product.name') ||
//       _.get(customer, 'metadata.form_title') ||
//       'Zencare Membership';

//     const statusColor = (status) => {
//       if (['incomplete', 'past_due', 'unpaid'].includes(status)) {
//         return 'quaternary';
//       }

//       if (ACTIVE_SUBSCRIPTION_STATUSES.includes(status)) {
//         return 'primary';
//       }

//       return 'secondary';
//     };

//     const statusName = (status) => {
//       if (status === 'trialing') {
//         status = 'active';
//       }

//       if (status === 'incomplete_expired') {
//         status = 'expired';
//       }

//       return Voca.capitalize(status);
//     };

//     const nextPaymentLine = () => {
//       const oneYearFromSubscriptionStart = dayjs(subscription.created).add(1, 'year');
//       const isMonthly = item.price.recurring && item.price.recurring.interval === 'month';
//       if (
//         ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) &&
//         isMonthly &&
//         dayjs().isBefore(oneYearFromSubscriptionStart)
//       ) {
//         return (
//           <p className='m-b-0'>
//             <span className='semi-bold'>Next Payment Date</span>:{' '}
//             {dayjs(subscription.current_period_end).format('MMMM D, YYYY')}
//           </p>
//         );
//       }

//       return null;
//     };

//     const subscriptionTermsAndRenewalLine = () => {
//       const oneYearFromSubscriptionStart = dayjs(subscription.created).add(1, 'year');
//       const isBeforeOneYearFromSubscriptionStart = dayjs().isBefore(oneYearFromSubscriptionStart);
//       const isMonthly = item.price.recurring && item.price.recurring.interval === 'month';
//       // Conditionally showing yearly is needed because we extend dates for yearly after profile goes live
//       const shouldShowForYearly = subProviders.find((p) => p.status === 'active');
//       // Only shown during first year for monthly subscriptions
//       if (ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status)) {
//         return (
//           <>
//             {isMonthly && isBeforeOneYearFromSubscriptionStart && (
//               <>
//                 <p>
//                   <span className='semi-bold'>Renewal Date:</span>{' '}
//                   {oneYearFromSubscriptionStart.format('MMMM D, YYYY')}
//                   <div>
//                     <button
//                       className='view-next-invoice-button'
//                       onClick={() => {
//                         openSubscriptionModal(subscription.customer_id, subscription.id);
//                       }}
//                     >
//                       View invoice
//                     </button>
//                   </div>
//                 </p>
//               </>
//             )}
//             {((isMonthly && !isBeforeOneYearFromSubscriptionStart) ||
//               (!isMonthly && shouldShowForYearly)) && (
//               <>
//                 {/* The renewal date for annual subscriptions or monthly ones after 1 year is always the next payment date */}
//                 <p>
//                   <span className='semi-bold'>Renewal Date:</span>{' '}
//                   {dayjs(subscription.current_period_end).format('MMMM D, YYYY')}
//                   <div>
//                     <button
//                       className='view-next-invoice-button'
//                       onClick={() => {
//                         openSubscriptionModal(subscription.customer_id, subscription.id);
//                       }}
//                     >
//                       View next invoice
//                     </button>
//                   </div>
//                 </p>
//               </>
//             )}
//             <p
//               style={{
//                 color: '#484848',
//                 fontSize: '0.9rem',
//                 lineHeight: '1.25rem',
//               }}
//             >
//               <span className='semi-bold'>Terms:</span> "I agree to the{' '}
//               <a
//                 rel='noopener noreferrer'
//                 target='_blank'
//                 href='https://zencare.co/policy/terms'
//                 style={{
//                   fontSize: '0.9rem',
//                   lineHeight: '1.25rem',
//                 }}
//               >
//                 Terms Of Service
//               </a>
//               . I understand that the Zencare membership is an annual commitment, that I will be
//               responsible for the remainder of the year if I cancel early, and that no refunds are
//               issued."
//             </p>
//           </>
//         );
//       }

//       return null;
//     };

//     return (
//       <div key={subscription.id}>
//         <div className='flex align-center'>
//           <h5 className='m-b-0 m-r-sm'>{name}</h5>
//           <span
//             className={`subscription-status-bubble m-r-sm text-${statusColor(subscription.status)}`}
//           >
//             {statusName(subscription.status)}
//           </span>
//           <button
//             className='primary pill md'
//             onClick={() =>
//               upgradePlan({
//                 account_id: subscription?.customer?.metadata?.account_id,
//                 provider_ids: subProviders.map((p) => p.id),
//                 provider_names: subProviders.map((p) =>
//                   p.first_name ? `${p.first_name} ${p.last_name}` : p.company_name
//                 ),
//               })
//             }
//           >
//             Add Practice Management
//           </button>
//         </div>
//         {ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) && (
//           <>
//             <hr className='m-t-sm m-b-sm' />
//             {nextPaymentLine()}
//             {subscriptionTermsAndRenewalLine()}
//             <hr className='m-t-sm m-b-sm' />
//           </>
//         )}

//         <UpcomingInvoiceModal
//           dto={upcomingInvoiceDto}
//           showModal={viewUpcomingInvoiceOpen}
//           setShowModal={setViewUpcomingInvoiceOpen}
//         />
//       </div>
//     );
//   };

//   return (
//     <div className='account-box m-r-sm'>
//       {subscription.items.data.map((i) =>
//         formatSubscriptionItem(i, subscription, customer, subProviders, getUpcomingInvoice)
//       )}
//       <p>Profiles covered by this membership:</p>
//       <SubscriptionProviders providers={subProviders} />
//       {CANCELED_SUBSCRIPTION_STATUSES.includes(subscription.status) && (
//         <>
//           <button className='primary pill md' onClick={() => setOpen(true)}>
//             Reactivate Membership
//           </button>
//           <ResubscribeModalSeatBased
//             open={open}
//             toggle={setOpen}
//             onSubmit={() => ({})}
//             customer={customer}
//             subscription={subscription}
//             plan={
//               <div className='flex column m-b-sm'>
//                 {subscription.items.data.map((i) => (
//                   <>
//                     <span>{subscriptionItemName(i)}</span>
//                     <span>{formatItemPrice(i, subscription, false)}</span>
//                   </>
//                 ))}
//                 <div>
//                   <span className='credit-card'>
//                     {Voca.capitalize(card.brand)} ····{card.last4}
//                   </span>{' '}
//                   <span>
//                     Expires {card.exp_month}/{card.exp_year}
//                   </span>
//                 </div>
//               </div>
//             }
//           />
//         </>
//       )}
//     </div>
//   );
// };

// export const IncompleteSubscription = ({ provider }) => (
//   <div>
//     <div className='flex align-center'>
//       <h5 className='m-b-0 m-r-sm'>
//         {provider.type === 'individual' ? 'Individual Provider' : 'Group Practice'} Profile
//       </h5>
//       <span className={'subscription-status-bubble text-secondary'}>Membership Not Started</span>
//     </div>
//     <ProviderAvatar provider={provider} />
//     <button
//       className='primary pill md'
//       onClick={() => {
//         if (provider.locations && provider.locations.length) {
//           history.push(`profiles/pay-for/${provider.id}?return_to_plan_management=1`);
//         } else {
//           history.push(`profiles/create/${provider.id}`);
//         }
//       }}
//     >
//       Begin Membership
//     </button>
//   </div>
// );
