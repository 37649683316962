/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Button } from '@zencare/ui';
import { actions as mainActions } from '../../../main/sagaSlice';
import _ from 'lodash';
import ErrorField from '+/forms/ErrorField';
import EditBox from '+/EditBox';
import PureModal from '+/Modal';
import { counts, regex, practiceBoxes } from './constants';
import { wordCount, getStyle, getMaxLength, getPlainText, getHTMLFromText } from './helpers';

const statementPlaceholder = `e.g. Dr. Kenneth Clark is a psychologist in Harlem, New York. He specializes in child development, race and identity issues, and racial biases in education. 
                                
Dr. Clark’s practice, Northside Center for Child Development, is the first full-time child guidance center offering psychological and casework services to families in the Harlem area. Here he utilizes therapeutic approaches such as play therapy and parent-child interaction therapy.

Dr. Clark was the First African-American tenured full professor at the City College of New York, the first African-American to be president of American Psychological Association and the first African-American appointed to the New York State Board of Regents. He and his partner Mamie obtained his bachelor's and master's degrees from Howard University and were the first African-Americans to obtain their doctoral degrees in psychology from Columbia University.`;

const messagePlaceholder =
  "e.g. It's so helpful to find someone who you can just be yourself for an hour a week, talk about all the things that are important to you, and not have to wear a brave face or feel judged...";

const HelpModal = ({ open, toggle }) => (
  <PureModal
    visible={open}
    headerContent={<h2 className='m-t-sm m-b-sm'>Professional Statement Examples</h2>}
    closeModal={() => toggle(false)}
  >
    <h5>Example One</h5>
    <p>
      Marian Cooper is a Licensed Clinical Social Worker based in Queens, NY, who works with
      individuals across the lifespan, with a focus on professional women. Many of her clients have
      found great success in their careers or academically, but struggle to feel connected in their
      relationships or at peace in their daily lives. Marian is particularly sensitive to clients
      facing moments of transition, like a move to the city, job loss, or breakup, when the delicate
      balance can seem to tremble, and symptoms of anxiety, depression, and self-doubt can emerge.
    </p>
    <p>
      Marian’s approach is rooted in Cognitive Behavioral Therapy, an evidence-based approach that
      focuses on helping clients understand how their thoughts, feelings, and behaviors are
      interconnected, so that they can begin to make targeted change. She works to help clients
      understand their physical response to external stressors, and begin to problem-solve ways to
      introduce moments of calm, to choose to respond rather than react in the face of change.
    </p>
    <p>
      Marian is focused on ensuring that her clients see real progress: her style is interactive,
      non-judgmental, and positions clients as the experts on where they are now, and where they
      want to go. A trauma-informed therapist, Marian is sensitive to the influence of past
      experiences on present challenges, and works closely with clients to reframe the narrative of
      their life in a way that increases self-confidence and compassion.
    </p>
    <p>
      Marian’s first priority in starting with a new client is ensuring that they feel safe, heard,
      and like an equal partner in the therapeutic process. As a seasoned telehealth provider, she
      offers the convenience of remote sessions to help make therapy a core component of your
      self-care calendar.
    </p>
    <h5>Example Two</h5>
    <p>
      Dr. Melba Nicholson Sullivan is a licensed clinical-community psychologist committed to
      promoting individual and collective resilience. Her clients are big thinkers, dreamers, and
      decision makers who sometimes find themselves held back by painful experiences of the past.
      Dr. Nicholson Sullivan treats old wounds with curiosity, creativity, and compassion, creating
      an opportunity for clients to move with greater freedom into their bright future.
    </p>
    <p>
      Many of Dr. Nicholson Sullivan’s clients navigate feelings of stress, anxiety, and depression
      that have become overwhelming, and impact how they show up at work and in relationships. She
      works with clients to explore core beliefs about themselves, others, and the systems they
      exist within, co-creating practical strategies tailored to each client and their life journey.
    </p>
    <p>
      Dr. Nicholson Sullivan’s therapeutic approach is rooted in mindfulness and human rights. She
      draws from her training in Eye Movement Desensitization and Reprocessing (EMDR), Mindfulness
      Based Stress Reduction (MBSR), and executive coaching strategies in order to expand clients’
      internal and external resources for healing, rest, and joy.
    </p>
    <p>
      Dr. Nicholson Sullivan is committed to maintaining a diverse, equitable, and inclusive
      practice, which serves as a safe harbor for people who identify as LGBTQIA and people of the
      global majority. In addition to her Midtown office, Dr. Nicholson Sullivan offers the
      flexibility of remote therapy sessions, and welcomes new clients to her online practice.
    </p>
    <h5>Example Three</h5>
    <p>
      Dr. Todd Kray is a Licensed Clinical Psychologist with a private practice in Manhattan’s
      Flatiron District, where he welcomes both individual adults and couples who are seeking to
      create change in their lives. Many of his clients bring in emotional pain, whether the
      symptoms of anxiety and depression are taking a daily toll, relationships feel unbalanced and
      disconnected, or stress and self-criticism are impacting performance at work. Together, he
      supports clients to pull back the layers of present challenges in order to release painful
      past experiences and re-engage with their careers, their loved ones, and themselves in more
      meaningful ways.{' '}
    </p>
    <p>
      Therapy sessions with Dr. Kray integrate both insight-oriented and skills-based approaches. He
      gently challenges clients to explore the root cause of their symptoms, gaining new awareness
      into their emotional and relationship patterns. At the same time, he focuses on practical
      strategies, with an emphasis on relaxation and meditation practices, to help clients feel
      better in the here and now.
    </p>
    <p>
      Having served as a consultant at local hospitals, Dr. Kray is uniquely skilled in working with
      those experiencing chronic pain, chronic illness, and other serious medical illnesses. He
      brings a special awareness to the healing power of the mind-body connection, and his office
      serves as a safe harbor to freely process pain and find relief.
    </p>
    <p>
      Dr. Kray is committed to maintaining an inclusive practice. With a background in the arts, he
      is particularly sensitive to the creative process, and welcomes a wide range of creative
      professionals, freelancers, executives, parents, and young adults to his practice.
    </p>
  </PureModal>
);

function TextArea({
  name,
  placeholder,
  initText,
  onSubmit,
  validate,
  suggestedWords,
  minWords,
  maxWords,
}) {
  const initialValues = {
    [name]: getPlainText(initText),
  };

  const passProps = {
    onSubmit,
    validate,
    initialValues,
  };

  const displayName = name.split('_').join(' ');

  return (
    <Form
      {...passProps}
      initialValuesEqual={() => true}
      render={({ handleSubmit, values, pristine, valid }) => (
        <>
          <Field
            name={name}
            component='textarea'
            required={true}
            type='textarea'
            placeholder={placeholder}
            maxLength={getMaxLength(values, name)}
          />
          <div className='word-count' style={getStyle(values, name)}>
            {wordCount(values[name])} / {maxWords}
          </div>
          {wordCount(values[name]) < minWords && (
            <p className='m-b-md help text-error'>
              {`We encourage you to write at least ${minWords} words for your ${displayName} 
                            to communicate your practice uniqueness with clients!`}
            </p>
          )}
          <span>
            <ErrorField name={name} />
          </span>
          <div className='actions'>
            {!pristine && !valid ? (
              // Have to do this right now because storybook button class
              // won't change when disabled is changed..
              <Button className='primary' onClick={handleSubmit} disabled>
                Submit
              </Button>
            ) : (
              <Button
                className='primary'
                onClick={() => {
                  if (
                    wordCount(values[name]) < minWords &&
                    !window.confirm(
                      `Are you sure you want to use a short ${displayName}? We encourage you to write at least ${minWords} words for your professional statement to communicate your practice uniqueness with clients!`
                    )
                  ) {
                    return;
                  }
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            )}
          </div>
        </>
      )}
    />
  );
}

function validate(values) {
  const errors = [];
  ['professional_statement', 'message_to_clients', 'answer'].forEach((key) => {
    const displayName = key.split('_').join(' ');
    const str = values[key];
    if (wordCount(str) >= counts[key].maxWords) {
      errors[
        key
      ] = `Please keep your ${displayName} under ${counts[key].maxWords} words! (Suggested count: ${counts[key].suggestedWords})`;
    }

    if (regex.phone.test(str) || regex.email.test(str) || regex.url.test(str)) {
      errors[
        key
      ] = `Please do not include URLs, phone numbers, or email addresses in your ${displayName}!`;
    }
  });

  return errors;
}

export const MessageStatement = ({ provider, updateProvider }) => {
  const [open, setOpen] = useState(false);

  // In the past / on admin, practice boxes were freeform in terms of header.
  // Try and map these boxes onto the standard boxes from ./constants.
  const ccBoxes = _.get(provider, 'custom_content.boxes', []);
  const matchedBoxes = ccBoxes
    .map(([question, answer]) => {
      const matchingBox = practiceBoxes.find((pb) => pb.existingRegex.test(question));

      if (!matchingBox) {
        return {
          title: question,
          initText: answer,
        };
      }

      return {
        title: matchingBox.title,
        description: matchingBox.description,
        initText: answer,
      };
    })
    .concat(
      practiceBoxes.reduce((acc, cur) => {
        if (!ccBoxes.find(([question, __]) => cur.existingRegex.test(question))) {
          return acc.concat({
            title: cur.title,
            description: cur.description,
            initText: '',
          });
        }

        return acc;
      }, [])
    );

  const [editingStatement, setEditingStatement] = useState(false);
  const [editingMessage, setEditingMessage] = useState(false);
  const [editingBoxes, setEditingBoxes] = useState(matchedBoxes.map((__) => false));

  const updateStatement = (form) => {
    updateProvider({
      id: provider.id,
      professional_statement: getHTMLFromText(form.professional_statement),
    });

    setEditingStatement(false);
  };

  const updateMessage = (form) => {
    updateProvider({
      id: provider.id,
      message_to_clients: getHTMLFromText(form.message_to_clients),
    });

    setEditingMessage(false);
  };

  // If any of the custom boxes matched the existing ones from constants,
  // overwrite the title with the generic title.
  const updateBox = (i, form) => {
    const boxes = matchedBoxes
      .map((b, j) => [b.title, i === j ? getHTMLFromText(form.answer) : b.initText])
      .filter((b) => b[1] && b[1].length);

    updateProvider({
      id: provider.id,
      custom_content: {
        boxes,
      },
    });
  };

  return (
    <div>
      <h1 className='m-b-md'>
        {provider.type === 'individual' ? 'Statement & Message' : 'Practice Description'}
      </h1>
      {provider.hasPremiumSetup ? (
        !provider.professional_statement && (
          <div className='announcement m-b-md'>
            <p>
              We are currently hard at work on writing your professional statement! You should see
              it drafted here within 48 business hours.
            </p>
          </div>
        )
      ) : (
        <>
          <HelpModal open={open} toggle={setOpen} />
          <div className='announcement m-b-md'>
            <p className='m-b-xs'>P.S. Struggling to know what to say? We're here to help!</p>
            <p className='m-b-xs m-r-md' style={{ 'text-decoration': 'underline' }}>
              <span
                role='presentation'
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen(true)}
                target='_blank'
              >
                View examples
              </span>
            </p>
            <p className='m-b-xs m-r-md' style={{ 'text-decoration': 'underline' }}>
              <a
                href='https://blog.zencare.co/how-to-write-therapist-professional-statement'
                target='_blank'
              >
                Read our guide to writing a great statement
              </a>
              .
            </p>
            <p className='m-b-xs' style={{ 'text-decoration': 'underline' }}>
              <Link to='/checkout/price_1Ij3SVHEu9I8ej85WkaG4UH5'>
                Request the Zencare team to write your statement.
              </Link>
            </p>
          </div>
        </>
      )}

      {provider.type === 'individual' && (
        <>
          <EditBox
            title='Professional Statement'
            editable={true}
            boxClassName='statement'
            description={
              <div className='instruction'>
                <p className='m-b-sm'>
                  Please write this in the third person. This section should be 3-5 paragraphs
                  focused on 1) Your clientele 2) Your therapy approach 3) Relevant professional
                  background information 4) Logistic details (office location, availability for
                  remote sessions, etc).
                </p>
                <p>
                  Though you’re welcome to use preexisting statements as a starting point, we
                  strongly recommend not using content that is exactly identical on other webpages,
                  since Google can identify (and may “punish,” or hide) duplicate content.
                </p>
              </div>
            }
            editing={editingStatement}
            setEditing={setEditingStatement}
            innerEditing={
              <TextArea
                name='professional_statement'
                placeholder={statementPlaceholder}
                onSubmit={updateStatement}
                initText={provider.professional_statement}
                validate={validate}
                suggestedWords={counts.professional_statement.suggestedWords}
                maxWords={counts.professional_statement.maxWords}
                minWords={counts.professional_statement.minWords}
              />
            }
            innerStatic={
              <div className='rendered-text' key={provider.professional_statement}>
                {getPlainText(provider.professional_statement)}
              </div>
            }
          />
          <EditBox
            title='Message to Clients'
            editable={true}
            boxClassName='statement'
            description={`Please write this in the first person. 
                            This section should be 1-3 paragraphs focused on you speaking 
                            directly to clients about anything they should know prior to 
                            starting sessions with you, or your hope for them.`}
            editing={editingMessage}
            setEditing={setEditingMessage}
            innerEditing={
              <TextArea
                name='message_to_clients'
                placeholder={messagePlaceholder}
                onSubmit={updateMessage}
                initText={provider.message_to_clients}
                validate={validate}
                suggestedWords={counts.message_to_clients.suggestedWords}
                maxWords={counts.message_to_clients.maxWords}
                minWords={counts.message_to_clients.minWords}
              />
            }
            innerStatic={
              <div className='rendered-text' key={provider.message_to_clients}>
                {getPlainText(provider.message_to_clients)}
              </div>
            }
          />
        </>
      )}
      {provider.type === 'practice' && (
        <>
          <EditBox
            title='Practice Introduction'
            editable={true}
            boxClassName='statement'
            description={
              <div className='instruction'>
                <p className='m-b-sm'>
                  This section should be 1-3 paragraphs providing an overview of the services your
                  practice offers, who your clinicians are, and makes your group unique .
                </p>
                <p>
                  Though you’re welcome to use preexisting statements as a starting point, we
                  strongly recommend not using content that is exactly identical on other webpages,
                  since Google can identify (and may “punish,” or hide) duplicate content.
                </p>
              </div>
            }
            editing={editingStatement}
            setEditing={setEditingStatement}
            innerEditing={
              <TextArea
                name='professional_statement'
                placeholder='Write your practice introduction here:'
                onSubmit={updateStatement}
                initText={provider.professional_statement}
                validate={validate}
                suggestedWords={counts.professional_statement.suggestedWords}
                minWords={counts.professional_statement.minWords}
                maxWords={counts.professional_statement.maxWords}
              />
            }
            innerStatic={
              <div className='rendered-text' key={provider.professional_statement}>
                {getPlainText(provider.professional_statement)}
              </div>
            }
          />
          {matchedBoxes.map((b, i) => {
            const [editing, setEditing] = [
              editingBoxes[i],
              (val) => {
                editingBoxes[i] = val;
                setEditingBoxes(_.clone(editingBoxes));
              },
            ];

            const onSubmit = (form) => {
              updateBox(i, form);
              setEditing(false);
            };

            return (
              <EditBox
                title={b.title}
                editable={true}
                boxClassName='statement'
                description={b.description}
                editing={editing}
                setEditing={setEditing}
                innerEditing={
                  <TextArea
                    name='answer'
                    placeholder='Write your answer here'
                    onSubmit={onSubmit}
                    initText={b.initText}
                    validate={validate}
                    suggestedWords={counts.answer.suggestedWords}
                    minWords={counts.answer.minWords}
                    maxWords={counts.answer.maxWords}
                  />
                }
                innerStatic={
                  <div className='rendered-text' key={b.title}>
                    {getPlainText(b.initText)}
                  </div>
                }
              />
            );
          })}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {
  updateProvider: mainActions.updateProvider,
};

export default connect(mapStateToProps, actions)(MessageStatement);
