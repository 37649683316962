import { put } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'clients',
  initialState: {
    isLoading: false,
    clientList: null,
    practiceClientList: null,
  },
  reducers: {
    getClientList: loadingReducer,
    getClientListSuccess: (state, payload) => {
      state.clientList = payload;
      state.practiceClientList = null;
      state.isLoading = false;
    },
    getClientListFail: failReducer,

    getPracticeClientList: loadingReducer,
    getPracticeClientListSuccess: (state, payload) => {
      state.practiceClientList = payload;
      state.clientList = null;
      state.isLoading = false;
    },
    getPracticeClientListFail: failReducer,
  },
  sagas: (A) => {
    const sagas = {
      [A.getClientList]: function* ({ payload }) {
        const { provider_id } = payload;
        yield sagaApi.get(
          `/portal/provider/${provider_id}/clients`,
          A.getClientListSuccess,
          A.getClientListFail
        );
      },
      [A.getPracticeClientList]: function* ({ payload }) {
        const { account_id } = payload;
        yield sagaApi.get(
          `/portal/account/${account_id}/clients`,
          A.getPracticeClientListSuccess,
          A.getPracticeClientListFail
        );
        yield put({
          type: 'practiceInbox/getConversations',
          payload: {
            account_id,
            limit: 10000,
            offset: 0,
          },
        });
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
