export const getQueryAsObject = (str) => {
  if (!str || str === '') {
    return {};
  }

  if (str[0] === '?') {
    str = str.slice(1, str.length);
  }

  try {
    const result = JSON.parse(
      `{"${decodeURI(str).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`
    );

    // Decode individual components of URI
    for (const key in result) {
      result[key] = decodeURIComponent(result[key]);
    }

    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  getQueryAsObject,
};
