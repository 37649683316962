import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

export const RecoverAccountSuccess = (props) => {
  if (!props.recoverAccountEmailValue) {
    return <Redirect to='/account/recover' />;
  }

  return (
    <>
      <p>
        The email address associated with the information you gave us is{' '}
        {props.recoverAccountEmailValue}
      </p>
      <div className='w-100 flex justify-center'>
        <a href='/'>
          <button className='primary md m-r-lg m-b-md' type='submit'>
            Log in
          </button>
        </a>
      </div>
      <Link to='/password/request'>
        <span>Reset your password</span>
      </Link>
    </>
  );
};

export default connect((state) => state.auth)(RecoverAccountSuccess);
