/* eslint-disable max-lines-per-function */
import React, { SetStateAction } from 'react';
import Modal from '+/Modal';
import { displayMoneyString } from '../../../utils/money';
import dayjs from 'dayjs';

interface Props {
  soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy: boolean;
  dto: $TSFixMe;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({
  soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy,
  dto,
  showModal,
  setShowModal,
}: Props) => {
  const getTableRow = (line: $TSFixMe, index: number, printPeriodHeader: boolean) => (
    <>
      {printPeriodHeader && line.period && (
        <tr>
          <td colSpan={2}>
            <div className='period-dates'>
              <hr />
              {dayjs.unix(line.period.start).format('MMMM D, YYYY')} -{' '}
              {dayjs.unix(line.period.end).format('MMMM D, YYYY')}
              <hr />
            </div>
          </td>
        </tr>
      )}
      <tr key={index}>
        <td>{line.description}</td>
        <td className='money-cell'>{formatMoney(line.amount)}</td>
      </tr>
    </>
  );

  const formatMoney = (cents: number) => {
    if (cents < 0) {
      // Reformat to put negative sign to left of dollar sign.
      const tempPositiveCents = cents * -1;
      const tempResult = displayMoneyString(tempPositiveCents / 100);

      return `-${tempResult}`;
    }

    return displayMoneyString(cents / 100);
  };

  const getDiscountName = (discountId: string) => {
    if (dto.discount && dto.discount.id === discountId && dto.discount.coupon) {
      return dto.discount.coupon.name;
    }

    // Unknown discount name
    return '';
  };

  let lastPeriodStart: number = 0;
  let lastPeriodEnd: number = 0;
  let printPeriodHeader = false;
  return (
    <>
      {dto && (
        <Modal
          additionalCssClasses='upcoming-invoice-modal'
          visible={showModal}
          closeModal={() => setShowModal(false)}
        >
          <>
            <h3>
              {soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy
                ? "Today's Invoice"
                : 'Upcoming Invoice'}
            </h3>
            <h5>Summary</h5>
            <table>
              <tr>
                <td className='label-cell'>Payment Date</td>
                <td>{dayjs.unix(dto.period_end).format('MMMM D, YYYY')}</td>
              </tr>
              <tr>
                <td className='label-cell'>To</td>
                <td>{dto.customer_name}</td>
              </tr>
              <tr>
                <td className='label-cell'>From</td>
                <td>{dto.account_name}</td>
              </tr>
            </table>
            <hr />
            <h5>Items</h5>
            <table className='items-table'>
              {!soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy &&
                dto.lines.data &&
                dto.lines.data[0] &&
                dto.lines.data[0].period && (
                  <tr>
                    <td colSpan={2}>
                      <div className='period-dates'>
                        <hr />
                        {dayjs.unix(dto.lines.data[0].period.start).format('MMMM D, YYYY')} -{' '}
                        {dayjs.unix(dto.lines.data[0].period.end).format('MMMM D, YYYY')}
                        <hr />
                      </div>
                    </td>
                  </tr>
                )}
              {dto.lines.data.map((line: $TSFixMe, index: number) => {
                if (lastPeriodStart === 0) {
                  lastPeriodStart = dto.lines.data[0].period.start;
                  lastPeriodEnd = dto.lines.data[0].period.end;
                }
                if (
                  dayjs.unix(lastPeriodStart).format('MMMM D, YYYY') !==
                    dayjs.unix(dto.lines.data[index].period.start).format('MMMM D, YYYY') ||
                  dayjs.unix(lastPeriodEnd).format('MMMM D, YYYY') !==
                    dayjs.unix(dto.lines.data[index].period.end).format('MMMM D, YYYY')
                ) {
                  printPeriodHeader = true;
                  lastPeriodStart = dto.lines.data[index].period.start;
                  lastPeriodEnd = dto.lines.data[index].period.end;
                } else {
                  printPeriodHeader = false;
                }
                return getTableRow(line, index, printPeriodHeader);
              })}
              <tr>
                <td>
                  <span className='semi-bold'>Subtotal</span>
                </td>
                <td className='money-cell'>{formatMoney(dto.subtotal)}</td>
              </tr>

              {dto.total_discount_amounts && dto.total_discount_amounts.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
              )}

              {dto.total_discount_amounts.map(
                (discountAmountItem: $TSFixMe, discountAmountIndex: number) => (
                  <tr className='discount-row' key={discountAmountIndex}>
                    <td>{getDiscountName(discountAmountItem.discount)}</td>

                    <td className='money-cell'>-{formatMoney(discountAmountItem.amount)}</td>
                  </tr>
                )
              )}

              {dto.starting_balance && dto.starting_balance < 0 ? (
                <>
                  <tr>
                    <td colSpan={2}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>Account Credit</td>

                    <td className='money-cell'>{formatMoney(dto.starting_balance)}</td>
                  </tr>
                </>
              ) : (
                ''
              )}

              {dto.starting_balance && dto.starting_balance > 0 ? (
                <>
                  <tr>
                    <td colSpan={2}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>Account Balance</td>

                    <td className='money-cell'>{formatMoney(dto.starting_balance)}</td>
                  </tr>
                </>
              ) : (
                ''
              )}

              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>

              <tr>
                <td>
                  <span className='semi-bold'>Amount due</span>
                </td>
                <td className='money-cell'>{formatMoney(dto.amount_due)}</td>
              </tr>
            </table>
          </>
        </Modal>
      )}
    </>
  );
};
