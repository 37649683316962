import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actionItemList from './actionItemList';
import DashboardCard from './DashboardCard';
import { actions } from '../sagaSlice';
import ctaItems from './ctaItems';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';

export default connect(
  (state) => ({
    actionItemData: state.main.actionItemData,
  }),
  actions
)(({ provider, updateProvider, actionItemData, getActionItemData }) => {
  useEffect(() => {
    if (provider) {
      getActionItemData({ id: provider.id });
    }
  }, [getActionItemData, provider]);

  if (!provider) {
    return <></>;
  }

  const cta = _.get(provider, 'custom_content.cta', {}) || {};

  const ctaCards = Object.keys(ctaItems)
    .map((ctaItem) => {
      if (
        cta[ctaItem] !== false &&
        ctaItems[ctaItem] &&
        (!ctaItems[ctaItem].providerDisplayFn || ctaItems[ctaItem].providerDisplayFn(provider))
      ) {
        return (
          <DashboardCard
            provider={provider}
            key={ctaItem}
            item={ctaItems[ctaItem]}
            dismiss={() => {
              try {
                mixpanel.track('therapist_portal_cta_event', {
                  action: 'dismiss_cta',
                  provider_id: provider.id,
                  card_name: ctaItems[ctaItem].cardName,
                });
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
              }

              updateProvider({
                id: provider.id,
                custom_content: {
                  cta: {
                    ...cta,
                    [ctaItem]: false,
                  },
                },
              });
            }}
          />
        );
      }
    })
    .filter((x) => !!x);

  const actionItemCards = actionItemList
    .map((item) => {
      if (
        item.cardName &&
        item.cardDescription &&
        item.cardLink &&
        (!item.providerDisplayFn || item.providerDisplayFn(provider, actionItemData))
      ) {
        item.cardType = 'Profile Creation Steps';

        return <DashboardCard provider={provider} key={item.cardName} item={item} />;
      }
    })
    .filter((x) => !!x);

  return (
    <div>
      {(actionItemCards.length > 0 || ctaCards.length > 0) && (
        <>
          <hr />
          <h2 className='m-b-md'>Let's strengthen your profile!</h2>
          {ctaCards}
          {actionItemCards}
        </>
      )}
    </div>
  );
});
