import React from 'react';

export default ({ provider }) => (
  <>
    {provider && (
      <div className='love-yo-work m-t-lg center'>
        <h5>
          Zencare <i className='fad fa-heart text-secondary'></i>
          {provider.type === 'individual'
            ? ` ${provider.first_name}'s Private Practice`
            : ` ${provider.company_name}`}
        </h5>
      </div>
    )}
    <footer style={{ padding: '3rem 1rem' }}></footer>
  </>
);
