/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { actions as mainActions } from '@/main/sagaSlice';
import ErrorField from '+/forms/ErrorField';
import FormCheckbox from '+/forms/FormCheckbox';
import SubmitButton from '+/forms/SubmitButton';
import ToggleSwitch from '+/forms/ToggleStateSwitch';
import AcceptingNewToggle from './AcceptingNewToggle';
import { actions as onboardingActions } from './sagaSlice';
import Loader from '+/forms/SectionLoader';
import TypeAhead from '+/TypeAhead';
import stateAbbrDict from '~/utils/stateAbbrDict';
import AddressFields from '@/provider/profile/AvailabilityLocations/AddressFields';
import hasClinicianScope from '#/hasClinicianScope';

import _ from 'lodash';

const LocationForm = ({
  onboarding,
  main,
  auth,
  getAllMyProviders,
  getAllAccountDetails,
  createInitialLocations,
  providerId,
}) => {
  useEffect(() => {
    getAllMyProviders();
    getAllAccountDetails();
  }, [getAllMyProviders, getAllAccountDetails]);
  const [acceptingPatientsPhysical, setAcceptingPatientsPhysical] = useState(40);
  const [acceptingPatientsOnline, setAcceptingPatientsOnline] = useState(40);
  const [displayPhysicalAddress, setDisplayPhysicalAddress] = useState(true);

  const accounts = _.get(main, 'accounts', []).filter((a) => !a.error);

  const provider = providerId
    ? main.allProviders.find((p) => p.id === providerId) ||
      _.flatten(accounts.map((a) => a.providers)).find((p) => p.id === providerId)
    : main.provider;

  if (!provider) {
    return 'Loading...';
  }

  const isClinician = hasClinicianScope(auth.roles, provider.id);

  let you = 'you';
  let your = 'your';

  if (isClinician && provider.type === 'practice') {
    you = 'your practice';
    your = "your practice's";
  } else if (!isClinician) {
    you = 'your provider';
    your = "your provider's";
  }

  if (onboarding.isLoading) {
    return (
      <div className='loader-container w-100 m-t-md flex align-center justify-center'>
        <Loader />
      </div>
    );
  }

  const states = Object.keys(stateAbbrDict).map((k, i) => ({
    id: i,
    abbr: k,
    name: stateAbbrDict[k],
  }));

  const updateDisplayAddress = (values) => {
    setDisplayPhysicalAddress(values.display_address);
  };

  const onSubmit = (values) => {
    const locations = [];

    if (!values.no_online_sessions) {
      values.states.forEach((stateIndex) => {
        locations.push({
          remote_location: true,
          state: states[stateIndex].abbr,
          accepting_patients: acceptingPatientsOnline,
        });
      });
    }

    if (!values.no_physical_practice_location) {
      const { address, address_2, city, state, zip } = values;
      locations.push({
        remote_location: false,
        address,
        address_2,
        city,
        state,
        zip,
        display_address: displayPhysicalAddress,
        accepting_patients: acceptingPatientsPhysical,
      });
    }

    const payload = {
      provider_id: providerId || main.provider.id,
      locations,
    };

    createInitialLocations(payload);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        no_physical_practice_location: false,
        no_online_sessions: false,
      }}
      mutators={{
        setField: ([name, val], state, { changeValue }) => {
          changeValue(state, name, () => val);
        },
      }}
      validate={(values) => {
        const errors = {};
        if (!values.no_online_sessions) {
          if (!values.states || !values.states.length) {
            errors['states'] = 'Please select at least one state';
          }
        }

        if (!values.no_physical_practice_location) {
          ['address', 'city', 'zip', 'state'].forEach((field) => {
            if ([null, undefined, ''].includes(values[field])) {
              errors[field] = `${_.capitalize(field)} is required.`;
            }
          });
        }

        if (values.no_online_sessions && values.no_physical_practice_location) {
          errors['wont actually show but will prevent submission'] = 'must select 1 or other';
        }

        return errors;
      }}
      render={(props) => (
        <div key='location-form'>
          <div className='box m-b-md'>
            <div className='w-100'>
              <h4 className='m-b-0'>Physical Practice Location*</h4>
              <p className='description'>
                We recommend adding a physical location as this helps clients find your practice
                more easily, even if {you} {you === 'you' ? 'are' : 'is'} currently offering online
                sessions only!
              </p>
            </div>
            <div className='flex w-100 justify-between wrap physical-location'>
              <AddressFields
                isOnline={false}
                disabled={props.values.no_physical_practice_location}
              />
            </div>
            <div className='m-b-lg display-physical-address'>
              <ToggleSwitch
                disabled={props.values.no_physical_practice_location}
                key='display_address'
                type='details'
                location_key='display_address'
                label='display_address'
                checked={true}
                updateFn={updateDisplayAddress}
              />
            </div>
            <div className='m-b-md'>
              <AcceptingNewToggle
                disabled={props.values.no_physical_practice_location}
                acceptingPatients={acceptingPatientsPhysical}
                propertyName='acceptingPatientsPhysical'
                setAcceptingPatients={setAcceptingPatientsPhysical}
                where={<span>at this location</span>}
              />
            </div>
            <div className='m-b-lg'>
              <Field name='no_physical_practice_location'>
                {(props) => (
                  <FormCheckbox
                    {...props}
                    label={`${you === 'you' ? 'I' : 'We'} don't have a physical practice location`}
                  />
                )}
              </Field>
            </div>
          </div>
          <div>
            <div className='box'>
              <div className='w-100 p-t-md'>
                <h3 className='m-b-sm'>Online Practice*</h3>
                <div className='field m-b-lg'>
                  <p className='description'>
                    Please indicate the states in which {you} {you === 'you' ? 'are' : 'is'}{' '}
                    licensed to practice.
                  </p>
                  <div className='bold w-100'>
                    States in which you are licensed and offer online therapy
                  </div>
                  <TypeAhead
                    field='name'
                    selectSingle={false}
                    criteria={() => true}
                    items={states}
                    className={props.values.no_online_sessions ? 'disabled w-49' : 'w-49'}
                    selected={props.values.states || []}
                    onSelect={props.form.mutators.setField.bind(null, 'states')}
                    placeholder='Select states'
                  />
                  <ErrorField name='states' />
                </div>
                <div className='m-b-md'>
                  <AcceptingNewToggle
                    disabled={props.values.no_online_sessions}
                    acceptingPatients={acceptingPatientsOnline}
                    propertyName='acceptingPatientsOnline'
                    setAcceptingPatients={setAcceptingPatientsOnline}
                    where='online'
                  />
                </div>
                <Field name='no_online_sessions'>
                  {(props) => (
                    <FormCheckbox
                      {...props}
                      label={`${you === 'you' ? 'I' : 'We'} don't offer online sessions`}
                    />
                  )}
                </Field>
                {props.values.no_online_sessions && props.values.no_physical_practice_location && (
                  <p className='m-t-lg help text-error'>
                    At least one online or physical location must be entered.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div>
            <div>
              <ErrorField name='address' />
            </div>
            <div>
              <ErrorField name='city' />
            </div>
            <div>
              <ErrorField name='zip' />
            </div>
            <div>
              <ErrorField name='state' />
            </div>
          </div>
          <div className='w-100 flex justify-center m-t-sm'>
            You can edit any of these fields later before going live!
          </div>
          <div className='w-100 flex justify-center m-t-sm'>
            <SubmitButton
              buttonClass='lg primary pill'
              submittable={
                !props.values.no_online_sessions || !props.values.no_physical_practice_location
              }
              buttonText='Save + Next'
              {...props}
            />
          </div>
        </div>
      )}
    />
  );
};

export default connect(
  //eslint-disable-next-line
  ({ onboarding, main, auth }) => ({ onboarding, main, auth }),
  {
    createInitialLocations: onboardingActions.createInitialLocations,
    getAllMyProviders: mainActions.getAllMyProviders,
    getAllAccountDetails: mainActions.getAllAccountDetails,
  }
)(LocationForm);
