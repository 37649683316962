import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';

export const onlyOnEnter = (fn) => (e) => e.which === 13 && fn();

const TypeAhead = (props) => {
  const {
    field,
    items = [],
    onSelect = () => ({}),
    placeholder,
    criteria,
    className,
    text: initialText,
    selected: initialSelect,
    errorItem,
    selectSingle,
    handleDeselect = () => {},
  } = props;

  const inputRef = useRef(null);

  const [text, setText] = useState(() => initialText || '');
  const [selected, setSelected] = useState(initialSelect);
  const [optionsOpen, setOptionsOpen] = useState(false);

  useEffect(() => {
    if (inputRef.current === document.activeElement) {
      setOptionsOpen(true);
    } else {
      setOptionsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (initialSelect && initialSelect.length) {
      const deduplicatedSelections = initialSelect.filter((x, i) => initialSelect.indexOf(x) === i);
      setSelected(deduplicatedSelections);
    }
  }, [initialSelect]);

  const keyUp = ({ target }) => setText(target.value);

  const select = (item) => {
    if (selectSingle) {
      setText('');
      setSelected([item.id]);
      onSelect(item.id);
    } else {
      const newSelected = [...selected, item.id];
      setText('');
      setSelected(newSelected);
      onSelect(newSelected);
    }

    if (selectSingle) {
      inputRef.current.blur();
      setOptionsOpen(false);
    }
  };

  const deselect = (index) => {
    if (selectSingle) {
      setSelected([]);
      onSelect();
      setText('');
    }

    const newSelected = selected.slice(0, index).concat(selected.slice(index + 1, selected.length));
    setSelected(newSelected);
    onSelect(newSelected);
  };

  const filter = (rgx, item) => {
    const name = displayName(item);
    const { id } = item;
    const notSelected = selected.indexOf(id) === -1;
    const matchesCriteria = (criteria || (() => true))(item);

    return (selectSingle || notSelected) && matchesCriteria && rgx.test(name);
  };

  const _items = () => {
    const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const rgx = new RegExp(escapedText, 'i');

    return items.filter((item) => filter(rgx, item));
  };

  const displayName = (item) => {
    if (field.constructor === Function) {
      return field(item);
    }

    return item[field];
  };

  const keyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };
  const filteredItems = _items();

  return (
    <div
      className={`typeahead w-100 ${className}`}
      role='presentation'
      onMouseDown={(event) => {
        event.preventDefault();
        setOptionsOpen(true);
        inputRef.current.focus();
      }}
    >
      <div className='input-container flex w-100'>
        {selected.map((s, i) => {
          // Items can be removed from the list so check for that and ignore selected
          // items that have been removed.
          if (!items.find((x) => x.id === s)) {
            return null;
          }

          return (
            <span key={i} className='selected m-t-xs m-r-xs'>
              {displayName(items.find((x) => x.id === s))}
              <button
                className='unselect m-l-xs m-r-xs'
                onClick={(e) => {
                  e.preventDefault();
                  handleDeselect(s);
                  deselect(i);
                }}
              >
                x
              </button>
            </span>
          );
        })}
        <input
          type='text'
          autoComplete={`${field}-${_.random(100)}`}
          placeholder={
            (selectSingle && !selected.length) || !selected.length
              ? placeholder || 'Filter items...'
              : ''
          }
          onKeyPress={keyPress}
          onChange={(e) => setText(e.target.value)}
          onClick={(e) => setText(e.target.value)}
          value={text}
          ref={inputRef}
          onFocus={(__) => {
            setOptionsOpen(true);
          }}
          onBlur={(e) => {
            if (!e.relatedTarget || !e.relatedTarget.classList.contains('_typeahead-option')) {
              setOptionsOpen(false);
            }
          }}
        />
      </div>
      {optionsOpen && (
        <div className='options'>
          {filteredItems.length ? (
            filteredItems.map((item, i) => (
              <div
                tabIndex={0}
                className='_typeahead-option'
                key={item.id || i}
                role='menuitem'
                onClick={(e) => {
                  e.preventDefault();
                  select(item);
                }}
                onKeyDown={onlyOnEnter(() => select(item))}
              >
                {displayName(item)}
              </div>
            ))
          ) : errorItem ? (
            <div
              tabIndex={0}
              className='_typeahead-option'
              key={errorItem.id || errorItem}
              role='menuitem'
              onClick={() => select(errorItem)}
              onKeyDown={onlyOnEnter(() => select(errorItem))}
            >
              {displayName(errorItem)}
            </div>
          ) : (
            <div className='grid m-t-sm justify-center align-center'>
              <h5>No matches</h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TypeAhead;
