/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import PureModal from '+/Modal';
import { days } from './constants';
import { toggleHours } from './helpers';
import ToggleSwitch from '+/forms/ToggleStateSwitch';
import FormCheckbox from '+/forms/FormCheckbox';
import _ from 'lodash';
import OnlineToggle from './OnlineToggle';
import AcceptingNewToggle from './AcceptingNewToggle';
import AddressFields from './AddressFields';
import ErrorField from '+/forms/ErrorField';
import stateAbbrDict from '~/utils/stateAbbrDict';
import { licenseFilledIn, initializeLicensePrefill } from './locationLicenseUtils';
const curYear = new Date().getUTCFullYear();
const yearOptions = [...Array(70).keys()].map((x) => curYear + 20 - x);

const insurancesAccepted = (provider, stateAbbr) => {
  if (stateAbbr) {
    return provider.insurances
      .filter(
        (insuranceTag) =>
          !provider.insurance_opt_outs.find(
            (insuranceOptOut) =>
              insuranceOptOut.state_abbr === stateAbbr && insuranceOptOut.tag_id === insuranceTag.id
          )
      )
      .map((insuranceTag) => insuranceTag.name.trim())
      .join(', ');
  } else {
    return '';
  }
};

//eslint-disable-next-line
export const AddEditLocationModal = ({
  provider,
  open,
  toggle,
  onSubmit,
  isEdit,
  location,
  existingLocations,
  licenses,
}) => {
  const onlineInitialState = isEdit ? location.remote_location === 1 : false;

  // Form should start out valid when editing but invalid when adding.
  const [currentlyValid, setValid] = useState(isEdit);
  const [isOnline, setOnline] = useState(onlineInitialState);

  const acceptingPatientsInitialState = isEdit ? location.accepting_patients : 40;
  const [acceptingPatients, setAcceptingPatients] = useState(acceptingPatientsInitialState);

  const timesOfDay = ['morning', 'daytime', 'afternoon', 'evening', 'weekend'];

  function submit(v) {
    const { address, address_2, city, state, zip, location_name } = v; // eslint-disable-line
    const hours = [v.monday, v.tuesday, v.wednesday, v.thursday, v.friday, v.saturday, v.sunday];
    const availability = timesOfDay.reduce((acc, k) => {
      acc[`${k}_availability`] = v[`${k}_availability`];

      return acc;
    }, {});

    const locationForPayload = {
      hours,
      address,
      address_2,
      city,
      state,
      zip,
      display_address: _.get(v, 'display_address.display_address', true),
      ada_accessible: _.get(v, 'ada_accessible.ada_accessible', false),
      remote_location: isOnline,
      accepting_patients: acceptingPatients,
      location_name,
      ...availability,
    };
    if (isEdit) {
      locationForPayload.id = location.id;
      onSubmit(locationForPayload);
    } else {
      const payload = {
        location: locationForPayload,
        license: {
          number: v[`license_number_${v.state}`],
          license_type: v[`license_type_${v.state}`],
          expiration: parseInt(v[`license_expiration_${v.state}`]),
        },
      };
      onSubmit(payload);
    }
  }

  function validate(values) {
    const errors = {};
    if (isEdit && !isOnline && [null, undefined, ''].includes(values['location_name'])) {
      errors['location_name'] = 'Location Name is required.';
    }
    ['address', 'city', 'zip'].forEach((field) => {
      if (!isOnline && [null, undefined, ''].includes(values[field])) {
        errors[field] = `${field} required.`;
      }
    });
    ['state'].forEach((field) => {
      if ([null, undefined, ''].includes(values[field])) {
        errors[field] = `${field} required.`;
      }
    });

    if (acceptingPatients > 0 && !timesOfDay.some((d) => values[`${d}_availability`])) {
      errors.time_availability =
        'Please indicate when you are accepting new clients. If you are not accepting new clients, please toggle location availability "No."';
    }

    if (days.map((d) => d.name).some((n) => values[`${n}_checkbox_modal`] && !values[n])) {
      errors.practice_hours =
        'Please indicate practice hours for each day that your practice is open.';
    }

    days.forEach((day) => {
      if (
        values[`${day.name}_checkbox_modal`] &&
        values[day.name] &&
        values[day.name].length > 70
      ) {
        errors[day.name] =
          'Max 70 characters. We recommend indicating specific hours, such as "9am - 4pm"!';
      }
    });

    if (isDuplicateAddress(values)) {
      if (isOnline) {
        if (!isEdit) {
          errors.state = `You already have ${values.state} listed as a state for online sessions.`;
        }
      } else {
        // Show error next to zip code so user can see it easily without having to scroll up
        errors.zip = 'You have already added a location with this exact same address.';
      }
    }

    if (!isEdit && values.state) {
      if (
        !(
          values[`license_number_${values.state}`] &&
          values[`license_number_${values.state}`].length > 0
        )
      ) {
        errors[`license_number_${values.state}`] = 'License Number is required';
      }

      if (
        !(
          values[`license_type_${values.state}`] &&
          values[`license_type_${values.state}`].length > 0
        )
      ) {
        errors[`license_type_${values.state}`] = 'License Type is required';
      }

      if (
        !(
          values[`license_expiration_${values.state}`] &&
          values[`license_expiration_${values.state}`].length > 0
        )
      ) {
        errors[`license_expiration_${values.state}`] = 'License Expiration Year is required';
      }
    }

    setValid(Object.values(errors).length === 0);

    return errors;
  }

  function isDuplicateAddress(values) {
    let locationsToCheck = existingLocations;
    if (isEdit) {
      locationsToCheck = existingLocations.filter((x) => x.id !== location.id);
    }

    const { address, address_2, city, state, zip } = values; // eslint-disable-line
    let isDuplicate = false;
    locationsToCheck.forEach((existingLocation) => {
      if (isOnline) {
        if (
          existingLocation.remote_location === 1 &&
          stateAbbrDict[state] === existingLocation.state
        ) {
          isDuplicate = true;
        }
      } else {
        if (existingLocation.remote_location === 0) {
          const newFullAddress =
            `${address ? address.trim().toLowerCase() : ''} ${
              address_2 ? address_2.trim().toLowerCase() : ''
            }` +
            ` ${city ? city.trim().toLowerCase() : ''} ${state ? stateAbbrDict[state] : ''} ${
              zip ? zip.trim() : ''
            }`;
          const existingFullAddress =
            `${existingLocation.address.trim().toLowerCase()} ${
              existingLocation.address_2 ? existingLocation.address_2.trim().toLowerCase() : ''
            }` +
            ` ${existingLocation.city.trim().toLowerCase()} ${
              existingLocation.state
            } ${existingLocation.zip.trim()}`;
          if (newFullAddress === existingFullAddress) {
            isDuplicate = true;
          }
        }
      }
    });

    return isDuplicate;
  }

  const locationHoursUnspecified = (v) => {
    const hours = [v.monday, v.tuesday, v.wednesday, v.thursday, v.friday, v.saturday, v.sunday];

    return hours && hours.some((h) => h === 'Please inquire about hours');
  };

  let initialValues = {};
  if (isEdit) {
    initialValues = Object.assign(initialValues, location);
    initialValues.monday = location.hours[0];
    initialValues.tuesday = location.hours[1];
    initialValues.wednesday = location.hours[2];
    initialValues.thursday = location.hours[3];
    initialValues.friday = location.hours[4];
    initialValues.saturday = location.hours[5];
    initialValues.sunday = location.hours[6];
    days.forEach((day) => {
      initialValues[`${day.name}_checkbox_modal`] = initialValues[day.name] !== 'Closed';
    });
    const lookupAbbrByFullState = _.invert(stateAbbrDict);
    initialValues.state = lookupAbbrByFullState[initialValues.state];
  } else {
    initialValues = days.reduce((acc, { name }) => {
      acc[`${name}_checkbox_modal`] = true;

      return acc;
    }, {});
    initialValues.display_address = true;
    initializeLicensePrefill(licenses, initialValues);
  }

  const isLicenseReadOnly = (state) => (state ? initialValues[`license_number_${state}`] : false);

  return (
    <PureModal
      visible={open}
      headerContent={
        isEdit ? <h4>Edit location: {location.location_name}</h4> : <h4>Add a new location</h4>
      }
      footerContent={
        <div className='actions'>
          <button
            onClick={() => {
              const button = document.querySelector(
                '.add-location-modal-interior .actions button.hollow'
              );
              if (button) {
                button.click();
              }
            }}
            className='hollow'
          >
            Cancel
          </button>
          <button
            onClick={() => {
              const button = document.querySelector(
                '.add-location-modal-interior .actions button.primary'
              );
              if (button) {
                button.click();
              }
            }}
            className='primary'
            disabled={!currentlyValid}
          >
            {isEdit ? <>Save Location</> : <>Create Location</>}
          </button>
        </div>
      }
      closeModal={() => toggle(false)}
    >
      <div className='add-location-modal-interior'>
        {!isEdit && <OnlineToggle isOnline={isOnline} setOnline={setOnline} />}
        <AcceptingNewToggle
          acceptingPatients={acceptingPatients}
          setAcceptingPatients={setAcceptingPatients}
        />

        <Form
          initialValues={initialValues}
          onSubmit={submit}
          mutators={{
            toggleHours,
          }}
          validate={validate}
          render={({ handleSubmit, values, pristine, valid, form, errors }) => (
            <>
              {provider && provider.insurances && provider.insurances.length ? (
                <div className='insurances-accepted'>
                  <div className='sub-header'>
                    <h5 className='title'>Insurances Accepted</h5>
                    <span className='desc block'>
                      {insurancesAccepted(provider, values.state).length ? (
                        <>
                          <i className='fas fa-shield-check in-network-badge'></i>{' '}
                          {insurancesAccepted(provider, values.state)}
                        </>
                      ) : (
                        'None'
                      )}
                    </span>
                    <p className='text-error m-t-0 m-b-md'>
                      Go to the{' '}
                      <a href='/profile/insurance-fees' target='_blank'>
                        Health Insurance
                      </a>{' '}
                      section to update your insurance list and indicate in-network insurances for
                      each state.
                    </p>
                  </div>
                </div>
              ) : (
                ''
              )}
              {isEdit && !isOnline && (
                <div className='field w-100 m-b-xs'>
                  <h5>Location Name</h5>
                  <Field
                    initialValue={values.location_name}
                    name='location_name'
                    placeholder='e.g. Midtown office'
                    className='w-100 m-b-xs'
                    component='input'
                    required={true}
                    type='text'
                  />
                  <ErrorField name='location_name' />
                </div>
              )}
              <div className='flex row justify-between m-b-sm wrap'>
                {!isOnline && <h3>Address</h3>}
                {(!isEdit || !isOnline) && (
                  <AddressFields isOnline={isOnline} stateDisabled={isEdit} />
                )}
                {!isOnline && (
                  <div className='field w-100 m-b-xs'>
                    <h4>Practice Details</h4>
                    <Field name='ada_accessible'>
                      {(props) => (
                        <ToggleSwitch
                          type='details'
                          checked={initialValues.ada_accessible}
                          label={'ada_accessible'}
                          location_key={'ada_accessible'}
                          updateFn={(a) => {
                            props.input.onChange(a);
                          }}
                        />
                      )}
                    </Field>
                    <Field name='display_address'>
                      {(props) => (
                        <ToggleSwitch
                          type='details'
                          checked={initialValues.display_address}
                          label={'display_address'}
                          location_key={'display_address'}
                          updateFn={(a) => {
                            props.input.onChange(a);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {!isEdit && values.state && !isLicenseReadOnly(values.state) && (
                  <>
                    <div className='modal-section-divider'></div>
                    <div className='field w-100 m-b-xs'>
                      <p className={`bold ${licenseFilledIn(values) ? '' : 'text-error'}`}>
                        Please indicate your {values.state ? stateAbbrDict[values.state] : ''}{' '}
                        license to display a {values.state ? stateAbbrDict[values.state] : ''}{' '}
                        location.
                      </p>
                    </div>
                    <div className='field w-49 m-b-xs'>
                      <h5>License State</h5>
                      {values.state ? stateAbbrDict[values.state] : ''}
                    </div>
                    <div className='field w-49 m-b-xs'>
                      <h5>License Number</h5>
                      <Field
                        name={`license_number_${values.state}`}
                        disabled={isLicenseReadOnly(values.state)}
                        className={`w-100 ${isLicenseReadOnly(values.state) ? 'disabled' : ''}`}
                        component='input'
                        required={true}
                        type='text'
                        placeholder='e.g. Psy222592'
                      />
                      <ErrorField
                        name={`license_number_${values.state}`}
                        metaOverride={{
                          modified: true,
                          visited: true,
                        }}
                      />
                    </div>

                    <div className='field w-49 m-b-xs'>
                      <h5>License Type</h5>
                      <Field
                        name={`license_type_${values.state}`}
                        disabled={isLicenseReadOnly(values.state)}
                        className={`w-100 ${isLicenseReadOnly(values.state) ? 'disabled' : ''}`}
                        component='input'
                        required={true}
                        type='text'
                        placeholder='e.g. PhD, LCSW, LCAT'
                      />
                      <ErrorField
                        name={`license_type_${values.state}`}
                        metaOverride={{
                          modified: true,
                          visited: true,
                        }}
                      />
                    </div>
                    <div className='field w-49 m-b-xs'>
                      <h5>License Expiration Year</h5>
                      <Field
                        className={`license-expiration ${
                          isLicenseReadOnly(values.state) ? 'disabled' : ''
                        }`}
                        name={`license_expiration_${values.state}`}
                        disabled={isLicenseReadOnly(values.state)}
                        component='select'
                        placeholder=''
                        type='select'
                      >
                        <option value='' disabled selected hidden>
                          Select year
                        </option>
                        {yearOptions.map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))}
                        <i className='fas fa-caret-down'></i>
                      </Field>
                      <ErrorField
                        name={`license_expiration_${values.state}`}
                        metaOverride={{
                          modified: true,
                          visited: true,
                        }}
                      />
                    </div>
                    <div className='modal-section-divider'></div>
                  </>
                )}

                {acceptingPatients > 0 && (
                  <>
                    <h3 className='w-100'>Open Appointment Times</h3>
                    <span className='description m-b-sm'>
                      Times when you are accepting clients.
                    </span>
                    <div className='select-items'>
                      {Object.entries({
                        morning_availability: (
                          <>
                            <span className='time-of-day'>Morning</span>
                            <span>(before 10am)</span>
                          </>
                        ),
                        daytime_availability: (
                          <>
                            <span className='time-of-day'>Midday </span>
                            <span>(10am - 1pm)</span>
                          </>
                        ),
                        afternoon_availability: (
                          <>
                            <span className='time-of-day'>Afternoon</span>
                            <span>(1pm - 5pm)</span>
                          </>
                        ),
                        evening_availability: (
                          <>
                            <span className='time-of-day'>Evening</span>
                            <span>(after 5pm)</span>
                          </>
                        ),
                        weekend_availability: (
                          <>
                            <span className='time-of-day'>Weekends</span>
                            <span></span>
                          </>
                        ),
                      }).map(([name, label]) => (
                        <div className='checkbox m-b-xs'>
                          <Field name={name}>
                            {(props) => (
                              <FormCheckbox
                                {...props}
                                label={label}
                                labelStyle={{
                                  width: '300px',
                                }}
                                labelClassName='aligned-label'
                                fieldClassName='flex align-center'
                              />
                            )}
                          </Field>
                        </div>
                      ))}
                      <ErrorField
                        name='time_availability'
                        metaOverride={{
                          modified: true,
                          visited: true,
                        }}
                      />
                    </div>
                  </>
                )}

                <h3 className='w-100 m-t-md m-b-0'>Regular Practice Hours</h3>
                <span className='description m-b-0'>When you typically see clients.</span>
                {locationHoursUnspecified(values) && (
                  <p className='w-100 text-error m-b-md'>
                    We recommend indicating specific hours, such as "9am - 4pm"!
                  </p>
                )}
                {days.map(({ name, short_name, very_short_name }) => (
                  <>
                    <div className='flex w-100 checkbox m-b-xs'>
                      <Field name={`${name}_checkbox_modal`}>
                        {(props) => (
                          <FormCheckbox
                            {...props}
                            label={window.screen.width > 600 ? short_name : very_short_name}
                            labelClassName='aligned-label'
                            fieldClassName='flex align-center'
                            mutator={(e) => form.mutators.toggleHours(e.target.checked, name)}
                          />
                        )}
                      </Field>

                      <Field name={name}>
                        {({ input, meta }) => (
                          <input
                            {...input}
                            placeholder='e.g. 10:00am-8:00pm'
                            className='input-box'
                            disabled={values[name] === 'Closed'}
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorField
                      name={name}
                      metaOverride={{
                        modified: true,
                        visited: true,
                      }}
                    />
                  </>
                ))}
                <ErrorField
                  name='practice_hours'
                  metaOverride={{
                    modified: true,
                    visited: true,
                  }}
                />

                <div className='actions'>
                  <button
                    onClick={() => {
                      toggle(false);
                    }}
                    className='hollow'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      if (!valid) return;
                      handleSubmit();
                    }}
                    className='primary'
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        />
      </div>
    </PureModal>
  );
};

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
});

const actions = {};

export default connect(mapStateToProps, actions)(AddEditLocationModal);
