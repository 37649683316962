import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import ReactTooltip from 'react-tooltip';
import DashboardCard from './DashboardCard';
import checklistItems from './checklistItems.js';

export default function ({ provider, checklist, roles, accounts, licenses }) {
  const [showChecklist, setShowChecklist] = useState(false);

  useEffect(() => {
    if (['incomplete', 'inactive', 'reviewing'].includes(provider.status) && checklist.length) {
      setShowChecklist(true);
    }
  }, [provider, checklist]);

  const done = (item) =>
    !(
      checklist.includes(item.name) ||
      (item.providerCheckFn && item.providerCheckFn(provider, roles, accounts, licenses))
    );

  const itemsToDisplay = checklistItems.filter(
    (c) => !c.providerDisplayFn || c.providerDisplayFn(provider)
  );

  const items = itemsToDisplay.map((item) => {
    const interior = (
      <div
        className={`checklist-item ${done(item) && 'done'}`}
        data-for='checklist-tooltip'
        data-tip={item.tooltip}
      >
        <i
          className={`fal fa-${
            done(item) ? 'check-square text-primary' : 'square text-quaternary'
          }`}
        ></i>
        <span className='checklist-item-name'>{item.name}</span>
      </div>
    );

    return item.cardLink[0] === '/' ? (
      <HashLink key={item.name} to={item.cardLink}>
        {interior}
      </HashLink>
    ) : (
      <a key={item.name} href={item.cardLink}>
        {interior}
      </a>
    );
  });

  const cards = itemsToDisplay.map((item) => {
    if (item.cardName && item.cardDescription && item.cardLink && !done(item)) {
      item.cardLinkText = 'Update';
      item.cardType = 'Profile Creation Steps';

      return <DashboardCard provider={provider} item={item} />;
    }
  });

  return (
    <>
      <div>
        {showChecklist && (
          <>
            <h2 className='m-b-md'>Let's get your profile live!</h2>
            <div className='box m-b-md'>
              <h3 className='m-b-md'>Profile Checklist</h3>
              <div className='checklist'>
                {items}
                <ReactTooltip
                  id='checklist-tooltip'
                  effect='solid'
                  place='right'
                  type='light'
                  arrowColor='#37BEC3'
                ></ReactTooltip>
              </div>
            </div>
          </>
        )}
        {cards}
      </div>
    </>
  );
}
