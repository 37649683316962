import React, { useState } from 'react';
import _ from 'lodash';
import Voca from 'voca';
import {
  getSubscriptionProviders,
  getCardFromSource,
  sourcesAreSame,
  deduplicateCustomerSourcesByCardNumAndExp,
} from './helpers';
import { CANCELED_SUBSCRIPTION_STATUSES } from './constants';
import providerName from '#/providerName';
import UpdatePaymentSection from './UpdatePaymentSection';
import wrapStripeElements from '~/utils/wrapStripeElements';
import BillingAddress from './BillingAddress';

const getSeatBasedProviderList = (
  subscription: $TSFixMe,
  accounts: $TSFixMe,
  allProviders: $TSFixMe
) => {
  let activeAccountProviders = [];
  if (accounts && accounts.length) {
    const account = accounts.find(
      (x: $TSFixMe) => x.seat_based_stripe_subscription_id === subscription.id
    );

    if (account && account.provider_paid_seats) {
      const activeAccountProviderIds = account.provider_paid_seats
        .filter((seat: $TSFixMe) => seat.canceled_at === null)
        .map((seat: $TSFixMe) => seat.provider_id);

      activeAccountProviders = allProviders.filter((p: Provider) =>
        activeAccountProviderIds.includes(p.id)
      );
    } else {
      console.log(
        `Warning: Unable to find account with seat_based_stripe_subscription_id of ${subscription.id}`
      );
    }
  }
  return activeAccountProviders;
};

interface PaymentMethodsProps {
  customers: $TSFixMe;
  allProviders: $TSFixMe;
  subscriptions: $TSFixMe;
  accounts: $TSFixMe;
}

export default ({ customers, allProviders, subscriptions, accounts }: PaymentMethodsProps) => {
  const [updating, setUpdating] = useState(-1);

  const childProps = _.flatten(
    customers.map((c: $TSFixMe) =>
      (deduplicateCustomerSourcesByCardNumAndExp(c.sources) || []).map((source) => {
        const sourceIsDefault = sourcesAreSame(source, c.default_source_object);

        const subscriptionsMatchingSource = subscriptions.filter((sub: $TSFixMe) => {
          if (sub.customer_id !== c.id) {
            return false;
          }

          if (sub.default_payment_method_object) {
            return sourcesAreSame(sub.default_payment_method_object, source);
          }

          return sourceIsDefault;
        });

        const providers = _.uniqBy(
          _.flatten(
            subscriptionsMatchingSource.map((s: $TSFixMe) => {
              if (s.metadata.seat_based === 'yes') {
                return getSeatBasedProviderList(s, accounts, allProviders);
              } else {
                return getSubscriptionProviders(s, allProviders);
              }
            })
          ),
          'id'
        );

        return {
          source,
          customer: c,
          subscriptions: subscriptionsMatchingSource.filter(
            (s: $TSFixMe) => !CANCELED_SUBSCRIPTION_STATUSES.includes(s.status)
          ),
          providers,
          updating,
          setUpdating: () => setUpdating(updating === source.id ? -1 : source.id),
        };
      })
    )
  ).filter((p: $TSFixMe) => p.source && p.subscriptions && p.subscriptions.length);

  if (!childProps.length) {
    return null;
  }

  return (
    <>
      <h2 className='m-t-md' id='payment-methods'>
        Payment Methods
      </h2>
      <div className='flex wrap'>
        {childProps.map((props: $TSFixMe) => (
          <PaymentMethod {...props} />
        ))}
      </div>
      <BillingAddress />
    </>
  );
};

interface PaymentMethodProps {
  source: $TSFixMe;
  customer: $TSFixMe;
  subscriptions: $TSFixMe;
  providers: $TSFixMe;
  updating: $TSFixMe;
  setUpdating: $TSFixMe;
}

const PaymentMethod = wrapStripeElements(
  ({ source, customer, subscriptions, providers, updating, setUpdating }: PaymentMethodProps) => {
    const card = getCardFromSource(source) || {};

    if (!source || !subscriptions || !subscriptions.length) {
      return null;
    }

    return (
      <div className='account-box w-49 m-r-sm'>
        <div className='flex justify-between'>
          <h5 className='m-b-xs'>
            {Voca.capitalize(card.brand)} ····{card.last4}
          </h5>
          <h6 className='m-b-xs instruction'>
            expires {card.exp_month}/{card.exp_year}
          </h6>
        </div>
        <p className='m-b-0'>Subscriptions using this payment method:</p>
        <ul>
          {providers.map((p: $TSFixMe) => (
            <li>{providerName(p)}</li>
          ))}
        </ul>
        <button className='primary pill hollow' onClick={setUpdating}>
          {updating === source.id ? 'Cancel' : 'Update payment method'}
        </button>
        {updating === source.id && (
          <UpdatePaymentSection customer={customer} source={source} setUpdating={setUpdating} />
        )}
      </div>
    );
  }
);
