/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import ContactModal from '~/components/provider/profile/ContactModal';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import FormCheckbox from '+/forms/FormCheckbox';
import SubmitButton from '+/forms/SubmitButton';
import _ from 'lodash';
import { actions as mainActions } from '../../main/sagaSlice';
import hasOwnerScope from '#/hasOwnerScope';
import steps from '@/onboarding/steps';
import storage from '#/storage';

export const StatusNotification = ({
  getMe,
  provider,
  goLive,
  isLoading,
  goLiveFailure,
  goLiveResult,
  accounts,
  roles,
  licenses,
}) => {
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contactModalSubmitted, setContactModalSubmitted] = useState(false);

  const sendGoLiveRequest = () => {
    goLive({ provider_id: provider.id });
    setContactModalSubmitted(true);
  };

  const toggleContactModal = () => {
    if (contactModalOpen) {
      setContactModalOpen(false);
      getMe();
    } else {
      setContactModalOpen(true);
    }
  };

  // Only if the provider was created after the new onboarding wizard
  // and is an owner and clinician for this provider,
  // automatically return to the 'Create a profile' wizard for this user instead
  // of just sending them to the portal home page.
  const getCompleteProfileLinkTarget = () => {
    if (
      provider &&
      !storage.get(`exit-onboarding-wizard-provider-id-${provider.id}`) &&
      provider.custom_content &&
      _.get(provider, 'custom_content.created_after_new_onboarding_wizard') === true &&
      accounts &&
      roles &&
      provider &&
      licenses
    ) {
      const isOwner = hasOwnerScope(accounts, roles, _.get(provider, 'id'));
      if (isOwner) {
        // Calculate using steps.js which page to send to.
        for (let i = 0; i < steps.length; i++) {
          if (
            steps[i].createForPathPrefix &&
            steps[i].checkFn(provider, roles, accounts, licenses)
          ) {
            return `${steps[i].createForPathPrefix}/${provider.id}`;
          }
        }
      } else {
        return '/';
      }
    }

    return '/';
  };

  const contactForm = (
    <Form
      onSubmit={sendGoLiveRequest}
      validate={(v) => {
        if (_.isNil(v['ready'])) {
          return { ready: 'Gotta be ready!' };
        }
      }}
      render={(props) => (
        <div className='contact-modal'>
          <div className='select-items m-r-sm'>
            <Field name='ready'>
              {(props) => (
                <FormCheckbox
                  {...props}
                  label='I have reviewed my profile and am ready to go live!'
                />
              )}
            </Field>
            <br></br>
            <SubmitButton {...props} />
          </div>
        </div>
      )}
    />
  );

  if (!provider || provider.status === 'active') {
    return <></>;
  }

  // Hide incomplete status notification on primary dashboard page
  if (['incomplete'].includes(provider.status) && window.location.pathname === '/') {
    return <></>;
  }

  const url =
    provider.type === 'individual'
      ? `${process.env.ZENCARE_WEBSITE_URL}/provider/${_.get(
          provider,
          'primary_profession.slug'
        )}/${provider.slug}`
      : `${process.env.ZENCARE_WEBSITE_URL}/practice/${provider.slug}`;

  return (
    <>
      <div className={`status-notification ${provider.status}`}>
        {['locked'].includes(provider.status) && (
          <>
            Your profile is not live!{' '}
            <Link to='/contact'>Contact us to change your membership.</Link>{' '}
            <i className='far fa-check text-primary'></i>
          </>
        )}
        {['inactive'].includes(provider.status) && (
          <>
            Your profile is not live! <Link to='/plan-management'>Reactivate your membership</Link>.{' '}
            <i className='far fa-check text-primary'></i>
          </>
        )}
        {['incomplete'].includes(provider.status) && (
          <>
            Your profile is not live yet!{' '}
            <Link to={getCompleteProfileLinkTarget()}>Preview and go live</Link>.{' '}
            <i className='far fa-check text-primary'></i>
          </>
        )}
        {provider.status === 'reviewing' && (
          <>
            Your profile is ready to go live!{' '}
            <a target='_blank' rel='noopener noreferrer' href={url}>
              {' '}
              Review your profile
            </a>{' '}
            and make it live.
            <button className='pill primary' onClick={() => setContactModalOpen(true)}>
              Make profile live
            </button>
          </>
        )}
        {provider.status === 'pending' && (
          <>Your profile is not live yet. We're reviewing your request and will be in touch soon!</>
        )}
      </div>
      <ContactModal
        open={contactModalOpen}
        submitted={contactModalSubmitted}
        toggle={toggleContactModal}
        form={contactForm}
        headerContent={
          <h4 className='congratulations-header'>Congratulations, your profile is going live!</h4>
        }
        confirmationMessage={
          isLoading ? (
            'Working on it...'
          ) : goLiveFailure ? (
            <div>Server error. Please send email to hello@zencare.co to let us know.</div>
          ) : goLiveResult === 'went-live' ? (
            <>
              <h3>Woohoo!</h3>
              <p>
                Your profile is now live! You will receive a separate email regarding the details of
                your profile going live, plus membership benefits - let us know if you don't see it!
              </p>
              <p>Thanks so much, and welcome to the community!</p>
              <p>Yours,</p>
              <p>Zencare team</p>
            </>
          ) : (
            <>
              <h3>Woohoo!</h3>
              <p>
                Your profile will go live within 24 business hours, pending a verification of the
                license information you provided. You will receive a separate email regarding the
                details of your profile going live, plus membership benefits - let us know if you
                don't see it!
              </p>
              <p>Thanks so much, and welcome to the community!</p>
              <p>Yours,</p>
              <p>Zencare team</p>
            </>
          )
        }
      />
    </>
  );
};

const mapStateToProps = ({ main, auth }) => ({
  isLoading: main.isLoading,
  goLiveFailure: main.goLiveFailure,
  goLiveResult: main.goLiveResult,
  accounts: main.accounts,
  roles: auth.roles,
});
const actions = {
  getMe: mainActions.getMe,
  goLive: mainActions.goLive,
};

export default connect(mapStateToProps, actions)(StatusNotification);
