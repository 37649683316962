import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import FormCheckbox from '+/forms/FormCheckbox';
import { connect } from 'react-redux';
import { actions as mainActions } from '../../../main/sagaSlice';

const DESELECT_WARNING = 'Ensure at least one state is selected for each active insurance.';

const mapStateToProps = ({ main }: $TSFixMe) => ({
  provider: main.provider,
  insuranceOptOuts: main.insuranceOptOuts,
  isLoading: main.isLoading,
  locations: Object.values(main.locations),
});

const actions = {
  updateMultipleTaggings: mainActions.updateMultipleTaggings,
  getInsuranceOptOuts: mainActions.getInsuranceOptOuts,
  addInsuranceOptOuts: mainActions.addInsuranceOptOuts,
  removeInsuranceOptOuts: mainActions.removeInsuranceOptOuts,
};

const currentlySelectedInsurances = (
  provider: $TSFixMe,
  state: string,
  addInsuranceOptOuts: $TSFixMeFunction,
  removeInsuranceOptOuts: $TSFixMeFunction,
  preventDeselection: $TSFixMeFunction
) =>
  _.get(provider, 'insurances', [])
    .sort((a: Tag, b: Tag) => (a.name.trim() > b.name.trim() ? 1 : -1))
    .map((tag: Tag) => {
      const checkboxObjectData: InsuranceOptOutCheckboxObjectData = { state, tagId: tag.id };

      return (
        <div className='insurance' key={`state-${state}-tag-id-${tag.id}`}>
          <Field name={`state-${state}-tag-id-${tag.id}`}>
            {(props) => (
              <FormCheckbox
                {...props}
                dataAttributeObjectData={JSON.stringify(checkboxObjectData)}
                input={{
                  ...props.input,
                  // This will display checkboxes true to opt-out value
                  // There must be a cleaner way
                  value: !provider.insurance_opt_outs.some(
                    (oo: InsuranceOptOut) => oo.tag_id === tag.id && oo.state === state
                  ),
                }}
                label={tag.name}
                title={tag.search_enabled ? '' : 'Disabled because no open slots.'}
                disabled={!tag.search_enabled}
                mutator={(e: Event) => {
                  if (e.target) {
                    const clickedCheckboxObjectData: InsuranceOptOutCheckboxObjectData = JSON.parse(
                      // @ts-ignore-next-line
                      e.target.dataset.objectData
                    );
                    // @ts-ignore-next-line
                    if (e.target.checked) {
                      removeInsuranceOptOuts({
                        provider_id: provider.id,
                        tag_ids: [clickedCheckboxObjectData.tagId],
                        state: clickedCheckboxObjectData.state,
                      });
                    } else if (preventDeselection([clickedCheckboxObjectData.tagId], state)) {
                      alert(DESELECT_WARNING);
                    } else {
                      addInsuranceOptOuts({
                        provider_id: provider.id,
                        tag_ids: [clickedCheckboxObjectData.tagId],
                        state: clickedCheckboxObjectData.state,
                      });
                    }
                  }
                }}
              />
            )}
          </Field>
        </div>
      );
    });

interface Props {
  provider: Provider;
  updateMultipleTaggings: $TSFixMeFunction;
  locations: Location[];
  insuranceOptOuts: InsuranceOptOut[];
  getInsuranceOptOuts: $TSFixMeFunction;
  addInsuranceOptOuts: $TSFixMeFunction;
  removeInsuranceOptOuts: $TSFixMeFunction;
}

export const InsuranceByState = ({
  provider,
  locations,
  insuranceOptOuts,
  getInsuranceOptOuts,
  addInsuranceOptOuts,
  removeInsuranceOptOuts,
}: Props) => {
  const [optOutsLoadStarted, setOptOutsLoadStarted] = useState(false);

  useEffect(() => {
    if (!optOutsLoadStarted && provider) {
      getInsuranceOptOuts({ id: provider.id });
      setOptOutsLoadStarted(true);
    }
  }, [optOutsLoadStarted, provider, getInsuranceOptOuts]);

  const states = _.uniq(locations.map((x) => x.state));

  const initialValues: $TSFixMe = {};
  _.get(provider, 'insurances', []).forEach((tag: Tag) => {
    states.forEach((state) => {
      if (insuranceOptOuts.find((x) => x.tag_id === tag.id && x.state === state)) {
        initialValues[`state-${state}-tag-id-${tag.id}`] = false;
      } else {
        initialValues[`state-${state}-tag-id-${tag.id}`] = true;
      }
    });
  });

  // Prevent the deselection of the last remaining insurance by state for a given insurance.
  // The goal here is to encourage the behavior of indicating that a provider is not
  // accepting clients with the "open slots by insurance" toggles, as opposed to deselecting
  // said insurance in each state, while still keeping "open slots by insurance" toggled on
  const preventDeselection = (tags: number[], state: string) => {
    const outOfStateOptOuts = insuranceOptOuts.filter((oo) => oo.state !== state);
    let shouldPrevent = false;
    tags.forEach((tag) => {
      if (outOfStateOptOuts.filter((oo) => tag === oo.tag_id).length + 1 === states.length) {
        shouldPrevent = true;
      }
    });
    return shouldPrevent;
  };

  return (
    <div id='insurance-by-state' className='insurance-by-state'>
      <div>
        <Form
          initialValues={initialValues}
          onSubmit={() => {
            // Do nothing
          }}
          mutators={{
            setField: ([name, val], state, { changeValue }) => {
              changeValue(state, name, () => val);
            },
          }}
          render={(formProps) => (
            <>
              <div className='box m-b-sm'>
                <h3 className='m-b-0'>Insurance by State</h3>
                <p className='instruction'>
                  For each state you practice in, select the health insurance you are paneled with.
                </p>
                {states
                  .sort((a, b) => (a > b ? 1 : -1))
                  .map((state) => (
                    <>
                      <p className='m-t-md'>
                        <b>
                          {state}{' '}
                          <button
                            onClick={() => {
                              const tagIds = _.get(provider, 'insurances', []).map(
                                (x: Tag) => x.id
                              );

                              tagIds.forEach((tagId: number) => {
                                formProps.form.mutators.setField(
                                  `state-${state}-tag-id-${tagId}`,
                                  true
                                );
                              });

                              // It's ok to send items that are already checked
                              // server is smart enough to skip ones as needed.
                              removeInsuranceOptOuts({
                                provider_id: provider.id,
                                tag_ids: tagIds,
                                state: state,
                              });
                            }}
                            className='link md'
                          >
                            Select all
                          </button>{' '}
                          |{' '}
                          <button
                            onClick={() => {
                              const tagIds = _.get(provider, 'insurances', [])
                                .filter((x: Tag) => x.search_enabled)
                                .map((x: Tag) => x.id);

                              if (preventDeselection(tagIds, state)) {
                                return alert(DESELECT_WARNING);
                              }

                              tagIds.forEach((tagId: number) => {
                                formProps.form.mutators.setField(
                                  `state-${state}-tag-id-${tagId}`,
                                  false
                                );
                              });

                              // It's ok to send items that are already unchecked
                              // server is smart enough to skip ones as needed.
                              addInsuranceOptOuts({
                                provider_id: provider.id,
                                tag_ids: tagIds,
                                state: state,
                              });
                            }}
                            className='link md'
                          >
                            Deselect all
                          </button>
                        </b>
                      </p>

                      <div className='insurance-by-state-insurances'>
                        {currentlySelectedInsurances(
                          provider,
                          state,
                          addInsuranceOptOuts,
                          removeInsuranceOptOuts,
                          preventDeselection
                        )}
                      </div>
                    </>
                  ))}
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

// @ts-ignore-next-line
export default connect(mapStateToProps, actions)(InsuranceByState);
