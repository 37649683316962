import React from 'react';
import PracticeAppointmentView from './PracticeAppointmentView';

function PracticeAppointments(props: $TSFixMe) {
  return (
    <>
      <h1>Practice Inbox | Appointments</h1>
      <PracticeAppointmentView />
    </>
  );
}

export default PracticeAppointments;
