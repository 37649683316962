import React from 'react';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';
import useSortable from '+/hooks/useSortable';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import { regex as TextAreaRegex } from '@/provider/profile/MessageStatement/constants';

const SortableTagForm = ({
  onEdit,
  dataList = [],
  columns,
  beforeAdd = () => new Promise((resolve) => resolve()),
  beforeDelete = () => new Promise((resolve) => resolve()),
  name,
  defaultNewItem = {
    name: '',
    description: '',
  },
  addText = 'Custom Treatment Approach',
}) => {
  const initialValues = {
    [name]: dataList.map((d, i) => ({
      ...d,
      oldIdx: i,
    })),
  };

  const [listRef, mutators, resort] = useSortable(name);

  /*
   * If there has been any sorting, we want to use the value of sorted. Otherwise we use the form values.
   * Filter out any empty entries.
   */
  function onEditWrapper(formValues) {
    let values = formValues[name];
    // filter out any values with no value for a required column
    values = values.filter((v) =>
      _.every(columns.map((c) => c.key).forEach((key) => !_.isNil(v[key]) && v[key].length > 0))
    );
    //Before submit, remove all properties which aren't required columns
    values.forEach((v) => {
      Object.keys(v).forEach((k) => {
        if (!columns.find((c) => c.key === k)) {
          delete v[k];
        }
      });
    });

    return onEdit(values);
  }

  /*
   * Prevent any nil or empty strings
   */
  const validate = (values) => {
    const errors = {};
    values[name].forEach((v, i) => {
      columns.forEach((c) => {
        const str = v[c.key];
        if (_.isNil(str) || !str.trim().length) {
          _.set(errors, `${name}[${i}].${c.key}`, `${c.placeholder} required`);
        }

        if (c.maxlength && !_.isNil(str) && str.length > c.maxlength) {
          _.set(errors, `${name}[${i}].${c.key}`, `Max length reached (${c.maxlength} characters)`);
        }

        if (
          !_.isNil(str) &&
          (TextAreaRegex.phone.test(str) ||
            TextAreaRegex.email.test(str) ||
            TextAreaRegex.url.test(str))
        ) {
          _.set(
            errors,
            `${name}[${i}].${c.key}`,
            'Please do not include URLs, phone numbers, or email addresses in this field!'
          );
        }
      });
    });

    return errors;
  };

  /*
   * Renders fields based on column structure.
   */
  const renderFields = (values) =>
    values.map((v, i) => (
      <div className='sortable-tag-form-field-group box no-mobile' key={v.oldIdx} handle={v.oldIdx}>
        {
          <div className='handle'>
            <i className='fas fa-ellipsis-v'></i>
            <i className='fas fa-ellipsis-v'></i>
          </div>
        }
        {columns.map(({ key, type, placeholder, maxlength }) => (
          <div className={`${key} sortable-tag-form-field`}>
            {key === 'name' && v.nameReadOnly ? (
              <div className='fixed-name'>
                <span>{v[key]}</span>
              </div>
            ) : (
              <>
                <Field
                  name={`${name}[${i}].${key}`}
                  component={type}
                  placeholder={placeholder}
                  initialValue={v[key]}
                  maxLength={maxlength + 1}
                />
                <ErrorField name={`${name}[${i}].${key}`} />
              </>
            )}
          </div>
        ))}
        <div
          className='delete'
          role='presentation'
          onClick={() => {
            beforeDelete().then(() => {
              mutators.current.remove(i);
            });
          }}
        >
          <i className='far fa-minus-circle'></i>
        </div>
      </div>
    ));

  return (
    <Form
      onSubmit={onEditWrapper}
      mutators={{
        resort,
        add: ([newItem], state, { changeValue }) => {
          changeValue(state, name, (oldVal) => [...oldVal, newItem]);
        },
        remove: ([index], state, { changeValue }) => {
          changeValue(state, name, (oldVal) => {
            const arr = [...oldVal];
            arr.splice(index, 1);

            return arr;
          });
        },
      }}
      initialValues={initialValues}
      initialValuesEqual={() => true}
      validate={validate}
      render={({ handleSubmit, values, valid, pristine, form }) => {
        mutators.current = form.mutators;

        return (
          <>
            <div className='sortable-tag-form'>
              <div className='sortable' ref={listRef}>
                {renderFields(values[name])}
              </div>
              <div
                className='add'
                role='presentation'
                onClick={() => {
                  beforeAdd(values).then((newItem) => {
                    if (!newItem) {
                      newItem = _.clone(defaultNewItem);
                    }
                    form.mutators.add(newItem);
                  });
                }}
              >
                <i className='far fa-plus-circle'></i>
                Add {addText}
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              valid={valid}
              handleSubmit={handleSubmit}
              submittable={!_.isEqual(values, initialValues)}
            />
          </>
        );
      }}
    />
  );
};

export default SortableTagForm;
