/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import Input from '+/forms/Input';
import validate from './formValidation';
import useQueryParams from '+/hooks/useQueryParams';
import { Link } from 'react-router-dom';
import { defaultLinks, zpmStandaloneLinks } from './constants';

interface Props {
  loginVerifyTwoFactor: $TSFixMeFunction;
  twoFactorCodeNeeded: boolean;
  phoneLastFour: string;
  login: $TSFixMeFunction;
  email: string | null;
  fromClientPortal: boolean;
  loginErrorReservedName?: string | null;
}

interface LoginParams {
  email: string;
  password: string;
}

const LoginForm = (props: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const mode = useQueryParams().get('mode');
  const links = initializeLinks();

  function initializeLinks() {
    switch (mode) {
      case 'zpm_standalone':
        return zpmStandaloneLinks;
      default:
        return defaultLinks;
    }
  }
  useEffect(() => {}, [mode]);

  const iv: { email?: string } = {};
  if (props.email) {
    iv.email = props.email;
  }
  const {
    loginErrorReservedName,
    loginVerifyTwoFactor,
    twoFactorCodeNeeded,
    phoneLastFour,
    login,
  } = props;

  const id = 'login';

  const submitLogin = (params: LoginParams) => {
    // We need to ensure that we only submit username/password (not 2FA code)
    // to login endpoint
    const loginParams = {
      email: params?.email,
      password: params?.password,
    };
    login(loginParams);
  };

  const onSubmit = (params: LoginParams) => {
    if (twoFactorCodeNeeded) {
      loginVerifyTwoFactor(params);
    } else {
      submitLogin(params);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={iv}
      validate={validate(login)}
      render={({ handleSubmit, values }) => (
        <form id={id} className='auth-form p-x-lg' onSubmit={handleSubmit}>
          <h4 className='text-center'>
            {twoFactorCodeNeeded ? 'Verify Your Account' : 'Welcome to Zencare! Log in here'}
          </h4>
          <div className='fields' style={{ display: twoFactorCodeNeeded ? 'none' : 'block' }}>
            <Field
              name='email'
              component={Input}
              label={<span className='semi-bold heading-5'>Email</span>}
            />
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label={<span className='semi-bold heading-5'>Password</span>}
              name='password'
            />
          </div>
          <div
            className='checkbox'
            style={{ display: twoFactorCodeNeeded ? 'none' : 'block', marginBottom: '1rem' }}
          >
            <input
              type='checkbox'
              id='showPassword'
              onChange={(e) => setShowPassword(e.target.checked)}
            />
            <label htmlFor='showPassword'>
              <span className='time-of-day'>Show Password</span>
            </label>
          </div>

          {loginErrorReservedName && loginErrorReservedName === 'INVALID_EMAIL_OR_PASSWORD' ? (
            <div className='text-error'>
              Incorrect email or password. Please check your details and try again or{' '}
              <a href='/login/help' className='underline'>
                reset your password
              </a>
              .
            </div>
          ) : (
            ''
          )}

          {loginErrorReservedName && loginErrorReservedName === 'TOO_MANY_ATTEMPTS' ? (
            <div className='text-error'>
              There have been too many attempts. Please try again later.
            </div>
          ) : null}
          {twoFactorCodeNeeded && (
            <>
              <div>
                <p>
                  Please confirm your account by entering the secure access code sent to ***-***-
                  {phoneLastFour}.
                </p>
                <p>
                  We'll ask you for this code every 30 days. Contact us at{' '}
                  <a href='mailto:support@zencare.co' rel='noopener noreferrer' target='_blank'>
                    support@zencare.co
                  </a>{' '}
                  if you're having any issues.
                </p>
                <Field
                  divStyles='w-100'
                  inputStyle='w-100'
                  name='token'
                  component={Input}
                  placeholder='Secure access code'
                />
              </div>
              <div className='m-t-sm'>
                <button
                  className='link'
                  onClick={(e) => {
                    e.preventDefault();
                    submitLogin(values);
                    setSuccessMessage('New code sent!');

                    return false;
                  }}
                >
                  Request new code
                </button>

                {successMessage && <span className='m-l-sm text-success'>{successMessage}</span>}
              </div>
            </>
          )}
          <div className={`tip-dialog ${props.fromClientPortal && 'show'}`}>
            <span>Tip:</span> Log in with the password you used on
            <a
              href={`${process.env.ZENCARE_WEBSITE_URL}/login`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <span> Zencare.co/login</span>
            </a>
            !
          </div>
          <div className='footer flex align-start wrap'>
            <button className='primary m-t-lg m-r-lg' type='submit'>
              {twoFactorCodeNeeded ? 'Submit' : 'Log in'}
            </button>
          </div>

          {!twoFactorCodeNeeded && (
            <div className='flex column'>
              <hr className='w-100' />
              {links.map((l) => (
                <p className='m-b-xs' key={l.link}>
                  <span className='semi-bold'>{l.text}</span>
                  <span>{l.additionalText}</span>
                  {l.internal ? (
                    <Link to={l.link}>{l.linkText}</Link>
                  ) : (
                    <a
                      className='semi-bold'
                      target='_blank'
                      rel='noopener noreferrer'
                      href={l.link}
                    >
                      {l.linkText}
                    </a>
                  )}
                </p>
              ))}
            </div>
          )}
        </form>
      )}
    />
  );
};

export default LoginForm;
