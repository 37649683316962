import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import providerName from '#/providerName';
import PracticeManagementCta from './PracticeManagementCta';

import { TopNav, NavGroup, NavLink, Avatar, NavDropdown } from '@zencare/ui';
import storage from '#/storage';

import { actions } from '@/auth/sagaSlice';
import { INTAKE_EXPORTER_ROLE, INTAKE_COORDINATOR_ROLE } from '~/components/auth/constants';

interface Props {
  toggleSideNav: $TSFixMeFunction;
  status: boolean | string;
  overrideLogoLink?: boolean;
}

export const TopNavContainer: React.FC<Props> = ({
  toggleSideNav,
  status,
  overrideLogoLink = false,
}) => {
  const dispatch = useDispatch();
  const { provider, accounts, allProviders } = useSelector((state: $TSFixMe) => state.main);
  const { hasUnreadMessages } = useSelector((state: $TSFixMe) => state.messages);
  const { roles } = useSelector<State, AuthState>((state) => state.auth);

  const location = useLocation();
  const { pathname } = location;
  const [menuShowing, toggleMenu] = useState(false);
  const imageSrc = _.get(provider, 'image.key')
    ? `https://assets.zencare.co/${encodeURIComponent(
        _.get(provider, 'image.key')
      )}?w=250&h=250&q=70`
    : require('~/assets/placeholder.png');

  const isStaging = process.env.ZENCARE_ENV === 'staging';

  const hasPracticeManagementAccount = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.practiceManagement.hasPracticeManagementAccount
  );

  const firstPracticeManagementAccount = useSelector(
    // @ts-ignore-next-line $TSFixMe
    (state) => state.practiceManagement.firstPracticeManagementAccount
  );

  const isOnboarding = pathname.includes('/signup');
  const lsAuth = storage.get('auth');

  const practiceManagementDomainName: string | undefined =
    process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME;

  const hasPracticeInboxAccess = roles
    ?.filter((roles) => roles.account_id === provider.account_id)
    // Creates an array of specific roles attached to the user
    .map((r) => r.role)
    // Checks to see if any of the roles include the ability to access the practice inbox
    .includes(INTAKE_COORDINATOR_ROLE);

  const hasReferralDataAccess = roles
    ?.filter((roles) => roles.account_id === provider.account_id)
    .map((r) => r.role)
    .includes(INTAKE_EXPORTER_ROLE);

  return (
    <TopNav bg={isStaging ? 'primary' : ''}>
      <Link
        className='brand-no-cors-zencare pointer text-center'
        to={overrideLogoLink ? location : '/'}
      />
      {/* eslint-disable-next-line */}
      {!toggleSideNav ? (
        <div></div>
      ) : (
        <div
          className='mobile-menu'
          role='button'
          onClick={() => toggleSideNav()}
          onKeyDown={() => toggleSideNav()}
          tabIndex={0}
        >
          <i className='fal fa-bars'></i>
        </div>
      )}
      {!!lsAuth && (
        <NavGroup>
          {isOnboarding && <PracticeManagementCta className='m-r-sm' />}
          {!isOnboarding && (
            <a href='/messages'>
              <button className={`nav-messages ${hasUnreadMessages ? 'bold' : ''}`} type='button'>
                <i className='fal fa-envelope nav-messages-icon'>
                  {hasUnreadMessages ? <span className='unread'></span> : null}
                </i>
                Messages
              </button>
            </a>
          )}
          <NavDropdown>
            <NavLink
              onClick={() => toggleMenu(!menuShowing)}
              className='profile'
              avatarBefore={true}
              avatar={
                <Avatar
                  xxs
                  title={providerName(provider, false)}
                  key={_.get(provider, 'image.key', 'asdfasdf')}
                  src={imageSrc}
                />
              }
            >
              <div className='name'>{providerName(provider, false)}</div>
              <i className='fas fa-angle-down'></i>
            </NavLink>
            <div className={`menu ${menuShowing ? '' : 'hidden'}`}>
              {hasPracticeManagementAccount && firstPracticeManagementAccount && (
                <NavLink
                  target='_blank'
                  icon='clipboard-list-check'
                  rel='noopener noreferrer'
                  href={`https://${firstPracticeManagementAccount.subdomain}.${practiceManagementDomainName}/`}
                >
                  Practice Management
                </NavLink>
              )}
              {provider && status && (
                <NavLink icon='cog' component={Link} to='/account-preferences'>
                  Account Settings{' '}
                </NavLink>
              )}
              {!isOnboarding &&
                accounts &&
                accounts.filter((a: $TSFixMe) => !a.error).length > 0 && (
                  <NavLink icon='user-plus' component={Link} to='/profiles/create'>
                    Create New Profile
                  </NavLink>
                )}
              {!isOnboarding && (
                <NavLink icon='money-check-alt' component={Link} to='/plan-management'>
                  Plan Management
                </NavLink>
              )}
              {allProviders && allProviders.length > 1 && (
                <NavLink icon='user-friends' component={Link} to='/profiles'>
                  Switch Profile
                </NavLink>
              )}
              {hasReferralDataAccess && (
                <NavLink icon='table' component={Link} to='/referral-data'>
                  Referral Data
                </NavLink>
              )}
              {hasPracticeInboxAccess && (
                <NavLink icon='mail-bulk' component={Link} to='/practice-inbox'>
                  Practice Inbox
                </NavLink>
              )}
              <NavLink
                icon='sign-out'
                component={Link}
                to='/login'
                className='logout'
                onClick={(e: Event) => {
                  e && e.preventDefault();
                  dispatch(actions.logout());
                }}
              >
                Log Out
              </NavLink>
            </div>
          </NavDropdown>
        </NavGroup>
      )}
    </TopNav>
  );
};

export default TopNavContainer;
