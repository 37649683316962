import * as React from 'react';
import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import storage from '#/storage';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import ErrorLayout from '##/Error';
import Routes from './routes';

import { getState } from './store';
import { actions as authActions } from '@/auth/sagaSlice';
import { getQueryAsObject } from './utils/queryParams';
import initMixpanel from './mixpanel.js';

import '../src/styles/index.scss';

try {
  Bugsnag.start({
    // @ts-ignore
    apiKey: process.env.ZENCARE_BUGSNAG_CLIENT,
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.BUILD_NUM,
    releaseStage: process.env.ZENCARE_ENV || process.env.NODE_ENV || 'dev',
  });
  initMixpanel();
} catch (e) {}

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

// An example of using "any" type in a place where typing would drastically slow down development
// We can come back to $TSFixMe types and remove aliases down the line
const App = () => {
  const [autoSwitchedProvider, setAutoSwitchedProvider] = useState(false);
  const isSignedIn = useSelector((state) => _.get(state, 'auth.isSignedIn'));
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutFn: $TSFixMe;

    const activityFn = () => {
      dispatch((authActions as $TSFixMe).setShouldRefreshSession(true));
    };

    const activityEvents = ['mousemove', 'mousedown', 'touchstart', 'click', 'scroll', 'keypress'];

    const debounceActivityFn = () => {
      clearTimeout(timeoutFn);
      timeoutFn = setTimeout(activityFn, 5000);
    };

    for (const event of activityEvents) {
      window.addEventListener(event, debounceActivityFn);
    }

    return () => {
      for (const event of activityEvents) {
        window.removeEventListener(event, debounceActivityFn);
      }
    };
  }, []);

  const urlParams = getQueryAsObject(window.location.search);
  if (isSignedIn && !autoSwitchedProvider) {
    const providerIdUrlParam = urlParams.provider_id;
    if (providerIdUrlParam) {
      setAutoSwitchedProvider(true);
      storage.set('current_provider_id', parseInt(providerIdUrlParam));
    }
  }

  // Create a loop to check sessions every minute
  if (isSignedIn) {
    dispatch(authActions.checkSessionStart());
  }

  return (
    <Switch>
      {Routes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          exact={route.exact}
          render={(props) => {
            const state = getState();

            if (route.auth && !_.get(state, 'auth.isSignedIn')) {
              console.log('Going to redirect!');
              const email = urlParams.email;
              let search = '';
              if (email) {
                search = `?email=${email}`;
              }

              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    search,
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }

            if (route.redirect) {
              console.log('route.redirect is set');
              return (
                <Redirect
                  to={{
                    pathname: route.redirect,
                  }}
                />
              );
            }

            if (!route.layout) {
              route.component = withRouter(route.component);

              return <route.component {...props} />;
            }

            route.layout = withRouter(route.layout);

            global.document.title = `${route.title || route.path} | Zencare`;

            return (
              <route.layout {...props} route={route}>
                <route.component {...props} route={route} />
              </route.layout>
            );
          }}
        />
      ))}
      <Route render={ErrorLayout} />
    </Switch>
  );
};

export default App;
