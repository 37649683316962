import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as reimbursifyActions } from '@/reimbursify/sagaSlice';
import { redirectToReimbursify } from '~/components/reimbursify/reimbursifyUtils';
import hasFeatureFlag from '../../utils/hasFeatureFlag';
import hasReimbursifySsoRole from '~/utils/hasReimbursifySsoRole';
import {
  ACCOUNT_ENABLE_SSO_UPGRADE_CURRENT_REIMBURSIFY,
  HIDE_REIMBURSIFY_SSO_UPGRADE,
} from '~/shared/constants/featureFlags';

interface Props {}

export const ReimbursifyCta: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { provider, accounts } = useSelector((state: $TSFixMe) => state.main);
  const { roles } = useSelector((state: $TSFixMe) => state.auth);
  const [existingReimbursifyAccountCheckRequired, setExistingReimbursifyAccountCheckRequired] =
    useState(false);
  const [existingReimbursifyAccountCheckStarted, setExistingReimbursifyAccountCheckStarted] =
    useState(false);

  const [existingReimbursifyAccountPassed, setExistingReimbursifyAccountPassed] = useState(false);

  useEffect(() => {
    if (!existingReimbursifyAccountCheckStarted && existingReimbursifyAccountCheckRequired) {
      setExistingReimbursifyAccountCheckStarted(true);
      const sagaParam: CheckIfStandaloneReimbursifyAccountAlreadyExistsSagaParam = {
        params: {
          provider_id: provider.id,
        },
        onSuccess: (alreadyHasStandaloneReimbursifyAccount: boolean) => {
          if (!alreadyHasStandaloneReimbursifyAccount) {
            setExistingReimbursifyAccountPassed(true);
          }
        },
      };

      // @ts-expect-error - NOTE: Need to look at example Jake made for typing this with JSDoc
      dispatch(reimbursifyActions.checkIfStandaloneReimbursifyAccountAlreadyExists(sagaParam));
    }
  }, [
    dispatch,
    existingReimbursifyAccountCheckRequired,
    existingReimbursifyAccountCheckStarted,
    provider.id,
  ]);

  // Note: accounts and thus account will be null for anyone who is not an owner, reimbursify-lead, practitioner, reimbursify-practitioner, or reimbursify-administrator
  const account: Account = accounts.find((a: Account) => a.id === parseInt(provider.account_id));

  if (provider && !hasReimbursifySsoRole(roles, provider.account_id)) {
    return null;
  }

  if (hasFeatureFlag('account', account, HIDE_REIMBURSIFY_SSO_UPGRADE)) {
    return null;
  }

  // If Reimbursify account isn't yet created, don't show SSO button if no paid subscription appears
  // to be associated with the account
  if (
    // Wait for account info to load before ever showing SSO button
    account
  ) {
    // Now make sure there is something paid for or a Reimbursify account was already created in this Zencare account.
    if (
      !account.reimbursify_account_created &&
      !account.legacy_or_seat_based_subscription_is_good
    ) {
      return null;
    } else {
      // If Reimbursify account isn't yet created, first make sure there is not already a standalone Reimbursify account.
      // We want to hide the SSO button if that is the case.
      if (!existingReimbursifyAccountCheckRequired && !account.reimbursify_account_created) {
        setExistingReimbursifyAccountCheckRequired(true);
      }
    }
  } else {
    // Account info not yet loaded
    return null;
  }

  // If there is a feature flag to show SSO button even if someone already has Reimbursify account,
  // don't actually hide if already has existing Reimbursify account
  if (!hasFeatureFlag('account', account, ACCOUNT_ENABLE_SSO_UPGRADE_CURRENT_REIMBURSIFY)) {
    // If we must check for an existing Reimbursify account, don't show SSO button until we confirm that there is not already a non-SSO account
    if (existingReimbursifyAccountCheckRequired && !existingReimbursifyAccountPassed) {
      return null;
    }
  }

  const handleButtonClick = () => performSsoLogin();

  const performSsoLogin = () => {
    const sagaParam: ReimbursifyGenerateSsoJwtSagaParam = {
      params: {
        provider_id: provider.id,
      },
      onSuccess: redirectToReimbursify,
    };

    // @ts-expect-error - NOTE: Need to look at example Jake made for typing this with JSDoc
    dispatch(reimbursifyActions.reimbursifyGenerateSsoJwt(sagaParam));
  };

  return (
    <button onClick={handleButtonClick} className='m-t-sm primary md pill nav-cta'>
      Log in to Private Pay Booster
    </button>
  );
};

export default ReimbursifyCta;
