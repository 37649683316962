import React from 'react';
import { Field } from 'react-final-form';
import FormCheckbox from '~/shared/forms/FormCheckbox';
import ErrorField from '~/shared/forms/ErrorField';

function TermsOfService() {
  return (
    <>
      <Field name='terms-of-service'>
        {(props) => (
          <FormCheckbox
            fieldClassName='m-r-xs'
            {...props}
            label={
              <p className='terms-of-service'>
                I agree to the Zencare{' '}
                <a rel='noopener noreferrer' target='_blank' href='https://zencare.co/policy/terms'>
                  Terms Of Service
                </a>
                . I understand that <b>no refunds are issued</b>.
              </p>
            }
          />
        )}
      </Field>
      <ErrorField name='terms-of-service' />
    </>
  );
}

export default TermsOfService;
