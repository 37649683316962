import React from 'react';
import { Button } from '@zencare/ui';
import PureModal from '+/Modal';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';
import { imgix } from './helpers';

const ProfileSelectModal = (
  { open, toggle, onSubmit, images, currentImage, modalHeader, modalDescription } //eslint-disable-line
) => (
  <PureModal
    visible={open}
    headerContent={<h4>{modalHeader}</h4>}
    footerContent={
      <div className='actions'>
        <button
          onClick={() => {
            toggle(false);
          }}
          className='hollow'
        >
          Cancel
        </button>
        <button
          onClick={() => {
            const button = document.querySelector(
              '.profile-select-modal-interior .actions button.primary'
            );
            if (button) {
              button.click();
            }
          }}
          className='primary'
        >
          Submit
        </button>
      </div>
    }
    closeModal={() => toggle(false)}
  >
    <Form
      initialValues={{ handle: _.get(currentImage, 'handle') }}
      initialValuesEqual={() => true}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, valid, values }) => (
        <div className='profile-select-modal-interior'>
          <h5>{modalDescription}</h5>
          <div className='gallery'>
            {images.map((p) => (
              <label htmlFor={p.handle}>
                <Field
                  name='handle'
                  value={p.handle}
                  type='radio'
                  component='input'
                  id={p.handle}
                />
                <img
                  className={`handle gallery-photo border-primary m-b-xs ${
                    values.handle === p.handle ? '' : 'o-3'
                  }`}
                  alt='1'
                  src={imgix(p)}
                ></img>
                {values.handle === p.handle && (
                  <span className='option-selected'>
                    <i className='fas text-primary fa-check-circle'></i>
                  </span>
                )}
              </label>
            ))}
          </div>
          <div className='actions'>
            {!pristine && !valid ? (
              <Button className='primary' onClick={handleSubmit} disabled>
                Submit
              </Button>
            ) : (
              <Button className='primary' onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </div>
        </div>
      )}
    />
  </PureModal>
);

export default ProfileSelectModal;
