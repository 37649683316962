export const tabOptions = {
  'In-Person': false,
  'Online Sessions': true,
};

export const availabilityTabOptions = {
  Yes: 40,
  Waitlist: 20,
  No: 0,
};

export const days = [
  {
    name: 'monday',
    short_name: 'Mon',
    very_short_name: 'M',
  },
  {
    name: 'tuesday',
    short_name: 'Tue',
    very_short_name: 'T',
  },
  {
    name: 'wednesday',
    short_name: 'Wed',
    very_short_name: 'W',
  },
  {
    name: 'thursday',
    short_name: 'Thur',
    very_short_name: 'T',
  },
  {
    name: 'friday',
    short_name: 'Fri',
    very_short_name: 'F',
  },
  {
    name: 'saturday',
    short_name: 'Sat',
    very_short_name: 'S',
  },
  {
    name: 'sunday',
    short_name: 'Sun',
    very_short_name: 'S',
  },
];
