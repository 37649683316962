import React from 'react';
import { connect } from 'react-redux';
import TabSelect from './TabSelect';

import { actions as mainActions } from '../../../main/sagaSlice';

export const AvailabilityUpdatesContainer = ({ updateAvailability, location }) => {
  const chooseAvailabilityStatus = () => {
    switch (location.accepting_patients) {
      case 40:
        return 'accepting';

      case 20:
        return 'waitlist';

      case 0:
        return 'not_accepting';

      default:
        break;
    }
  };

  const onSubmit = (value) => {
    let accepting_patients;

    switch (value) {
      case 'accepting':
        accepting_patients = 40;
        break;

      case 'not_accepting':
        accepting_patients = 0;
        break;

      case 'waitlist':
        accepting_patients = 20;
        break;

      default:
        accepting_patients = 0;
        break;
    }

    updateAvailability({
      id: location.id,
      accepting_patients,
    });
  };

  return (
    <div className='tab-container m-b-sm'>
      <p className='m-b-sm secondary-text'>{location.location_name}</p>
      <TabSelect
        location={location}
        initialState={chooseAvailabilityStatus()}
        onSubmit={onSubmit}
      />
    </div>
  );
};

const actions = { updateAvailability: mainActions.updateAvailability };

export default connect(null, actions)(AvailabilityUpdatesContainer);
