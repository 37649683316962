/* eslint-disable max-lines-per-function */
import React from 'react';
import { Link } from 'react-router-dom';

export const BlogFeatures = () => (
  <div
    id='static-page'
    style={{
      maxWidth: '1080px',
      margin: '0 auto',
    }}
  >
    <h1>Therapist Interview Features</h1>
    <h3>Share your expertise with prospective clients.</h3>
    <p>
      If you would like an interview feature on the Zencare blog, please let us know by clicking
      here. We are excited to share your practice and expertise with our readership and beyond!
    </p>
    <a rel='noopener noreferrer' target='_blank' href='https://forms.gle/PRALuknwtkY4KdWF8'>
      <button className='lg pill primary block m-b-lg'>Get featured on the blog</button>
    </a>
    <img
      style={{
        height: '30rem',
        width: '100%',
        objectFit: 'cover',
      }}
      alt='cover'
      src='https://images.squarespace-cdn.com/content/v1/563a0909e4b03923f8ec4b09/1550192885468-XPZKHMPMKGH6DXCIWJ14/ke17ZwdGBToddI8pDm48kLzP0XzJiNxuFi6hF79w0EoUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcbUCVIi5dO6wG_M3JJRqZ0OG-zV542ywbG2kWIw8gKb6WzsS0aYqJxHq3AzfrZeKH/image-asset.jpeg?format=1500w'
    />

    <h3>How It Works </h3>
    <ol style={{ listStyle: 'decimal' }}>
      <li>
        <b>Initial contact:</b> Interested in highlighting your expertise on the Zencare blog? Let
        us know by filling out this form! The Zencare content team may also reach out to you
        directly regarding submissions and collaborations, too.
      </li>
      <li>
        <b>Publication and promotion:</b> We will let you know when your content is published!
        Unless you explicitly request otherwise, your article may be promoted via our newsletter
        and/or social media in the months following publication, and your quotes may be included in
        other articles on our blog or partner publications we write for.
      </li>
    </ol>

    <h3>Guidelines </h3>
    <ul>
      <li>
        <b>Avoid jargon. </b>Most readers will not have significant knowledge of therapy modalities
        (e.g. psychodynamic, humanistic, narrative therapy) or mental health conditions. Try to
        avoid terms that your non-therapist friends would not know, or if you do mention them,
        explain what they mean (e.g. “anorexia, an eating disorder that involves restriction of food
        intake”).
      </li>
      <li>
        <b>Write like you are speaking to a client or friend. </b> Avoid academic sounding writing.
        (e.g. instead of “depressive episodes,” use “feeling depressed”). Content should be
        conversational, rather than professorial.
      </li>
    </ul>

    <h3>Therapist Feature Examples </h3>
    <ul>
      <li>
        You can read through{' '}
        <a
          rel='noopener noreferrer'
          target='_blank'
          href='https://blog.zencare.co/tag/therapist-interview/'
        >
          other Zencare therapist interviews
        </a>{' '}
        for reference (and inspiration!).{' '}
      </li>
    </ul>

    <h3>Basics</h3>
    <ul>
      <li>Zencare blog features are an exclusive benefit for Zencare therapist members.</li>
      <li>Content may be published and/or cross-promoted on other partner publications.</li>
      <li>Content is owned by Zencare.</li>
    </ul>

    <h3>Frequently Asked Questions</h3>
    <ul>
      <li>
        <b>Who owns this content? </b> Content is owned by Zencare Group Inc. (“Zencare”). Quotes
        and/or sections from the article may be pulled to be used on other related articles by
        Zencare, published on the Zencare blog and/or on partner publications.
      </li>
      <li>
        <b>Can I publish my submitted content on other publications?</b> No, upon submitting your
        content, you indicate that you understand Zencare claims full ownership, and that you are
        not permitted to submit the content to other publications.{' '}
      </li>
      <li>
        <b>Can I publish my submitted content on my own website?</b> Yes, you are welcome to share
        this content on your own website (personal or group practice), or blog. However, please
        indicate: “This article was originally published on Zencare.co” and include the link to the
        original article.{' '}
      </li>
      <li>
        <b>Will my article be featured in the Zencare newsletter? </b> Depending on the topic and
        our editorial calendar, we may include the article in our user newsletter. We will let you
        know if we are planning to promote it in our newsletter.{' '}
      </li>
      <li>
        <b>Will you tag me on social media?</b> Yes, if you have a public account and would like to
        be tagged. We promote content on social media, so if you’d like to be tagged, please let us
        know the appropriate handle(s) for each platform (Facebook, Twitter, Instagram)!
      </li>
      <li>
        <b>Can I share on social media?</b> Yes. You are also welcome to tag Zencare on social media
        as well. Our handles are: zencareco (Twitter), Zencare.co (Facebook), zencare.co
        (Instagram).{' '}
      </li>
      <li>
        <b>What happens to my content if I choose to cancel my Zencare membership?</b> Content
        published on the Zencare blog will stay on the website even if you cancel your membership.
      </li>
      <li>
        <b>Will my content ever be taken down?</b> Zencare content typically stays on the blog for
        years; however, we reserve the right to remove content as needed.
      </li>
      <li>
        <b>Can I add my post to my profile?</b> Yes, this feature is coming soon!!
      </li>
    </ul>

    <p>
      Got more questions? <Link to='/contact'>Contact us!</Link>
    </p>
  </div>
);
