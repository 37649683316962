/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { Form, Field } from 'react-final-form';
import { actions } from './sagaSlice';
// import { actions as messageActions } from './messages/sagaSlice';
import PureModal from '+/Modal';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import SubmitButton from '~/shared/forms/SubmitButton';
import TimeSelector from '../../../shared/forms/TimeSelector';
import { militaryTimeTo12HourTime, twelveHourTimeToMilitaryTime } from '#/timeUtils';
import providerName from '#/providerName';

interface Props {
  practiceInbox?: boolean;
}

const intakeKeyMapping: { [key: string]: string } = {
  state: 'What state are you based in?',
  session_type: 'What type of session are you interested in?',
  insurance: 'What health insurance plan do you have?',
  message: "What's on your mind?",
};

export default function AppointmentModal({ practiceInbox }: Props) {
  const dispatch = useDispatch();
  const appointment =
    useSelector<State, AppointmentsState['currentAppointment']>(
      (state) => state.appointments.currentAppointment
    ) || {};
  const provider = useSelector<State, MainState['provider']>((state) => state.main.provider);
  const calendar = _.get(provider, 'calendar[0]');
  const tz = useSelector((state) =>
    calendar && calendar.timezone ? calendar.timezone : _.get(state, 'calendars.tz')
  );
  const open = !!appointment.id;
  const minDay = DateTime.local({ zone: tz }).toFormat('yyyy-LL-dd');
  const maxDay = DateTime.local({ zone: tz }).plus({ days: 90 }).toFormat('yyyy-LL-dd');
  const endTime = DateTime.fromISO(appointment.end_time).setZone(tz);
  const legacy = appointment.legacy;
  const [action, setAction] = useState<$TSFixMe | null>(null);
  const history = useHistory();
  const cancelInProgress =
    useSelector<State, AppointmentsState['cancelInProgress']>(
      (state) => state.appointments.cancelInProgress
    ) || false;
  const cancelError =
    useSelector<State, AppointmentsState['cancelError']>(
      (state) => state.appointments.cancelError
    ) || null;
  const rescheduleInProgress =
    useSelector<State, AppointmentsState['rescheduleInProgress']>(
      (state) => state.appointments.rescheduleInProgress
    ) || false;
  const { conversations } = useSelector<State, MessagesState>((state) => state.messages);
  const { conversations: practiceInboxConversations } = useSelector<State, PracticeInboxState>(
    (state) => state.practiceInbox
  );
  // const { id: provider_id } = useSelector((state) => state.main.provider);
  const rescheduleError =
    useSelector<State, AppointmentsState['rescheduleError']>(
      (state) => state.appointments.rescheduleError
    ) || null;
  const [showMessageButton, setShowMessageButton] = useState(false);

  // Logic to determine if the message button should be displayed
  useEffect(() => {
    // Check that there are conversations, no reschedule or cancel action is taking place
    // and that the appointment creator has a user account
    if (!conversations || action !== null || !appointment.client_user_id) {
      return setShowMessageButton(false);
    }
    // check that the user has started a conversation, that there's something to link to
    const validId = practiceInbox
      ? practiceInboxConversations.some((obj) => obj.id === appointment.client_user_id)
      : conversations.some((obj) => obj.id === appointment.client_user_id);
    if (validId) {
      return setShowMessageButton(true);
    }
  }, [action, appointment, conversations]);

  const rescheduleSuccess =
    useSelector<State, AppointmentsState['rescheduleSuccess']>(
      (state) => state.appointments.rescheduleSuccess
    ) || false;
  let startTime = DateTime.utc();
  if (appointment) {
    startTime = DateTime.fromISO(appointment.start_time).setZone(tz);
  }

  const closeModal = () => {
    setAction(null);
    dispatch(actions.closeAppointment());
  };

  const submitReschedule = (values: $TSFixMe) => {
    if (!values.time || !values.date) {
      return;
    }

    const duration = endTime.diff(startTime, 'minutes');

    const militaryStartTime = twelveHourTimeToMilitaryTime(values.time);
    const newStart = DateTime.fromISO(`${values.date}T${militaryStartTime}:00.000`, { zone: tz });
    const newEnd = newStart.plus(duration);

    dispatch(
      // @ts-ignore
      actions.rescheduleAppointment({
        id: appointment.id,
        reschedule_message: values.message,
        start_time: newStart.toUTC().toISO(),
        end_time: newEnd.toUTC().toISO(),
      })
    );
  };

  const submitCancel = (values: $TSFixMe) => {
    dispatch(
      // @ts-ignore
      actions.cancelAppointment({
        id: appointment.id,
        cancel_message: values.message,
      })
    );
  };

  const handleMessageRedirect = () => {
    dispatch(actions.closeAppointment());
    if (appointment.client_user_id) {
      if (practiceInbox) {
        return history.push(
          `/practice-inbox/messages/${appointment.client_user_id}/${appointment.provider.id}`
        );
      }
      return history.push(`/messages/${appointment.client_user_id}`);
    }
    return history.push('/messages');
  };

  return (
    <PureModal
      visible={open}
      additionalCssClasses='appointment-modal'
      closeModal={closeModal}
      headerContent={
        <div>
          <h6 className='m-b-0'>
            {appointment.type === 'provider_contact' ? 'Contact Message' : 'Phone Consultation'}
          </h6>
          <h5>
            {startTime.toLocaleString({
              weekday: 'long',
              month: 'long',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
            ({startTime.toFormat('ZZZZ')})
          </h5>
          {appointment.links && (
            <div>
              Add to calendar:{' '}
              <a target='_blank' rel='noopener noreferrer' href={appointment.links.google}>
                Google
              </a>
              &nbsp;|&nbsp;
              <a target='_blank' rel='noopener noreferrer' href={appointment.links.outlook}>
                Outlook
              </a>
              &nbsp;|&nbsp;
              <a target='_blank' rel='noopener noreferrer' href={appointment.links.ics}>
                iCal
              </a>
            </div>
          )}
        </div>
      }
    >
      <>
        {!!appointment.canceled && <p className='text-error'>This appointment was canceled.</p>}
        {/* Only display provider name for modern appointments within the practice inbox */}
        {practiceInbox && !appointment.legacy && (
          <div className='p-b-sm'>
            <h5>Provider Information</h5>
            <p>
              <b>Name: </b>
              {providerName(appointment.provider)}
            </p>
          </div>
        )}
        <div className='flex wrap justify-between'>
          <div>
            <h5>Client Information</h5>
            <p>
              <b>First Name:</b> {_.get(appointment, 'user.first_name')}
            </p>
            <p>
              <b>Last Name:</b> {_.get(appointment, 'user.last_name')}
            </p>
          </div>
          <div>
            <h5>Contact Information</h5>
            <div className='flex wrap w-100 justify-between'>
              <span className='m-r-sm m-b-xs'>
                {_.get(appointment, 'user.phone_number') ? (
                  <a
                    id='appt-user-phone-link'
                    href={`tel:${_.get(appointment, 'user.phone_number')}`}
                  >
                    <i className='fal fa-phone text-primary m-r-sm'></i>
                    {_.get(appointment, 'user.phone_number')}
                  </a>
                ) : (
                  '(Phone number unavailable)'
                )}
              </span>
            </div>
            <div className='flex wrap w-100 justify-between'>
              <span className='m-b-xs m-r-sm'>
                <a id='appt-user-email-link' href={`mailto:${_.get(appointment, 'user.email')}`}>
                  <i className='fal fa-envelope text-primary m-r-sm'></i>
                  {_.get(appointment, 'user.email')}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className={`appointment-action-buttons ${action === null ? '' : 'active'}`}>
          {showMessageButton && (
            <button onClick={handleMessageRedirect} className='primary md'>
              Message
            </button>
          )}
          {!legacy &&
            action === null &&
            !appointment.canceled &&
            DateTime.local({ zone: tz }) < endTime && (
              <>
                <button
                  disabled={cancelInProgress || rescheduleInProgress}
                  onClick={() => setAction('reschedule')}
                  className='quaternary md'
                >
                  Reschedule
                </button>
                <button
                  disabled={cancelInProgress || rescheduleInProgress}
                  onClick={() => setAction('cancel')}
                  className='error md'
                >
                  Cancel
                </button>
              </>
            )}
          {action === 'cancel' && (
            <div className='w-100'>
              <p>Are you sure you'd like to cancel this appointment?</p>
              <Form
                initialValues={{
                  message: '',
                }}
                onSubmit={submitCancel}
                render={(props) => (
                  <>
                    <Field name='message'>
                      {({ input }) => (
                        <input
                          {...input}
                          className='w-100 m-b-sm m-t-sm'
                          type='textarea'
                          placeholder={'Write a message explaining why you are canceling.'}
                        ></input>
                      )}
                    </Field>
                    {cancelInProgress && <div className='flex wrap'>Canceling...</div>}
                    {cancelError && (
                      <div className='flex wrap'>
                        <div className='text-error'>{cancelError}</div>
                      </div>
                    )}
                    {rescheduleInProgress && <div className='flex wrap'>Rescheduling...</div>}
                    {rescheduleError && (
                      <div className='flex wrap'>
                        <div className='text-error'>{rescheduleError}</div>
                      </div>
                    )}
                    <div className='flex wrap'>
                      <SubmitButton
                        buttonClass='error sm m-r-sm'
                        buttonText='Cancel this appointment'
                        {...props}
                        pristine={false}
                      />
                      <button onClick={() => setAction(null)} className='hollow sm'>
                        Keep this appointment
                      </button>
                    </div>
                  </>
                )}
              />
            </div>
          )}
          {action === 'reschedule' && (
            <div className='w-100'>
              {rescheduleSuccess ? (
                <div className='text-success'>Reschedule successful!</div>
              ) : (
                <>
                  <p>Pick a time to reschedule this appointment to:</p>
                  <Form
                    initialValues={{
                      time: militaryTimeTo12HourTime(startTime.toFormat('HH:mm')),
                      date: DateTime.fromISO(appointment.start_time)
                        .setZone(tz)
                        .toFormat('yyyy-LL-dd'),
                    }}
                    mutators={{
                      setField: ([name, val], state, { changeValue }) => {
                        changeValue(state, name, () => val);
                      },
                    }}
                    onSubmit={submitReschedule}
                    validate={(values) => {
                      const errors: $TSFixMe = {};
                      if (!values.time) {
                        errors.time = 'Time required';
                      }
                      const militaryStartTime = twelveHourTimeToMilitaryTime(values.time);
                      if (!militaryStartTime) {
                        errors.startTime = 'Start time invalid. Use 1:00pm format';
                      }
                      if (!values.date) {
                        errors.date = 'Date required';
                      }
                      return errors;
                    }}
                    render={(props) => (
                      <>
                        <div className='flex wrap'>
                          <div className='flex column m-r-md' style={{ flex: '0 0 160px' }}>
                            <h6 className='m-b-0'>Date</h6>
                            <Field name='date'>
                              {({ input }) => (
                                <input {...input} type='date' min={minDay} max={maxDay} />
                              )}
                            </Field>
                          </div>
                          <div className='flex column' style={{ flex: '0 0 160px' }}>
                            <h6 className='m-b-0'>Time ({startTime.toFormat('ZZZZ')})</h6>
                            <Field name='time'>
                              {(fieldProps) => (
                                <TimeSelector
                                  fieldProps={fieldProps}
                                  setValueFromDropdownSelection={(newValue: $TSFixMe) => {
                                    props.form.mutators.setField('time', newValue);
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        <Field name='message'>
                          {({ input }) => (
                            <input
                              {...input}
                              className='w-100 m-b-sm m-t-sm'
                              type='textarea'
                              placeholder={'Write a message explaining why you are rescheduling.'}
                            ></input>
                          )}
                        </Field>
                        <div className='flex wrap'>
                          <SubmitButton
                            buttonClass='quaternary sm m-r-sm'
                            buttonText='Reschedule this appointment'
                            {...props}
                            pristine={false}
                          />
                          <button onClick={() => setAction(null)} className='hollow sm'>
                            Keep this appointment
                          </button>
                        </div>
                      </>
                    )}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <hr></hr>
        <div>
          <h5>Intake Information</h5>
          {Object.keys(appointment.intake_form || {}).map((key) => {
            const question = intakeKeyMapping[key] || key;
            const answer = (appointment.intake_form || {})[key] || <i>No response</i>;
            if (['agree_to_terms', 'hear_about_us', 'hear_about_us_metadata'].includes(key)) {
              return null;
            }
            return (
              <>
                <h6 className='m-b-0'>{question}</h6>
                <p className='m-b-md intake-answer'>{answer}</p>
              </>
            );
          })}
        </div>
      </>
    </PureModal>
  );
}
