import React from 'react';

export default (props) => {
  const {
    label,
    labelClassName,
    labelStyle = {},
    fieldClassName,
    disabled,
    disabledTooltip = '',
    title = '',
    input,
    mutator,

    // Optional data to store in the DOM attribute 'data-object-data'
    // Accessible from the mutator callback using JSON.parse(e.target.dataset.objectData)
    dataAttributeObjectData = {},
  } = props;

  const labelFor = `${typeof label === 'string' ? label.split(' ').join('') : ''}checkbox-${
    input.name
  }`;

  return (
    <div title={title} className={`field ${fieldClassName ? fieldClassName : ''}`}>
      <div className={`mx-md checkbox ${disabled ? 'disabled-checkbox' : ''}`}>
        <input
          type='checkbox'
          id={labelFor}
          data-object-data={dataAttributeObjectData}
          checked={input.value}
          onChange={(e) => {
            if (disabled) {
              // eslint-disable-next-line no-console
              console.log('Disabled');
            } else {
              mutator && mutator(e);
              input.onChange(e.target.checked);
            }
          }}
        />
        <label
          data-for='checkbox-tooltip'
          data-tip={disabled ? disabledTooltip : ''}
          style={labelStyle}
          className={`checkbox ${labelClassName ? labelClassName : ''}`}
          htmlFor={labelFor}
        >
          {label}
        </label>
      </div>
    </div>
  );
};
