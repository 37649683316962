/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actions as tagActions } from '../../taggings/sagaSlice';
import useSortable from '+/hooks/useSortable';
import SubmitButton from '+/forms/SubmitButton';
import PhotoUploader from '+/PhotoUploader';
import { Form, Field } from 'react-final-form';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import ErrorField from '+/forms/ErrorField';

const UnregisteredProviderForm = ({ readEveryDegree, degrees, providers, submit }) => {
  const name = 'unregistered';
  const [listRef, mutators, resort] = useSortable(name);

  useEffect(() => {
    readEveryDegree();
  }, [readEveryDegree]);

  const initialValues = {
    [name]: providers.map((p, oldIdx) => ({
      ...p,
      oldIdx,
    })),
  };

  const beforeDelete = () =>
    new Promise((resolve, reject) => {
      window.confirm("Are you sure you'd like to delete this provider?") ? resolve() : reject();
    });

  const beforeImageAdd = () =>
    new Promise((resolve, reject) => {
      window.confirm(
        "Are you sure you want to change your provider's image? Once you submit the form, the current image will be deleted and not retrievable."
      )
        ? resolve()
        : reject();
    });

  const addableMutators = {
    add: ([newItem], state, { changeValue }) => {
      changeValue(state, name, (oldVal) => [...oldVal, newItem]);
    },
    remove: ([index], state, { changeValue }) => {
      changeValue(state, name, (oldVal) => {
        const arr = [...oldVal];
        arr.splice(index, 1);

        return arr;
      });
    },
  };

  const degreeData = Array.isArray(degrees.data) ? degrees.data : [];

  const NO_DEGREE = -99;

  const degreeOptions = [
    {
      id: NO_DEGREE,
      value: undefined,
      name: '',
    },
  ]
    .concat(degreeData)
    .map((d) => (
      <option key={d.id} value={d.id}>
        {d.name}
      </option>
    ));

  const onSubmit = (values) =>
    submit(
      _.clone(values[name]).map((x) => {
        const degree = degreeData.find((d) => d.id === parseInt(x.degree));
        if (degree) {
          x.degrees = [degree];
        } else {
          delete x.degrees;
        }

        delete x.degree;
        delete x.oldIdx;

        return x;
      })
    );

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        resort,
        ...addableMutators,
        addImage: ([name, imageDetails], state, { changeValue }) => {
          changeValue(state, name, (v) => imageDetails);
        },
      }}
      validate={(values) => {
        const errors = {};

        values[name].forEach((p, i) => {
          if (!p.first_name) {
            _.set(errors, `${name}[${i}].first_name`, 'First name required');
          }

          if (!p.last_name) {
            _.set(errors, `${name}[${i}].last_name`, 'Last name required');
          }

          if ((!p.degree || parseInt(p.degree) === NO_DEGREE) && !p.title) {
            _.set(errors, `${name}[${i}].title`, 'Either degree or position is required.');
            _.set(errors, `${name}[${i}].degree`, 'Either degree or position is required.');
          }

          if (!_.get(p, 'image.key')) {
            _.set(errors, `${name}[${i}].image`, 'Image required.');
          }
        });

        return errors;
      }}
      initialValues={initialValues}
      initialValuesEqual={() => true}
      render={({ handleSubmit, values, form, submitFailed }) => {
        mutators.current = form.mutators;

        return (
          <>
            <div className='sortable-tag-form'>
              <div className='sortable flex column' ref={listRef}>
                {values[name].map((v, i) => (
                  <div
                    className='sortable-tag-form-field-group lg box no-mobile'
                    key={v.oldIdx}
                    handle={v.oldIdx}
                  >
                    <div className='handle'>
                      <i className='fas fa-ellipsis-v'></i>
                      <i className='fas fa-ellipsis-v'></i>
                    </div>
                    <div className='flex column align-center m-r-sm'>
                      <img
                        className='avatar border-primary md m-l-md m-b-sm m-r-sm'
                        src={imgix(v.image)}
                        alt={v.first_name}
                      />
                      {/* Note: Too small/grayscale logging won't be enabled since not passing providerId and logEvent props */}
                      <PhotoUploader
                        preventGrayscale={true}
                        minSizeInKB={100}
                        buttonClass='pill primary change-image-button'
                        onSuccess={(image) => {
                          beforeImageAdd().then(() => {
                            form.mutators.addImage(`${name}[${i}].image`, image);
                          });
                        }}
                      />
                      <ErrorField name={`${name}[${i}].image`} />
                    </div>

                    <div className='unregistered-provider-grid w-100 h-100 align-center'>
                      <div>
                        <h5>First name</h5>
                        <Field
                          className='m-r-sm'
                          name={`${name}[${i}].first_name`}
                          component='input'
                          placeholder='First name'
                          initialValue={v.first_name}
                        />
                        <ErrorField className='absolute' name={`${name}[${i}].first_name`} />
                      </div>
                      <div>
                        <h5>Middle name</h5>
                        <Field
                          className='m-r-sm'
                          name={`${name}[${i}].middle_name`}
                          component='input'
                          placeholder='Middle name'
                          initialValue={v.middle_name}
                        />
                      </div>

                      <div>
                        <h5>Last name</h5>
                        <Field
                          className='m-r-sm'
                          name={`${name}[${i}].last_name`}
                          component='input'
                          placeholder='Last name'
                          initialValue={v.last_name}
                        />
                        <ErrorField className='absolute' name={`${name}[${i}].last_name`} />
                      </div>
                      <div>
                        <h5>Degree</h5>
                        <Field
                          className='m-r-sm'
                          style={{ width: '90%' }}
                          name={`${name}[${i}].degree`}
                          component='select'
                          type='select'
                          initialValue={_.get(v, 'degrees[0].id')}
                        >
                          <option value={''} disabled selected hidden>
                            Select degree
                          </option>
                          {degreeOptions}
                          <i className='fas fa-caret-down'></i>
                        </Field>
                      </div>

                      <div>
                        <h5>Position</h5>
                        <Field
                          className='m-r-sm'
                          name={`${name}[${i}].title`}
                          component='input'
                          placeholder='e.g. Intake Coordinator'
                          initialValue={v.title}
                        />
                        <ErrorField className='absolute' name={`${name}[${i}].title`} />
                      </div>
                    </div>
                    <div className='delete'>
                      <i
                        role='presentation'
                        onClick={() => {
                          beforeDelete().then(() => {
                            form.mutators.remove(i);
                          });
                        }}
                        className='far fa-minus-circle'
                      ></i>
                    </div>
                  </div>
                ))}
              </div>
              {submitFailed && (
                <p className='help text-error'>Please fill out all of the required fields.</p>
              )}
            </div>
            <SubmitButton
              pristine={false}
              valid={true}
              handleSubmit={handleSubmit}
              submittable={!_.isEqual(values, initialValues)}
            />
          </>
        );
      }}
    />
  );
};

const mapStateToProps = ({ degrees }) => ({
  degrees,
});

const actions = {
  readEveryDegree: tagActions.degrees.readEvery,
};

export default connect(mapStateToProps, actions)(UnregisteredProviderForm);
