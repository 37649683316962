export const CLINICIAN_ROLE = 'clinician';
export const OWNER_ROLE = 'owner';
// Provides access to Practice Inbox
export const INTAKE_EXPORTER_ROLE = 'intake-exporter';
// Provides access to CSV Export tool
export const INTAKE_COORDINATOR_ROLE = 'intake-coordinator';

// Provides access to page that includes Practice Tools (currently only has CSV export widget)
// Note: Account Dashboard only exists in development as now the Referral Data page exists alone
export const ACCOUNT_DASHBOARD_ROLES = [INTAKE_EXPORTER_ROLE];

export const defaultLinks: Array<LoginFormLink> = [
  {
    text: 'Trouble logging in? ',
    linkText: 'Reset your password!',
    link: '/login/help',
    internal: true,
  },
  {
    text: 'New provider to Zencare? ',
    linkText: 'Get started today!',
    link: '/signup',
    internal: true,
  },
  {
    text: 'Are you a client? ',
    linkText: 'Log in here.',
    link: `${process.env.ZENCARE_WEBSITE_URL}/login`,
    internal: true,
  },
];
export const zpmStandaloneLinks: Array<LoginFormLink> = [
  {
    text: 'Questions? ',
    additionalText: 'Reach out to our dedicated support team at ',
    linkText: 'support@zencarepractice.com',
    link: 'mailto:support@zencarepractice.com',
  },
];
