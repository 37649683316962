/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import { actions as mainActions } from '@/main/sagaSlice';
import { actions as onboardingActions } from './sagaSlice';
import PhotoUploader from '+/PhotoUploader';
import { imgix } from '@/provider/profile/PhotosVideo/helpers.js';
import Loader from '+/forms/SectionLoader';
import _ from 'lodash';
import PhotoVideoshootIframe from '@/onboarding/PhotoVideoshootIframe';
import providerHasSubscriptionInAccount from '#/providerHasSubscriptionInAccount';
import StartMembershipButton from './StartMembershipButton';

const PhotosForm = ({
  onboarding,
  submitOnboardingPhotos,
  createForSubmitOnboardingPhotos,
  getMe,
  getAllAccountDetails,
  logEvent,
  providerId,
  main,
}) => {
  const routerParams = useParams();

  useEffect(() => {
    getMe();
    getAllAccountDetails();
  }, [getMe, getAllAccountDetails]);

  const accounts = _.get(main, 'accounts', []).filter((a) => !a.error);

  const provider =
    main.allProviders.find((p) => p.id === providerId) ||
    _.flatten(accounts.map((a) => a.providers)).find((p) => p.id === providerId) ||
    main.provider;

  if (!provider) {
    return 'Loading...';
  }

  const onSubmit = (values) => {
    const payload = {
      provider_id: provider.id,
      image: values.image,
    };

    // If in 'create for' mode inside portal
    if (routerParams.providerId) {
      createForSubmitOnboardingPhotos(payload);
    } else {
      submitOnboardingPhotos(payload);
    }
  };

  if ((onboarding && onboarding.isLoading) || !provider) {
    return (
      <div key='info-form'>
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      </div>
    );
  }

  const isPractice = provider.type === 'practice';
  const isIndividual = provider.type === 'individual';
  const hasSubscription =
    (provider && provider.hasPaidPlan) ||
    (accounts && accounts.some((a) => providerHasSubscriptionInAccount(provider, a)));

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        setField: ([name, val], state, { changeValue }) => {
          changeValue(state, name, () => val);
        },
      }}
      validate={(values) => {
        const errors = {};
        if (!values.image) {
          errors.image = 'Please upload a headshot.';
        }

        return errors;
      }}
      render={(props) => (
        <div key='info-form'>
          <div className='box m-b-md'>
            {isIndividual && (
              <h2 className='m-b-sm'>
                Upload your headshot<>*</>
              </h2>
            )}
            {isPractice && (
              <h2 className='m-b-sm'>
                Upload a headshot to represent your practice<>*</>
              </h2>
            )}
            <div className='field m-b-md w-100'>
              {isIndividual && (
                <>
                  <p className='description'>
                    Upload a headshot to use for your profile photo until your professional
                    photoshoot. Your profile photo is a client's first impression of your practice,
                    so we suggest choosing a photo with a warm smile!
                  </p>
                </>
              )}
              {isPractice && (
                <>
                  <p className='description'>
                    Upload a headshot to use for your profile photo until your professional
                    photoshoot. We recommend a headshot of the head clinician of your practice with
                    a warm smile, not a logo or group photo.
                  </p>
                </>
              )}
              {props.values.image && (
                <img
                  className='avatar border-primary md'
                  src={imgix(props.values.image)}
                  alt='headshot'
                />
              )}
              <p className='m-b-0 m-t-sm'>Click the button below to upload a new profile photo.</p>
              <p>
                <b>
                  Once you select a photo, you will have the{' '}
                  <a
                    href='https://support.zencare.co/help/how-to-crop-headshot-profile-photo'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='underline'
                  >
                    option to crop and center your image
                  </a>{' '}
                  <i>before</i> hitting "Upload."
                </b>
              </p>
              <PhotoUploader
                providerId={provider.id}
                logEvent={logEvent}
                preventGrayscale={true}
                minSizeInKB={100}
                buttonClass={`pill primary ${props.values.image ? 'hollow' : ''} md`}
                buttonText={props.values.image ? 'Change photo' : 'Upload photo'}
                onSuccess={(image) => props.form.mutators.setField('image', image)}
              />
              <ErrorField name='image' />
              You can replace this with headshots from your professional photoshoot later!
            </div>
          </div>
          {hasSubscription && (
            <div className='box m-b-md m-b-md' style={{ height: '90vh' }}>
              <h2 className='m-b-sm'>Book your Zencare photo/videoshoot (optional)</h2>
              <p>You can schedule this later, too.</p>
              <PhotoVideoshootIframe type='new-photoshoot' />
            </div>
          )}
          <div className='w-100 justify-center m-t-lg'>
            <div className='w-100 flex justify-center'>
              <ErrorField name='image' />
            </div>
            <div className='flex justify-center'>
              {/* Note: We need to pass provider id as prop 
            in so we can set it to id of one being created */}
              <StartMembershipButton providerId={providerId} />
              <SubmitButton
                buttonClass='lg primary pill'
                buttonText='Save + Preview Profile'
                {...props}
                submittable={true}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default connect(
  ({ onboarding, main }) =>
    //eslint-disable-next-line
    ({ onboarding, main }),
  {
    submitOnboardingPhotos: onboardingActions.submitOnboardingPhotos,
    createForSubmitOnboardingPhotos: onboardingActions.createForSubmitOnboardingPhotos,
    getMe: mainActions.getMe,
    getAllAccountDetails: mainActions.getAllAccountDetails,
    logEvent: mainActions.logEvent,
  }
)(PhotosForm);
