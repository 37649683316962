/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Contact from './Contact';
import AvailabilityUpdates from './AvailabilityUpdates';
import LocationView from './LocationView/index';
import { actions as mainActions } from '../../../main/sagaSlice';
import _ from 'lodash';
import { dispatch } from '~/store';
import { flashError } from '~/shared/flashes/redux';
import DeleteLocationModal from './DeleteLocationModal';
import AddEditLocationModal from './AddEditLocationModal';
import NudgeInsurancesAddedModal from './NudgeInsurancesAddedModal';
import NudgeInsurancesRemovedModal from './NudgeInsurancesRemovedModal';
import EditBox from '+/EditBox';
import PlainTextArea from '+/forms/PlainTextArea';
import { getPlainText } from '../MessageStatement/helpers';
import { regex as TextAreaRegex } from '../MessageStatement/constants';
import ConfirmHoursModal from './ConfirmHoursModal';
import stateAbbrDict from '~/utils/stateAbbrDict';
import VacationResponder from './VacationResponder';

interface Props {
  provider: $TSFixMe;
  contactZencare: $TSFixMe;
  locations: $TSFixMe;
  updateProvider: $TSFixMe;
  createLocationWithLicense: $TSFixMe;
  deleteLocation: $TSFixMe;
  location: $TSFixMe;
  getLicenses: $TSFixMe;
  licenses: $TSFixMe;
}

export const Home = ({
  provider,
  contactZencare,
  locations,
  updateProvider,
  createLocationWithLicense,
  deleteLocation,
  location,
  getLicenses,
  licenses,
}: Props) => {
  const justConfirmed = location.hash === '#confirmed';

  locations = locations || [];

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hoursModalOpen, setHoursModalOpen] = useState(false);
  const [nudgeInsurancesAddedModalOpen, setNudgeInsurancesAddedModalOpen] = useState(false);
  const [nudgeInsurancesRemovedModalOpen, setNudgeInsurancesRemovedModalOpen] = useState(false);
  const [statesRemoved, setStatesRemoved] = useState('');
  const [editingNotices, setEditingNotices] = useState(false);

  useEffect(() => {
    if (provider && provider.id) {
      getLicenses({ id: provider.id });
    }
  }, [provider, getLicenses]);

  useEffect(() => {
    if (location && justConfirmed) {
      setHoursModalOpen(true);
      const div = document.querySelector('#practice-locations-header');
      if (div) {
        div.scrollIntoView();
      }
    }
  }, []);
  const availabilityNotice = _.get(provider, 'custom_content.availability_notes', '');

  /*
    const onRemoteSessionsSubmit = value => {
        updateProvider({
            id: provider.id,
            custom_content: value
        });
    };
    */
  interface Location {
    id: number;
    remote_location: boolean;
    address: string;
    ada_accessible: boolean;
    state: keyof typeof stateAbbrDict;
  }

  interface SubmitLocationPayload {
    location: Location;
    provider_id: number;
  }

  const onSubmitLocation = (payload: SubmitLocationPayload) => {
    if (payload.location.remote_location) {
      payload.location.address = '';
      payload.location.ada_accessible = true;
    }
    payload.provider_id = provider.id;
    const alreadyHadLocationInState =
      locations.filter((x: Location) => x.state === stateAbbrDict[payload.location.state]).length >
      0;
    createLocationWithLicense(payload);
    setAddModalOpen(false);

    // If provider has just added location in a new state and has selected insurances already
    // encourage them to go update the list of insurances that they take.
    if (!alreadyHadLocationInState && _.get(provider, 'insurances', []).length > 0) {
      setNudgeInsurancesAddedModalOpen(true);
    }
  };

  const onDeleteLocation = (values: $TSFixMe) => {
    const locationsIdsToDelete = Object.keys(values)
      .filter((k) => values[k] === true)
      .map((k) => k.split('-')[1]);

    if (locationsIdsToDelete.length >= locations.length) {
      return dispatch(flashError('You must have at least one location.'));
    }

    if (locationsIdsToDelete.length === 0) {
      return dispatch(flashError('You must select one or more locations to delete.'));
    }

    if (
      window.confirm(
        "Are you sure you'd like to delete these locations? All information about these locations will be removed from your profile."
      )
    ) {
      const statesFullyRemoved: $TSFixMe[] = [];

      const locationsToDelete: $TSFixMe[] = [];
      locationsIdsToDelete.forEach((locationId) => {
        locationsToDelete.push(locations.find((x: Location) => x.id === parseInt(locationId)));
      });

      locationsToDelete.forEach((location) => {
        const numLocationsInStateToDelete = locationsToDelete.filter(
          (x) => x.state === location.state
        ).length;
        const numTotalLocationsInState = locations.filter(
          (x: Location) => x.state === location.state
        ).length;
        // If we're deleting all the locations in this state, list them as fully removed.
        if (numLocationsInStateToDelete === numTotalLocationsInState) {
          if (!statesFullyRemoved.includes(location.state)) {
            statesFullyRemoved.push(location.state);
          }
        }

        deleteLocation({ id: location.id });
      });

      // If provider has just removed all locations in at least one state and has selected insurances already
      // encourage them to go update the list of insurances that they take.
      if (statesFullyRemoved.length > 0 && _.get(provider, 'insurances', []).length > 0) {
        let statesRemovedString = '';
        statesFullyRemoved.forEach((state, index) => {
          if (statesFullyRemoved.length > 1 && index === statesFullyRemoved.length - 1) {
            statesRemovedString += ' and ';
          } else if (index !== 0) {
            statesRemovedString += ', ';
          }
          statesRemovedString += state;
        });
        setStatesRemoved(statesRemovedString);
        setNudgeInsurancesRemovedModalOpen(true);
      }
    }

    setDeleteModalOpen(false);
  };

  const inPersonLocations = locations.filter((l: Location) => !l.remote_location);
  const onlineLocations = locations.filter((l: Location) => l.remote_location);
  const hasCalendar = provider.calendar && provider.calendar.length;

  const noCalenderAvailabilityEditors = (
    <>
      {inPersonLocations.length > 0 &&
        inPersonLocations.map((location: Location, i: number) => (
          <React.Fragment key={`toggle-${location.id}`}>
            <h3>In-Person</h3>
            <div key={i}>
              <AvailabilityUpdates location={location} />
            </div>
          </React.Fragment>
        ))}
      {onlineLocations.length > 0 &&
        onlineLocations.map((location: Location, i: number) => (
          <React.Fragment key={`toggle-${location.id}`}>
            <h5 className='m-t-md m-b-xs'>Online Therapy</h5>
            <div key={i}>
              <AvailabilityUpdates location={location} />
            </div>
          </React.Fragment>
        ))}
    </>
  );

  const hasCalenderAvailabilityEditors = (
    <div className='location-availability-container'>
      {locations.length > 0 &&
        locations.map((location: Location, i: number) => (
          <div className='location-availability-child' key={`toggle-${location.id}`}>
            <h5 className='m-t-sm m-b-0'>
              {location.remote_location ? 'Online Therapy' : 'In-Person'}
            </h5>
            <div key={i}>
              <AvailabilityUpdates location={location} />
            </div>
          </div>
        ))}
    </div>
  );

  const noticesSection = (
    <EditBox
      title='Notices'
      boxClassName='w-100'
      description='Share additional details about your availability or new practice offerings.'
      editable={true}
      editing={editingNotices}
      setEditing={setEditingNotices}
      innerStatic={
        availabilityNotice && <p className='announcement'>{getPlainText(availabilityNotice)}</p>
      }
      innerEditing={
        <PlainTextArea
          name='availability_notes'
          placeholder='Write your availability notice here.'
          validate={(values) => {
            const errors: $TSFixMe = {};
            const str = values.availability_notes;

            if (str && str.length > 200) {
              errors.availability_notes = 'Please keep your note under 200 characters!';
            }

            if (
              TextAreaRegex.phone.test(str) ||
              TextAreaRegex.email.test(str) ||
              TextAreaRegex.url.test(str)
            ) {
              errors.availability_notes =
                'Please do not include URLs, phone numbers, or email addresses in your availability notice!';
            }

            return errors;
          }}
          initText={availabilityNotice}
          submit={(availability_notes: $TSFixMe) => {
            updateProvider({
              id: provider.id,
              custom_content: {
                availability_notes,
              },
            });
            setEditingNotices(false);
          }}
        />
      }
    />
  );

  return (
    <div>
      <div className='m-b-md'>
        <h1 className='m-b-md title'>Availability &amp; Locations</h1>
      </div>
      <div className='m-b-md availability-container'>
        <div className='box mobile-border-bottom w-100'>
          <h3 className='m-b-0'>Availability Updates</h3>
          <p className='secondary-text'>Are you accepting new clients?</p>
          {hasCalendar ? hasCalenderAvailabilityEditors : noCalenderAvailabilityEditors}
        </div>
      </div>
      <VacationResponder />
      {hasCalendar ? (
        <div className='m-b-sm availability-container'>
          <div className='contact-announcements w-100'>{noticesSection}</div>
        </div>
      ) : (
        <div className='contact-announcements'>
          <Contact />
          {noticesSection}
        </div>
      )}

      <div id='practice-locations'>{/* for links to this section */}</div>
      <div className='m-b-sm' id='practice-locations-header'>
        <h2 className='m-b-0 m-t-lg title'>Practice Locations</h2>
      </div>
      <div>
        {locations.map((location: Location) => (
          <div key={location.id}>
            <LocationView
              provider={provider}
              licenses={licenses}
              location={location}
              existingLocations={locations}
            />
          </div>
        ))}
      </div>
      {hoursModalOpen && <ConfirmHoursModal open={hoursModalOpen} toggle={setHoursModalOpen} />}
      {addModalOpen && (
        <AddEditLocationModal
          open={addModalOpen}
          toggle={setAddModalOpen}
          onSubmit={onSubmitLocation}
          existingLocations={locations}
          licenses={licenses}
          location={undefined}
          isEdit={undefined}
        />
      )}
      {deleteModalOpen && (
        <DeleteLocationModal
          open={deleteModalOpen}
          toggle={setDeleteModalOpen}
          onSubmit={onDeleteLocation}
          locations={provider.locations}
        />
      )}
      {nudgeInsurancesAddedModalOpen && (
        <NudgeInsurancesAddedModal
          open={nudgeInsurancesAddedModalOpen}
          toggle={setNudgeInsurancesAddedModalOpen}
        />
      )}
      {nudgeInsurancesRemovedModalOpen && (
        <NudgeInsurancesRemovedModal
          open={nudgeInsurancesRemovedModalOpen}
          toggle={setNudgeInsurancesRemovedModalOpen}
          statesRemoved={statesRemoved}
        />
      )}

      <button
        onClick={() => {
          setAddModalOpen(true);
        }}
        className='primary pill md m-r-xs m-b-xs'
      >
        <i className='fal fa-plus-circle m-r-xs'></i>
        Add New Location
      </button>
      <button
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        className='pill md m-r-xs'
      >
        <i className='fal fa-minus-circle m-r-xs'></i>
        Remove Location
      </button>
    </div>
  );
};

const mapStateToProps = ({ auth, main }: $TSFixMe) => ({
  provider: main.provider,
  isLoading: main.isLoading,
  locations: Object.values(main.locations),
  licenses: main.licenses,
});

const actions = {
  getMe: mainActions.getMe,
  updateProvider: mainActions.updateProvider,
  createLocationWithLicense: mainActions.createLocationWithLicense,
  deleteLocation: mainActions.deleteLocation,
  getLicenses: mainActions.getLicenses,
  contactZencare: mainActions.contactZencare,
};

export default connect(mapStateToProps, actions)(Home);
