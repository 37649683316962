/**
 * Check if field has error
 * @param {object} meta Final Form field state
 */
export const fieldHasError = (meta) => {
  const { error, visited, modified, submitFailed } = meta;

  // Don't show error if just tabbing through
  if (!submitFailed && (!visited || !modified)) {
    return false;
  }

  // show error if user has touched
  return (submitFailed || modified) && error;
};

/**
 * Check if field has error
 * @param {object} meta Final Form field state
 */
export const fieldHasErrorAfterSubmission = (meta) => {
  const { error, visited, modified, submitFailed } = meta;

  // Don't show error if just tabbing through
  if (!submitFailed && (!visited || !modified)) {
    return false;
  }

  // show error if user has submitted
  return submitFailed && error;
};
