/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Voca from 'voca';
import SummaryItem from './SummaryItem';
import SummaryTotal from './SummaryTotal';
import constants, { SummaryItemItemsType, SummaryItemType } from './constants';
import { formValues, Invoice, Discount } from './types/types';
import { CardElement } from '@stripe/react-stripe-js';
import { Field, useForm } from 'react-final-form';
import FormCheckbox from '+/forms/FormCheckbox';
import ErrorField from '+/forms/ErrorField';
import Coupon from './Coupon';
import { displayMoneyString } from '../../../utils/money';
import { DateTime, Interval } from 'luxon';
import { getCouponAmount, getCouponApplicableProductItems } from '../../../utils/seat-based/stripe';
import { getCardFromSource } from '@/main/account/plan-management/helpers';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import seatBasedComputeLookupKey from '~/utils/seat-based/seatBasedComputeLookupKey';
import BillingAddressInputs from '~/shared/forms/BillingAddressInputs';
import { useSelector } from 'react-redux';

interface SavedLocationInputs {
  address_city: string;
  address_line1: string;
  address_line2: string;
  address_postal_code: string;
  address_state: string;
}

interface Props {
  values: formValues;
  children: JSX.Element;
  customer: $TSFixMe;
  getProductsFromValues: (values: formValues) => {
    lookup_key: string | null;
    setup_lookup_key: string | null;
    add_on_lookup_key: string | null;
    reimbursify_key: string | null;
  };
  paymentStep: 'customize' | 'pay' | 'success';
  coupon: CouponType | null;
  seats: { ny: number; non_ny: number; reimbursify: number };
  isNy: boolean;
  hidePayment: boolean | undefined;
  upgrade: boolean | undefined;
  upgradingFromLegacy: boolean | undefined;
  convertingToMonthly?: boolean;
  soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy: boolean | undefined;
  invoiceDTO: Invoice | null;
  upgradeReimbursify: boolean;
  defaultCoupon: string;
  defaultCouponSetAndNotRemovable: boolean;
  defaultCouponDiscountNotRemovable: Discount | null;
  couponError: string | null;
  cardElRef: (node: $TSFixMe) => void;
  getSetupPriceNoAddons: (values: formValues) => number;
  getInvoicePreviewData: (
    values: formValues,
    latestCouponValueOverride: CouponType | null | undefined
  ) => any;
  NEW_SOURCE_SENTINEL: string;
  priorSubscriptionWasIncompleteExpired: boolean;
  setCoupon: React.Dispatch<React.SetStateAction<CouponType | null>>;
  setCouponError: React.Dispatch<React.SetStateAction<string | null>>;
  cardError: StripeElementChangeEvent['error'] | null;
  checkCoupon: $TSFixMeFunction;
}

function SummaryBox({
  values,
  children,
  customer,
  getProductsFromValues,
  paymentStep,
  coupon,
  seats,
  isNy,
  upgrade,
  hidePayment,
  upgradingFromLegacy,
  convertingToMonthly,
  soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy,
  invoiceDTO,
  upgradeReimbursify,
  defaultCoupon,
  defaultCouponSetAndNotRemovable,
  defaultCouponDiscountNotRemovable,
  couponError,
  cardElRef,
  getSetupPriceNoAddons,
  getInvoicePreviewData,
  NEW_SOURCE_SENTINEL,
  priorSubscriptionWasIncompleteExpired,
  setCoupon,
  setCouponError,
  cardError,
  checkCoupon,
}: Props) {
  const form = useForm();
  const [oneTimeItems, setOneTimeItems] = useState<SummaryItemItemsType | null>(null);
  const [monthlyItems, setMonthlyItems] = useState<SummaryItemItemsType | null>(null);
  const [monthlyTotalNoDiscounts, setMonthlyTotalNoDiscounts] = useState<number>(0);
  const [monthlyTotalAfterDiscounts, setMonthlyTotalAfterDiscounts] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [adjustedTotal, setAdjustedTotal] = useState<number>(0);
  const [savedLocationInputs, setSavedLocationInputs] = useState<SavedLocationInputs>({
    address_city: '',
    address_line1: '',
    address_line2: '',
    address_postal_code: '',
    address_state: '',
  });

  // Get provider's primary physical location
  const { provider } = useSelector<State, MainState>((state) => state.main);
  const physicalLocations = provider?.locations.filter(
    (l) => Boolean(!l.remote_location) && l.model_id === provider.id
  );
  const physicalLocation =
    physicalLocations && physicalLocations.length ? physicalLocations[0] : null;

  const addingSeat =
    (hidePayment && !upgrade && !upgradeReimbursify) ||
    (!hidePayment && !upgrade && !upgradeReimbursify) ||
    upgradingFromLegacy;
  const regionSeats = seats ? (isNy ? seats.ny : seats.non_ny) : 0;
  const nonRegionSeats = seats ? (isNy ? seats.non_ny : seats.ny) : 0;

  let subTotal: number = 0;
  let subTotalAfterDiscounts: number = 0;
  let totalDueTodayNotYetAvailable: boolean = false;
  let totalDueTodayNotYetAvailableText = '';

  if (upgrade) {
    if (paymentStep === 'customize') {
      totalDueTodayNotYetAvailable = true;
      totalDueTodayNotYetAvailableText = '(Shown on next screen)';
    } else {
      subTotal = 0;
      // Usually, upgrading is $0 due today.
      // However, if we are converting from a yearly plan
      // it will be a number that depends how many credits are
      // available due to partial usage of the plan
      if (soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy) {
        if (invoiceDTO) {
          const amountDueInDollars = invoiceDTO.subtotal / 100;
          // Only if there's a non-negative sub-total will we set it
          // otherwise, a negative sub-total means credits are
          // covering everything.
          if (amountDueInDollars > 0) {
            subTotal = amountDueInDollars;
          }
        } else {
          totalDueTodayNotYetAvailable = true;
          totalDueTodayNotYetAvailableText = 'Loading...';
        }
      }
      subTotalAfterDiscounts = subTotal;
    }
  } else {
    subTotal = hidePayment ? total : total + monthlyTotalNoDiscounts;
    subTotalAfterDiscounts = hidePayment ? total : total + monthlyTotalAfterDiscounts;
  }

  useEffect(() => {
    selectItems(values);
    calculateAdjustedTotal(values, subTotal);
  }, [monthlyTotalNoDiscounts, total, values, coupon, subTotal]);

  useEffect(() => {
    if (oneTimeItems) {
      // @ts-ignore-next-line
      const total = oneTimeItems.reduce((previous, item) => previous + item.price, 0);
      setTotal(total);
    } else {
      setTotal(0);
    }
  }, [oneTimeItems]);

  useEffect(() => {
    if (monthlyItems && monthlyItems.length > 0) {
      // Ex. Paying for NY Basic membership + Premium Practice Management for an additional provider
      // Before: seats: { non_ny: 1, ny: 0 };
      // After: seats: { non_ny: 2, ny: 0 };
      // Note: Unlike stripe where the above note are one product, the frontend treats these as separate items
      let total: number = 0;
      // The below will add the corresponding region cost for each provider already on the relevant subscription
      // and will add an additional region cost if adding a seat to the subscription
      monthlyItems.forEach((item) => {
        total +=
          // Price of the item x existing seats in the same region (non_ny) as additional seat + cost of additional seat (non_ny)
          // + the corresponding non-region price (ny) x the amount of non-region providers on the account (0)
          item.price * (regionSeats + (addingSeat ? 1 : 0)) + item.locale_price * nonRegionSeats;
      });

      setMonthlyTotalNoDiscounts(total);
      let totalAfterDiscounts = total;
      const ongoingOnlyCouponAmount = calculateApplicableCouponAmountOnGoingOnly();
      if (ongoingOnlyCouponAmount > 0) {
        totalAfterDiscounts = total - ongoingOnlyCouponAmount;
      }
      // Prevent extremely large coupons from causing monthly total after discounts to go negative.
      if (totalAfterDiscounts < 0) {
        totalAfterDiscounts = 0;
      }
      setMonthlyTotalAfterDiscounts(totalAfterDiscounts);
    } else {
      setMonthlyTotalNoDiscounts(0);
    }
  }, [monthlyItems]);

  /**
   * Updates the state values mapped over to render summary lines
   * @param values Payment form values
   */
  const selectItems = (values: formValues): SummaryItemItemsType => {
    const { lookup_key, setup_lookup_key, add_on_lookup_key, reimbursify_key } =
      getProductsFromValues(values);

    const items = [
      ...getSummaryData(lookup_key),
      ...getSummaryData(setup_lookup_key),
      ...getSummaryData(add_on_lookup_key),
      ...getSummaryData(reimbursify_key),
    ];
    setOneTimeItems(items.filter((item) => item.recurring === false));
    setMonthlyItems(items.filter((item) => item.recurring === true));
    return items;
  };

  // const getExistingItems = () => {};

  /**
   * Returns the item in an array matching the product lookup key accepted by this function
   * @param lookupKey A form value containing a lookup key that maps to a summary item containing metadata
   */
  const getSummaryData = (lookupKey: string | null): SummaryItemItemsType => {
    if (!lookupKey) return [];
    const matchingItems = constants.summary_items.filter((item) => item.lookup_key === lookupKey);
    const data = matchingItems.reduce(
      (previous, current) => [...previous, ...current.items],
      [] as SummaryItemItemsType
    );
    return data;
  };

  // Returns true if the current coupon is auto-set, not removable, and provides 0 value.
  // This is the short-term solution for ZEN-817
  const couponIsAutoSetAndUseless = (values: formValues) => {
    if (defaultCouponSetAndNotRemovable && coupon && _.get(coupon, 'valid')) {
      const couponAmount = calculateApplicableCouponAmountBothOneTimeAndOngoing(values);
      const ongoingOnlyCouponAmount = calculateApplicableCouponAmountOnGoingOnly();
      if (couponAmount <= 0 && ongoingOnlyCouponAmount <= 0) {
        return true;
      }
    }

    return false;
  };

  // This can do the heavy lifting for now
  const generateCouponDescription = (values: formValues) => {
    let phrase = '';
    if (coupon && (defaultCouponSetAndNotRemovable || _.get(coupon, 'valid'))) {
      const couponAmount = calculateApplicableCouponAmountBothOneTimeAndOngoing(values);
      const ongoingOnlyCouponAmount = calculateApplicableCouponAmountOnGoingOnly();
      const onetimeOnlyCouponAmount = calculateApplicableCouponAmountSetupOnly(values);
      // Only show error if this coupon provides 0 ongoing benefit
      // (e.g. in case of add seat and upgrade, benefit is not for today's setup charge so couponAmount = 0 but ongoingOnlyCouponAmount is non-zero)
      if (couponAmount <= 0 && ongoingOnlyCouponAmount <= 0) {
        return <div className='text-error'>Sorry, this coupon does not work for these items.</div>;
      }

      const { percent_off, amount_off } = coupon;

      if (percent_off) {
        phrase += `${percent_off}% off`;
      }
      if (amount_off) {
        if (couponAmount <= 0) {
          // If coupon is an amount-off and couponAmount <= 0, don't return normal phrase as
          // it would read $0 off
          // If we reached this, coupon does provide ongoing value but best to not show a phrase
          // instead point user to use preview upcoming invoice link.
          if (coupon.id === coupon.name) {
            return '';
          } else {
            return coupon.name;
          }
        } else {
          if (ongoingOnlyCouponAmount > 0 && onetimeOnlyCouponAmount > 0) {
            phrase += `${displayMoneyString(
              onetimeOnlyCouponAmount
            )} off one-time services today and ${displayMoneyString(ongoingOnlyCouponAmount)}`;
          } else if (ongoingOnlyCouponAmount > 0) {
            phrase += `${displayMoneyString(ongoingOnlyCouponAmount)} off`;
          } else if (onetimeOnlyCouponAmount > 0) {
            phrase += `${displayMoneyString(onetimeOnlyCouponAmount)} off`;
          }
        }
      }
    }

    phrase += ` ${getCouponDurationPhrase()}`;
    const productItems = calculateApplicableProductItemsForCoupon(values);
    if (productItems.length) {
      phrase += ` (applies to ${productItems.join(', ')})`;
    }
    return phrase;
  };

  // This can do the heavy lifting for now
  const getCouponDurationPhrase = () => {
    let durationPhrase = '';

    if (coupon && (defaultCouponSetAndNotRemovable || _.get(coupon, 'valid'))) {
      const { duration, duration_in_months } = coupon;

      if (duration_in_months) {
        if (duration_in_months === 1) {
          durationPhrase += 'once';
        } else {
          if (
            defaultCouponSetAndNotRemovable &&
            defaultCouponDiscountNotRemovable &&
            defaultCouponDiscountNotRemovable.end
          ) {
            // Compute how many months have already passed.
            const discountEnd = DateTime.fromMillis(defaultCouponDiscountNotRemovable.end * 1000);
            const now = DateTime.now();
            const diff = Interval.fromDateTimes(now, discountEnd);

            const diffMonths = diff.length('months');

            if (diffMonths < 1) {
              const diffDays = diff.length('days');
              if (diffDays < 1) {
                const diffHours = diff.length('hours');
                if (diffHours < 1) {
                  const diffMinutes = diff.length('minutes');
                  if (diffMinutes < 1) {
                    const diffSeconds = diff.length('seconds');
                    durationPhrase += `for ${Math.trunc(diffSeconds)} more ${
                      Math.trunc(diffSeconds) > 1 ? 'seconds' : 'second'
                    }`;
                  } else {
                    durationPhrase += `for ${Math.trunc(diffMinutes)} more ${
                      Math.trunc(diffMinutes) > 1 ? 'minutes' : 'minute'
                    }`;
                  }
                } else {
                  durationPhrase += `for ${Math.trunc(diffHours)} more ${
                    Math.trunc(diffHours) > 1 ? 'hours' : 'hour'
                  }`;
                }
              } else {
                durationPhrase += `for ${Math.trunc(diffDays)} more ${
                  Math.trunc(diffDays) > 1 ? 'days' : 'day'
                }`;
              }
            } else {
              if (Math.trunc(diffMonths) === 12) {
                durationPhrase += 'first year of membership';
              } else {
                durationPhrase += `for ${Math.trunc(diffMonths)} more ${
                  Math.trunc(diffMonths) > 1 ? 'months' : 'month'
                }`;
              }
            }
          } else {
            if (duration_in_months === 12) {
              durationPhrase += 'first year of membership';
            } else {
              durationPhrase += `for ${duration_in_months} months`;
            }
          }
        }
      } else {
        if (duration === 'once') {
          durationPhrase += 'once';
        } else {
          durationPhrase += 'forever';
        }
      }
    }

    return durationPhrase;
  };

  const generateProductsToEvaluateArraySetupOnly = (values: formValues) => {
    // No subscription products because we want only the impact of coupon on setup.
    const productsToEvaluate: Array<{
      product_name: string;
      quantity: number;
      price: number;
      lookup_key: string;
    }> = [];

    if (!(upgrade || upgradeReimbursify)) {
      // Not an upgrade, allow setup amounts to affect coupon discounts (won't be setup amount if reactivating).
      const { setup_lookup_key, add_on_lookup_key } = getProductsFromValues(values);

      if (setup_lookup_key) {
        const setupProductSummaryItem = getSummaryItemForLookupKey(setup_lookup_key);
        const entry = {
          product_name: setupProductSummaryItem.exactStripeProductNameForCouponMatching,
          quantity: 1,
          // Our setup product only has 1 summary item
          price: setupProductSummaryItem.items[0].price,
          lookup_key: setupProductSummaryItem.lookup_key,
        };
        productsToEvaluate.push(entry);
      }

      // Coupon support for professional writing add-on.
      if (add_on_lookup_key) {
        const oneTimeAddOnProductSummaryItem = getSummaryItemForLookupKey(add_on_lookup_key);
        const entry = {
          product_name: oneTimeAddOnProductSummaryItem.exactStripeProductNameForCouponMatching,
          quantity: 1,
          // Our one-time add-on product (professional writing) only has 1 summary item
          price: oneTimeAddOnProductSummaryItem.items[0].price,
          lookup_key: oneTimeAddOnProductSummaryItem.lookup_key,
        };
        productsToEvaluate.push(entry);
      }
    }
    return productsToEvaluate;
  };

  const calculateApplicableCouponAmountSetupOnly = (values: formValues) => {
    const productsToEvaluate: Array<{
      product_name: string;
      quantity: number;
      price: number;
      lookup_key: string;
    }> = generateProductsToEvaluateArraySetupOnly(values);

    return getCouponAmount(productsToEvaluate, coupon, defaultCouponSetAndNotRemovable);
  };

  const getCouponAmountStringOnGoingOnly = (values: formValues) => {
    const couponAmount = calculateApplicableCouponAmountOnGoingOnly();
    if (couponAmount > 0) {
      return `-${displayMoneyString(couponAmount)}`;
    }

    return '';
  };

  const getCouponAmountStringSetupOnly = (values: formValues) => {
    const couponAmount = calculateApplicableCouponAmountSetupOnly(values);
    if (couponAmount > 0) {
      return `-${displayMoneyString(couponAmount)}`;
    }

    return '';
  };

  const getSummaryItemForLookupKey = (lookupKey: string | null): SummaryItemType => {
    const matchingItem = constants.summary_items.find((item) => item.lookup_key === lookupKey);
    // @ts-ignore-next-line
    return matchingItem;
  };

  const getTierProductEntriesForCouponPurposes = (tier: string): CouponProductList => {
    const results: CouponProductList = [];

    if (seats.ny > 0) {
      const nyUpgradeProductSummmaryItem = getSummaryItemForLookupKey(
        seatBasedComputeLookupKey(tier, true)
      );

      const entry = {
        product_name: nyUpgradeProductSummmaryItem.exactStripeProductNameForCouponMatching,
        quantity: seats.ny,
        // We only sum here because in our constants file a tier-based stripe product can be broken down into more than one item
        price: _.sum(nyUpgradeProductSummmaryItem.items.map((x) => x.price)),
        lookup_key: nyUpgradeProductSummmaryItem.lookup_key,
      };
      results.push(entry);
    }

    if (seats.non_ny > 0) {
      const nonNyUpgradeProductSummmaryItem = getSummaryItemForLookupKey(
        seatBasedComputeLookupKey(tier, false)
      );

      const entry = {
        product_name: nonNyUpgradeProductSummmaryItem.exactStripeProductNameForCouponMatching,
        quantity: seats.non_ny,
        // We only sum here because in our constants file a tier-based stripe product can be broken down into more than one item
        price: _.sum(nonNyUpgradeProductSummmaryItem.items.map((x) => x.price)),
        lookup_key: nonNyUpgradeProductSummmaryItem.lookup_key,
      };
      results.push(entry);
    }

    return results;
  };

  /**
   * Builds the array used by the coupon calculation code.
   * @returns Array<{ product_name: string; quantity: number, price: number }>
   */
  const buildRecurringProductsToEvaluateArrayForCouponPurposes = (): CouponProductList => {
    let results: CouponProductList = [];
    const { lookup_key, reimbursify_key } = getProductsFromValues(values);

    // Zencare Basic / Zencare Premium / Zencare
    const newProductSummmaryItem = getSummaryItemForLookupKey(lookup_key);
    // @ts-ignore-next-line
    const tier: string = newProductSummmaryItem.tier;
    // What are we doing?
    if (convertingToMonthly) {
      // There will be only 1 seat
      const entry: CouponProductListItem = {
        product_name: newProductSummmaryItem.exactStripeProductNameForCouponMatching,
        quantity: 1,
        // We only sum here because in our constants file a tier-based stripe product can be broken down into more than one item
        price: _.sum(newProductSummmaryItem.items.map((x) => x.price)),
      };
      results.push(entry);
    } else if (upgrade) {
      // Seat count does not increase
      // ZPM upgrade
      results = getTierProductEntriesForCouponPurposes(tier);

      if (seats.reimbursify > 0) {
        const reimbursifyProductSummaryItem = getSummaryItemForLookupKey(reimbursify_key);

        const entry: CouponProductListItem = {
          product_name: reimbursifyProductSummaryItem.exactStripeProductNameForCouponMatching,
          quantity: seats.reimbursify,
          // Our Reimbursify product only has 1 summary item
          price: reimbursifyProductSummaryItem.items[0].price,
        };
        results.push(entry);
      }
    } else if (upgradeReimbursify) {
      // Seat count does not increase - but we add Reimbursify seats based on how many NY/CA seats there are
      results = getTierProductEntriesForCouponPurposes(tier);
      if (reimbursify_key) {
        const reimbursifyProductSummaryItem = getSummaryItemForLookupKey(reimbursify_key);

        const entry: CouponProductListItem = {
          product_name: reimbursifyProductSummaryItem.exactStripeProductNameForCouponMatching,
          quantity: _.sum(results.map((x) => x.quantity)),
          // Our Reimbursify product only has 1 summary item
          price: reimbursifyProductSummaryItem.items[0].price,
        };
        results.push(entry);
      }
    } else if (hidePayment) {
      // Adding seat
      results = getTierProductEntriesForCouponPurposes(tier);

      const existingEntry = results.find(
        (x) => x.product_name === newProductSummmaryItem.exactStripeProductNameForCouponMatching
      );
      if (existingEntry) {
        existingEntry.quantity++;
      } else {
        const entry: CouponProductListItem = {
          product_name: newProductSummmaryItem.exactStripeProductNameForCouponMatching,
          quantity: 1,
          price: _.sum(newProductSummmaryItem.items.map((x) => x.price)),
        };
        results.push(entry);
      }

      if (seats.reimbursify > 0) {
        const reimbursifyProductSummaryItem = getSummaryItemForLookupKey(reimbursify_key);

        const entry: CouponProductListItem = {
          product_name: reimbursifyProductSummaryItem.exactStripeProductNameForCouponMatching,
          // Note: We need the future number of seats not current number of RBSFY seats.
          quantity: _.sum(results.map((x) => x.quantity)),
          // Our Reimbursify product only has 1 summary item
          price: reimbursifyProductSummaryItem.items[0].price,
        };
        results.push(entry);
      }
    } else {
      // Initial new customer sign up
      // There will be only 1 seat
      const entry: CouponProductListItem = {
        product_name: newProductSummmaryItem.exactStripeProductNameForCouponMatching,
        quantity: 1,
        price: _.sum(newProductSummmaryItem.items.map((x) => x.price)),
      };
      results.push(entry);

      // Support for Reimbursify coupons on onboarding wizard
      if (reimbursify_key) {
        const reimbursifyProductSummaryItem = getSummaryItemForLookupKey(
          constants.reimbursify_professional.lookup_key
        );

        const entry: CouponProductListItem = {
          product_name: reimbursifyProductSummaryItem.exactStripeProductNameForCouponMatching,
          quantity: _.sum(results.map((x) => x.quantity)),
          // Our Reimbursify product only has 1 summary item
          price: reimbursifyProductSummaryItem.items[0].price,
        };
        results.push(entry);
      }
    }

    return results;
  };

  // We need to not show a coupon error if adding a seat where coupon is not providing
  // a discount to 'total due today' but is providing an ongoing benefit.
  const calculateApplicableCouponAmountOnGoingOnly = () => {
    let reduceAvailableDollarOffCouponAmountBy = null;

    const productsToEvaluate = buildRecurringProductsToEvaluateArrayForCouponPurposes();

    // Is this an amount off coupon that was already partially or completey used by setup fee?
    if (coupon && coupon.amount_off) {
      const setOnlyCouponAmount = calculateApplicableCouponAmountSetupOnly(values);

      if (setOnlyCouponAmount) {
        reduceAvailableDollarOffCouponAmountBy = setOnlyCouponAmount;
      }
    }

    const result = getCouponAmount(
      productsToEvaluate,
      coupon,
      defaultCouponSetAndNotRemovable,
      reduceAvailableDollarOffCouponAmountBy
    );
    return result;
  };

  const calculateApplicableCouponAmountDueTodayOnly = (values: formValues) => {
    // (hidePayment === true) when adding a seat or upgrading and we must set subscriptionAmount to 0
    // for coupon purposes, because we are not charging any part of
    // subscription fee upon adding seat. We are only charging the setup/add-ons today
    // otherwise, an incorrect total due today amount will be shown.

    let couponAmount = 0;

    if (!hidePayment) {
      const ongoingCouponAmount = calculateApplicableCouponAmountOnGoingOnly();
      if (ongoingCouponAmount) {
        couponAmount += ongoingCouponAmount;
      }
    }

    const setOnlyCouponAmount = calculateApplicableCouponAmountSetupOnly(values);
    if (setOnlyCouponAmount) {
      couponAmount += setOnlyCouponAmount;
    }

    return couponAmount;
  };

  const calculateApplicableCouponAmountBothOneTimeAndOngoing = (values: formValues) => {
    let couponAmount = 0;
    const ongoingCouponAmount = calculateApplicableCouponAmountOnGoingOnly();
    if (ongoingCouponAmount) {
      couponAmount += ongoingCouponAmount;
    }

    const setOnlyCouponAmount = calculateApplicableCouponAmountSetupOnly(values);
    if (setOnlyCouponAmount) {
      couponAmount += setOnlyCouponAmount;
    }

    return couponAmount;
  };

  const calculateApplicableProductItemsForCoupon = (values: formValues): Array<string> => {
    const productsToEvaluateRecurring = buildRecurringProductsToEvaluateArrayForCouponPurposes();

    const productsToEvaluateSetup = generateProductsToEvaluateArraySetupOnly(values);

    const allProducts = productsToEvaluateRecurring.concat(productsToEvaluateSetup);

    return getCouponApplicableProductItems(allProducts, coupon);
  };

  const calculateApplicableProductItemsForCouponRecurringOnly = (
    values: formValues
  ): Array<string> => {
    const productsToEvaluateRecurring = buildRecurringProductsToEvaluateArrayForCouponPurposes();
    return getCouponApplicableProductItems(productsToEvaluateRecurring, coupon);
  };

  const calculateAdjustedTotal = (values: formValues, subtotal: number) => {
    const couponAmount = calculateApplicableCouponAmountDueTodayOnly(values);
    let newAdjustedTotal = subtotal;

    // Only subtract coupon amount if subtotal is not already 0.
    if (subtotal > 0) {
      newAdjustedTotal = subtotal - couponAmount;
    }

    // Don't let total due today go below $0.00
    if (newAdjustedTotal < 0) {
      newAdjustedTotal = 0;
    }

    setAdjustedTotal(newAdjustedTotal);
  };

  let totalNotYetAvailable = false;
  let totalNotYetAvailableText = '';

  if (soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy && !invoiceDTO) {
    totalNotYetAvailable = true;
    totalNotYetAvailableText = 'Loading...';
  }

  const sourceOptions =
    customer && customer.sources
      ? customer.sources.map((s: $TSFixMe) => {
          const card = getCardFromSource(s) || {};

          return (
            <div className='flex align-baseline' key={s.id}>
              <Field
                name='source'
                component='input'
                type='radio'
                value={s.id}
                id={`source${s.id}`}
              />
              <label className='m-b-sm' htmlFor={`source${s.id}`}>
                {Voca.capitalize(card.brand)} ····{card.last4} (Exp {card.exp_month}/{card.exp_year}
                )
              </label>
            </div>
          );
        })
      : null;

  const couponDurationPhrase = getCouponDurationPhrase();

  const populatePhysicalAddressFields = () => {
    if (!provider || !physicalLocation) return;

    // Check value of checkbox prior to click
    const formState = form.getState();
    const {
      use_physical_location,
      address_city,
      address_line1,
      address_line2,
      address_postal_code,
      address_state,
    } = formState.values;

    // If the checkbox was checked prior to this event
    if (use_physical_location) {
      // Reset form fields to customer user input
      form.change('address_city', savedLocationInputs.address_city);
      form.change('address_line1', savedLocationInputs.address_line1);
      form.change('address_line2', savedLocationInputs.address_line2);
      form.change('address_postal_code', savedLocationInputs.address_postal_code);
      form.change('address_state', savedLocationInputs.address_state);
    } else {
      // Save user inputs
      setSavedLocationInputs({
        address_city,
        address_line1,
        address_line2,
        address_postal_code,
        address_state,
      });
      // Use physical location
      form.change('address_city', physicalLocation.city);
      form.change('address_line1', physicalLocation.address);
      form.change('address_line2', physicalLocation.address_2);
      form.change('address_postal_code', physicalLocation.zip);
      form.change('address_state', physicalLocation.state);
    }
  };

  return (
    <div className='payment-form-summary'>
      <h2>{`${paymentStep === 'customize' ? 'Summary' : 'Complete Your Purchase'}`}</h2>
      <div className='payment-form-summary-box box'>
        <div className='payment-form-summary-products h-100'>
          {monthlyItems && monthlyItems.length > 0 && (
            <div className='payment-form-summary-section'>
              <div className='m-b-sm flex column'>
                <h5 className='m-b-0'>Monthly Plan</h5>
                <small>Annual commitment for the first year</small>
              </div>
              {monthlyItems.map((item, index) => (
                <SummaryItem
                  item={item}
                  key={index}
                  regionSeats={regionSeats}
                  nonRegionSeats={nonRegionSeats}
                  upgrade={upgrade}
                  upgradingFromLegacy={upgradingFromLegacy}
                  upgradeReimbursify={upgradeReimbursify}
                />
              ))}
              {calculateApplicableCouponAmountOnGoingOnly() > 0 ? (
                <div className='flex justify-between m-t-md appear'>
                  <p>
                    {couponDurationPhrase === 'once' ? 'One-time' : 'Monthly'} discount
                    {paymentStep === 'pay' && (
                      <>
                        {' '}
                        (applies to{' '}
                        {calculateApplicableProductItemsForCouponRecurringOnly(values).join(
                          ', '
                        )}{' '}
                        {couponDurationPhrase === 'first year of membership'
                          ? 'for first year'
                          : couponDurationPhrase}
                        )
                      </>
                    )}
                  </p>
                  <div className='total-value'>
                    <p className='payment-form-summary-number payment-form-summary-number-discount'>
                      {getCouponAmountStringOnGoingOnly(values)}
                    </p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          )}

          {monthlyTotalAfterDiscounts < monthlyTotalNoDiscounts ? (
            <SummaryTotal
              text={`Discounted monthly charge ${couponDurationPhrase}`}
              total={monthlyTotalAfterDiscounts}
              recurring={true}
              totalNotYetAvailable={false}
              totalNotYetAvailableText=''
            />
          ) : (
            <SummaryTotal
              text='Total monthly charge'
              total={monthlyTotalNoDiscounts}
              recurring={true}
              totalNotYetAvailable={false}
              totalNotYetAvailableText=''
            />
          )}
          {oneTimeItems && oneTimeItems.length > 0 && (
            <div className='payment-form-summary-section payment-form-summary-section-one-time-services'>
              <div className='m-b-sm'>
                <h5 className='m-b-xs'>One-time Services</h5>
                {oneTimeItems.map((item, index) => (
                  <SummaryItem item={item} key={index} />
                ))}
              </div>
            </div>
          )}

          {/* TODO: note that "Total" will be "smarter" once we start adding seats etc. */}

          {subTotal > 0 &&
            soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy &&
            invoiceDTO &&
            invoiceDTO.lines &&
            invoiceDTO.lines.data &&
            invoiceDTO.lines.data[0] && (
              <SummaryTotal
                text={invoiceDTO.lines.data[0].description}
                total={invoiceDTO.lines.data[0].amount / 100}
                recurring={false}
                totalNotYetAvailable={false}
                totalNotYetAvailableText=''
              />
            )}

          {subTotalAfterDiscounts < subTotal ? (
            <SummaryTotal
              text={paymentStep === 'customize' ? 'Total due today' : 'Subtotal'}
              total={subTotalAfterDiscounts}
              recurring={false}
              totalNotYetAvailable={totalDueTodayNotYetAvailable}
              totalNotYetAvailableText={totalDueTodayNotYetAvailableText}
            />
          ) : (
            <SummaryTotal
              text={paymentStep === 'customize' ? 'Total due today' : 'Subtotal'}
              total={subTotal}
              recurring={false}
              totalNotYetAvailable={totalDueTodayNotYetAvailable}
              totalNotYetAvailableText={totalDueTodayNotYetAvailableText}
            />
          )}
        </div>
        {paymentStep === 'pay' && (
          <>
            {!couponIsAutoSetAndUseless(values) && (
              <div className='w-100 coupon-row total-row flex row m-b-sm'>
                <div className='total-label'>
                  <Coupon
                    coupon={coupon}
                    defaultCoupon={defaultCoupon}
                    defaultCouponSetAndNotRemovable={defaultCouponSetAndNotRemovable}
                    checkCoupon={checkCoupon}
                    onCheckCouponCompletedOrCouponRemoved={(
                      latestCouponValue: CouponType | null
                    ) => {
                      // Note: We need to accept latestCouponValue due to async nature of setCoupon
                      // we need to make sure we use the latest value for network request to Stripe.
                      getInvoicePreviewData(values, latestCouponValue);
                    }}
                    setCouponError={setCouponError}
                    setCoupon={setCoupon}
                    couponError={couponError}
                    generateCouponDescription={() => generateCouponDescription(values)}
                  />
                </div>
                {calculateApplicableCouponAmountSetupOnly(values) > 0 ? (
                  <div className='total-value'>
                    <p>{getCouponAmountStringSetupOnly(values)}</p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
            <SummaryTotal
              text='Total due today'
              recurring={false}
              total={adjustedTotal}
              totalNotYetAvailable={totalNotYetAvailable}
              totalNotYetAvailableText={totalNotYetAvailableText}
            />
            {!hidePayment && (
              <>
                <h5 className='m-t-sm m-b-xs'>Payment Details</h5>
                {!priorSubscriptionWasIncompleteExpired && sourceOptions}
                {!priorSubscriptionWasIncompleteExpired &&
                  customer &&
                  customer.sources &&
                  customer.sources.length > 0 && (
                    <>
                      <Field
                        name='source'
                        component='input'
                        type='radio'
                        value={NEW_SOURCE_SENTINEL}
                        id={NEW_SOURCE_SENTINEL}
                      />
                      <label className='m-b-sm' htmlFor={NEW_SOURCE_SENTINEL}>
                        Use a new card
                      </label>
                    </>
                  )}
                <div className='flex column m-b-sm' ref={cardElRef}>
                  <div
                    className={`card-input m-b-xs ${
                      values.source !== NEW_SOURCE_SENTINEL && 'disabled'
                    }`}
                  >
                    <CardElement
                      options={{
                        disabled: values.source !== NEW_SOURCE_SENTINEL,
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#666666',
                            },
                          },
                          invalid: {
                            color: '#F2474E',
                          },
                        },
                      }}
                    />
                  </div>
                  {cardError && values.source === NEW_SOURCE_SENTINEL && (
                    <span className='text-error'>{cardError.message}</span>
                  )}
                </div>
                <h5 className='m-b-xs'>Billing Address</h5>
                {physicalLocation && (
                  <Field name='use_physical_location'>
                    {(props) => (
                      <FormCheckbox
                        mutator={populatePhysicalAddressFields}
                        fieldClassName='m-r-xs m-b-xs'
                        label={<span>Use physical location</span>}
                        {...props}
                      />
                    )}
                  </Field>
                )}
                <BillingAddressInputs />
              </>
            )}
            <div className='terms-of-service'>
              <Field name='terms-of-service'>
                {(props) => (
                  <FormCheckbox
                    fieldClassName='m-r-xs'
                    {...props}
                    label={
                      <p
                        style={{
                          fontSize: '0.9rem',
                          lineHeight: '1.25rem',
                        }}
                        className='m-b-md'
                      >
                        I agree to the Zencare{' '}
                        <a
                          rel='noopener noreferrer'
                          target='_blank'
                          href='https://zencare.co/policy/terms'
                          style={{
                            fontSize: '0.9rem',
                            lineHeight: '1.25rem',
                          }}
                        >
                          Terms Of Service
                        </a>
                        . I understand that the Zencare membership is an <b>annual commitment</b>,
                        that I will be responsible for the remainder of the year if I cancel early,
                        and that <b>no refunds are issued</b>. When eligible, subscription change
                        requests must be made by providing fifteen (15) days' notice prior to your
                        renewal date, or next payment date.
                      </p>
                    }
                  />
                )}
              </Field>
              <ErrorField name='languages' />
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
}

export default SummaryBox;
