import React, { useState } from 'react';
import ProductDescription from '../payment/seat-based/ProductDescription';
import constants, { products } from '../payment/seat-based/constants';
import UserCounter from './UserCounter';
import { Field } from 'react-final-form';
import ProductDescriptionModal from '../payment/seat-based/ProductDescriptionModal';

function ReviewPlanBox() {
  const [showSetupOptionModal, setShowSetupOptionModal] = useState(false);
  const [currentModalSetupOption, setCurrentModalSetupOption] = useState(null);
  const standaloneProducts = [
    products.PRACTICE_MANAGEMENT_PRO_STANDALONE,
    products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE,
  ];

  return (
    <div className='box'>
      <div className='flex justify-between'>
        <div className='w-100'>
          <h5 className='payment-form-product-title'>
            Zencare Practice Management
            <ProductDescription
              onlyIcon={true}
              setShowModal={setShowSetupOptionModal}
              setCurrentModalSetupOption={setCurrentModalSetupOption}
              option={constants.product_descriptions.zencare_practice_management}
            />
          </h5>
          <p className='payment-form-product-description'>
            An EHR to streamline appointments, billing, notes, telehealth, and more.{' '}
            <a
              href='https://therapist.zencare.co/zencare-practice-management-feature-comparison'
              target='_blank'
              rel='noopener noreferrer'
            >
              Compare plans
            </a>
          </p>
          {standaloneProducts.map((product) => (
            <div className='flex justify-between' key={product.LOOKUP_KEY}>
              <p>{product.EXACT_PRODUCT_NAME_FROM_STRIPE}</p>
              <p className='review-price-display'>
                ${product.PRICE} <span>/ Month</span>
                <Field
                  name='tier'
                  component='input'
                  type='radio'
                  value={product.TIER}
                  id='practice_management_selected'
                />
              </p>
            </div>
          ))}
          <hr className='m-t-0' />
          <div className='flex justify-between align-center'>
            <p className='m-0'>How many users?</p>
            <UserCounter />
          </div>
        </div>
      </div>
      <ProductDescriptionModal
        showModal={showSetupOptionModal}
        setShowModal={setShowSetupOptionModal}
        option={currentModalSetupOption}
      />
    </div>
  );
}

export default ReviewPlanBox;
