// Allows for special handling of 00:00 since we don't allow events to cross day boundaries
//  00:00 should be considered after 23:00
export const isMilitaryTime2AfterMilitaryTime1 = (militaryTime1, militaryTime2) => {
  if (militaryTime2 === '00:00') {
    if (militaryTime1 === '00:00') {
      return false;
    } else {
      return true;
    }
  } else {
    return militaryTime2 > militaryTime1;
  }
};

export const militaryTimeTo12HourTime = (militaryTime) => {
  let result = '';
  if (militaryTime && militaryTime.includes(':')) {
    const split = militaryTime.split(':');
    const militaryHours = parseInt(split[0]);
    const minutes = split[1];
    let amPm = '';
    let visibleHours = militaryHours;
    if (militaryHours >= 12) {
      visibleHours = militaryHours - 12;
      amPm = 'pm';
    } else {
      amPm = 'am';
    }

    if (visibleHours === 0) {
      visibleHours = 12;
    }

    result = `${visibleHours}:${minutes}${amPm}`;
  }

  return result;
};

export const twelveHourTimeToMilitaryTime = (time) => {
  let result = '';
  try {
    if (
      (time && time.includes(':') && time.toUpperCase('AM').endsWith('AM')) ||
      time.toUpperCase('PM').endsWith('PM')
    ) {
      const split = time.split(':');
      let militaryHours = parseInt(split[0]);
      const minutesAndAmPm = split[1];

      // If invalid hours (should be max 12 before we add 12), return empty string.
      if (militaryHours > 12) {
        return '';
      }

      if (minutesAndAmPm.toUpperCase().includes('PM')) {
        // 12:00pm should be 12:00 not 24:00 so don't add 12 for noon.
        if (militaryHours !== 12) {
          militaryHours += 12;
        }
      }

      if (minutesAndAmPm.toUpperCase().includes('AM')) {
        if (militaryHours === 12) {
          militaryHours = 0;
        }
      }

      const minutes = parseInt(minutesAndAmPm.toUpperCase().replace('AM', '').replace('PM', ''));
      // If invalid minutes, return empty string.
      if (minutes > 59) {
        return '';
      }
      let minutesString = minutes.toString();
      if (minutes < 10) {
        minutesString = `0${minutesString}`;
      }

      // If invalid hours, return empty string.
      if (militaryHours > 24) {
        return '';
      }

      let militaryHoursString = militaryHours.toString();
      if (militaryHours < 10) {
        militaryHoursString = `0${militaryHoursString}`;
      }

      result = `${militaryHoursString}:${minutesString}`;
    }
  } catch (e) {
    console.log(e);
  }

  return result;
};
