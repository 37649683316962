import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../main/sagaSlice';
import Loader from '+/forms/SectionLoader';
import { INTAKE_EXPORTER_ROLE } from '~/components/auth/constants';

const IntakeExport = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showWidget, setShowWidget] = useState<boolean | undefined>(false);

  const dispatch = useDispatch();
  const { roles } = useSelector<State, AuthState>((state) => state.auth);
  const { provider } = useSelector<State, MainState>((state) => state.main);

  const clinicianRoleAccountId = provider?.account_id;

  function handleExportIntakes() {
    if (!clinicianRoleAccountId) return;
    return dispatch(actions.exportIntakes({ account_id: clinicianRoleAccountId }));
  }

  useEffect(() => {
    setShowWidget(() =>
      roles
        ?.filter((roles) => roles.account_id === provider?.account_id)
        .map((r) => r.role)
        .includes(INTAKE_EXPORTER_ROLE)
    );
    setLoading(false);
  }, [roles]);

  if (loading) {
    return (
      <div className='account-dashboard'>
        <Loader />
      </div>
    );
  }

  if (!showWidget) {
    return (
      <div className='account-dashboard'>
        <section className='error-container'>
          <h1>Oops!</h1>
          <h3>
            You are unable to access this resource. If you think you are seeing this in error,
            please <a href='/contact'>contact support!</a>
          </h3>
        </section>
      </div>
    );
  }

  return (
    <div className='account-dashboard'>
      <h1>Referral Data</h1>
      <section className='widget-grid'>
        <div className='box widget'>
          <h2>Download Practice Referrals</h2>
          <p className='widget-desc'>Get a CSV of all referrals your practice has ever received.</p>
          <p className='widget-text'>Note that all appointment times are indicated in UTC.</p>
          <button onClick={handleExportIntakes} className='primary md pill'>
            Download
          </button>
        </div>
      </section>
    </div>
  );
};

export default IntakeExport;
