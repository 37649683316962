import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Input from '+/forms/Input';
import { Form, Field } from 'react-final-form';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import Loader from '+/forms/SectionLoader';
import { createPhoneErrorMessage } from '~/utils/createPhoneErrorMessage';

export const RecoverAccount = (props: $TSFixMe) => {
  const { recoverAccountEmail, recoverAccountEmailValue, recoverAccountEmailError } = props;

  const [loading, setLoading] = useState(false);

  const handleSubmit = (v: $TSFixMe) => {
    recoverAccountEmail(v);
    setLoading(true);
  };

  useEffect(() => {
    if (loading && (recoverAccountEmailValue || recoverAccountEmailError)) {
      setLoading(false);
    }
  }, [recoverAccountEmailValue, recoverAccountEmailError]);

  if (recoverAccountEmailValue) {
    return <Redirect to='/account/recovered' />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: $TSFixMe = {};

        if (!values.first_name) {
          errors.first_name = 'First name required.';
        }

        if (!values.last_name) {
          errors.last_name = 'Last name required.';
        }

        errors.phone_number = createPhoneErrorMessage(values.phone_number);

        return errors;
      }}
      render={({ handleSubmit, pristine, invalid, errors }) => (
        <div className='reset-password'>
          <p style={{ textAlign: 'center' }}>
            Recover your account by indicating your personal information below.
          </p>
          <Field
            name='first_name'
            component={Input}
            label={<span className='semi-bold heading-5'>First name</span>}
            placeholder='First Name'
          />
          <Field
            name='last_name'
            component={Input}
            label={<span className='semi-bold heading-5'>Last name</span>}
            placeholder='Last Name'
          />
          <Field
            name='phone_number'
            isphone={true}
            component={Input}
            label={<span className='semi-bold heading-5'>Mobile number</span>}
            placeholder='Mobile Number'
          />
          {recoverAccountEmailError && (
            <p style={{ textAlign: 'left' }} className='text-error w-100'>
              <i className='fas fa-exclamation-triangle m-r-xs'></i>
              Hmm... the information you provided did not match our records. Please review for
              accuracy and try again, or{' '}
              <a
                style={{
                  textDecoration: 'underline',
                }}
                href='mailto:support@zencare.co'
              >
                contact the Zencare team
              </a>
              .
            </p>
          )}
          {loading ? (
            <Loader />
          ) : (
            <button className='primary md' disabled={invalid} onClick={handleSubmit}>
              Continue
            </button>
          )}
        </div>
      )}
    />
  );
};

export default connect((state: $TSFixMe) => state.auth, {
  recoverAccountEmail: actions.recoverAccountEmail,
})(RecoverAccount);
