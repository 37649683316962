import stateAbbrDict from '#/stateAbbrDict';

export const products = {
  BASIC_NY: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Zencare Membership (NY/CA)',
    LOOKUP_KEY: 'zencare_basic_monthly_ny_v4',
    PRICE: { MEMBERSHIP: 69, PRACTICE_MANAGEMENT: 0 },
    RECURRING: true,
  },
  BASIC: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Zencare Membership',
    LOOKUP_KEY: 'zencare_basic_monthly_v3',
    PRICE: { MEMBERSHIP: 59, PRACTICE_MANAGEMENT: 0 },
    RECURRING: true,
  },
  PROFESSIONAL_NY: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Zencare Professional (NY/CA)',
    LOOKUP_KEY: 'zencare_professional_monthly_ny_v4',
    PRICE: { MEMBERSHIP: 69, PRACTICE_MANAGEMENT: 19 },
    RECURRING: true,
  },
  PROFESSIONAL: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Zencare Professional',
    LOOKUP_KEY: 'zencare_professional_monthly_v3',
    PRICE: { MEMBERSHIP: 59, PRACTICE_MANAGEMENT: 19 },
    RECURRING: true,
  },
  PREMIUM_NY: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Zencare Premium (NY/CA)',
    LOOKUP_KEY: 'zencare_premium_monthly_ny_v4',
    PRICE: { MEMBERSHIP: 69, PRACTICE_MANAGEMENT: 39 },
    RECURRING: true,
  },
  PREMIUM: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Zencare Premium',
    LOOKUP_KEY: 'zencare_premium_monthly_v3',
    PRICE: { MEMBERSHIP: 59, PRACTICE_MANAGEMENT: 39 },
    RECURRING: true,
  },
  COPYWRITING: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Professional Copywriting',
    LOOKUP_KEY: 'add_on_professional_copywriting_v1',
    PRICE: 70,
    RECURRING: false,
  },
  SETUP_REMOTE: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'One-Time Setup Fee',
    LOOKUP_KEY: 'setup_remote_photo_videoshoot_v1',
    PRICE: 80,
    RECURRING: false,
  },
  SETUP_REMOTE_PRACTICE: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'One-Time Setup Fee',
    LOOKUP_KEY: 'setup_remote_photo_videoshoot_group_practice_v1',
    PRICE: 100,
    RECURRING: false,
  },
  SETUP_INDIVIDUAL: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'One-Time Setup Fee',
    LOOKUP_KEY: 'setup_in_person_photo_videoshoot_v1',
    PRICE: 130,
    RECURRING: false,
  },
  SETUP_PRACTICE: {
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'One-Time Setup Fee',
    LOOKUP_KEY: 'setup_in_person_photo_videoshoot_group_practice_v1',
    PRICE: 200,
    RECURRING: false,
  },
  REIMBURSIFY_PRO: {
    // Note: This is the product name (even though the lookup key is for the price)
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Private Pay Booster Pro',
    LOOKUP_KEY: 'reimbursify_pro_monthly_v2',
    PRICE: 29,
    RECURRING: true,
  },
  REIMBURSIFY_PREMIUM: {
    // Note: This is the product name (even though the lookup key is for the price)
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Private Pay Booster Premium',
    LOOKUP_KEY: 'reimbursify_premium_monthly_v2',
    PRICE: 59,
    RECURRING: true,
  },
  PRACTICE_MANAGEMENT_PRO_STANDALONE: {
    // Note: This is the product name (even though the lookup key is for the price)
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Practice Management Pro',
    LOOKUP_KEY: 'zpm_pro_monthly_v1',
    PRICE: 49,
    RECURRING: true,
    TIER: 'professional',
  },
  PRACTICE_MANAGEMENT_PREMIUM_STANDALONE: {
    // Note: This is the product name (even though the lookup key is for the price)
    EXACT_PRODUCT_NAME_FROM_STRIPE: 'Practice Management Premium',
    LOOKUP_KEY: 'zpm_premium_monthly_v1',
    PRICE: 69,
    RECURRING: true,
    TIER: 'premium',
  },
};

/* eslint-disable*/
const constants = {
  subscriptions_individual_monthly: {
    ny: {
      lookup_key: products.BASIC_NY.LOOKUP_KEY,
      price_per_month:
        products.BASIC_NY.PRICE.MEMBERSHIP + products.BASIC_NY.PRICE.PRACTICE_MANAGEMENT,
    },
    standard: {
      lookup_key: products.BASIC.LOOKUP_KEY,
      price_per_month: products.BASIC.PRICE.MEMBERSHIP + products.BASIC.PRICE.PRACTICE_MANAGEMENT,
    },
  },
  subscriptions_individual_monthly_professional: {
    ny: {
      lookup_key: products.PROFESSIONAL_NY.LOOKUP_KEY,
      price_per_month:
        products.PROFESSIONAL_NY.PRICE.MEMBERSHIP +
        products.PROFESSIONAL_NY.PRICE.PRACTICE_MANAGEMENT,
    },
    standard: {
      lookup_key: products.PROFESSIONAL.LOOKUP_KEY,
      price_per_month:
        products.PROFESSIONAL.PRICE.MEMBERSHIP + products.PROFESSIONAL.PRICE.PRACTICE_MANAGEMENT,
    },
  },
  subscriptions_individual_monthly_premium: {
    ny: {
      lookup_key: products.PREMIUM_NY.LOOKUP_KEY,
      price_per_month:
        products.PREMIUM_NY.PRICE.MEMBERSHIP + products.PREMIUM_NY.PRICE.PRACTICE_MANAGEMENT,
    },
    standard: {
      lookup_key: products.PREMIUM.LOOKUP_KEY,
      price_per_month:
        products.PREMIUM.PRICE.MEMBERSHIP + products.PREMIUM.PRICE.PRACTICE_MANAGEMENT,
    },
  },

  professional_copywriting: {
    lookup_key: products.COPYWRITING.LOOKUP_KEY,
    price: products.COPYWRITING.PRICE,
  },

  // reimbursify: {
  //   lookup_key: products.REIMBURSIFY.LOOKUP_KEY,
  //   price: products.REIMBURSIFY.PRICE,
  // },
  reimbursify_professional: {
    lookup_key: products.REIMBURSIFY_PRO.LOOKUP_KEY,
    price: products.REIMBURSIFY_PRO.PRICE,
  },
  reimbursify_premium: {
    lookup_key: products.REIMBURSIFY_PREMIUM.LOOKUP_KEY,
    price: products.REIMBURSIFY_PREMIUM.PRICE,
  },
  practice_management_pro_standalone: {
    lookup_key: products.PRACTICE_MANAGEMENT_PRO_STANDALONE.LOOKUP_KEY,
    price: products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE.PRICE,
  },
  practice_management_premium_standalone: {
    lookup_key: products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE.LOOKUP_KEY,
    price: products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE.PRICE,
  },

  setup_remote: [
    {
      name: 'Basic Remote',
      reservedName: 'remote-basic',
      priority: 0,
      lookup_key: products.SETUP_REMOTE.LOOKUP_KEY,
      price: products.SETUP_REMOTE.PRICE,
      blurb: 'Remote photo/videoshoot with trained Zencare photographer',
      features: [
        {
          name: 'A video call with a trained Zencare photographer who will coach you through how to take polished photos and videos with your smartphone',
        },
        {
          name: 'In advance of the call, we’ll send you a robust guide with instructions on how to prepare and what to expect.',
        },
      ],
      isThisForMe:
        'This is a great option if you have an idea of how you’d like to frame your practice, and are excited to write your profile content.',
    },
  ],

  setup_remote_practice: [
    {
      name: 'Group Remote',
      reservedName: 'remote-practice',
      priority: 0,
      lookup_key: products.SETUP_REMOTE_PRACTICE.LOOKUP_KEY,
      price: products.SETUP_REMOTE_PRACTICE.PRICE,
      blurb: 'Remote photo/videoshoot with trained Zencare photographer',
      features: [
        {
          name: 'A video call with a trained Zencare photographer who will coach you through how to take polished photos and videos with your smartphone',
        },
        {
          name: 'In advance of the call, we’ll send you a robust guide with instructions on how to prepare and what to expect.',
        },
      ],
      isThisForMe:
        'This is a great option if you have an idea of how you’d like to frame your practice, and are excited to write your profile content.',
    },
  ],

  setup_individual: [
    {
      name: 'Basic',
      reservedName: 'basic',
      priority: 0,
      lookup_key: products.SETUP_INDIVIDUAL.LOOKUP_KEY,
      price: products.SETUP_INDIVIDUAL.PRICE,
      blurb: '50min photo/videoshoot',
      features: [
        { name: 'A 50 minute photo and videoshoot at your office with a trained photographer' },
      ],
      isThisForMe:
        'This is a great option if you have an idea of how you’d like to frame your practice, and are excited to write your profile content.',
    },
  ],
  setup_practice: [
    {
      name: 'Professional',
      reservedName: 'professional',
      priority: 1,
      lookup_key: products.SETUP_PRACTICE.LOOKUP_KEY,
      price: products.SETUP_PRACTICE.PRICE,
      blurb: '90min photo and videoshoot onsite, script review by Zencare team',
      features: [
        { name: 'A 90 minute photo and videoshoot at your office with a trained photographer' },
        { name: 'A review of your video script by the Zencare team' },
      ],
      isThisForMe:
        'This is a popular option for therapists who are looking for some extra support around the video; we’ll send you direct edits and feedback on your script to ensure it speaks to your ideal clients, and you’ll have time for extra video takes with your trained photographer.',
    },
  ],

  // TODO THESE WILL NOT SUFFICE FUTURE ITERATIONS OF PRODUCT MODALS
  product_descriptions: {
    zencare_membership: {
      name: 'Zencare Membership',
      frequency: 'monthly',
      price: '59',
      blurb: '90min photo and videoshoot onsite, script review by Zencare team',
      features: [
        { detail: 'Unlimited client referrals who are a great fit for your practice' },
        { detail: 'High quality headshots and video introduction' },
        { detail: 'A modern booking tool to convert referrals into clients' },
        { detail: 'A secure messaging tool to communicate with prospective clients' },
      ],
      rating: 4,
      testimonial_source: '— Lisa, Massachusetts',
      testimonial:
        'Joining Zencare was the single best thing I have done to help me grow my practice with clients who are a great match for my skills, my approach, and my personality.',
      description:
        "Zencare is the online destination for clients to start therapy and therapists to grow their practice. With a beautiful profile, high-quality headshots and video introduction, and a modern booking tool, we make it easy for clients to find and connect with your unique practice. We've connected hundreds of thousands of therapy seekers with clinically excellent and compassionate therapists and psychiatrists nationwide.",
    },
    zencare_practice_management: {
      name: 'Zencare Practice Management',
      frequency: 'monthly',
      price: '19-39',
      blurb: '90min photo and videoshoot onsite, script review by Zencare team',
      features: [
        {
          detail:
            'Intuitive calendar that supports multiple locations and third-party calendar sync',
        },
        { detail: 'Fully HIPAA compliant and integrated video therapy designed for therapists' },
        {
          detail:
            'Simplified payments to invoice, process credit cards, and track your practice growth',
        },
        { detail: 'Customized and detailed superbills for out-of-network billing' },
        {
          detail:
            'Secure session note storage, client appointment reminders, and to-do lists for each client',
        },
      ],
      rating: 4,
      testimonial_source: '— Vivienne, Mental Health Care Professional',
      testimonial:
        'I love this software. Amazing customer support that helped me with all my questions and met one-on-one with me when I needed to navigate setting up the system.',
      description:
        "Zencare Practice Management is the most comprehensive and compliant suite of therapy practice solutions. Take your practice to the next level with secure video therapy, consent forms, clinical measures, online intake, invoicing, payments, medication tracking, and more. Manage your business with ease so you can spend more time doing what you're best at: spending more time with your clients.",
    },
    // Not entirely sure if this is needed
    zencare_practice_management_standalone: {
      name: 'Zencare Practice Management',
      frequency: 'monthly',
      price: '49-69',
      features: [
        {
          detail:
            'Intuitive calendar that supports multiple locations and third-party calendar sync',
        },
        { detail: 'Fully HIPAA compliant and integrated video therapy designed for therapists' },
        {
          detail:
            'Simplified payments to invoice, process credit cards, and track your practice growth',
        },
        { detail: 'Customized and detailed superbills for out-of-network billing' },
        {
          detail:
            'Secure session note storage, client appointment reminders, and to-do lists for each client',
        },
      ],
      rating: 4,
      testimonial_source: '— Vivienne, Mental Health Care Professional',
      testimonial:
        'I love this software. Amazing customer support that helped me with all my questions and met one-on-one with me when I needed to navigate setting up the system.',
      description:
        "Zencare Practice Management is the most comprehensive and compliant suite of therapy practice solutions. Take your practice to the next level with secure video therapy, consent forms, clinical measures, online intake, invoicing, payments, medication tracking, and more. Manage your business with ease so you can spend more time doing what you're best at: spending more time with your clients.",
    },
    professional_copywriting: {
      name: 'Professional Copywriting',
      frequency: 'once',
      price: '70',
      blurb: '90min photo and videoshoot onsite, script review by Zencare team',
      features: [
        {
          detail:
            'Professional statement written by our trained copywriters showcasing your practice',
        },
        { detail: 'A review of your video script and suggested edits by our copywriters' },
      ],
      rating: 4,
      testimonial_source: '— Therapist, Massachusetts',
      testimonial:
        'Zencare is exactly as advertised. My profile represents me very well; it’s something I’m really proud to share with colleagues and friends.',
      description:
        'Copywriting services to highlight your expertise, clinical style, and unique practice features. Includes a professional statement showcasing your unique practice and extensive experience, and a review of your video script for the Zencare photo and videoshoot. Help clients find the right fit for you through copy that is singularly tailored to showcase your skills and strengths as a therapist.',
    },
    reimbursify: {
      name: 'Private Pay Booster',
      frequency: 'monthly',
      price: '29-59',
      blurb:
        "Instantly verify your clients' out-of-network benefits, and turn more referrals into ongoing clients.",
      features: [
        {
          detail: 'Real-time verification of out-of-network mental health benefits.',
        },
        {
          detail:
            'Up-to-date information on deductibles, amount satisfied to date, and anticipated reimbursement for your out-of-network services.',
        },
        {
          detail: 'Cost-free, claim filing for your clients through the Reimbursify mobile app.',
        },
      ],
      rating: 4,
      testimonial_source: '—- Brenda Lewis, LCSW',
      testimonial:
        'Obtaining a verification of benefits helps me inform clients of their actual out-of-pocket costs, making that first appointment booking easier.',
      description:
        'With this easy-to-use tool, powered by Reimbursify, you can verify a client’s out-of-network benefits, before they even book. This will turn more referrals and in-bound inquiries into on-going clients by instantly checking deductibles, how much deductible is satisfied to date, and how much reimbursement they can expect for your out-of-network care. For existing clients, give them the ability to file claims for free. Clients who receive reimbursements miss fewer appointments and stay in treatment longer — increasing your practice revenue! They also tend to be more satisfied with your practice and create more personal referrals.',
    },
  },
  summary_items: [
    {
      lookup_key: products.BASIC_NY.LOOKUP_KEY,
      tier: 'basic',
      exactStripeProductNameForCouponMatching: products.BASIC_NY.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Membership',
          price: products.BASIC_NY.PRICE.MEMBERSHIP,
          locale_price: products.BASIC.PRICE.MEMBERSHIP,
          recurring: products.BASIC_NY.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.BASIC.LOOKUP_KEY,
      tier: 'basic',
      exactStripeProductNameForCouponMatching: products.BASIC.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Membership',
          price: products.BASIC.PRICE.MEMBERSHIP,
          locale_price: products.BASIC_NY.PRICE.MEMBERSHIP,
          recurring: products.BASIC.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.PROFESSIONAL_NY.LOOKUP_KEY,
      tier: 'professional',
      exactStripeProductNameForCouponMatching:
        products.PROFESSIONAL_NY.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Membership',
          price: products.PROFESSIONAL_NY.PRICE.MEMBERSHIP,
          locale_price: products.PROFESSIONAL.PRICE.MEMBERSHIP,
          recurring: products.PROFESSIONAL_NY.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          description: 'Practice Management Pro',
          price: products.PROFESSIONAL_NY.PRICE.PRACTICE_MANAGEMENT,
          locale_price: products.PROFESSIONAL.PRICE.PRACTICE_MANAGEMENT,
          recurring: products.PROFESSIONAL_NY.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.PROFESSIONAL.LOOKUP_KEY,
      tier: 'professional',
      exactStripeProductNameForCouponMatching: products.PROFESSIONAL.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Membership',
          price: products.PROFESSIONAL.PRICE.MEMBERSHIP,
          locale_price: products.PROFESSIONAL_NY.PRICE.MEMBERSHIP,
          recurring: products.PROFESSIONAL.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          description: 'Practice Management Pro',
          price: products.PROFESSIONAL.PRICE.PRACTICE_MANAGEMENT,
          locale_price: products.PROFESSIONAL_NY.PRICE.PRACTICE_MANAGEMENT,
          recurring: products.PROFESSIONAL.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.PREMIUM_NY.LOOKUP_KEY,
      tier: 'premium',
      exactStripeProductNameForCouponMatching: products.PREMIUM_NY.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Membership',
          price: products.PREMIUM_NY.PRICE.MEMBERSHIP,
          locale_price: products.PREMIUM.PRICE.MEMBERSHIP,
          recurring: products.PREMIUM_NY.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          description: 'Practice Management Premium',
          price: products.PREMIUM_NY.PRICE.PRACTICE_MANAGEMENT,
          locale_price: products.PREMIUM.PRICE.PRACTICE_MANAGEMENT,
          recurring: products.PREMIUM_NY.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.PREMIUM.LOOKUP_KEY,
      tier: 'premium',
      exactStripeProductNameForCouponMatching: products.PREMIUM.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Membership',
          price: products.PREMIUM.PRICE.MEMBERSHIP,
          locale_price: products.PREMIUM_NY.PRICE.MEMBERSHIP,
          recurring: products.PREMIUM.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          description: 'Practice Management Premium',
          price: products.PREMIUM.PRICE.PRACTICE_MANAGEMENT,
          locale_price: products.PREMIUM_NY.PRICE.PRACTICE_MANAGEMENT,
          recurring: products.PREMIUM.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.COPYWRITING.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching: products.COPYWRITING.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Professional Copywriting',
          price: products.COPYWRITING.PRICE,
          locale_price: 0,
          recurring: products.COPYWRITING.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.SETUP_REMOTE.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching: products.SETUP_REMOTE.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'One-Time Setup Fee',
          price: products.SETUP_REMOTE.PRICE,
          locale_price: 0,
          recurring: products.SETUP_REMOTE.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.SETUP_REMOTE_PRACTICE.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching:
        products.SETUP_REMOTE_PRACTICE.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'One-Time Setup Fee',
          price: products.SETUP_REMOTE_PRACTICE.PRICE,
          locale_price: 0,
          recurring: products.SETUP_REMOTE_PRACTICE.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.SETUP_INDIVIDUAL.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching:
        products.SETUP_INDIVIDUAL.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'One-Time Setup Fee',
          price: products.SETUP_INDIVIDUAL.PRICE,
          locale_price: 0,
          recurring: products.SETUP_INDIVIDUAL.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.SETUP_PRACTICE.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching:
        products.SETUP_PRACTICE.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'One-Time Setup Fee',
          price: products.SETUP_PRACTICE.PRICE,
          locale_price: 0,
          recurring: products.SETUP_PRACTICE.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching:
        products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Practice Management Premium',
          price: products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE.PRICE,
          locale_price: 0,
          recurring: products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE.RECURRING,
        },
      ],
    },
    {
      lookup_key: products.PRACTICE_MANAGEMENT_PRO_STANDALONE.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching:
        products.PRACTICE_MANAGEMENT_PRO_STANDALONE.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Zencare Practice Management Premium',
          price: products.PRACTICE_MANAGEMENT_PRO_STANDALONE.PRICE,
          locale_price: 0,
          recurring: products.PRACTICE_MANAGEMENT_PRO_STANDALONE.RECURRING,
        },
      ],
    },
    // {
    //   lookup_key: products.REIMBURSIFY.LOOKUP_KEY,
    //   exactStripeProductNameForCouponMatching: products.REIMBURSIFY.EXACT_PRODUCT_NAME_FROM_STRIPE,
    //   items: [
    //     {
    //       description: 'Private Pay Booster',
    //       price: products.REIMBURSIFY.PRICE,
    //       locale_price: products.REIMBURSIFY.PRICE,
    //       recurring: products.REIMBURSIFY.RECURRING,
    //       blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    //     },
    //   ],
    // },
    {
      lookup_key: products.REIMBURSIFY_PRO.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching:
        products.REIMBURSIFY_PRO.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Private Pay Booster Professional',
          price: products.REIMBURSIFY_PRO.PRICE,
          locale_price: products.REIMBURSIFY_PRO.PRICE,
          recurring: products.REIMBURSIFY_PRO.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    {
      lookup_key: products.REIMBURSIFY_PREMIUM.LOOKUP_KEY,
      exactStripeProductNameForCouponMatching:
        products.REIMBURSIFY_PREMIUM.EXACT_PRODUCT_NAME_FROM_STRIPE,
      items: [
        {
          description: 'Private Pay Booster Premium',
          price: products.REIMBURSIFY_PREMIUM.PRICE,
          locale_price: products.REIMBURSIFY_PREMIUM.PRICE,
          recurring: products.REIMBURSIFY_PREMIUM.RECURRING,
          blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
  ],
  plan_items: {
    [products.BASIC.LOOKUP_KEY]: {
      text: 'Unlimited referrals, messaging, and booking to grow your practice.',
      price: products.BASIC.PRICE.MEMBERSHIP,
      recurring: products.BASIC.RECURRING,
    },

    [products.BASIC_NY.LOOKUP_KEY]: {
      text: 'Unlimited referrals, messaging, and booking to grow your practice.',
      price: products.BASIC_NY.PRICE.MEMBERSHIP,
      recurring: products.BASIC_NY.RECURRING,
    },

    [products.PROFESSIONAL.LOOKUP_KEY]: {
      text: 'Unlimited referrals, messaging, and booking to grow your practice.',
      price: products.PROFESSIONAL.PRICE.MEMBERSHIP,
      recurring: products.PROFESSIONAL.RECURRING,
    },

    [products.PROFESSIONAL_NY.LOOKUP_KEY]: {
      text: 'Unlimited referrals, messaging, and booking to grow your practice.',
      price: products.PROFESSIONAL_NY.PRICE.MEMBERSHIP,
      recurring: products.PROFESSIONAL_NY.RECURRING,
    },

    [products.PREMIUM.LOOKUP_KEY]: {
      text: 'Unlimited referrals, messaging, and booking to grow your practice.',
      price: products.PREMIUM.PRICE.MEMBERSHIP,
      recurring: products.PREMIUM.RECURRING,
    },

    [products.PREMIUM_NY.LOOKUP_KEY]: {
      text: 'Unlimited referrals, messaging, and booking to grow your practice.',
      price: products.PREMIUM_NY.PRICE.MEMBERSHIP,
      recurring: products.PREMIUM_NY.RECURRING,
    },

    [products.PREMIUM_NY.LOOKUP_KEY]: {
      text: 'Unlimited referrals, messaging, and booking to grow your practice.',
      price: products.PREMIUM_NY.PRICE.MEMBERSHIP,
      recurring: products.PREMIUM_NY.RECURRING,
    },

    [products.SETUP_INDIVIDUAL.LOOKUP_KEY]: {
      text: '+ One-time in-person professional photo/videoshoot.',
      price: products.SETUP_INDIVIDUAL.PRICE,
      recurring: products.SETUP_INDIVIDUAL.RECURRING,
    },

    [products.SETUP_PRACTICE.LOOKUP_KEY]: {
      text: '+ One-time in-person professional photo/videoshoot for your group practice.',
      price: products.SETUP_PRACTICE.PRICE,
      recurring: products.SETUP_PRACTICE.RECURRING,
    },

    [products.SETUP_REMOTE.LOOKUP_KEY]: {
      text: '+ One-time remote professional photo/videoshoot.',
      price: products.SETUP_REMOTE.PRICE,
      recurring: products.SETUP_REMOTE.RECURRING,
    },

    // TODO: Delete if this gets to code review. I believe that commenting this prevents this from displaying in MembershipBox
    // MORE TODO: MAKE THIS WORK THOUGH?!
    // [products.REIMBURSIFY.LOOKUP_KEY]: {
    //   text: 'Broaden your network of potential clients',
    //   price: products.REIMBURSIFY.PRICE,
    //   recurring: products.REIMBURSIFY.RECURRING,
    // },
  },
};

// 50 states + DC
const statesAbbrAccepting = [
  'CA',
  'CO',
  'CT',
  'FL',
  'GA',
  'IL',
  'MA',
  'NJ',
  'NY',
  'RI',
  'TX',
  'WA',
  'DC',
  'AZ',
  'MI',
  'OR',
  'PA',
  'NC',
  'IN',
  'MN',
  'NV',
  'OH',
  'OK',
  'TN',
  'UT',
  'VA',
  'MD',
  'LA',
  'NH',
  'KY',
  'AL',
  'MO',
  'AR',
  'IA',
  'KS',
  'SC',
  'WI',
  'ID',
  'ME',
  'NM',
  'DE',
  'NE',
  'HI',
  'WV',
  'WY',
  'VT',
  'AK',
  'MS',
  'MT',
  'ND',
  'SD',
];

// @ts-ignore: $TSFixMe
const statesFullAccepting = statesAbbrAccepting.map((x) => stateAbbrDict[x]);

export const statesAccepting = statesAbbrAccepting.concat(statesFullAccepting);

const statesAbbrWithPhotographers = [
  'AZ',
  'CA',
  'CO',
  'CT',
  'FL',
  'GA',
  'IL',
  'IN',
  'LA',
  'MD',
  'MA',
  'MI',
  'MN',
  'NV',
  'NJ',
  'NY',
  'NC',
  'OH',
  'OR',
  'PA',
  'RI',
  'TN',
  'UT',
  'TX',
  'WA',
  'DC',
];

// @ts-ignore: $TSFixMe
const statesFullWithPhotographers = statesAbbrWithPhotographers.map((x) => stateAbbrDict[x]);
export const statesWithPhotographers = statesAbbrWithPhotographers.concat(
  statesFullWithPhotographers
);

const statesAbbrComingSoon = ['OK', 'VA'];

// @ts-ignore: $TSFixMe
const statesFullComingSoon = statesAbbrComingSoon.map((x) => stateAbbrDict[x]);
export const statesComingSoon = statesAbbrComingSoon.concat(statesFullComingSoon);

// TYPES - SHOW THIS AT CODE REVIEW!!
export type Constants = typeof constants;
// This is kinda the worst but it works?
export type Plan = typeof constants.subscriptions_individual_monthly.ny;
export type SummaryItems = typeof constants.summary_items;
export type SummaryItemType = (typeof constants.summary_items)[0];
export type SummaryItemItemsType = (typeof constants.summary_items)[0]['items'];
export type SummaryItemItemType = (typeof constants.summary_items)[0]['items'][0];
export type ProductDescriptionType =
  | typeof constants.product_descriptions.professional_copywriting
  | typeof constants.product_descriptions.zencare_membership
  | typeof constants.product_descriptions.zencare_practice_management;

export type SetupOptionType =
  | typeof constants.setup_remote
  | typeof constants.setup_remote_practice
  | typeof constants.setup_individual
  | typeof constants.setup_practice;
// Sauce: https://github.com/microsoft/TypeScript/issues/13949#issuecomment-1228758370

// Not used?
const productsArray = Object.values(products);
const lookupKeys = productsArray.map((item) => item.LOOKUP_KEY);
export type LookupKeys = typeof lookupKeys;

export default constants;
