import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import ToggleSwitch from '+/forms/ToggleStateSwitch';
import { flashError } from '+/flashes/redux';
import { actions as mainActions } from '../../../main/sagaSlice';
import { dispatch } from '~/store';

export const ContactContainer = ({ provider, locations, updateProvider, contactZencare }) => {
  const [disabled, setDisabled] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const disableConsultation = locations.reduce((acc, curr) => {
      if (curr.accepting_patients) acc = true;

      return acc;
    }, false);

    setDisabled(!disableConsultation);
  }, [locations]);

  const onSubmit = (value, type) => {
    if (type === 'display_acuity_scheduling_link' && !display_contact_form) {
      dispatch(
        flashError(
          'Alert! Turning both contact options off means clients can’t reach you! Please keep at least one contact method on. If you’d like both turned off, please contact the Zencare team.'
        )
      );

      return;
    }

    if (type === 'display_acuity_scheduling_link' && (!acuity_link || disabled)) {
      return;
    }

    if (type === 'display_contact_form' && !display_acuity_link) {
      dispatch(
        flashError(
          'Alert! Turning both contact options off means clients can’t reach you! Please keep at least one contact method on. If you’d like both turned off, please contact the Zencare team.'
        )
      );

      return;
    }

    if (type === 'display_contact_form' && !value.display_contact_form) {
      if (
        !window.confirm(
          'Are you sure you want to hide your contact form? 75% of client inquiries are made via the contact form, so we strongly recommend keeping it!'
        )
      )
        return;
    }

    updateProvider({
      id: provider.id,
      custom_content: value,
    });
  };

  const display_acuity_link = get(provider, 'custom_content.display_acuity_scheduling_link', false);
  const acuity_link = get(provider, 'custom_content.acuity_link');
  const display_contact_form = get(provider, 'custom_content.display_contact_form', false);

  return (
    <div className='box m-b-md mobile-border-bottom w-100'>
      <div style={{ width: '100%' }}>
        <h3 className='title m-b-xs'>Contact Methods</h3>
        <h5>How can clients contact you?</h5>
        <div className='toggle-items'>
          <div
            className='item flex justify-between'
            style={{ background: !acuity_link || disabled ? '#d4d4d4' : '' }}
          >
            <h6>Phone Consultation</h6>
            <div className='switch'>
              <ToggleSwitch
                type='contact'
                label='display_acuity_scheduling_link'
                checked={display_acuity_link}
                updateFn={(value) => onSubmit(value, 'display_acuity_scheduling_link')}
              />
            </div>
          </div>
          <div className='item flex justify-between'>
            <h6>Contact Form</h6>
            <div className='switch primary'>
              <ToggleSwitch
                type='contact'
                label='display_contact_form'
                checked={display_contact_form}
                updateFn={(value) => onSubmit(value, 'display_contact_form')}
              />
            </div>
          </div>
        </div>
        {!acuity_link && (
          <>
            <button className='pill primary md m-t-sm' onClick={() => history.push('/contact')}>
              Contact Support to Enable Phone Consultations
            </button>
          </>
        )}
        {acuity_link && (
          <>
            <button className='pill primary md m-t-sm' onClick={() => history.push('/contact')}>
              Contact Support to Update Phone Consultation Times
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ main, auth }) => ({
  provider: main.provider,
  locations: Object.values(main.locations),
});
const actions = {
  updateProvider: mainActions.updateProvider,
  contactZencare: mainActions.contactZencare,
};

export default connect(mapStateToProps, actions)(ContactContainer);
