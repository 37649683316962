import React from 'react';
import { HashLink } from 'react-router-hash-link';

import PureModal from '+/Modal';

export default function NudgeLicenseWithoutLocationModal({ open, toggle }) {
  return (
    <PureModal
      visible={open}
      additionalCssClasses='shorter-modal'
      closeModal={() => toggle(false)}
      headerContent={<h4>Update locations?</h4>}
    >
      <h5>
        Congratulations on getting a new license! Make sure you update your practice locations so
        you show up on the right search pages.
      </h5>

      <HashLink
        className='link-as-button'
        to='/profile/availability-locations#practice-locations-header'
      >
        Review Locations
      </HashLink>
    </PureModal>
  );
}
