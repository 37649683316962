import React from 'react';
import { connect } from 'react-redux';

const ZencareHubspotForm = ({ region, portalId, formId, formData }) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId,
          region,
          target: '#hubspot-form',
          onFormReady: (form, ctx) => {
            Object.entries(formData).forEach(([fieldName, value]) => {
              const inputField = form.querySelector(`input[name="${fieldName}"]`);
              if (inputField) {
                inputField.value = value;
                inputField.dispatchEvent(new Event('input', { bubbles: true }));
                inputField.dispatchEvent(new Event('change'));
              }
            });
          },
        });
      }
    });
  }, [portalId, formId, region, formData]);

  return (
    <div>
      <div id='hubspot-form'></div>
    </div>
  );
};

function ContactZencare({ provider, account, user }) {
  // Hubspot form data
  const formData = {
    email: user.email,
    firstname: provider.first_name,
    lastname: provider.last_name,
    provider_id: provider.id,
    account_id: account.id,
    user_id: user.id,
  };

  return (
    <>
      <h1 className='m-b-lg'>Contact the Zencare Team</h1>
      <div className='flex mobile-column'>
        <img
          className='contact-image'
          src='https://zencare.s3.us-east-2.amazonaws.com/general_site/team/Michelle+Klejmont_Zencare.png'
          alt='Michelle Klejmont'
        ></img>

        <div>
          <h5 className='m-b-xs'>
            Hi {provider.first_name || provider.company_name}! How can we help you?
          </h5>
          <p className='m-b-0'>
            I manage provider operations at Zencare and am here to support you.
          </p>
          <p className='m-b-0'>
            Let us know how we can be of help below! We'll be in touch within 24 business hours.
          </p>
          <p className='m-b-0'>Hope you are having a wonderful day so far! </p>
          <p>— Michelle</p>
        </div>
      </div>

      {/* Wait until hidden field values are available before rendering form */}
      {formData.provider_id && formData.account_id && (
        <ZencareHubspotForm
          portalId='19873989'
          formId='b8c15366-2844-41af-93c0-10e629b61f02'
          region='na1'
          account_id={account && account.id}
          formData={formData}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ main, auth }) => ({
  provider: main.provider,
  account: main.accounts && main.accounts.length && main.accounts[0],
  user: auth,
});
const actions = {};

export default connect(mapStateToProps, actions)(ContactZencare);
