import { put, select, call, delay, take } from 'redux-saga/effects';
import _ from 'lodash';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import { api } from '#/apis';
import history from '#/history';
import { flashSuccess } from '+/flashes/redux';

const sagaSliceModule = createModule({
  name: 'payment',
  initialState: {
    isLoading: false,
    coupon: null,
    checkoutId: null,
  },
  reducers: {
    subscribeToPlan: loadingReducer,
    subscribeToPlanSuccess: notLoadingReducer,
    subscribeToPlanFailure: failReducer,

    resubscribeToPlan: loadingReducer,
    resubscribeToPlanSuccess: notLoadingReducer,
    resubscribeToPlanFailure: failReducer,

    getUpcomingInvoice: loadingReducer,
    getUpcomingInvoiceSuccess: notLoadingReducer,
    getUpcomingInvoiceFailure: failReducer,

    updateSource: loadingReducer,
    updateSourceSuccess: notLoadingReducer,
    updateSourceFailure: failReducer,

    checkCoupon: loadingReducer,
    checkCouponSuccess: notLoadingReducer,
    checkCouponFailure: failReducer,

    getCheckoutId: loadingReducer,
    getCheckoutIdSuccess: (state, payload) => {
      state.isLoading = false;
      state.checkoutId = payload;
    },
    getCheckoutIdFailure: failReducer,

    getPriceProduct: loadingReducer,
    getPriceProductSuccess: notLoadingReducer,
    getPriceProductFailure: failReducer,

    updateAddress: loadingReducer,
    updateAddressFailure: failReducer,
    updateAddressSuccess: notLoadingReducer,
  },

  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.subscribeToPlan]: function* ({ payload }) {
        try {
          let source = payload.source;

          if (!source && payload.sourcePromise) {
            const result = yield call(payload.sourcePromise);

            if (result.error) {
              return payload.onError(result.error);
            }

            source = result.source;
          }

          const subPayload = {
            source,
            lookup_key: payload.lookup_key,
            setup_lookup_key: payload.setup_lookup_key,
            coupon: payload.coupon,
            referrer: payload.referrer,
          };

          const { data } = yield api.post(`/portal/subscribe/${payload.provider_id}`, subPayload, {
            timeout: 15000,
          });

          yield put({ type: 'main/getMe' });
          yield put(A.subscribeToPlanSuccess());
          yield put({ type: 'main/getAllAccountDetails' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          payload.onSuccess();
        } catch (err) {
          yield put(A.subscribeToPlanFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.resubscribeToPlan]: function* ({ payload }) {
        try {
          let source = payload.source;

          if (!source && payload.sourcePromise) {
            const result = yield call(payload.sourcePromise);

            if (result.error) {
              return payload.onError(result.error);
            }

            source = result.source;
          }

          const { data } = yield api.post(
            `/portal/resubscribe/${payload.customer.id}/${payload.subscription.id}`,
            {
              source,
              coupon: payload.coupon,
            },
            { timeout: 150000 }
          );

          yield put(A.resubscribeToPlanSuccess());

          payload.onResubscribed(data);
        } catch (err) {
          yield put(A.resubscribeToPlanFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.getUpcomingInvoice]: function* ({ payload }) {
        try {
          const { data } = yield api.get(
            `/portal/invoice/upcoming/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
            { timeout: 150000 }
          );

          yield put(A.getUpcomingInvoiceSuccess());

          payload.onSuccess(data);
        } catch (err) {
          yield put(A.getUpcomingInvoiceFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.updateSource]: function* ({ payload }) {
        try {
          const oldSource = payload.oldSource;
          let newSource = payload.newSource;

          if (!newSource && payload.sourcePromise) {
            const { source, error } = yield call(payload.sourcePromise);

            if (error) {
              return payload.onError(error);
            }

            newSource = source;
          }

          const sourcePayload = {
            oldSource,
            newSource,
          };

          yield api.post(`/portal/update-source/${payload.customer_id}`, sourcePayload, {
            timeout: 1500000,
          });

          yield put({ type: 'main/getMe' });
          yield take([
            'main/getMeSuccess',
            'main/getMeFailure',
            'main/getAllMyProvidersSuccess',
            'main/getAllMyProvidersFailure',
          ]);
          yield put({ type: 'main/getAllAccountDetails' });
          yield take(['main/getAllAccountDetailsSuccess', 'main/getAllAccountDetailsFailure']);
          yield put(A.updateSourceSuccess());

          payload.onUpdated();
        } catch (err) {
          console.error(err);

          yield put(A.updateSourceFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.checkCoupon]: function* ({ payload }) {
        try {
          const response = yield api.get(`/portal/coupon/${payload.coupon}`);

          yield put(A.checkCouponSuccess());

          return payload.onSuccess(response.data);
        } catch (err) {
          yield put(A.checkCouponFailure());

          return payload.onError(
            {
              message: _.get(err, 'response.data.message'),
            } || err
          );
        }
      },
      [A.getCheckoutId]: function* ({ payload }) {
        try {
          const response = yield api.post(`/portal/checkout/${payload.priceId}`, {
            customer_id: payload.customer_id,
            coupon: payload.coupon,
          });

          yield put(A.getCheckoutIdSuccess(response.data));
        } catch (err) {
          yield put(A.getCheckoutIdFailure());
        }
      },
      [A.getPriceProduct]: function* ({ payload }) {
        try {
          const { data } = yield api.get(`/portal/price/product/${payload.priceId}`);

          yield history.push('/');
          yield put(A.getPriceProductSuccess());
          yield delay(1000);
          yield put(
            flashSuccess(
              `Thank you for purchase of ${data.product.name}! Please check your email for next steps.`
            )
          );
        } catch (err) {
          yield history.push('/');
          yield put(A.getPriceProductFailure());
        }
      },
      [A.updateAddress]: function* ({ payload: { params, apiPayload } }) {
        try {
          yield api.post(`portal/account/update-address/${params.account_id}`, { ...apiPayload });
          yield put(A.updateAddressSuccess());
        } catch (error) {
          yield put(A.updateAddressFailure());
        }
      },
      [A.updateAddressSuccess]: function* () {
        try {
          // Skipping getAllAccountDetails as this as it causes as full page refresh / loading
          // state on PM. Billing info appears updated post form submission so fetching AD is
          // not necessary
          // yield put({
          //    type: 'main/getAllAccountDetails',
          // });
          yield put(flashSuccess(`Your billing information has been updated!`));
        } catch (error) {
          console.error(error);
        }
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
