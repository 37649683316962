/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import { actions as appointmentActions } from '../appointments/sagaSlice';
import Loader from 'react-loader-spinner';
import AvailabilityModal from './AvailabilityModal.jsx';
import AppointmentModal from '../appointments/AppointmentModal';
import timezones from './timezones.json';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { dispatch } from '~/store';
import useWindowSize from '~/shared/hooks/useWindowSize';
import AppointmentView from '../appointments/AppointmentView';
import PracticeManagementBookingLinkWidget from './PracticeManagementBookingLinkWidget';
// import GoogleCalendarActions from './google-calendar/GoogleCalendarActions';

const adjustedTimezoneList = timezones.map((t) => {
  const info = DateTime.now().isInDST && t.daylight && t.daylight.name ? t.daylight : t.standard;

  return {
    iana: t.iana,
    placename: t.placename,
    ...info,
  };
});

//eslint-disable-next-line
const formatGMTOffset = (t) =>
  t && t.offset
    ? `GMT${t.offset < 0 ? '' : '+'}${parseInt(t.offset)}:${
        t.offset - 0.5 === parseInt(t.offset) ? '30' : '00'
      }`
    : '';

const hourHeight = 50; // must be synchronized with .grid-item.hour
const dayHeight = hourHeight * 24;

export default () => {
  const ref = useRef(null);
  const [width] = useWindowSize();
  const isMobile = width <= 780;
  const [calendarJustOpened, setCalendarJustOpened] = useState(false);

  const {
    calendarStartTime,
    availability,
    tz: defaultTimezone,
    isLoading,
    fullscreen,
  } = useSelector((state) => state.calendars);
  const currentlyOpen = useSelector((state) => state.calendars.currentlyOpen) || {};

  const provider = useSelector((state) => state.main.provider);
  const calendar = _.get(provider, 'calendar[0]');
  const timezone = calendar && calendar.timezone ? calendar.timezone : defaultTimezone;

  const practiceManagement = useSelector((state) => state?.practiceManagement);
  const { firstPracticeManagementAccount } = practiceManagement;

  const shouldShowWidget = Boolean(firstPracticeManagementAccount);
  const linkIsActive = provider?.practice_management_booking_link?.status === 'active';
  const shouldHideNativeCalendar = shouldShowWidget && linkIsActive;

  useEffect(() => {
    if (ref && ref.current && availability) {
      const earliestTime = Math.min(
        ...availability.availabilityBlocks.concat(availability.appointmentBlocks).map((a) => {
          if (timezone) {
            return DateTime.fromISO(a.start, { zone: timezone }).hour;
          }

          return DateTime.fromISO(a.start).hour;
        })
      );

      ref.current.scroll(0, hourHeight * (earliestTime - 1));
    }
  }, [availability, timezone]);

  const hasCalendar = calendar && calendar.id;
  const isOpen = calendar && calendar.open;
  const providerHasAcceptingLocations = provider.locations.some((l) => l.accepting_patients > 0);

  useEffect(() => {
    if (calendarJustOpened && isOpen) {
      setCalendarJustOpened(false);
      openAvailabilityModal(true, true, false);
    }
  }, [calendarJustOpened, isOpen]);

  /***
   * @param {bool} free Add availability
   * @param {bool} recurring Add recurring availability?
   * @param {bool} showTypeToggle Whether to show the Busy/Free type toggle.
   */
  const openAvailabilityModal = (free, recurring, showTypeToggle) => {
    // Start block at 1 day and 1 hour past the closest whole hour in the future
    // e.g. 9:05am -> 11:00am or 1:54pm -> 3:00pm
    const start = DateTime.now().startOf('hour').plus({ days: 1, hours: 2 });
    // Set block duration to one hour
    // e.g. 12:00pm or 4:00pm for examples above
    const end = start.plus({ hours: 1 });

    let recurrence = null;
    if (recurring) {
      recurrence = 'Every Weekday (Monday to Friday)';
    }

    return dispatch(
      actions.openAvailability({
        currentlyOpen: {
          free,
          start,
          end,
        },
        recurrence,
        showTypeToggle,
      })
    );
  };

  const setCalendarStatus = (val) => {
    const payload = {
      calendar_id: _.get(calendar, 'id'),
      timezone,
    };
    if (val === 'closed') {
      payload.open = false;
      dispatch(actions.updateCalendar(payload));
    } else {
      payload.open = true;
      dispatch(actions.updateCalendar(payload));
      alert('A reminder to check and set your timezone if needed.');
      setCalendarJustOpened(true);
    }
  };

  const openCalendarSilently = () => {
    const payload = {
      calendar_id: _.get(calendar, 'id'),
      timezone,
      open: true,
    };

    dispatch(actions.updateCalendar(payload));
  };

  const changeTimezone = (newTZ) => {
    dispatch(actions.setTZ(newTZ));
    dispatch(actions.setStartTime(calendarStartTime.setZone(newTZ)));
    const payload = {
      calendar_id: _.get(calendar, 'id'),
      timezone: newTZ,
      open: isOpen === 1,
    };
    dispatch(actions.updateCalendar(payload));
  };

  return (
    <>
      <div>
        <h1>Calendar</h1>
        {/* <GoogleCalendarActions
          provider={provider}
          isOpen={isOpen}
          openCalendarSilently={openCalendarSilently}
        /> */}
        {!hasCalendar && !shouldHideNativeCalendar && (
          <p>This provider does not have a calendar attached!</p>
        )}
        {!providerHasAcceptingLocations && !shouldHideNativeCalendar && (
          <div className='m-b-sm'>
            <p className='text-error'>
              Your profile is indicated as not accepting new clients. To show your phone
              consultation calendar, please update your availability.
            </p>
            <Link to={'profile/availability-locations'}>
              <button className='pill primary'>Go to Locations</button>
            </Link>
          </div>
        )}
        {shouldShowWidget && <PracticeManagementBookingLinkWidget />}
        {hasCalendar && !shouldHideNativeCalendar && (
          <>
            <div className='announcement calendar-announcement'>
              <h4>About the Calendar</h4>
              <p className='m-b-xs'>
                Add your availability for phone consultations and busy blocks by clicking the "Add"
                button! Currently, only clients can book phone consultations; the ability for you to
                add phone consultations to your calendar is coming soon!
              </p>
              <ul>
                <li>
                  <a
                    href='https://support.zencare.co/help/how-do-i-set-or-update-call-availability'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    How do I set or update call availability?
                  </a>
                </li>
                <li>
                  <a
                    href='https://support.zencare.co/help/what-happens-when-a-client-requests-an-initial-call'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    What happens when a client requests an initial call?
                  </a>
                </li>
                <li>
                  <a
                    href='https://support.zencare.co/help/how-do-i-block-off-call-times-if-i-am-out-of-office'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    How do I block off call times if I am out of office?
                  </a>
                </li>
                <li>
                  <a
                    href='https://support.zencare.co/help/is-a-two-way-calendar-sync-supported'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Is a two-way calendar sync supported?
                  </a>
                </li>
                <li>
                  <a
                    href='https://support.zencare.co/help/contact-methods-phone-consultation'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    More FAQs
                  </a>
                </li>
              </ul>
            </div>
            <div className='calendar-settings-wrapper'>
              <div
                className={`calendar-settings-wrapper-inner w-100 ${
                  !providerHasAcceptingLocations && 'dim-options'
                }`}
              >
                <a href='#appt-view' className='underline link bold m-b-sm block'>
                  View list of phone consultations.
                </a>

                <div className='status-timezone-container flex flex-between'>
                  <div className='calendar-status'>
                    <h5 className='w-100'>Calendar Status</h5>
                    {isLoading && (
                      <div
                        className='flex justify-center align-center'
                        style={{
                          backdropFilter: 'blur(2px)',
                          zIndex: 5,
                        }}
                      >
                        <div className='loader-container'>
                          <Loader type='Oval' color='#37BEC3' height={50} width={50} />
                        </div>
                      </div>
                    )}
                    {!isLoading && (
                      <div className='tab-select m-b-sm'>
                        {Object.entries({
                          Open: 'open',
                          Closed: 'closed',
                        }).map(([key, val], j) => (
                          <div key={j} className='container' style={{ width: '50%' }}>
                            <input
                              disabled={!providerHasAcceptingLocations}
                              type='radio'
                              component='input'
                              id={`open_${val}`}
                              value={val}
                              checked={val === 'open' ? calendar.open : !calendar.open}
                              name={`open_${val}`}
                              onChange={() => setCalendarStatus(val)}
                            />
                            <label className='flex-center' htmlFor={`open_${val}`}>
                              {key === 'Open' ? 'Show Calendar' : 'Hide Calendar'}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className='timezone-container'>
                    <h5 className='w-100'>Your Timezone</h5>
                    <select
                      id='calendar-tz'
                      name='calendar-tz'
                      onChange={(evt) => changeTimezone(evt.target.value)}
                      onBlur={(evt) => changeTimezone(evt.target.value)}
                      defaultValue={timezone}
                      className='timezone-select'
                    >
                      {adjustedTimezoneList.map((t) => (
                        <option key={`tz-${t.iana}`} value={t.iana}>
                          {t.placename} ({t.abbr}) {formatGMTOffset(t)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {!calendar.open && (
                  <p className='text-error'>
                    Clients can only make appointments on your calendar if it is shown.
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        {isMobile && !shouldHideNativeCalendar && (
          <h5 className='w-100'>Your phone consultation calendar</h5>
        )}
        {hasCalendar && !shouldHideNativeCalendar && (
          <div className={`calendar-container ${fullscreen ? 'full-screen' : 'not-full-screen'}`}>
            {(!isOpen || !providerHasAcceptingLocations) && (
              <div
                className='flex justify-center align-center'
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  opacity: 0.5,
                  background: '#fff',
                  zIndex: 5,
                }}
              ></div>
            )}
            <ControlBar openAvailabilityModal={openAvailabilityModal} />
            <div className='x-scroll-container'>
              {isLoading && (
                <div
                  className='flex justify-center align-center'
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    fontSize: '50px',
                    backdropFilter: 'blur(2px)',
                    zIndex: 5,
                  }}
                >
                  <div className='loader-container'>
                    <Loader type='Oval' color='#37BEC3' height={200} width={200} />
                  </div>
                </div>
              )}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  zIndex: 4,
                  left: 0,
                  background: 'white',
                  height: 50,
                  width: 50,
                  fontSize: '10px',
                  borderBottom: '1px solid gray',
                  display: 'flex',
                  alignItems: 'flex-end',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {formatGMTOffset(adjustedTimezoneList.find((t) => t.iana === timezone))}
              </div>
              <div className='y-scroll-container' ref={ref}>
                <DateLabels />
                <div className='flex'>
                  <HourLabels />
                  {availability && (
                    <>
                      <CalendarGrid availability={availability} />
                    </>
                  )}
                </div>
              </div>
            </div>
            <AvailabilityModal key={currentlyOpen ? currentlyOpen.id : 'new'} />
            <AppointmentModal />
          </div>
        )}
      </div>
      {!shouldHideNativeCalendar && <AppointmentView />}
    </>
  );
};

const AddAvailabilityButton = ({ openAvailabilityModal }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [width] = useWindowSize();
  const isSmallMobile = width <= 400;

  return (
    <button
      className='pill primary calendar-availabilty-button'
      onClick={() => setShowMenu(!showMenu)}
    >
      <i className='fas fa-plus-circle button-symbol'></i>
      <span className='button-text'>Add</span>
      {!isSmallMobile && <i className='fas fa-caret-down button-caret'></i>}
      <div className={`menu ${!showMenu && 'hide'}`}>
        <div
          className='link'
          onKeyDown={(evt) => {}}
          role='button'
          tabIndex={0}
          onClick={() => openAvailabilityModal(true, true, false)}
        >
          Add recurring availability
        </div>
        <div
          className='link'
          onKeyDown={(evt) => {}}
          role='button'
          tabIndex={0}
          onClick={() => openAvailabilityModal(true, false, false)}
        >
          Add one-time availability
        </div>
        <div
          className='link'
          onKeyDown={(evt) => {}}
          role='button'
          tabIndex={0}
          onClick={() => openAvailabilityModal(false, false, false)}
        >
          Block availability
        </div>
      </div>
    </button>
  );
};

const CalendarGrid = ({ availability }) => {
  const timezone = useSelector((state) => state.calendars.tz);
  const [stuffByDay, setStuffByDay] = useState([]);

  useEffect(() => {
    if (!timezone) {
      return;
    }

    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = [];

      ['availabilityBlocks', 'appointmentBlocks'].forEach((type) => {
        for (const item of availability[type]) {
          const adjustedStart = DateTime.fromISO(item.start, { zone: timezone });
          const shouldDisplay = !item.canceled && item.start !== item.end;
          if (adjustedStart.weekday % 7 === i && shouldDisplay) {
            day.push(item);
          }
        }
      });
      days.push(day);
    }

    setStuffByDay(days);
  }, [availability, timezone]);

  if (!stuffByDay.length) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        flex: '1 1 100%',
      }}
    >
      {stuffByDay.map((d, j) => (
        <DayGrid key={`day-${j}`} day={d} j={j} />
      ))}
    </div>
  );
};

const ControlBar = ({ openAvailabilityModal }) => {
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.main.provider);
  const {
    calendarStartTime,
    tz: timezone,
    edits,
    fullscreen,
  } = useSelector((state) => state.calendars);
  const calendar = _.get(provider, 'calendar[0]');
  const [width] = useWindowSize();
  const isMobile = width <= 780;

  useEffect(() => {
    const defTZ = DateTime.local().zoneName || 'America/New_York';
    dispatch(actions.setTZ(defTZ));

    const start = DateTime.local().set(
      {
        weekday: 0,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      },
      { zone: defTZ }
    );

    dispatch(actions.setStartTime(start));
  }, []);

  const calendarEndTime = calendarStartTime ? calendarStartTime.plus({ days: 7 }) : null;

  const startTimeIso = calendarStartTime ? calendarStartTime.toUTC().toISO() : null;
  const endTimeIso = calendarEndTime ? calendarEndTime.toUTC().toISO() : null;

  useEffect(() => {
    if (calendar && calendar.id && startTimeIso && endTimeIso) {
      dispatch(
        actions.getAvailability({
          calendar_id: calendar.id,
          provider_id: provider.id,
          //appointment_type_id: 1,
          start_time: startTimeIso,
          end_time: endTimeIso,
        })
      );
    }
  }, [provider.id, calendar.id, startTimeIso, endTimeIso, timezone, edits]);

  if (!calendarStartTime || !timezone) {
    return null;
  }

  const shiftToTodaysWeek = () => {
    dispatch(
      actions.setStartTime(
        DateTime.local().set(
          {
            weekday: 0,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          },
          { zone: timezone }
        )
      )
    );
  };

  const shiftOneWeekForward = () => {
    dispatch(actions.setStartTime(calendarStartTime.plus({ days: 7 })));
  };

  const shiftOneWeekBackward = () => {
    dispatch(actions.setStartTime(calendarStartTime.minus({ days: 7 })));
  };

  const name =
    calendarStartTime.month === calendarEndTime.month
      ? calendarStartTime.toFormat('LLLL yyyy')
      : `${calendarStartTime.toFormat('LLL')}-${calendarEndTime.toFormat('LLL yyyy')}`;

  return (
    <div className='date-controls m-b-sm w-100 '>
      <div className='control-pagination-container'>
        <button className='pill primary m-r-sm' onClick={shiftOneWeekBackward}>
          <i className='fas fa-chevron-left' />
        </button>
        <button className='today-button pill primary hollow m-r-sm' onClick={shiftToTodaysWeek}>
          {!isMobile && <i className='fas fa-calendar-day m-r-sm' />}
          Today
        </button>
        <button className='pill primary' onClick={shiftOneWeekForward}>
          <i className='fas fa-chevron-right' />
        </button>
      </div>
      <p className='control-heading-container'>{name}</p>
      <div className='control-button-container'>
        <AddAvailabilityButton openAvailabilityModal={openAvailabilityModal} />
        <button
          className='pill primary fullscreen-button'
          onClick={() => dispatch(actions.setFullScreen(!fullscreen))}
        >
          <span className='fullscreen-button-text'>{fullscreen ? 'Collapse ' : 'Expand '}</span>
          <i
            style={{ color: '#fff' }}
            className={fullscreen ? 'fas fa-compress-alt' : 'fas fa-expand-alt'}
          />
        </button>
      </div>
    </div>
  );
};

const DateLabels = () => {
  const { calendarStartTime, tz } = useSelector((state) => state.calendars);

  if (!calendarStartTime) {
    return null;
  }

  const headers = [];

  const today = DateTime.local({ zone: tz });

  for (let i = 0; i < 7; i++) {
    const thisDay = calendarStartTime.plus({ weeks: 1 }).set({ weekday: i });
    const isToday =
      thisDay.year === today.year && today.month === thisDay.month && today.day === thisDay.day;
    headers.push(
      <div key={`dayheader-${i}`} className={`grid-item day-header ${isToday ? 'today' : ''}`}>
        <p className='day-of-week'>{thisDay.weekdayShort}</p>
        <p className={`date-of-month ${isToday ? 'today' : ''}`}>{thisDay.day}</p>
      </div>
    );
  }

  return <div className='date-labels flex'>{headers}</div>;
};

const HourLabels = () => {
  const hours = [];
  for (let i = 1; i < 24; i++) {
    hours.push(
      <div key={`hourlabels-${i}`} className='hour-grid'>
        {i % 12 || 12}&nbsp;{i < 12 ? 'AM' : 'PM'}
      </div>
    );
  }

  return <div className='hour-labels'>{hours}</div>;
};

const DayGrid = ({ day, j }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { calendarStartTime, tz } = useSelector((state) => state.calendars);

  const now = DateTime.local({ zone: tz });
  const thisDay = calendarStartTime.plus({ weeks: 1 }).set({ weekday: j });
  const isToday =
    thisDay.year === now.year && now.month === thisDay.month && now.day === thisDay.day;

  const hourGridBoxes = [];
  for (let i = 0; i < 24; i++) {
    const afterNow =
      thisDay.set({
        minute: 59,
        hour: i,
      }) >= now.set({ minute: 0 });

    hourGridBoxes.push(
      <div
        key={`day-${j}-${i}`}
        className={`hour-grid ${afterNow ? 'hour-grid-after-now' : 'hour-grid-past'}`}
      ></div>
    );
  }

  // Shift overlapping blocks slightly to the right;
  const ordered = _.orderBy(day, (x) => x.start, 'asc');
  ordered.forEach((item, i) => {
    item.offset = 0;
    const overlapping = ordered
      .slice(0, i)
      .reverse()
      .find((x) => x.id !== item.id && x.start <= item.start && x.end > item.start);

    if (overlapping) {
      item.offset = overlapping.offset + 5;
    }
  });

  const items = day.map((item) => <Block key={`block-${item.id}`} {...item} />);
  if (isToday) {
    items.push(
      <Block
        key={`block-now`}
        type='now'
        start={DateTime.utc().toISO()}
        end={DateTime.utc().plus({ minutes: 2 }).toISO()}
      />
    );
  }

  return (
    <div
      className='grid-item day-grid'
      style={{
        flexDirection: 'column',
        display: 'flex',
        height: dayHeight,
        position: 'relative',
      }}
      ref={ref}
      role='button'
      tabIndex={0}
      onClick={(e) => {
        if (!ref || !ref.current) {
          return;
        }

        const rect = ref.current.getBoundingClientRect();
        const y = e.clientY - rect.top; //y position within the element.
        const pxPerMinute = 60 / hourHeight;
        let minutes = pxPerMinute * y;
        const remainder = minutes % 15;
        if (remainder < 8) {
          minutes = minutes - remainder;
        } else {
          minutes = minutes - remainder + 15;
        }

        const start = thisDay.set({
          hour: 0,
          minute: minutes,
        });
        const elevenPmMinutes = 1380;
        const midnightMinutes = 1440;
        let end = 0;
        // Prevent end times that start before midnight and end after midnight
        if (minutes > elevenPmMinutes && minutes < midnightMinutes) {
          end = start.plus({ minutes: midnightMinutes - minutes });
        } else {
          end = start.plus({ hours: 1 });
        }

        // Note: Enable this check if want to disallow allow creation of events in the past.
        // if (start.toISO() > now.toISO()) {
        // based on day and y offset, we can do a gcal like create modal
        dispatch(
          actions.openAvailability({
            currentlyOpen: {
              id: null,
              is_recurring: false,
              parent_id: false,
              free: true,
              rrule: null,
              type: 'availability',
              start,
              end,
            },
            recurrence: null,
            showTypeToggle: true,
          })
        );
      }}
      onKeyDown={(evt) => {}}
    >
      {hourGridBoxes}
      {items}
    </div>
  );
};

const Block = (item) => {
  const { tz } = useSelector((state) => state.calendars);
  const dispatch = useDispatch();
  const start = DateTime.fromISO(item.start, { zone: tz });
  const end = DateTime.fromISO(item.end, { zone: tz });
  const height = (end.diff(start, 'minutes').toObject().minutes * hourHeight) / 60;
  const topOffset = start.hour * hourHeight + (start.minute * hourHeight) / 60;
  const isInPast = end < DateTime.local();

  let text;

  let className = `calendar-block `;

  if (item.type === 'appointment') {
    text = 'Appointment';
    className += ' appointment';
  }

  if (item.type === 'availability') {
    text = item.name || (item.free ? 'Available' : 'Busy');
    className += ` ${item.free ? 'free' : 'busy'}`;
  }

  if (item.type === 'now') {
    className += ' now-indicator';
  }

  if (isInPast) {
    className += ' in-the-past';
  }

  // TODO: remove when implimenting custom event naming
  if (item.free) {
    text = 'Available';
  } else {
    text = 'Unavailable';
  }

  // TODO: remove when implimenting custom event naming
  if (item.type === 'appointment') {
    text = 'Phone Consult';
  }

  const style = {
    top: topOffset,
    height,
    cursor: 'pointer',
    left: item.offset,
  };

  if (item.offset > 0) {
    style.border = '1px solid white';
  }

  return (
    <div
      className={className}
      style={style}
      role='menu'
      tabIndex={0}
      onKeyPress={() => {}}
      onClick={(evt) => {
        evt.stopPropagation();
        // Use this if wanted to disallow editing availability in the past
        //if (item.type === 'availability' && !isInPast) {
        if (item.type === 'availability') {
          dispatch(
            actions.openAvailability({
              currentlyOpen: {
                ...item,
                start,
                end,
              },
              recurrence: null,
              showTypeToggle: true,
            })
          );
        }

        if (item.type === 'appointment') {
          dispatch(appointmentActions.openAppointment({ id: item.id }));
        }
      }}
    >
      <p style={{ lineHeight: '1rem' }} className='m-b-0'>
        {text}
      </p>
      <p className='m-b-0' style={{ fontSize: '12px' }}>
        {start.toFormat('t')} - {end.toFormat('t')}
      </p>
      <p style={{ fontSize: '12px' }}>{item.free ? 'Free' : 'Busy'}</p>
    </div>
  );
};
