import React from 'react';

const tabOptions = {
  Hide: 0,
  Display: 1,
};

const DisplayTestimonialToggle = ({ id, isDisplayed, setIsDisplayed }) => (
  <>
    <div className='tab-select m-b-sm'>
      {Object.entries(tabOptions).map(([key, val], j) => (
        <div key={j} className='container'>
          <input
            type='radio'
            component='input'
            name={`display_testimonial_${id}`}
            id={`display_testimonial_${id}_${val}`}
            value={val}
            checked={isDisplayed === val}
            onChange={(e) => setIsDisplayed(e.target.value)}
          />
          <label className='flex-center' htmlFor={`display_testimonial_${id}_${val}`}>
            {key}
          </label>
        </div>
      ))}
    </div>
  </>
);

export default DisplayTestimonialToggle;
