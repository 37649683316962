import React from 'react';
import rgx from '#/regex';

const usernameExamples = ['johndoe', 'john.doe', 'john_doe', 'john-doe'].map((un, i) => (
  <code key={i}>{un}</code>
));

const validate = (login) => (values) => {
  const errors = {};

  if (!login) {
    if (!values.username) {
      errors.username = 'You must enter a name';
    } else if (!rgx.username.test(values.username)) {
      errors.username = ['Invalid username format. Examples: '].concat(usernameExamples);
    }
  }

  if (!values.email) {
    errors.email = 'You must enter an email address';
  } else if (!rgx.email.test(values.email.trim())) {
    errors.email = 'Invalid email address format';
  }

  if (!values.password) {
    errors.password = 'You must enter a password';
  }

  return errors;
};
export default validate;
