import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as mainActions } from '@/main/sagaSlice';
import dayjs from 'dayjs';
import EditBox from '+/EditBox';
import DisplayTestimonialToggle from './DisplayTestimonialToggle';

function MonthAndYear({ date }) {
  const monthYearString = dayjs(date).format('MMMM, YYYY');

  return <>{monthYearString}</>;
}

function Testimonial({ provider, providerFeedback, updateTestimonialDisplay }) {
  const [isDisplayed, setIsDisplayed] = useState(providerFeedback.display_testimonial);

  function saveToggleSelection(isDisplayed) {
    setIsDisplayed(parseInt(isDisplayed));
    const displayTestimonialBool = parseInt(isDisplayed) === 1;
    updateTestimonialDisplay({
      provider_id: provider.id,
      provider_feedback_id: providerFeedback.id,
      display_testimonial: displayTestimonialBool,
    });
  }

  return (
    <>
      <div className='box m-b-sm mobile-border-bottom'>
        <div className='testimonial-toggle'>
          <DisplayTestimonialToggle
            id={providerFeedback.id}
            isDisplayed={isDisplayed}
            setIsDisplayed={saveToggleSelection}
          />
        </div>
        <div>"{providerFeedback.testimonial}"</div>
        <div>
          {/* Show legacy author field if set */}
          {providerFeedback.author ? <span>{providerFeedback.author} • </span> : null}
          <MonthAndYear date={providerFeedback.submitted_at} />
        </div>
      </div>
    </>
  );
}

function Feedback({ providerFeedback }) {
  return (
    <>
      <div className='box m-b-sm mobile-border-bottom'>
        <div>"{providerFeedback.feedback}"</div>
        <MonthAndYear date={providerFeedback.submitted_at} />
      </div>
    </>
  );
}

function ProviderFeedback({
  provider,
  testimonials,
  feedback,
  getProviderFeedback,
  updateTestimonialDisplay,
}) {
  const [providerFeedbackLoaded, setProviderFeedbackLoaded] = useState(false);

  useEffect(() => {
    if (provider && provider.id && !providerFeedbackLoaded) {
      setProviderFeedbackLoaded(true);
      getProviderFeedback({ id: provider.id });
    }
  }, [getProviderFeedback, provider, provider.id, providerFeedbackLoaded]);

  const testimonialComponents = testimonials.map((pf) => (
    <Testimonial
      key={`${pf.id}-testimonial`}
      providerFeedback={pf}
      provider={provider}
      updateTestimonialDisplay={updateTestimonialDisplay}
    />
  ));

  const feedbackComponents = feedback.map((pf) => (
    <Feedback key={`${pf.id}-feedback`} providerFeedback={pf} />
  ));

  return (
    <div className='provider-feedback-container'>
      <h1 className='m-b-xs'>Testimonials &amp; Feedback</h1>
      <p className='m-b-md'>
        Learn{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://zencare.co/about/testimonial-faq-providers'
        >
          how Zencare testimonials work
        </a>
        .
      </p>
      <EditBox
        title='Client Testimonials'
        headerClassName='m-b-0'
        editable={false}
        description={
          <div className='instruction m-b-md'>
            Indicate which testimonials you'd like to display on your profile.
          </div>
        }
        innerStatic={
          <div>
            {testimonialComponents.length === 0 && <p>You do not have any testimonials.</p>}
            {testimonialComponents}
          </div>
        }
      />

      <EditBox
        title='Client Feedback'
        headerClassName='m-b-0'
        editable={false}
        description={
          <div className='instruction m-b-md'>
            These are not displayed on your profile. They are shared with you for your reference!
          </div>
        }
        innerStatic={
          <div>
            {feedbackComponents.length === 0 && <p>You do not have any feedback.</p>}
            {feedbackComponents}
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = ({ main }) => ({
  provider: main.provider,
  testimonials: main.testimonials,
  feedback: main.feedback,
});

const actions = {
  getProviderFeedback: mainActions.getProviderFeedback,
  updateTestimonialDisplay: mainActions.updateTestimonialDisplay,
};

export default connect(mapStateToProps, actions)(ProviderFeedback);
