import * as React from 'react';
import Modal from '~/shared/Modal';

interface Props {
  toggleModal: (arg0: boolean) => void;
  visible: boolean;
}

function BookingLinkSetupModal({ toggleModal, visible }: Props) {
  return (
    <Modal
      headerContent={<h4>Set up EHR Client Portal</h4>}
      footerContent={
        <div className='flex'>
          <a
            href='https://practicemanagement.zencare.co/help/how-do-i-set-up-phone-consultations-and-add-them-to-zencare'
            target='_blank'
            rel='noopener noreferrer'
          >
            <button className='primary m-r-sm' onClick={() => toggleModal(false)}>
              See Help Desk Article
            </button>
          </a>
          <button onClick={() => toggleModal(false)} className='primary hollow'>
            Close
          </button>
        </div>
      }
      visible={visible}
      closeModal={() => toggleModal(false)}
      additionalCssClasses={'practice-management-booking-link-widget-modal'}
    >
      <p>
        It looks like your EHR client portal isn't set up yet! Follow{' '}
        <a
          href='https://practicemanagement.zencare.co/help/how-do-i-set-up-phone-consultations-and-add-them-to-zencare'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => toggleModal(false)}
          className='bold underline'
        >
          these instructions
        </a>{' '}
        to finish setting up the portal so clients can book time with you directly.
      </p>
    </Modal>
  );
}

export default BookingLinkSetupModal;
