import React from 'react';

function SupportLink() {
  return (
    <div className='support-link-wrapper'>
      <p className='callout-contact-text'>
        <span className='bold'>Questions?</span> Reach our dedicated customer support team!
      </p>
      <a className='callout-contact-link bold' href='mailto:support@zencarepractice.com'>
        <i className='fal fa-envelope text-primary'></i>
        support@zencarepractice.com
      </a>
    </div>
  );
}

export default SupportLink;
