import React from 'react';
import { Form, Field } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import _ from 'lodash';

export default ({ pronouns, provider, updateProvider, setEditingPronouns }) => {
  const OTHER_SENTINEL = 'Other';
  const shePronouns = 'She/Her/Hers';
  const hePronouns = 'He/Him/His';
  const theyPronouns = 'They/Them/Theirs';
  const initialValues = {};

  const updatePronouns = (values) => {
    let pr;
    if (values.pr === OTHER_SENTINEL) {
      pr = values.other_pr;
    } else {
      pr = values.pr;
    }

    updateProvider({
      id: provider.id,
      custom_content: {
        pronouns: pr,
      },
    });

    setEditingPronouns(false);
  };

  if ([shePronouns, hePronouns, theyPronouns].includes(pronouns)) {
    initialValues.pr = pronouns;
  } else {
    initialValues.pr = OTHER_SENTINEL;
    initialValues.other_pr = pronouns;
  }

  return (
    <Form
      onSubmit={updatePronouns}
      initialValues={initialValues}
      validate={(v) => {
        const errors = {};

        if (v.pr === OTHER_SENTINEL) {
          if (_.isNil(v.other_pr)) {
            errors.other_pr = 'Please enter your preferred pronouns.';
          }

          if (v.other_pr && v.other_pr.length > 40) {
            errors.other_pr = 'Exceeds maximum length (40 characters).';
          }
        }

        return errors;
      }}
      render={({ handleSubmit, pristine, valid, values }) => (
        <>
          <div>
            <h5>Which pronouns do you use?</h5>
            <p className='instruction m-b-md'>
              Please select the pronouns you use, not those of the clients you see.
            </p>
            <div className='flex column pronoun-fields'>
              <div className='flex align-baseline'>
                <Field
                  name='pr'
                  component='input'
                  type='radio'
                  value={shePronouns}
                  id='she-pronouns'
                />
                <label className='m-b-sm' htmlFor='she-pronouns'>
                  {shePronouns}
                </label>
              </div>
              <div className='flex align-baseline'>
                <Field
                  name='pr'
                  component='input'
                  type='radio'
                  value={hePronouns}
                  id='he-pronouns'
                />
                <label className='m-b-sm' htmlFor='he-pronouns'>
                  {hePronouns}
                </label>
              </div>
              <div className='flex align-baseline'>
                <Field
                  name='pr'
                  component='input'
                  type='radio'
                  value={theyPronouns}
                  id='they-pronouns'
                />
                <label className='m-b-sm' htmlFor='they-pronouns'>
                  {theyPronouns}
                </label>
              </div>
              <div className='flex align-baseline m-b-sm'>
                <Field
                  name='pr'
                  component='input'
                  type='radio'
                  value={OTHER_SENTINEL}
                  id='other-pronouns'
                />
                <label className='m-b-sm m-r-sm' htmlFor='other-pronouns'>
                  Other
                </label>
                <div className='flex column'>
                  <Field id='custom-pronoun-input' name='other_pr' component='input' />
                  <ErrorField name='other_pr' />
                </div>
              </div>
            </div>
          </div>
          <SubmitButton pristine={pristine} valid={valid} handleSubmit={handleSubmit} />
        </>
      )}
    ></Form>
  );
};
