type Checklist = ChecklistItem[];

const checklist: Checklist = [
  {
    cardName: 'Update your payment information',
    cardDescription:
      "One of your subscriptions requires attention. Head to the Plan Management page to update your payment information and ensure that you don't miss out on your Zencare benefits!",
    cardLink: '/plan-management?action=update_payment',
    cardLinkText: 'Update',
    providerDisplayFn: ({ accounts }: { accounts: Account[] }) =>
      accounts.some((a) => a.should_show_update_payment_cta),
    isUrgent: true,
    cardType: 'Action Needed',
    dismissText: '',
  },
  {
    cardName: 'Your Vacation Responder is currently active',
    cardDescription:
      "Your Vacation Responder lets therapy seekers know that you're out of office and unable to respond to messages. If you're currently available for referrals, make sure to update your status on your Availability & Locations page!",
    cardLink: '/profile/availability-locations',
    cardLinkText: 'Update',
    providerDisplayFn: ({ provider }: { provider: Provider }) =>
      Boolean(
        provider.out_of_office_message &&
          provider.out_of_office_message.status === 'active' &&
          new Date(provider.out_of_office_message.start_date) < new Date()
      ),
    isUrgent: true,
    cardType: 'Attention',
    dismissText: '',
  },
];

export default checklist;
