import * as React from 'react';

interface Props {
  step: 0 | 1 | 2 | 3 | 4 | 5;
}

function Callout({ step }: Props): JSX.Element {
  return (
    <div className='callout-container'>
      <div className='box callout-box'>
        {/* Create Account */}
        {step === 0 && (
          <>
            <p className='callout-box-text'>
              <i className='far fa-users'></i>Unlimited client referrals
            </p>
            <p className='callout-box-text'>
              <i className='far fa-photo-video'></i>Professional photos and video
            </p>
            <p className='callout-box-text'>
              <i className='far fa-concierge-bell'></i>Personalized customer support
            </p>
          </>
        )}
        {/* Locations */}
        {step === 1 && (
          <>
            <i className='fas fa-quote-left callout-quote-icon'></i>
            <p className='callout-box-quote'>
              Joining Zencare was the single best thing I have done to help me grow my practice with
              clients who are a great match for my skills, my approach, and my personality. And I
              have been in practice for 30+ years of practice!
            </p>
            <p className='callout-box-author'>-Lisa, Massachusetts</p>
          </>
        )}
        {/* About You */}
        {step === 2 && (
          <>
            <i className='fas fa-quote-left callout-quote-icon'></i>
            <p className='callout-box-quote'>
              I have been amazed by the referrals - each of them has been an awesome fit. I am
              seeing 2 of them weekly and the other biweekly. Thank you so much for your support and
              I am so happy to be a part of Zencare.
            </p>
            <p className='callout-box-author'>-Darcey, Colorado</p>
          </>
        )}
        {/* Your Practice */}
        {step === 3 && (
          <>
            <i className='fas fa-quote-left callout-quote-icon'></i>
            <p className='callout-box-quote'>
              We have received two more referrals… your team and service has been awesome and
              definitely keeping us busy! I really really appreciate what you all are doing in the
              backend to make this such a success for us! It has more than paid for itself already.
            </p>
            <p className='callout-box-author'>-David, Maryland</p>
          </>
        )}
        {/* Photos */}
        {step === 4 && (
          <>
            <i className='fas fa-quote-left callout-quote-icon'></i>
            <p className='callout-box-quote'>
              I’m loving Zencare!!! I just started my private practice this week and already have 5
              full fee clients. It really is nice to know there are humans out there cheering me on!
              Thank you for all the support!
            </p>
            <p className='callout-box-author'>-Neeka, Massachusetts</p>
          </>
        )}
        {/* Go Live */}
        {step === 5 && (
          <>
            <i className='fas fa-quote-left callout-quote-icon'></i>
            <p className='callout-box-quote'>
              The clients I am working with are great. They definitely feel like a good fit. I’m
              loving Zencare!
            </p>
            <p className='callout-box-author'>-Mary Claire, Illinois</p>
          </>
        )}
        {/* No explicit step -- Fallback */}
        {step > 5 && (
          <>
            <i className='fas fa-quote-left callout-quote-icon'></i>
            <p className='callout-box-quote'>
              We have received two more referrals… your team and service has been awesome and
              definitely keeping us busy! I really really appreciate what you all are doing in the
              backend to make this such a success for us! It has more than paid for itself already.
            </p>
            <p className='callout-box-author'>-David, Maryland</p>
          </>
        )}
      </div>
      <p className='callout-contact-text'>
        <span className='bold'>Questions?</span> Reach our dedicated customer support team!
      </p>
      <a className='callout-contact-link bold' href='mailto:clinicalteam@zencare.co'>
        <i className='fal fa-envelope text-primary'></i>
        clinicalteam@zencare.co
      </a>
    </div>
  );
}

export default Callout;
