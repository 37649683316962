import React from 'react';
import { formatMessageTime } from '~/utils/formatTime';
import { formatPhoneNumber, formatTelLink } from '~/utils/formatPhone';
import VobIntakeMessageFooter from '~/components/reimbursify/VobIntakeMessageFooter';

type Props = {
  message: Message;
  textAreaInput: React.RefObject<HTMLTextAreaElement>;
};

function IntakeMessage({ message, textAreaInput }: Props): JSX.Element {
  const {
    send_date,
    sender,
    read_at,
    message_content,
    intake_form: { state, session_type, insurance, source, source_type },
    client_email,
    client_phone,
    client_full_name,
  } = message;

  return (
    <div className={`message ${sender === 'provider' ? 'message_provider' : ''}`}>
      <div
        className={`message-bubble rich-content ${
          sender === 'provider' ? 'message-bubble_provider' : ''
        }`}
      >
        <h5 className='message-heading'>
          New Client Inquiry{source_type === 'therapy_group' ? ` via ${source}` : ''}
        </h5>
        <hr />
        <div className='client-info'>
          <h5>Client Information</h5>
          <p>
            <span className='semi-bold'>Name: </span>
            {client_full_name}
          </p>
          <p>
            <span className='semi-bold'>Phone: </span>
            <a href={`tel:${formatTelLink(client_phone)}`}>{formatPhoneNumber(client_phone)}</a>
          </p>
          <p>
            <span className='semi-bold'>Email: </span>
            {client_email === 'Redacted' ? (
              'Redacted'
            ) : (
              <a href={`mailto:${client_email}`}>{client_email}</a>
            )}
          </p>
        </div>
        <hr />
        <div className='session-info'>
          <h5>Intake Information</h5>
          {state && <h6>What state are you based in?</h6>}
          <p>{state}</p>
          {insurance && <h6>What health insurance plan do you have?</h6>}
          <p>{insurance}</p>
          {message_content && <h6>What's on your mind?</h6>}
          <p>
            {message_content}{' '}
            {message_content === 'This message is no longer available.' ? (
              <i
                className='fal fa-info-circle'
                data-for='redaction-tooltip'
                data-tip='This client deleted all of their personal data and messages from Zencare! We kept this record as a reminder that the referral was received, but any content and personal information is no longer accessible. Please reach out to support@zencare.co if you have any questions.'
              ></i>
            ) : (
              ''
            )}
          </p>
          {session_type && <h6>What type of session are you interested in?</h6>}
          <p>{session_type}</p>
          <VobIntakeMessageFooter message={message} textAreaInput={textAreaInput} />
        </div>
        {sender === 'client' && !read_at ? (
          <span className='message-unread-notification'></span>
        ) : null}
      </div>
      <small className='message-date'>Sent {formatMessageTime(send_date)}</small>
    </div>
  );
}

export default IntakeMessage;
