/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import FormCheckbox from '+/forms/FormCheckbox';
import { connect } from 'react-redux';
import { actions as mainActions } from '../main/sagaSlice';
import utils from '../../utils/index';

import {
  filterEntriesBySearch,
  checkForSubInsurancesRequired,
  renderCheckboxHierarchy,
} from '../provider/profile/InsuranceFees/editInsuranceUtils';
import StartMembershipButton from './StartMembershipButton';

const mapStateToProps = ({ main }) => ({
  relevantInsurances: main.relevantInsurances,
});

const actions = {
  updateMultipleTaggings: mainActions.updateMultipleTaggings,
  getRelevantInsurances: mainActions.getRelevantInsurances,
};

export const YourPracticeInsuranceForm = ({
  providerId,
  provider,
  insuranceSubmitClicked,
  getRelevantInsurances,
  relevantInsurances,
  updateMultipleTaggings,
}) => {
  const [search, setSearch] = useState('');
  const [relevantInsurancesLoaded, setRelevantInsurancesLoaded] = useState(false);
  const initialValues = {};
  _.get(provider, 'insurances', []).forEach((initialValuesTag) => {
    initialValues[`tag-id-${initialValuesTag.id}`] = true;
  });

  useEffect(() => {
    if (!relevantInsurancesLoaded && provider) {
      getRelevantInsurances({ id: provider.id });
      setRelevantInsurancesLoaded(true);
    }
  }, [relevantInsurancesLoaded, provider, getRelevantInsurances]);

  const handleSearch = ({ target }) => utils.debounce(() => setSearch(target.value));

  const generateUpdatesList = (values) => {
    const updates = [];
    const nowValueIndexes = Object.keys(values);
    nowValueIndexes
      .filter((x) => _.startsWith(x, 'tag-id-'))
      .forEach((newValueIndex) => {
        const tagId = parseInt(newValueIndex.replace('tag-id-', ''));
        updates.push({
          tagTypePlural: 'insurances',
          tagId: tagId,
          associate: true,
        });
      });

    return updates;
  };

  const saveInsuranceUpdates = (values) => {
    const updates = generateUpdatesList(values);
    updateMultipleTaggings({
      providerId: provider.id,
      entries: updates,
    });
    insuranceSubmitClicked();
  };

  const submitDisabled = (formProps) => {
    if (!formProps.valid) {
      return true;
    }
    if (!formProps.values.update_insurances_confirmed) {
      return true;
    }

    return false;
  };

  const validate = (values) => {
    const errors = {};
    const validationErrorParagraphs = [];
    // If data is not yet ready, return now.
    if (!relevantInsurances || !relevantInsurances.commonInsurancesEntries) {
      return errors;
    }

    checkForSubInsurancesRequired(
      values,
      validationErrorParagraphs,
      relevantInsurances.commonInsurancesEntries
    );
    checkForSubInsurancesRequired(
      values,
      validationErrorParagraphs,
      relevantInsurances.allInsuranceEntries
    );

    if (validationErrorParagraphs.length > 0) {
      errors.validationErrorParagraphs = validationErrorParagraphs;
    }

    return errors;
  };

  return (
    <div className='edit-health-insurances'>
      <div>
        <Form
          initialValues={initialValues}
          onSubmit={saveInsuranceUpdates}
          validate={validate}
          render={(formProps) => (
            <>
              <div className='box m-b-sm'>
                <h4 className='m-b-0' id='edit-health-insurances-header'>
                  In-Network Health Insurances
                </h4>
                <div className='add-remove-health-insurances'>
                  <h5>Select Health Insurances</h5>
                  <div className='filter-insurances m-t-md m-b-sm'>
                    <input
                      type='text'
                      className='block w-100'
                      placeholder='Filter insurances'
                      onKeyUp={handleSearch}
                    />
                  </div>
                  <div className='common-health-insurances'>
                    {filterEntriesBySearch('commonInsurancesEntries', relevantInsurances, search)
                      .length > 0 ? (
                      <>
                        <h6>Common health insurances</h6>
                        <div className='insurances'>
                          {renderCheckboxHierarchy(
                            formProps.values,
                            filterEntriesBySearch(
                              'commonInsurancesEntries',
                              relevantInsurances,
                              search
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='all-health-insurances'>
                    <h6 className='p-t-md'>All health insurances</h6>
                    <div className='insurances'>
                      {renderCheckboxHierarchy(
                        formProps.values,
                        filterEntriesBySearch('allInsuranceEntries', relevantInsurances, search)
                      )}
                    </div>
                  </div>
                  <div className='m-t-lg p-b-md' style={{ borderTop: '1px solid lightgray' }}>
                    <h5 className='m-t-md  semi-bold'>
                      Insurance not listed? You can request to add more later!
                    </h5>
                  </div>
                </div>
              </div>
              <div className='help-dialog show'>
                <i className='fal fa-lightbulb-on'></i>
                <p>
                  Tip: Don’t take insurance in all the states where you practice? You can indicate
                  insurance by state later!
                </p>
              </div>
              <div className='actions'>
                <div className='confirm-paneled m-t-lg m-b-sm'>
                  <Field name='update_insurances_confirmed'>
                    {(props) => (
                      <FormCheckbox
                        {...props}
                        label={
                          <div>
                            <span
                              style={{
                                fontWeight: 600,
                                fontSize: '1.1rem',
                              }}
                            >
                              I confirm that I am&nbsp;
                              <span style={{ textDecoration: 'underline' }}>in-network</span>{' '}
                              and&nbsp;
                              <span style={{ textDecoration: 'underline' }}>paneled</span> with
                              these health insurances.
                            </span>
                            <span
                              style={{
                                color: 'gray',
                                fontWeight: 'normal',
                                lineHeight: 'inherit',
                              }}
                            >
                              Please only indicate health insurances that you are in-network with,
                              not those that you offer out-of-network billing support for.
                            </span>
                          </div>
                        }
                      />
                    )}
                  </Field>
                </div>

                {formProps.errors.validationErrorParagraphs
                  ? formProps.errors.validationErrorParagraphs
                  : ''}
                <div className='w-100 flex justify-center m-t-sm m-b-md'>
                  You can edit any of these fields later before going live!
                </div>
                <div className='flex justify-center'>
                  {/* Note: We need to pass provider id as prop 
            in so we can set it to id of one being created */}
                  <StartMembershipButton providerId={providerId} />
                  <button
                    disabled={submitDisabled(formProps)}
                    onClick={() => {
                      formProps.handleSubmit();
                    }}
                    className={`lg pill ${submitDisabled(formProps) ? '' : 'primary'}`}
                  >
                    Save + Next
                  </button>
                </div>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, actions)(YourPracticeInsuranceForm);
