import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TwoFactorAuthEnrollModal from './TwoFactorAuthEnrollModal';
import { actions as authActions } from '../../auth/sagaSlice';
import EditPhoneNumberForm from './EditPhoneNumberForm';
import { formatPhoneNumber } from '~/utils/formatPhone';

export const ManagePhoneNumber = () => {
  const dispatch = useDispatch();
  const [enrollModalOpen, setEnrollModalOpen] = useState(false);

  //    const dispatch = useDispatch();

  const { isLoading, phone_number, enrolledInTwoFactor } = useSelector<State, AuthState>(
    (state) => state.auth
  );

  const { accounts } = useSelector<State, MainState>((state) => state.main);

  let activeOrTrialingSubscriptionCount = 0;

  accounts.forEach((account) => {
    if (
      account.active_or_trialing_subscription_count &&
      account.active_or_trialing_subscription_count > 0
    ) {
      activeOrTrialingSubscriptionCount += account.active_or_trialing_subscription_count;
    }
  });

  const disableTwoFactorAuthenticationClicked = () => {
    dispatch(authActions.disableTwoFactorEnrollment());
  };

  return (
    <>
      <div className='form portal m-b-sm'>
        <div className='account-preferences'>
          <div className='flex flex-between m-b-md'>
            <div>
              <h4>
                Phone Number {activeOrTrialingSubscriptionCount > 0 && <>/ Two-Factor Authentication</>}
              </h4>
            </div>
            <div>
              {enrolledInTwoFactor ? (
                <button
                  className='primary'
                  onClick={() => {
                    disableTwoFactorAuthenticationClicked();
                  }}
                >
                  Disable 2FA
                </button>
              ) : (
                activeOrTrialingSubscriptionCount > 0 && (
                  <button
                    className='primary'
                    onClick={() => {
                      setEnrollModalOpen(true);
                    }}
                  >
                    Enable 2FA...
                  </button>
                )
              )}
            </div>
          </div>

          {activeOrTrialingSubscriptionCount > 0 && (
            <p>
              Two-factor authentication (or 2FA) adds an extra layer of security to your account. To
              log in, you’ll input a secure access code sent through SMS in addition to your
              password. You will only need to use a code every 30 days.
            </p>
          )}

          {enrolledInTwoFactor ? (
            <>
              <p>Phone Number: {phone_number ? formatPhoneNumber(phone_number) : ''}</p>
              <button
                className='primary'
                onClick={() => {
                  setEnrollModalOpen(true);
                }}
              >
                Edit Phone Number...
              </button>
            </>
          ) : (
            <EditPhoneNumberForm />
          )}
        </div>
      </div>
      <TwoFactorAuthEnrollModal open={enrollModalOpen} toggle={setEnrollModalOpen} />
    </>
  );
};

export default ManagePhoneNumber;
