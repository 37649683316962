import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import Input from '+/forms/Input';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import rgx from '#/regex';
import Loader from 'react-loader-spinner';

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'You must enter an password';
  } else if (!rgx.new_password.test(values.password)) {
    errors.password =
      'Your password must contain at least twelve characters, an uppercase letter, a lowercase letter, and a symbol or number';
  }

  if (values.password !== values.confirm_password) {
    errors.confirm_password = 'Passwords must match';
  }

  return errors;
};

export const ResetPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const { resetPassword, checkResetToken, logout, isSignedIn, match = { params: {} } } = props;
  const { reset_token } = match.params;

  useEffect(() => {
    checkResetToken({ reset_token });
    if (isSignedIn) {
      logout({ route: false });
    }
  }, [checkResetToken, isSignedIn, logout, reset_token]);

  const onSubmit = (formValues) =>
    resetPassword({
      password: formValues.password,
      reset_token,
    });

  if (props.isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader type='TailSpin' color='#37BEC3' height={50} width={50} />
      </div>
    );
  }

  if (!props.resetTokenSuccess) {
    return (
      <>
        <h1>Reset password link has expired</h1>
        <p>
          To request a new reset password link, please <a href='/password/request'>click here</a>.
        </p>
      </>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, invalid }) => (
        <form
          onSubmit={handleSubmit}
          className='reset-password grid justify-between column align-center'
        >
          <div>
            <h1 className='text-center'>Set New Password</h1>
            <p>
              Your password must be at least 8 characters long and have at least one character from
              three of the following four categories: lowercase, uppercase, number, special
              character.
            </p>
          </div>
          <div>
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label='Password: '
              name='password'
            />
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label='Confirm Password: '
              name='confirm_password'
            />

            <div
              className='checkbox'
              style={{
                marginBottom: '1rem',
              }}
            >
              <input
                type='checkbox'
                id='showPassword'
                onChange={(e) => setShowPassword(e.target.checked)}
              />
              <label htmlFor='showPassword'>
                <span className='time-of-day'>Show Password</span>
              </label>
            </div>

            <button type='submit' className='primary' disabled={invalid}>
              Reset Password
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default connect((state) => state.auth, {
  isLoading: actions.isLoading,
  resetPassword: actions.resetPassword,
  checkResetToken: actions.checkResetToken,
  logout: actions.logoutDone,
})(ResetPassword);
