import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actions } from './sagaSlice';
import { actions as reimbursifyActions } from '@/reimbursify/sagaSlice';
import { actions as mainActions } from '../main/sagaSlice';
import Loader from '+/forms/SectionLoader';

import LoginForm from './LoginForm';
import _ from 'lodash';
import { getQueryAsObject } from '../../utils/queryParams';
import { getReimbursifyDomain } from '../reimbursify/reimbursifyUtils';
import AskIfClientLoginDesiredModal from './AskIfClientLoginDesiredModal';
import ZpmPortalLinkModal from './ZpmPortalLinkModal';

export const Login = (props: {
  isSignedIn: any;
  twoFactorCodeNeeded: boolean;
  phoneLastFour: string;
  login: $TSFixMeFunction; // Ask Jake in code review if any way to use AuthActions interface in src/components/auth/sagaSlice.ts here.
  loginVerifyTwoFactor: $TSFixMeFunction;
  loginErrorReservedName?: string | null;
}) => {
  const [askIfClientLoginDesiredModalOpen, setAskIfClientLoginDesiredModalOpen] = useState(false);
  const [zpmPortalLinkModalOpen, setZpmPortalLinkModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { provider } = useSelector((state: $TSFixMe) => state.main);
  const { isLoading } = useSelector((state: $TSFixMe) => state.reimbursify);
  const {
    askIfClientLoginDesired,
    redirectToZpmStandalonePayment,
    practiceManagementClinicCreatorInfo,
    showZpmPortalLinkModal,
    practiceManagementSubdomain,
  } = useSelector((state: $TSFixMe) => state.auth);

  const [startedGetMeFetch, setStartedGetMeFetch] = useState(false);

  const email = new URLSearchParams(_.get(props, 'location.search', '')).get('email');
  const origin = new URLSearchParams(_.get(props, 'location.search', '')).get('origin');
  const reimbursifyRedirectUrl = new URLSearchParams(_.get(props, 'location.search', '')).get(
    'reimbursify_redirect_url'
  );
  const fromClientPortal = origin === 'client';

  const fromPathName = _.get(props, 'location.state.from.pathname', '/profiles');
  const fromSearch = _.get(props, 'location.state.from.search', '');

  const {
    loginErrorReservedName,
    isSignedIn,
    twoFactorCodeNeeded,
    phoneLastFour,
    login,
    loginVerifyTwoFactor,
  } = props;

  useEffect(() => {
    if (askIfClientLoginDesired) {
      setAskIfClientLoginDesiredModalOpen(true);
    }
  }, [askIfClientLoginDesired]);

  useEffect(() => {
    if (showZpmPortalLinkModal) {
      setZpmPortalLinkModalOpen(true);
    }
  }, [showZpmPortalLinkModal]);

  useEffect(() => {
    // Once current provider is loaded, use that id.
    if (isSignedIn && reimbursifyRedirectUrl && provider) {
      // Fetch SSO token and redirect
      const sagaParam: ReimbursifyGenerateSsoJwtSagaParam = {
        params: {
          provider_id: provider.id,
        },
        onSuccess: (reimbursifyJwt: string) => {
          const urlPrefix = `https://${getReimbursifyDomain()}/auth?jwt=`;

          window.location.href = `${urlPrefix}${reimbursifyJwt}&redirect=${encodeURIComponent(
            reimbursifyRedirectUrl
          )}`;
        },
      };

      // @ts-expect-error - NOTE: Need to look at example Jake made for typing this with JSDoc
      dispatch(reimbursifyActions.reimbursifyGenerateSsoJwt(sagaParam));
    }
  }, [dispatch, isSignedIn, provider, reimbursifyRedirectUrl]);

  if (redirectToZpmStandalonePayment && practiceManagementClinicCreatorInfo) {
    window.location.href = `/zpm-signup/?a=${
      practiceManagementClinicCreatorInfo.accountId
    }&email=${encodeURIComponent(
      practiceManagementClinicCreatorInfo.email
    )}&first_name=${encodeURIComponent(
      practiceManagementClinicCreatorInfo.firstName
    )}&last_name=${encodeURIComponent(practiceManagementClinicCreatorInfo.lastName)}`;

    // Stop further page processing.
    return (
      <div key='info-form'>
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      </div>
    );
  }

  if (isSignedIn) {
    if (reimbursifyRedirectUrl) {
      if (!startedGetMeFetch) {
        setStartedGetMeFetch(true);
        dispatch(mainActions.getMe());
      }

      // Once current provider is loaded, use that id.
      if (!provider) {
        return (
          <div key='info-form'>
            <div className='loader-container w-100 m-t-md flex align-center justify-center'>
              <Loader />
            </div>
          </div>
        );
      }
    } else {
      let providerIdRedirect = false;
      if (fromSearch && fromSearch.length > 0) {
        const urlParams = getQueryAsObject(fromSearch);
        const providerIdUrlParam = urlParams.provider_id;
        if (providerIdUrlParam) {
          providerIdRedirect = true;
          // Do full refresh if there is a provider_id param as we
          // must do that to get auto-switch to work.
          // @ts-ignore-next-line
          window.location = `${fromPathName}${fromSearch}`;
        }
      }

      if (!providerIdRedirect) {
        return (
          <Redirect
            to={{
              pathname: fromPathName,
            }}
          />
        );
      }
    }
  }

  if (isLoading) {
    return (
      <div key='info-form'>
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      <LoginForm
        login={login}
        loginVerifyTwoFactor={loginVerifyTwoFactor}
        twoFactorCodeNeeded={twoFactorCodeNeeded}
        phoneLastFour={phoneLastFour}
        email={email}
        fromClientPortal={fromClientPortal}
        loginErrorReservedName={loginErrorReservedName}
      />

      {askIfClientLoginDesiredModalOpen && (
        <AskIfClientLoginDesiredModal
          open={askIfClientLoginDesiredModalOpen}
          toggle={setAskIfClientLoginDesiredModalOpen}
        />
      )}

      {zpmPortalLinkModalOpen && (
        <ZpmPortalLinkModal
          open={zpmPortalLinkModalOpen}
          toggle={setZpmPortalLinkModalOpen}
          practiceManagementSubdomain={practiceManagementSubdomain}
        />
      )}
    </>
  );
};

// @ts-ignore-next-line
export default connect((state) => state.auth, {
  login: actions.login,
  loginVerifyTwoFactor: actions.loginVerifyTwoFactor,
})(Login);
