import React from 'react';
import { capitalize } from 'voca';

export default (props) => (
  <div className={`alert ${props.type} w-100`}>
    <div className='content'>
      <strong>{capitalize(props.type)}!</strong>
      <p>{props.message}</p>
    </div>
    <span className='close'>
      <button
        className='link'
        onClick={() => {
          props.remove(props.id);
        }}
      >
        &times;
      </button>
    </span>
  </div>
);
