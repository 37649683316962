import React, { useEffect } from 'react';
import StandaloneCoupon from './StandaloneCoupon';
import { products } from '../payment/seat-based/constants';
import { commaSeparateValues } from '../reimbursify/reimbursifyUtils';
import { useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './sagaSlice';
import SupportLink from './SupportLink';

function StandaloneSummaryBox() {
  const form = useForm();
  const { count, tier, coupon } = form.getState().values;
  const dispatch = useDispatch();
  const { previewTotal } = useSelector<State, PracticeManagementState>(
    (state) => state.practiceManagement
  );

  const chosenProduct =
    tier === 'professional'
      ? products.PRACTICE_MANAGEMENT_PRO_STANDALONE
      : products.PRACTICE_MANAGEMENT_PREMIUM_STANDALONE;

  const total = commaSeparateValues(previewTotal);

  useEffect(() => {
    const sagaParam: ZpmSignupPayPreviewPayload = {
      params: {},
      apiPayload: {
        zpm_tier: tier,
        quantity: count,
        coupon: coupon ? coupon : undefined,
      },
    };
    // @ts-expect-error
    dispatch(actions.zpmSignupPayPreview(sagaParam));
  }, [count, coupon, dispatch, tier]);

  return (
    <>
      <div className='standalone-summary-box box'>
        <h5 className='m-0 title'>Monthly Plan</h5>
        <div className='m-t-sm flex justify-between align-center'>
          <p>
            {chosenProduct.EXACT_PRODUCT_NAME_FROM_STRIPE} x{count}
          </p>
          <p>
            <span className='bold'>${chosenProduct.PRICE}</span> / Month
          </p>
        </div>
        <StandaloneCoupon />
        <hr />
        <div className='total-line flex justify-between align-center'>
          <p>Total due today</p>
          <p className='total'>${total}</p>
        </div>
      </div>
      <SupportLink />
    </>
  );
}

export default StandaloneSummaryBox;
