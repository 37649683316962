import React from 'react';

export const PhotoLicensing = () => (
  <div
    id='static-page'
    className='main-content'
    style={{
      maxWidth: '1080px',
      margin: '0 auto',
    }}
  >
    <h1 className='title m-b-sm'>Using Photos for Personal Use</h1>
    <p className='m-b-sm'>Updated September 2, 2021</p>
    <p className='m-b-sm'>
      Members are welcome to use Zencare photos and videos on personal websites!
    </p>
    <p className='m-b-sm'>
      However, we ask that you not use them on websites that compete with Zencare (e.g. other
      provider directories). Thank you for your understanding!
    </p>
    <p className='m-b-sm'>
      You can download photos directly from your profile and{' '}
      <a href='/benefits/video-embedding'>access video embed code here</a>; please see our{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.zencare.co/terms-of-service-photography/'
      >
        photography terms of service
      </a>{' '}
      for full details.
    </p>
    <p className='m-b-sm'>*******</p>
    <p className='m-b-sm'>Photography policy for Zencare member therapists</p>
    <ul>
      <li>
        <strong>Photo ownership:&nbsp;</strong>I understand that all photos taken during Zencare
        photoshoots ("Zencare photos") are owned by Zencare Group Inc. ("Zencare").
      </li>
      <li>
        <strong>Photo usage permission:&nbsp;</strong>I understand that I can use my Zencare photos
        on my own website and promotional materials for professional and personal needs. I also
        understand that I cannot use my Zencare photos on competitor sites such as therapist and
        medical professional directories. I understand I may be asked to leave the professional
        network and/or end my Zencare membership if I violate this agreement.
      </li>
      <li>
        <strong>Video ownership and usage permission:&nbsp;</strong>I understand that the video
        taken duringZencare videoshoot is owned by Zencare Group Inc. ("Zencare") but can be used
        for personal websites or group practice websites. I understand that I will not be granted
        permission to download any version of the video, and will only have access via Vimeo and
        Youtube.
      </li>
      <li>
        <strong>Cancellation policy:&nbsp;</strong>In the event of a Zencare account cancellation, I
        understand that access to photos and videos will be removed, and no longer available to
        appear in web search results or be used on personal websites.
      </li>
      <li>
        <strong>Zencare marketing:&nbsp;</strong> I give Zencare and my photographer permission to
        use Zencare photos on their website, blog, social media, and any marketing material for
        advertising purposes. If I do not want Zencare to use my photographs for these purposes, I
        will indicate this to them in written form by email, with the understanding that this will
        lower the amount of exposure I receive with prospective clients than what Zencare could
        otherwise provide.
      </li>
    </ul>
    <p>
      By confirming a photoshoot with us, you agree to these policies. Thank you for your
      understanding!
    </p>
  </div>
);
