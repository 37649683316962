/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { onlyOnEnter } from '../../../../utils';
import { Field, Form } from 'react-final-form';
import { getValidationErrors, isFullyFilledOut } from './validationUtils';
import stateAbbrDict from '~/utils/stateAbbrDict';
import _ from 'lodash';
import { actions as mainActions } from '~/components/main/sagaSlice';
import { useDispatch, useSelector } from 'react-redux';

const curYear = new Date().getUTCFullYear();
const yearOptions = [...Array(70).keys()].map((x) => curYear + 20 - x);
const stateOptions = Object.values(stateAbbrDict);

interface Props {
  provider: Provider;
  licenses: License[];
  setEditingLicenses: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingSupervisorLicenses: React.Dispatch<React.SetStateAction<boolean>>;
  setNudgeLicenseWithoutLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function EditLicensesForm({
  provider,
  licenses,
  setEditingLicenses,
  setEditingSupervisorLicenses,
  setNudgeLicenseWithoutLocationModalOpen,
}: Props) {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const [licenseIdsPendingDelete, setLicenseIdsPendingDelete] = useState<$TSFixMe>([]);
  const [newlyAddedLicenseCounter, setNewlyAddedLicenseCounter] = useState(0);
  const [credentialBoxes, setCredentialBoxes] = useState<$TSFixMe>([]);
  const { locations } = useSelector<State, MainState>((state) => state.main);

  useEffect(() => {
    if (provider && provider.id) {
      dispatch(mainActions.getLicenses({ id: provider.id }));
    }
  }, [dispatch, provider]);

  useEffect(() => {
    const newInitialValues: $TSFixMe = {};
    const boxes: Array<$TSFixMe> = [];
    const states = _.uniq(locations.map((x) => x.state));
    // Always show missing credential boxes first
    states.forEach((state) => {
      if (!licenses.find((x) => x.state === state)) {
        boxes.push({
          licenseId: null,
          newlyAddedLicenseNumber: null,
          state,
          showRequiredNote: true,
          stateEditable: false,
          verificationStatus: 'Unverified',
          notes: null,
          initialValues: {
            state: null,
            number: null,
            license_type: null,
            expiration: null,
          },
        });
      }
    });

    licenses.forEach((existingLicense) => {
      const box = {
        licenseId: existingLicense.id,
        newlyAddedLicenseNumber: null,
        state: existingLicense.state,
        showRequiredNote: false,
        stateEditable: false,
        verificationStatus: existingLicense.verification,
        notes: existingLicense.notes,
        initialValues: {
          state: existingLicense.state,
          number: existingLicense.number,
          license_type: existingLicense.license_type,
          expiration: existingLicense.expiration.toString(),
        },
      };
      boxes.push(box);
      const boxIndex = boxes.length - 1;
      // Now update initial values
      newInitialValues[`state-${boxIndex}`] = existingLicense.state;
      newInitialValues[`number-${boxIndex}`] = existingLicense.number;
      newInitialValues[`license_type-${boxIndex}`] = existingLicense.license_type;
      newInitialValues[`expiration-${boxIndex}`] = box.initialValues.expiration;
    });
    setCredentialBoxes(boxes);
    setInitialValues(newInitialValues);
  }, [licenses, locations]);

  const addNewLicenseBox = () => {
    const newBox = {
      licenseId: null,
      newlyAddedLicenseNumber: newlyAddedLicenseCounter + 1,
      state: null,
      showRequiredNote: false,
      stateEditable: true,
      verificationStatus: 'Unverified',
      notes: null,
      initialValues: {
        state: null,
        number: null,
        license_type: null,
        expiration: null,
      },
    };
    setNewlyAddedLicenseCounter((newlyAddedLicenseCounter) => newlyAddedLicenseCounter + 1);
    setCredentialBoxes((credentialBoxes: $TSFixMe) => [...credentialBoxes, newBox]);
  };

  const handleSubmit = (values: $TSFixMe) => {
    if (submitDisabled(values)) {
      return;
    }

    const payload: $TSFixMe = {
      providerId: provider.id,
      licenses: [],
      deleteLicenseIds: licenseIdsPendingDelete,
    };
    const statesForLicenses: $TSFixMe = [];
    credentialBoxes.forEach((credentialBox: $TSFixMe, i: $TSFixMe) => {
      // Save any that are fully filled out
      if (
        !licenseIdsPendingDelete.includes(credentialBox.licenseId) &&
        isFullyFilledOut(i, values, credentialBoxes)
      ) {
        const state = credentialBoxes[i].state || values[`state-${i}`];
        statesForLicenses.push(state);
        payload.licenses.push({
          id: credentialBox.licenseId,
          state,
          number: values[`number-${i}`],
          license_type: values[`license_type-${i}`],
          expiration: parseInt(values[`expiration-${i}`]),
        });
      }
    });
    if (payload.licenses.length > 0 || payload.deleteLicenseIds.length > 0) {
      dispatch(mainActions.updateLicenses(payload));
    }
    setEditingLicenses(false);
    // Now need to clear out license ids pending deletion
    setLicenseIdsPendingDelete([]);

    // If there is not a matching location for any license, encourage the user to add locations
    let showPrompt = false;
    for (const stateName of statesForLicenses) {
      const matchingLocation = locations.find((x) => x.state === stateName);
      if (!matchingLocation) {
        showPrompt = true;
        break; // Stop for loop
      }
    }

    if (showPrompt) {
      setNudgeLicenseWithoutLocationModalOpen(true);
    }
  };

  const submitDisabled = (values: $TSFixMe) =>
    Object.keys(getValidationErrors(values, credentialBoxes)).length > 0;

  const renderValidationErrors = (values: $TSFixMe) => {
    const errors: $TSFixMe = getValidationErrors(values, credentialBoxes);

    return Object.keys(errors).map((errorKey) => <p className='text-error'>{errors[errorKey]}</p>);
  };

  const removeUnsavedLicense = (newlyAddedLicenseNumber: number) => {
    setCredentialBoxes((credentialBoxes: $TSFixMe) =>
      credentialBoxes.filter(
        (box: $TSFixMe) => box.newlyAddedLicenseNumber !== newlyAddedLicenseNumber
      )
    );
  };

  const confirmMarkLicenseDeleted = (licenseId: number) => {
    let readyToContinue = true;

    // See if a provider is trying to delete a credential that is the only one tied to a state
    const license = licenses.find((x) => x.id === licenseId);
    const matchingStateLocations = locations.filter((x) => x.state === license?.state);
    if (matchingStateLocations.length > 0) {
      const matchingLicenseLocations = licenses.filter((x) => x.state === license?.state);
      if (matchingLicenseLocations.length === 1) {
        readyToContinue = false;
        alert(
          `A license is required for every state in which you’re practicing. To delete this license, please first provide the details for your new license in ${license?.state}, or remove the practice location.`
        );
      }
    }

    if (readyToContinue && window.confirm("Are you sure you'd like to delete this credential?")) {
      markLicenseDeleted(licenseId);
    }
  };

  // We need to show errors even if never visited so can't use normal <ErrorField>
  // Our error setting code only sets error if user has partially filled
  // out something and needs to fill out the rest. We need to stop rendering
  // errors if user deletes all their credential info for a state they had not yet added one to
  // (i.e. if they enter just license number, then change their mind and erase it,
  // we should stop requiring it since a provider is allowed to submit with some credentials not filled in)
  const renderConditionalError = (values: $TSFixMe, fieldName: string) => {
    const validationErrors: $TSFixMe = getValidationErrors(values, credentialBoxes);
    if (Object.keys(validationErrors).includes(fieldName)) {
      return <p className='text-error'>{validationErrors[fieldName]}</p>;
    }
  };
  const markLicenseDeleted = (licenseId: number) => {
    setLicenseIdsPendingDelete((licenseIdsPendingDelete: $TSFixMe) => [
      ...licenseIdsPendingDelete,
      licenseId,
    ]);
  };

  const renderCredentialBoxes = (values: $TSFixMe) =>
    credentialBoxes
      .filter(
        (credentialBox: $TSFixMe) => !licenseIdsPendingDelete.includes(credentialBox.licenseId)
      )
      .map((box: $TSFixMe, i: $TSFixMe) => (
        <div className='edit-credential' key={i}>
          <div className='box m-b-sm'>
            {box.showRequiredNote && (
              <div className='field w-100 m-b-xs'>
                <div className='m-xs'>
                  <p className='text-error bold'>Required to display {box.state} location.</p>
                </div>
              </div>
            )}
            <div className='field w-49 m-b-xs'>
              <div className='m-xs'>
                <h5>License State</h5>
                {box.stateEditable ? (
                  <>
                    <Field
                      name={`state-${i}`}
                      component='select'
                      placeholder='Select state'
                      type='select'
                    >
                      <option value='' disabled selected hidden>
                        Select state
                      </option>
                      {stateOptions.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))}
                      <i className='fas fa-caret-down'></i>
                    </Field>
                    {renderConditionalError(values, `state-${i}`)}
                  </>
                ) : (
                  <>{box.state}</>
                )}
              </div>
            </div>
            <div className='field w-49 m-b-xs'>
              <div className='m-xs'>
                <h5>License Number</h5>
                <Field
                  name={`number-${i}`}
                  className='w-100'
                  component='input'
                  required={true}
                  type='input'
                  placeholder='e.g. Psy222592'
                />
                {renderConditionalError(values, `number-${i}`)}
              </div>
            </div>

            <div className='field w-49 m-b-xs'>
              <div className='m-xs'>
                <h5>License Type</h5>
                <Field
                  name={`license_type-${i}`}
                  className='w-100'
                  component='input'
                  required={true}
                  type='input'
                  placeholder='e.g. PhD, LCSW, LCAT'
                />
                {renderConditionalError(values, `license_type-${i}`)}
              </div>
            </div>
            <div className='field w-49 m-b-xs'>
              <div className='m-xs'>
                <h5>License Expiration Year</h5>
                <Field name={`expiration-${i}`} component='select' placeholder='' type='select'>
                  <option value='' disabled selected hidden>
                    Select year
                  </option>
                  {yearOptions.map((o) => (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  ))}
                  <i className='fas fa-caret-down'></i>
                </Field>
                {renderConditionalError(values, `expiration-${i}`)}
              </div>
            </div>

            {/* Note: we don't show a verification status for legacy licenses. */}
            {(!box.licenseId || box.verificationStatus !== 'Legacy') && (
              <div className='field w-100 m-b-xs'>
                <div className='m-xs'>
                  <h5>Verification Status</h5>
                  {box.licenseId && box.verificationStatus === 'Unverified' && (
                    <p className='verification-status unverified-text'>
                      Unverified{' '}
                      <i
                        className='fal fa-info-circle'
                        data-for='license-tooltip'
                        data-tip='The Zencare team is working on verifying your license.'
                      ></i>
                    </p>
                  )}
                  {!box.licenseId && box.verificationStatus === 'Unverified' && (
                    <p className='verification-status unverified-text'>
                      Unverified{' '}
                      <i
                        className='fal fa-info-circle'
                        data-for='license-tooltip'
                        data-tip='Once you submit a new license, the Zencare team will work on verifying it.'
                      ></i>
                    </p>
                  )}
                  {box.verificationStatus === 'Verified' && (
                    <p className='verification-status text-success'>
                      Verified{' '}
                      <i
                        className='fal fa-info-circle'
                        data-for='license-tooltip'
                        data-tip='License has been verified through a manual lookup by the Zencare team'
                      ></i>
                    </p>
                  )}
                  {box.verificationStatus === 'Not found' && (
                    <p className='verification-status text-error'>
                      Could not verify{' '}
                      <i
                        className='fal fa-info-circle'
                        data-for='license-tooltip'
                        data-tip='License could not be found under the indicated name and license number.'
                      ></i>
                    </p>
                  )}
                </div>
              </div>
            )}

            {box.notes && box.notes.length ? (
              <div className='field w-100 m-b-xs'>
                <div className='m-xs'>
                  <h5>Notes regarding verification</h5>
                  {box.notes}
                </div>
              </div>
            ) : null}
          </div>
          {box.licenseId !== null && (
            <div className='delete'>
              <i
                role='button'
                tabIndex={0}
                onKeyDown={onlyOnEnter(() => confirmMarkLicenseDeleted(box.licenseId))}
                onClick={() => confirmMarkLicenseDeleted(box.licenseId)}
                className='far fa-minus-circle'
              ></i>
            </div>
          )}
          {box.newlyAddedLicenseNumber && (
            <div className='delete'>
              <i
                role='button'
                tabIndex={0}
                onKeyDown={onlyOnEnter(() => removeUnsavedLicense(box.newlyAddedLicenseNumber))}
                onClick={() => removeUnsavedLicense(box.newlyAddedLicenseNumber)}
                className='far fa-minus-circle'
              ></i>
            </div>
          )}
        </div>
      ));

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={(values) => getValidationErrors(values, credentialBoxes)}
      render={(formProps) => (
        <>
          <div className='flex row justify-between m-b-sm wrap tab-container'>
            {renderCredentialBoxes(formProps.values)}

            <div
              onClick={addNewLicenseBox}
              tabIndex={0}
              role='button'
              onKeyDown={onlyOnEnter(() => addNewLicenseBox)}
              className='add m-b-md'
            >
              <i className='far fa-plus-circle'></i> Add Another License
            </div>
          </div>

          <div className='actions'>
            {renderValidationErrors(formProps.values)}
            <button
              className={submitDisabled(formProps.values) ? 'md' : 'primary md'}
              disabled={submitDisabled(formProps.values)}
              onClick={() => {
                handleSubmit(formProps.values);
              }}
            >
              Submit
            </button>
          </div>
        </>
      )}
    />
  );
}

export default EditLicensesForm;
