import React from 'react';
import { useSelector } from 'react-redux';
import ProgressIndicator from './ProgressIndicator';
import steps from './steps';
import RegistrationForm from './RegistrationForm';
import Callout from './Callout';

interface Props {
  step: 0 | 1 | 2 | 3 | 4 | 5;
}

function OnboardingFromClient() {
  const { step: curStep } = useSelector((state: $TSFixMe) => state.onboarding);

  return (
    <div className='onboarding-component-container flex column align-center'>
      <ProgressIndicator steps={steps} curStep={curStep} />
      <div
        style={{
          width: '90%',
          textAlign: 'left',
          maxWidth: '1200px',
        }}
      >
        <h1 className='m-b-md m-l-0'>
          Welcome to Zencare! <i className='fad fa-sun'></i>
          <br />
          <p className='heading-5 semi-bold'>Let's create your account!</p>
        </h1>
      </div>
      <section className='onboarding-section'>
        <div className='onboarding-form'>
          <RegistrationForm
            isFromExistingClientUser={true}
            registerAndCreateProvider={undefined}
            registerAndCreateProviderFromExistingClientAccount={undefined}
            isLoading={undefined}
            inviteToken={undefined}
            error={undefined}
          />
        </div>
        <Callout step={curStep} />
      </section>
    </div>
  );
}

export default OnboardingFromClient;
