// @ts-check
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { actions as mainActions } from '~/components/main/sagaSlice';
import EditBox from '+/EditBox';
import ReadOnlyLicenses from './ReadOnlyLicenses';
import ReadOnlySupervisorLicense from './ReadOnlySupervisorLicense';
import NudgeLicenseWithoutLocationModal from './NudgeLicenseWithoutLocationModal';
import EditLicensesForm from './EditLicensesForm';
import EditSupervisorLicenseForm from './EditSupervisorLicenseForm';

// @ts-ignore
const mapStateToProps = ({ main }) => ({
  provider: main.provider,
  licenses: main.licenses,
});

const actions = {
  getLicenses: mainActions.getLicenses,
  updateLicenses: mainActions.updateLicenses,
};

/**
 *
 * @param {$TSFixMe} param0
 * @returns
 */
export const ProviderLicenses = ({ provider, licenses }) => {
  const [editingLicenses, setEditingLicenses] = useState(false);
  const [editingSupervisorLicenses, setEditingSupervisorLicenses] = useState(false);
  const [nudgeLicenseWithoutLocationModalOpen, setNudgeLicenseWithoutLocationModalOpen] =
    useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [editingLicenses, licenses]);

  return (
    <>
      <ReactTooltip
        id='license-tooltip'
        effect='solid'
        place='right'
        type='light'
        arrowColor='#37BEC3'
      ></ReactTooltip>
      {/* @ts-ignore */}
      <EditBox
        boxClassName='provider-licenses'
        title={provider.type === 'individual' ? 'Licensure' : "Head Clinician's Licensure"}
        editable={true}
        setEditing={setEditingLicenses}
        editing={editingLicenses}
        innerEditing={
          <EditLicensesForm
            provider={provider}
            licenses={licenses}
            setEditingLicenses={setEditingLicenses}
            setEditingSupervisorLicenses={setEditingSupervisorLicenses}
            setNudgeLicenseWithoutLocationModalOpen={setNudgeLicenseWithoutLocationModalOpen}
          />
        }
        innerStatic={<ReadOnlyLicenses curYear='curYear' licenses={licenses} />}
      />
      {/* @ts-ignore */}
      <EditBox
        boxClassName='provider-supervisor-licenses'
        title={
          provider.type === 'individual'
            ? 'Supervisor Licensure'
            : "Head Clinician's Supervisor Licensure"
        }
        editable={true}
        setEditing={setEditingSupervisorLicenses}
        editing={editingSupervisorLicenses}
        innerEditing={
          <EditSupervisorLicenseForm
            provider={provider}
            licenses={licenses}
            setEditingLicenses={setEditingLicenses}
            setEditingSupervisorLicenses={setEditingSupervisorLicenses}
            setNudgeLicenseWithoutLocationModalOpen={setNudgeLicenseWithoutLocationModalOpen}
          />
        }
        innerStatic={<ReadOnlySupervisorLicense curYear='curYear' licenses={licenses} />}
      />
      {nudgeLicenseWithoutLocationModalOpen && (
        <NudgeLicenseWithoutLocationModal
          open={nudgeLicenseWithoutLocationModalOpen}
          toggle={setNudgeLicenseWithoutLocationModalOpen}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, actions)(ProviderLicenses);
