import React, { useState, useEffect } from 'react';
import { Button } from '@zencare/ui';
import { capitalize } from '~/utils/capitalize';
import AddEditLocationModal from '../AddEditLocationModal';
import LocationVerificationMessage from './LocationVerificationMessage';

const renderAppointmentTimes = (location, onSubmit, disabled) => {
  const timesOfDay = {
    morning_availability: 'Before 10am',
    daytime_availability: '10am - 1pm',
    evening_availability: 'After 5pm',
    afternoon_availability: '1pm- 5pm',
    weekend_availability: 'Saturdays & Sundays',
  };
  let availabilities = Object.entries(location)
    .filter(([key]) => key.includes('availability'))
    .reduce((acc, curr) => {
      acc[curr[0]] = curr[1];

      return acc;
    }, {});

  availabilities = {
    morning_availability: availabilities.morning_availability,
    daytime_availability: availabilities.daytime_availability,
    afternoon_availability: availabilities.afternoon_availability,
    evening_availability: availabilities.evening_availability,
    weekend_availability: availabilities.weekend_availability,
  };

  return Object.entries(availabilities).map(([key, val]) => (
    <div>
      {val ? (
        <span className='text-primary'>
          <i className='fas fa-check-circle'></i>
        </span>
      ) : (
        <span className='text-error'>
          <i className='fas fa-times-circle'></i>
        </span>
      )}
      &nbsp;
      {key === 'daytime_availability' ? 'Midday' : capitalize(key).replace('_availability', '')}
      &nbsp;({timesOfDay[key]})
    </div>
  ));
};

const renderPracticeHours = (location) => {
  const days = ['mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun'];

  return Object.entries(location.hours).map(([key, val], i) => (
    <div key={i} className='hour'>
      <span>{capitalize(days[key])}</span>
      <span>{val ? val : 'Please inquire about hours'}</span>
    </div>
  ));
};

const renderLocationDetails = (location, onSubmit) => {
  const questions = {
    ada_accessible: 'Is this office ADA Accessible?',
    display_address: 'Would you like this address displayed on your profile?',
  };
  const conditions = ['ada_accessible', 'display_address'];

  const locationDetails = Object.entries(location)
    .filter(([key]) => conditions.some((el) => key.includes(el)))
    .reduce((acc, curr) => {
      acc[curr[0]] = curr[1];

      return acc;
    }, {});

  return Object.entries(locationDetails).map(([key, val]) => (
    <div>
      {val ? (
        <span className='text-primary'>
          <i className='fas fa-check-circle'></i>
        </span>
      ) : (
        <span className='text-error'>
          <i className='fas fa-times-circle'></i>
        </span>
      )}
      &nbsp;{questions[key]}
    </div>
  ));
};

const locationHoursUnspecified = (location) =>
  location.hours && location.hours.some((h) => h === 'Please inquire about hours');

const insurancesAccepted = (provider, location) =>
  provider.insurances
    .filter(
      (insuranceTag) =>
        !provider.insurance_opt_outs.find(
          (insuranceOptOut) =>
            insuranceOptOut.state === location.state && insuranceOptOut.tag_id === insuranceTag.id
        )
    )
    .map((insuranceTag) => insuranceTag.name.trim())
    .join(', ');

export const LocationView = ({
  provider,
  location,
  primaryLocationId,
  onSubmit,
  existingLocations,
  licenses,
}) => {
  const [edit, toggleEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isRemote, setIsRemote] = useState(false);

  useEffect(() => {
    setIsRemote(!!location.remote_location);

    if (location.accepting_patients) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [location]);

  const formattedAddress = [location.address, location.address_2, location.city, location.state]
    .filter((x) => !!x)
    .join(', ');

  const onEditLocation = (location) => {
    toggleEdit(false);
    onSubmit(location);
  };

  return (
    <div className='location-view2'>
      {edit && (
        <AddEditLocationModal
          open={edit}
          toggle={toggleEdit}
          onSubmit={onEditLocation}
          isEdit={true}
          location={location}
          existingLocations={existingLocations}
        />
      )}
      <div className='header'>
        <div className='location'>
          <div className='area'>
            <h3 className='name'>{location.location_name}</h3>
            {location.id === primaryLocationId && <i className='fas fa-star'></i>}

            <Button onClick={() => toggleEdit(!edit)} className='primary mobile-hide'>
              Edit
            </Button>
          </div>
          {!location.remote_location && <h6 className='address'>{formattedAddress}</h6>}
        </div>
      </div>
      <div className='credential-verification'>
        {location && licenses && (
          <LocationVerificationMessage licenses={licenses} location={location} />
        )}
      </div>
      {provider && provider.insurances && provider.insurances.length ? (
        <div className='insurances-accepted'>
          <div className='sub-header'>
            <h5 className='title'>Insurances Accepted</h5>
            <span className='desc block'>
              {insurancesAccepted(provider, location).length ? (
                <>
                  <i className='fas fa-shield-check in-network-badge'></i>{' '}
                  {insurancesAccepted(provider, location)}
                </>
              ) : (
                'None'
              )}
            </span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='appointment-info'>
        <div className='available-hours'>
          <div className='sub-header'>
            <h5 className='title'>Open Appointment Times</h5>
            <span className='desc block'>Times when you are accepting clients.</span>
            {disabled && (
              <span className='text-error'>
                You are indicated as not accepting new clients in this location.
              </span>
            )}
          </div>
          <div className={'select-items'} style={{ backgroundColor: disabled ? '#d4d4d4' : '' }}>
            {renderAppointmentTimes(location, onSubmit, disabled)}
          </div>
        </div>
        <div className='regular-hours'>
          <div className='sub-header'>
            <h5 className='title'>Regular Practice Hours</h5>
            <span className='desc block'>When you typically see clients.</span>
            {locationHoursUnspecified(location) && (
              <span className='text-error'>
                We recommend indicating specific hours, such as "9am - 4pm"!
              </span>
            )}
          </div>
          <div className='hours'>{renderPracticeHours(location)}</div>
        </div>
      </div>
      {!isRemote && (
        <>
          <hr className='mobile-hide'></hr>
          <div className='location-details'>
            <h5 className='sub-header'>Practice Location Details</h5>
            <div className='toggle-items'>{renderLocationDetails(location, onSubmit)}</div>
          </div>
        </>
      )}
      <Button onClick={() => toggleEdit(!edit)} className='primary mobile-only m-t-md'>
        Edit
      </Button>
    </div>
  );
};

export default LocationView;
