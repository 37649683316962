const isPartiallyFilledOut = (i, values) => {
  // Note: We intentionally don't count selecting only expiration date
  // as partially filling out a license
  // because there is no way to 'erase' and cancel out of filling out
  // an expiration date drop-down like with text fields
  if (
    (values[`state-${i}`] && values[`state-${i}`].length > 0) ||
    (values[`number-${i}`] && values[`number-${i}`].length > 0) ||
    (values[`license_type-${i}`] && values[`license_type-${i}`].length > 0)
  ) {
    return true;
  }

  return false;
};

export const getValidationErrors = (values, credentialBoxes) => {
  const errors = {};
  credentialBoxes.forEach((_credentialBox, i) => {
    // If a credential box is partially filled out require
    // it to be fully filled out, otherwise allow it to be fully empty and it will be ignored.
    if (isPartiallyFilledOut(i, values)) {
      if (
        !(credentialBoxes[i].state || (values[`state-${i}`] && values[`state-${i}`].length > 0))
      ) {
        errors[`state-${i}`] = 'License State is required';
      }
      if (values[`number-${i}`] && values[`number-${i}`].length > 0) {
        if (values[`number-${i}`].length > 60) {
          errors[`number-${i}`] = 'License Number has a max length of 60 characters.';
        }
      } else {
        errors[`number-${i}`] = 'License Number is required';
      }

      if (!(values[`license_type-${i}`] && values[`license_type-${i}`].length > 0)) {
        errors[`license_type-${i}`] = 'License Type is required';
      }

      if (!(values[`expiration-${i}`] && values[`expiration-${i}`].length > 0)) {
        errors[`expiration-${i}`] = 'License Expiration Year is required';
      }
    }
  });

  return errors;
};

export const isFullyFilledOut = (i, values, credentialBoxes) => {
  if (!(credentialBoxes[i].state || (values[`state-${i}`] && values[`state-${i}`].length > 0))) {
    return false;
  }
  if (
    !credentialBoxes[i].initialValues.number &&
    !(values[`number-${i}`] && values[`number-${i}`].length > 0)
  ) {
    return false;
  }

  if (
    !credentialBoxes[i].initialValues.license_type &&
    !(values[`license_type-${i}`] && values[`license_type-${i}`].length > 0)
  ) {
    return false;
  }

  if (
    !credentialBoxes[i].initialValues.license_type &&
    !(values[`expiration-${i}`] && values[`expiration-${i}`].length > 0)
  ) {
    return false;
  }

  return true;
};
