import React from 'react';
import { formatMessageTime } from '~/utils/formatTime';
import { actions } from '../sagaSlice';
import { actions as practiceInboxActions } from '../../../../practice-inbox/sagaSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Linkify from 'react-linkify';
import punycode from 'punycode';

function Message({ message }) {
  const { message_content, send_date, deleted_at, read_at, sender, id, provider_id } = message;
  const { provider } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const location = useLocation();
  const practiceInbox = location.pathname.includes('/practice-inbox');

  function handleDeleteMessage(id) {
    return () => {
      if (
        window.confirm('Are you sure you want to delete this message? This cannot be undone.') ===
        true
      ) {
        if (practiceInbox) {
          return dispatch(
            practiceInboxActions.deleteMessage({
              provider_id,
              message_id: id,
              account_id: provider.account_id,
            })
          );
        }
        return dispatch(actions.deleteMessage({ provider_id, message_id: id }));
      }
    };
  }

  function renderMessageContent() {
    if (!deleted_at) {
      return message_content;
    }
    // If deleted, render special message based on sender
    if (sender === 'provider') {
      return 'You deleted this message.';
    }
    if (sender === 'client') {
      return `This message has been deleted.`;
    }
    return (
      <>
        {message_content}{' '}
        {message_content === 'This message is no longer available.' ? (
          <i
            className='fal fa-info-circle'
            data-for='redaction-tooltip'
            data-tip='This client deleted all of their personal data and messages from Zencare! We kept this record as a reminder that the referral was received, but any content and personal information is no longer accessible. Please reach out to support@zencare.co if you have any questions.'
          ></i>
        ) : (
          ''
        )}
      </>
    );
  }

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target='_blank' rel='noopener'>
      {punycode.toASCII(text)}
    </a>
  );

  return (
    <div className={`message ${sender === 'provider' ? 'message_provider' : ''}`}>
      <div className={`message-bubble ${sender === 'provider' ? 'message-bubble_provider' : ''}`}>
        <Linkify componentDecorator={componentDecorator}>
          <p className={`message-content ${deleted_at && 'deleted'}`}>{renderMessageContent()}</p>
        </Linkify>
        {sender === 'client' && !read_at ? (
          <span className='message-unread-notification'></span>
        ) : null}
        {sender === 'provider' && !deleted_at && (
          <button type='button' onClick={handleDeleteMessage(id)} className='delete-message'>
            <i className='fas fa-trash'></i>
          </button>
        )}
      </div>
      <small className='message-date'>Sent {formatMessageTime(send_date)}</small>
    </div>
  );
}

export default Message;
