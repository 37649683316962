/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { actions as mainActions } from '@/main/sagaSlice';
import { actions as paymentActions } from '@/payment/sagaSlice';
import {
  IncompleteSubscription,
  Subscription,
  IncompletePracticeManagementSubscription,
} from './Subscriptions';
import InvoiceSection from './InvoiceSection';
import PaymentMethods from './PaymentMethods';
import { ACTIVE_SUBSCRIPTION_STATUSES, CANCELED_SUBSCRIPTION_STATUSES } from './constants';
import {
  getIncompleteProviders,
  overlappingSubsWithStatus,
  getCorrespondingProviderIds,
} from './helpers';
import LinkButton from '+/LinkButton';
import history from '#/history';
import CanceledProvider from './seat-based/CanceledProvider';
import { SeatBasedSubscription } from './seat-based/SeatBasedSubscriptions';
import Loader from '+/forms/SectionLoader';
import { getQueryAsObject } from '~/utils/queryParams';

const OFFSET_PADDING = 84;

const PlanManagement = ({
  accounts,
  getUpcomingInvoice,
  getAllMyProviders,
  getAllAccountDetails,
  getPendingZencarePracticeManagementClinics,
  provider,
  isLoading,
  pendingZencarePracticeManagementClinicData,
}: $TSFixMe) => {
  const urlParams = getQueryAsObject(window.location.search);

  if (urlParams.action === 'update_payment') {
    const paymentSection = document.getElementById('payment-methods');
    if (paymentSection) {
      window.scrollTo({ top: paymentSection?.offsetTop - OFFSET_PADDING, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    getAllMyProviders();
    getAllAccountDetails();
    getPendingZencarePracticeManagementClinics();
  }, [getAllMyProviders, getAllAccountDetails, getPendingZencarePracticeManagementClinics]);

  if (_.isNil(accounts)) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className='loader-container w-100 m-t-md flex align-center justify-center'>
        <Loader />
      </div>
    );
  }

  const upgradePlan = (
    data: {
      account_id: number;
      provider_ids: number[];
      provider_names: string[];
    },
    subscription: $TSFixMe
  ) => {
    const selectedAccount = accounts.find((a: Account) => a.id === data.account_id);
    if (!selectedAccount) return;

    // Show modal if:
    // There is 1 or more legacy subscriptions and a seat-based subscription
    // There is more than one legacy subscription
    if (
      ((selectedAccount.legacy_subscription_count > 0 &&
        selectedAccount.seat_based_stripe_subscription_id &&
        selectedAccount.seat_based_stripe_subscription_id.length > 0) ||
        selectedAccount.legacy_subscription_count > 1) &&
      !selectedAccount.has_practice_management
    ) {
      redirectToContactPage();
      return;
    }
    // If subscription has provider_ids property instead of provider_id property
    // use contact modal to handle the their reactivation
    if (subscription?.metadata?.provider_ids?.length > 0) {
      redirectToContactPage();
      return;
    }

    // For the many providers still in trial mode, manual work is needed to
    // switch to new subscription level while maintaining outstanding credits
    // See Scenario #10 or ZEN-804
    if (subscription?.status === 'trialing') {
      redirectToContactPage();
      return;
    }

    if (
      selectedAccount.tier === 'premium' ||
      (selectedAccount.has_practice_management && selectedAccount.tier === 'basic')
    ) {
      console.log(
        JSON.stringify({ message: 'Error! Invalid selection...', ...selectedAccount }, null, 2)
      );
      alert(
        "We shouldn't be here. Chances are we're still working on the plan management page and you hit a scenario that shouldn'nt be accessible. Check the console for account details to see what's up!"
      );
      return;
    }

    history.push(`/practice-management/upgrade/${selectedAccount.id}`);
  };

  const redirectToContactPage = () => {
    // Redirect to /contact
    history.push('/contact');
  };

  const upgradeReimbursify = (data: {
    account_id: number;
    provider_ids: number[];
    provider_names: string[];
    isAnnual?: boolean;
  }) => {
    // Send annual legacy subscriptions to the contact for rather than hiding the button altogether
    const { isAnnual } = data;
    const selectedAccount = accounts.find((a: Account) => a.id === data.account_id);
    if (!selectedAccount) return;

    if (selectedAccount.legacy_subscription_count > 0 || isAnnual) {
      return redirectToContactPage();
    } else {
      history.push(`/reimbursify/upgrade/${selectedAccount.id}`);
    }
  };

  const accountsWithPermission = accounts.filter((a: $TSFixMe) => !a.error);
  // allProviders in state includes providers to which the user has access but does not have billing info
  // perms for. So all we want here is the providers attached to the accounts with billing info perm.
  // @ts-ignore
  const allProviders: Provider[] = _.uniqBy(
    _.flatten(
      accountsWithPermission.map((a: $TSFixMe) => a.providers),
      // @ts-ignore
      'id'
    )
  );

  const getAccountById = (id: number) => {
    if (!id || !accountsWithPermission) return;
    return accountsWithPermission.find((a: Account) => a.id === id);
  };

  // Seat based canceled seats
  const canceledSeats = accountsWithPermission
    .map((a: $TSFixMe) => a.provider_paid_seats)
    .flat()
    .filter((s: $TSFixMe) => s.canceled_at !== null);

  if (accountsWithPermission.length) {
    const invoices = _.flattenDeep(
      accountsWithPermission.map((ap: $TSFixMe) =>
        ap.stripe_customers.map((sc: $TSFixMe) => sc.stripe_invoices)
      )
    );
    const charges = _.flattenDeep(
      accountsWithPermission.map((ap: $TSFixMe) =>
        ap.stripe_customers.map((sc: $TSFixMe) => sc.stripe_charges)
      )
    );
    // TODO: This is replicated in /main/sagaSlice.ts -- Account parsing could be done there in order to be accessible App-wide
    const customers = _.flatten(
      accountsWithPermission.map((a: $TSFixMe) => a.stripe_customers)
    ).filter((x) => !!x);
    const subs = _.flattenDeep(
      customers.map((c: $TSFixMe) =>
        c.stripe_subscriptions.map((s: $TSFixMe) => ({
          ...s,
          customer: c,
        }))
      )
    );

    const seatBasedAccounts = accountsWithPermission.filter((a: Account) => a.seat_based === true);

    const activeSubs = subs.filter(
      (s) => ACTIVE_SUBSCRIPTION_STATUSES.includes(s.status) && s.metadata.seat_based !== 'yes'
    );

    const seatBasedSubs = subs.filter((s) => s.metadata.seat_based === 'yes');

    // Canceled Legacy Subscriptions
    const inactiveSubs = _.uniqBy(
      subs.filter((s) => {
        // Exclude seat based subs in the inactive subs list as we won't use them during reactivation
        // Exclude subscriptions where the provider_id or provider_ids have been removed during the CSX process (ZEN-836)
        if (
          !CANCELED_SUBSCRIPTION_STATUSES.includes(s.status) ||
          s.metadata.seat_based === 'yes' ||
          (!s.metadata.provider_id && !s.metadata.provider_ids)
        ) {
          return false;
        }
        const overlapping = overlappingSubsWithStatus(s, subs, ACTIVE_SUBSCRIPTION_STATUSES);

        let noActiveOrCanceledProviderPaidSeatFound = true;
        const providerIds = getCorrespondingProviderIds(s);
        accountsWithPermission.forEach((account: $TSFixMe) => {
          if (
            account &&
            account.provider_paid_seats.find((x: $TSFixMe) => providerIds.includes(x.provider_id))
          ) {
            // One of the provider ids on this subscription (usually there is just one) matched
            // a provider paid seat. That means we should not show this subscription
            // since one of the providers (usually there is only one) matched
            // either an active or canceled seat-based seat.
            // The appearance of provider in the list of either active of canceled
            // seat-based ones should take precedence over showing an old canceled subscription.
            noActiveOrCanceledProviderPaidSeatFound = false;
          }
        });

        return overlapping.length === 0 && noActiveOrCanceledProviderPaidSeatFound;
      }),
      (x) => getCorrespondingProviderIds(x).join('.')
    );

    const subscriptionsForPaymentMethods = activeSubs.concat(inactiveSubs);
    accountsWithPermission.forEach((a: $TSFixMe) => {
      if (a.seat_based_stripe_subscription_id && a.seat_based_stripe_subscription_id.length) {
        const seatBasedSubscription = subs.find(
          (x) => x.id === a.seat_based_stripe_subscription_id
        );
        if (seatBasedSubscription) {
          subscriptionsForPaymentMethods.push(seatBasedSubscription);
        }
      }
    });

    // Incomplete Legacy Providers
    const incompleteProviders = getIncompleteProviders(
      customers,
      allProviders,
      accountsWithPermission
    );

    const incompleteWarning = [...activeSubs, ...seatBasedSubs].find((s) =>
      ['incomplete', 'past_due', 'unpaid'].includes(s.status)
    );

    const expiredWarning =
      inactiveSubs.find((s) => ['incomplete_expired'].includes(s.status)) ||
      (!seatBasedSubs.find((s) => ACTIVE_SUBSCRIPTION_STATUSES.includes(s.status)) &&
        seatBasedSubs.find((s) => ['incomplete_expired'].includes(s.status)));

    const handleScroll: React.MouseEventHandler<HTMLButtonElement> = () => {
      const paymentSection = document.getElementById('payment-methods');
      if (paymentSection) {
        window.scrollTo({ top: paymentSection?.offsetTop - OFFSET_PADDING, behavior: 'smooth' });
      }
    };

    const showPendingSection =
      incompleteProviders.length > 0 || pendingZencarePracticeManagementClinicData;

    return (
      <div className='account-details'>
        <h1 className='m-b-md'>Plan Management</h1>
        {incompleteWarning && (
          <div className='box text-error m-b-sm flex align-center subscription-warning'>
            <i className='fas fa-info-circle m-r-sm'></i>
            <span>
              {' '}
              One or more of your subscriptions are incomplete because we were unable to
              successfully charge your card. Please{' '}
              <button onClick={handleScroll}>update your payment method</button> to ensure your
              subscription remains active.
            </span>
          </div>
        )}
        <div className='membership-questions-button'>
          <LinkButton className='primary pill md m-b-md m-t-sm' to='/contact' color='primary'>
            Questions about your membership?
          </LinkButton>
        </div>
        {expiredWarning && (
          <div className='box text-error m-b-sm flex align-center subscription-warning'>
            <i className='fas fa-info-circle m-r-sm'></i>
            <span>
              One or more of your subscriptions has expired before starting because we were unable
              to successfully charge your card. Please{' '}
              <button onClick={handleScroll}>update your payment method</button> and reactivate this
              subscription.
            </span>
          </div>
        )}
        {showPendingSection && (
          <>
            <h2 className='m-t-lg'>Pending Memberships</h2>
            {pendingZencarePracticeManagementClinicData && (
              <IncompletePracticeManagementSubscription
                data={pendingZencarePracticeManagementClinicData}
              />
            )}
            {incompleteProviders.length > 0 && (
              <div className='account-box'>
                {incompleteProviders.map((p: $TSFixMe, i: number) => (
                  <>
                    <IncompleteSubscription provider={p} />
                    {i < incompleteProviders.length - 1 && <hr></hr>}
                  </>
                ))}
              </div>
            )}
          </>
        )}
        {(activeSubs.length > 0 || (seatBasedAccounts.length > 0 && subs)) && (
          <h2 className='m-t-lg'>Active Memberships</h2>
        )}
        {activeSubs.length > 0 && (
          <div className='flex wrap'>
            {activeSubs.map((s) => (
              <Subscription
                key={s.id}
                subscription={s}
                customer={s.customer}
                allProviders={allProviders}
                getUpcomingInvoice={getUpcomingInvoice}
                upgradePlan={upgradePlan}
                getAccountById={getAccountById}
                openContactModal={redirectToContactPage}
                account={null}
                accounts={accounts}
                upgradeReimbursify={upgradeReimbursify}
                pendingZencarePracticeManagementClinicData={
                  pendingZencarePracticeManagementClinicData
                }
              />
            ))}
          </div>
        )}
        {seatBasedAccounts.length > 0 && subs && (
          <div className='flex wrap seat-based-active'>
            {seatBasedAccounts.map((a: Account) => (
              <SeatBasedSubscription
                key={a.id}
                allProviders={allProviders}
                getUpcomingInvoice={getUpcomingInvoice}
                upgradePlan={upgradePlan}
                getAccountById={getAccountById}
                openContactModal={redirectToContactPage}
                account={a}
                allSubscriptions={subs}
                upgradeReimbursify={upgradeReimbursify}
                pendingZencarePracticeManagementClinicData={
                  pendingZencarePracticeManagementClinicData
                }
              />
            ))}
          </div>
        )}
        {inactiveSubs.length > 0 && (
          <>
            <h2 className='m-t-md'>Canceled Memberships</h2>
            <div className='flex wrap'>
              {inactiveSubs.map((s) => (
                <Subscription
                  key={s.id}
                  subscription={s}
                  customer={s.customer}
                  allProviders={allProviders}
                  getUpcomingInvoice={undefined}
                  upgradePlan={upgradePlan}
                  getAccountById={getAccountById}
                  openContactModal={redirectToContactPage}
                  upgradeReimbursify={upgradeReimbursify}
                  pendingZencarePracticeManagementClinicData={
                    pendingZencarePracticeManagementClinicData
                  }
                />
              ))}
            </div>
          </>
        )}
        {canceledSeats.length > 0 && (
          <>
            <h2 className='m-t-md'>Canceled Memberships</h2>
            {canceledSeats.map((providerPaidSeat: $TSFixMe) => {
              const canceledProvider = allProviders.find(
                (p: $TSFixMe) => p.id === providerPaidSeat.provider_id
              );
              if (!canceledProvider) return null;
              return (
                <CanceledProvider
                  canceledProvider={canceledProvider}
                  accounts={accountsWithPermission}
                />
              );
            })}
          </>
        )}

        <PaymentMethods
          customers={customers}
          subscriptions={subscriptionsForPaymentMethods}
          allProviders={allProviders}
          accounts={accountsWithPermission}
        />
        <h2 className='m-t-lg'>Billing History</h2>
        <InvoiceSection invoices={invoices} charges={charges} />
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: '70vh',
        maxWidth: '1080px',
        margin: '0 auto',
      }}
    >
      <h1>Manage Your Plan</h1>

      <p>You do not have access to this part of the Members Portal.</p>
      <p>If a practice owner or another provider paid for your account, please contact them.</p>
      <p>
        If you think you are seeing this in error, please <a href='/contact'>contact support.</a>
      </p>
    </div>
  );
};

export default connect(
  ({ main, payment }: $TSFixMe) => ({
    provider: main.provider,
    accounts: main.accounts,
    loading: payment.isLoading,
    isLoading: main.isLoading,
    pendingZencarePracticeManagementClinicData: main.pendingZencarePracticeManagementClinicData,
  }),
  {
    getAllMyProviders: mainActions.getAllMyProviders,
    getAllAccountDetails: mainActions.getAllAccountDetails,
    updateSource: paymentActions.updateSource,
    getUpcomingInvoice: paymentActions.getUpcomingInvoice,
    getPendingZencarePracticeManagementClinics:
      mainActions.getPendingZencarePracticeManagementClinics,
  }
)(PlanManagement);
