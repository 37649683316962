/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import ErrorField from '+/forms/ErrorField';
import SubmitButton from '+/forms/SubmitButton';
import TypeAhead from '+/TypeAhead';
import StartMembershipButton from './StartMembershipButton';

interface Errors {
  session_fee_ongoing?: string;
  session_length_ongoing?: string;
  session_fee_min?: string;
  top_specialties?: string;
  specialties?: string;
  approaches?: string;
}

interface Props {
  providerId: string;
  onSubmit: $TSFixMeFunction;
  youPhrase: string;
  yourPhrase: string;
  clienteleData: string;
  languageData: string;
  approachData: string;
  topSpecialtyData: string;
  getSpecialtyData: $TSFixMeFunction;
}

interface FormValues {
  session_fee_ongoing: string;
  session_length_ongoing: string;
  display_sliding_scale_slots: boolean;
  session_fee_min: string;
  top_specialties: string[];
  specialties: string[];
  approaches: string[];
  languages: string[];
  clientele: string[];
}

const YourPracticeTagsFees: React.FC<Props> = ({
  providerId,
  onSubmit,
  youPhrase,
  yourPhrase,
  clienteleData,
  languageData,
  approachData,
  topSpecialtyData,
  getSpecialtyData,
}) => {
  const [showTopSpecialitiesValidation, setShowTopSpecialitiesValidation] = useState(false);
  const [showSpecialitiesValidation, setShowSpecialitiesValidation] = useState(false);
  const [showApproachesValidation, setShowApproachesValidation] = useState(false);

  let errors: Errors = {};

  // eslint-disable-next-line implicit-arrow-linebreak
  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        setField: ([name, val], state, { changeValue }) => {
          changeValue(state, name, () => val);
        },
      }}
      validate={(values: FormValues) => {
        errors = {};
        const requiredFields: Array<'clientele' | 'languages' | 'approaches'> = [
          'clientele',
          'languages',
          'approaches',
        ];

        if (!values.session_fee_ongoing || !parseFloat(values.session_fee_ongoing)) {
          errors.session_fee_ongoing = 'Session Fee is required.';
        }

        if (!values.session_length_ongoing || !parseInt(values.session_length_ongoing)) {
          errors.session_length_ongoing = 'Session Length is required.';
        }

        if (values.display_sliding_scale_slots && !values.session_fee_min) {
          errors.session_fee_min = 'Please indicate your sliding scale lowest limit';
        }

        if (
          values.session_fee_ongoing &&
          values.display_sliding_scale_slots &&
          values.session_fee_min &&
          parseFloat(values.session_fee_min) >= parseFloat(values.session_fee_ongoing)
        ) {
          errors.session_fee_min =
            'Your sliding scale lowest limit must be lower than your session fee';
        }

        if (!values.top_specialties || values.top_specialties.length < 1) {
          errors.top_specialties = 'Please select at least one top specialty.';
        }

        if (values.top_specialties && values.top_specialties.length > 6) {
          errors.top_specialties = `Please choose no more than 6 top specialities. You have selected ${values.top_specialties.length}.`;
        }

        if (
          !values.specialties ||
          values.specialties.filter(
            (x: string) => !values.top_specialties || !values.top_specialties.includes(x)
          ).length < 1
        ) {
          errors.specialties = 'Please select at least one additional area of expertise.';
        }

        // Extra check to whether values.top_specialties includes it is required since if someone
        // adds as regular specialty then removes, we need to ignore it in max number
        if (
          values.specialties &&
          values.specialties.filter(
            (x: string) => !values.top_specialties || !values.top_specialties.includes(x)
          ).length > 14
        ) {
          errors.specialties = `Please choose no more than 14 additional areas of expertise. You have selected ${values.specialties.length}.`;
        }

        if (values.approaches && values.approaches.length > 20) {
          errors.approaches = `Please choose no more than 20 treatment approaches. You have selected ${values.approaches.length}.`;
        }

        requiredFields.forEach((f) => {
          if (!values[f] || !values[f].length) {
            errors[f as keyof Errors] = `Please select one or more ${f}.`;
          }
        });

        return errors;
      }}
      render={(props) => (
        <div key='info-form'>
          <div className='box m-b-md p-b-xs'>
            <h2 className='m-b-0'>Your practice</h2>
            <div className='flex row justify-between m-b-sm wrap tab-container'>
              <div className='field w-49 m-b-md'>
                <h5 className='m-b-0'>Who do {youPhrase} see in your practice?*</h5>
                <p className='description'>Select any that apply.</p>
                <TypeAhead
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={clienteleData}
                  selected={props.values.clientele || []}
                  onSelect={props.form.mutators.setField.bind(null, 'clientele')}
                  placeholder='Select clientele'
                />
                <ErrorField name='clientele' className={undefined} metaOverride={undefined} />
              </div>

              <div className='field w-49 m-b-md'>
                <h5 className='m-b-0'>What languages do {youPhrase} speak?*</h5>
                <p className='description'>
                  Please indicate at least one language {youPhrase} offer sessions in.
                </p>
                <TypeAhead
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={languageData}
                  selected={props.values.languages || []}
                  onSelect={props.form.mutators.setField.bind(null, 'languages')}
                  placeholder='Select languages'
                />
                <ErrorField name='languages' className={undefined} metaOverride={undefined} />
              </div>

              <div className='field w-49 m-b-md'>
                <h5 className='m-b-0'>What are {yourPhrase} top specialties?*</h5>
                <p className='description'>
                  Select up to 6 areas in which {youPhrase} have the most training and would
                  currently see clients. You can always update this later.
                </p>

                <TypeAhead
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={topSpecialtyData}
                  selected={props.values.top_specialties || []}
                  onSelect={(newValue: string) => {
                    props.form.mutators.setField('top_specialties', newValue);
                    if (newValue && newValue.length > 6) {
                      setShowTopSpecialitiesValidation(true);
                    }
                  }}
                  placeholder='Select specialties'
                />
                <ErrorField
                  metaOverride={{
                    modified: showTopSpecialitiesValidation,
                    visited: showTopSpecialitiesValidation,
                  }}
                  name='top_specialties'
                  className={undefined}
                />
              </div>

              <div className='field w-49 m-b-md'>
                <h5 className='m-b-0'>What are {yourPhrase} additional areas of expertise?*</h5>
                <p className='description'>
                  Select up to 14 additional areas in which {youPhrase} have training and would
                  currently see clients. You can always update this later.
                </p>
                <TypeAhead
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={getSpecialtyData(props.values)}
                  selected={props.values.specialties || []}
                  onSelect={(newValue: string) => {
                    props.form.mutators.setField('specialties', newValue);
                    if (newValue && newValue.length > 14) {
                      setShowSpecialitiesValidation(true);
                    }
                  }}
                  placeholder='Select specialties'
                />
                <ErrorField
                  metaOverride={{
                    modified: showSpecialitiesValidation,
                    visited: showSpecialitiesValidation,
                  }}
                  name='specialties'
                  className={undefined}
                />
              </div>

              <div className='field w-49 m-b-md'>
                <h5 className='m-b-0'>What treatment approaches do {youPhrase} use?*</h5>
                <p className='description' style={{ minHeight: '48px' }}>
                  Select the therapy approaches {youPhrase} have training in and use in your
                  practice. You can always update this later.
                </p>
                <TypeAhead
                  field='name'
                  selectSingle={false}
                  criteria={() => true}
                  items={approachData}
                  selected={props.values.approaches || []}
                  onSelect={(newValue: string) => {
                    props.form.mutators.setField('approaches', newValue);
                    if (newValue && newValue.length > 20) {
                      setShowApproachesValidation(true);
                    }
                  }}
                  placeholder='Select approaches'
                />
                <ErrorField
                  metaOverride={{
                    modified: showApproachesValidation,
                    visited: showApproachesValidation,
                  }}
                  name='approaches'
                  className={undefined}
                />
              </div>

              <p className='instruction'>
                Don't see your speciality or approach listed? You can add custom specialties and
                approaches later!
              </p>
            </div>
          </div>

          <div className='box m-b-md'>
            <h2 className='m-b-0'>Your Session Fees</h2>
            <div className='justify-between m-b-sm tab-container'>
              <>
                <div className='sliding-scale flex align-center justify-between m-b-sm'>
                  <h5>Do you have openings for sliding scale spots?</h5>
                  <Field name={'display_sliding_scale_slots'} type='checkbox'>
                    {(props) => (
                      <div className='switch'>
                        <input
                          type='checkbox'
                          id='sliding-on'
                          defaultChecked={false}
                          onChange={props.input.onChange}
                        />
                        <label className='md' htmlFor='sliding-on'>
                          <span className='toggle'></span>
                        </label>
                      </div>
                    )}
                  </Field>
                </div>

                <div className='in-network-switch flex align-center justify-between m-b-sm'>
                  <h5>Are you in-network with any health insurances?</h5>
                  <Field name={'takes_insurance'} type='checkbox'>
                    {(props) => (
                      <div className='switch'>
                        <input
                          type='checkbox'
                          id='in-network-on'
                          defaultChecked={false}
                          onChange={props.input.onChange}
                        />
                        <label className='md' htmlFor='in-network-on'>
                          <span className='toggle'></span>
                        </label>
                      </div>
                    )}
                  </Field>
                </div>

                <div className='flex row justify-between m-b-sm wrap tab-container'>
                  <div className='w-49'>
                    <div
                      className={`sliding-scale align-center justify-between m-b-${
                        props.errors &&
                        (props.errors.session_fee_ongoing || props.errors.session_length_ongoing)
                          ? '0'
                          : 'md'
                      }`}
                    >
                      <div>
                        <h5>What are your individual session fees and length?*</h5>
                      </div>
                      <div className='flex row justify-between m-b-sm wrap'>
                        <div className='w-49'>
                          <p className='instruction'>Session Fee</p>
                          <div style={{ position: 'relative' }}>
                            <Field
                              name='session_fee_ongoing'
                              component='input'
                              type='number'
                            ></Field>
                            <span className='units'>$</span>
                          </div>
                        </div>

                        <div className='session-length w-49'>
                          <p className='instruction'>Session Length</p>
                          <div style={{ position: 'relative' }}>
                            <Field
                              name='session_length_ongoing'
                              component='input'
                              type='number'
                            ></Field>
                            <span className='units'>min</span>
                          </div>
                        </div>
                      </div>

                      <div className='flex row justify-between m-b-sm wrap'>
                        <div className='w-49'>
                          <ErrorField
                            name='session_fee_ongoing'
                            className={undefined}
                            metaOverride={undefined}
                          />
                        </div>

                        <div className='session-length w-49'>
                          <ErrorField
                            name='session_length_ongoing'
                            className={undefined}
                            metaOverride={undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {props.values.display_sliding_scale_slots && (
                    <div className='w-49'>
                      <div
                        className={`sliding-scale flex align-center justify-between m-b-${
                          props.errors && props.errors.session_fee_min ? '0' : 'md'
                        }`}
                      >
                        <h5>What is your sliding scale lowest limit?</h5>
                        <div style={{ position: 'relative' }}>
                          <Field
                            min='0'
                            name='session_fee_min'
                            component='input'
                            type='number'
                          ></Field>
                          <span className='units'>$</span>
                        </div>
                      </div>
                      <span
                        className='w-100'
                        style={{
                          display: 'block',
                          textAlign: 'right',
                        }}
                      >
                        <ErrorField
                          name='session_fee_min'
                          className={undefined}
                          metaOverride={undefined}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
          <div className='w-100 flex justify-center m-t-sm m-b-md'>
            You can edit any of these fields later before going live!
          </div>
          <div className='flex justify-center'>
            {/* Note: We need to pass provider id as prop 
            in so we can set it to id of one being created */}
            <StartMembershipButton providerId={providerId} />
            <SubmitButton
              buttonClass='lg primary pill'
              buttonText='Save + Next'
              {...props}
              submittable={true}
            />
          </div>
        </div>
      )}
    />
  );
};

export default YourPracticeTagsFees;
