/* eslint-disable max-lines-per-function */
import { getQueryAsObject } from '../../../utils/queryParams';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as paymentActionsSeatBased } from './sagaSlice';
import { Form } from 'react-final-form';
import _ from 'lodash';
import useStripeCardElement from '+/hooks/useStripeCardElement';
import { CardElement } from '@stripe/react-stripe-js';
import SubmitButton from '+/forms/SubmitButton';
import Loader from 'react-loader-spinner';
import wrapStripeElements from '#/seat-based/wrapStripeElements';
import constants, { ProductDescriptionType, Plan } from './constants';
import ProductDescriptionModal from './ProductDescriptionModal';
import { displayMoneyString } from '../../../utils/money';
import getAccountData from '../../../utils/getAccountData';
import SummaryBox from './SummaryBox';
import MembershipBox from './MembershipBox';
import AddOnsBox from './AddOnsBox';
import { ConvertToMonthlySagaParam, Discount, formValues, Invoice } from './types/types';
import CheckoutUpcomingInvoiceModal from './CheckoutUpcomingInvoiceModal';
import Onboarding from '~/layouts/Onboarding';

interface Props {
  provider: $TSFixMe;
  customer: $TSFixMe;
  subscribeToPlan: $TSFixMe;
  onlySetupOptions: typeof constants.setup_remote | null;
  setSucceeded: $TSFixMe;
  loading: boolean;
  checkCoupon: $TSFixMe;
  referrer: $TSFixMe;
  initialCoupon: string;
  showDoThisLater: boolean;
  doThisLaterClicked: $TSFixMe;
  onSubscribed: $TSFixMe;
  paymentStep: 'customize' | 'pay' | 'success';
  setPaymentStep: (step: 'customize' | 'pay' | 'success') => void;
  accounts: $TSFixMe; // temp variable
  addSeat: $TSFixMeFunction;
  upgrade?: boolean;
  upgradePlan: $TSFixMeFunction;
  addSeatPreview: $TSFixMeFunction;
  upgradeProductPreview: $TSFixMeFunction;
  reactivate?: boolean;
  convertingToMonthly?: boolean;
  convertToMonthly: $TSFixMeFunction;
  convertToMonthlyData: {
    stripeCustomerId: string;
    stripeSubscriptionId: string;
    legacyProviderId: number;
  };
  upgradeReimbursify: boolean;
  upgradeReimbursifyPreview: $TSFixMeFunction;
  upgradeToPaidReimbursify: $TSFixMeFunction;
}

/**
 * @description This component is the primary payment form as of October 2022.
 * This form can accept payment from customers looking to subscribe, add a seat, upgrade,
 * or reactivate a subscription. See /src/components/payment/seat-based/readme.md for an in-depth
 * look at the flags used to determine what options should be visible to users of this form and what
 * endpoint said users will be directed to on submission.
 */
const PaymentFormSeatBased = ({
  provider,
  customer = { sources: [] },
  subscribeToPlan,
  onlySetupOptions,
  setSucceeded,
  loading,
  checkCoupon,
  referrer,
  initialCoupon,
  showDoThisLater,
  doThisLaterClicked,
  onSubscribed,
  paymentStep,
  setPaymentStep,
  accounts,
  addSeat,
  upgrade,
  upgradePlan,
  addSeatPreview,
  upgradeProductPreview,
  reactivate,
  convertingToMonthly,
  convertToMonthly,
  convertToMonthlyData,
  upgradeReimbursify,
  upgradeReimbursifyPreview,
  upgradeToPaidReimbursify,
}: Props) => {
  const { stripe, elements, cardElRef, cardComplete, cardError } = useStripeCardElement();
  const couponCodeIfReferred = 'ReferralWelcome2021';
  // const formApi = useForm();

  // Note: 'initialCoupon || referrer' should likley just be 'null' on next line.
  const [coupon, setCoupon] = useState<CouponType | null>(initialCoupon || referrer);
  const [submitError, setSubmitError] = useState(null);
  const [couponError, setCouponError] = useState<string | null>(null);
  const [showSetupOptionModal, setShowSetupOptionModal] = useState(false);
  const [currentModalSetupOption, setCurrentModalSetupOption] =
    useState<ProductDescriptionType | null>(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
  const [invoiceDTO, setInvoiceDTO] = useState<Invoice | null>(null);
  const [showZpmOptions, setShowZpmOptions] = useState(upgrade && !convertingToMonthly);
  const [showReimbursifyOptions, setShowReimbursifyOptions] = useState(
    upgradeReimbursify && !convertingToMonthly
  );
  const {
    seat_based,
    has_practice_management,
    tier,
    seat_based_stripe_customer_id,
    seat_based_stripe_subscription_id,
    seat_based_stripe_subscription_discount,
    seat_based_stripe_subscription_seats,
    legacy_subscription_count,
    sole_legacy_stripe_customer_id,
    sole_legacy_stripe_subscription_id,
    sole_legacy_stripe_subscription_is_yearly,
    sole_legacy_stripe_subscription_discount,
    has_paid_reimbursify_plan,
    reimbursify_tier,
  } = getAccountData(provider, accounts, customer);

  // If any location is NY or CA, show new york pricing.
  const isNy: boolean = provider
    ? provider.locations.some((l: $TSFixMe) =>
        ['NY', 'CA', 'New York', 'California'].includes(l.state)
      )
    : false;

  // ---
  // BEGIN PARSING SCENARIO
  // ---
  // Flags determined below are set based on props and account level properties.
  // These flag are used to control UI elements as well as determine what payment
  // endpoint should be used.
  // ---
  // See /src/components/payment/seat-based/readme.md to gain a better understanding of this process
  // ---

  // Adding or upgrading ZPM -- Scenario #5, #7, or #8
  let addingPracticeManagement = upgrade && !has_practice_management;
  const upgradingPracticeManagement = upgrade && has_practice_management;
  let showReimbursifyOption = true;

  // Hides payment details input and uses existing payment information -- Scenario #6b or #3 or #12
  let hidePayment =
    !!seat_based_stripe_subscription_id &&
    (legacy_subscription_count > 0 || legacy_subscription_count === 0);

  // Hides options to select ZPM as an add on -- Scenario #2, #3, #4, or #6
  let hidePracticeManagementOption =
    legacy_subscription_count > 0 || has_practice_management || (hidePayment && !upgrade);

  // Hides entire add ons container -- Scenario #5, #8, or #9
  let hideAddOns =
    (addingPracticeManagement && hidePracticeManagementOption) ||
    upgradingPracticeManagement ||
    reactivate;

  // Single legacy provider with no seat-based providers accesses "Upgrade" flow -- Scenario #7
  let soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy = false;
  const upgradingFromLegacy = upgrade && legacy_subscription_count === 1 && !seat_based;
  if (upgradingFromLegacy) {
    addingPracticeManagement = true;
    hidePracticeManagementOption = false;
    hideAddOns = false;
    hidePayment = true;
    if (sole_legacy_stripe_subscription_is_yearly) {
      soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy = true;
    }
    showReimbursifyOption = false;
  }

  // Ensure that Reimbursify option only displays during onboarding (Parsing this Elijah style, so much nicer)
  if (
    upgrade ||
    convertingToMonthly ||
    (hidePayment && !upgradeReimbursify) ||
    legacy_subscription_count > 0 ||
    reactivate
  ) {
    showReimbursifyOption = false;
  }
  // Upgrading reimbursify -- Scenario #12 (Parsing this Elijah style, so much nicer)
  if (upgradeReimbursify) {
    addingPracticeManagement = false;
    hidePracticeManagementOption = true;
    hideAddOns = false;
    hidePayment = true;
    soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy = false;
    showReimbursifyOption = true;

    // Prevent the selection of the same tier by hiding addons
    // Note: if we had a third tier, this will need tweaking to only hide at the highest tier
    if (has_paid_reimbursify_plan) {
      hideAddOns = true;
    }
  }

  // ---
  // END PARSING SCENARIO
  // ---

  let defaultCoupon = referrer ? couponCodeIfReferred : '';
  if (initialCoupon) {
    defaultCoupon = initialCoupon;
  }

  // Overriding all other possible initial coupons is a coupon that is already attached to a subscription
  let defaultCouponSetAndNotRemovable = false;
  let defaultCouponDiscountNotRemovable: Discount | null = null;

  if (
    upgradingFromLegacy &&
    sole_legacy_stripe_subscription_discount &&
    sole_legacy_stripe_subscription_discount.coupon
  ) {
    defaultCouponDiscountNotRemovable = sole_legacy_stripe_subscription_discount;
    defaultCoupon = sole_legacy_stripe_subscription_discount.coupon.id;
    defaultCouponSetAndNotRemovable = true;
  }

  // If an account has both a legacy subscription and a seat-based one,
  // we'll always need the seat-based discount to win, so assign it second.
  if (seat_based_stripe_subscription_discount && seat_based_stripe_subscription_discount.coupon) {
    defaultCouponDiscountNotRemovable = seat_based_stripe_subscription_discount;
    defaultCoupon = seat_based_stripe_subscription_discount.coupon.id;
    defaultCouponSetAndNotRemovable = true;
  }

  if (convertingToMonthly) {
    // show ZPM options
    // hidePayment
    addingPracticeManagement = true;
    hidePracticeManagementOption = false;
    hideAddOns = false;
    hidePayment = true;
    soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy = true;
    showReimbursifyOption = false;
  }

  // Set payment form to initial step on reload
  useEffect(() => {
    setPaymentStep('customize');
  }, [setPaymentStep]);

  // If there's a default coupon, automatically check the coupon and apply.
  useEffect(() => {
    const payload = {
      onError: (e: $TSFixMe) => {
        setCouponError(e);
        setCoupon(null);
      },
      onSuccess: (data: CouponType) => {
        setCouponError(null);
        setCoupon(data);
      },
    };

    if (initialCoupon) {
      // @ts-ignore: $TSFixMe
      payload.coupon = initialCoupon;
      checkCoupon(payload);
    } else if (referrer) {
      // @ts-ignore: $TSFixMe
      payload.coupon = couponCodeIfReferred;
      checkCoupon(payload);
    } else if (defaultCouponSetAndNotRemovable) {
      // @ts-ignore: $TSFixMe
      payload.coupon = defaultCoupon;
      checkCoupon(payload);
    }
  }, [checkCoupon, defaultCoupon, defaultCouponSetAndNotRemovable, initialCoupon, referrer]);

  const NEW_SOURCE_SENTINEL = 'newsource';

  const getPlan = (values: formValues): Plan => {
    const { practice_management } = values;
    // @ts-ignore
    return constants[
      `subscriptions_individual_monthly${practice_management ? `_${practice_management}` : ''}`
    ][isNy ? 'ny' : 'standard'];
  };
  const onSubmit = (values: formValues) => {
    if (
      !hideAddOns &&
      !hidePracticeManagementOption &&
      !showZpmOptions &&
      upgrade &&
      !convertingToMonthly
    ) {
      setShowZpmOptions(true);
      alert('Please select a Zencare Practice Management option.');
      return;
    }
    if (paymentStep === 'customize') {
      getInvoicePreviewData(values, undefined);
      return setPaymentStep('pay');
    }
    if (!stripe || !elements || loading) {
      return;
    }

    setSubmitError(null);

    const { lookup_key, setup_lookup_key, add_on_lookup_key } = getProductsFromValues(values);

    if (convertingToMonthly) {
      const { stripeCustomerId, stripeSubscriptionId, legacyProviderId } = convertToMonthlyData;
      const sagaParam: ConvertToMonthlySagaParam = {
        params: {
          stripe_customer_id: stripeCustomerId,
          stripe_subscription_id: stripeSubscriptionId,
        },
        apiPayload: {
          new_tier: values.practice_management || 'basic',
          coupon: _.get(coupon, 'id'),
          legacy_is_ny_or_ca: isNy,
          legacy_provider_id: legacyProviderId,
        },
        onSuccess: () => {
          setPaymentStep('customize');
          onSubscribed();
        },
        onError: (e: $TSFixMe) => setSubmitError(e),
      };
      return convertToMonthly(sagaParam);
    }

    if (upgrade) {
      const upgradePlanPayload = {
        new_tier: values.practice_management,
        is_legacy: legacy_subscription_count > 0,
        legacy_is_ny_or_ca: legacy_subscription_count > 0 && isNy,
        legacy_provider_id: null,
        coupon: _.get(coupon, 'id'),
        stripe_customer_id: seat_based_stripe_customer_id || sole_legacy_stripe_customer_id,
        stripe_subscription_id:
          seat_based_stripe_subscription_id || sole_legacy_stripe_subscription_id,
        onError: (e: $TSFixMe) => setSubmitError(e),
        onSuccess: () => {
          setPaymentStep('customize');
          onSubscribed();
        },
      };

      if (upgradePlanPayload.is_legacy) {
        upgradePlanPayload.legacy_provider_id = provider.id;
      }

      return upgradePlan(upgradePlanPayload);
    }

    if (upgradeReimbursify) {
      const upgradeReimbursifyPayload = {
        coupon: _.get(coupon, 'id'),
        new_tier: values.reimbursify_tier,
        stripe_customer_id: seat_based_stripe_customer_id || sole_legacy_stripe_customer_id,
        stripe_subscription_id:
          seat_based_stripe_subscription_id || sole_legacy_stripe_subscription_id,
        onError: (e: $TSFixMe) => setSubmitError(e),
        onSuccess: () => {
          setPaymentStep('customize');
          onSubscribed();
        },
      };

      return upgradeToPaidReimbursify(upgradeReimbursifyPayload);
    }

    if (hidePayment) {
      return addSeat({
        provider_id: provider.id,
        stripe_customer_id: seat_based_stripe_customer_id,
        stripe_subscription_id: seat_based_stripe_subscription_id,
        lookup_key: lookup_key,
        setup_lookup_key: setup_lookup_key || undefined,
        add_on_lookup_key,
        coupon: _.get(coupon, 'id'),
        // previewed_subscription_proration_date: undefined,
        onError: (e: $TSFixMe) => setSubmitError(e),
        onSuccess: () => {
          setPaymentStep('customize');
          onSubscribed();
        },
      });
    }

    // @ts-ignore
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    let source: $TSFixMe;
    let sourcePromise;

    // If we're adding a new card, use stripe elements to create it,
    // otherwise select existing source
    if (values.source === NEW_SOURCE_SENTINEL) {
      if (!cardComplete) {
        return;
      }

      sourcePromise = () =>
        // @ts-ignore
        stripe.createSource(cardElement, {
          type: 'card',
          currency: 'usd',
          owner: {
            name: _.get(source, 'name'),
          },
        });
    } else {
      source = customer.sources.find((s: $TSFixMe) => s.id === values.source);
    }

    return subscribeToPlan({
      sourcePromise,
      source,
      provider_id: provider.id,
      onError: (e: $TSFixMe) => setSubmitError(e),
      onSuccess: () => {
        setPaymentStep('customize');
        onSubscribed();
      },
      coupon: _.get(coupon, 'id'),
      lookup_key: lookup_key,
      setup_lookup_key: setup_lookup_key,
      add_on_lookup_key: add_on_lookup_key,
      is_reactivation: reactivate,
      reimbursify_tier: values.reimbursify_tier || null,
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      address_city: values.address_city,
      address_state: values.address_state,
      address_postal_code: values.address_postal_code,
    });
  };

  const getSetupPriceNoAddons = (values: formValues) => {
    const setupOptionAddOnCost = onlySetupOptions ? onlySetupOptions[0].price : 0;

    return setupOptionAddOnCost;
  };

  const getInitialSetupOptionLookupKey = () => {
    if (!provider || upgrade || reactivate || upgradeReimbursify) {
      return null;
    }

    return onlySetupOptions ? onlySetupOptions[0].lookup_key : null;
  };

  const getInitialPracticeManagementOption = () =>
    has_practice_management
      ? upgrade && !convertingToMonthly
        ? 'premium'
        : tier
      : upgrade && !convertingToMonthly
      ? 'professional'
      : '';

  const getInitialReimbursifyOption = () => {
    if (upgradeReimbursify) {
      if (has_paid_reimbursify_plan) {
        return 'premium';
      } else {
        return 'professional';
      }
    }
    return reimbursify_tier;
  };

  const getProductsFromValues = (values: formValues) => {
    const {
      setup_option_lookup_key,
      professional_copywriting,
      reimbursify_selected,
      reimbursify_tier,
    } = values;

    const plan = getPlan(values);
    const lookup_key = plan.lookup_key;
    const setup_lookup_key = setup_option_lookup_key;
    const add_on_lookup_key = professional_copywriting && professional_copywriting[0];

    const reimbursify_key =
      reimbursify_selected && reimbursify_tier
        ? constants[`reimbursify_${reimbursify_tier}`].lookup_key
        : null;

    return {
      lookup_key,
      setup_lookup_key,
      add_on_lookup_key,
      reimbursify_key,
    };
  };

  const getInvoicePreviewData = (
    values: formValues,
    latestCouponValueOverride: CouponType | null | undefined
  ) => {
    // Note: We need to accept latestCouponValue due to async nature of setCoupon
    // we need to make sure we use the latest value for network request to Stripe.
    // We do want to use null for coupon if latestCouponValueOverride is null.
    // Only if latestCouponValueOverride is undefined do we want to use coupon
    const latestCouponValue =
      latestCouponValueOverride === undefined ? coupon : latestCouponValueOverride;

    // If not a upgrading, or if upgrading without having selected a ZPM option,
    if (
      (!upgrade && !seat_based) ||
      (upgrade && !values.practice_management && !convertingToMonthly)
    ) {
      return;
    }
    const { lookup_key, setup_lookup_key, add_on_lookup_key, reimbursify_key } =
      getProductsFromValues(values);
    const params = {
      stripe_customer_id: seat_based_stripe_customer_id || sole_legacy_stripe_customer_id,
      stripe_subscription_id:
        seat_based_stripe_subscription_id || sole_legacy_stripe_subscription_id,
    };
    const onSuccess = (data: $TSFixMe) => {
      setInvoiceDTO(data.preview);
    };

    const onError = (error: Error) => {
      alert(error.message);
      throw new Error(
        `Error getting upcoming invoice for stripe_customer_id ${seat_based_stripe_customer_id} and stripe_subscription_id ${seat_based_stripe_subscription_id}: ${error}`
      );
    };

    if (upgrade) {
      if (!values.practice_management && !convertingToMonthly) {
        alert('Please select a Practice Management option in order to preview your invoice.');
        return;
      }
      return upgradeProductPreview({
        params,
        apiPayload: {
          // new_tier: tier === 'basic' ? 'professional' : 'premium',
          new_tier: values.practice_management || 'basic',
          is_legacy: !seat_based,
          coupon: _.get(latestCouponValue, 'id'),
          legacy_is_ny_or_ca: !seat_based && isNy,
        },
        onSuccess,
        onError,
      });
    }

    if (upgradeReimbursify) {
      if (!values.reimbursify_tier) {
        // Note: Once "ok" is clicked this will automatically reselect Reimbursify then
        // take the user to the payment step. It's not pretty, but the solution would be to
        // pass setPaymentStep in as a callback, change this "alert" to a "confirm", then only
        // setPaymentStep to 'pay' if 'ok' is clicked... which, while a better user experience,
        // might make this code even more fo a mess. Holding off for now as it's an edge case.
        alert('Please select a Private Pay Booster option in order to preview your invoice.');
        return;
      }
      return upgradeReimbursifyPreview({
        params,
        apiPayload: {
          coupon: _.get(latestCouponValue, 'id'),
          new_tier: values.reimbursify_tier,
        },
        onSuccess,
        onError,
      });
    }

    return addSeatPreview({
      params,
      apiPayload: {
        lookup_key,
        setup_lookup_key: setup_lookup_key || undefined,
        add_on_lookup_key,
        coupon: _.get(latestCouponValue, 'id'),
      },
      onSuccess,
      onError,
    });
  };

  const getInvoiceParagraph = (invoice: Invoice) => {
    const { period_end, amount_due, subtotal } = invoice;
    const now = new Date();
    // const periodStart = new Date(period_start * 1000);
    const periodEnd = new Date(period_end * 1000);
    const nextPeriodStart = new Date(period_end * 1000);

    const periodStartString = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
    const periodEndString = `${
      periodEnd.getMonth() + 1
    }/${periodEnd.getDate()}/${periodEnd.getFullYear()}`;
    const nextPeriodStartString = `${
      nextPeriodStart.getMonth() + 1
    }/${nextPeriodStart.getDate()}/${nextPeriodStart.getFullYear()}`;

    if (soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy) {
      if (amount_due === 0) {
        return `Subscription starts on purchase. After today's payment, your account will have ${displayMoneyString(
          (subtotal * -1) / 100
        )} in credits, which will apply to future payments until used up. `;
      } else {
        return 'Subscription starts on purchase. ';
      }
    }

    return `Subscription starts on purchase. You will be automatically charged for your
    subscription on
      ${nextPeriodStartString}
    until you cancel. The next billing cycle will include a prorated charge for
    this new subscription’s partial month (${periodStartString} -
    ${periodEndString}) of ${displayMoneyString(amount_due / 100)}. `;
  };

  const urlParams = getQueryAsObject(window.location.search);
  const priorSubscriptionWasIncompleteExpired = urlParams.prior_subscription_was_incomplete_expired
    ? parseInt(urlParams.prior_subscription_was_incomplete_expired) === 1
    : false;

  return (
    <>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize={true}
        initialValues={{
          setup_option_lookup_key: getInitialSetupOptionLookupKey(),
          source:
            !priorSubscriptionWasIncompleteExpired &&
            customer &&
            customer.sources &&
            customer.sources.length
              ? undefined
              : NEW_SOURCE_SENTINEL,
          plan_type: process.env.ZENCARE_DISABLE_MONTHLY_PAY_SIGNUPS === '1' ? 'annual' : 'monthly',
          // Values initially unset
          practice_management_selected:
            (upgrade && !convertingToMonthly) || has_practice_management || false,
          practice_management: getInitialPracticeManagementOption(),
          reimbursify_selected: upgradeReimbursify || has_paid_reimbursify_plan,
          reimbursify_tier: getInitialReimbursifyOption(),

          address_city: '',
          address_line1: '',
          address_line2: '',
          address_postal_code: '',
          address_state: '',
          use_physical_location: false,
        }}
        validate={(values) => {
          const errors: $TSFixMe = {};

          if (!hidePayment) {
            if (!values.source) {
              errors.source = 'Payment method required.';
            }
            if (!values.address_line1) {
              errors.address_line1 = 'Address line 1 required.';
            }

            if (!values.address_city) {
              errors.address_city = 'City required.';
            }

            if (!values.address_state) {
              errors.address_state = 'State required.';
            }

            if (!values.address_postal_code) {
              errors.address_postal_code = 'Postal code required.';
            }
          }

          if (!values['terms-of-service']) {
            errors['terms-of-service'] = 'Please accept our terms of service.';
          }
          return errors;
        }}
        render={({ handleSubmit, pristine, valid, values, form }) => (
          <form
            onSubmit={handleSubmit}
            className={`payment-form ${paymentStep === 'pay' ? 'pay' : ''}`}
          >
            <div className={`payment-form-options ${paymentStep === 'pay' ? 'hide' : ''}`}>
              <div className='payment-form-your-plan'>
                <h2>Review Your Plan</h2>
                <MembershipBox
                  setShowSetupOptionModal={setShowSetupOptionModal}
                  setCurrentModalSetupOption={setCurrentModalSetupOption}
                  // membershipItems={onlySetupOptions}
                  lookupKeys={Object.values(getProductsFromValues(values))}
                  tier={tier}
                  upgradingPracticeManagement={upgradingPracticeManagement}
                  reimbursifyTier={reimbursify_tier}
                  upgradeReimbursify={upgradeReimbursify}
                />
              </div>
              {!hideAddOns && (
                <div className='payment-form-add-ons'>
                  <h2>Select your add-ons</h2>
                  <AddOnsBox
                    setShowSetupOptionModal={setShowSetupOptionModal}
                    setCurrentModalSetupOption={setCurrentModalSetupOption}
                    form={form}
                    hidePracticeManagementOption={hidePracticeManagementOption}
                    addingPracticeManagement={addingPracticeManagement}
                    upgrade={upgrade}
                    showZpmOptions={showZpmOptions}
                    setShowZpmOptions={setShowZpmOptions}
                    showReimbursifyOption={showReimbursifyOption}
                    showReimbursifyOptions={showReimbursifyOptions}
                    setShowReimbursifyOptions={setShowReimbursifyOptions}
                    showCopywritingOption={
                      !addingPracticeManagement &&
                      !upgradeReimbursify &&
                      !upgradingPracticeManagement
                    }
                  />
                </div>
              )}
            </div>
            <SummaryBox
              getProductsFromValues={getProductsFromValues}
              values={values}
              customer={customer}
              paymentStep={paymentStep}
              coupon={coupon}
              setCoupon={setCoupon}
              setCouponError={setCouponError}
              seats={seat_based_stripe_subscription_seats}
              isNy={isNy}
              upgrade={upgrade}
              hidePayment={hidePayment}
              upgradingFromLegacy={upgradingFromLegacy}
              convertingToMonthly={convertingToMonthly}
              upgradeReimbursify={upgradeReimbursify}
              soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy={
                soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy
              }
              invoiceDTO={invoiceDTO}
              defaultCoupon={defaultCoupon}
              defaultCouponSetAndNotRemovable={defaultCouponSetAndNotRemovable}
              defaultCouponDiscountNotRemovable={defaultCouponDiscountNotRemovable}
              couponError={couponError}
              cardElRef={cardElRef}
              getSetupPriceNoAddons={getSetupPriceNoAddons}
              getInvoicePreviewData={getInvoicePreviewData}
              NEW_SOURCE_SENTINEL={NEW_SOURCE_SENTINEL}
              priorSubscriptionWasIncompleteExpired={priorSubscriptionWasIncompleteExpired}
              cardError={cardError}
              checkCoupon={checkCoupon}
            >
              <>
                {paymentStep === 'pay' && invoiceDTO && (
                  <div className='payment-form-price-notice appear'>
                    <i className='fal fa-lightbulb'></i>
                    <div>
                      <p>
                        {getInvoiceParagraph(invoiceDTO)}
                        <button
                          type='button'
                          className='payment-form-preview-button link'
                          onClick={() => setShowInvoiceModal(!showInvoiceModal)}
                        >
                          {soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy
                            ? "View Today's Invoice"
                            : 'Preview Upcoming Invoice'}
                        </button>
                        . Questions?
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://members.zencare.co/contact'
                        >
                          Let us know!
                        </a>
                      </p>
                    </div>
                  </div>
                )}
                <div className='payment-form-button-container'>
                  <SubmitButton
                    pristine={false}
                    buttonClass='w-100 primary button lg  payment-form-summary-button'
                    handleSubmit={(e: Event) => {
                      e.preventDefault();
                      return onSubmit(values);
                    }}
                    valid={
                      valid &&
                      (values.source !== NEW_SOURCE_SENTINEL ||
                        (!cardError && cardComplete) ||
                        hidePayment)
                    }
                    submittable={paymentStep === 'customize' || valid}
                    buttonText=''
                    children={
                      loading ? (
                        <Loader type='Oval' color='#ffffff' height={20} width={20} />
                      ) : paymentStep === 'customize' ? (
                        <>
                          <span>Next: Payment</span>
                          <i className='far fa-arrow-right'></i>
                        </>
                      ) : (
                        <span>Invest in my practice</span>
                      )
                    }
                  />
                  {paymentStep === 'pay' && (
                    <button
                      className='w-100 primary button hollow m-t-sm lg payment-form-summary-button'
                      onClick={() => setPaymentStep('customize')}
                    >
                      Back
                    </button>
                  )}
                  {showDoThisLater && (
                    <div className='column'>
                      <button className='do-this-later-link' onClick={doThisLaterClicked}>
                        Do this later
                      </button>
                    </div>
                  )}
                </div>
                {/* @ts-ignore: $TSFixMe */}
                {submitError && <span className='text-error'>{submitError.message}</span>}
              </>
            </SummaryBox>
          </form>
        )}
      />
      <ProductDescriptionModal
        showModal={showSetupOptionModal}
        setShowModal={setShowSetupOptionModal}
        option={currentModalSetupOption}
      />
      <CheckoutUpcomingInvoiceModal
        soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy={
          soleLegacyStripeSubscriptionIsYearlyAndUpgradingFromLegacy
        }
        dto={invoiceDTO}
        showModal={showInvoiceModal}
        setShowModal={setShowInvoiceModal}
      />
    </>
  );
};

export default connect(
  ({ main, paymentSeatBased }: $TSFixMe, ownProps: $TSFixMe): $TSFixMe => ({
    referrer: _.get(main, 'unusedReferrals[0].referring_user_id'),
    loading: paymentSeatBased.isLoading,
    paymentStep: paymentSeatBased.paymentStep,
    convertToMonthlyData: paymentSeatBased.convertToMonthlyData,
    accounts: main.accounts,
  }),
  {
    subscribeToPlan: paymentActionsSeatBased.subscribeToPlan,
    addSeat: paymentActionsSeatBased.addSeat,
    upgradePlan: paymentActionsSeatBased.upgradePlan,
    checkCoupon: paymentActionsSeatBased.checkCoupon,
    setPaymentStep: paymentActionsSeatBased.setPaymentStep,
    addSeatPreview: paymentActionsSeatBased.addSeatPreview,
    upgradeProductPreview: paymentActionsSeatBased.upgradeProductPreview,
    convertToMonthly: paymentActionsSeatBased.convertToMonthly,
    upgradeReimbursifyPreview: paymentActionsSeatBased.upgradeReimbursifyPreview,
    upgradeToPaidReimbursify: paymentActionsSeatBased.upgradeToPaidReimbursify,
  }
)(wrapStripeElements(PaymentFormSeatBased));
