// Note: potential for SnowPlow tracking if we utilize SnowPlow in Therapist Portal
// Note: future work will be done to this to extend the "account" param to accept users and providers as well
type Type = 'account' | 'user' | 'provider';

type SubjectType<T> = T extends 'account'
  ? Account
  : T extends 'provider'
  ? Provider
  : T extends 'user'
  ? User
  : never;

const hasFeatureFlag = <T extends Type>(
  type: T,
  subject: SubjectType<T>,
  feature: string
): boolean => {
  let hasFeatureFlag = false;

  if (!subject) return hasFeatureFlag;

  if (type === 'account') {
    if ('feature_flags' in subject) {
      hasFeatureFlag = subject.feature_flags
        ?.filter((f) => f.active)
        .map((f) => f.reserved_name)
        .includes(feature);
    }
  }
  return hasFeatureFlag;
};

export default hasFeatureFlag;
