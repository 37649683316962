import React from 'react';

function HelpdeskLinks({ mobile }: { mobile: boolean }) {
  return (
    <div className={`messages-announcement-wrapper ${mobile ? 'mobile' : ''}`}>
      {mobile && <hr />}
      <div
        className={`announcement messages-announcement ${
          mobile ? 'messages-announcement-mobile' : ''
        }`}
      >
        <h4>About Messaging</h4>
        <p className='m-b-xs'>
          Secure messaging is the primary mode of communication between clients and providers on
          Zencare. Message any client who has contacted you via Zencare below!
        </p>
        <ul>
          <li>
            <a
              href='https://support.zencare.co/help/why-should-i-use-messaging-to-contact-a-client'
              target='_blank'
              rel='noopener noreferrer'
            >
              Why should I use messaging to contact a client?
            </a>
          </li>
          <li>
            <a
              href='https://support.zencare.co/help/how-do-i-message-a-client-via-zencare'
              target='_blank'
              rel='noopener noreferrer'
            >
              How do I message a client?
            </a>
          </li>
          <li>
            <a
              href='https://support.zencare.co/help/how-to-know-client-message'
              target='_blank'
              rel='noopener noreferrer'
            >
              How will I know when I’ve received a message?
            </a>
          </li>
          <li>
            <a
              href='https://support.zencare.co/help/using-template-responses'
              target='_blank'
              rel='noopener noreferrer'
            >
              How do I use the templated responses?
            </a>
          </li>
          <li>
            <a
              href='https://support.zencare.co/help/messaging'
              target='_blank'
              rel='noopener noreferrer'
            >
              More FAQS
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HelpdeskLinks;
