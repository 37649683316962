import React from 'react';

export default ({ link, img, description, title, time }) => (
  <div className='post pointer'>
    <a href={link} target='_blank'>
      <aside className='image text-center'>
        <img
          alt={title}
          title={title}
          src={`${img}?w=800&amp;w=600&amp;fit=crop&amp;crop=faces&amp;q=50`}
          srcSet={`
                        ${img}?w=320&amp;h=240&amp;fit=crop&amp;crop=faces 320w,
                        ${img}?w=600&amp;h=450&amp;fit=crop&amp;crop=faces 600w,
                        ${img}?w=800&amp;h=600&amp;fit=crop&amp;crop=faces 800w,
                        ${img}?w=1000&amp;h=750&amp;fit=crop&amp;crop=faces 1000w
                    `}
          size='
                        (max-width: 320px) 320px,
                        (max-width: 600px) 600px,
                        (max-width: 800px) 800px,
                        1000px
                    '
        ></img>
      </aside>

      <div className='card-content'>
        <article className='card-info'>
          <h4 className='title'>{title}</h4>
          <p className='m-b-0' style={{ color: '#222' }}>
            {description}
          </p>
        </article>
        <footer className='m-t-md'>
          <div className='reading-time' style={{ color: '#222' }}>
            {time} min read
          </div>
        </footer>
      </div>
    </a>
  </div>
);
