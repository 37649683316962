/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { actions } from './sagaSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import autosize from 'autosize';
import HelpDialog from '../provider/appointments/messages/HelpDialog';

interface Props {
  recipientName: string;
  recipientPhone: string;
  providerName: string;
  clientIsRedacted: boolean;
}

interface Params {
  clientId: string;
  providerId: string;
}

const MessageForm = ({
  recipientName,
  recipientPhone,
  providerName,
  clientIsRedacted,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const textAreaInput = useRef<HTMLTextAreaElement>(null);
  const { clientId, providerId } = useParams<Params>();
  const {
    id: provider_id,
    status,
    account_id,
  } = useSelector((state: $TSFixMe) => state.main.provider);
  const { messageSendSuccess, messageSendError } = useSelector(
    (state: $TSFixMe) => state.practiceInbox
  );

  const [buttonText, setButtonText] = useState(``);
  const [showDialog, setShowDialog] = useState(true);

  // Send button succuss UX
  useEffect(() => {
    if (messageSendSuccess) {
      setButtonText('Message sent!');
      setTimeout(() => {
        setButtonText('Send');
      }, 1500);
      return;
    }
    return;
  }, [messageSendSuccess]);

  //
  useEffect(() => {
    setButtonText('Send');
  }, [providerName]);

  const sendMessage = (values: $TSFixMe) => {
    const { message } = values;
    dispatch(
      // @ts-expect-error: haven't typed actions yet
      actions.sendMessage({
        provider_id: providerId,
        client_user_id: clientId,
        message,
        account_id,
      })
    );
    setShowDialog(false);
  };

  const handleKeyDown = (props: $TSFixMe) => (e: KeyboardEvent) => {
    const { handleSubmit } = props;
    if (textAreaInput.current) {
      autosize(textAreaInput.current);
    }

    // send on 'meta' and 'enter'
    if (e.key === 'Enter' && e.metaKey) {
      handleSubmit();
      if (textAreaInput.current) {
        autosize(textAreaInput.current);
      }
      return;
    }
  };

  if (status !== 'active') {
    return (
      <div className='appointment-message-form message-form-inactive'>
        <h6>
          <i className='far fa-info-circle'></i>
          Your profile is currently inactive. Please reactivate your profile to use Zencare's
          messaging tool! In the meantime, please reach the client via email or phone.
        </h6>
      </div>
    );
  }

  if (clientIsRedacted) {
    return (
      <div className='appointment-message-form message-form-inactive'>
        <h6>
          <i className='far fa-info-circle'></i>
          This client deleted all of their personal data and messages from Zencare! We kept this
          record as a reminder that the referral was received, but any content and personal
          information is no longer accessible. Please reach out to support@zencare.co if you have
          any questions.
        </h6>
      </div>
    );
  }

  return (
    <div className='appointment-message-form'>
      <Form
        initialValues={{}}
        onSubmit={(values, form) => {
          sendMessage(values);
          form.mutators.clearMessage();
        }}
        validate={(values) => {
          const errors: { message?: string } = {};

          if (!values.message) {
            errors.message = 'Message is required';
          }

          return errors;
        }}
        mutators={{
          clearMessage: (args, state, utils) => {
            utils.changeValue(state, 'message', () => '');
            setTimeout(() => {
              if (textAreaInput.current) {
                autosize(textAreaInput.current);
                autosize.update(textAreaInput.current);
              }
            }, 0);
          },
        }}
        render={(formProps) => (
          <>
            <HelpDialog shouldShow={showDialog}>
              Remember that you are responding on behalf of {providerName}
            </HelpDialog>
            <div className='form-status-box'>
              <p className='text-error'>{messageSendError}</p>
            </div>
            <div className='form-wrapper form-wrapper-practice-inbox'>
              <div className='textarea-wrapper'>
                <Field
                  ref={textAreaInput}
                  className='message-textarea'
                  name='message'
                  placeholder={`Message ${recipientName}`}
                  component='textarea'
                  onKeyDown={handleKeyDown(formProps)}
                />
              </div>
              <button
                onClick={() => {
                  formProps.handleSubmit();
                }}
                className='send-message-button primary pill md'
              >
                {buttonText}
              </button>
              <button
                onClick={() => {
                  formProps.handleSubmit();
                }}
                className='send-message-button-mobile primary pill md'
              >
                <i className='far fa-paper-plane'></i>
              </button>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default MessageForm;
