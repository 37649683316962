import React from 'react';

export default ({ licenses, curYear }) => {
  const license = licenses.filter((c) => Boolean(c.supervisor_name))[0];
  if (!license) {
    return (
      <p className='instruction'>
        You have no supervisor licensure information saved. Click "Edit" to add your supervisor's
        license.
      </p>
    );
  }
  return licenses.length ? (
    <>
      <div className='supervisor-license-display'>
        <h5 className='m-y-xs'>Name</h5>
        <h5 className='m-y-xs'>Number</h5>
        <h5 className='m-y-xs'>Type</h5>
        <h6>{license.supervisor_name}</h6>
        <div>{license.supervisor_license}</div>
        <div>{license.supervisor_license_type}</div>
      </div>
    </>
  ) : (
    <p className='instruction'>
      You have no supervisor licenses saved. Click "Edit" to add your supervisor's licenses.
    </p>
  );
};
