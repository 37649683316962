import React, { useContext } from 'react';
import StripeContext from '../stripe';
import { Elements } from '@stripe/react-stripe-js';

export default (Component) => (props) => {
  const stripeContext = useContext(StripeContext);

  return (
    <Elements stripe={stripeContext}>
      <Component {...props} />
    </Elements>
  );
};
