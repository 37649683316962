import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions as mainActions } from '@/main/sagaSlice';
import Loader from '+/forms/SectionLoader';
import _ from 'lodash';
import storage from '#/storage';
import GoingLiveModal from './GoingLiveModal';
import PayAndGoLiveModal from './seat-based/PayAndGoLiveModal';
import WaitingOnOwnerToPayModal from './WaitingOnOwnerToPayModal';
import hasOwnerScope from '#/hasOwnerScope';
import providerHasSubscriptionInAccount from '#/providerHasSubscriptionInAccount';
import history from '#/history';
import Accordian from '~/shared/Accordian';

const GoLiveForm = ({
  onboarding,
  getMe,
  getAllAccountDetails,
  providerId,
  goLive,
  main,
  roles,
  selectActiveProvider,
}) => {
  const [exitProfileWizard, setExitProfileWizard] = useState(false);
  const [goingLiveModalOpen, setGoingLiveModalOpen] = useState(false);
  const [payAndGoLiveModalOpen, setPayAndGoLiveModalOpen] = useState(false);
  const [waitingOnOwnerToPayModalOpen, setWaitingOnOwnerToPayModalOpen] = useState(false);
  const [accordianOpen, setAccordianOpen] = useState(false);

  const routerParams = useParams();

  useEffect(() => {
    getMe();
    getAllAccountDetails();
  }, [getMe, getAllAccountDetails]);

  const accounts = _.get(main, 'accounts', []).filter((a) => !a.error);

  const provider =
    main.allProviders.find((p) => p.id === providerId) ||
    _.flatten(accounts.map((a) => a.providers)).find((p) => p.id === providerId) ||
    main.provider;

  if ((onboarding && onboarding.isLoading) || !provider) {
    return (
      <div key='info-form'>
        <div className='loader-container w-100 m-t-md flex align-center justify-center'>
          <Loader />
        </div>
      </div>
    );
  }

  if (exitProfileWizard) {
    return <Redirect to='/profile/general-information' />;
  }

  const iframeSource = `${process.env.ZENCARE_WEBSITE_URL}/profile-preview-by-slug/${provider.slug}`;

  const setOnboardingWizardExit = () => {
    storage.set(`exit-onboarding-wizard-provider-id-${provider.id}`, true);
    setExitProfileWizard(true);
  };

  const editProfileClicked = () => {
    // If in 'create for' mode inside portal
    if (routerParams.providerId) {
      // Switch provider and head to the edit profile page
      selectActiveProvider({ id: provider.id });
      history.push('/profile/general-information');
    } else {
      setOnboardingWizardExit();
    }
  };

  const toggleWaitingOnOwnerToPayModal = () => {
    setWaitingOnOwnerToPayModalOpen(false);
    editProfileClicked();
  };

  const makeProfileLiveClicked = () => {
    const isOwner = hasOwnerScope(accounts, roles, _.get(provider, 'id'));
    const hasSubscription =
      provider.hasPaidPlan || accounts.some((a) => providerHasSubscriptionInAccount(provider, a));

    if (!hasSubscription && isOwner) {
      setPayAndGoLiveModalOpen(true);
    } else {
      // This provider could still have not paid if they are using an invite token
      // if, so we need to show a message about how they need to contact their practice
      // owner or the provider who invited them to have them pay
      if (!isOwner && !hasSubscription) {
        setWaitingOnOwnerToPayModalOpen(true);
      } else {
        goLive({ provider_id: provider.id });
        setGoingLiveModalOpen(true);
      }
    }
  };

  const toggleGoLiveModal = (showModal) => {
    if (!showModal) {
      setOnboardingWizardExit();
    }
    setGoingLiveModalOpen(showModal);
  };

  const onSubscribed = () => {
    console.log('GoLiveForm: onSubscribed called');
    setPayAndGoLiveModalOpen(false);
    goLive({ provider_id: provider.id });
    setGoingLiveModalOpen(true);
  };

  return (
    <>
      <div key='info-form' className='go-live-form'>
        <div className='box m-b-md p-b-xs'>
          <div className='w-100 m-b-md'>
            <h1>
              Let’s make your profile live!
              <span className='m-l-xs' role='img' aria-label='tada'>
                🎉
              </span>
            </h1>

            <div className='m-b-xl'>
              <Accordian
                text='See a preview of your profile'
                isOpen={accordianOpen}
                toggleMethod={() => {
                  setAccordianOpen(!accordianOpen);
                }}
              >
                <div className='m-t-md'>
                  <div className='frame-wrapper box'>
                    <iframe title='Profile Preview' src={iframeSource} />
                  </div>
                </div>
              </Accordian>
            </div>

            <div className='w-100 flex m-b-xl'>
              <button onClick={makeProfileLiveClicked} className='lg primary pill'>
                Go Live
              </button>

              <button onClick={editProfileClicked} className='lg m-l-sm edit-profile-button'>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </div>
      <PayAndGoLiveModal
        open={payAndGoLiveModalOpen}
        toggle={setPayAndGoLiveModalOpen}
        onSubscribed={onSubscribed}
        provider={provider}
      />
      <GoingLiveModal open={goingLiveModalOpen} toggle={toggleGoLiveModal} />

      <WaitingOnOwnerToPayModal
        open={waitingOnOwnerToPayModalOpen}
        toggle={toggleWaitingOnOwnerToPayModal}
      />
    </>
  );
};

export default connect(
  ({ onboarding, main, auth }) => ({
    onboarding,
    main,
    roles: auth.roles,
  }),
  {
    getMe: mainActions.getMe,
    getAllAccountDetails: mainActions.getAllAccountDetails,
    goLive: mainActions.goLive,
    selectActiveProvider: mainActions.selectActiveProvider,
  }
)(GoLiveForm);
