import React, { ChangeEventHandler } from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {
  toggleName: string;
  toggleTooltipText?: string;
  handleToggleOn: ChangeEventHandler<HTMLInputElement>;
  handleToggleOff: ChangeEventHandler<HTMLInputElement>;
  isOn: boolean;
  offText: string;
  onText: string;
}
/**
 * IsolatedEitherOrToggle is a component that provides an isolated toggle switch with two options.
 * It allows the user to select between two states with corresponding labels and tooltips outside the
 * context of a form
 *
 * @example
 * <IsolatedEitherOrToggle
 *   toggleName="status_toggle"
 *   toggleTooltipText="Toggle your status"
 *   handleToggleOff={() => dispatch(actions.toggleStatus({ status: 'inactive'}))}
 *   handleToggleOn={() => dispatch(actions.toggleStatus({ status: 'active'}))}
 *   isOn={provider.status === 'active'}
 *   offText="Deactivate profile"
 *   onText="Activate status"
 * />
 */
function IsolatedEitherOrToggle({
  toggleName = '',
  toggleTooltipText = '',
  handleToggleOff = () => {},
  handleToggleOn = () => {},
  isOn = false,
  offText = 'Off',
  onText = 'On',
}: Props) {
  return (
    <form className={`${toggleName}-toggle-form isolated-either-or-toggle`} action=''>
      <div className='tab-select' data-for={`${toggleName}-tooltip`} data-tip={toggleTooltipText}>
        <div className='container'>
          <input
            type='radio'
            id={`${toggleName}_toggle_off`}
            value='off'
            checked={!isOn}
            name='off'
            onChange={handleToggleOff}
          />
          <label htmlFor={`${toggleName}_toggle_off`}>{offText}</label>
        </div>
        <div className='container'>
          <input
            type='radio'
            id={`${toggleName}_toggle_on`}
            value='on'
            checked={isOn}
            name='on'
            onChange={handleToggleOn}
          />
          <label htmlFor={`${toggleName}_toggle_on`}>{onText}</label>
        </div>
      </div>
      <ReactTooltip
        id={`${toggleName}-tooltip`}
        effect='solid'
        place='top'
        type='light'
        arrowColor='#37BEC3'
        className='isolated-either-or-toggle-tooltip'
      />
    </form>
  );
}

export default IsolatedEitherOrToggle;
