/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import ProductDescription from './ProductDescription';
import { Field } from 'react-final-form';
import constants, { ProductDescriptionType } from './constants';
import { formValues } from './types/types';
import { FormApi } from 'final-form';

interface Props {
  setShowSetupOptionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentModalSetupOption: React.Dispatch<React.SetStateAction<ProductDescriptionType | null>>;
  form: FormApi<formValues, Partial<formValues>>;
  hidePracticeManagementOption?: boolean;
  addingPracticeManagement?: boolean;
  upgrade?: boolean;
  showZpmOptions: boolean | undefined;
  setShowZpmOptions: $TSFixMe;
  showReimbursifyOption: boolean;
  showCopywritingOption: boolean;
  showReimbursifyOptions: boolean;
  setShowReimbursifyOptions: $TSFixMe;
}

function AddOnsBox({
  setShowSetupOptionModal,
  setCurrentModalSetupOption,
  form,
  hidePracticeManagementOption,
  addingPracticeManagement,
  upgrade,
  showZpmOptions,
  setShowZpmOptions,
  showReimbursifyOption,
  showCopywritingOption,
  showReimbursifyOptions,
  setShowReimbursifyOptions,
}: Props) {
  return (
    <div className='box m-b-sm'>
      {!hidePracticeManagementOption && (
        <>
          <div className='flex justify-between'>
            <div>
              <h5 className='payment-form-product-title'>
                Zencare Practice Management
                <ProductDescription
                  onlyIcon={true}
                  setShowModal={setShowSetupOptionModal}
                  setCurrentModalSetupOption={setCurrentModalSetupOption}
                  option={constants.product_descriptions.zencare_practice_management}
                />
              </h5>
              <p className='payment-form-product-description w-80'>
                An EHR to streamline appointments, billing, notes, telehealth, and more. Available
                in Premium or Pro. {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href='https://therapist.zencare.co/practice-management-feature-comparison'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Compare plans
                </a>
              </p>
            </div>
            <div className='switch  flex justify-between top'>
              <p className='price'>
                $19-39<span> /Month</span>
              </p>
              <Field
                name='practice_management_selected'
                component='input'
                type='checkbox'
                checked={showZpmOptions}
                id='practice_management_selected'
              />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label
                className='sm'
                htmlFor='practice_management_selected'
                onKeyDown={() => {}}
                onClick={() => {
                  const zpmState = form.getFieldState('practice_management');
                  if (zpmState && zpmState.value) {
                    form.change('practice_management', undefined);
                    form.change('practice_management_selected', false);
                  } else {
                    form.change('practice_management', 'professional');
                    form.change('practice_management_selected', true);
                  }
                  setShowZpmOptions(!showZpmOptions);
                }}
              >
                <span className='toggle'>{''}</span>
              </label>
            </div>
          </div>
          {showZpmOptions && (
            <div className='appear'>
              <div className='payment-form-product'>
                <p className='product-details m-y-0'>Practice Management Pro</p>
                <p className='price '>
                  $19<span> / Month</span>
                  <Field
                    name='practice_management'
                    component='input'
                    type='radio'
                    value='professional'
                    id='practice_management_professional'
                  />
                  <label className='m-b-sm' htmlFor='practice_management_professional'>
                    {''}
                  </label>
                </p>
              </div>
              <div className='payment-form-product'>
                <p className='product-details m-y-0'>Practice Management Premium</p>
                <p className='price '>
                  $39<span> / Month</span>
                  <Field
                    name='practice_management'
                    component='input'
                    type='radio'
                    value='premium'
                    id='practice_management_premium'
                  />
                  <label className='m-b-sm' htmlFor='practice_management_premium'>
                    {''}
                  </label>
                </p>
              </div>
            </div>
          )}
          {!addingPracticeManagement && <hr />}
        </>
      )}
      {showCopywritingOption && (
        <>
          <h5 className='payment-form-product-title'>
            Professional Copywriting
            <ProductDescription
              onlyIcon={true}
              setShowModal={setShowSetupOptionModal}
              setCurrentModalSetupOption={setCurrentModalSetupOption}
              option={constants.product_descriptions.professional_copywriting}
            />
          </h5>
          <div className='flex justify-between payment-form-product'>
            <p className='payment-form-product-description '>
              A professionally written bio and review of your video script.
            </p>
            <div className='switch flex justify-between'>
              <p className='price thin'>
                $70<span> Once</span>
              </p>
              <Field
                name='professional_copywriting'
                component='input'
                type='checkbox'
                id='professional_copywriting'
                value='add_on_professional_copywriting_v1'
              />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label className='sm' htmlFor='professional_copywriting'>
                <span className='toggle'>{''}</span>
              </label>
            </div>
          </div>
          {showReimbursifyOption && <hr />}
        </>
      )}
      {showReimbursifyOption && (
        <>
          <div className='flex justify-between'>
            <div>
              <h5 className='payment-form-product-title'>
                Private Pay Booster
                <ProductDescription
                  onlyIcon={true}
                  setShowModal={setShowSetupOptionModal}
                  setCurrentModalSetupOption={setCurrentModalSetupOption}
                  option={constants.product_descriptions.reimbursify}
                />
              </h5>
              <p className='payment-form-product-description w-80'>
                Instantly verify existing or prospective clients' out-of-network benefits to book
                more first appointments, turn more referrals into ongoing clients and increase
                client retention and practice revenue through free claims filing.{' '}
                <a
                  href='https://therapist.zencare.co/private-pay-booster-feature-comparison'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Compare plans
                </a>
              </p>
            </div>
            <div className='switch  flex justify-between top'>
              <p className='price'>
                $29-59<span> /Month</span>
              </p>
              <Field
                name='reimbursify_selected'
                component='input'
                type='checkbox'
                checked={showReimbursifyOptions}
                id='reimbursify_selected'
              />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label
                className='sm'
                htmlFor='reimbursify_selected'
                onKeyDown={() => {}}
                onClick={() => {
                  const reimbursifyState = form.getFieldState('reimbursify_tier');
                  if (reimbursifyState && reimbursifyState.value) {
                    form.change('reimbursify_tier', undefined);
                    form.change('reimbursify_selected', false);
                  } else {
                    form.change('reimbursify_tier', 'professional');
                    form.change('reimbursify_selected', true);
                  }
                  setShowReimbursifyOptions(!showReimbursifyOptions);
                }}
              >
                <span className='toggle'>{''}</span>
              </label>
            </div>
          </div>
          {showReimbursifyOptions && (
            <div className='appear'>
              <div className='payment-form-product'>
                <p className='product-details m-y-0'>Private Pay Booster Professional</p>
                <p className='price '>
                  $29<span> / Month</span>
                  <Field
                    name='reimbursify_tier'
                    component='input'
                    type='radio'
                    value='professional'
                    id='reimbursify_professional'
                  />
                  <label className='m-b-sm' htmlFor='reimbursify_professional'>
                    {''}
                  </label>
                </p>
              </div>
              <div className='payment-form-product'>
                <p className='product-details m-y-0'>Private Pay Booster Premium</p>
                <p className='price '>
                  $59<span> / Month</span>
                  <Field
                    name='reimbursify_tier'
                    component='input'
                    type='radio'
                    value='premium'
                    id='reimbursify_premium'
                  />
                  <label className='m-b-sm' htmlFor='reimbursify_premium'>
                    {''}
                  </label>
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AddOnsBox;
