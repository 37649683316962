/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { actions } from './sagaSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import autosize from 'autosize';
import HelpDialog from './HelpDialog';

interface Props {
  recipientName: string;
  recipientPhone: string;
  shouldShowDialog: boolean;
  textAreaInput: React.RefObject<HTMLTextAreaElement>;
  clientIsRedacted: boolean;
  isLocked: boolean;
}

interface Params {
  id: string;
}

const MessageForm = ({
  recipientName,
  recipientPhone,
  shouldShowDialog,
  textAreaInput,
  clientIsRedacted,
  isLocked,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const { id: client_user_id } = useParams<Params>();
  const { id: provider_id, status } = useSelector((state: $TSFixMe) => state.main.provider);
  const { messageSendSuccess, messageSendError } = useSelector((state: $TSFixMe) => state.messages);
  const schedulingMessage = `Hi ${recipientName},\n\nThank you very much for reaching out and for your interest in working together. I'm looking forward to speaking with you and seeing how I may be of help.\n\nI'd love to start with a phone consultation to learn more.\n\nWhat times work for you in the coming days for a phone call?`;
  const confirmationMessage = `Hi ${recipientName},\n\nThank you very much for reaching out and for your interest in working together. This is to confirm that I will be calling you${
    recipientPhone ? ` at ${recipientPhone}` : ''
  } at your requested time.\n\nI'm looking forward to speaking with you then and seeing how I may be of help.`;

  const [buttonText, setButtonText] = useState('Send');

  // Send button succuss UX
  useEffect(() => {
    if (messageSendSuccess) {
      setButtonText('Message sent!');
      setTimeout(() => {
        setButtonText('Send');
      }, 1500);
      return;
    }
    return;
  }, [messageSendSuccess]);

  // Handle close on overlay click -- will always close the menu unless the menu itself is clicked
  useEffect(() => {
    const closeMenu = (evt: Event) => {
      const path = evt.composedPath();
      if (!path) return;
      if (!path.some((path) => (path as HTMLInputElement).id === 'template-menu-button')) {
        setShowMenu(false);
      }
    };
    document.body.addEventListener('click', closeMenu);
    return () => document.body.removeEventListener('click', closeMenu);
  }, []);

  const sendMessage = (values: $TSFixMe) => {
    const { message } = values;
    setShowMenu(false);
    // @ts-expect-error: haven't typed actions yet
    dispatch(actions.sendMessage({ provider_id, client_user_id, message }));
  };

  const handleKeyDown = (props: $TSFixMe) => (e: KeyboardEvent) => {
    const { handleSubmit } = props;
    setShowMenu(false);
    if (textAreaInput.current) {
      autosize(textAreaInput.current);
    }

    // send on 'meta' and 'enter'
    if (e.key === 'Enter' && e.metaKey) {
      handleSubmit();
      if (textAreaInput.current) {
        autosize(textAreaInput.current);
      }
      return;
    }
  };

  if (isLocked) {
    return (
      <div className='appointment-message-form p-t-0'>
        <HelpDialog
          shouldShow={true}
          isClosable={false}
          icon={<i className='far fa-info-circle'></i>}
        >
          This conversation is locked. If you have any questions or concerns, please contact the
          Zencare team directly <Link to='/contact'>here</Link>.
        </HelpDialog>
      </div>
    );
  }

  // TODO this
  if (status !== 'active') {
    return (
      <div className='appointment-message-form message-form-inactive'>
        <h6>
          <i className='far fa-info-circle'></i>
          Your profile is currently inactive. Please reactivate your profile to use Zencare's
          messaging tool! In the meantime, please reach the client via email or phone.
        </h6>
      </div>
    );
  }

  if (clientIsRedacted) {
    return (
      <div className='appointment-message-form message-form-inactive'>
        <h6>
          <i className='far fa-info-circle'></i>
          This client deleted all of their personal data and messages from Zencare! We kept this
          record as a reminder that the referral was received, but any content and personal
          information is no longer accessible. Please reach out to support@zencare.co if you have
          any questions.
        </h6>
      </div>
    );
  }

  return (
    <div className='appointment-message-form'>
      <Form
        initialValues={{}}
        onSubmit={(values, form) => {
          sendMessage(values);
          form.mutators.clearMessage();
        }}
        validate={(values) => {
          const errors: { message?: string } = {};

          if (!values.message) {
            errors.message = 'Message is required';
          }

          return errors;
        }}
        mutators={{
          useSchedulingMessage: (args, state, utils) => {
            utils.changeValue(state, 'message', () => schedulingMessage);
            setTimeout(() => {
              if (textAreaInput.current) {
                autosize(textAreaInput.current);
                autosize.update(textAreaInput.current);
              }
            }, 0);
          },
          useConfirmationMessage: (args, state, utils) => {
            utils.changeValue(state, 'message', () => confirmationMessage);
            setTimeout(() => {
              if (textAreaInput.current) {
                autosize(textAreaInput.current);
                autosize.update(textAreaInput.current);
              }
            }, 0);
          },
          clearMessage: (args, state, utils) => {
            utils.changeValue(state, 'message', () => '');
            setTimeout(() => {
              if (textAreaInput.current) {
                autosize(textAreaInput.current);
                autosize.update(textAreaInput.current);
              }
            }, 0);
          },
        }}
        render={(formProps) => (
          <>
            <div className='form-status-box'>
              <p className='text-error'>{messageSendError}</p>
            </div>
            <div className='form-wrapper'>
              <button
                id='template-menu-button'
                onClick={() => setShowMenu(!showMenu)}
                className='primary md pill hollow form-options'
              >
                <i className='fal fa-file-plus'></i>
                <div className={`menu ${!showMenu && 'menu-hide'}`}>
                  <div
                    role='button'
                    onKeyDown={() => {}}
                    className='link menu-item'
                    tabIndex={0}
                    onClick={formProps.form.mutators.useConfirmationMessage}
                  >
                    <i className='far fa-calendar-check'></i>
                    Use Confirmation Template
                  </div>
                  <div
                    role='button'
                    onKeyDown={() => {}}
                    className='link menu-item'
                    tabIndex={0}
                    onClick={formProps.form.mutators.useSchedulingMessage}
                  >
                    <i className='far fa-calendar-day'></i>
                    Use Scheduling Template
                  </div>
                </div>
              </button>
              <div className='textarea-wrapper'>
                <Field
                  ref={textAreaInput}
                  className='message-textarea'
                  name='message'
                  placeholder={`Message ${recipientName}`}
                  component='textarea'
                  onKeyDown={handleKeyDown(formProps)}
                />
              </div>
              <button
                onClick={() => {
                  formProps.handleSubmit();
                }}
                className='send-message-button primary pill md'
              >
                {buttonText}
              </button>
              <button
                onClick={() => {
                  formProps.handleSubmit();
                }}
                className='send-message-button-mobile primary pill md'
              >
                <i className='far fa-paper-plane'></i>
              </button>
            </div>
            <HelpDialog shouldShow={shouldShowDialog}>
              Respond to the client here! If you take the conversation off Zencare, please let the
              client know to look out for your call or email.
            </HelpDialog>
          </>
        )}
      />
    </div>
  );
};

export default MessageForm;
