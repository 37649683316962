import DefaultLayout from '##/Default';
import AuthLayout from '##/Auth';
import AccountPreferences from './account/AccountPreferences';
import SwitchProfile from './account/SwitchProfile';
import AddNewProvider from './account/add-profile/AddNewProvider';
import PayForProvider from './account/add-profile/PayForProvider';
import AboutYouFor from './account/add-profile/AboutYouFor';
import YourPracticeFor from './account/add-profile/YourPracticeFor';
import PhotosFor from './account/add-profile/PhotosFor';
import GoLiveFor from './account/add-profile/GoLiveFor';
import PriceCheckout from '@/payment/PriceCheckout';
import PriceCheckoutSuccess from '@/payment/PriceCheckoutSuccess';
import LocationsForNewProvider from './account/add-profile/LocationsForNewProvider';
import PlanManagement from './account/plan-management/PlanManagement';
import PracticeManagement from '../practice-management/PracticeManagement';
import PracticeManagementPostMessageTest from '../practice-management/PracticeManagementPostMessageTest';
import EditClienteleApproach from '../provider/profile/ClienteleApproach.jsx/ClienteleApproach';
import Specialties from '../provider/profile/Specialties/Specialties';
import EducationEmployment from '../provider/profile/EducationEmployment/EducationEmployment';
import InsuranceFees from '../provider/profile/InsuranceFees/InsuranceFees';
import GeneralInformation from '../provider/profile/GeneralInformation/GeneralInformation';
import MessageStatement from '../provider/profile/MessageStatement/MessageStatement';
import Dashboard from './dashboard/Dashboard';
import AvailabilityLocations from '../provider/profile/AvailabilityLocations/AvailabilityLocations';
import PhotosVideo from '../provider/profile/PhotosVideo/PhotosVideo';
import TherapyGroups from '../provider/therapy-groups/TherapyGroups';
import ProviderFeedback from '../provider/feedback/ProviderFeedback';
import PracticeProviders from '../provider/profile/PracticeProviders/PracticeProviders';
import Messages from '../provider/appointments/messages/Messages';
import ClientList from '../provider/appointments/client-list/ClientList';
import VettingQuiz from './vetting/VettingQuiz';
import CalendarView from '../provider/calendars/CalendarView';
import UpgradePlan from '../payment/seat-based/UpgradePlan';
import UpgradeReimbursify from '../payment/seat-based/UpgradeReimbursify';
import ConvertToMonthly from '../payment/seat-based/ConvertToMonthly';
import IntakeExport from './account/IntakeExport';
import PracticeAppointments from '../practice-inbox/PracticeAppointments';
import PracticeMessages from '../practice-inbox/PracticeMessages';
import StandaloneRegistration from '../practice-management/StandaloneRegistration';
import StandaloneRegistrationPostMessageTest from '../practice-management/StandaloneRegistrationPostMessageTest';

import StandaloneOnboarding from '~/layouts/StandaloneOnboarding';

export interface MainRoute extends BaseRoute {
  auth: boolean;
  containerLayoutClass?: string;
  practiceInbox?: boolean;
}

const routes: Array<MainRoute> = [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: Dashboard,
    title: 'Dashboard',
    auth: true,
  },
  {
    path: '/account-preferences',
    exact: true,
    layout: DefaultLayout,
    component: AccountPreferences,
    title: 'Account Preferences',
    auth: true,
  },
  {
    path: '/referral-data',
    exact: true,
    layout: DefaultLayout,
    component: IntakeExport,
    title: 'Referral Data',
    auth: true,
  },
  {
    path: '/plan-management',
    exact: true,
    layout: DefaultLayout,
    component: PlanManagement,
    title: 'Plan Management',
    auth: true,
  },
  {
    path: '/practice-management',
    exact: true,
    layout: DefaultLayout,
    component: PracticeManagement,
    title: 'Practice Management',
    auth: true,
  },
  {
    path: '/practice-management-post-message-test',
    containerLayoutClass: 'payment-container-layout',
    exact: true,
    layout: DefaultLayout,
    component: PracticeManagementPostMessageTest,
    title: 'Practice Management Post Message Test',
    auth: true,
  },
  {
    path: '/profiles',
    exact: true,
    layout: DefaultLayout,
    component: SwitchProfile,
    title: 'Switch Profile',
    auth: true,
  },
  {
    path: '/profiles/create',
    exact: true,
    layout: DefaultLayout,
    component: AddNewProvider,
    title: 'Create a Profile',
    auth: true,
  },
  {
    path: '/profiles/create/:providerId',
    exact: true,
    layout: DefaultLayout,
    component: LocationsForNewProvider,
    title: 'Locations for new Profile',
    auth: true,
  },
  {
    path: '/profiles/pay-for/:providerId',
    containerLayoutClass: 'payment-container-layout',
    exact: true,
    layout: DefaultLayout,
    component: PayForProvider,
    title: 'Pay for a Profile',
    auth: true,
  },
  {
    path: '/profiles/about-you-for/:providerId',
    exact: true,
    layout: DefaultLayout,
    component: AboutYouFor,
    title: 'About You',
    auth: true,
  },
  {
    path: '/profiles/your-practice-for/:providerId',
    exact: true,
    layout: DefaultLayout,
    component: YourPracticeFor,
    title: 'Your Practice',
    auth: true,
  },
  {
    path: '/profiles/photos-for/:providerId',
    exact: true,
    layout: DefaultLayout,
    component: PhotosFor,
    title: 'Photos',
    auth: true,
  },
  {
    path: '/profiles/go-live-for/:providerId',
    exact: true,
    layout: DefaultLayout,
    component: GoLiveFor,
    title: 'Preview and Go Live',
    auth: true,
  },
  {
    path: '/checkout/:priceId',
    exact: true,
    layout: DefaultLayout,
    component: PriceCheckout,
    title: 'Checkout',
    auth: true,
  },
  {
    path: '/checkout/no-sign-in/:priceId',
    exact: true,
    layout: AuthLayout,
    component: PriceCheckout,
    title: 'Checkout',
    auth: false,
  },
  {
    path: '/checkout/success/:priceId',
    exact: true,
    layout: AuthLayout,
    component: PriceCheckoutSuccess,
    title: 'Checkout Success',
    auth: true,
  },
  {
    path: '/practice-management/upgrade/:accountId',
    exact: true,
    layout: DefaultLayout,
    component: UpgradePlan,
    title: 'Upgrade Plan Management',
    auth: true,
  },
  {
    path: '/reimbursify/upgrade/:accountId',
    exact: true,
    layout: DefaultLayout,
    component: UpgradeReimbursify,
    title: 'Upgrade Private Pay Booster',
    auth: true,
  },
  {
    path: '/membership/switch-to-monthly/:subscriptionId',
    exact: true,
    layout: DefaultLayout,
    component: ConvertToMonthly,
    title: 'Switch to Monthly Payments',
    auth: true,
  },
  {
    path: '/profile/clientele-approach',
    exact: true,
    layout: DefaultLayout,
    component: EditClienteleApproach,
    title: 'Edit Clientele & Approach',
    auth: true,
  },
  {
    path: '/profile/specialties',
    exact: true,
    layout: DefaultLayout,
    component: Specialties,
    title: 'Edit Specialties',
    auth: true,
  },
  {
    path: '/profile/education-employment',
    exact: true,
    layout: DefaultLayout,
    component: EducationEmployment,
    title: 'Edit Education & Employment',
    auth: true,
  },
  {
    path: '/profile/insurance-fees',
    exact: true,
    layout: DefaultLayout,
    component: InsuranceFees,
    title: 'Edit Insurance & Fees',
    auth: true,
  },
  {
    path: '/profile/general-information',
    exact: true,
    layout: DefaultLayout,
    component: GeneralInformation,
    title: 'Edit General Information',
    auth: true,
  },
  {
    path: '/profile/message-statement',
    exact: true,
    layout: DefaultLayout,
    component: MessageStatement,
    title: 'Edit Message & Statement',
    auth: true,
  },
  {
    path: '/profile/availability-locations',
    exact: true,
    layout: DefaultLayout,
    component: AvailabilityLocations,
    title: 'Availability & Locations',
    auth: true,
  },
  {
    path: '/profile/photos-video',
    exact: true,
    layout: DefaultLayout,
    component: PhotosVideo,
    title: 'Edit Photos & Video',
    auth: true,
  },
  {
    path: '/testimonials',
    exact: true,
    layout: DefaultLayout,
    component: ProviderFeedback,
    title: 'Provider Feedback',
    auth: true,
  },
  {
    path: '/therapy-groups',
    exact: true,
    layout: DefaultLayout,
    component: TherapyGroups,
    title: 'Therapy Groups',
    auth: true,
  },
  {
    path: '/practice/providers',
    exact: true,
    layout: DefaultLayout,
    component: PracticeProviders,
    title: 'Practice Providers',
    auth: true,
  },

  {
    path: '/messages',
    exact: false,
    layout: DefaultLayout,
    component: Messages,
    title: 'Messages',
    auth: true,
  },
  {
    path: '/client-list',
    exact: true,
    layout: DefaultLayout,
    component: ClientList,
    title: 'Full Client List',
    auth: true,
  },
  {
    path: '/client-referrals',
    exact: true,
    layout: DefaultLayout,
    component: ClientList,
    title: 'Full Client List',
    auth: true,
  },
  {
    path: '/vetting/quiz',
    exact: true,
    layout: DefaultLayout,
    component: VettingQuiz,
    title: 'Vetting Quiz',
    auth: true,
  },
  {
    path: '/calendar',
    exact: true,
    layout: DefaultLayout,
    component: CalendarView,
    title: 'Calendar',
    auth: true,
  },
  {
    path: '/practice-inbox',
    exact: true,
    layout: DefaultLayout,
    component: PracticeMessages,
    title: 'Practice Inbox',
    auth: true,
    practiceInbox: true,
  },
  {
    path: '/practice-inbox/appointments',
    exact: true,
    layout: DefaultLayout,
    component: PracticeAppointments,
    title: 'Practice Appointments',
    auth: true,
    practiceInbox: true,
  },
  {
    path: '/practice-inbox/messages',
    exact: false,
    layout: DefaultLayout,
    component: PracticeMessages,
    title: 'Practice Inbox',
    auth: true,
    practiceInbox: true,
  },
  {
    path: '/practice-inbox/client-list',
    exact: true,
    layout: DefaultLayout,
    component: ClientList,
    title: 'Practice Clients',
    auth: true,
    practiceInbox: true,
  },
  {
    path: '/zpm-signup',
    exact: true,
    layout: StandaloneOnboarding,
    component: StandaloneRegistration,
    title: 'Zencare Practice Management Signup',
    auth: false,
  },
  {
    path: '/zpm-signup-iframe-post-message-test',
    exact: true,
    layout: StandaloneOnboarding,
    component: StandaloneRegistrationPostMessageTest,
    title: 'Zencare Practice Management Signup Iframe Post Message Test',
    auth: false,
  },
];

export default routes;
