export default [
  // Singular, Plural
  ['clientele', 'clientele'],
  ['gender', 'genders'],
  ['identity', 'identities'],
  ['insurance', 'insurances'],
  ['specialty', 'specialties'],
  ['approach', 'approaches'],
  ['profession', 'professions'],
  ['language', 'languages'],
  ['degree', 'degrees'],
];
