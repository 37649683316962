import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '#/history';

import LocationForm from '@/onboarding/LocationForm';
import hasClinicianScope from '#/hasClinicianScope';
import providerName from '#/providerName';

import _ from 'lodash';

export const LocationsForNewProvider = ({ match, allProviders, accounts, roles }) => {
  let provider;
  try {
    const providerId = _.get(match, 'params.providerId', -1);
    provider =
      allProviders.find((p) => parseInt(p.id) === parseInt(providerId)) ||
      _.flatten(accounts.map((a) => a.providers)).find(
        (p) => parseInt(p.id) === parseInt(providerId)
      );
  } catch (e) {}

  const isClinician = provider && hasClinicianScope(roles, _.get(provider, 'id'));
  const [effectRun, setEffectRun] = useState(false);

  useEffect(() => {
    if (!provider) return;

    if (_.get(provider, 'locations.length')) {
      if (isClinician) {
        // If provider is complete enough, go straight to
        // payment (if owner w/o clinician access) or otherwise
        // send to about you
        history.replace(`/profiles/about-you-for/${provider.id}`);
      } else {
        history.replace(`/profiles/pay-for/${provider.id}`);
      }
    }
    setEffectRun(true);
  }, [isClinician, provider]);

  if (!provider) {
    return null;
  }

  if (!effectRun) {
    return null;
  }

  return (
    <div className='flex column align-center'>
      <div className='w-100'>
        <h1 className='m-b-md p-l-xl'>
          Complete {providerName(provider)}'s profile!{' '}
          <i className='fad fa-stars text-quaternary'></i>
          <br></br>
        </h1>
      </div>
      <div
        className='onboarding-form'
        style={{
          maxWidth: '90%',
          minWidth: '90%',
        }}
      >
        <LocationForm providerId={provider.id} />
      </div>
    </div>
  );
};

export default connect(
  ({ main, auth }) => ({
    allProviders: main.allProviders,
    accounts: _.get(main, 'accounts', []).filter((a) => !a.error),
    roles: auth.roles,
  }),
  {}
)(LocationsForNewProvider);
