import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';

function ConversationList({ showConversation, setShowConversation, getConversations }) {
  const ref = useRef();
  const { conversations, isLoadingConversations, hideLoadMoreButton } = useSelector(
    (state) => state.practiceInbox
  );
  const [displayedConversations, setDisplayedConversations] = useState([]);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  // Initialize displayed names
  useEffect(() => {
    if (!conversations) return;
    setDisplayedConversations(conversations);
    if (shouldScrollToBottom) {
      // Give this scroll behavior just a split second to load element size
      // in oder to scroll to the right location
      setTimeout(() => {
        ref.current.scrollTop = ref.current.scrollHeight;
      }, 0);
    }
  }, [conversations, shouldScrollToBottom]);

  return (
    <div className={`messages-list ${showConversation ? 'show-conversation' : ''}`}>
      <h4 className='title practice-inbox-title'> Messages</h4>
      <ul className='message-link-list' ref={ref}>
        {isLoadingConversations ? (
          <div className='loader-container'>
            <Loader type='Oval' color='#37BEC3' height={50} width={50} />
          </div>
        ) : (
          <>
            {displayedConversations.map((conversation) => (
              <li
                key={`${conversation.id}${conversation.providerId}`}
                className='message-link-list-item'
              >
                <NavLink
                  onClick={() => setShowConversation(true)}
                  className={`message-link ${conversation.isUnread ? 'message-link-unread' : ''}`}
                  activeClassName='message-link-active'
                  to={`/practice-inbox/messages/${conversation.id}/${conversation.providerId}`}
                >
                  <div className='message-link-names-wrapper'>
                    <p className='m-0'>{conversation.fullName}</p>
                    <small>{conversation.providerName}</small>
                  </div>
                  {conversation.isUnread ? (
                    <i className='fas fa-circle unread-indicator'></i>
                  ) : null}
                </NavLink>
              </li>
            ))}
            {!hideLoadMoreButton && (
              <button
                className='message-list-button pill primary sm'
                onClick={() => {
                  getConversations();
                  setShouldScrollToBottom(true);
                }}
              >
                Load More
              </button>
            )}
            <small className='message-list-count'>
              These are your most recent {displayedConversations.length} conversations. To see a
              searchable list of all your conversations, see your
              <Link to='/practice-inbox/client-list'> Client List</Link>.
            </small>
          </>
        )}
      </ul>
    </div>
  );
}

export default ConversationList;

// REMOVING SEARCH FROM PRACTICE INBOX UNTIL (IF) PAGINATION IS HANDLED

// const [inputValue, setInputValue] = useState('');

// function handleInputChange(e) {
//   const { value } = e.target;
//   setInputValue(value);
//   updateSearchResults(value);
// }

// function updateSearchResults(input) {
//   if (input === '') {
//     return setDisplayedConversations(conversations);
//   }
//   setDisplayedConversations((previous) =>
//     previous.filter((conversation) => {
//       const shortNameMatch =
//         conversation.shortName &&
//         conversation.shortName.toLowerCase().includes(input.toLowerCase());
//       const fullNameMatch =
//         conversation.fullName &&
//         conversation.fullName.toLowerCase().includes(input.toLowerCase());
//       const providerNameMatch =
//         conversation.providerName &&
//         conversation.providerName.toLowerCase().includes(input.toLowerCase());
//       return shortNameMatch || fullNameMatch || providerNameMatch;
//     })
//   );
// }

// {/* <input
//   id='search-messages'
//   className='input'
//   type='text'
//   value={inputValue}
//   onChange={handleInputChange}
//   placeholder='Search by client or provider'
// /> */}
