import React from 'react';
import TopNav from '+/nav/TopNav';
import Flashes from '+/flashes/Container';

export const StandaloneOnboarding = ({ children }) => (
  <div id='layout' className='onboarding'>
    <Flashes />
    <div className='nav-top-container'>
      <TopNav overrideLogoLink={true} />
    </div>
    <div className='content-container'>
      <div style={{ width: '100%' }}>
        <div
          style={{
            maxWidth: '1500px',
            margin: '0 auto',
          }}
          className='content'
        >
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default StandaloneOnboarding;
