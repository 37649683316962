export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // Aug. 2022 -- For now, do not display country code
    // const intlCode = match[1] ? '+1 ' : '';
    // return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
}

export function formatTelLink(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1' : '';
    return [intlCode, match[2], match[3], match[4]].join('');
  }
  return phoneNumberString;
}
