import _ from 'lodash';
import { displayMoneyString } from '../../../../utils/money';

export function getCardFromSource(source) {
  if (!source) {
    return null;
  }

  // source is a Stripe Source object
  if (source.card) {
    return source.card;
  }

  // source is a Stripe card object (from Moonclerk era) or
  // source is a testing card on stage with no id/object field
  if (source.object === 'card' || source.fingerprint) {
    return source;
  }
}

// Sources can be sources or card. Try comparing by ids first, or compare card fingerprints if one
// or more ids is missing or ids don't match.
// NOTE: This logic cshould be kept in sync with the API repo's
// lib/stripe-customers/index.js / sourcesAreSame() function.
export function sourcesAreSame(source1, source2) {
  if (!source1 || !source2) {
    return false;
  }

  if (source1.id && source2.id && source1.id === source2.id) {
    return true;
  }

  // If fingerprints match, the card numbers are the same.
  // However, the exp date could still be different
  const source1Card = getCardFromSource(source1);
  const source2Card = getCardFromSource(source2);
  const matchingFingerprints =
    _.get(source1Card, 'fingerprint') === _.get(source2Card, 'fingerprint');
  const matchingExpMonth = _.get(source1Card, 'exp_month') === _.get(source2Card, 'exp_month');
  const matchingExpYear = _.get(source1Card, 'exp_year') === _.get(source2Card, 'exp_year');
  const matchingExpDates = matchingExpMonth && matchingExpYear;

  return matchingFingerprints && matchingExpDates;
}

export const deduplicateCustomerSourcesByCardNumAndExp = (sources) => {
  // We consider a source unique only if the card fingerprint (basically the card #)
  // exp_month, and exp_year all match.
  // This means, if the user has manually typed in the same card (with same exp)
  // more than once rather than using the radio buttons to select the already saved
  // card, we're only going to show the card once on Plan Management even though
  // the card is stored more than once in the Stripe Customer.
  // This is OK, because our code that updates sources, also uses the same
  // logic when finding sources to update such that it will find all the subscriptions
  // using this same card number and exp in the stripe customer and
  const uniqueSources = [];
  if (sources && sources.length) {
    sources.forEach((sourceToCheck) => {
      // Only need to check for uniqueness if we already have some sources in the list
      if (sources && sources.length) {
        let isUnique = true;
        uniqueSources.forEach((existingSource) => {
          if (sourcesAreSame(existingSource, sourceToCheck)) {
            isUnique = false;
          }
        });
        if (isUnique) {
          uniqueSources.push(sourceToCheck);
        }
      } else {
        uniqueSources.push(sourceToCheck);
      }
    });
  }

  return uniqueSources;
};

export function formatPrice(subscriptionItem) {
  if (!subscriptionItem.price) {
    return;
  }
  const amount = subscriptionItem.price.unit_amount / 100;

  return formatPriceGivenAmount(subscriptionItem, amount);
}

export function formatPriceGivenAmount(subscriptionItem, amount) {
  let price;
  if (!subscriptionItem.price) {
    return;
  }

  const recurring = subscriptionItem.price.recurring;
  if (recurring) {
    let recurringPhrase;
    if (recurring.interval_count > 1) {
      recurringPhrase = `${recurring.interval_count} ${recurring.interval}s`;
    } else {
      recurringPhrase = recurring.interval;
    }

    price = `${displayMoneyString(amount)} per ${recurringPhrase}`;
  } else {
    price = `${displayMoneyString(amount)} one-time`;
  }

  return price;
}

export const formatInvoiceDiscount = (invoice) => {
  if (!invoice.discount) {
    return;
  }

  let discount;

  // TO DO: Edge case fix needed, if coupon was restricted to specific products
  // (e.g. $200 that can only be used towards setup)
  // we still show the full value of the coupon rather than the actual applied amount.
  if (_.get(invoice, 'discount.coupon.percent_off')) {
    discount = `${invoice.discount.coupon.name} (-${invoice.discount.coupon.percent_off}%)`;
  } else if (_.get(invoice, 'discount.coupon.amount_off')) {
    discount = `${invoice.discount.coupon.name} (-$${invoice.discount.coupon.amount_off / 100})`;
  }

  return discount;
};

export const formatInvoiceBalanceApplied = (invoice) => {
  if (invoice.amount_due >= invoice.total) {
    return;
  }

  return `Referral discounts (-$${(invoice.total - invoice.amount_due) / 100})`;
};

export function getCorrespondingProviderIds(subscription) {
  const pid = _.get(subscription, 'metadata.provider_id');
  const pids = _.get(subscription, 'metadata.provider_ids');

  if (!pid && !pids) {
    return [];
  }

  const corresponding = (pids ? _.uniq(pids) : [pid]).map((s) => parseInt(s));

  return corresponding;
}

export function getSubscriptionProviders(subscription, allProviders) {
  const providerIds = getCorrespondingProviderIds(subscription);

  return allProviders.filter((p) => providerIds.includes(p.id));
}

// This is legacy providers only coming back
// Not people who have a legacy subscription (paid)
// Not people who have a legacy canceled subscription
// Not people who are on a seat-based subscription (paid)
// Not people who are on a seat-based subscription who are canceled
export function getIncompleteProviders(customers, allProviders, accounts) {
  const allLegacySubscriptions = _.flatten(
    customers.map((c) => c.stripe_subscriptions.filter((s) => s.metadata?.seat_based !== 'yes'))
  );

  // This is complete legacy providers only
  const completeProviderIds = _.uniq(
    _.flatten(allLegacySubscriptions.map((s) => getSubscriptionProviders(s, allProviders))).map(
      (p) => p.id
    )
  );
  const incompleteProviderIds = _.difference(
    allProviders.map((p) => p.id),
    completeProviderIds
  );

  const completeOrCanceledProviderSeatBasedIds = [];

  accounts.forEach((a) => {
    a.provider_paid_seats.forEach((s) => {
      completeOrCanceledProviderSeatBasedIds.push(s.provider_id);
    });
  });

  return allProviders.filter(
    (p) =>
      incompleteProviderIds.includes(p.id) && !completeOrCanceledProviderSeatBasedIds.includes(p.id)
  );
}

export function overlappingSubsWithStatus(subscription, allSubscriptions, statusesToCheck) {
  const providerIds = getCorrespondingProviderIds(subscription);

  return allSubscriptions.filter((s) => {
    if (s.id === subscription.id) {
      return false;
    }

    if (!statusesToCheck.includes(s.status)) {
      return false;
    }

    const ids = getCorrespondingProviderIds(s);

    return _.intersection(providerIds, ids).length > 0;
  });
}

export function getAssociatedAccount(accounts, subProviders) {
  if (!accounts || accounts.length < 1 || !subProviders || subProviders.length < 1) return null;
  const associatedAccount = accounts.find((account) => account.id === subProviders[0]?.account_id);
  return associatedAccount;
}
