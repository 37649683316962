import React from 'react';
import { connect } from 'react-redux';
import Location from './Location';
import { actions as mainActions } from '~/components/main/sagaSlice';

export const LocationViewContainer = ({
  provider,
  updateLocation,
  location,
  existingLocations,
  licenses,
}) => {
  const onSubmit = (value) => {
    const obj = { id: location.id };

    const payload = Object.assign(obj, value);

    updateLocation(payload);
  };

  return (
    <div className='location-view-container m-b-md'>
      <Location
        provider={provider}
        licenses={licenses}
        location={location}
        existingLocations={existingLocations}
        onSubmit={onSubmit}
      />
    </div>
  );
};

const actions = { updateLocation: mainActions.updateLocation };

export default connect(null, actions)(LocationViewContainer);
