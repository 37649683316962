import { put } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';
import Bugsnag from '@bugsnag/js';

const initialState = {
  isLoading: true,
  // Updates with getMe in state.main
  conversations: null,
  isLoadingConversations: false,
  conversationMessages: null,
  hasUnreadMessages: false,

  messageSendInProgress: false,
  messageSendSuccess: false,
  messageSendError: null,

  messageDeleteInProgress: false,
  messageDeleteSuccess: false,
  messageDeleteError: null,
};

const sagaSliceModule = createModule({
  name: 'messages',
  initialState,
  reducers: {
    clearStore: (state) => {
      state = {
        ...initialState,
      };

      return state;
    },
    updateMessages: (state, payload) => {
      state.messageSendInProgress = false;
      state.messageSendSuccess = false;
      state.messageSendError = null;
    },
    // @ts-ignore
    sendMessage: (state, payload) => {
      state.messageSendInProgress = true;
      state.messageSendSuccess = false;
      state.messageSendError = null;
    },
    // @ts-ignore
    sendMessageSuccess: (state, payload) => {
      state.messageSendInProgress = false;
      state.messageSendSuccess = true;
    },
    // @ts-ignore
    sendMessageFail: (state, payload) => {
      state.messageSendInProgress = false;
      state.messageSendError = payload.toString();
      state.messageSendSuccess = false;
      Bugsnag.notify(
        `${JSON.stringify({
          errorType: 'sendMessageFail',
          response: payload.response,
          message: payload.message,
        })}`
      );
    },
    // @ts-ignore
    deleteMessage: (state, payload) => {
      state.messageDeleteInProgress = true;
      state.messageDeleteSuccess = false;
      state.messageDeleteError = null;
    },
    // @ts-ignore
    deleteMessageSuccess: (state, payload) => {
      state.messageDeleteInProgress = false;
      state.messageDeleteSuccess = true;
    },
    // @ts-ignore
    deleteMessageFail: failReducer,
    // @ts-ignore
    checkForUnreadMessages: (state) => {
      if (!state.conversations) return;
      state.hasUnreadMessages = state.conversations.some((c: $TSFixMe) => c.isUnread === true);
    },
    // deleteMessageFail: (state, payload) => {
    //   state.messageDeleteInProgress = false;
    //   state.messageDeleteError = payload.toString();
    // },

    // @ts-ignore
    getConversations: (state) => {
      // @ts-ignore
      loadingReducer(state);
      state.messageSendSuccess = false;
      state.isLoadingConversations = true;
    },
    // @ts-ignore
    getConversationsSuccess: (state, payload) => {
      state.isLoading = false;
      state.messageSendSuccess = false;
      state.isLoadingConversations = false;
      state.conversations = payload;
    },
    // @ts-ignore
    getConversationsFail: failReducer,
    // @ts-ignore
    getConversationMessages: loadingReducer,
    // @ts-ignore
    getConversationMessagesSuccess: (state, payload) => {
      state.messageSendSuccess = false;
      state.isLoading = false;
      state.conversationMessages = payload;
    },
    // @ts-ignore
    getConversationMessagesFail: failReducer,
  },
  // eslint-disable-next-line
  sagas: (A) => {
    const sagas = {
      [A.getConversations]: function* ({ payload }: $TSFixMe) {
        const { provider_id } = payload;
        yield sagaApi.get(
          `/portal/provider/${provider_id}/conversations`,
          A.getConversationsSuccess,
          A.getConversationsFail
        );
        yield put(A.checkForUnreadMessages());
      },
      [A.getConversationMessages]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id } = payload;
        yield sagaApi.get(
          `/portal/provider/${provider_id}/conversation-messages/${client_user_id}`,
          A.getConversationMessagesSuccess,
          A.getConversationMessagesFail
        );
        yield put(A.getConversations({ provider_id }));
      },
      [A.sendMessage]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id, message } = payload;

        yield sagaApi.post(
          `/portal/provider/${provider_id}/messages/${client_user_id}`,
          { message },
          A.sendMessageSuccess,
          A.sendMessageFail
        );
      },
      [A.sendMessageSuccess]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id } = payload;
        yield put(A.getConversationMessages({ provider_id, client_user_id }));
      },
      [A.deleteMessage]: function* ({ payload }: $TSFixMe) {
        const { provider_id, message_id } = payload;

        yield sagaApi.put(
          `/portal/provider/${provider_id}/messages/${message_id}/delete`,
          payload,
          A.deleteMessageSuccess,
          A.deleteMessageFail
        );
      },
      [A.deleteMessageSuccess]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id } = payload;
        yield put(A.getConversationMessages({ provider_id, client_user_id }));
      },
      [A.updateMessages]: function* ({ payload }: $TSFixMe) {
        const { provider_id, client_user_id } = payload;
        yield put(A.getConversations({ provider_id }));
        if (client_user_id) {
          yield put(A.getConversationMessages({ provider_id, client_user_id }));
        }
      },
    };

    return sagas;
  },
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
