import React, { useState } from 'react';

interface Props {
  children: React.ReactNode;
  shouldShow: boolean;
  isClosable?: boolean;
  icon?: JSX.Element;
}

function HelpDialog({ children, shouldShow, isClosable = true, icon }: Props) {
  const [open, setOpen] = useState(true);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <div className={`help-dialog ${shouldShow && open ? 'show' : ''}`}>
      {icon ? icon : <i className='fal fa-lightbulb-on'></i>}
      <p>{children}</p>
      {isClosable && <button onClick={closeDialog}>&times;</button>}
    </div>
  );
}

export default HelpDialog;
